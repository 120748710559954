import { useCallback, useState, useMemo, memo } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { toast } from 'react-toastify';

import { IoMdArrowRoundBack } from 'react-icons/io';
import { MdIosShare } from 'react-icons/md';
import { AiOutlineLink } from 'react-icons/ai';

import ToolTipView from 'components/tooltipView';

import StatusButton from 'pages/auth/editAppointment/commonComponents/StatusButton';
import { appointmentTabConst } from 'pages/auth/editAppointment/data';
import TabView from 'pages/auth/editAppointment/commonComponents/TabView';

import { updateAppointmentDetail } from 'redux/appointment/appointment.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { modalConst, socialAction } from 'redux/local/local.const';
import { setModal, setShareSocial } from 'redux/local/local.slice';
import { appointmentStatusEnum } from 'redux/appointment/appointment.const';

import { pagesInfo } from 'utils/pagesInfo';
import { cn } from 'utils/cn.utils';
import { getCustomHostPath } from 'utils/generators.utils';
import { EDULYTE_WEB_DOMAIN_URL } from 'const/default.const';
import useCustomNavigation from 'hooks/useCustomNavigation';

const SideBarHeader = ({ activeTab, setActiveTab }) => {
    const { modal, shareSocial } = useAppState((state) => state.local)
    const { publicOrgDetail } = useAppState((state) => state.org)
    const { modifyAppointmentDetail } = useAppState((state) => state.appointment)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const customNavigation = useCustomNavigation()

    const [isUrlCopied, setIsUrlCopied] = useState(false)

    const PUBLIC_APPOINTMENT_DETAIL_URL = useMemo(() => (
        // `${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `${pagesInfo.APPOINTMENT.pagePath}/${modifyAppointmentDetail?.payload?.slug}` })}`
        customNavigation.getCustomBaseUrl({ path: `${pagesInfo.APPOINTMENT.pagePath}/${modifyAppointmentDetail?.payload?.slug}`, isWeb: true })
    ), [modifyAppointmentDetail?.payload?.slug]);
    const isAppointmentPublished = useMemo(() => [appointmentStatusEnum.PUBLISHED.value]?.includes(modifyAppointmentDetail?.payload?.status), [modifyAppointmentDetail?.payload?.status])

    const onHandleGoBack = useCallback(() => {
        navigate(-1)
    }, [])

    const onHandleShareAppointment = useCallback(() => {
        if (!isAppointmentPublished) return;

        dispatcher(setShareSocial({
            ...shareSocial,
            socialAction: socialAction.SHARE_APPOINTMENT,
            url: PUBLIC_APPOINTMENT_DETAIL_URL
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.SHARE_SOCIAL_MODAL.stateKey]: true
        }))
    }, [modal, shareSocial, modifyAppointmentDetail?.payload, isAppointmentPublished])

    const onHandleCopyLink = useCallback(() => {
        if (!isAppointmentPublished) return;

        navigator.clipboard.writeText(PUBLIC_APPOINTMENT_DETAIL_URL);
        setIsUrlCopied(true)
        toast.success("Copied")
        setTimeout(() => {
            setIsUrlCopied(false)
        }, 2000)
    }, [modifyAppointmentDetail?.payload, isUrlCopied, isAppointmentPublished])

    const onHandleUpdateStatus = useCallback((selectedStatus) => {
        if (modifyAppointmentDetail?.isLoading) return;
        dispatcher(updateAppointmentDetail(modifyAppointmentDetail?.payload?.id, { status: selectedStatus?.value }))
    }, [modifyAppointmentDetail])

    return (
        <div className={"p-0 pt-5 px-5 h-52 md:h-40 md:p-5 space-y-5 bg-white border-b border-text-300 sticky top-0 z-30"}>
            <div className={"w-full flex items-center justify-between gap-3 md:gap-5"}>
                <div className={"flex items-center justify-start gap-2"}>
                    <ToolTipView content={"Back"}>
                        <button className={"p-2 rounded-full hover:bg-divider-darkLight cursor-pointer"} onClick={onHandleGoBack}>
                            <IoMdArrowRoundBack className={'text-lg md:text-xl text-back-ground-dark'} />
                        </button>
                    </ToolTipView>
                    <Link to={`${pagesInfo.TUTOR_PRODUCTS.pagePath}${pagesInfo.APPOINTMENT_LIST.pagePath}`} className={"group"}>
                        <span className={"font-bodyPri font-normal text-text-900 text-base underline tracking-wide line-clamp-1 group-hover:text-secondary-dark group-hover:underline cursor-pointer"}>
                            {"Appointment page"}
                        </span>
                    </Link>
                </div>
                <div className={"flex items-center justify-start gap-2"}>
                    <ToolTipView content={isAppointmentPublished ? "Share" : "Publish to share"}>
                        <button
                            className={cn(
                                "p-2 rounded-md hover:bg-divider-darkLight cursor-pointer",
                                !isAppointmentPublished && "!cursor-not-allowed"
                            )}
                            onClick={onHandleShareAppointment}
                        // disabled={!isAppointmentPublished}
                        >
                            <MdIosShare className={cn("text-2xl text-text-800", !isAppointmentPublished && "!text-text-400")} />
                        </button>
                    </ToolTipView>
                    <ToolTipView content={isAppointmentPublished ? "Copy Link" : "Publish for live link"}>
                        <button
                            className={cn(
                                "p-2 rounded-md hover:bg-divider-darkLight cursor-pointer",
                                !isAppointmentPublished && "!cursor-not-allowed"
                            )}
                            onClick={onHandleCopyLink}
                        // disabled={!isAppointmentPublished}
                        >
                            <AiOutlineLink className={cn("text-2xl text-text-800", !isAppointmentPublished && "!text-text-400")} />
                        </button>
                    </ToolTipView>
                    <StatusButton
                        isLoading={modifyAppointmentDetail?.isLoading}
                        loaderBtnClassName={`bg-${appointmentStatusEnum[modifyAppointmentDetail?.payload?.status?.toUpperCase()]?.darkColor}`}
                        selectedBtnClassName={`text-white bg-${appointmentStatusEnum[modifyAppointmentDetail?.payload?.status?.toUpperCase()]?.darkColor}`}
                        dropdownBtnClassName={`text-white bg-${appointmentStatusEnum[modifyAppointmentDetail?.payload?.status?.toUpperCase()]?.darkColor}`}
                        optionsList={Object.values(appointmentStatusEnum)?.filter((statusItem) => (statusItem?.value != modifyAppointmentDetail?.payload?.status))}
                        selectedOption={appointmentStatusEnum[modifyAppointmentDetail?.payload?.status?.toUpperCase()]?.label}
                        onHandleUpdateStatus={onHandleUpdateStatus}
                    />
                </div>
            </div>
            <div className={"space-y-2"}>
                <span className={"font-bold text-lg sm:text-xl md:text-2xl lg:text-3xl text-text-900 tracking-wide line-clamp-1"}>
                    {modifyAppointmentDetail?.payload?.title}
                </span>
                <span className={"font-medium text-sm md:text-base lg:text-md text-text-600 tracking-wide line-clamp-1"}>
                    {modifyAppointmentDetail?.payload?.subtitle}
                </span>
            </div>
            <div className={"flex md:hidden"}>
                <TabView
                    tabs={Object.values(appointmentTabConst)}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />
            </div>
        </div>
    )
}

export default memo(SideBarHeader);