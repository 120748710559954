import { useEffect } from 'react';
import { cn } from "utils/cn.utils";

import ComponentLoader from 'components/loader/ComponentLoader';

import { getAvailableSlotDetail } from 'redux/availability/availability.request';
import { getSelectedPublicCourseDetail } from 'redux/course/course.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { resetAvailableSlotDetail } from 'redux/availability/availability.slice';
import { resetSelectedPublicCourseDetail } from 'redux/course/course.slice';
import { setAddBookingDetailPayload } from 'redux/booking/booking.slice';
import { modalConst } from 'redux/local/local.const';
import { availabilityIsRecurringEnum, availabilityEndTypeEnum } from 'redux/availability/availability.const';
import { courseType } from 'redux/course/course.const';
import { bookingOfferingTypeEnum } from 'redux/booking/booking.const';

import { getTimeZoneOffset, dayjs, timeZone } from 'utils/dateTime.utils';

const DayItems = ({ localDay, timeSlots }) => {

    const timeZoneOffset = getTimeZoneOffset(timeZone)

    return timeSlots?.length > 0 && (
        <div className={cn(
            "col-span-1 flex flex-col justify-start items-center gap-1 py-2 w-32 h-full",
            "border-2 border-divider-medium rounded-lg bg-primary-light"
        )}>
            <span className={"font-bodyPri font-medium text-base text-primary-dark"}>
                {localDay}
            </span>
            <div className={"flex flex-col items-center gap-1"}>
                {(timeSlots?.length > 0) && timeSlots?.map((timeSlot, index) => (
                    <span key={index} className={"font-bodyPri font-medium text-base text-primary-dark"}>
                        {dayjs(dayjs().tz(timeZone).format("YYYY-MM-DD") + " " + timeSlot?.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("hh:mm A")}
                    </span>
                ))}
            </div>
        </div>
    )
}

const DateTimeItems = ({ dateTimeSlot }) => {

    const timeZoneOffset = getTimeZoneOffset(timeZone)

    return dateTimeSlot?.timeSlots?.length > 0 && (
        <div className={cn(
            "col-span-1 flex flex-col justify-start items-center gap-1 py-2 w-32 h-full",
            "border-2 border-divider-medium rounded-lg bg-primary-light"
        )}>
            <span className={"font-bodyPri font-medium text-base text-primary-dark"}>
                {dayjs(dateTimeSlot?.date + " " + dateTimeSlot?.timeSlots[0]?.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("ddd, DD MMM YY")}
            </span>
            <div className={"flex flex-col items-center gap-1"}>
                {dateTimeSlot?.timeSlots.map((timeSlot, index) => (
                    <span key={index} className={"font-bodyPri font-medium text-base text-primary-dark"}>
                        {dayjs(dateTimeSlot?.date + " " + timeSlot?.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("hh:mm A")}
                    </span>
                ))}
            </div>
        </div>
    )
}


const CourseScheduleModal = () => {
    const { modal } = useAppState((s) => s.local)
    const { availableSlotDetail } = useAppState(s => s.availability)
    const { selectedPublicCourseDetail } = useAppState((s) => s.course)
    const { addBookingDetail } = useAppState((s) => s.booking)

    const dispatcher = useAppDispatcher()
    const timeZoneOffset = getTimeZoneOffset(timeZone)

    useEffect(() => {
        return () => {
            dispatcher(resetSelectedPublicCourseDetail())
            dispatcher(resetAvailableSlotDetail())
        }
    }, [])

    useEffect(() => {
        if (addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.COHORT.value) {
            if (selectedPublicCourseDetail?.data) {
                if (selectedPublicCourseDetail?.data?.type === courseType.GROUP.value) {
                    let payload = {
                        ...addBookingDetail?.payload,
                        offering: selectedPublicCourseDetail?.data,
                        sessionCount: selectedPublicCourseDetail?.data?.coursePrice?.sessionCount
                    }
                    dispatcher(setAddBookingDetailPayload(payload))
                    dispatcher(getAvailableSlotDetail(selectedPublicCourseDetail?.data?.courseAvailability?.availabilityId, { timeZone: timeZone }))
                }
            } else {
                dispatcher(getSelectedPublicCourseDetail(addBookingDetail?.payload?.slug))
            }
        }
    }, [selectedPublicCourseDetail?.data])

    const handleNext = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.COURSE_SCHEDULE_MODAL.stateKey]: false,
            [modalConst.COURSE_CHECKOUT_MODAL.stateKey]: true,
        }))
    }

    if (selectedPublicCourseDetail?.isLoading) {
        return (
            <ComponentLoader
                isLoading={selectedPublicCourseDetail?.isLoading}
                className={"min-h-[24rem] flex justify-center items-center"}
            />
        )
    }

    if (selectedPublicCourseDetail?.message) {
        return (
            <div className={"min-h-[24rem] flex justify-center items-center"}>
                <span className={"font-bodyPri font-medium text-md text-text-700"}>
                    {selectedPublicCourseDetail?.message}
                </span>
            </div>
        )
    }

    return (
        <div className={"space-y-5"}>
            <div className={"min-h-[24rem] flex flex-col gap-5 overflow-auto"}>
                <div className={"w-full flex items-center justify-center"}>
                    <span className={"truncate text-center font-bodyPri font-medium text-lg text-text-900"}>
                        {addBookingDetail?.payload?.offering?.title.substring(0, 50) + (addBookingDetail?.payload?.offering?.title.length > 50 ? "..." : "")}
                    </span>
                </div>
                <div className={"self-center gap-1 p-3 rounded-lg bg-primary-light border-2 border-divider-medium"}>
                    <div className={cn(
                        "flex items-center justify-evenly gap-1",
                        "font-bodyPri font-normal text-sm text-primary-dark"
                    )}>
                        <span>{"Starts on"}</span>
                        <span>{"  "}</span>
                        <span>{"Ends on"}</span>
                    </div>
                    <div className={cn(
                        "flex items-center justify-evenly gap-1",
                        "font-bodyPri font-medium text-base text-primary-dark"
                    )}>
                        {(addBookingDetail?.payload?.offering?.type === courseType.GROUP.value) && availableSlotDetail?.data?.result?.startDateTime &&
                            <div className={"flex flex-row items-start justify-start gap-2"}>
                                <span className={"font-bodyPri font-medium text-base"}>
                                    {dayjs(availableSlotDetail?.data?.result?.startDateTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format('ddd, MMM DD YYYY')}
                                </span>
                                {(availableSlotDetail?.data?.result?.endType === availabilityEndTypeEnum.ON_DATE.value)
                                    && availableSlotDetail?.data?.result?.endDateTime &&
                                    <>
                                        <span className={"font-bodyPri font-medium text-base"}>{"-"}</span>
                                        <span className={"font-bodyPri font-medium text-base"}>
                                            {dayjs(availableSlotDetail?.data?.result?.endDateTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format('ddd, MMM DD YYYY')}
                                        </span>
                                    </>
                                }
                                {availableSlotDetail?.data?.result?.endType === availabilityEndTypeEnum.WEEKLY_CYCLE.value &&
                                    <>
                                        <span className={"font-bodyPri font-medium text-base"}>{"-"}</span>
                                        <span className={"font-bodyPri font-medium text-base"}>
                                            {`Repeat ${availableSlotDetail?.data?.result?.weeklyCycle} weeks`}
                                        </span>
                                    </>
                                }
                                {availableSlotDetail?.data?.result?.endType === availabilityEndTypeEnum.FOREVER.value &&
                                    <>
                                        <span className={"font-bodyPri font-medium text-base"}>{"-"}</span>
                                        <span className={"font-bodyPri font-medium text-base"}>
                                            {"Never Ends"}
                                        </span>
                                    </>
                                }
                            </div>
                        }
                    </div>
                </div>
                {((addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.APPOINTMENT.value)
                    || (addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.COHORT.value
                        && (addBookingDetail?.payload?.offering?.type === courseType.ONE_ON_ONE.value))) &&
                    <div className={cn(
                        "grid grid-cols-2 gap-2 px-1 pb-3 overflow-hidden overflow-y-scroll",
                        "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                        "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                    )}>
                        {addBookingDetail?.payload?.schedule?.map((dateTimeSlot, index) => (
                            <DateTimeItems key={index} dateTimeSlot={dateTimeSlot} />
                        ))}
                    </div>
                }
                {(addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.COHORT.value
                    && addBookingDetail?.payload?.offering?.type === courseType.GROUP.value
                    && availableSlotDetail?.data?.result?.isRecurring === availabilityIsRecurringEnum?.NON_RECURRING.value) &&
                    <div className={cn(
                        "grid grid-cols-2 gap-2 px-1 pb-3 overflow-hidden overflow-y-scroll",
                        "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                        "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                    )}>
                        {availableSlotDetail?.data?.result?.availableSlot?.map((dateTimeSlot, index) => (
                            <DateTimeItems key={index} dateTimeSlot={dateTimeSlot} />
                        ))}
                    </div>
                }
                {((addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.COHORT.value)
                    && (addBookingDetail?.payload?.offering?.type === courseType.GROUP.value)
                    && (availableSlotDetail?.data?.result?.isRecurring === availabilityIsRecurringEnum?.RECURRING.value)) &&
                    <div className={cn(
                        "grid grid-cols-2 gap-2 px-1 pb-3 overflow-hidden overflow-y-scroll",
                        "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                        "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                    )}>
                        {Object.keys(availableSlotDetail?.data?.result?.availableSlot).map((localDay, index) => (
                            <DayItems key={index} localDay={localDay} timeSlots={availableSlotDetail?.data?.result?.availableSlot[localDay]} />
                        ))}
                    </div>
                }
            </div>

            <div className={"flex justify-end"}>
                <span onClick={handleNext} className={cn(
                    "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                    "font-buttons font-normal text-base text-secondary-main",
                    "border border-secondary-main hover:bg-secondary-main hover:text-text-50"
                )}>
                    {"Next"}
                </span>
            </div>
        </div>
    )
}

export default CourseScheduleModal