import { motion } from "framer-motion";

import ToolTipView from "components/tooltipView";
import ButtonLoader from "components/loader/button-loader";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { createUserCheckoutSession, createUserCustomerPortalSession } from "redux/payment/payment.request";

import { cn } from 'utils/cn.utils';
import { subscriptionStatusEnum, subscriptionPlanEnum } from "redux/subscription/subscription.const";
import { currencyRateConversion, getCustomHostPath } from "utils/generators.utils";
import useCustomNavigation from "hooks/useCustomNavigation";

const PriceCard = ({
    plan,
    isLoading,
    message
}) => {
    const locals = useAppState(state => state.local.locals)
    const { publicOrgDetail } = useAppState((state) => state.org)
    const session = useAppState(state => state.auth.session)
    const user = useAppState(state => state.user.user)
    const addUserCustomerPortalSession = useAppState(s => s.payment.createUserCustomerPortalSession)
    const addUserCheckoutSession = useAppState(s => s.payment.createUserCheckoutSession)

    const productPrice = plan?.productPrice
    const price = productPrice?.price_items[0]

    const dispatcher = useAppDispatcher()
    const customNavigation = useCustomNavigation()

    const user_subscriptions = user?.user?.user_subscriptions?.reduce((initial, user_subscription) => {
        if (!initial[user_subscription?.plan]) {
            initial[user_subscription?.plan] = user_subscription
        }
        return initial
    }, {})

    const isSubscribed = !!user?.user?.user_subscriptions?.length

    const isActive = session?.isLoggedIn && (
        (plan?.plan === subscriptionPlanEnum.free.key && !isSubscribed) ||
        (plan?.plan === productPrice?.metadata?.plan && [subscriptionStatusEnum.active.key, subscriptionStatusEnum.trialing.key].includes(user_subscriptions[plan?.plan]?.status) && plan?.interval === user_subscriptions[plan?.plan]?.interval)
    )

    const redirectLogin = () => {
        // window.open(`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, pathname: `/log-in?app_name=edulyte-web&redirect_url=${window.location?.href}` })}`, "_self")
        customNavigation.navigation({ path: `/log-in?app_name=edulyte-app&redirect_url=${window.location?.href}` })
    }

    const handleSubscribe = () => {
        if (!session?.isLoggedIn) {
            redirectLogin()
            return
        }

        const requestData = {
            userId: user?.user?.userId,
            prices: [
                {
                    price_id: price?.id,
                    quantity: 1,
                    adjustable_quantity: false
                }
            ]
        }
        dispatcher(createUserCheckoutSession(requestData))

    }

    const handleGotoPortal = () => {
        if (!isActive) return

        dispatcher(createUserCustomerPortalSession({ "userId": user?.user?.userId }))
    }

    return (
        <div className={cn(
            'relative overflow-hidden min-h-full min-w-full sm:min-w-[30rem] md:min-w-[20rem] w-full p-5 rounded border hover:bg-primary-light hover:bg-opacity-70 ease-in-out duration-500 space-y-3',
            plan?.isFocus && "border-primary-dark"
        )}>
            {!!isLoading
                ? (
                    <>
                        <div className='space-y-1 mb-2'>
                            <p className='bg-slate-300 animate-pulse w-60 h-8'></p>
                            <p className='bg-slate-300 animate-pulse w-48 h-3'></p>
                        </div>
                        <div className='space-y-1 mb-2'>
                            <p className='bg-slate-300 animate-pulse w-60 h-8'></p>
                        </div>
                        <button className="w-48 h-14 bg-slate-300 animate-pulse"></button>
                    </>
                ) : !!message
                    ? (
                        <div className={"space-y-1 font-tagLine"}>
                            <h3 className="text-xl font-medium text-red-500">{message}</h3>
                        </div>
                    ) : (plan?.plan === subscriptionPlanEnum.free.key)
                        ? (
                            <>
                                <div className={"space-y-1"}>
                                    <h2 className="text-xl font-semibold font-subHeading text-primary-dark flex items-center justify-start gap-2">
                                        <span>
                                            {plan?.title}
                                        </span>
                                        {isActive && (
                                            <span className={"text-accent-main text-xs"}>({"Active"})</span>
                                        )}
                                    </h2>
                                    <p className={"font-tagLine text-sm text-text-900"}>{plan?.description}</p>
                                </div>
                                <div className='space-y-1 font-tagLine mb-2'>
                                    <p className='text-3xl font-bold'>
                                        {"Free"}
                                    </p>
                                </div>
                                <motion.button
                                    whileHover={{ scale: session?.isLoggedIn ? 1 : 1.05 }}
                                    whileTap={{ scale: session?.isLoggedIn ? 1 : 0.95 }}
                                    className={cn(
                                        "w-48 h-14 ease-in-out duration-300 border border-primary-main rounded cursor-pointer shadow",
                                        "font-buttons font-semibold text-lg text-primary-main",
                                        session?.isLoggedIn && "border-text-400 text-text-600 cursor-not-allowed"
                                    )}
                                    disabled={session?.isLoggedIn}
                                    onClick={handleSubscribe}
                                >
                                    {"Get Started"}
                                </motion.button>
                            </>
                        ) : (
                            <>
                                <div className={"space-y-1"}>
                                    <h2 className="text-xl font-semibold font-subHeading text-primary-dark flex items-center justify-start gap-2">
                                        <span>
                                            {productPrice?.name}
                                        </span>
                                        {isActive && (
                                            <span className={"text-accent-main text-xs"}>({"Active"})</span>
                                        )}
                                    </h2>
                                    {productPrice?.description && (
                                        <p className={"font-tagLine text-sm text-text-900"}>{productPrice?.description}</p>
                                    )}
                                </div>
                                <div className='space-y-1 font-tagLine mb-2'>
                                    <p className='text-3xl font-bold'>{currencyRateConversion(locals?.currencyCode, price?.unit_amount)}<span className='text-lg'>/{plan?.interval}</span></p>
                                    {/* <p className='text-3xl font-bold'>{`${price?.currency?.toUpperCase()} ${parseFloat(price?.unit_amount / 100).toFixed(2)}`}<span className='text-lg'>/{plan?.interval}</span></p> */}
                                </div>
                                <motion.button
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    className={cn(
                                        "w-48 h-14 ease-in-out duration-300 bg-primary-dark  rounded cursor-pointer shadow",
                                        "font-buttons font-semibold text-lg text-white",
                                        (addUserCustomerPortalSession?.isLoading || addUserCheckoutSession?.isLoading) && "cursor-wait"
                                    )}
                                    onClick={isSubscribed ? handleGotoPortal : handleSubscribe}
                                    disabled={(addUserCustomerPortalSession?.isLoading || addUserCheckoutSession?.isLoading)}
                                >
                                    {(addUserCustomerPortalSession?.isLoading || addUserCheckoutSession?.isLoading)
                                        ? <ButtonLoader />
                                        : isSubscribed
                                            ? isActive
                                                ? "Manage"
                                                : "Upgrade"
                                            : "Subscribe"
                                    }
                                </motion.button>
                                {(addUserCustomerPortalSession?.message || addUserCheckoutSession?.message) && (
                                    <div className={"font-bodyPri font-normal text-xs text-secondary-dark text-left"}>
                                        {(addUserCustomerPortalSession?.message || addUserCheckoutSession?.message)}
                                    </div>
                                )}
                            </>
                        )
            }
            <div className='mt-3 space-y-1'>
                {plan?.features?.map((item, index) => (
                    <ul key={index} className='w-full flex items-center justify-start gap-3 font-bulletPoints'>
                        <span className='text-primary-dark'>{"✓"}</span>
                        <ToolTipView className={"p-3"} content={item.tooltip} disabled={!item.tooltip}>
                            <li className={cn("flex-1 cursor-help",
                                item.tooltip && "hover:underline"
                            )}>
                                {item.item}
                            </li>
                        </ToolTipView>
                    </ul>
                ))}
            </div>
            {
                plan?.isPopular && (
                    <div className='absolute top-5 -right-14'>
                        <div className='flex items-center justify-center bg-primary-dark text-white w-full px-14 py-2 rotate-45'>
                            {'Most Popular'}
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default PriceCard;