import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { cn } from "utils/cn.utils";

import { FiEdit } from 'react-icons/fi';
import { MdGroups } from 'react-icons/md';
import { IoMdPeople } from 'react-icons/io';
import { FaInfoCircle } from 'react-icons/fa';
import { FaSpinner } from 'react-icons/fa';

import ComponentLoader from 'components/loader/ComponentLoader';

import { getSelectedPublicCourseDetail } from 'redux/course/course.request';
import { getAvailableSlotDetail } from 'redux/availability/availability.request';
import { createBookingDetail } from 'redux/booking/booking.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal, setLocals } from 'redux/local/local.slice';
import { resetSelectedPublicCourseDetail } from 'redux/course/course.slice';
import { setAddBookingDetailPayload, resetBooking_Detail } from 'redux/booking/booking.slice';
import { resetAvailableSlotDetail } from 'redux/availability/availability.slice';
import { bookingOfferingTypeEnum, bookingStatusEnum } from 'redux/booking/booking.const';
import { courseType, coursePricingModelTypeEnum } from 'redux/course/course.const';
import { appointmentPriceModelEnum } from 'redux/appointment/appointment.const';
import { availabilityIsRecurringEnum, availabilityEndTypeEnum } from 'redux/availability/availability.const';
import { modalConst, localsConst } from 'redux/local/local.const';
import { userRoles } from 'redux/auth/auth.const';

import { dayjs, timeZone, getTimeZoneOffset } from 'utils/dateTime.utils';
import { currencyRateConversion } from 'utils/generators.utils';
import { pagesInfo } from 'utils/pagesInfo';

const DayItems = ({ localDay, timeSlots }) => {

    const timeZoneOffset = getTimeZoneOffset(timeZone)

    return timeSlots?.length > 0 && (
        <div className={cn(
            "col-span-1 flex flex-col justify-start items-center gap-1 py-2 w-32 h-full",
            "border-2 border-divider-medium rounded-lg bg-primary-light"
        )}>
            <span className={"font-bodyPri font-medium text-base text-primary-dark"}>
                {localDay}
            </span>
            <div className={"flex flex-col items-center gap-1"}>
                {timeSlots?.length > 0 && timeSlots?.map((timeSlot, index) => (
                    <span key={index} className={"font-bodyPri font-medium text-base text-primary-dark"}>
                        {dayjs(dayjs().tz(timeZone).format("YYYY-MM-DD") + " " + timeSlot?.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("hh:mm A")}
                    </span>
                ))}
            </div>
        </div>
    )
}

const DateTimeItems = ({ dateTimeSlot }) => {

    const timeZoneOffset = getTimeZoneOffset(timeZone)

    return dateTimeSlot?.timeSlots?.length > 0 && (
        <div className={cn(
            "col-span-1 flex flex-col justify-start items-center gap-1 py-2 w-32 h-full",
            "border-2 border-divider-medium rounded-lg bg-primary-light"
        )}>
            <span className={"font-bodyPri font-medium text-base text-primary-dark"}>
                {dayjs(dateTimeSlot?.date + " " + dateTimeSlot?.timeSlots[0]?.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("ddd, DD MMM YY")}
            </span>
            <div className={"flex flex-col items-center gap-1"}>
                {dateTimeSlot?.timeSlots.map((timeSlot, index) => (
                    <span key={index} className={"font-bodyPri font-medium text-base text-primary-dark"}>
                        {dayjs(dateTimeSlot?.date + " " + timeSlot?.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("hh:mm A")}
                    </span>
                ))}
            </div>
        </div>
    )
}

const CourseCheckoutModal = () => {
    const { modal, locals } = useAppState(s => s.local)
    const { user } = useAppState(s => s.user)
    const { session } = useAppState(s => s.auth)
    const { selectedPublicCourseDetail } = useAppState(s => s.course)
    const { addBookingDetail, booking_Detail } = useAppState(s => s.booking)
    const { availableSlotDetail } = useAppState(s => s.availability)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    useEffect(() => {
        return () => {
            dispatcher(resetSelectedPublicCourseDetail())
            dispatcher(resetAvailableSlotDetail())
        }
    }, [])

    useEffect(() => {
        if (addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.COHORT.value) {
            if (selectedPublicCourseDetail?.data) {
                if (selectedPublicCourseDetail?.data?.type === courseType.GROUP.value) {
                    let payload = {
                        ...addBookingDetail?.payload,
                        offering: selectedPublicCourseDetail?.data,
                        sessionCount: selectedPublicCourseDetail?.data?.coursePrice?.sessionCount
                    }
                    dispatcher(setAddBookingDetailPayload(payload))
                    dispatcher(getAvailableSlotDetail(selectedPublicCourseDetail?.data?.courseAvailability?.availabilityId, { timeZone: timeZone }))
                }
            } else {
                dispatcher(getSelectedPublicCourseDetail(addBookingDetail?.payload?.slug))
            }
        }
    }, [selectedPublicCourseDetail?.data])

    useEffect(() => {
        if (booking_Detail?.data?.result) {
            let bookingId = booking_Detail?.data?.result?.id
            const bookingStatus = booking_Detail?.data?.result?.status
            dispatcher(setModal({
                ...modal,
                [modalConst.COURSE_CHECKOUT_MODAL.stateKey]: false
            }))
            dispatcher(resetBooking_Detail())
            if ([bookingStatusEnum.CONFIRMED.value]?.includes(bookingStatus)) {
                navigate(`${pagesInfo.USER_FINANCE.pagePath}${pagesInfo.BOOKINGS.pagePath}?actionBookingId=${bookingId}`)
            } else {
                navigate(`${pagesInfo?.CHECKOUT_BOOKING?.pagePath}/${bookingId}`)
            }
        }
    }, [booking_Detail?.data?.result])

    let isMyOffering = false;
    if (session?.isLoggedIn && (addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.COHORT.value)) {
        isMyOffering = user?.user?.userId === addBookingDetail?.payload?.offering?.tutor?.user?.userId
    } else if (session?.isLoggedIn && (addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.APPOINTMENT.value)) {
        isMyOffering = user?.user?.userId === addBookingDetail?.payload?.offering?.user?.id
    }

    let isMyCourse = false;
    if (session?.isLoggedIn
        && (addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.COHORT.value)
        && (addBookingDetail?.payload?.offering?.type === courseType.GROUP.value)) {
        isMyCourse = user?.user?.myCourses?.includes(addBookingDetail?.payload?.offering?.id)
    }

    const handleCheckout = async () => {
        if (!session?.isLoggedIn || !user?.user) {
            dispatcher(setModal({
                ...modal,
                [modalConst.COURSE_CHECKOUT_MODAL.stateKey]: false,
            }))
            navigate(`${pagesInfo?.LOG_IN?.pagePath}?redirect_url=${window.location.href}`)
            return;
        }

        if (session?.isLoggedIn && (user?.user && (locals.userRole === userRoles?.TUTOR?.value))) {
            if (!window.confirm("To proceed, please switch to the learner role")) return;
            dispatcher(setLocals({
                ...locals,
                [localsConst.USER_ROLE.value]: userRoles?.STUDENT?.value
            }))

        }

        if (addBookingDetail?.isLoading) return;

        let payload = {
            user_id: user?.user?.userId,
            offering_type: addBookingDetail?.payload?.offeringType,
        }

        if (addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.APPOINTMENT.value) {
            payload = {
                ...payload,
                appointment: {
                    appointment_id: addBookingDetail?.payload?.appointmentId,
                    timeZone: timeZone,
                    schedule: addBookingDetail?.payload?.schedule,
                }
            }
        }
        dispatcher(createBookingDetail(payload))
    }

    const editChooseTimeSlot = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.COURSE_CHECKOUT_MODAL.stateKey]: false,
            [modalConst.CHOOSE_TIME_SLOT_MODAL.stateKey]: true,
        }))
    }

    const editNoOfSession = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.COURSE_CHECKOUT_MODAL.stateKey]: false,
            [modalConst.NO_SESSION_MODAL.stateKey]: true,
        }))
    }

    if (selectedPublicCourseDetail?.isLoading) {
        return (
            <ComponentLoader
                isLoading={selectedPublicCourseDetail?.isLoading}
                className={"min-h-[24rem] flex justify-center items-center"}
            />
        )
    }

    if (selectedPublicCourseDetail?.message) {
        return (
            <div className={"min-h-[24rem] flex justify-center items-center"}>
                <span className={"font-bodyPri font-medium text-md text-text-700"}>
                    {selectedPublicCourseDetail?.message}
                </span>
            </div>
        )
    }

    const getUser = () => {
        if (addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.COHORT.value) {
            return addBookingDetail?.payload?.offering?.tutor?.user
        } else if (addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.APPOINTMENT.value) {
            return addBookingDetail?.payload?.offering?.user
        }
    }

    const HeaderSection = () => {
        return (
            <div className={"grid grid-cols-12"}>
                <div className={"w-full col-start-1 col-span-9 flex flex-col items-start gap-1"}>
                    <span className={"w-full self-start truncate text-start font-bodyPri font-medium text-base text-text-900"}>
                        {addBookingDetail?.payload?.offering?.title?.substring(0, 50) + (addBookingDetail?.payload?.offering?.title?.length > 50 ? "..." : "")}
                    </span>
                    <span className={"w-full self-end truncate text-end font-bodyPri font-normal text-sm text-text-800"}>
                        {getUser()?.firstName + " " + getUser()?.lastName?.charAt(0) + "."}
                    </span>
                </div>
                <div className={"col-start-10 col-span-3"}>
                    <div className={"w-full flex items-center justify-end"}>
                        <div className={'relative w-12 h-12 rounded-full'}>
                            <img src={getUser()?.profilePicUrl} alt={"profile-pic"}
                                className="w-full h-full rounded-full object-cover"
                            />
                            <div className='absolute right-0.5 bottom-0.5 w-3 h-3 rounded-full overflow-hidden border-white'>
                                <img src={`https://flagcdn.com/16x12/${getUser()?.fromCountry?.countryDomain}.png`.toLowerCase()} alt={"country-flag"}
                                    className='w-full h-full object-cover'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const getSessionCountText = () => {
        let sessionCountText = ""
        if (((addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.APPOINTMENT.value)
            || ((addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.COHORT.value)
                && (addBookingDetail?.payload?.offering?.type === courseType.ONE_ON_ONE.value)))) {
            sessionCountText = addBookingDetail?.payload?.sessionCount + " session(s)"
        } else if ((addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.COHORT.value)
            && (addBookingDetail?.payload?.offering?.type === courseType.GROUP.value)) {
            if (addBookingDetail?.payload?.offering?.basicDetail?.isRecurring === "non_recurring") {
                sessionCountText = addBookingDetail?.payload?.offering?.basicDetail?.sessionCount + " session(s)"
            } else if (addBookingDetail?.payload?.offering?.basicDetail?.isRecurring === "recurring") {
                sessionCountText = addBookingDetail?.payload?.offering?.basicDetail?.sessionCount + " per week"
            }
        }
        return sessionCountText;
    }

    const getPriceUtils = () => {
        let priceUnitText = ""
        let purchasePrice = 0
        let priceText = ""
        let delPriceText = ""
        let discountPctText = ""

        if (addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.APPOINTMENT.value) {
            priceUnitText = appointmentPriceModelEnum[addBookingDetail?.payload?.offering?.appointment_price?.price_model.toUpperCase()]?.label
            let price = (addBookingDetail?.payload?.offering?.appointment_price?.price_model === appointmentPriceModelEnum.FREE.value) ? 0 : addBookingDetail?.payload?.offering?.appointment_price?.price || 0
            purchasePrice = (price - ((price * (addBookingDetail?.payload?.offering?.appointment_price?.discount_pct || 0)) / 100))
            priceText = (price === 0) ? "FREE" : `${addBookingDetail?.payload?.offering?.appointment_price?.masterCurrency?.code} ${parseFloat(purchasePrice / 100)?.toFixed(2)}`
            delPriceText = (price === 0) ? "" : `${addBookingDetail?.payload?.offering?.appointment_price?.masterCurrency?.code} ${parseFloat(price / 100)?.toFixed(2)}`
            discountPctText = (price === 0) ? "" : addBookingDetail?.payload?.offering?.appointment_price?.discount_pct + "% off"
        } else if ((addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.COHORT.value)
            && addBookingDetail?.payload?.offering?.type === courseType.ONE_ON_ONE.value) {
            priceUnitText = coursePricingModelTypeEnum[addBookingDetail?.payload?.offering?.coursePrice?.priceModel.toUpperCase()].label
            let price = addBookingDetail?.payload?.offering?.coursePrice?.price || 0
            if (addBookingDetail?.payload?.offering?.coursePrice?.priceModel === coursePricingModelTypeEnum.PER_HOUR.value) {
                price = ((price / 4) * (addBookingDetail?.payload?.duration / 15)) * addBookingDetail?.payload?.sessionCount
            } else if (addBookingDetail?.payload?.offering?.coursePrice?.priceModel === coursePricingModelTypeEnum.PER_SESSION.value) {
                price = price * addBookingDetail?.payload?.sessionCount
            }
            purchasePrice = (price - ((price * (addBookingDetail?.payload?.offering?.coursePrice?.discountPct || 0)) / 100))
            purchasePrice = addBookingDetail?.payload?.sessionCount < 5 ? purchasePrice : purchasePrice - ((purchasePrice * (addBookingDetail?.payload?.offering?.volumeDiscountPct || 0)) / 100)
            priceText = (price === 0) ? "FREE" : currencyRateConversion(locals?.currencyCode, purchasePrice)
            delPriceText = (price === 0) ? "" : currencyRateConversion(locals?.currencyCode, price)
            discountPctText = (price === 0) ? "" : addBookingDetail?.payload?.offering?.coursePrice?.discountPct + "% off" + (addBookingDetail?.payload?.sessionCount >= 5 && addBookingDetail?.payload?.offering?.volumeDiscountPct > 0 ? " + " + addBookingDetail?.payload?.offering?.volumeDiscountPct + "% off" : "")
        } else if (addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.COHORT.value
            && addBookingDetail?.payload?.offering?.type === courseType.GROUP.value) {
            priceUnitText = coursePricingModelTypeEnum[addBookingDetail?.payload?.offering?.coursePrice?.priceModel.toUpperCase()].label
            let price = (addBookingDetail?.payload?.offering?.coursePrice?.price || 0)
            if (addBookingDetail?.payload?.offering?.coursePrice?.priceModel === coursePricingModelTypeEnum.PER_HOUR.value) {
                price = ((price / 4) * (addBookingDetail?.payload?.offering?.basicDetail?.duration / 15)) * addBookingDetail?.payload?.sessionCount
            } else if (addBookingDetail?.payload?.offering?.coursePrice?.priceModel === coursePricingModelTypeEnum.PER_SESSION.value) {
                price = price * addBookingDetail?.payload?.sessionCount
            }
            purchasePrice = (price - ((price * (addBookingDetail?.payload?.offering?.coursePrice?.discountPct || 0)) / 100))
            priceText = (price === 0) ? "FREE" : currencyRateConversion(locals?.currencyCode, purchasePrice)
            delPriceText = (price === 0) ? "" : currencyRateConversion(locals?.currencyCode, price)
            discountPctText = (price === 0) ? "" : addBookingDetail?.payload?.offering?.coursePrice?.discountPct + "% off"
        }

        return { priceUnitText, purchasePrice, priceText, delPriceText, discountPctText }
    }

    const PriceSection = () => {

        let priceUtils = getPriceUtils()

        return (
            <div className={"w-full space-y-2"}>
                <div className={"flex flex-row items-center justify-between"}>
                    <span className={"font-bodyPri font-normal text-sm text-text-800"}>
                        {"No. of sessions"}
                    </span>
                    {((addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.COHORT.value)
                        && (addBookingDetail?.payload?.offering?.type === courseType.ONE_ON_ONE.value)) &&
                        <FiEdit onClick={editNoOfSession} className={"text-xl text-text-700 hover:text-text-800 cursor-pointer"} />
                    }
                    {((addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.COHORT.value)
                        && (addBookingDetail?.payload?.offering?.type === courseType.GROUP.value)) &&
                        <span className={"font-bodyPri font-medium text-base text-text-900"}>
                            {getSessionCountText()}
                        </span>
                    }
                    {(addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.APPOINTMENT.value) &&
                        <span className={"font-bodyPri font-medium text-base text-text-900"}>
                            {getSessionCountText()}
                        </span>
                    }
                </div>
                <div className={"flex items-center justify-between"}>
                    <div className={"flex flex-col items-start gap-2"}>
                        {((addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.COHORT.value)
                            && (addBookingDetail?.payload?.offering?.type === courseType.ONE_ON_ONE.value)) &&
                            <span className={"font-bodyPri font-medium text-base text-text-900"}>
                                {getSessionCountText()}
                            </span>
                        }
                        {((priceUtils?.delPriceText?.length > 0) && (priceUtils?.discountPctText !== "0% off")) &&
                            <span className={"font-bodyPri font-normal text-base text-text-700"}>
                                <del>{priceUtils?.delPriceText}</del>
                            </span>
                        }
                        {(priceUtils?.discountPctText !== "0% off") &&
                            <span className={"font-bodyPri font-normal text-base text-text-700"}>
                                {priceUtils?.discountPctText}
                            </span>
                        }
                    </div>
                    <div className={"bg-primary-light px-3 py-1 rounded flex flex-col gap-1 border-2 border-divider-medium"}>
                        <span className={"font-bodyPrim font-medium text-base text-primary-dark"}>
                            {(addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.APPOINTMENT.value)
                                ? `${addBookingDetail?.payload?.offering?.appointment_price?.masterCurrency?.code} ${parseFloat(priceUtils?.purchasePrice / 100)?.toFixed(2)}`
                                : currencyRateConversion(locals?.currencyCode, priceUtils?.purchasePrice)
                            }
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    const ScheduleSection = () => {

        const timeZoneOffset = getTimeZoneOffset(timeZone)

        return (
            <div className={"w-full space-y-2"}>
                <div className={"flex flex-row items-center justify-between"}>
                    <div className={"flex flex-row items-center justify-start gap-2"}>
                        <span className={"font-bodyPri font-normal font-base text-sm text-text-800"}>
                            {`Date and time (${dayjs().tz(timeZone).format("z")})`}
                        </span>
                        {((addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.COHORT.value)
                            && (addBookingDetail?.payload?.offering?.type === courseType.ONE_ON_ONE.value)
                            && (addBookingDetail?.payload?.sessionCount > 1)) &&
                            <div className={"relative has-tooltip cursor-pointer"}>
                                <FaInfoCircle className="inline text-md text-text-500 hover:text-text-700" />
                                <span
                                    className={cn(
                                        "tooltip w-40 px-2 py-0.5 font-bodyPri font-normal text-xs rounded shadow-lg bg-text-900 text-text-50 bottom-8 -translate-x-[50%] text-clip overflow-hidden"
                                    )}
                                >
                                    {"Schedule your first session now. Schedule remaining session(s) in booking menu after payment."}
                                </span>
                            </div>
                        }
                    </div>
                    {((addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.COHORT.value)
                        && (addBookingDetail?.payload?.offering?.type === courseType.ONE_ON_ONE.value)) &&
                        <FiEdit onClick={editChooseTimeSlot} className={"text-xl text-text-700 hover:text-text-800 cursor-pointer"} />
                    }
                </div>
                <div className={"flex flex-col gap-2"}>
                    {(addBookingDetail?.payload?.offering?.type === courseType.GROUP.value) && availableSlotDetail?.data?.result?.startDateTime &&
                        <div className={"flex flex-row items-start justify-start gap-2"}>
                            <span className={"font-bodyPri font-medium text-base text-text-900"}>
                                {dayjs(availableSlotDetail?.data?.result?.startDateTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format('ddd, MMM DD YYYY')}
                            </span>
                            {(availableSlotDetail?.data?.result?.endType === availabilityEndTypeEnum.ON_DATE.value)
                                && availableSlotDetail?.data?.result?.endDateTime &&
                                <>
                                    <span className={"font-bodyPri font-medium text-base text-text-700"}>{"-"}</span>
                                    <span className={"font-bodyPri font-medium text-base text-text-900"}>
                                        {dayjs(availableSlotDetail?.data?.result?.endDateTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format('ddd, MMM DD YYYY')}
                                    </span>
                                </>
                            }
                            {(availableSlotDetail?.data?.result?.endType === availabilityEndTypeEnum.WEEKLY_CYCLE.value) &&
                                <>
                                    <span className={"font-bodyPri font-medium text-base text-text-700"}>{"-"}</span>
                                    <span className={"font-bodyPri font-medium text-base text-text-900"}>
                                        {`Repeat ${availableSlotDetail?.data?.result?.weeklyCycle} weeks`}
                                    </span>
                                </>
                            }
                            {(availableSlotDetail?.data?.result?.endType === availabilityEndTypeEnum.FOREVER.value) &&
                                <>
                                    <span className={"font-bodyPri font-medium text-base text-text-700"}>{"-"}</span>
                                    <span className={"font-bodyPri font-medium text-base text-text-900"}>
                                        {"Never Ends"}
                                    </span>
                                </>
                            }
                        </div>
                    }
                    {((addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.APPOINTMENT.value)
                        || ((addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.COHORT.value)
                            && (addBookingDetail?.payload?.offering?.type === courseType.ONE_ON_ONE.value))) &&
                        <div className={cn(
                            "grid grid-flow-col gap-2 px-1 pb-3 overflow-hidden overflow-x-scroll",
                            "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-sm",
                            "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                        )}>
                            {addBookingDetail?.payload?.schedule?.map((dateTimeSlot, index) => (
                                <DateTimeItems key={index} dateTimeSlot={dateTimeSlot} />
                            ))}
                        </div>
                    }
                    {((addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.COHORT.value)
                        && (addBookingDetail?.payload?.offering?.type === courseType.GROUP.value)
                        && (availableSlotDetail?.data?.result?.isRecurring === availabilityIsRecurringEnum?.NON_RECURRING.value)) &&
                        <div className={cn(
                            "grid grid-flow-col gap-2 px-1 pb-3 overflow-hidden overflow-x-scroll",
                            "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-sm",
                            "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                        )}>
                            {availableSlotDetail?.data?.result?.availableSlot?.map((dateTimeSlot, index) => (
                                <DateTimeItems key={index} dateTimeSlot={dateTimeSlot} />
                            ))}
                        </div>
                    }
                    {((addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.COHORT.value)
                        && (addBookingDetail?.payload?.offering?.type === courseType.GROUP.value)
                        && (availableSlotDetail?.data?.result?.isRecurring === availabilityIsRecurringEnum?.RECURRING.value)) &&
                        <div className={cn(
                            "grid grid-flow-col gap-2 px-1 pb-3 overflow-hidden overflow-x-scroll",
                            "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-sm",
                            "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                        )}>
                            {Object.keys(availableSlotDetail?.data?.result?.availableSlot).map((localDay, index) => (
                                <DayItems key={index} localDay={localDay} timeSlots={availableSlotDetail?.data?.result?.availableSlot[localDay]} />
                            ))}
                        </div>
                    }
                </div>
            </div>
        )
    }

    return (
        <div className={"space-y-5"}>
            <div className={"min-h-[24rem] block space-y-3"}>
                <HeaderSection />

                <div className={"h-0.5 bg-divider-medium w-full rounded-full"}></div>

                <div className={"w-full space-y-2"}>
                    <div className={"flex flex-row items-center justify-between"}>
                        <span className={"font-bodyPri font-medium text-base text-text-900"}>
                            {((addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.APPOINTMENT.value)
                                || ((addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.COHORT.value)
                                    && (addBookingDetail?.payload?.offering?.type === courseType.ONE_ON_ONE.value)))
                                ? `${courseType.ONE_ON_ONE.label} (${availabilityIsRecurringEnum.NON_RECURRING.label})`
                                : `${courseType.GROUP.label} (${availabilityIsRecurringEnum[availableSlotDetail?.data?.result?.isRecurring?.toUpperCase()]?.label})`
                            }
                        </span>
                        {((addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.APPOINTMENT.value)
                            || ((addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.COHORT.value)
                                && addBookingDetail?.payload?.offering?.type === courseType.ONE_ON_ONE.value))
                            ? <IoMdPeople className={"text-xl text-text-800"} />
                            : <MdGroups className={"text-xl text-text-800"} />
                        }
                    </div>
                </div>

                {/* <div className={"h-0.5 bg-divider-medium w-full rounded-full"}></div> */}

                <ScheduleSection />

                <div className={"h-0.5 bg-divider-medium w-full rounded-full"}></div>

                <PriceSection />
            </div>
            {!(isMyOffering || isMyCourse) &&
                <>
                    <div className={"w-full h-10"}>
                        {addBookingDetail?.message &&
                            <div className={"flex items-center justify-start"}>
                                <span className={"font-bodyPri font-medium text-base text-red-500 text-center"}>
                                    {addBookingDetail?.message}
                                </span>
                            </div>
                        }
                        {addBookingDetail?.isLoading &&
                            <div className={"flex items-center justify-start gap-2 whitespace-nowrap"}>
                                <span className={"font-bodyPri font-normal text-base text-text-700 whitespace-nowrap"}>
                                    {"Loading ..."}
                                </span>
                                <FaSpinner className="inline-flex text-primary-main animate-spin" />
                            </div>
                        }
                    </div>
                    <div className={"flex justify-end"}>
                        <span onClick={handleCheckout} className={cn(
                            "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                            "font-buttons font-normal text-base text-secondary-main",
                            "border border-secondary-main hover:bg-secondary-main hover:text-text-50",
                            addBookingDetail?.isLoading && "text-secondary-light border-secondary-light hover:bg-white hover:text-secondary-light"
                        )}>
                            {"Checkout"}
                        </span>
                    </div>
                </>
            }
        </div>
    )
}

export default CourseCheckoutModal