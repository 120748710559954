
import { MdAdd } from 'react-icons/md';

import { cn } from 'utils/cn.utils';

const AddButtonWithIcon = ({ btnTitle = "", onHandleAddBtn, btnClassName }) => {

    return (
        <div
            className={cn(
                "w-fit flex items-center justify-center cursor-pointer",
                "border border-divider-lightDark rounded-md px-2 py-1",
                "font-bodyPri font-medium text-base text-text-700",
                "hover:text-text-900 hover:border-divider-dark",
                btnClassName
            )}
            onClick={onHandleAddBtn}
        >
            <MdAdd className={"text-xl"} />
            <span className={"whitespace-nowrap"}>
                {btnTitle}
            </span>
        </div>
    )
}

export default AddButtonWithIcon