import { DEFAULT_BOOKING_PAGE, DEFAULT_BOOKING_RECORDS } from "redux/booking/booking.const";

export const pageHeading = {
    heading: "My Bookings",
    subHeading: "Browse all your bookings and take actions!",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/bookings.svg"
}

export const myBookingToolTip = "View and manage all your bookings."

export const tHeaders = [
    "Booking ID",
    "Session Count",
    "User",
    "Offering Type",
    "Price",
    "Status",
    "Created At",
    "Actions",
];

export const sHeaders = [
    "Booking ID",
    "Session Count",
    "Tutor",
    "Offering Type",
    "Price",
    "Status",
    "Created At",
    "Actions",
];

export const bookingLogConst = {
    PENDING: {
        title: "Pending",
    },
    PROCESSING: {
        title: "Processing"
    },
    CONFIRMED: {
        title: "Confirmed",
    },
    COMPLETED: {
        title: "Completed",
    },
    CANCELLED: {
        title: "Cancelled",
    },
    REFUNDED: {
        title: "Refunded",
    },
    FAILED: {
        title: "Failed"
    }
}

export const bookingMenuOptions = {
    GET_A_REVIEW: {
        label: "Get a review",
        value: "get_a_review"
    }
}

export const searchParamsInfo = {
    page: {
        key: "page"
    },
    records: {
        key: "records"
    },
    offeringType: {
        key: "offeringType"
    },
    status: {
        key: "status"
    },
    bookingId: {
        key: "bookingId"
    },
    actionBookingId: {
        key: "actionBookingId"
    }
}

export const bookingFilters = {
    [searchParamsInfo.bookingId.key]: {
        key: "bookingId",
        label: "Booking ID"
    },
    [searchParamsInfo.offeringType.key]: {
        key: "offeringType",
        label: "Offering Type",
    },
    [searchParamsInfo.status.key]: {
        key: "status",
        label: "Status",
    }
}

export const getBookingListPayload = async (searchQueryParams) => {

    let requestDataPayload = {
        page: searchQueryParams.get(searchParamsInfo.page.key) || DEFAULT_BOOKING_PAGE,
        records: searchQueryParams.get(searchParamsInfo.records.key) || DEFAULT_BOOKING_RECORDS
    }
    if (!!searchQueryParams.get(searchParamsInfo.bookingId.key)) {
        requestDataPayload["id"] = Number(searchQueryParams.get(searchParamsInfo.bookingId.key))
    }
    if (!!searchQueryParams.get(searchParamsInfo.offeringType.key)) {
        // requestDataPayload[searchParamsInfo.offeringType.key] = searchQueryParams.get(searchParamsInfo.offeringType.key)?.replaceAll("-", "_")
        requestDataPayload["offering_type"] = searchQueryParams.get(searchParamsInfo.offeringType.key)?.replaceAll("-", "_")
    }
    if (!!searchQueryParams.get(searchParamsInfo.status.key)) {
        requestDataPayload[searchParamsInfo.status.key] = searchQueryParams.get(searchParamsInfo.status.key)?.replaceAll("-", "_")
    }

    return requestDataPayload;
}