export const url = {

    // Instructor Course Url
    GET_INSTRUCTOR_COURSES: "/courses/courses/instructors/{user_id}",
    GET_INSTRUCTOR_COURSE: "/courses/courses/{id}/instructors/{user_id}",

    // Course url
    CREATE_COURSE: "/courses/",
    UPDATE_COURSE: "/courses/{id}",
    DELETE_COURSE: "/courses/{id}",

    // course tag detail
    GET_COURSE_TAGS: "/courses/{id}/tags",
    CREATE_COURSE_TAG: "/courses/{id}/tags",
    UPDATE_COURSE_TAG_ORDER_LIST: "/courses/{id}/tags/order",
    DELETE_COURSE_TAG: "courses/{id}/tags/{courseTagId}",

    // course language detail
    GET_COURSE_LANGUAGES: "courses/{id}/languages",
    CREATE_COURSE_LANGUAGE: "courses/{id}/languages",
    UPDATE_COURSE_LANGUAGE_ORDER_LIST: "courses/{id}/languages/order",
    DELETE_COURSE_LANGUAGE: "courses/{id}/languages/{courseLanguageId}",

    // course prof detail
    GET_COURSE_PROFS: "courses/{id}/proficiencies",
    CREATE_COURSE_PROF: "courses/{id}/proficiencies",
    UPDATE_COURSE_PROF_ORDER_LIST: "courses/{id}/proficiencies/order",
    DELETE_COURSE_PROF: "courses/{id}/proficiencies/{courseProfId}",

    // course faq detail
    GET_COURSE_FAQS: "courses/{id}/faqs",
    CREATE_COURSE_FAQ: "courses/{id}/faqs",
    UPDATE_COURSE_FAQ: "courses/{id}/faqs/{courseFaqId}",
    UPDATE_COURSE_FAQ_ORDER_LIST: "courses/{id}/faqs/order",
    DELETE_COURSE_FAQ: "courses/{id}/faqs/{courseFaqId}",

    CREATE_COHORT_COURSE: "courses/{id}/cohorts",
    GET_COHORT_DETAIL: "courses/cohorts/{cohortId}",
    UPDATE_COHORT_DETAIL: "courses/cohorts/{cohortId}",

    // cohort section
    GET_COHORT_SECTION_LIST: "courses/cohorts/{cohortId}/sections",
    CREATE_COHORT_SECTION: "courses/cohorts/{cohortId}/sections",
    UPDATE_COHORT_SECTION: "courses/cohorts/{cohortId}/sections/{sectionId}",
    UPDATE_COHORT_SECTION_ORDER_LIST: "courses/cohorts/{cohortId}/sections/order",
    DELETE_COHORT_SECTION: "courses/cohorts/{cohortId}/sections/{sectionId}",

    // cohort participants
    GET_COHORT_PARTICIPANT_LIST: "courses/cohorts/{cohortId}/participants",
    CREATE_COHORT_PARTICIPANT_DETAIL: "courses/cohorts/{cohortId}/participants",
    UPDATE_COHORT_PARTICIPANT_DETAIL: "courses/cohorts/{cohortId}/participants/{participantId}",

    // my cohorts
    GET_PARTICIPANT_COHORT_COURSE_LIST: "courses/course-cohorts/participants/{participantId}",
    GET_PARTICIPANT_COHORT_COURSE_DETAIL: "courses/course-cohorts/{cohortId}/participants/{participantId}",

    // Old Urls
    GET_PUBLIC_COURSE_LIST: "/courses/courses/public",
    GET_PUBLIC_COURSE_DETAIL: "courses/courses/{slug}/public",

    GET_COURSE_TEMPLATE_LIST: "/courses/templates",
    GET_COURSE_TEMPLATE_DETAIL: "/courses/templates/{courseTemplateId}",

    CREATE_COURSE_DETAIL: "/courses/courses",
    GET_COURSE_LIST: "/courses/courses",
    GET_COURSE_DETAIL: "/courses/courses/{courseId}",
    UPDATE_COURSE_DETAIL: "/courses/courses/{courseId}",
    DELETE_COURSE_DETAIL: "/courses/courses/{courseId}",

    GET_COURSE_PREVIEW_DETAIL: "/courses/courses/{slug}/preview",

    CREATE_COURSE_TAG_DETAIL: "/courses/tags",
    GET_COURSE_TAG_LIST: "/courses/tags",
    UPDATE_COURSE_TAG_LIST_ORDER: "/courses/tags/order",
    DELETE_COURSE_TAG_DETAIL: "/courses/tags/{courseTagId}",

    CREATE_COURSE_LANGUAGE_DETAIL: "/courses/languages",
    GET_COURSE_LANGUAGE_LIST: "/courses/languages",
    UPDATE_COURSE_LANGUAGE_LIST_ORDER: "/courses/languages/order",
    DELETE_COURSE_LANGUAGE_DETAIL: "/courses/languages/{courseLanguageId}",

    CREATE_COURSE_PROFICIENCY_DETAIL: "/courses/proficiencies",
    GET_COURSE_PROFICIENCY_LIST: "/courses/proficiencies",
    UPDATE_COURSE_PROFICIENCY_LIST_ORDER: "/courses/proficiencies/order",
    DELETE_COURSE_PROFICIENCY_DETAIL: "/courses/proficiencies/{courseProficiencyId}",

    CREATE_COURSE_AVAILABILITY_DETAIL: "/courses/availabilities",
    GET_COURSE_AVAILABILITY_LIST: "/courses/availabilities",
    GET_COURSE_AVAILABILITY_DETAIL: "/courses/availabilities/{courseAvailabilityId}",
    UPDATE_COURSE_AVAILABILITY_DETAIL: "/courses/availabilities/{courseAvailabilityId}",
    DELETE_COURSE_AVAILABILITY_DETAIL: "/courses/availabilities/{courseAvailabilityId}",

    CREATE_COURSE_PRICE_DETAIL: "/courses/prices",
    GET_COURSE_PRICE_LIST: "/courses/prices",
    GET_COURSE_PRICE_DETAIL: "/courses/prices/{coursePriceId}",
    UPDATE_COURSE_PRICE_DETAIL: "/courses/prices/{coursePriceId}",
    DELETE_COURSE_PRICE_DETAIL: "/courses/prices/{coursePriceId}",

    CLONE_COURSE_DETAIL: "/courses/courses/{courseId}/clone",

    GET_COURSE_COMMENT_LIST: "/courses/comments/",
    CREATE_COURSE_COMMENT_DETAIL: "/courses/comments/",

    CREATE_COURSE_TUTOR_DETAIL: "/courses/tutors",
    GET_COURSE_TUTOR_LIST: "/courses/tutors",
    GET_COURSE_TUTOR_DETAIL: "/courses/tutors/{courseTutorId}",
    UPDATE_COURSE_TUTOR_DETAIL: "/courses/tutors/{courseTutorId}",
    DELETE_COURSE_TUTOR_DETAIL: "/courses/tutors/{courseTutorId}",

    GET_TUTOR_COURSE_LIST: "/courses/courses/tutors/{tutorId}",
    GET_TUTOR_COURSE_DETAIL: "/courses/courses/{courseId}/tutors/{tutorId}",

    CREATE_NEXT_COURSE_SESSION_DETAIL: "/courses/courses/{courseId}/session",

    GET_USER_COURSE_LIST: "/courses/courses/users/{userId}",
    GET_USER_FAV_COURSE_LIST: "/courses/courses/users/{userId}/favorite",

    GET_PUBLIC_TUTOR_COURSE_LIST: "/courses/courses/tutors/{tutorId}/public",

    CREATE_COURSE_FAVORITE_DETAIL: "/courses/favorites",
    DELETE_COURSE_FAVORITE_DETAIL: "/courses/favorites",
}

export const instructorCourseStatusEnum = {
    draft: {
        key: 'draft',
        label: 'Draft',
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    published: {
        key: 'published',
        label: 'Published',
        lightColor: 'green-100',
        darkColor: 'green-500',
    },
    inactive: {
        key: 'inactive',
        label: 'Inactive',
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    }
}

export const cohortEnrollmentCloseEnum = {
    participant_limit: {
        label: "Participant limit",
        key: "participant_limit"
    },
    datetime: {
        label: "Date time",
        key: "datetime"
    },
    first_session: {
        label: "First session",
        key: "first_session"
    },
    last_session: {
        label: "Last session",
        key: "last_session"
    },
    never: {
        label: "Never",
        key: "never"
    },
}

export const cohortStatusEnum = {
    draft: {
        key: 'draft',
        label: 'Draft',
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    published: {
        key: 'published',
        label: 'Published',
        lightColor: 'green-100',
        darkColor: 'green-500',
    },
    inactive: {
        key: 'inactive',
        label: 'Inactive',
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    }
}

export const cohortParticipantStatusEnum = {
    pending: {
        label: "Pending",
        key: "pending"
    },
    joined: {
        label: "Joined",
        key: "joined"
    },
    cancelled: {
        label: "Cancelled",
        key: "cancelled"
    }
}

export const cohortJoiningFlowEnum = {
    checkout: {
        label: "Checkout",
        key: "checkout"
    },
    invite: {
        label: "Invite",
        key: "invite"
    },
    manual: {
        label: "Manual",
        key: "manual"
    },
}

export const modifyCoursePayload = {
    title: "",
    sub_title: "",
    category: "",
    courseHeaderActiveTab: "class_details",
    courseDetailActiveTab: "general",
}

export const tempCourseType = {
    ALL: {
        value: "all",
        label: "All"
    },
    ONE_ON_ONE: {
        value: "one_on_one",
        label: "One on One"
    },
    GROUP: {
        value: "group",
        label: "Group"
    }
}

export const DEFAULT_COURSE_RECORDS = 10
export const DEFAULT_COURSE_PAGE = 1

export const courseStatus = {
    ALL: {
        value: 'all',
        label: 'All',
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    },
    DRAFT: {
        value: 'draft',
        label: 'Draft',
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    PUBLISHED: {
        value: 'published',
        label: 'Published',
        lightColor: 'green-100',
        darkColor: 'green-500',
    },
    INACTIVE: {
        value: 'inactive',
        label: 'Inactive',
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    }
}

export const courseMessageType = {
    INFO: {
        label: "Info",
        value: "info",
        lightColor: "blue-100",
        darkColor: "blue-500"
    },
    ERROR: {
        label: "Error",
        value: "error",
        lightColor: "red-100",
        darkColor: "red-500"
    }
}

export const courseType = {
    ALL: {
        label: "All",
        value: "all"
    },
    ONE_ON_ONE: {
        label: "One on One",
        value: "one_on_one"
    },
    GROUP: {
        label: "Group",
        value: "group"
    }
}

export const visibility = {
    PUBLIC: {
        label: "Public",
        value: "public"
    },
    PRIVATE: {
        label: "Private",
        value: "private"
    }
}

export const courseSortBy = {
    RELEVANCE: {
        label: "Relevance",
        value: "relevance"
    },
    PRICELOWFIRST: {
        label: "Price lowest first",
        value: "priceLowFirst"
    },
    PRICEHIGHFIRST: {
        label: "Price highest first",
        value: "priceHighFirst"
    }
}

export const weekOptions = {
    SUNDAY: {
        label: "Sun",
        value: "Sunday"
    },
    MONDAY: {
        label: "Mon",
        value: "Monday"
    },
    TUESDAY: {
        label: "Tue",
        value: "Tuesday"
    },
    WEDNESDAY: {
        label: "Wed",
        value: "Wednesday"
    },
    THURSDAY: {
        label: "Thu",
        value: "Thursday"
    },
    FRIDAY: {
        label: "Fri",
        value: "Friday"
    },
    SATURDAY: {
        label: "Sat",
        value: "Saturday"
    }
}

export const courseIsRecurringEnum = {
    RECURRING: {
        label: "Repeat weekly",
        value: "recurring"
    },
    NON_RECURRING: {
        label: "Doesn't repeat",
        value: "non_recurring"
    }
}

export const weekAvailTimeSlots = {
    SUN: [],
    MON: [],
    TUE: [],
    WED: [],
    THU: [],
    FRI: [],
    SAT: []
}

export const courseEndTypeEnum = {
    FOREVER: {
        label: "Never",
        value: "forever"
    },
    ON_DATE: {
        label: "Select a Date",
        value: "on_date"
    },
    WEEKLY_CYClE: {
        label: "Weekly Cycles",
        value: "weekly_cycle"
    }
}

export const coursePriceTypeEnum = {
    REGULAR: {
        label: "Regular",
        value: "regular"
    },
    PLAN: {
        label: "Subscription plan",
        value: "plan"
    }
}

export const coursePricingModelTypeEnum = {
    FLAT_FEE: {
        label: "Flat Fee",
        value: "flat_fee"
    },
    PER_HOUR: {
        label: "Per Hour",
        value: "per_hour"
    },
    PER_SESSION: {
        label: "Per Session",
        value: "per_session"
    }
}

export const filterName = {
    FIND_COURSES: {
        value: "find_courses"
    },
    ALL_COURSES: {
        value: "all_courses"
    },
    FAV_COURSES: {
        value: "fav_courses"
    }
}

export const courseFilterProps = {
    page: DEFAULT_COURSE_PAGE,
    records: DEFAULT_COURSE_RECORDS,
    sortBy: courseSortBy?.RELEVANCE?.value,
    search: "",
    priceType: "",
    minPrice: 0,
    maxPrice: 0,
    courseType: [],
    segment: [],
    category: [],
    language: [],
    proficiency: [],
    visibility: [],
    status: [],
    tutorFrom: [],
    speak: [],
    roleStatus: [],
    day: [],
    offeringCourseType: courseType?.ALL?.value,
    offeringCourseStatus: courseStatus?.ALL?.value
}