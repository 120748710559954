import { lazy, Suspense } from 'react';
import { Navigate, Routes, Route, useLocation } from 'react-router-dom';

import PageLoader from 'components/loader/PageLoader';

import { classDetailTabConst } from './data';
import EditClassDetailPage from './layout';

const ClassDetailRoutes = lazy(() => import("./classDetail/router"))
const CohortsRoutes = lazy(() => import("./cohorts/router"))

const EditClassRouter = () => {

    const location = useLocation()

    return (
        <Suspense fallback={<PageLoader />}>
            <Routes>
                <Route element={<EditClassDetailPage />}>
                    <Route path={classDetailTabConst.class_details.routePath} element={<ClassDetailRoutes />} />
                    <Route path={`${classDetailTabConst.cohorts.pagePath}/*`} element={<CohortsRoutes />} />
                    <Route index element={<Navigate to={classDetailTabConst.class_details.key} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />} />
                </Route>
            </Routes>
        </Suspense>
    )
}

export default EditClassRouter;