import { useState } from "react";
import { AnimatePresence, motion } from 'framer-motion';

import { MdOutlineClose } from 'react-icons/md';

import RefreshAndAddBtn from "components/common-components/RefreshAndAddBtn";
import FilterIcon from "components/pageFilter/FilterIcon";
import ClearFilter from "components/pageFilter/ClearFilter";

import CourseStatusFilter from "./CourseStatusFilter";
import { courseFilters, searchParamsInfo } from "../data";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";


const FilterSection = ({ getInstructorCourseList }) => {
    const { modal } = useAppState((state) => state.local)

    const dispatcher = useAppDispatcher()

    const [activeFilter, setActiveFilter] = useState(null)
    const [mobileFilter, setMobileFilter] = useState(false)

    const onHandleCreateNew = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.createCourseModal.key]: {
                ...modal.createCourseModal,
                isVisible: true
            }
        }))
    }

    const handleMobileFilter = () => {
        setMobileFilter(!mobileFilter)
    }

    const FilterItemList = () => {
        return (
            <>
                {Object?.values(courseFilters)?.map((filterItem, index) => (
                    <div key={index}>
                        {(filterItem.key === searchParamsInfo.status.key) &&
                            <CourseStatusFilter
                                filterItem={filterItem}
                                activeFilter={activeFilter}
                                setActiveFilter={setActiveFilter}
                            />
                        }
                    </div>
                ))}
                <ClearFilter setFilterPayload={setActiveFilter} />
            </>
        )
    }

    return (
        <div className={"relative w-full px-6 py-3 bg-white shadow-md rounded-lg"}>
            <div className={"flex flex-col items-center justify-center gap-3 sm:flex-row sm:justify-between sm:gap-5"}>
                <AnimatePresence initial={mobileFilter}>
                    {mobileFilter && (
                        <motion.div
                            initial={{ x: -200 }}
                            animate={{ x: 0 }}
                            exit={{ x: -200 }}
                            transition={{ duration: 0.3 }}
                            className='absolute left-0 top-0 h-screen w-60 flex flex-col gap-5 p-5 bg-white rounded lg:hidden shadow-md z-10'
                        >
                            <div className='flex justify-between items-center'>
                                <h3 className='text-lg font-bodyPri font-semibold text-text-900'>{"Filter"}</h3>
                                <MdOutlineClose size={20} onClick={() => setMobileFilter(false)} className='cursor-pointer' />
                            </div>
                            <FilterItemList />
                        </motion.div>
                    )}
                </AnimatePresence>
                <div className='hidden w-full lg:flex flex-grow flex-wrap justify-center items-center sm:justify-start gap-3'>
                    <FilterIcon iconTooltip={"Filter Classes"} />
                    <FilterItemList />
                </div>
                <div className={"flex w-full items-center justify-between lg:justify-end gap-3"}>
                    <div onClick={handleMobileFilter} className={'block lg:hidden text-primary-main font-medium hover:text-secondary-dark whitespace-nowrap cursor-pointer'}>
                        {"Filter"}
                    </div>
                    <RefreshAndAddBtn
                        btnName={"Create New"}
                        onHandleRefresh={getInstructorCourseList}
                        onHandleAddBtn={onHandleCreateNew}
                    />
                </div>
            </div>
        </div>
    )
}

export default FilterSection;