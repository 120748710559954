// Environment key
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT

// Firebase key
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
export const FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID
export const FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID

// Strip Product
export const PRO_USER_PRODUCT_ID = process.env.REACT_APP_PRO_USER_PRODUCT_ID
// Strip Product
export const PRO_ORG_PRODUCT_ID = process.env.REACT_APP_PRO_ORG_PRODUCT_ID

// Google key
export const GOOGLE_SITE_KEY = process.env.REACT_APP_GOOGLE_SITE_KEY

// Google manager script id 
export const GMT_ID = process.env.REACT_APP_GMT_ID

// Google Analytic Id
export const GA_ID = process.env.REACT_APP_GA_ID

// Mux key
export const MUX_DEMO_PLAYBACK_ID = process.env.REACT_APP_MUX_DEMO_PLAYBACK_ID
export const MUX_ENV_KEY = process.env.REACT_APP_MUX_ENV_KEY

// Unsplash key
export const UNSPLASH_ACCESS_KEY = process.env.REACT_APP_UNSPLASH_ACCESS_KEY

// Country code key
export const COUNTRY_CODES_KEY = process.env.REACT_APP_COUNTRY_CODES_KEY

// Edulyte api domain urls
export const EDULYTE_API_DOMAIN_URL = process.env.REACT_APP_EDULYTE_API_DOMAIN_URL
export const EDULYTE_WS_DOMAIN_URL = process.env.REACT_APP_EDULYTE_WS_DOMAIN_URL

// Edulyte website domain urls
export const EDULYTE_ADMIN_DOMAIN_URL = process.env.REACT_APP_EDULYTE_ADMIN_DOMAIN_URL
export const EDULYTE_APP_DOMAIN_URL = process.env.REACT_APP_EDULYTE_APP_DOMAIN_URL
export const EDULYTE_WEB_DOMAIN_URL = process.env.REACT_APP_EDULYTE_WEB_DOMAIN_URL
export const EDULYTE_MEET_DOMAIN_URL = process.env.REACT_APP_EDULYTE_MEET_DOMAIN_URL
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID

export const QR_CODE_URL = `${EDULYTE_API_DOMAIN_URL}/v1/qr?url={link}`;