import { memo } from 'react';

import { motion } from "framer-motion";

import { useAppState } from 'hooks/useStore';

import { cn } from 'utils/cn.utils';
import { getCustomHostPath } from 'utils/generators.utils';
import useCustomNavigation from 'hooks/useCustomNavigation';

const RepeatBookingDetail = ({ newBookingDetail }) => {
    const { publicOrgDetail } = useAppState((state) => state.org)

    const customNavigation = useCustomNavigation()

    const onHandleRepeatBooking = () => {
        // const path = getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, pathname: `/appointment/${newBookingDetail?.data?.result?.appointment?.slug}` })
        // window.open(path, "_self")
        customNavigation.navigation({ path: `/appointment/${newBookingDetail?.data?.result?.appointment?.slug}` })
    }

    return (
        <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            transition={{ duration: 0.3 }}
            className={cn(
                "py-1.5 px-3 md:px-5 flex items-center justify-center text-white text-sm md:text-base ease-in-out duration-200 bg-primary-dark hover:opacity-90 rounded",
                "font-bodyPri font-normal text-sm sm:text-base",
            )}
            onClick={onHandleRepeatBooking}
        >
            <span className={""}>
                {"Repeat"}
            </span>
        </motion.button>
    )
}

export default memo(RepeatBookingDetail);