import { useState } from 'react'
import { useParams } from 'react-router-dom';

import { toast } from 'react-toastify';
import { Reorder } from 'framer-motion';

import ComponentLoader from 'components/loader/ComponentLoader';

import { sectionInitialState } from 'pages/auth/editClass/data';
import PageHeading from 'pages/auth/editClass/_components/header/pageHeading';

import { useGetCohortSectionList } from './_hooks/schedule.hooks';
import CohortSection from './_components/section';
import SectionButton from './_components/buttons/SectionButton';

import courseServices from 'redux/course/course.services';

import { isOrderUpdated } from 'utils/generators.utils';

const SchedulePage = () => {

    const { cohortSectionList: { isLoading, result, error }, setCohortSectionList } = useGetCohortSectionList()

    const { cohortId } = useParams()

    const [isCohortSectionOrderUpdated, setIsCohortSectionOrderUpdated] = useState(false)
    const [sectionPayload, setSectionPayload] = useState(sectionInitialState)

    const onHandleReorderGroup = (newOrder) => {
        if (newOrder) {
            const isOrderModified = isOrderUpdated(result, newOrder)
            if (!isOrderModified) {
                setIsCohortSectionOrderUpdated(false)
                return;
            }
            if (isOrderModified) {
                setIsCohortSectionOrderUpdated(true)
                setCohortSectionList((prevState) => ({ ...prevState, result: newOrder }))
            }
        }
    }

    const updateCohortSectionOrderList = async () => {
        if (isCohortSectionOrderUpdated && (result?.length > 0)) {

            try {
                const requestData = {
                    params: { cohortId: Number(cohortId) },
                    body: {
                        order: result?.map((cohortSection) => ({
                            id: cohortSection?.id
                        }))
                    }
                }
                const response = await courseServices.updateCohortSectionOrderList(requestData)
                if (response.status === 200) {
                    setCohortSectionList((prevState) => ({ ...prevState, result: response?.data?.data?.result }))
                    setIsCohortSectionOrderUpdated(false)
                }
            } catch (error) {
                console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
                toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
            }
        }
    }

    if (isLoading) {
        return (
            <ComponentLoader isLoading={isLoading} />
        )
    }

    if (error) {
        return (
            <div className={"w-full h-full flex items-center justify-center"}>
                <span className={"font-bodyPri text-red-500"}>
                    {error}
                </span>
            </div>
        )
    }

    return (
        <div className={"w-full space-y-5"}>

            <PageHeading headerText={"Schedule"} />

            {!!result?.length &&
                <Reorder.Group
                    className={'w-full space-y-5'}
                    values={result}
                    onReorder={onHandleReorderGroup}
                >
                    {result?.map((cohortSection) => (
                        <Reorder.Item
                            key={cohortSection?.id}
                            value={cohortSection}
                            onDragEnd={updateCohortSectionOrderList}
                        >
                            <CohortSection
                                cohortSection={cohortSection}
                                setCohortSectionList={setCohortSectionList}
                                sectionPayload={sectionPayload}
                                setSectionPayload={setSectionPayload}
                            />
                        </Reorder.Item>
                    ))}
                </Reorder.Group>
            }
            <SectionButton
                cohortSections={result}
                setCohortSectionList={setCohortSectionList}
                sectionPayload={sectionPayload}
                setSectionPayload={setSectionPayload}
            />
        </div>
    )
}

export default SchedulePage;