import { useNavigate } from "react-router-dom";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";
import { courseType } from "redux/course/course.const";
import { bookingOfferingTypeEnum } from "redux/booking/booking.const";

import Modal from "components/modals/modal/Modal";
import RoleSwitchModal from "components/modals/roleSwitchModal/RoleSwitchModal";
import CourseScheduleModal from "components/modals/courseScheduleModal/CourseScheduleModal";
import NoOfSessionModal from "components/modals/noOfSessionModal/NoOfSessionModal";
import ChooseTimeSlotModal from "components/modals/chooseTimeSlotModal/ChooseTimeSlotModal";
import CourseCheckoutModal from "components/modals/courseCheckoutModal/CourseCheckoutModal";
import MakePaymentModal from "components/modals/makePaymentModal/MakePaymentModal";
import PaymentMethodModal from "components/modals/paymentMethodModal/PaymentMethodModal";
import AddCreditModal from "components/modals/addCreditModal/AddCreditModal";
import TodoModal from "components/modals/todoModal/TodoModal";
import SessionDetailModal from "components/modals/sessionDetailModal/SessionDetailModal";
import SessionActionModal from "components/modals/sessionActionModal/SessionActionModal";
import CourseScheduleEditModal from "components/modals/courseScheduleEditModal/CourseScheduleEditModal";
import UploadDocumentModal from "components/modals/uploadDocumentModal/UploadDocumentModal";
import PreviewDocumentModal from "components/modals/uploadDocumentModal/PreviewDocumentModal";
import QuestionAnswerModal from "components/modals/questionAnswerModal/QuestionAnswerModal";
import ChooseInterviewSlotModal from "components/modals/chooseInterviewSlot/ChooseInterviewSlotModal";
import ComingSoonModal from "components/modals/comingSoonModal/ComingSoonModal";
import ApplyReferralModal from "components/modals/applyReferralModal/ApplyReferralModal";
import AuthorizationModal from "components/modals/authorizationModal/AuthorizationModal";
import PersonalDetailModal from "components/modals/personalDetailModal/PersonalDetailModal";
import CodeVerificationModal from "components/modals/codeVerificationModal/CodeVerificationModal";
import SubmitCourseModal from "components/modals/submitCourseModal/SubmitCourseModal";
import MessageTemplateModal from "components/modals/messageTemplateModal/MessageTemplateModal";
import ShareSocialModal from "components/modals/shareSocialModal/ShareSocialModal";
import PostRequirementModal from "components/modals/postRequirementModal/PostRequirementModal";
import CompanyPeopleModal from "components/modals/companyPeopleModal/CompanyPeopleModal";
import ImagePickerModal from "components/modals/imagePickerModal/ImagePickerModal";
import VerifyEmailModal from "components/modals/verifyEmailModal/VerifyEmailModal";
import VerifyMobileNoModal from "components/modals/verifyMobileNoModal/VerifyMobileNoModal";
import SpeakToUsModal from "components/modals/speakToUsModal/SpeakToUsModal";
import NeedATutorModal from "components/modals/needATutorModal/NeedATutorModal";
import ValidationBarEmailVerifyModal from "components/modals/validationBarEmailVerifyModal/ValidationBarEmailVerifyModal";
import ValidationBarMobileVerifyModal from "components/modals/validationBarMobileVerifyModal/ValidationBarMobileVerifyModal";
import WithdrawMoneyModal from "components/modals/withdrawMoneyModal/WithdrawMoneyModal";
import ConfirmPayoutModal from "components/modals/confirmPayoutModal/ConfirmPayoutModal";
import WithdrawalRequestSubmitModal from "components/modals/withdrawalRequestSubmitModal/WithdrawalRequestSubmitModal";
import ForgetPasswordModal from "components/modals/forgetPasswordModal/ForgetPasswordModal";
import ResetPasswordModal from "components/modals/resetPasswordModal/ResetPasswordModal";
import VerifyAuthenticationModal from "components/modals/verifyAuthenticationModal/VerifyAuthenticationModal";
import UpdateEmailModal from "components/modals/updateEmailModal/UpdateEmailModal";
import UpdateMobileNoModal from "components/modals/updateMobileNoModal/UpdateMobileNoModal";
import UpdatePasswordModal from "components/modals/updatePasswordModal/UpdatePasswordModal";
import UploadVideoModal from "components/modals/uploadVideoModal/UploadVideoModal";
import RecordVideoModal from "components/modals/recordVideoModal/RecordVideoModal";
import PhotoSearchModal from "components/modals/photoSearchModal/PhotoSearchModal";
import PromoModal from "components/modals/promoModal/PromoModal";
import AddBookingSessionModal from "components/modals/addBookingSessionModal/AddBookingSessionModal";
import AddReviewModal from "components/modals/addReviewModal/AddReviewModal";
import GetMoreReviewsModal from "components/modals/getMoreReviewsModal/GetMoreReviewsModal";
import AddOffDayModal from "components/modals/addOffDayModal/AddOffDayModal";
import CheckoutSlotModal from "components/modals/checkoutSlotModal/CheckoutSlotModal";
import PaymentCardModal from "components/modals/paymentCardModal/PaymentCardModal";
import RescheduleModal from "components/modals/rescheduleTimeSlotModal/RescheduleModal";
import CancelBookingSessionModal from "components/modals/cancelBookingSessionModal.js/CancelBookingSessionModal";
import AddCourseNextSessionModal from "components/modals/addCourseNextSessionModal/AddCourseNextSessionModal";
import ManualMobileVerificationModal from "components/modals/manualMobileVerificationModal/ManualMobileVerificationModal";
import UploadDriveFileModal from "components/modals/uploadDriveFileModal/UploadDriveFileModal";
import ShareDriveFileModal from "components/modals/shareDriveFileModal/ShareDriveFileModal";

import { pagesInfo } from "utils/pagesInfo";
import AttachLibraryModal from "components/modals/lmsModals/attachLibraryModal/AttachLibraryModal";
import CreateBulkQuestions from "components/modals/lmsModals/createBulkQuestion/createBulkQuestions";
import CreateLmsArticleModal from "components/modals/lmsModals/CreateLmsArticleModal";
import CreateLmsCourseModal from "components/modals/lmsModals/CreateLmsCourseModal";
import CreateLmsQuestionModal from "components/modals/lmsModals/CreateLmsQuestionModal";
import CreateLmsQuizModal from "components/modals/lmsModals/CreateLmsQuizModal";
import CreateLmsTopicModal from "components/modals/lmsModals/CreateLmsTopicModal";
import CreateLmsLectureModal from "components/modals/lmsModals/createLmsLectureModal/CreateLmsLectureModal";
import UploadLmsMediaModal from "components/modals/lmsModals/uploadLmsMediaModal/UploadLmsMediaModal";
import LmsPreviewImageModal from "components/modals/lmsModals/LmsPreviewImageModal";
import ProductPriceDetailModal from "components/modals/productPriceDetailModal/ProductPriceDetailModal";
import InviteLmsCourseUserModal from "components/modals/lmsModals/inviteLmsCourseUserModal/InviteLmsCourseUserModal";
import InviteLmsQuizUserModal from "components/modals/lmsModals/inviteLmsQuizUserModal/InviteLmsQuizUserModal";
import AddLmsLessonInstructorModal from "components/modals/lmsModals/addLmsLessonInstructorModal/AddLmsLessonInstructorModal";
import AddLmsQuestionInstructorModal from "components/modals/lmsModals/addLmsQuestionInstructorModal/AddLmsQuestionInstructorModal";
import AddLmsQuizInstructorModal from "components/modals/lmsModals/addLmsQuizInstructorModal/AddLmsQuizInstructorModal";
import AddLmsArticleInstructorModal from "components/modals/lmsModals/addLmsArticleInstructorModal/AddLmsArticleInstructorModal";
import AddLmsLectureInstructorModal from "components/modals/lmsModals/addLmsLectureInstructorModal/AddLmsLectureInstructorModal";
import AddEventlySlotModal from "components/modals/eventlyModals/addEventlySlotModal/AddEventlySlotModal";
import ViewEventlySlotModal from "components/modals/eventlyModals/viewEventlySlotModal/ViewEventlySlotModal";
import EditEventlyEventSlotModal from "components/modals/eventlyModals/editEventlyEventSlotModal/EditEventlyEventSlotModal";
import CheckoutLocationModal from "components/modals/checkoutLocationModal";

import ViewContactDetailModal from "components/modals/crmModals/viewContactDetailModal/ViewContactDetailModal";
import CreateCrmContactDetailModal from "components/modals/crmModals/createCrmContactDetailModal/CreateCrmContactDetailModal";
import UpdateCrmBulkContactListModal from "components/modals/crmModals/updateCrmBulkContactListModal/UpdateCrmBulkContactListModal";

import PlanTableModal from "components/modals/planTableModal/PlanTableModal";

import AddDownloadModalLayout from "components/modals/downloadModals/addDownloadModal/AddDownloadModalLayout";
import InviteDownloadUserModal from "components/modals/downloadModals/inviteDownloadUserModal/InviteDownloadUserModal";
import MyDownloadDetailModal from "components/modals/downloadModals/myDownloadDetailModal/MyDownloadDetailModal";
import CreateAppointmentDetailModalLayout from "components/modals/appointmentModal/createAppointmentDetailModal/CreateAppointmentDetailModalLayout";
import LocationLayoutModal from "components/modals/appointmentModal/locationModal/LocationLayoutModal";
import CreateCertificateModal from "components/modals/createCertificateModal/createCertificateModal";
import CreateLinkHubModal from "components/modals/createLinkHubModal";
import GatewayModal from "components/modals/selectGateway/GatewayModal";

import CreateCourseModal from "components/modals/courseModal/createCourseModal"
import AddCohortModal from "components/modals/cohortModal/addCohortModal";
import InviteCohortParticipantModal from "components/modals/cohortModal/inviteParticipantModal";
import AddCohortSessionModal from "components/modals/cohortModal/addSessionModal";

const ModalContainer = () => {
    const { modal, bookingPayload } = useAppState((s) => s.local);
    const { addLeadDetail } = useAppState((state) => state.lead);
    const { addBookingDetail } = useAppState((s) => s.booking);
    const { cameraStatus, instructorCourse } = useAppState((state) => state.course);
    const { lmsCourseDetail } = useAppState((state) => state.lms.lmsCourse);
    const { lmsQuizDetail } = useAppState((state) => state.lms.lmsQuiz);
    const { ownerUserDownloadDetail } = useAppState((state) => state.downloads);
    const { addUserReviewDetail } = useAppState((state) => state.review);

    const dispatcher = useAppDispatcher();
    const navigate = useNavigate();

    const onSetModal = (stateKey, value) => {
        if (stateKey === modalConst.RECORD_VIDEO_MODAL.stateKey && cameraStatus.isCameraOpen) {
            alert("Please close your camera! ");
            return;
        }
        if ([modalConst.SESSION_DETAIL_MODAL.stateKey, modalConst.SESSION_ACTION_MODAL.stateKey, modalConst.RESCHEDULE_TIME_SLOT_MODAL.stateKey]?.includes(stateKey)) {
            navigate(-1);
        }
        if (stateKey === modalConst.inviteCohortParticipantModal.key) {
            navigate(`${pagesInfo?.CREATE_COURSE?.pagePath}/${instructorCourse?.result?.id}/edit/cohorts/${modal.inviteCohortParticipantModal.payload.cohortId}/participants`);
        }
        if (stateKey === modalConst.inviteLmsCourseUserModal.key) {
            navigate(`${pagesInfo?.EDIT_LMS_COURSE?.pagePath}/${lmsCourseDetail?.data?.id}/edit/participants/invited`);
        }
        if (stateKey === modalConst.inviteLmsQuizUserModal.key) {
            navigate(`${pagesInfo?.EDIT_LMS_QUIZ?.pagePath}/${lmsQuizDetail?.data?.id}/edit/participants/invited`);
        }
        if (stateKey === modalConst.inviteDownloadUserModal.key) {
            navigate(`${pagesInfo?.EDIT_DOWNLOAD?.pagePath}/${ownerUserDownloadDetail?.data?.id}/edit/participants/invited`);
        }
        dispatcher(setModal({ ...modal, [stateKey]: value }));
    };

    const onBackModal = (stateKey) => {
        switch (stateKey) {
            case modalConst.CHOOSE_TIME_SLOT_MODAL.stateKey:
                dispatcher(
                    setModal({
                        ...modal,
                        [modalConst.CHOOSE_TIME_SLOT_MODAL.stateKey]: false,
                        [modalConst.NO_SESSION_MODAL.stateKey]: true,
                    })
                );
                break;
            case modalConst.COURSE_CHECKOUT_MODAL.stateKey:
                if (bookingPayload?.course?.courseType === courseType.ONE_ON_ONE.value)
                    dispatcher(
                        setModal({
                            ...modal,
                            [modalConst.COURSE_CHECKOUT_MODAL.stateKey]: false,
                            [modalConst.CHOOSE_TIME_SLOT_MODAL.stateKey]: true,
                        })
                    );
                if (bookingPayload?.course?.courseType === courseType.GROUP.value)
                    dispatcher(
                        setModal({
                            ...modal,
                            [modalConst.COURSE_CHECKOUT_MODAL.stateKey]: false,
                            [modalConst.COURSE_SCHEDULE_MODAL.stateKey]: true,
                        })
                    );
                break;
            case modalConst.MAKE_PAYMENT_MODAL.stateKey:
                dispatcher(
                    setModal({
                        ...modal,
                        [modalConst.MAKE_PAYMENT_MODAL.stateKey]: false,
                        [modalConst.COURSE_CHECKOUT_MODAL.stateKey]: true,
                    })
                );
                break;
            case modalConst.SESSION_ACTION_MODAL.stateKey:
                dispatcher(
                    setModal({
                        ...modal,
                        [modalConst.SESSION_ACTION_MODAL.stateKey]: false,
                        [modalConst.SESSION_DETAIL_MODAL.stateKey]: true,
                    })
                );
                break;
            case modalConst.NEED_A_TUTOR_MODAL.stateKey:
                dispatcher(
                    setModal({
                        ...modal,
                        [modalConst.NEED_A_TUTOR_MODAL.stateKey]: false,
                        [modalConst.SPEAK_TO_US_MODAL.stateKey]: true,
                    })
                );
                break;
            case modalConst.CONFIRM_PAYOUT_MODAL.stateKey:
                dispatcher(
                    setModal({
                        ...modal,
                        [modalConst.CONFIRM_PAYOUT_MODAL.stateKey]: false,
                        [modalConst.WITHDRAW_MONEY_MODAL.stateKey]: true,
                    })
                );
                break;
            case modalConst.RESET_PASSWORD_MODAL.stateKey:
                dispatcher(
                    setModal({
                        ...modal,
                        [modalConst.RESET_PASSWORD_MODAL.stateKey]: false,
                        [modalConst.FORGET_PASSWORD_MODAL.stateKey]: true,
                    })
                );
                break;

            case modalConst.RESCHEDULE_TIME_SLOT_MODAL.stateKey:
                dispatcher(
                    setModal({
                        ...modal,
                        [modalConst.RESCHEDULE_TIME_SLOT_MODAL.stateKey]: false,
                        [modalConst.SESSION_ACTION_MODAL.stateKey]: true,
                    })
                );
                break;
            default:
                dispatcher(setModal({ ...modal }));
        }
    };

    return (
        <>
            <Modal
                title={"Choose Your Role"}
                maxWidth={"max-w-xl"}
                openModal={modal[modalConst.ROLE_SWITCH_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.ROLE_SWITCH_MODAL.stateKey, isShow)}
                children={<RoleSwitchModal />}
            />
            <Modal
                title={"Schedule"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.COURSE_SCHEDULE_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.COURSE_SCHEDULE_MODAL.stateKey, isShow)}
                children={<CourseScheduleModal />}
            />
            <Modal
                title={"Number of sessions"}
                maxWidth={"max-w-md"}
                openModal={modal[modalConst.NO_SESSION_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.NO_SESSION_MODAL.stateKey, isShow)}
                children={<NoOfSessionModal />}
            />
            <Modal
                title={"Choose time for your first session"}
                maxWidth={"max-w-md md:max-w-3xl"}
                openModal={modal[modalConst.CHOOSE_TIME_SLOT_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.CHOOSE_TIME_SLOT_MODAL.stateKey, isShow)}
                onBackModal={
                    addBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.COHORT.value
                        ? () => onBackModal(modalConst.CHOOSE_TIME_SLOT_MODAL.stateKey)
                        : undefined
                }
                children={<ChooseTimeSlotModal />}
            />
            <Modal
                title={"Booking Preview"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.COURSE_CHECKOUT_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.COURSE_CHECKOUT_MODAL.stateKey, isShow)}
                onBackModal={
                    bookingPayload?.course?.courseType === courseType.ONE_ON_ONE.value
                        ? () => onBackModal(modalConst.COURSE_CHECKOUT_MODAL.stateKey)
                        : undefined
                }
                children={<CourseCheckoutModal />}
            />
            <Modal
                title={"Make Payment"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.MAKE_PAYMENT_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.MAKE_PAYMENT_MODAL.stateKey, isShow)}
                onBackModal={() => onBackModal(modalConst.MAKE_PAYMENT_MODAL.stateKey)}
                children={<MakePaymentModal />}
            />
            <Modal
                title={"Payment method"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.PAYMENT_METHOD_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.PAYMENT_METHOD_MODAL.stateKey, isShow)}
                children={<PaymentMethodModal />}
            />
            <Modal
                title={"Add Credit"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.ADD_CREDIT_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.ADD_CREDIT_MODAL.stateKey, isShow)}
                children={<AddCreditModal />}
            />
            <Modal
                title={"Select Gateway"}
                maxWidth={"max-w-md md:max-w-3xl"}
                openModal={modal[modalConst.SELECT_GATEWAY_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.SELECT_GATEWAY_MODAL.stateKey, isShow)}
                children={<GatewayModal />}
            />
            <Modal
                title={"My Todo"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.TODO_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.TODO_MODAL.stateKey, isShow)}
                children={<TodoModal />}
            />
            <Modal
                title={"Session Detail"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.SESSION_DETAIL_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.SESSION_DETAIL_MODAL.stateKey, isShow)}
                children={<SessionDetailModal />}
            />
            <Modal
                title={"Session Action"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.SESSION_ACTION_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.SESSION_ACTION_MODAL.stateKey, isShow)}
                onBackModal={() => onBackModal(modalConst.SESSION_ACTION_MODAL.stateKey)}
                children={<SessionActionModal />}
            />
            <Modal
                title={"Edit Class Schedule"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.COURSE_SCHEDULE_EDIT_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.COURSE_SCHEDULE_EDIT_MODAL.stateKey, isShow)}
                children={<CourseScheduleEditModal />}
            />
            <Modal
                title={"Upload Document"}
                maxWidth={"max-w-xl"}
                openModal={modal[modalConst.UPLOAD_DOCUMENT_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.UPLOAD_DOCUMENT_MODAL.stateKey, isShow)}
                children={<UploadDocumentModal />}
            />
            <Modal
                title={"Preview"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.PREVIEW_DOCUMENT_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.PREVIEW_DOCUMENT_MODAL.stateKey, isShow)}
                children={<PreviewDocumentModal />}
            />
            <Modal
                title={"Placement Questionnaire"}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.QUESTION_ANSWER_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.QUESTION_ANSWER_MODAL.stateKey, isShow)}
                children={<QuestionAnswerModal />}
            />
            <Modal
                title={"Choose time slot"}
                maxWidth={"max-w-md md:max-w-3xl"}
                openModal={modal[modalConst.CHOOSE_INTERVIEW_SLOT.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.CHOOSE_INTERVIEW_SLOT.stateKey, isShow)}
                children={<ChooseInterviewSlotModal />}
            />
            <Modal
                title={"Coming Soon"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.COMING_SOON_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.COMING_SOON_MODAL.stateKey, isShow)}
                children={<ComingSoonModal />}
            />
            <Modal
                title={"Referral"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.APPLY_REFERRAL_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.APPLY_REFERRAL_MODAL.stateKey, isShow)}
                children={<ApplyReferralModal />}
            />
            <Modal
                title={""}
                maxWidth={"max-w-xl"}
                openModal={modal[modalConst.AUTHORIZATION_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.AUTHORIZATION_MODAL.stateKey, isShow)}
                children={<AuthorizationModal />}
            />
            <Modal
                title={"Personal Details"}
                maxWidth={"max-w-xl"}
                openModal={modal[modalConst.PERSONAL_DETAIL_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.PERSONAL_DETAIL_MODAL.stateKey, isShow)}
                children={<PersonalDetailModal />}
            />
            <Modal
                title={"Verify Your Account"}
                maxWidth={"max-w-lg"}
                openModal={modal[modalConst.CODE_VERIFICATION_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.CODE_VERIFICATION_MODAL.stateKey, isShow)}
                children={<CodeVerificationModal />}
            />
            <Modal
                title={"Submit for Review"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.SUBMIT_COURSE_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.SUBMIT_COURSE_MODAL.stateKey, isShow)}
                children={<SubmitCourseModal />}
            />
            <Modal
                title={"Select a Message"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.MESSAGE_TEMPLATE_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.MESSAGE_TEMPLATE_MODAL.stateKey, isShow)}
                children={<MessageTemplateModal />}
            />
            <Modal
                title={"Share Now!"}
                maxWidth={"max-w-md"}
                openModal={modal[modalConst.SHARE_SOCIAL_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.SHARE_SOCIAL_MODAL.stateKey, isShow)}
                children={<ShareSocialModal />}
            />
            <Modal
                title={"Post Your Requirement"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.POST_REQUIREMENT_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.POST_REQUIREMENT_MODAL.stateKey, isShow)}
                children={<PostRequirementModal />}
            />
            <Modal
                title={"Add People"}
                maxWidth={"max-w-md"}
                openModal={modal[modalConst.COMPANY_PEOPLE_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.COMPANY_PEOPLE_MODAL.stateKey, isShow)}
                children={<CompanyPeopleModal />}
            />
            <Modal
                title={"Select Image"}
                maxWidth={"max-w-4xl"}
                openModal={modal[modalConst.IMAGE_PICKER_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.IMAGE_PICKER_MODAL.stateKey, isShow)}
                children={<ImagePickerModal />}
            />
            <Modal
                title={"Authenticate"}
                maxWidth={"max-w-md"}
                openModal={modal[modalConst.VERIFY_EMAIL_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.VERIFY_EMAIL_MODAL.stateKey, isShow)}
                children={<VerifyEmailModal />}
            />
            <Modal
                title={"Authenticate"}
                maxWidth={"max-w-md"}
                openModal={modal[modalConst.VERIFY_MOBILE_NO_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.VERIFY_MOBILE_NO_MODAL.stateKey, isShow)}
                children={<VerifyMobileNoModal />}
            />
            <Modal
                title={"Would you like to speak to us?"}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.SPEAK_TO_US_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.SPEAK_TO_US_MODAL.stateKey, isShow)}
                children={<SpeakToUsModal />}
            />
            <Modal
                title={""}
                maxWidth={"max-w-4xl"}
                openModal={modal[modalConst.NEED_A_TUTOR_MODAL.stateKey]}
                onCloseModal={(isShow) => {
                    if (addLeadDetail?.addLeadDetailPayload?.isDone) {
                        onSetModal(modalConst.NEED_A_TUTOR_MODAL.stateKey, isShow);
                    } else {
                        onBackModal(modalConst.NEED_A_TUTOR_MODAL.stateKey);
                    }
                }}
                children={<NeedATutorModal />}
            />
            <Modal
                title={"Verify Email"}
                maxWidth={"max-w-md"}
                openModal={modal[modalConst.VALIDATION_BAR_EMAIL_VERIFY_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.VALIDATION_BAR_EMAIL_VERIFY_MODAL.stateKey, isShow)}
                children={<ValidationBarEmailVerifyModal />}
            />
            <Modal
                title={"Please Verify Your Phone"}
                maxWidth={"max-w-md"}
                openModal={modal[modalConst.VALIDATION_BAR_MOBILE_VERIFY_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.VALIDATION_BAR_MOBILE_VERIFY_MODAL.stateKey, isShow)}
                children={<ValidationBarMobileVerifyModal />}
            />
            <Modal
                title={"Withdraw Money"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.WITHDRAW_MONEY_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.WITHDRAW_MONEY_MODAL.stateKey, isShow)}
                children={<WithdrawMoneyModal />}
            />
            <Modal
                title={"Confirm Payout"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.CONFIRM_PAYOUT_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.CONFIRM_PAYOUT_MODAL.stateKey, isShow)}
                onBackModal={() => onBackModal(modalConst.CONFIRM_PAYOUT_MODAL.stateKey)}
                children={<ConfirmPayoutModal />}
            />
            <Modal
                title={""}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.WITHDRAWAL_REQUEST_SUBMIT_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.WITHDRAWAL_REQUEST_SUBMIT_MODAL.stateKey, isShow)}
                children={<WithdrawalRequestSubmitModal />}
            />
            <Modal
                title={"Reset Password"}
                maxWidth={"max-w-lg"}
                openModal={modal[modalConst.FORGET_PASSWORD_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.FORGET_PASSWORD_MODAL.stateKey, isShow)}
                children={<ForgetPasswordModal />}
            />
            <Modal
                title={"Reset Password"}
                maxWidth={"max-w-lg"}
                openModal={modal[modalConst.RESET_PASSWORD_MODAL.stateKey]}
                onBackModal={() => onBackModal(modalConst.RESET_PASSWORD_MODAL.stateKey)}
                onCloseModal={(isShow) => onSetModal(modalConst.RESET_PASSWORD_MODAL.stateKey, isShow)}
                children={<ResetPasswordModal />}
            />
            <Modal
                title={"Authenticate"}
                maxWidth={"max-w-lg"}
                openModal={modal[modalConst.VERIFY_AUTHENTICATION_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.VERIFY_AUTHENTICATION_MODAL.stateKey, isShow)}
                children={<VerifyAuthenticationModal />}
            />
            <Modal
                title={"Update Email"}
                maxWidth={"max-w-lg"}
                openModal={modal[modalConst.UPDATE_EMAIL_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.UPDATE_EMAIL_MODAL.stateKey, isShow)}
                children={<UpdateEmailModal />}
            />
            <Modal
                title={"Update Phone"}
                maxWidth={"max-w-lg"}
                openModal={modal[modalConst.UPDATE_MOBILE_NO_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.UPDATE_MOBILE_NO_MODAL.stateKey, isShow)}
                children={<UpdateMobileNoModal />}
            />
            <Modal
                title={"Update Password"}
                maxWidth={"max-w-lg"}
                openModal={modal[modalConst.UPDATE_PASSWORD_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.UPDATE_PASSWORD_MODAL.stateKey, isShow)}
                children={<UpdatePasswordModal />}
            />
            <Modal
                title={"Upload Video"}
                maxWidth={"max-w-lg"}
                openModal={modal[modalConst.UPLOAD_VIDEO_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.UPLOAD_VIDEO_MODAL.stateKey, isShow)}
                children={<UploadVideoModal />}
            />
            <Modal
                title={"Record Video"}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.RECORD_VIDEO_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.RECORD_VIDEO_MODAL.stateKey, isShow)}
                children={<RecordVideoModal />}
            />
            <Modal
                title={"Search Photo"}
                maxWidth={"max-w-lg"}
                openModal={modal[modalConst.PHOTO_SEARCH_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.PHOTO_SEARCH_MODAL.stateKey, isShow)}
                children={<PhotoSearchModal />}
            />
            <Modal
                title={"Apply Promo"}
                maxWidth={"max-w-lg"}
                openModal={modal[modalConst.PROMO_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.PROMO_MODAL.stateKey, isShow)}
                children={<PromoModal />}
            />
            <Modal
                title={"Schedule a new session"}
                maxWidth={"max-w-md md:max-w-3xl"}
                openModal={modal[modalConst.ADD_BOOKING_SESSION_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.ADD_BOOKING_SESSION_MODAL.stateKey, isShow)}
                children={<AddBookingSessionModal />}
            />
            <Modal
                title={"Leave a review"}
                maxWidth={"max-w-xl"}
                openModal={modal[modalConst.ADD_REVIEW_MODAL.stateKey]}
                onCloseModal={(isShow) => {
                    if (addUserReviewDetail?.addUserReviewDetailPayload?.isNavigateToTutorPage) {
                        navigate(
                            `${pagesInfo?.TUTOR?.pagePath}/${addUserReviewDetail?.addUserReviewDetailPayload?.userId}`
                        );
                    }
                    onSetModal(modalConst.ADD_REVIEW_MODAL.stateKey, isShow);
                }}
                children={<AddReviewModal />}
            />
            <Modal
                title={"Get more reviews"}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.GET_MORE_REVIEWS.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.GET_MORE_REVIEWS.stateKey, isShow)}
                children={<GetMoreReviewsModal />}
            />
            <Modal
                title={"Off day(s)"}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.ADD_OFF_DAY_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.ADD_OFF_DAY_MODAL.stateKey, isShow)}
                children={<AddOffDayModal />}
            />
            <Modal
                title={"Choose time for your first session"}
                maxWidth={"max-w-md md:max-w-3xl"}
                openModal={modal[modalConst.CHECKOUT_SLOT_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.CHECKOUT_SLOT_MODAL.stateKey, isShow)}
                children={<CheckoutSlotModal />}
            />
            <Modal
                title={"Payment method"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.PAYMENT_CARD_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.PAYMENT_CARD_MODAL.stateKey, isShow)}
                children={<PaymentCardModal />}
            />
            <Modal
                title={"Reschedule Your Session"}
                maxWidth={"max-w-md md:max-w-3xl"}
                openModal={modal[modalConst.RESCHEDULE_TIME_SLOT_MODAL.stateKey]}
                onBackModal={() => onBackModal(modalConst.RESCHEDULE_TIME_SLOT_MODAL.stateKey)}
                onCloseModal={(isShow) => onSetModal(modalConst.RESCHEDULE_TIME_SLOT_MODAL.stateKey, isShow)}
                children={<RescheduleModal />}
            />
            <Modal
                title={"Cancel Your Booking"}
                maxWidth={"max-w-md"}
                openModal={modal[modalConst.CANCEL_BOOKING_SESSION_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.CANCEL_BOOKING_SESSION_MODAL.stateKey, isShow)}
                children={<CancelBookingSessionModal />}
            />
            <Modal
                title={"Add Session(s)"}
                maxWidth={"max-w-lg"}
                openModal={modal[modalConst.ADD_COURSE_NEXT_SESSION_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.ADD_COURSE_NEXT_SESSION_MODAL.stateKey, isShow)}
                children={<AddCourseNextSessionModal />}
            />
            <Modal
                title={"Please Verify Your Phone"}
                maxWidth={"max-w-lg md:max-w-[80vw]"}
                openModal={modal[modalConst.MANUAL_MOBILE_VERIFICATION_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.MANUAL_MOBILE_VERIFICATION_MODAL.stateKey, isShow)}
                children={<ManualMobileVerificationModal />}
            />
            <Modal
                title={""}
                maxWidth={"max-w-lg"}
                openModal={modal[modalConst.UPLOAD_DRIVE_FILE_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.UPLOAD_DRIVE_FILE_MODAL.stateKey, isShow)}
                children={<UploadDriveFileModal />}
            />
            <Modal
                title={""}
                maxWidth={"max-w-lg"}
                openModal={modal[modalConst.SHARE_DRIVE_FILE_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.SHARE_DRIVE_FILE_MODAL.stateKey, isShow)}
                children={<ShareDriveFileModal />}
            />

            {/* lms modals */}
            <AttachLibraryModal
                title={""}
                maxWidth={"max-w-5xl"}
                openModal={modal[modalConst.ATTACH_LIBRARY_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.ATTACH_LIBRARY_MODAL.stateKey, isShow)}
            />
            <CreateBulkQuestions
                title={""}
                maxWidth={"max-w-5xl"}
                openModal={modal[modalConst.CREATE_BULK_QUESTIONS.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.CREATE_BULK_QUESTIONS.stateKey, isShow)}
            />
            <CreateLmsArticleModal
                title={""}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.CREATE_LMS_ARTICLE_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.CREATE_LMS_ARTICLE_MODAL.stateKey, isShow)}
            />
            <CreateLmsCourseModal
                title={""}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.CREATE_LMS_COURSE_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.CREATE_LMS_COURSE_MODAL.stateKey, isShow)}
            />
            <CreateLmsQuestionModal
                title={""}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.CREATE_LMS_QUESTION_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.CREATE_LMS_QUESTION_MODAL.stateKey, isShow)}
            />
            <CreateLmsQuizModal
                title={""}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.CREATE_LMS_QUIZ_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.CREATE_LMS_QUIZ_MODAL.stateKey, isShow)}
            />
            <CreateLmsTopicModal
                title={""}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.CREATE_LMS_TOPIC_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.CREATE_LMS_TOPIC_MODAL.stateKey, isShow)}
            />
            <CreateLmsLectureModal
                title={""}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.CREATE_LMS_LECTURE_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.CREATE_LMS_LECTURE_MODAL.stateKey, isShow)}
            />
            <UploadLmsMediaModal
                title={""}
                maxWidth={"max-w-5xl"}
                openModal={modal[modalConst.UPLOAD_LMS_MEDIA_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.UPLOAD_LMS_MEDIA_MODAL.stateKey, isShow)}
            />
            <LmsPreviewImageModal
                title={""}
                maxWidth={"max-w-5xl"}
                openModal={modal[modalConst.LMS_PREVIEW_IMAGE_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.LMS_PREVIEW_IMAGE_MODAL.stateKey, isShow)}
            />
            <Modal
                title={"Price Detail"}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.PRODUCT_PRICE_DETAIL_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.PRODUCT_PRICE_DETAIL_MODAL.stateKey, isShow)}
                children={<ProductPriceDetailModal />}
            />
            <Modal
                title={modal[modalConst.inviteLmsCourseUserModal.key]?.title}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.inviteLmsCourseUserModal.key]?.isVisible}
                onCloseModal={(isShow) =>
                    onSetModal(modalConst.inviteLmsCourseUserModal.key, modalConst.inviteLmsCourseUserModal)
                }
                children={<InviteLmsCourseUserModal />}
            />
            <Modal
                title={modal[modalConst.inviteLmsQuizUserModal.key]?.title}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.inviteLmsQuizUserModal.key]?.isVisible}
                onCloseModal={(isShow) =>
                    onSetModal(modalConst.inviteLmsQuizUserModal.key, modalConst.inviteLmsQuizUserModal)
                }
                children={<InviteLmsQuizUserModal />}
            />
            <Modal
                title={modal[modalConst.addLmsLessonInstructorModal.key]?.title}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.addLmsLessonInstructorModal.key]?.isVisible}
                onCloseModal={(isShow) =>
                    onSetModal(modalConst.addLmsLessonInstructorModal.key, modalConst.addLmsLessonInstructorModal)
                }
                children={<AddLmsLessonInstructorModal />}
            />
            <Modal
                title={modal[modalConst.addLmsQuestionInstructorModal.key]?.title}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.addLmsQuestionInstructorModal.key]?.isVisible}
                onCloseModal={(isShow) =>
                    onSetModal(modalConst.addLmsQuestionInstructorModal.key, modalConst.addLmsQuestionInstructorModal)
                }
                children={<AddLmsQuestionInstructorModal />}
            />
            <Modal
                title={modal[modalConst.addLmsQuizInstructorModal.key]?.title}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.addLmsQuizInstructorModal.key]?.isVisible}
                onCloseModal={(isShow) =>
                    onSetModal(modalConst.addLmsQuizInstructorModal.key, modalConst.addLmsQuizInstructorModal)
                }
                children={<AddLmsQuizInstructorModal />}
            />
            <Modal
                title={modal[modalConst.addLmsArticleInstructorModal.key]?.title}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.addLmsArticleInstructorModal.key]?.isVisible}
                onCloseModal={(isShow) =>
                    onSetModal(modalConst.addLmsArticleInstructorModal.key, modalConst.addLmsArticleInstructorModal)
                }
                children={<AddLmsArticleInstructorModal />}
            />
            <Modal
                title={modal[modalConst.addLmsLectureInstructorModal.key]?.title}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.addLmsLectureInstructorModal.key]?.isVisible}
                onCloseModal={(isShow) =>
                    onSetModal(modalConst.addLmsLectureInstructorModal.key, modalConst.addLmsLectureInstructorModal)
                }
                children={<AddLmsLectureInstructorModal />}
            />

            <AddEventlySlotModal
                title={modal[modalConst.addEventlySlotModal.key]?.title}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.addEventlySlotModal.key]?.isVisible}
                onCloseModal={(isShow) =>
                    onSetModal(modalConst.addEventlySlotModal.key, modalConst.addEventlySlotModal)
                }
            />
            <ViewEventlySlotModal
                title={modal[modalConst.viewEventlySlotModal.key]?.title}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.viewEventlySlotModal.key]?.isVisible}
                onCloseModal={(isShow) => { }}
            />
            <Modal
                title={modal[modalConst.editEventlyEventSlotModal.key]?.title}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.editEventlyEventSlotModal.key]?.isVisible}
                onCloseModal={(isShow) =>
                    onSetModal(modalConst.editEventlyEventSlotModal.key, modalConst.editEventlyEventSlotModal)
                }
                children={<EditEventlyEventSlotModal />}
            />
            <ViewContactDetailModal
                title={modal[modalConst.viewContactDetailModal.key]?.title}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.viewContactDetailModal.key]?.isVisible}
                onCloseModal={(isShow) => { }}
            />
            <CreateCrmContactDetailModal
                title={modal[modalConst.createCrmContactDetailModal.key]?.title}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.createCrmContactDetailModal.key]?.isVisible}
                onCloseModal={(isShow) => { }}
            />
            <UpdateCrmBulkContactListModal
                title={modal[modalConst.updateCrmBulkContactListModal.key]?.title}
                maxWidth={"max-w-lg"}
                openModal={modal[modalConst.updateCrmBulkContactListModal.key]?.isVisible}
                onCloseModal={(isShow) => { }}
            />
            <PlanTableModal
                title={modal[modalConst.planTableModal.key]?.title}
                maxWidth={"max-w-md sm:max-w-lg md:max-w-3xl lg:max-w-6xl"}
                openModal={modal[modalConst.planTableModal.key]?.isVisible}
                onCloseModal={(isShow) => { }}
            />
            <AddDownloadModalLayout
                title={modal[modalConst.addDownloadModal.key]?.title}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.addDownloadModal.key]?.isVisible}
                onCloseModal={(isShow) => { }}
            />
            <Modal
                title={modal[modalConst.inviteDownloadUserModal.key]?.title}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.inviteDownloadUserModal.key]?.isVisible}
                onCloseModal={(isShow) =>
                    onSetModal(modalConst.inviteDownloadUserModal.key, modalConst.inviteDownloadUserModal)
                }
                children={<InviteDownloadUserModal />}
            />
            <MyDownloadDetailModal
                title={modal[modalConst.myDownloadDetailModal.key]?.title}
                maxWidth={"max-w-lg"}
                openModal={modal[modalConst.myDownloadDetailModal.key]?.isVisible}
                onCloseModal={(isShow) => { }}
            />
            <CreateAppointmentDetailModalLayout
                title={modal[modalConst.createAppointmentDetailModal.key]?.title}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.createAppointmentDetailModal.key]?.isVisible}
                onCloseModal={(isShow) => { }}
            />
            <CreateCertificateModal
                title={modal[modalConst.CREATE_CERTIFICATE_MODAL.key]?.title}
                maxWidth={"max-w-4xl"}
                openModal={modal[modalConst.CREATE_CERTIFICATE_MODAL.key]?.isVisible}
                onCloseModal={(isShow) => { }}
            />
            <LocationLayoutModal
                title={modal[modalConst.appointmentLocationDetailModal.key].title}
                maxWidth={"max-w-md"}
                openModal={modal[modalConst.appointmentLocationDetailModal.key].isVisible}
                onCloseModal={(isShow) => { }}
            />
            <CreateLinkHubModal
                title={modal[modalConst.CREATE_LINK_HUG_MODAL.key]?.title}
                maxWidth={"max-w-4xl"}
                openModal={modal[modalConst.CREATE_LINK_HUG_MODAL.key]?.isVisible}
                onCloseModal={(isShow) => { }}
            />

            <Modal
                title={modal[modalConst.checkoutLocationModal.key].title}
                maxWidth={"max-w-lg"}
                openModal={modal[modalConst.checkoutLocationModal.key].isVisible}
                onCloseModal={(isShow) =>
                    onSetModal(modalConst.checkoutLocationModal.key, modalConst.checkoutLocationModal)
                }
                children={<CheckoutLocationModal />}
            />
            <CreateCourseModal
                title={modal[modalConst.createCourseModal.key]?.title}
                maxWidth={"max-w-3xl"}
                openModal={modal[modalConst.createCourseModal.key]?.isVisible}
                onCloseModal={(isShow) => { }}
            />
            <AddCohortModal
                title={modal[modalConst.addCohortModal.key]?.title}
                maxWidth={"max-w-3xl"}
                openModal={modal[modalConst.addCohortModal.key]?.isVisible}
                onCloseModal={(isShow) => { }}
            />
            <Modal
                title={modal[modalConst.inviteCohortParticipantModal.key]?.title}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.inviteCohortParticipantModal.key]?.isVisible}
                onCloseModal={(isShow) => {
                    onSetModal(modalConst.inviteCohortParticipantModal.key, modalConst.inviteCohortParticipantModal)
                }}
                children={<InviteCohortParticipantModal />}
            />
            <Modal
                title={modal[modalConst.addCohortSessionModal.key]?.title}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.addCohortSessionModal.key]?.isVisible}
                onCloseModal={(isShow) => {
                    onSetModal(modalConst.addCohortSessionModal.key, modalConst.addCohortSessionModal)
                }}
                children={<AddCohortSessionModal />}
            />
        </>
    );
};

export default ModalContainer;