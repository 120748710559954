import { memo, useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { MdEdit } from 'react-icons/md';

import AiTextButton from 'components/createWithAi/aiTextButton';

import useAiText from 'hooks/useAiText';

import { cn } from "utils/cn.utils";
import { debounce } from 'utils/generators.utils';

const SectionDescription = ({
    isLoading = false,
    descriptionPlaceholder = "Sub Header (optional)",
    containerClassName = "",
    inputClassName = "",
    description = "",
    rows = 3,
    maxLength = 512,
    autoFocus = true,
    descriptionInputStatus = "",
    setDescriptionInputStatus = "",
    descriptionTextContainerClassName = "",
    customViewTextDescription = () => { },
    descriptionPlaceholderStyle = "",
    isShowInputLabel,
    inputLabelClassName,
    onHandleFocusInput,
    onHandleSaveDescription,
    isShowAiBtn = false
}) => {
    const [isShowDescription, setIsShowDescription] = useState(false)
    const [isMouseHovered, setIsMouseHovered] = useState(false)
    const [descriptionInput, setDescriptionInput] = useState(description)
    const [isShowInput, setIsShowInput] = useState(false)
    const [topic, setTopic] = useState('')

    const { aiText, generateAiText } = useAiText()

    const requestData = {
        purpose: "description", // description or title 
        total_chars: 150,
        topic: topic,
        response_type: "text" // text or html 
    };

    const getAiText = () => {
        if (topic === "") {
            toast.error("Please write ai prompt");
            return
        }
        setIsShowInput(false)
        generateAiText(requestData);
    };

    const handlePrompt = (e) => {
        const value = e.target.value
        setTopic(value)
    }

    useEffect(() => {
        if (aiText?.data?.ai_response) {
            setDescriptionInput(aiText.data.ai_response);
            onHandleSaveDescription(aiText.data.ai_response)
        }
    }, [aiText?.data]);

    const onHandleChangeInputDescription = (event) => {
        const value = event?.target?.value
        setDescriptionInputStatus("Typing...")
        setDescriptionInput(value)

        if (value?.length > maxLength) {
            setDescriptionInputStatus("Typing...")
            return;
        }

        const delayFunction = debounce.debounce(onHandleSaveDescription)
        delayFunction(value)
    }

    const onHandleBlurInput = () => {
        setIsShowDescription(!isShowDescription)
        setIsMouseHovered(false)
    }


    return (
        <div className='flex gap-1'>
            {!isShowDescription &&
                <div
                    className={cn("relative w-full flex items-center justify-between gap-3 py-1 cursor-pointer group", descriptionTextContainerClassName)}
                    onMouseOver={() => setIsMouseHovered(true)}
                    onMouseOut={() => setIsMouseHovered(false)}
                    onClick={() => setIsShowDescription(!isShowDescription)}
                >
                    {!descriptionInput &&
                        <span className={cn(
                            "font-bodyPri font-normal text-text-700 group-hover:text-primary-main text-sm cursor-pointer whitespace-nowrap",
                            descriptionPlaceholderStyle
                        )}>
                            {descriptionPlaceholder || "Add Description (optional)"}
                        </span>
                    }
                    <div className='w-full px-3 text-sm text-text-700 bg-white hover:bg-back-ground-lightBlue'>
                        {descriptionInput &&
                            customViewTextDescription() || descriptionInput
                        }
                    </div>
                    {isMouseHovered &&
                        <button className={"absolute right-0"}>
                            <MdEdit className={"text-xl text-text-800 cursor-pointer"} />
                        </button>
                    }
                </div>
            }

            {isShowDescription &&
                <div className={cn("flex-1 w-full space-y-1 transition-transform ease-in-out duration-300 hover:bg-back-ground-lightBlue", containerClassName)}>
                    <div className={"relative w-full group flex items-center justify-start"}>
                        <textarea
                            type={'text'}
                            className={cn(
                                "relative translate-y-1 flex w-full p-3 focus:outline-none bg-back-ground-light hover:bg-text-100 border-b border-text-400",
                                "focus:border-primary-dark transition ease-in-out duration-300 overflow-hidden",
                                'font-bodyPri font-normal text-text-800 text-sm tracking-wide placeholder:text-text-500', inputClassName
                            )}
                            id={"Description"}
                            name={"Description"}
                            value={descriptionInput}
                            rows={rows}
                            maxLength={maxLength}
                            onChange={onHandleChangeInputDescription}
                            onFocus={onHandleFocusInput}
                            onBlur={onHandleBlurInput}
                            autoFocus={autoFocus}
                        />
                        {isShowInputLabel &&
                            <label for={"Description"} className={cn(
                                "absolute z-1 left-3 whitespace-nowrap cursor-text",
                                "transition ease-in-out duration-300",
                                "font-bodyPri font-normal text-sm text-text-500",
                                "group-focus-within:-translate-y-6 group-focus-within:-translate-x-4 group-focus-within:scale-75 group-focus-within:text-primary-dark",
                                "group-focus-within:bg-back-ground-light group-focus-within:py-0 group-focus-within:cursor-default",
                                descriptionInput && "-translate-y-6 -translate-x-4 scale-75 !text-primary-dark", inputLabelClassName,
                                descriptionInput && "bg-back-ground-light py-0 cursor-default",
                            )}>
                                {descriptionPlaceholder}
                            </label>
                        }
                    </div>
                    <div className={"h-2.5"}>
                        {((descriptionInputStatus === "Typing...") || isLoading) && (
                            <div className={"w-full flex items-center justify-between gap-3 my-2"}>
                                <div className={""}>
                                    {((descriptionInput?.trim()?.length > maxLength)) &&
                                        <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
                                            {`Please enter maximum ${maxLength} characters`}
                                        </span>
                                    }
                                </div>
                                <div className={"flex flex-row items-center justify-end gap-3"}>
                                    {!isLoading &&
                                        <span className={"font-bodyPri font-normal text-sm text-text-700"}>
                                            {`${descriptionInput?.trim()?.length}/${maxLength}`}
                                        </span>
                                    }
                                    <span className={"font-bodyPri font-normal text-sm text-text-700"}>
                                        {descriptionInputStatus}
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            }
            {isShowAiBtn && (
                <div className='relative flex mt-1 justify-end'>
                    <AiTextButton
                        btnClassName="rounded h-6"
                        containerClassName="top-0 right-0"
                        btnTitle=''
                        tooltip='Create description with AI'
                        isShowInput={isShowInput}
                        setIsShowInput={setIsShowInput}
                        handlePrompt={handlePrompt}
                        getAiText={getAiText}
                        aiText={aiText}
                    />
                </div>
            )}
        </div>
    )
};

export default memo(SectionDescription);