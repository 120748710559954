import { useFormContext } from 'react-hook-form';

import { cn } from 'utils/cn.utils';

const InviteeLocation = ({ values }) => {

    const { register, errors } = useFormContext()

    return (
        <div className={"space-y-0.5"}>
            <div className={"flex items-center justify-end"}>
                <span className={"font-bodyPri font-normal text-xs text-text-600 text-right"}>
                    {`${values?.session_locations[0]?.invitee?.custom_text?.length} / {10, 500} characters`}
                </span>
            </div>
            <div
                className={cn(
                    "relative group w-full h-full min-h-20 p-2 flex gap-3 items-center border rounded focus-within:shadow",
                    !!errors?.session_locations[0]?.invitee?.custom_text
                        ? 'border-secondary-dark focus-within:border-secondary-main shadow shadow-secondary-main/20 focus-within:shadow-secondary-main/20'
                        : !!values?.session_locations[0]?.invitee?.custom_text
                            ? 'border-primary-main focus-within:border-primary-main shadow shadow-primary-main/20 focus-within:shadow-primary-main/20'
                            : 'border-text-400 focus-within:border-primary-main focus-within:shadow-primary-main/20'
                )}
            >
                <textarea
                    className={('w-full h-full bg-transparent outline-none text-base')}
                    placeholder={"Write your place..."}
                    {...register("session_locations[0].invitee.custom_text")}
                />
            </div>
            {!!errors?.session_locations[0]?.invitee?.custom_text && (
                <div className={"flex items-center justify-start"}>
                    <span className={"font-bodyPri font-normal text-xs text-secondary-main"}>
                        {errors?.session_locations[0]?.invitee?.custom_text?.message}
                    </span>
                </div>
            )}
        </div>
    )
}

export default InviteeLocation;