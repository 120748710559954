import { useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getParticipantCourseCohortDetail } from "redux/course/course.request";
import courseServices from "redux/course/course.services";

import { useTitle } from "hooks/useTitle";
import { useAppState, useAppDispatcher } from "hooks/useStore";
import { resetParticipantCohortCourseDetail } from "redux/course/course.slice";

import { pagesInfo } from "utils/pagesInfo";
import { getSessionUserDetail } from "redux/session/session.request";
import { resetSessionUserDetail } from "redux/session/session.slice";
import { dayjs, timeZone } from "utils/dateTime.utils";

const COHORT_SECTION_INITIAL_STATE = { isLoading: false, result: [], error: null }

const initialActive = { previous: null, current: null, next: null }

const findClosestSession = (sessions) => {
    const now = dayjs().tz(timeZone)

    const futureSessions = sessions.filter(session => dayjs(session.start_datetime).tz(timeZone).isAfter(now));
    if (futureSessions.length) {
        return futureSessions.reduce((closest, session) => {
            return dayjs(session.start_datetime).tz(timeZone).diff(now) <
                dayjs(closest.start_datetime).tz(timeZone).diff(now)
                ? session
                : closest;
        });
    } else {
        return sessions.reduce((closest, session) => {
            return now.diff(dayjs(session.end_datetime).tz(timeZone)) <
                now.diff(dayjs(closest.end_datetime).tz(timeZone))
                ? session
                : closest;
        });
    }
};

const useGetCohortDetail = (config = {}) => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { user } = useAppState((state) => state.user)

    const params = useParams()
    const dispatcher = useAppDispatcher()
    const [title, setTitle] = useTitle()

    useEffect(() => {
        setTitle({
            ...title,
            title: `Edulyte Cohort Study Mode`
        })
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.STUDENT_COHORT))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        if (!!params?.slug && !!user?.user?.userId) {
            dispatcher(getParticipantCourseCohortDetail(params?.slug, user?.user?.userId))
        }

        return () => {
            dispatcher(resetParticipantCohortCourseDetail())
        }
    }, [params?.slug, user?.user?.userId])

    const { cohortSectionList, setCohortSectionList, activeSection, setActiveSection, activeSession, setActiveSession } = useHandleCohortSection()

    return { cohortSectionList, setCohortSectionList, activeSection, setActiveSection, activeSession, setActiveSession }
}

const useGetCohortSectionList = (config = {}) => {

    const params = useParams()
    const [cohortSectionList, setCohortSectionList] = useState(COHORT_SECTION_INITIAL_STATE)

    const getCohortSectionList = async () => {
        setCohortSectionList((prevState) => ({ ...prevState, isLoading: true }))

        try {
            const requestData = {
                params: { cohortId: params?.slug }
            }
            const response = await courseServices.getCohortSectionList(requestData)
            if (response.status === 200) {
                setCohortSectionList((prevState) => ({ ...prevState, result: response?.data?.data?.result }))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            setCohortSectionList((prevState) => ({ ...prevState, error: error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!" }))
            toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
        } finally {
            setCohortSectionList((prevState) => ({ ...prevState, isLoading: false }))
        }
    }

    useEffect(() => {
        if (!!params?.slug) {
            getCohortSectionList()
        }
    }, [params?.slug])

    return { cohortSectionList, setCohortSectionList }
}

const useGetSessionDetail = (config = {}) => {
    const { user } = useAppState((state) => state.user)

    const dispatcher = useAppDispatcher()
    const params = useParams()

    useEffect(() => {
        if (!!params?.sessionId && !!user?.user?.userId) {
            dispatcher(getSessionUserDetail(Number(params?.sessionId), user?.user?.userId))
        }

        return () => {
            dispatcher(resetSessionUserDetail())
        }
    }, [params?.sessionId, user?.user?.userId])
}

const useHandleCohortSection = () => {

    const [activeSection, setActiveSection] = useState();
    const [activeSession, setActiveSession] = useState(initialActive)

    const params = useParams()
    const navigate = useNavigate()

    const { cohortSectionList, setCohortSectionList } = useGetCohortSectionList()

    const cohortSections = useMemo(() => cohortSectionList?.result ?? [], [cohortSectionList?.result])

    useEffect(() => {
        if (
            !params?.sessionId &&
            !!cohortSections?.length &&
            !!cohortSections[0]?.sessions?.length
        ) {
            let cohort_sections_sessions = cohortSections?.reduce((initial, cohort_section) => {
                return [...initial, ...cohort_section?.sessions]
            }, [])

            if (!!cohort_sections_sessions?.length) {

                const closestSession = findClosestSession(cohort_sections_sessions)
                navigate(`session/${closestSession?.id}`, { replace: true })
            }
        }
    }, [cohortSections])

    useEffect(() => {
        if (
            params?.sessionId &&
            !!cohortSections?.length &&
            !!cohortSections[0]?.sessions?.length
        ) {

            let section_session_id = Number(params?.sessionId)

            for (let index = 0; index < cohortSections?.length; index++) {
                let section_session = cohortSections[index]?.sessions?.find(section_session => section_session_id === section_session?.id)
                if (!!section_session) {
                    setActiveSection({
                        ...initialActive,
                        previous: (index > 0) ? cohortSections[(index - 1)] : null,
                        current: cohortSections[index],
                        next: (index >= 0 && index < (cohortSections?.length - 1)) ? cohortSections[(index + 1)] : null
                    })
                    break;
                }
            }

            let section_sessions = cohortSections?.reduce((initial, course_section) => {
                return [...initial, ...course_section?.sessions]
            }, [])
            for (let index = 0; index < section_sessions?.length; index++) {
                if (section_session_id === section_sessions[index]?.id) {
                    setActiveSession({
                        ...initialActive,
                        previous: (index > 0) ? section_sessions[(index - 1)] : null,
                        current: section_sessions[index],
                        next: (index >= 0 && index < (section_sessions?.length - 1)) ? section_sessions[(index + 1)] : null
                    })
                    break;
                }
            }
        }
    }, [cohortSections, params?.sessionId])

    return { cohortSectionList, setCohortSectionList, activeSection, setActiveSection, activeSession, setActiveSession }
}

export {
    useGetCohortDetail,
    useGetSessionDetail,
    useGetCohortSectionList,
    useHandleCohortSection
}