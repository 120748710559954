import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';

import ButtonLoader from 'components/loader/ButtonLoader';

import { classDetailTabConst, cohortsNavigationOptions } from 'pages/auth/editClass/data';

import courseServices from 'redux/course/course.services';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';

import { validateEmail } from 'utils/validation-functions';
import { cn } from "utils/cn.utils";
import { pagesInfo } from 'utils/pagesInfo';

const inviteParticipantInitialState = { isLoading: false, result: null, payload: null, error: null }

const InviteCohortParticipantModal = () => {
    const { modal } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { instructorCourse } = useAppState((state) => state.course)

    const inputRef = useRef()
    const navigate = useNavigate()
    const dispatcher = useAppDispatcher()

    const [emailAddress, setEmailAddress] = useState("")
    const [invalidEmail, setInvalidEmail] = useState("")
    const [invitationDetail, setInvitationDetail] = useState(inviteParticipantInitialState)

    useEffect(() => {
        if (!!invitationDetail?.result) {
            dispatcher(setModal({
                ...modal,
                [modalConst.inviteCohortParticipantModal.key]: {
                    ...modal[modalConst.inviteCohortParticipantModal.key],
                    isVisible: false,
                    title: ""
                }
            }))
            navigate(`${pagesInfo?.CREATE_COURSE?.pagePath}/${instructorCourse?.result?.id}/edit${classDetailTabConst.cohorts.pagePath}/${modal.inviteCohortParticipantModal.payload.cohortId}/${cohortsNavigationOptions.participants.routePath}`)
        }
    }, [invitationDetail?.result])

    useEffect(() => {
        if (emailAddress && !validateEmail(emailAddress)) {
            setInvalidEmail("Please enter valid email address.")
        }
        if (emailAddress && validateEmail(emailAddress)) {
            setInvalidEmail("")
        }
    }, [emailAddress])

    const onHandleChangeEmailAddress = (event) => {
        if (invitationDetail?.error) {
            setInvitationDetail((prevState) => ({ ...prevState, error: null }))
        }
        setEmailAddress(event?.target?.value?.toLowerCase())
    }

    const onHandleKeyDown = (e) => {
        if ((e.keyCode === 13) && emailAddress) {
            onHandleSendInvite()
        }
    }

    const onHandleSendInvite = async () => {
        if (invitationDetail?.isLoading || !emailAddress || invalidEmail) return;

        // if (!invitationDetail?.payload?.invitedUsers || (invitationDetail?.payload?.invitedUsers?.length === 0)) return;

        setInvitationDetail((prevState) => ({ ...prevState, isLoading: true }))

        try {
            const requestData = {
                params: { cohortId: modal.inviteCohortParticipantModal.payload.cohortId },
                body: {
                    email: emailAddress,
                    invite_email_sent: false,
                    added_by_user_id: user?.user?.userId
                }
            }
            const response = await courseServices.createCohortParticipant(requestData)
            if (response.status === 201) {
                setInvitationDetail((prevState) => ({ ...prevState, result: response?.data?.data?.result }))
                toast.success(response.data.data.message || "Created successfully")
            } else {
                throw new Error()
            }
        } catch (error) {
            console.error(error?.response?.data?.error || error?.response?.data?.error || "Something went wrong")
            toast.error(error?.response?.data?.error || error?.response?.data?.error || "Something went wrong")
            setInvitationDetail((prevState) => ({ ...prevState, error: error?.response?.data?.error || error?.response?.data?.error || "Something went wrong" }))
        } finally {
            setInvitationDetail((prevState) => ({ ...prevState, isLoading: false }))
        }
    }

    return (
        <div className={"space-y-5"}>
            <div className={'min-h-[14rem] block space-y-5'}>
                <div className={"w-full space-y-3"}>
                    <span className={"font-bodyPri font-medium text-text-900 text-lg mt-0.5"}>
                        {"To:"}
                    </span>
                    <input
                        type={"email"}
                        ref={inputRef}
                        className={cn(
                            "w-full p-2 border border-divider-medium rounded-lg focus:outline-none bg-transparent focus:border-primary-main",
                            "font-bodyPri font-normal text-text-900 text-base placeholder:text-text-500"
                        )}
                        placeholder={"Enter email address"}
                        value={emailAddress}
                        onChange={onHandleChangeEmailAddress}
                        autoFocus={true}
                        onKeyDown={onHandleKeyDown}
                    />
                </div>
                <div className={"w-full flex items-center justify-center gap-1 mt-1"}>
                    {((emailAddress?.length > 0) && !!invalidEmail) &&
                        <span className={"font-bodyPri font-normal text-red-500 text-sm tracking-wide"}>
                            {invalidEmail}
                        </span>
                    }
                </div>
            </div>

            <div className={"w-full flex items-center justify-center"}>
                {invitationDetail?.error &&
                    <span className={"font-bodyPri font-normal text-red-500 text-base tracking-wide text-center"}>
                        {invitationDetail?.error}
                    </span>
                }
            </div>

            <div className={"flex justify-end"}>
                <span
                    className={cn(
                        "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                        "font-buttons font-normal text-base text-secondary-main",
                        !invitationDetail?.isLoading && "border border-secondary-main hover:bg-secondary-main hover:text-text-50",
                        invitationDetail?.isLoading && "bg-secondary-main",
                        ( !emailAddress || invalidEmail) && "text-secondary-light border-secondary-light hover:bg-white hover:text-secondary-light"
                    )}
                    onClick={onHandleSendInvite}
                >
                    {invitationDetail?.isLoading &&
                        <ButtonLoader isLoading={invitationDetail?.isLoading} />
                    }
                    {!invitationDetail?.isLoading && "Send"}
                </span>
            </div>
        </div>
    )
}

export default InviteCohortParticipantModal;