import { memo, useMemo } from "react";

const TabView = ({ tabs, activeTab, setActiveTab }) => {

    const activeTabButtonStyle = useMemo(() => ({
        color: '#0741ad',
        borderColor: '#0741ad',
    }), [])

    return (
        <>
            {tabs.map((tab, index) => (
                <button
                    key={index}
                    onClick={() => setActiveTab(tab.value)}
                    style={activeTab === tab.value ? activeTabButtonStyle : {}}
                    className='border-b h-10 px-5 font-bodyPri whitespace-nowrap border-divider-darkLight bg-back-ground-light duration-200 ease-in-out text-back-ground-black hover:bg-back-ground-lightBlue'
                >
                    {tab.label}
                </button>
            ))}
        </>
    )
}

export default memo(TabView);