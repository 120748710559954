import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

import { FiEdit } from "react-icons/fi";
import { TbChevronsRight } from "react-icons/tb";
import { BiCategoryAlt } from "react-icons/bi";
import { FaRegShareSquare } from "react-icons/fa";

import ToolTipView from 'components/tooltipView';

import Menu from "pages/auth/offerings/components/Menu";
import { menuItems } from "pages/auth/offerings/data";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal, setShareSocial } from 'redux/local/local.slice';
import { modalConst, socialAction } from 'redux/local/local.const';
import { courseStatus, visibility } from "redux/course/course.const";

import { pagesInfo } from 'utils/pagesInfo';
import { cn } from 'utils/cn.utils';
import { getCustomHostPath } from 'utils/generators.utils';
import { EDULYTE_WEB_DOMAIN_URL } from 'const/default.const';
import useCustomNavigation from 'hooks/useCustomNavigation';

const LiveClassCardUpdated = ({ course, updateCourseDetail, deleteCourseDetail }) => {
    const { modal, shareSocial } = useAppState((state) => state.local)
    const { publicOrgDetail } = useAppState((state) => state.org)

    const navigate = useNavigate()
    const dispatcher = useAppDispatcher()
    const customNavigation = useCustomNavigation()

    // const TUTOR_COURSE_URL = `${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/class/${course?.slug}` })}`
    const TUTOR_COURSE_URL = `${customNavigation.getCustomBaseUrl({ path: `/class/${course?.slug}`, isWeb: true })}`

    const onHandleEditOffering = () => {
        navigate(`${pagesInfo?.CREATE_COURSE?.pagePath}/${course?.id}/edit`)
    }

    const onHandleLink = () => {
        if (course?.status === courseStatus?.PUBLISHED?.value) {
            window.open(TUTOR_COURSE_URL)
        } else {
            onHandleEditOffering()
        }
    }

    const onHandleShareOffering = () => {
        dispatcher(setShareSocial({
            ...shareSocial,
            socialAction: socialAction.SHARE_TUTOR_COURSE,
            url: TUTOR_COURSE_URL
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.SHARE_SOCIAL_MODAL.stateKey]: true
        }))
    }

    const status = Object?.values(courseStatus).filter(dropDown => dropDown.value === course?.status)[0]?.label

    const Draft = {
        border: "border-2 border-orange-500",
        bg: "bg-orange-500",
        text: "text-orange-500",
        iconColor: '#e0e0e0',
        textColor: 'text-text-300'
    }, Published = {
        border: "border-2 border-green-500",
        bg: "bg-green-600",
        text: "text-green-600",
        iconColor: '#424242',
        textColor: 'text-text-800'
    }, InActive = {
        border: "border-2 border-red-500",
        bg: "bg-red300",
        text: "text-red-500",
        iconColor: '#424242',
        textColor: 'text-text-800'
    };

    let borderAsPerStatus = ''
    let bgAsPerStatus = ''
    let textAsPerStatus = ''
    let iconColorAsPerStatus = ''
    let textColorAsPerStatus = ''

    switch (course?.status) {
        case courseStatus.DRAFT.value:
            borderAsPerStatus = Draft.border
            bgAsPerStatus = Draft.bg
            textAsPerStatus = Draft.text
            iconColorAsPerStatus = Draft.iconColor
            textColorAsPerStatus = Draft.textColor
            break;
        case courseStatus.PUBLISHED.value:
            borderAsPerStatus = Published.border
            bgAsPerStatus = Published.bg
            textAsPerStatus = Published.text
            iconColorAsPerStatus = Published.iconColor
            textColorAsPerStatus = Published.textColor
            break;
        case courseStatus.INACTIVE.value:
            borderAsPerStatus = InActive.border
            bgAsPerStatus = InActive.bg
            textAsPerStatus = InActive.text
            iconColorAsPerStatus = InActive.iconColor
            textColorAsPerStatus = InActive.textColor
            break;
        default:
            borderAsPerStatus = ''
            bgAsPerStatus = ''
            textAsPerStatus = ''
            break;
    }

    const Public = {
        border: borderAsPerStatus,
        bg: bgAsPerStatus,
        text: textAsPerStatus,
        promote: "border-2 border-primary-main text-primary-main cursor-pointer hover:bg-primary-main hover:text-white",
        iconColor: '#2194f3',
        textColor: 'text-text-800'
    };

    const Private = {
        border: borderAsPerStatus,
        bg: bgAsPerStatus,
        text: textAsPerStatus,
        promote: "border-2 border-text-300 text-text-300",
        iconColor: '#e0e0e0',
        textColor: 'text-text-300'
    };

    const Hidden = {
        border: borderAsPerStatus,
        bg: bgAsPerStatus,
        text: textAsPerStatus,
        promote: "border-2 border-text-300 text-text-300",
        iconColor: '#e0e0e0',
        textColor: 'text-text-300'
    };

    let border = ''
    let background = ''
    let text = ''
    let promote = ''
    let iconColor = ''
    let textColor = ''

    switch (course?.visibility) {
        case visibility?.PUBLIC?.value:
            border = Public.border
            background = Public.bg
            text = Public.text
            promote = Public.promote
            iconColor = Public.iconColor
            textColor = Public.textColor
            break;
        case visibility?.PRIVATE?.value:
            border = Private.border
            background = Private.bg
            text = Private.text
            promote = Private.promote
            iconColor = Private.iconColor
            textColor = Private.textColor
            break;
        case "hidden":
            border = Hidden.border
            background = Hidden.bg
            text = Hidden.text
            promote = Hidden.promote
            iconColor = Hidden.iconColor
            textColor = Hidden.textColor
            break;
        default:
            border = ''
            background = ''
            text = ''
            promote = ''
            iconColor = ''
            textColor = ''
            break;
    }

    return (
        <div className='w-full rounded-lg bg-gradient-to-r from-primary-light to-[#FFF4CD] shadow'>
            <div className='flex'>
                <div className='flex w-full md:w-2/3 p-3'>
                    <div className='flex flex-col justify-between gap-3 w-full'>
                        <div className='flex md:flex-row flex-col-reverse justify-between items-start gap-3'>
                            <h2
                                onClick={onHandleLink}
                                className={cn("text-lg md:text-xl font-tagLine font-bold text-back-ground-black",
                                    status === courseStatus.PUBLISHED.label && "hover:underline hover:text-secondary-dark cursor-pointer"
                                )}>
                                {course?.title?.length > 50 ? course?.title?.substring(0, 50) + '...' : course?.title}
                            </h2>
                            <div className='flex items-center self-end md:self-start gap-4 text-base text-text-800'>
                                <ToolTipView content={'Edit course'}>
                                    <button className='hover:text-secondary-dark' onClick={onHandleEditOffering}>
                                        <FiEdit />
                                    </button>
                                </ToolTipView>
                                {status === courseStatus.PUBLISHED.label && (
                                    <ToolTipView content={'Share'}>
                                        <button className='hover:text-secondary-dark' onClick={onHandleShareOffering}>
                                            <FaRegShareSquare />
                                        </button>
                                    </ToolTipView>
                                )}
                                <ToolTipView content={'More options'}>
                                    <div className='mt-0.5'>
                                        <Menu
                                            course={course}
                                            updateCourseDetail={updateCourseDetail}
                                            deleteCourseDetail={deleteCourseDetail}
                                        />
                                    </div>
                                </ToolTipView>
                            </div>
                        </div>
                        <div className='flex flex-col md:flex-row justify-between gap-5'>
                            <div className='flex gap-3 items-center text-text-700'>
                                <ToolTipView content={`Category : ${course?.category?.segment?.segment + "/" + course?.category?.category}`} >
                                    <div className='cursor-pointer'>
                                        <BiCategoryAlt size={20} />
                                    </div>
                                </ToolTipView>
                                {(course?.visibility === menuItems.MAKE_PUBLIC.value && course?.status !== courseStatus.INACTIVE.value) && (
                                    <ToolTipView content={'Public'}>
                                        <div className="h-4 w-4 rounded-full bg-green-600 cursor-pointer" />
                                    </ToolTipView>
                                )}
                                {(course?.visibility === menuItems.MAKE_PRIVATE.value) && (
                                    <ToolTipView content={'Private'}>
                                        <div className="h-4 w-4 rounded-full bg-text-600 cursor-pointer" />
                                    </ToolTipView>
                                )}
                            </div>
                            <motion.button
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                onClick={onHandleLink}
                                className={cn("h-10 flex gap-5 items-center self-start md:self-end bg-white pl-5 rounded-full shadow font-semibold font-subHeading ease-in-out duration-200",
                                    text
                                )}>
                                {status}
                                <div className={cn('h-10 w-10 flex justify-center items-center rounded-full text-white text-lg', background)}>
                                    <TbChevronsRight />
                                </div>
                            </motion.button>
                        </div>
                    </div>
                </div>
                <div className='hidden md:flex w-1/3 rounded-lg overflow-hidden'>
                    <img
                        className='w-full h-32 object-cover'
                        src={course?.thumbnail_pic_url || "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/home-page/live-one-on-one-lesson-learning-never-stop.jpg"}
                        alt={"class-thumbnail"}
                    />
                </div>
            </div>
        </div >
    )
}

export const LiveClassCardUpdatedLoader = () => {
    return (
        <div className='animate-pulse rounded-lg shadow h-32 w-full bg-white space-y-3'>
            <div className='flex'>
                <div className='flex flex-col justify-between h-32 w-full md:w-2/3 p-3'>
                    <div className='flex w-full gap-20 justify-between'>
                        <div className='h-10 w-full bg-slate-200 animate-pulse rounded' />
                        <div className='h-6 w-32 bg-slate-200 animate-pulse rounded' />
                    </div>
                    <div className='flex w-full gap-20 justify-between'>
                        <div className='flex gap-3'>
                            <div className='h-5 w-5 bg-slate-200 animate-pulse rounded-full' />
                            <div className='h-5 w-5 bg-slate-200 animate-pulse rounded-full' />
                            <div className='h-5 w-5 bg-slate-200 animate-pulse rounded-full' />
                            <div className='h-5 w-5 bg-slate-200 animate-pulse rounded-full' />
                            <div className='h-5 w-5 bg-slate-200 animate-pulse rounded-full' />
                        </div>
                        <div className='h-8 w-40 bg-slate-200 animate-pulse rounded-full' />
                    </div>
                </div>
                <div className='hidden h-32 md:flex w-1/3 rounded-lg overflow-hidden bg-slate-200 animate-pulse' ></div>
            </div>
        </div>
    )
}

export default LiveClassCardUpdated
