import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { cn } from "utils/cn.utils";

import { FaSpinner } from 'react-icons/fa'

import { messageTemplateList } from 'components/modals/messageTemplateModal/MessageTemplateModal.data';

import { createFBIndividualChat, sendFBMessage, sendNotifications } from 'redux/firebase/firebase.request';
import { sendMessageEmail } from "redux/notification/notification.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';
import { setClearMessageTemplate, setMessageTemplate } from 'redux/firebase/firebase.slice';

import { pagesInfo } from 'utils/pagesInfo';
import { getCustomHostPath } from 'utils/generators.utils';
import useCustomNavigation from 'hooks/useCustomNavigation';

const MessageTemplateModal = () => {
    const { modal } = useAppState((state) => state.local)
    const { publicOrgDetail } = useAppState((state) => state.org)
    const { user } = useAppState((s) => s.user)
    const { messageTemplate } = useAppState(s => s.firebase)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const customNavigation = useCustomNavigation()

    const [selectedMessage, setSelectedMessage] = useState('')
    const [inputMessage, setInputMessage] = useState('')

    const minCharacterLength = 20
    const maxCharacterLength = 50

    const isSendEnable = !["", "Other"].includes(selectedMessage) || (selectedMessage === "Other" && inputMessage.length > 20 && inputMessage.length < 50)

    useEffect(() => {
        if (messageTemplate?.message && user?.user?.userId === messageTemplate?.userIdOne && messageTemplate?.tutorUserId === messageTemplate?.userIdTwo) {
            createFBChatAndRedirect()
        }
    }, [messageTemplate?.message])

    const createFBChatAndRedirect = async () => {
        const fbChat = await createFBIndividualChat(messageTemplate?.userIdOne, messageTemplate?.userIdTwo)

        if (!fbChat || fbChat === undefined) {
            dispatcher(setMessageTemplate({
                ...messageTemplate,
                isLoading: false,
                errorMsg: "Chat not exist!",
            }))
            return;
        }

        await sendFBMessage({
            senderUserId: user?.user?.userId,
            text: messageTemplate?.message,
            chatId: fbChat.chatId
        })
        await sendNotifications({
            chatId: fbChat.chatId,
            senderUserId: messageTemplate?.userIdOne,
            receiverUserId: messageTemplate?.userIdTwo,
            type: "text",
        })
        dispatcher(sendMessageEmail({
            receiverUserId: messageTemplate?.userIdTwo,
            senderUserId: messageTemplate?.userIdOne,
            message: (selectedMessage === 'Other' ? inputMessage : selectedMessage),
            // chatLink: `${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, pathname: `${pagesInfo?.MESSAGES.pagePath}/${fbChat.chatId}` })}`
            chatLink: `${customNavigation.getCustomBaseUrl({ path: `${pagesInfo?.MESSAGES.pagePath}/${fbChat.chatId}` })}`
        }))

        navigate(`${pagesInfo.MESSAGES.pagePath}/${fbChat.chatId}`)

        dispatcher(setClearMessageTemplate())
        dispatcher(setModal({
            ...modal,
            [modalConst.MESSAGE_TEMPLATE_MODAL.stateKey]: false
        }))
    }

    const handleSendMessage = () => {
        if (!isSendEnable) return;

        dispatcher(setMessageTemplate({
            ...messageTemplate,
            isLoading: true,
            message: (selectedMessage === 'Other' ? inputMessage : selectedMessage),
            errorMsg: null
        }))
    }

    return (
        <div className={"space-y-5"}>
            <div className={'min-h-[24rem] block space-y-5 pt-5'}>
                <div className={"w-full space-y-3"}>
                    <div className={"mx-3 flex flex-col items-start justify-start gap-5"}>
                        {messageTemplateList?.map((messageTemp, index) => (
                            <div key={index}
                                onClick={() => {
                                    setSelectedMessage(messageTemp.message);
                                    if (messageTemplate?.errorMsg) {
                                        dispatcher(setMessageTemplate({
                                            ...messageTemplate,
                                            isLoading: false,
                                            errorMsg: null
                                        }))
                                    }
                                }}
                                className={cn(
                                    "px-3 py-1 rounded-lg border-2 border-primary-dark text-primary-dark cursor-pointer",
                                    "hover:bg-primary-dark hover:text-text-50",
                                    selectedMessage === messageTemp.message && "!bg-primary-dark !text-text-50"
                                )}
                            >
                                <span className={"font-bodyPri font-medium text-base"}>
                                    {messageTemp.message}
                                </span>
                            </div>
                        ))}
                    </div>
                    {selectedMessage === "Other" &&
                        <div className={"flex flex-col items-start gap-3 px-3"}>
                            <textarea
                                className={cn(
                                    "w-full px-5 py-3 bg-back-ground-light rounded-lg resize-none",
                                    "outline-none border-2 border-primary-dark focus:shadow-all-md focus:shadow-primary-light focus:border-primary-dark",
                                    "font-bodyPri font-normal text-base text-text-800 tracking-wide"
                                )}
                                minLength={minCharacterLength}
                                maxLength={maxCharacterLength}
                                rows={2}
                                placeholder="Write here ...."
                                name={"message"}
                                value={inputMessage}
                                onChange={(event) => {
                                    setInputMessage(event.target.value);
                                    if (messageTemplate?.errorMsg) {
                                        dispatcher(setMessageTemplate({
                                            ...messageTemplate,
                                            isLoading: false,
                                            errorMsg: null
                                        }))
                                    }
                                }}
                            />
                            <div className={"w-full flex justify-between"}>
                                <span className={cn(
                                    "font-bodyPri font-normal text-xs text-text-800",
                                    (inputMessage.length < minCharacterLength || inputMessage.length > maxCharacterLength) && "text-red-500"
                                )}>
                                    {`Please enter between ${minCharacterLength} and ${maxCharacterLength} characters`}
                                </span>
                                <span className="flex justify-end font-bodyPri font-normal text-text-800 text-xs">
                                    {inputMessage.length + "/" + maxCharacterLength}
                                </span>
                            </div>
                        </div>
                    }
                </div>
            </div>

            <div className={"flex justify-between"}>
                <div className={"flex items-center justify-center gap-2"}>
                    {messageTemplate?.isLoading &&
                        <>
                            <span className={"font-bodyPri font-normal text-base text-text-700"}>
                                {"Loading"}
                            </span>
                            <FaSpinner className="text-primary-main animate-spin" />
                        </>
                    }
                    {messageTemplate?.errorMsg &&
                        <span className={"font-bodyPri font-normal text-base text-red-500"}>
                            {messageTemplate?.errorMsg}
                        </span>
                    }
                </div>
                <span onClick={handleSendMessage} className={cn(
                    "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                    "font-buttons font-normal text-base text-secondary-main",
                    "border border-secondary-main hover:bg-secondary-main hover:text-text-50",
                    !isSendEnable && "text-secondary-light border-secondary-light hover:bg-white hover:text-secondary-light"
                )}>
                    {"Send"}
                </span>
            </div>
        </div>
    )
}

export default MessageTemplateModal