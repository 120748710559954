import format from "string-format";

import { baseApiInstance, baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/course/course.const";

class CourseService {

    // Course service
    createCourse = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_COURSE),
            body
        )

        return response;
    }
    updateCourse = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_COURSE, params),
            body
        )

        return response;
    }
    deleteCourse = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_COURSE, params)
        )

        return response;
    }

    // Instructor Course Service
    getInstructorCourses = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_INSTRUCTOR_COURSES, params),
            { params: query }
        )

        return response;
    }
    getInstructorCourse = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_INSTRUCTOR_COURSE, params)
        )

        return response;
    }

    // tag
    getCourseTags = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COURSE_TAGS, params)
        )

        return response;
    }
    // create tag detail
    createCourseTag = async ({ params, body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_COURSE_TAG, params),
            body
        )

        return response;
    }
    // update tag order list
    updateCourseTagOrderList = async ({ params, body, query }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_COURSE_TAG_ORDER_LIST, params),
            body,
            { params: query }
        )

        return response;
    }
    // delete tag detail
    deleteCourseTag = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_COURSE_TAG, params)
        )

        return response;
    }

    // languages
    getCourseLanguages = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COURSE_LANGUAGES, params)
        )

        return response;
    }
    // create language
    createCourseLanguage = async ({ params, body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_COURSE_LANGUAGE, params),
            body
        )

        return response;
    }

    // update language order list
    updateCourseLanguageOrderList = async ({ params, body, query }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_COURSE_LANGUAGE_ORDER_LIST, params),
            body,
            { params: query }
        )

        return response;
    }

    // delete language
    deleteCourseLanguage = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_COURSE_LANGUAGE, params)
        )

        return response;
    }

    // prof
    getCourseProfs = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COURSE_PROFS, params)
        )

        return response;
    }
    // create prof
    createCourseProf = async ({ params, body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_COURSE_PROF, params),
            body
        )

        return response;
    }

    // update prof order list
    updateCourseProfOrderList = async ({ params, body, query }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_COURSE_PROF_ORDER_LIST, params),
            body,
            { params: query }
        )

        return response;
    }

    // delete prof
    deleteCourseProf = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_COURSE_PROF, params)
        )

        return response;
    }

    // faq
    getCourseFaqs = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COURSE_FAQS, params)
        )

        return response;
    }
    // create faq
    createCourseFaq = async ({ params, body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_COURSE_FAQ, params),
            body
        )

        return response;
    }

    // update faq
    updateCourseFaq = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_COURSE_FAQ, params),
            body,
        )

        return response;
    }

    // update faq order
    updateCourseFaqOrderList = async ({ params, body, query }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_COURSE_FAQ_ORDER_LIST, params),
            body,
            { params: query }
        )

        return response;
    }

    // delete faq
    deleteCourseFaq = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_COURSE_FAQ, params)
        )

        return response;
    }

    // create cohort
    createCohortCourse = async ({ params, body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_COHORT_COURSE, params),
            body
        )

        return response;
    }
    getCohortDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COHORT_DETAIL, params)
        )

        return response;
    }
    updateCohortDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_COHORT_DETAIL, params),
            body
        )

        return response;
    }

    // cohort section
    getCohortSectionList = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COHORT_SECTION_LIST, params)
        )

        return response;
    }
    createCohortSection = async ({ params, body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_COHORT_SECTION, params),
            body
        )

        return response;

    }
    updateCohortSection = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_COHORT_SECTION, params),
            body
        )

        return response;
    }
    updateCohortSectionOrderList = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_COHORT_SECTION_ORDER_LIST, params),
            body
        )

        return response;
    }
    deleteCohortSection = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_COHORT_SECTION, params)
        )

        return response;
    }

    // course participants
    getCohortParticipantList = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COHORT_PARTICIPANT_LIST, params),
            query
        )

        return response;
    }
    createCohortParticipant = async ({ params, body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_COHORT_PARTICIPANT_DETAIL, params),
            body
        )

        return response;
    }

    updateCohortParticipantDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_COHORT_PARTICIPANT_DETAIL, params),
            body
        )

        return response;
    }

    // my cohorts
    getParticipantCohortCourseList = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_PARTICIPANT_COHORT_COURSE_LIST, params),
            { params: query }
        )

        return response;
    }

    // participant cohort course detail
    getParticipantCohortCourseDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_PARTICIPANT_COHORT_COURSE_DETAIL, params),
        )

        return response;
    }

    // ##### OLD SERVICES #########
    // NEW COURSE SERVICES
    getUserCourseList = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_USER_COURSE_LIST, params),
            { params: query }
        )

        return response;
    }

    getUserFavoriteCourseList = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_USER_FAV_COURSE_LIST, params),
            { params: query }
        )

        return response;
    }

    getPublicTutorCourseList = async ({ params, query }) => {
        const response = await baseApiInstance().get(
            format(url.GET_PUBLIC_TUTOR_COURSE_LIST, params),
            { params: query }
        )

        return response;
    }

    createCourseFavoriteDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_COURSE_FAVORITE_DETAIL),
            body
        )

        return response;
    }

    deleteCourseFavoriteDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.CREATE_COURSE_FAVORITE_DETAIL),
            { data: body }
        )

        return response;
    }

    // Public Course Service
    getPublicCourseList = async ({ query }) => {
        const response = await baseApiInstance().get(
            format(url.GET_PUBLIC_COURSE_LIST),
            { params: query }
        )

        return response;
    }
    getPublicCourseDetail = async ({ params }) => {
        const response = await baseApiInstance().get(
            format(url.GET_PUBLIC_COURSE_DETAIL, params)
        )

        return response;
    }

    // Course Template Service
    getCourseTemplateList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COURSE_TEMPLATE_LIST),
            { params: query }
        )

        return response;
    }

    getCourseTemplateDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COURSE_TEMPLATE_DETAIL, params)
        )

        return response;
    }

    // Course service
    createCourseDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_COURSE_DETAIL),
            body
        )

        return response;
    }
    getCourseDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COURSE_DETAIL, params)
        )

        return response;
    }
    updateCourseDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_COURSE_DETAIL, params),
            body
        )

        return response;
    }
    deleteCourseDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_COURSE_DETAIL, params)
        )

        return response;
    }

    // Course Preview Service
    getCoursePreviewDetail = async ({ query, params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COURSE_PREVIEW_DETAIL, params),
            { params: query }
        )

        return response;
    }

    // Course Tag Service
    createCourseTagDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_COURSE_TAG_DETAIL),
            body
        )

        return response;
    }
    getCourseTagList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COURSE_TAG_LIST),
            { params: query }
        )

        return response;
    }
    updateCourseTagListOrder = async ({ body, query }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_COURSE_TAG_LIST_ORDER),
            body, { params: query }
        )

        return response;
    }
    deleteCourseTagDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_COURSE_TAG_DETAIL, params)
        )

        return response;
    }

    // Course Language Service
    createCourseLanguageDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_COURSE_LANGUAGE_DETAIL),
            body
        )

        return response;
    }
    getCourseLanguageList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COURSE_LANGUAGE_LIST),
            { params: query }
        )

        return response;
    }
    updateCourseLanguageListOrder = async ({ body, query }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_COURSE_LANGUAGE_LIST_ORDER),
            body, { params: query }
        )

        return response;
    }
    deleteCourseLanguageDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_COURSE_LANGUAGE_DETAIL, params)
        )

        return response;
    }

    // Course Proficiency Service
    createCourseProficiencyDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_COURSE_PROFICIENCY_DETAIL),
            body
        )

        return response;
    }
    getCourseProficiencyList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COURSE_PROFICIENCY_LIST),
            { params: query }
        )

        return response;
    }
    updateCourseProficiencyListOrder = async ({ body, query }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_COURSE_PROFICIENCY_LIST_ORDER),
            body, { params: query }
        )

        return response;
    }
    deleteCourseProficiencyDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_COURSE_PROFICIENCY_DETAIL, params)
        )

        return response;
    }

    // Course Availability Service
    createCourseAvailabilityDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_COURSE_AVAILABILITY_DETAIL),
            body
        )

        return response;
    }
    getCourseAvailabilityDetail = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COURSE_AVAILABILITY_DETAIL, params),
            { params: query }
        )

        return response;
    }
    updateCourseAvailabilityDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_COURSE_AVAILABILITY_DETAIL, params),
            body
        )

        return response;
    }
    deleteCourseAvailabilityDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_COURSE_AVAILABILITY_DETAIL, params)
        )

        return response;
    }

    // Course Price Service
    createCoursePriceDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_COURSE_PRICE_DETAIL),
            body
        )

        return response;
    }
    getCoursePriceDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COURSE_PRICE_DETAIL, params)
        )

        return response;
    }
    updateCoursePriceDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_COURSE_PRICE_DETAIL, params),
            body
        )

        return response;
    }
    deleteCoursePriceDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.DELETE_COURSE_PRICE_DETAIL, params)
        )

        return response;
    }

    // tutor course list
    getTutorCourseList = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_TUTOR_COURSE_LIST, params),
            { params: query }
        )

        return response;
    }

    // tutor course detail
    getTutorCourseDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_TUTOR_COURSE_DETAIL, params)
        )

        return response;
    }

    // clone course detail
    createCloneCourseDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CLONE_COURSE_DETAIL, params),
            body
        )

        return response;
    }

    // Course Tutor Service
    getCourseTutorList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COURSE_TUTOR_LIST),
            { params: query }
        )

        return response;
    }
    getCourseTutorDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COURSE_TUTOR_DETAIL, params)
        )

        return response;
    }

    // add next session detail
    createNextCourseSessionDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_NEXT_COURSE_SESSION_DETAIL, params),
            body
        )

        return response;
    }

    // get course comment list
    getCourseCommentList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_COURSE_COMMENT_LIST),
            { params: query }
        )

        return response;
    }
    createCourseCommentDetail = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_COURSE_COMMENT_DETAIL),
            body
        )

        return response;
    }
}

export default new CourseService()