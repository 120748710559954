import { useEffect, useMemo } from 'react';
import { Navigate, useLocation, Outlet } from "react-router-dom";

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setLocals } from 'redux/local/local.slice';
import { localsConst } from 'redux/local/local.const';
import { userRoles } from 'redux/auth/auth.const';

import { pagesInfo } from 'utils/pagesInfo';

export default function TutorProductsPage({ allowedRoles }) {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { locals } = useAppState((s) => s.local)
    const { session } = useAppState((s) => s.auth)
    const { user } = useAppState((s) => s.user)

    const dispatcher = useAppDispatcher()
    const location = useLocation()

    const searchQueryParams = useMemo(() => new URLSearchParams(location.search), [location.search])

    // useEffect(() => {
    //     dispatcher(setPageInfo(currentPageInfo, pagesInfo.TUTOR_PRODUCTS))
    // }, [dispatcher, currentPageInfo])

    useEffect(() => {
        if (session?.isLoggedIn && (userRoles.TUTOR.value !== locals.userRole) && user?.user?.policy_permissions?.find(permission => allowedRoles?.includes(permission?.name) && (searchQueryParams.get("role_as") === userRoles.TUTOR.value))) {
            dispatcher(setLocals({
                ...locals,
                [localsConst.USER_ROLE.value]: userRoles.TUTOR.value
            }))
        }
    }, [location.search, session?.isLoggedIn])

    return (
        <div className={"h-full w-full p-3 mx-auto min-h-screen overflow-hidden bg-background-light"}>
            {session?.isLoggedIn && user?.user?.policy_permissions?.find(permission => allowedRoles?.includes(permission?.name) && ((searchQueryParams.get("role_as") === userRoles.TUTOR.value) || (permission?.name === locals.userRole)))
                ? <Outlet />
                : <Navigate to={pagesInfo.UNAUTHORIZED.pagePath} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />
            }
        </div>
    );
}