import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { toast } from 'react-toastify';

import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import ButtonLoader from 'components/loader/button-loader';
import PageLoader from 'components/loader/PageLoader';

import { cohortDetailSchema } from 'pages/auth/editClass/data';
import PageHeading from 'pages/auth/editClass/_components/header/pageHeading';

import { useGetCohortDetail } from './_hooks/detail.hooks';
import BasicInfo from './basicInfo';
import EnrollmentInfo from './enrollmentInfo';
import PriceInfo from './priceInfo';

import courseServices from 'redux/course/course.services';

import { cohortEnrollmentCloseEnum } from 'redux/course/course.const';
import { cn } from 'utils/cn.utils';
import { dayjs } from "utils/dateTime.utils";

const DetailPage = () => {

    const { cohortId } = useParams()

    const form = useForm({
        mode: "onChange",
        resolver: zodResolver(cohortDetailSchema),
        defaultValues: {}
    })

    const {
        handleSubmit,
        setValue,
        reset,
        formState: { isLoading, isValid, errors, isDirty },
        watch,
        control
    } = form;

    const { cohortDetail, setCohortDetail, getCohortDetail } = useGetCohortDetail({ setValue })
    const values = watch({ control: control })

    useEffect(() => {
        reset({ ...cohortDetail?.result })

        return () => {
            reset()
        }
    }, [form])

    const onSubmit = async (data) => {
        let bodyData = {
            title: data?.title,
            enrollment_start_datetime: dayjs(data?.enrollment_start_datetime).utc().format("YYYY-MM-DD HH:mm:ss"),
            enrollment_close: data?.enrollment_close,
            status: data?.status,
            is_paid: data?.is_paid,
        }
        if (!!data?.description) {
            bodyData["description"] = data?.description
        }
        if ([cohortEnrollmentCloseEnum.participant_limit.key]?.includes(data?.enrollment_close)) {
            bodyData["participant_limit"] = data?.participant_limit
        }
        if ([cohortEnrollmentCloseEnum.datetime.key]?.includes(data?.enrollment_close)) {
            bodyData["enrollment_close_datetime"] = dayjs(data?.enrollment_close_datetime).utc().format("YYYY-MM-DD HH:mm:ss")
        }
        if (!!data?.is_paid) {
            bodyData["cohort_price"] = data?.cohort_price
        }
        try {
            const requestData = {
                params: { cohortId: Number(cohortId) },
                body: bodyData
            }
            const response = await courseServices.updateCohortDetail(requestData)
            if (response.status === 200) {
                setCohortDetail((prevState) => ({ ...prevState, result: response?.data?.data?.result }))
                toast.success(response.data.data.message || "Updated successfully")
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
            toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
        }
    }

    return (
        <div className={"w-full space-y-5"}>
            <PageHeading headerText={"Cohort Detail"} />
            {cohortDetail?.isLoading &&
                <PageLoader />
            }
            {(!cohortDetail?.isLoading && cohortDetail?.error) &&
                <div className={"w-full flex items-center justify-center"}>
                    <span className={"font-bodyPri font-normal text-red-500"}>
                        {cohortDetail?.error || "No cohort found!"}
                    </span>
                </div>
            }
            {(!cohortDetail?.isLoading && !!cohortDetail?.result) &&
                <FormProvider {...form}>
                    <form
                        className={"w-full mx-auto container max-w-6xl space-y-5 md:space-y-8"}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <BasicInfo />
                        <EnrollmentInfo enrollmentDetail={values} />
                        <PriceInfo priceDetail={values} />
                        <div className={"flex justify-center items-center py-5 space-x-5"}>
                            <button
                                type={"button"}
                                className={cn(
                                    "py-2 px-3 text-text-900 text-sm md:text-base ease-in-out duration-200 border border-text-400 hover:border-text-600 rounded",
                                    "font-bodyPri font-normal text-sm sm:text-base",
                                    cohortDetail?.isLoading && "cursor-wait"
                                )}
                                disabled={cohortDetail?.isLoading || isLoading}
                                onClick={() => { reset(); getCohortDetail() }}
                            >
                                {cohortDetail?.isLoading &&
                                    <ButtonLoader className={"h-10"} />
                                }
                                {!cohortDetail?.isLoading &&
                                    <span className={""}>
                                        {"Reset"}
                                    </span>
                                }
                            </button>
                            <button
                                type={"submit"}
                                className={cn(
                                    "py-2 px-3 text-white text-sm md:text-base ease-in-out duration-200 rounded",
                                    "font-bodyPri font-normal text-sm sm:text-base",
                                    (cohortDetail?.isLoading || isLoading)
                                        ? "cursor-wait"
                                        : (isValid || isDirty)
                                            ? "bg-primary-dark hover:bg-secondary-dark"
                                            : "bg-text-300 text-text-200 hover:bg-text-300 cursor-not-allowed"
                                )}
                                disabled={cohortDetail?.isLoading || isLoading}
                            >
                                {(isLoading || cohortDetail?.isLoading) &&
                                    <ButtonLoader className={"py-2.5"} />
                                }
                                {(!isLoading && !cohortDetail?.isLoading) &&
                                    <span className={""}>
                                        {"Save"}
                                    </span>
                                }
                            </button>
                        </div>
                    </form>
                </FormProvider>
            }
        </div>
    )
}

export default DetailPage;