import { useCallback, useEffect, useMemo, useState } from "react";
import { cn } from "utils/cn.utils";
import { Link, useLocation } from "react-router-dom";

import ComponentLoader from "components/loader/ComponentLoader";
import {OfferingCard, OfferingTutorCard} from "components/offeringCard/OfferingCard"

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { getTutorCourseList, getUserCourseList } from 'redux/course/course.request';
import { setClearTutorCourseList, setClearUserCourseList } from 'redux/course/course.slice';
import { userRoles } from 'redux/auth/auth.const';

import { generateQueryParams } from 'utils/generators.utils';
import { pagesInfo } from 'utils/pagesInfo';
import { toast } from "react-toastify";
import axios from "axios";
import courseServices from "redux/course/course.services";
import { getCourseListPayload } from "pages/auth/offerings/data";


interface InitialState {
  isLoading: boolean;
  result: any; 
  pagination: any; 
  message: string | null;
}

const cohortListInitialState: InitialState = {
  isLoading: false,
  result: null,
  pagination: null,
  message: null
};

const coursesState: InitialState = {
  isLoading: false,
  result: null,
  pagination: null,
  message: null
}

const RECORDS = 5

export const MyOfferings: React.FC = () => {
  const { user } = useAppState((s: any) => s.user)
  const { userCourseList, tutorCourseList } = useAppState((s: any) => s.course)
  const { locals } = useAppState((s: any) => s.local)

  const dispatcher = useAppDispatcher()
  const location = useLocation();
  const [cohortList, setCohortList] = useState<InitialState>(cohortListInitialState);
  const [courses, setCourses] = useState<InitialState>(coursesState);
  const searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

  const getInstructorCourseList = useCallback(async () => {
    setCourses({ ...coursesState, isLoading: true })
    try {
      const queryParamPayload = await getCourseListPayload(searchQueryParams)
      queryParamPayload['records'] = RECORDS
      const requestData = {
        params: { user_id: user?.user?.userId },
        query: queryParamPayload
      }
      const response = await courseServices.getInstructorCourses(requestData)
      if (response.status === 200) {
        setCourses(course => ({ ...course, result: response.data.data.result, pagination: response.data.data?.pagination }))
      } else {
        throw new Error(response.data.error || "Oops, Something went wrong!")
      }
    } catch (error) {
        if (axios.isAxiosError(error)) {
          console.error(error.response?.data?.message || error.response?.data?.error || 'Something went wrong!');
          toast.error(error.response?.data?.message || error.response?.data?.error || 'Something went wrong!');
        } else {
          console.error('Unknown error', error);
          toast.error('Something went wrong!');
        }
      } finally {
      setCourses(course => ({ ...course, isLoading: false }))
    }
  }, [user?.user?.userId, searchQueryParams])

  const getParticipantCohortCourseList = async (
      participantId: Number,
      query: any
    ) => {
      setCohortList((prevState) => ({ ...prevState, isLoading: true }));

      try {
        query['records'] = RECORDS
        const requestData = {
          params: { participantId: participantId },
          query: query
        };
        const response = await courseServices.getParticipantCohortCourseList(requestData);

        if (response.status === 200) {
          setCohortList((prevState) => ({
            ...prevState,
            result: response?.data?.data?.result,
            pagination: response?.data?.data?.pagination
          }));
        } else {
          throw new Error('Non-200 response');
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.error(error.response?.data?.message || error.response?.data?.error || 'Something went wrong!');
          toast.error(error.response?.data?.message || error.response?.data?.error || 'Something went wrong!');
        } else {
          console.error('Unknown error', error);
          toast.error('Something went wrong!');
        }
      } finally {
        setCohortList((prevState) => ({ ...prevState, isLoading: false }));
      }
    };


  useEffect(() => {

    if (locals.userRole === userRoles.STUDENT.value) {
      const query = generateQueryParams(location.search)
      
      getParticipantCohortCourseList(user?.user?.userId, query)
    }

    if (locals.userRole === userRoles.TUTOR.value) {
      getInstructorCourseList()
    }

    return () => {
      dispatcher(setClearUserCourseList())
      dispatcher(setClearTutorCourseList())
    }
  }, [locals.userRole])

  return (
    <div className="w-full p-5 bg-white shadow-md rounded-lg">
      <div className="flex justify-between">
        <p className="text-xl text-text-900 font-medium font-bodyPri tracking-wider">
          {"My Classes"}
        </p>
        <p className="text-sm text-text-700 font-bodyPri">
          {locals.userRole === userRoles.STUDENT.value &&
            <Link to={pagesInfo.MY_COURSES.pagePath}>
              {"View all"}
            </Link>
          }
          {locals.userRole === userRoles.TUTOR.value &&
            <Link to={`${pagesInfo.TUTOR_PRODUCTS.pagePath}/live-classes` + "?pageNo=1"}>
              {"View all"}
            </Link>
          }
        </p>
      </div>

      <div className={cn(
        "grid grid-flow-col gap-8 px-4 py-4 overflow-hidden overflow-x-scroll",
        "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-sm",
        "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
      )}>
        <ComponentLoader isLoading={courses?.result?.isLoading} />
        {(locals.userRole === userRoles.STUDENT.value && cohortList?.pagination?.records > 0) &&
          cohortList?.result?.map((courses: any, idx: any) => (
            <OfferingCard key={idx} cohort={courses} />
          ))
        }
        {(locals.userRole === userRoles.TUTOR.value && courses?.pagination?.records > 0) &&
          courses?.result?.map((course: any, idx: any) => (
            <OfferingTutorCard key={idx} course={course} />
          ))
        }
        {(cohortList?.message || courses?.message || cohortList?.pagination?.records === 0 || courses?.pagination?.records === 0) &&
          <span className="w-full font-bodyPri font-normal text-text-800 text-md text-center">
            {cohortList?.message || courses?.message || "No classes found!"}
          </span>
        }
      </div>
    </div>
  );
};
