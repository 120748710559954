import { useEffect, useState } from 'react';
import { Navigate, useLocation, Outlet } from "react-router-dom";

import { pageHeading, navigationOptions } from 'pages/auth/settings/settings.data';

import PageHeader from 'components/pageHeader/PageHeader';

import MySettingsNavigation, { MySettingsNavOptions } from "./components/SettingsNavigation";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { pagesInfo } from 'utils/pagesInfo';

import { useTitle } from 'hooks/useTitle';

export default function GeneralSettingsPage({ allowedRoles }: any) {
  const { currentPageInfo } = useAppState((state: any) => state.pageInfo)
  const { locals } = useAppState((s) => s.local)
  const { session } = useAppState((s) => s.auth)
  const { user } = useAppState((s) => s.user)

  const dispatcher = useAppDispatcher()
  const location = useLocation()
  const [title, setTitle] = useTitle()

  const [tabList, setTabList] = useState<MySettingsNavOptions[]>(navigationOptions)

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.GENERAL_SETTINGS))
  }, [dispatcher, currentPageInfo])

  useEffect(() => {
    setTitle({
      ...title,
      title: `App Settings for ${user?.user?.firstName} | Edulyte`
    })
  }, [dispatcher, currentPageInfo])

  useEffect(() => {
    const filteredOptions: MySettingsNavOptions[] =
      navigationOptions.filter((option: MySettingsNavOptions) => option.roles.includes(locals.userRole))
    setTabList(filteredOptions)
  }, [locals.userRole])

  return (
    <div className="h-full w-full p-3 mx-auto min-h-screen space-y-3">
      <PageHeader pageHeading={pageHeading} />

      <div className="flex flex-col space-y-3 md:space-y-0 md:space-x-3 md:flex-row">
        <MySettingsNavigation options={tabList} />
        <div className="w-full rounded-lg overflow-hidden p-5 bg-white">
          {session?.isLoggedIn && user?.user?.policy_permissions?.find((permission: any) => allowedRoles?.includes(permission?.name) && permission?.name === locals.userRole)
            ? <Outlet />
            : <Navigate to={pagesInfo.UNAUTHORIZED.pagePath} state={{ from: {...location, url: `${location.pathname}${location.search}${location.hash}`} }} replace />
          }
        </div>
      </div>
    </div>
  );
}