import { useNavigate, NavLink } from "react-router-dom";

import { IoMdArrowRoundBack } from "react-icons/io";
import { IoMdShare } from "react-icons/io";
import { MdFullscreen, MdFullscreenExit } from "react-icons/md";

import { motion } from "framer-motion";

import ToolTipView from 'components/tooltipView/index';
import ThreeDotMenu from "components/threeDotMenu/ThreeDotMenu";

import useCustomNavigation from "hooks/useCustomNavigation";
import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal, setShareSocial } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";

import { pagesInfo } from "utils/pagesInfo";

const menuItemEnum = Object.freeze({
    dashboard: {
        label: "My Dashboard",
        value: "dashboard"
    },
    share: {
        label: "Share",
        value: "share"
    },
    fullscreen: {
        label: "Full Screen",
        value: "fullscreen"
    },
    exit_fullscreen: {
        label: "Exit Full Screen",
        value: "exit_fullscreen"
    },
})

const HeaderView = ({
    isFullScreen = false,
    toggleFullScreen = () => { }
}) => {
    const { modal, shareSocial } = useAppState(state => state.local)
    const { participantCohortCourse } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const customNavigation = useCustomNavigation()

    const COURSE_URL = `${customNavigation.getCustomBaseUrl({ path: `/class/${participantCohortCourse?.result?.cohort?.course?.slug}`, isWeb: true })}`

    const handleShareCourse = () => {
        dispatcher(setShareSocial({
            ...shareSocial,
            socialAction: "",
            url: COURSE_URL
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.SHARE_SOCIAL_MODAL.stateKey]: true
        }))
    }

    const handleBack = () => {
        navigate(-1)
    }

    const selectMenuOption = (option) => {
        switch (option.value) {
            case menuItemEnum.dashboard.value:
                customNavigation.navigation({ path: "/dashboard" })
                break;
            case menuItemEnum.share.value:
                handleShareCourse()
                break;
            case menuItemEnum.fullscreen.value:
                toggleFullScreen()
                break;
            case menuItemEnum.exit_fullscreen.value:
                toggleFullScreen()
                break;
            default:
                return;
        }
    }

    return (
        <header className='flex items-center justify-between w-full h-16 px-2 lg:px-5 py-1 bg-white shadow-lg'>
            <div className='grow flex items-center gap-3 lg:gap-8'>
                <ToolTipView content={"Go Back"}>
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className='p-2 text-text-300 rounded-full ease-in-out duration-200 hover:bg-back-ground-medium'
                        onClick={handleBack}
                    >
                        <IoMdArrowRoundBack className='text-text-700' style={{ fontSize: "1.5rem" }} />
                    </motion.button>
                </ToolTipView>
                <div className={"grow flex items-center md:items-start justify-start gap-3"}>
                    <div className={"w-12 md:w-16 lg:w-20 aspect-video overflow-hidden rounded"}>
                        <img
                            src={participantCohortCourse?.result?.cohort?.course?.thumbnail_pic_url}
                            alt={participantCohortCourse?.result?.cohort?.course?.title}
                            className={"w-full h-full object-cover"}
                        />
                    </div>
                    <div className={"grow flex flex-col items-start justify-center"}>
                        <span className={"flex items-center justify-start gap-1 font-buttons font-normal text-sm text-text-600"}>
                            <NavLink to={pagesInfo.DASHBOARD.pagePath} className={"hover:text-secondary-dark hover:underline"}>
                                {"Dashboard"}
                            </NavLink>
                            <span>{"/"}</span>
                            <NavLink to={pagesInfo.MY_COURSES.pagePath} className={"hover:text-secondary-dark hover:underline whitespace-nowrap"}>
                                {"My Cohorts"}
                            </NavLink>
                        </span>
                        <span className={"font-bodyPri font-medium text-lg text-text-800 line-clamp-1"}>
                            {participantCohortCourse?.result?.cohort?.title ?? ""}
                        </span>
                    </div>
                </div>
            </div>
            <div className='grow-0 flex items-center gap-5'>
                <NavLink
                    to={pagesInfo.DASHBOARD.pagePath}
                    className='hidden lg:block bg-back-ground-lightBlue border border-primary-dark px-5 py-2 text-primary-dark rounded hover:bg-primary-light ease-in-out duration-200'
                >
                    {"My Dashboard"}
                </NavLink>
                <div className={"hidden lg:block"}>
                    <ToolTipView content={"Share"}>
                        <button
                            className='bg-back-ground-lightBlue border border-primary-dark p-2 text-primary-dark rounded hover:bg-primary-light ease-in-out duration-200'
                            onClick={handleShareCourse}
                        >
                            <IoMdShare className={"text-xl"} />
                        </button>
                    </ToolTipView>
                </div>
                <div className={"hidden lg:block"}>
                    <ToolTipView content={isFullScreen ? "Exit full screen" : "Full screen"}>
                        <button
                            className='bg-back-ground-lightBlue border border-primary-dark p-2 text-primary-dark rounded hover:bg-primary-light ease-in-out duration-200'
                            onClick={toggleFullScreen}
                        >
                            {isFullScreen
                                ? <MdFullscreenExit className='text-xl' />
                                : <MdFullscreen className='text-xl' />
                            }
                        </button>
                    </ToolTipView>
                </div>
                <div className="block lg:hidden bg-back-ground-lightBlue border border-primary-dark px-3 py-2 text-primary-dark rounded hover:bg-primary-light ease-in-out duration-200 cursor-pointer">
                    <ThreeDotMenu
                        menuItems={[
                            menuItemEnum.dashboard,
                            menuItemEnum.share,
                            isFullScreen ? menuItemEnum.exit_fullscreen : menuItemEnum.fullscreen
                        ]}
                        onHandleSelect={(option) => selectMenuOption(option)}
                    />
                </div>
            </div>
        </header>
    )
}

export default HeaderView;