import { Controller, useFormContext } from 'react-hook-form';

import { OptionSelector } from 'components/common-components/Select';
import { Toggle } from 'components/common-components/Toggle';

import { masterCurrencyCodeEnum } from 'redux/master/master.const';

import { cn } from 'utils/cn.utils';

const PriceInfo = ({ priceDetail }) => {

    const { register, unregister, getValues, setValue, control, trigger, formState: { errors } } = useFormContext()

    return (
        <div className={"w-full grid grid-cols-2 gap-5 font-bodyPri"}>
            <div className={"w-full col-span-full flex items-center justify-between gap-3"}>
                <p className={cn(
                    "font-bodyPri font-bold text-text-700 text-base tracking-wide",
                    !getValues("is_paid") && "text-green-500"
                )}>
                    {"This is Free"}
                </p>
                <Controller
                    name={"is_paid"}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <div className={"flex -m-2"}>
                            <Toggle
                                value={!value}
                                onChange={(toggledValue) => {
                                    onChange(!toggledValue)
                                    if (toggledValue) {
                                        unregister("cohort_price")
                                    } else {
                                        register("cohort_price.paid_session_count", { value: priceDetail?.cohort_price.paid_session_count ?? 0 });
                                        register("cohort_price.currency_code", { value: priceDetail?.cohort_price.currency_code ?? "" });
                                        register("cohort_price.price", { value: priceDetail?.cohort_price.price ?? 0 });
                                        register("cohort_price.discount_pct", { value: priceDetail?.cohort_price.discount_pct ?? 0 });

                                        setValue("cohort_price", {
                                            paid_session_count: priceDetail?.cohort_price?.paid_session_count ?? 0,
                                            currency_code: priceDetail?.cohort_price?.currency_code ?? "",
                                            price: priceDetail?.cohort_price?.price ?? 0,
                                            discount_pct: priceDetail?.cohort_price?.discount_pct ?? 0,
                                        });
                                        trigger(["cohort_price", "cohort_price.paid_session_count", "cohort_price.currency_code", "cohort_price.price"]);
                                    }
                                }}
                            />
                        </div>
                    )}
                />
            </div>

            {!!getValues("is_paid") &&
                <>
                    <div className={"col-span-full md:col-span-1 space-y-2"}>
                        <span className={"font-bodyPri font-bold text-text-700 text-base tracking-wide"}>
                            {"Currency Code"}<span className={"text-red-500"}>{"*"}</span>
                        </span>
                        <Controller
                            name={"cohort_price.currency_code"}
                            control={control}
                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <div className={"w-full space-y-2"}>
                                    <OptionSelector
                                        options={Object.values(masterCurrencyCodeEnum)}
                                        value={!!value ? value : "Select any"}
                                        onChange={(option) => onChange(option.value)}
                                    />
                                    {!!error && (
                                        <div className={"flex items-center justify-start"}>
                                            <span className={"font-bodyPri font-normal text-sm text-secondary-main"}>
                                                {error?.message}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            )}
                        />
                    </div>

                    <div className={"col-span-full md:col-span-1 space-y-2"}>
                        <span className={"font-bodyPri font-bold text-text-700 text-base tracking-wide"}>
                            {"Price"}<span className={"text-red-500"}>{"*"}</span>
                        </span>
                        <input
                            type={"number"}
                            placeholder={"Enter price here ..."}
                            className={cn(
                                "px-3 h-9 flex w-full input-number-spin-none focus:outline-none rounded-lg border border-back-ground-lightDark focus:border focus:border-primary-main",
                                "font-bodyPri font-normal text-text-800 text-base placeholder:text-text-500"
                            )}
                            value={(priceDetail?.cohort_price?.price / 100)?.toString()}
                            onChange={(event) => {
                                let safe_number = Number(event.target.value)
                                if (isNaN(safe_number)) {
                                    safe_number = 0
                                }
                                setValue("cohort_price.price", safe_number * 100)
                                trigger("cohort_price.price")
                            }}
                        />
                        {!!errors?.cohort_price?.price && (
                            <div className={"flex items-center justify-start"}>
                                <span className={"font-bodyPri font-normal text-sm text-secondary-main"}>
                                    {errors?.cohort_price?.price?.message}
                                </span>
                            </div>
                        )}
                    </div>

                    <div className={"col-span-full md:col-span-1 space-y-2"}>
                        <span className={""}>
                            {"Discount percentage"}
                        </span>
                        <input
                            type={"number"}
                            placeholder={"Enter discount price here ..."}
                            className={cn(
                                "px-3 h-9 flex w-full input-number-spin-none focus:outline-none rounded-lg border border-back-ground-lightDark focus:border focus:border-primary-main",
                                "font-bodyPri font-normal text-text-800 text-base placeholder:text-text-500"
                            )}
                            value={priceDetail?.cohort_price?.discount_pct?.toString()}
                            onChange={(event) => {
                                setValue("cohort_price.discount_pct", Number(event.target.value))
                                trigger("cohort_price.discount_pct")
                            }}
                            maxLength={100}
                        />
                        {!!errors?.cohort_price?.discount_pct && (
                            <div className={"flex items-center justify-start"}>
                                <span className={"font-bodyPri font-normal text-sm text-secondary-main"}>
                                    {errors?.cohort_price?.discount_pct?.message}
                                </span>
                            </div>
                        )}
                    </div>

                    <div className={"col-span-full md:col-span-1 space-y-2"}>
                        <span className={"font-bodyPri font-bold text-text-700 text-base tracking-wide"}>
                            {"Paid session count"}<span className={"text-red-500"}>{"*"}</span>
                        </span>
                        <input
                            type={"number"}
                            placeholder={"Enter session count here ..."}
                            className={cn(
                                "px-3 h-9 flex w-full input-number-spin-none focus:outline-none rounded-lg border border-back-ground-lightDark focus:border focus:border-primary-main",
                                "font-bodyPri font-normal text-text-800 text-base placeholder:text-text-500"
                            )}
                            value={priceDetail?.cohort_price?.paid_session_count?.toString()}
                            onChange={(event) => {
                                setValue("cohort_price.paid_session_count", Number(event.target.value))
                                trigger("cohort_price.paid_session_count")
                            }}
                            maxLength={100}
                        />
                        {!!errors?.cohort_price?.paid_session_count && (
                            <div className={"flex items-center justify-start"}>
                                <span className={"font-bodyPri font-normal text-sm text-secondary-main"}>
                                    {errors?.cohort_price?.paid_session_count?.message}
                                </span>
                            </div>
                        )}
                    </div>
                </>
            }
        </div>
    )
}

export default PriceInfo;