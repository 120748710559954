import { memo, useMemo } from "react";

import { FaSpinner } from 'react-icons/fa';

import { sessionNoteConst } from 'components/modals/sessionActionModal/SessionActionModal.data';

import {
    startSessionUser,
    acceptSessionUser,
    declineSessionUser,
    // rescheduleSessionUser,
    completeSessionUser
} from 'redux/session/session.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { sessionStatusEnum } from 'redux/session/session.const';

import { cn } from "utils/cn.utils";

const SessionActionFooter = memo(({ sessionUserInfo, selectedAction }) => {
    const {
        sessionUserDetail,
        startSessionUserDetail,
        acceptSessionUserDetail,
        declineSessionUserDetail,
        addSessionUserDetailPayload
    } = useAppState((state) => state.session)

    const dispatcher = useAppDispatcher()

    const loadingElement = useMemo(() => startSessionUserDetail?.isLoading || acceptSessionUserDetail?.isLoading || declineSessionUserDetail?.isLoading, [startSessionUserDetail?.isLoading, acceptSessionUserDetail?.isLoading, declineSessionUserDetail?.isLoading])

    const isSubmitDisable = useMemo(() => (sessionUserInfo?.status === selectedAction.value)
        || (sessionNoteConst[selectedAction.value.toUpperCase()].isNoteRequired
            && (addSessionUserDetailPayload?.payload?.note.length < 20
                || addSessionUserDetailPayload?.payload?.note.length > 100)), [sessionUserInfo, addSessionUserDetailPayload?.payload, selectedAction])

    // const handleOnCancel = () => {
    //     if (sessionUserDetail?.data?.result?.type === sessionTypeEnum.COHORT.value) {
    //         if (sessionUserInfo?.role === userRoles.STUDENT.value ||
    //             (sessionUserInfo?.role === userRoles.TUTOR.value && sessionUserDetail?.data?.result?.session_course?.type === courseType.ONE_ON_ONE.value)) {
    //             if (!window.confirm("Are you sure? \nThis event will be cancelled and it can't be undone.")) return;
    //             dispatcher(oldCancelBookingByBookingId(sessionUserDetail?.data?.result?.bookings[0].bookingId, { note: addSessionUserDetailPayload?.payload?.note }))
    //             return;
    //         }

    //         if (sessionUserDetail?.data?.result?.session_course?.type === courseType.GROUP.value) {
    //             if (sessionUserInfo?.role !== userRoles.TUTOR.value) {
    //                 alert("Only tutor will cancel group session.")
    //                 return;
    //             }
    //             if (!window.confirm("Are you sure? \nThis event will be cancelled and it can't be undone.")) return;
    //             dispatcher(oldCancelBookingByCourseId(sessionUserDetail?.data?.result?.session_course?.id, { note: addSessionUserDetailPayload?.payload?.note }))
    //             return;
    //         }
    //     }
    // }

    const handleSubmit = () => {
        if (isSubmitDisable) return;

        switch (selectedAction.value) {
            case sessionStatusEnum.PENDING.value:
                alert('Pending status is not changeable!')
                break;
            case sessionStatusEnum.DECLINED.value:
                if (!window.confirm("Are you sure?")) return;
                dispatcher(declineSessionUser(sessionUserDetail?.data?.result?.id, { comment: addSessionUserDetailPayload?.payload?.note }))
                break;
            case sessionStatusEnum.RESCHEDULED.value:
                // alert('Reschedule event coming soon!')
                // dispatcher(rescheduleSessionUser(sessionUserDetail?.data?.result?.id, body))
                break;
            case sessionStatusEnum.SCHEDULED.value:
                let scheduleComment = {}
                if (addSessionUserDetailPayload?.payload?.note?.length > 0) {
                    scheduleComment["comment"] = addSessionUserDetailPayload?.payload?.note
                }
                dispatcher(acceptSessionUser(sessionUserDetail?.data?.result?.id, scheduleComment))
                break;
            case sessionStatusEnum.ON_GOING.value:
                let onGoingComment = {}
                if (addSessionUserDetailPayload?.payload?.note?.length > 0) {
                    onGoingComment["comment"] = addSessionUserDetailPayload?.payload?.note
                }
                dispatcher(startSessionUser(sessionUserDetail?.data?.result?.id, onGoingComment))
                break;
            case sessionStatusEnum.COMPLETED.value:
                let completedComment = {}
                if (addSessionUserDetailPayload?.payload?.note?.length > 0) {
                    completedComment["comment"] = addSessionUserDetailPayload?.payload?.note
                }
                dispatcher(completeSessionUser(sessionUserDetail?.data?.result?.id, completedComment))
                break;
            case sessionStatusEnum.CANCELLED.value:
                alert('Cancel event coming soon!')
                // dispatcher(declineSessionUser(sessionUserDetail?.data?.result?.id, { comment: addSessionUserDetailPayload?.payload?.note }))
                break;
            default:
                alert("Something went wrong!")
        }
    }

    return (
        <div className={"flex justify-between"}>
            <div className={"flex items-center justify-center gap-2"}>
                {loadingElement &&
                    <>
                        <span className={"font-bodyPri font-normal text-base text-text-700"}>
                            {"Loading"}
                        </span>
                        <FaSpinner className="text-primary-main animate-spin" />
                    </>
                }
            </div>
            <span onClick={handleSubmit} className={cn(
                "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                "font-buttons font-normal text-base text-secondary-main",
                "border border-secondary-main hover:bg-secondary-main hover:text-text-50",
                (isSubmitDisable || loadingElement) &&
                "text-secondary-light border-secondary-light hover:bg-white hover:text-secondary-light cursor-not-allowed"
            )}>
                {"Submit"}
            </span>
        </div>
    )
})

export default SessionActionFooter