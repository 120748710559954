import { useFormContext } from 'react-hook-form';

import QuillEditor from 'components/textEditor/QuillEditor';

import { Editor } from 'pages/auth/editClass/data';

import { cn } from 'utils/cn.utils';

const BasicInfo = () => {

    const { register, getValues, setValue, trigger, formState: { errors } } = useFormContext()

    return (
        <div className={"w-full space-y-5 font-bodyPri font-normal"}>
            <div className={"w-full space-y-2"}>
                <span className={"font-bodyPri font-bold text-text-700 text-base tracking-wide"}>
                    {"Title"}<span className={"text-red-500"}>{"*"}</span>
                </span>
                <input
                    type={"text"}
                    placeholder={"Enter title here ..."}
                    className={cn(
                        "px-3 h-9 flex w-full focus:outline-none rounded-lg border border-back-ground-lightDark focus:border focus:border-primary-main",
                        "font-bodyPri font-normal text-text-800 text-base placeholder:text-text-500"
                    )}
                    {...register("title")}
                />
                {!!errors?.title &&
                    <span className="text-secondary-main text-sm">{errors?.title?.message}</span>
                }
            </div>
            <div className={"w-full space-y-2"}>
                <span className={"font-bodyPri font-bold text-text-700 text-base tracking-wide"}>
                    {"Description"}
                </span>
                <QuillEditor
                    editorToolModules={Editor?.modules}
                    editorToolFormats={Editor?.formats}
                    editorText={getValues("description")}
                    onHandleChangeText={(content) => {
                        setValue("description", content)
                        trigger("description")
                    }}
                    placeholder={"Start Typing"}
                />
            </div>
        </div>
    )
}

export default BasicInfo;