import { setAndGetLocalStorage } from "redux/local/local.utils"
import { localsConst, modalConst } from 'redux/local/local.const';


export const LOCAL_INITIAL_STATE = {
    locals: {
        userRole: setAndGetLocalStorage(localsConst.USER_ROLE.value),
        displayLanguage: setAndGetLocalStorage(localsConst.DISPLAY_LANGUAGE.value),
        currencyCode: setAndGetLocalStorage(localsConst.CURRENCY_CODE.value),
        timeZone: setAndGetLocalStorage(localsConst.TIME_ZONE.value),
        timeFormat: setAndGetLocalStorage(localsConst.TIME_FORMAT.value),
    },
    showRoleModal: {
        isShowRoleModal: true,
    },

    pages: {
        isThankYou: false,
    },

    shareSocial: {
        socialAction: null,
        url: "",
    },

    termsAndConditions: {
        termsAndConditionsAction: null,
    },
    previewImage: {
        previewUrl: "",
    },
    localTabs: {
        modalTitle: "",
        tabList: [],
        activeTabList: [],
        currentActiveTab: null,
        tabItem: {
            tabType: null,
            payload: null,
        },
    },

    modal: {
        isShowRoleSwitchModal: false,
        isShowCourseScheduleModal: false,
        isShowNoOfSessionModal: false,
        isShowChooseTimeSlotModal: false,
        isShowCourseCheckoutModal: false,
        isShowMakePaymentModal: false,
        isShowPaymentMethodModal: false,
        isShowTodoModal: false,
        isShowSessionDetailModal: false,
        isShowSessionActionModal: false,
        isShowCourseScheduleEditModal: false,
        isShowImagePickerModal: false,
        isShowUploadDocumentModal: false,
        isShowPreviewDocumentModal: false,
        isShowQuestionAnswerModal: false,
        isShowChooseIntervalSlot: false,
        isShowComingSoonModal: false,
        isShowMessageTemplateModal: false,
        isShowApplyReferralModal: false,
        isShowAuthorizationModal: false,
        isShowCodeVerificationModal: false,
        isShowSubmitCourseModal: false,
        isShowShareSocialModal: false,
        isShowPostRequirementModal: false,
        isShowCompanyPeopleModal: false,
        isShowSpeakToUsModal: false,
        isShowNeedATutorModal: false,
        isShowValidationBarEmailVerifyModal: false,
        isShowValidationBarMobileVerifyModal: false,
        isShowWithdrawMoneyModal: false,
        isShowConfirmPayoutModal: false,
        isShowWithdrawalRequestSubmitModal: false,
        isShowForgetPasswordModal: false,
        isShowRestPasswordModal: false,
        isShowVerifyAuthenticationModal: false,
        isShowUpdateEmailModal: false,
        isShowUpdateMobileNoModal: false,
        isShowUpdatePasswordModal: false,
        isShowUploadVideoModal: false,
        isShowRecordVideoModal: false,
        isShowPhotoSearchModal: false,
        isShowPromoModal: false,
        isShowAddBookingSessionModal: false,
        isShowAddReviewModal: false,
        isShowGetMoreReviewsModal: false,
        isShowAddOffDayModal: false,
        isShowCheckoutSlotModal: false,
        isShowPaymentCardModal: false,
        isShowRescheduleTimeSlotModal: false,
        isShowCancelBookingSessionModal: false,
        isShowAddCourseNextSessionModal: false,
        isShowManualMobileVerificationModal: false,
        isShowUploadDriveFileModal: false,
        isShowShareDriveFileModal: false,
        
        // lms modals
        isShowAttachLibraryModal: false,
        isShowCreateLmsArticleModal: false,
        isShowCreateLmsCourseModal: false,
        isShowCreateLmsQuestionModal: false,
        isShowCreateLmsQuizModal: false,
        isShowCreateLmsTopicModal: false,
        isShowCreateLmsLectureModal: false,
        isShowUploadLmsMediaModal: false,
        isShowLmsPreviewImageModal: false,
        isShowProductPriceDetailModal: false,
        inviteLmsCourseUserModal: modalConst.inviteLmsCourseUserModal,
        inviteLmsQuizUserModal: modalConst.inviteLmsQuizUserModal,
        addLmsLessonInstructorModal: modalConst.addLmsLessonInstructorModal,
        addLmsQuestionInstructorModal: modalConst.addLmsQuestionInstructorModal,
        addLmsQuizInstructorModal: modalConst.addLmsQuizInstructorModal,
        addLmsArticleInstructorModal: modalConst.addLmsArticleInstructorModal,
        addLmsLectureInstructorModal: modalConst.addLmsLectureInstructorModal,
        addEventlySlotModal: modalConst.addEventlySlotModal,
        viewEventlySlotModal: modalConst.viewEventlySlotModal,
        editEventlyEventSlotModal: modalConst.editEventlyEventSlotModal,
        viewContactDetailModal: modalConst.viewContactDetailModal,
        createCrmContactDetailModal: modalConst.createCrmContactDetailModal,
        updateCrmBulkContactListModal: modalConst.updateCrmBulkContactListModal,
        planTableModal: modalConst.planTableModal,
        addDownloadModal: modalConst.addDownloadModal,
        createAppointmentDetailModal: modalConst.createAppointmentDetailModal,
        inviteDownloadUserModal: modalConst.inviteDownloadUserModal,
        myDownloadDetailModal: modalConst.myDownloadDetailModal,
        appointmentLocationDetailModal: modalConst.appointmentLocationDetailModal,
        checkoutLocationModal: modalConst.checkoutLocationModal,
        createCertificateModal: modalConst.CREATE_CERTIFICATE_MODAL,
        createCourseModal: modalConst.createCourseModal,
        addCohortModal: modalConst.addCohortModal,
        inviteCohortParticipantModal: modalConst.inviteCohortParticipantModal,
        addCohortSessionModal: modalConst.addCohortSessionModal,
    },
};