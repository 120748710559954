import { useMemo, useState } from "react";

import format from "string-format";

import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

import { useAppState } from "hooks/useStore";

import { pagesInfo } from "utils/pagesInfo";
import { cn } from "utils/cn.utils";
import { currencyRateConversion, getCustomHostPath } from 'utils/generators.utils';

import { QR_CODE_URL } from 'const/default.const';
import useCustomNavigation from "hooks/useCustomNavigation";

const Refer = () => {
  const { locals } = useAppState((s) => s.local)
  const { publicOrgDetail } = useAppState((state) => state.org)
  const { user } = useAppState((s) => s.user)
  const [copiedURL, setCopiedURL] = useState(false);
  const [copiedCode, setCopiedCode] = useState(false);

  const customNavigation = useCustomNavigation()

  // const referralUrl = `${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, pathname: `${pagesInfo.REFERRAL_SIGN_UP.pagePath}/` })}`
  // const referralUrl = `${customNavigation.getCustomBaseUrl({ path: `${pagesInfo.REFERRAL_SIGN_UP.pagePath}/` })}`
  const referralUrl = `${window.location.origin}${pagesInfo.REFERRAL_SIGN_UP.pagePath}/`
  const referAndGetReward = "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/refer-and-earn-page/header-refer-and-earn.png"

  const qrUrl = useMemo(() => format(QR_CODE_URL, { link: referralUrl + user?.user?.referralCode }), [referralUrl, user?.user?.referralCode])

  const handleDownloadQRCode = () => {
    fetch(qrUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "qrcode.png";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => console.error("Error downloading QR code:", error));
  };

  const handleCopyURL = () => {
    navigator.clipboard.writeText(referralUrl + user?.user?.referralCode);
    setCopiedURL(true);
    setTimeout(() => {
      setCopiedURL(false);
    }, 2000);
  };

  const handleCopyCode = () => {
    navigator.clipboard.writeText(user?.user?.referralCode);
    setCopiedCode(true);
    setTimeout(() => {
      setCopiedCode(false);
    }, 2000);
  };

  return (
    <div className="bg-white rounded-lg shadow-all p-5">
      <div className="grid grid-cols-12 w-full">
        <div className="col-span-12 col-start-1 lg:col-span-4 flex items-center justify-center">
          <div className="w-60 h-60 flex justify-center items-center overflow-hidden">
            <img src={referAndGetReward} alt="Refer-Main" className="w-full h-full object-cover" />
          </div>
        </div>
        <div className="col-span-12 col-start-1 lg:col-start-5 lg:col-span-8 space-y-5">
          <div className={"w-full flex justify-center items-center flex-col"}>
            <span className="text-3xl font-medium text-primary-dark py-4">
              {"Refer a friend and get rewarded!"}
            </span>
            <span className="text-lg font-medium text-text-600">
              {"When a friend joins Edulyte and makes a purchase,"}
            </span>
            <span className="text-lg font-medium text-text-600">
              {`you will both get ${currencyRateConversion("USD", 500)} (approx ${currencyRateConversion(locals?.currencyCode, 500)}) credit in wallet.`}
            </span>
          </div>

          <div className="flex justify-center">
            <div className="flex flex-wrap flex-col gap-5">

              <div className={"flex flex-col items-center justify-center gap-3"}>
                <div className={"font-bodyPri font-medium text-center text-base text-text-900"}>
                  {"Your unique referral link and code"}
                </div>

                <div className={"w-full flex flex-col items-center justify-center gap-5 px-5 py-8 bg-pink-50 border border-pink-50 shadow-md rounded-lg"}>
                  <div className={"w-64 md:w-fit font-bodyPri font-normal text-text-900 text-base truncate"}>
                    {referralUrl}
                    <span className={"font-bodyPri font-semibold text-text-900 text-lg"}>
                      {user?.user?.referralCode}
                    </span>
                  </div>
                  <div className={"flex justify-between items-center gap-5"}>
                    <span className={cn(
                      "w-full md:w-48 px-2 py-1 font-bodyPri font-normal text-base text-center rounded-md cursor-pointer",
                      !copiedURL && "text-text-50 bg-secondary-dark hover:text-secondary-dark hover:bg-white hover:border hover:border-secondary-dark",
                      copiedURL && "bg-green-500 text-text-50 hover:bg-white hover:text-green-500 hover:border hover:border-green-500"
                    )}
                      onClick={handleCopyURL}
                    >
                      {copiedURL ? "Copied" : "Copy Link"}
                    </span>
                    <span className={cn(
                      "w-full md:w-48 px-2 py-1 font-bodyPri font-normal text-base text-center text-text-50 bg-secondary-dark border border-secondary-dark rounded-md cursor-pointer whitespace-nowrap",
                      !copiedCode && "hover:text-secondary-dark hover:bg-white hover:border hover:border-secondary-dark",
                      copiedCode && "bg-green-500 text-text-50 hover:bg-white hover:text-green-500 hover:border hover:border-green-500"
                    )}
                      onClick={handleCopyCode}
                    >
                      {copiedCode ? "Copied" : "Copy Code"}
                    </span>
                  </div>
                </div>
              </div>

              <div className="w-full flex flex-col flex-wrap items-center justify-center sm:flex-row sm:justify-between sm:items-center gap-3">
                <span className="text-xl text-text-700 font-medium">
                  {"Share via:"}
                </span>
                <div className="flex space-x-2">
                  <div
                    className={"w-[3.5rem] h-[3.5rem] flex items-center justify-center bg-primary-dark cursor-pointer"}
                    onClick={handleDownloadQRCode}
                  >
                    <img
                      src={qrUrl}
                      alt={"QR Code"}
                      className={"w-full h-full object-contain"}
                    />
                  </div>
                  <FacebookShareButton url={referralUrl + user?.user?.referralCode}>
                    <FacebookIcon size={50} />
                  </FacebookShareButton>
                  <WhatsappShareButton url={referralUrl + user?.user?.referralCode}>
                    <WhatsappIcon size={50} />
                  </WhatsappShareButton>
                  <LinkedinShareButton url={referralUrl + user?.user?.referralCode}>
                    <LinkedinIcon size={50} />
                  </LinkedinShareButton>
                  <TwitterShareButton url={referralUrl + user?.user?.referralCode}>
                    <TwitterIcon size={50} />
                  </TwitterShareButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Refer;
