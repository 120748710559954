import { cn } from "utils/cn.utils";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";
import { userRoles } from "redux/auth/auth.const";

const ActiveRoleContainer = () => {
    const { locals, modal } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)

    const dispatcher = useAppDispatcher()

    const handleRoleSwitch = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.ROLE_SWITCH_MODAL.stateKey]: true
        }))
    }

    return (!!locals && (user?.user?.policy_permissions?.length > 1)) && (
        <button
            className={cn(
                "p-1.5 absolute top-0 left-[50%] -translate-x-[50%] flex items-center justify-center bg-primary-main rounded-b-lg cursor-pointer hover:!bg-secondary-dark",
                (locals?.userRole === userRoles?.TUTOR?.value) && "!bg-slate-700"
            )}
            onClick={handleRoleSwitch}
        >
            <span className={"font-bodyPri font-normal text-text-50 text-sm tracking-wide"}>
                {`Active Role: ${[userRoles?.TUTOR?.value]?.includes(locals?.userRole) ?  "Business" : "Default"}`}
            </span>
        </button>
    )
}

export default ActiveRoleContainer;