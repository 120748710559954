import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { toast } from 'react-toastify';

import { AiOutlinePlus } from 'react-icons/ai';

import { sectionInitialState } from 'pages/auth/editClass/data';
import CreateSection from 'pages/auth/editClass/_components/section/index';
import AddBorderButton from 'pages/auth/editClass/_components/buttons/AddBorderButton';

import courseServices from 'redux/course/course.services';

const SectionButton = ({ cohortSections = [], setCohortSectionList, sectionPayload, setSectionPayload }) => {

    const { cohortId } = useParams()

    const onHandleAddSection = (key, value) => {
        setSectionPayload(() => ({
            ...sectionInitialState,
            payload: {
                ...sectionInitialState?.payload,
                [key]: value
            }
        }))
    }

    const onHandleSectionPayload = (key, value) => {
        setSectionPayload((prevState) => ({
            ...prevState,
            payload: {
                ...prevState?.payload,
                [key]: value
            }
        }))
    }

    const onHandleAddCohortSection = async () => {
        setSectionPayload((prevState) => ({ ...prevState, isLoading: true }))

        try {
            const requestData = {
                params: { cohortId: Number(cohortId) },
                body: { title: sectionPayload?.payload?.title }
            }
            const response = await courseServices.createCohortSection(requestData)
            if (response.status === 201) {
                setSectionPayload(() => ({ ...sectionInitialState, payload: { ...sectionInitialState?.payload, id: response?.data?.data?.result?.id } }))
                // setSectionPayload(sectionInitialState)
                setCohortSectionList((prevState) => ({ ...prevState, result: [...prevState?.result, response?.data?.data?.result] }))
                toast.success(response.data.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
            toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
        } finally {
            setSectionPayload((prevState) => ({ ...prevState, isLoading: false }))
        }
    }

    return (
        <>
            {(!sectionPayload?.payload?.isInputVisible && (!cohortSections?.length || (cohortSections?.length === 0))) &&
                <AddBorderButton btnTitle={"Add Section"} onHandleAdd={() => onHandleAddSection("isInputVisible", !sectionPayload?.payload?.isInputVisible)} />
            }
            {(!sectionPayload?.payload?.isInputVisible && !!cohortSections?.length) &&
                <div className={'w-full flex items-center justify-center'}>
                    <button
                        className={'flex justify-center items-center bg-white rounded-full px-5 py-2 text-text-900 border border-divider-dark hover:border-secondary-dark hover:text-secondary-dark'}
                        onClick={() => onHandleAddSection("isInputVisible", !sectionPayload?.payload?.isInputVisible)}
                    >
                        <AiOutlinePlus className='mr-1' size={20} />
                        {"Add new section"}
                    </button>
                </div>
            }
            {!!sectionPayload?.payload?.isInputVisible && (
                <CreateSection
                    isLoading={sectionPayload?.isLoading}
                    sectionName={'New Section'}
                    onClose={() => onHandleSectionPayload("isInputVisible", !sectionPayload?.payload?.isInputVisible)}
                    value={sectionPayload?.payload?.title}
                    onChange={(e) => onHandleSectionPayload("title", e.target.value)}
                    placeholder={'Section Title'}
                    onCreate={onHandleAddCohortSection}
                />
            )}
        </>
    )
}

export default SectionButton;