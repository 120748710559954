import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { AiOutlineLink } from 'react-icons/ai';

import RepeatBookingDetail from './RepeatBookingDetail';

import { useAppState } from 'hooks/useStore';
import { userRoles } from 'redux/auth/auth.const';
import { bookingStatusEnum } from 'redux/booking/booking.const';
import { appointmentStatusEnum } from 'redux/appointment/appointment.const';

import { pagesInfo } from 'utils/pagesInfo';
import { cn } from 'utils/cn.utils';
import { getCustomHostPath } from 'utils/generators.utils';
import { EDULYTE_WEB_DOMAIN_URL } from 'const/default.const';
import useCustomNavigation from 'hooks/useCustomNavigation';

const BookingAppointment = ({ newBookingDetail }) => {
    const { locals } = useAppState((state) => state.local)
    const { publicOrgDetail } = useAppState((state) => state.org)

    const navigate = useNavigate()
    const customNavigation = useCustomNavigation()

    const onHandleNavigateToAppointment = useCallback(() => {
        if (newBookingDetail?.data?.result?.appointment?.slug && [appointmentStatusEnum.PUBLISHED.value]?.includes(newBookingDetail?.data?.result?.appointment?.status)) {
            // const path = getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `${pagesInfo.APPOINTMENT.pagePath}/${newBookingDetail?.data?.result?.appointment?.slug}` })
            const path = customNavigation.getCustomBaseUrl({ path: `${pagesInfo.APPOINTMENT.pagePath}/${newBookingDetail?.data?.result?.appointment?.slug}`, isWeb: true })
            window.open(path, "_blank")
        }
    }, [newBookingDetail?.data?.result])

    const onHandleCheckout = useCallback(() => {
        navigate(`${pagesInfo?.CHECKOUT_BOOKING?.pagePath}/${newBookingDetail?.data?.result?.id}`)
    }, [newBookingDetail?.data?.result?.id])

    return (
        <>
            <div className={"w-full px-2 py-3 flex flex-col gap-5 border border-divider-medium rounded-md"}>
                <div className={"w-full flex flex-col gap-1.5"}>
                    <div className={cn(
                        "w-fit flex items-center justify-start gap-1.5 text-lg text-text-900",
                        [appointmentStatusEnum.PUBLISHED.value]?.includes(newBookingDetail?.data?.result?.appointment?.status) && "!text-primary-dark hover:!text-primary-dark hover:underline cursor-pointer"
                    )}
                        onClick={onHandleNavigateToAppointment}
                    >
                        <AiOutlineLink className={"text-lg"} />
                        <span className={"font-semibold font-bodyPri tracking-wide text-left truncate"}>
                            {newBookingDetail?.data?.result?.appointment?.title}
                        </span>
                    </div>
                    {newBookingDetail?.data?.result?.appointment?.subtitle &&
                        <span className={"font-bodyPri font-normal text-text-800 text-sm truncate"}>
                            {newBookingDetail?.data?.result?.appointment?.subtitle}
                        </span>
                    }
                </div>
                <div className={"grid gap-3 text-sm md:grid-cols-2 opacity-70 place-items-start"}>
                    <div className="inline-flex space-x-2 whitespace-nowrap font-bodyPri tracking-wide text-text-900">
                        <p>{"SESSION(S):"}</p>
                        <p className="font-bold">{newBookingDetail?.data?.result?.booking_price?.session_count}</p>
                    </div>
                </div>
            </div>
            {((locals?.userRole === userRoles?.STUDENT?.value) && (![bookingStatusEnum?.PENDING?.value, bookingStatusEnum?.PROCESSING?.value]?.includes(newBookingDetail?.data?.result?.status))) &&
                <RepeatBookingDetail newBookingDetail={newBookingDetail} />
            }
            {((locals?.userRole === userRoles?.STUDENT?.value) && (newBookingDetail?.data?.result?.status === bookingStatusEnum?.PENDING?.value)) &&
                <div
                    className={cn(
                        "w-full px-4 py-2 rounded-md bg-secondary-dark hover:opacity-90 cursor-pointer",
                        "text-center font-buttons font-medium text-base text-text-50 whitespace-nowrap",
                    )}
                    onClick={onHandleCheckout}
                >
                    <span>
                        {"Checkout to confirm"}
                    </span>
                </div>
            }
        </>
    )
}

export default memo(BookingAppointment);