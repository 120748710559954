import { useEffect, useMemo, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';

import PageHeader from 'components/pageHeader/PageHeader';
import ThreeDotMenu from 'components/threeDotMenu/ThreeDotMenu';
import Pagination from 'components/pagination/Pagination';

import { getEventlyListPayload, pageHeading, searchParamsInfo, eventlyTableHeaderConst, menuItemsConst } from './data';
import FilterSection from './component/filters/FilterSection';
import EventlyTable from './component/EventlyTable';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { deleteUserEventlyDetail, getUserEventlyList } from 'redux/evently/evently.request';

import { useTitle } from "hooks/useTitle";
import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal, setShareSocial } from 'redux/local/local.slice';
import { resetDestroyUserEventlyDetail, resetUserEventlyList, setUserEventlyListData } from 'redux/evently/evently.slice';
import { modalConst, socialAction } from 'redux/local/local.const';

import { getCustomHostPath } from 'utils/generators.utils';
import { pagesInfo } from 'utils/pagesInfo';
import { dayjs, timeZone } from 'utils/dateTime.utils';

import { EDULYTE_WEB_DOMAIN_URL } from 'const/default.const';
import useCustomNavigation from 'hooks/useCustomNavigation';

const EventsListPage = () => {
    const publicOrgDetail = useAppState((state) => state.org.publicOrgDetail)
    const { currentPageInfo } = useAppState(state => state.pageInfo)
    const { shareSocial, modal } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { userEventlyList, destroyUserEventlyDetail } = useAppState((s) => s.evently)

    const dispatcher = useAppDispatcher()
    const [title, setTitle] = useTitle()
    const navigate = useNavigate()
    const location = useLocation()
    const customNavigation = useCustomNavigation()

    const [copiedURL, setCopiedURL] = useState(false)

    const searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.EVENTS_LIST))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        setTitle({
            ...title,
            title: "My Events | Edulyte"
        })
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        return () => {
            dispatcher(resetUserEventlyList())
        }
    }, [])

    useEffect(() => {
        if (destroyUserEventlyDetail?.data) {
            dispatcher(setUserEventlyListData({
                ...userEventlyList?.data,
                results: userEventlyList?.data?.result?.filter((item) => item?.id !== destroyUserEventlyDetail?.data?.id),
                pagination: {
                    ...userEventlyList?.data?.pagination,
                    records: userEventlyList?.data?.pagination?.records - 1,
                    totalRecords: userEventlyList?.data?.pagination?.totalRecords - 1
                },
            }))
            dispatcher(resetDestroyUserEventlyDetail())
        }
    }, [destroyUserEventlyDetail?.data])

    useEffect(() => {
        if (user?.user?.userId) {
            getEventlyList()
        }
    }, [user?.user?.userId])

    useEffect(() => {
        if (searchQueryParams?.get(searchParamsInfo.eventlyId.key)) {
            dispatcher(setModal({
                ...modal,
                [modalConst.viewEventlySlotModal.key]: {
                    ...modal[modalConst.viewEventlySlotModal.key],
                    isVisible: true,
                    title: "View Event",
                }
            }))
        }
    }, [searchQueryParams.get(searchParamsInfo.eventlyId.key)])

    const getEventlyList = async () => {
        const requestDataPayload = await getEventlyListPayload(searchQueryParams)

        dispatcher(getUserEventlyList(user?.user?.userId, { ...requestDataPayload }))
    }

    const onHandlePageChange = async (page) => {
        searchQueryParams.set(searchParamsInfo.page.key, page)
        navigate(`${location.pathname}?${searchQueryParams?.toString()}`);
    }

    const onHandleSelectMenuItem = useCallback((menuItem, eventlyItem) => {
        if (menuItem.value === menuItemsConst.SHARE.value) {
            dispatcher(setShareSocial({
                ...shareSocial,
                socialAction: socialAction.SHARE_EVENTLY,
                // url: `${EDULYTE_WEB_DOMAIN_URL}/evently/${eventlyItem?.id}`
                // url: `${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/evently/${eventlyItem?.id}` })}`
                url: `${customNavigation.getCustomBaseUrl({ path: `/evently/${eventlyItem?.id}`, isWeb: true })}`
            }))
            dispatcher(setModal({
                ...modal,
                [modalConst.SHARE_SOCIAL_MODAL.stateKey]: true
            }))
        }
        if (menuItem.value === menuItemsConst.COPY.value) {
            // navigator.clipboard.writeText(`${EDULYTE_WEB_DOMAIN_URL}/evently/${eventlyItem?.id}`);
            // navigator.clipboard.writeText(`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/evently/${eventlyItem?.id}` })}`);
            navigator.clipboard.writeText(`${customNavigation.getCustomBaseUrl({ path: `/evently/${eventlyItem?.id}`, isWeb: true })}`);
            setCopiedURL(true);
            toast.success("Copied")
            setTimeout(() => {
                setCopiedURL(false);
            }, 2000);
        }
        if (menuItem.value === menuItemsConst.VIEW.value) {
            searchQueryParams.set(searchParamsInfo.eventlyId.key, eventlyItem?.id)
            navigate(`${location.pathname}?${searchQueryParams.toString()}`)
        }
        if (menuItem.value === menuItemsConst.EDIT.value) {
            navigate(`${pagesInfo.EDIT_EVENTLY.pagePath}/${eventlyItem?.id}/edit`)
        }
        if (menuItem.value === menuItemsConst.DELETE.value) {
            dispatcher(deleteUserEventlyDetail(eventlyItem?.id))
        }
    }, [copiedURL, modal])

    const onHandleNavigateToEvently = (eventlyItem) => {
        // window.open(`${EDULYTE_WEB_DOMAIN_URL}/evently/${eventlyItem?.id}`, "_blank")
        // window.open(`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/evently/${eventlyItem?.id}` })}`, "_blank")
        window.open(`${customNavigation.getCustomBaseUrl({ path: `/evently/${eventlyItem?.id}`, isWeb: true })}`, "_blank")
    }

    const EventlyTitleContainer = ({ evently }) => {
        return (
            <span
                className={'font-bodyPri font-normal text-text-900 text-base line-clamp-1 tracking-wide cursor-pointer hover:text-primary-dark hover:underline'}
                title={evently?.title && evently?.title}
                onClick={() => onHandleNavigateToEvently(evently)}
            >
                {evently?.title
                    ? (evently?.title?.length > 30)
                        ? evently?.title?.slice(0, 30) + " ..."
                        : evently?.title
                    : "-"
                }
            </span>
        )
    }

    const tableRowsConst = !!userEventlyList?.data?.result?.length && userEventlyList?.data?.result?.map((evently) => ([
        evently?.id,
        <EventlyTitleContainer evently={evently} />,
        evently?.link ? "Online" : evently?.location ? evently?.location : "-",
        evently?.next_event?.startDateTime ? dayjs(evently?.next_event?.startDateTime)?.tz(timeZone)?.format('DD, MMM, YYYY') : "-",
        dayjs(evently?.createdAt)?.tz(timeZone)?.format('DD, MMM, YYYY') || "-",
        <div className={"flex items-center justify-start"}>
            <ThreeDotMenu
                iconClassName={"rotate-[90deg]"}
                menuItems={Object.values(menuItemsConst)}
                onHandleSelect={(option) => onHandleSelectMenuItem(option, evently)}
            />
        </div>
    ]))

    return (
        <div className={"space-y-3"}>
            <PageHeader
                pageHeading={pageHeading}
                toolTipText={"Seamlessly create and manage online or offline events, whether free or paid."}
            />
            <FilterSection />
            <EventlyTable
                isLoading={userEventlyList?.isLoading}
                headers={eventlyTableHeaderConst}
                rows={tableRowsConst}
                alternateRowColor={"bg-sky-100"}
                rowHeaderColor={"bg-sky-200"}
            />
            {(!userEventlyList?.isLoading && (userEventlyList?.data && (userEventlyList?.data?.pagination?.totalPages > 1))) &&
                <div className={"flex justify-center items-center"}>
                    <Pagination
                        page={userEventlyList?.data?.pagination?.page}
                        totalPages={userEventlyList?.data?.pagination?.totalPages}
                        onChangePage={onHandlePageChange}
                    />
                </div>
            }
            {(!userEventlyList?.isLoading && userEventlyList?.message) &&
                <div className={'w-full flex items-center justify-center'}>
                    <span className={'font-bodyPri font-normal text-red-500 text-base text-center'}>
                        {userEventlyList.message}
                    </span>
                </div>
            }
        </div>
    )
}

export default EventsListPage;