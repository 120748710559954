import { cn } from "utils/cn.utils";
import { Link, useNavigate } from 'react-router-dom';

import { MdGroups, MdVerified } from 'react-icons/md';
import { BsInfoCircle } from "react-icons/bs";
import { IoMdPeople } from 'react-icons/io';
import { FiMessageSquare } from 'react-icons/fi';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import RatingStars from 'components/ratingStars/RatingStars';

import { getSelectedPublicCourseDetail } from 'redux/course/course.request';
import {
    getFBChat,
    getFBChatId,
} from 'redux/firebase/firebase.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { courseType, coursePriceTypeEnum, coursePricingModelTypeEnum } from "redux/course/course.const";
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';
import { bookingOfferingTypeEnum, addBookingDetailPayload } from 'redux/booking/booking.const';
import { setAddBookingDetailPayload } from 'redux/booking/booking.slice'
import { setMessageTemplate } from 'redux/firebase/firebase.slice';
import { blueTickEnum } from 'redux/user/user.const';

import { pagesInfo } from 'utils/pagesInfo';
import { currencyRateConversion } from 'utils/generators.utils';

const CourseCard = ({ course, isFav = false, handleFav }) => {
    const { session } = useAppState(s => s.auth)
    const { user } = useAppState((s) => s.user)
    const { messageTemplate } = useAppState((s) => s.firebase)
    const { modal, locals } = useAppState((s) => s.local)
    const { publicCourseList } = useAppState((s) => s.course)

    const navigate = useNavigate()
    const dispatcher = useAppDispatcher()

    const RATING_COUNT = 2

    const isMyOffering = user?.user?.userId === course?.tutor?.user?.id

    const userCourse = user?.user?.bookedCourses?.find((courseItem) => courseItem?.courseId === course?.id)
    const isMyCourse = publicCourseList?.data?.results?.filter((publicCourse) => publicCourse?.type === courseType?.GROUP?.value)
        .filter((myCourse) => myCourse?.id === userCourse?.courseId)?.length > 0

    const handleMessage = async () => {
        if (!session?.isLoggedIn) {
            dispatcher(setModal({
                ...modal,
                [modalConst.AUTHORIZATION_MODAL.stateKey]: true
            }))
            return;
        }

        if (isMyOffering) return;

        const userIdOne = user?.user?.userId
        const userIdTwo = course?.tutor?.user?.id

        const chatId = await getFBChatId(userIdOne, userIdTwo)
        let fbChat = await getFBChat(chatId)

        if (!fbChat || fbChat === undefined) {
            dispatcher(setMessageTemplate({
                ...messageTemplate,
                isLoading: false,
                userIdOne: userIdOne,
                userIdTwo: userIdTwo,
                tutorUserId: course?.tutor?.user?.id,
                message: '',
                errorMsg: null
            }))
            dispatcher(setModal({
                ...modal,
                [modalConst.MESSAGE_TEMPLATE_MODAL.stateKey]: true
            }))
            return;
        }

        navigate(`${pagesInfo.MESSAGES.pagePath}/${fbChat.chatId}`)
    }

    const handleBookNow = () => {
        if (isMyCourse || isMyOffering) return;

        dispatcher(setAddBookingDetailPayload({
            ...addBookingDetailPayload,
            offeringType: bookingOfferingTypeEnum.COHORT.value,
            courseId: course?.id,
            slug: course?.slug
        }))
        dispatcher(getSelectedPublicCourseDetail(course?.slug))

        if (course?.type === courseType.GROUP.value) {
            dispatcher(setModal({ ...modal, [modalConst.COURSE_CHECKOUT_MODAL.stateKey]: true }))
        } else if (course?.type === courseType.ONE_ON_ONE.value && course?.coursePrice?.type === coursePriceTypeEnum.REGULAR.value) {
            dispatcher(setModal({ ...modal, [modalConst.NO_SESSION_MODAL.stateKey]: true }))
        }
    }

    return (
        <div className='w-[22rem] h-full flex flex-col bg-white rounded-lg shadow-all'>
            <div className='w-full h-48 overflow-hidden relative'>
                <div className='rounded-t-lg overflow-hidden h-full w-full'>
                    <Link to={`/class/${course?.slug}`}>
                        <img
                            src={course?.thumbnailPicUrl}
                            alt={"class-thumbnail"}
                            className='w-full h-full object-cover'
                        />
                    </Link>
                </div>
                {session?.isLoggedIn &&
                    <div className='absolute right-2 top-2 p-1.5 bg-white rounded-full shadow-md'>
                        <span onClick={() => handleFav(course.id)} className="cursor-pointer">
                            {isFav
                                ? (<FavoriteIcon className='text-red-500' style={{ fontSize: "1.5rem" }} />)
                                : <FavoriteBorderIcon className='text-text-700' style={{ fontSize: "1.5rem" }} />
                            }
                        </span>
                    </div>
                }
            </div>
            <div className='flex w-full flex-col gap-3 p-5 pt-3'>
                <div className='h-full flex flex-col justify-between gap-5'>
                    <div className='flex flex-col justify-start gap-5'>
                        <div className='flex justify-between items-center'>
                            <Link to={`${pagesInfo?.FIND_COURSES?.pagePath}/${course?.category?.replaceAll(" ", "-")?.toLowerCase()}`}
                                className='text-text-900 font-normal font-bodyPri text-sm tracking-wide'>
                                {course?.category}
                            </Link>
                            <div className='flex justify-center items-center gap-2'>
                                {Object.keys(courseType).map((item, index) => courseType[item].value === course?.type &&
                                    <Link to={`${pagesInfo?.FIND_COURSES?.pagePath}?classType=${courseType[item]?.value}`}
                                        key={index} className='px-2 py-0.5 flex items-center justify-center rounded-full bg-text-300 font-normal font-bodyPri text-sm tracking-wide whitespace-nowrap'>
                                        {courseType[item]?.label}
                                    </Link>
                                )}
                                {course?.type === courseType.GROUP.value
                                    ? <MdGroups className='text-text-700 text-2xl' />
                                    : <IoMdPeople className='text-text-700 text-2xl' />
                                }
                            </div>
                        </div>
                        <Link to={`/class/${course?.slug}`} className={cn(
                            'text-text-900 font-bodyPri font-medium text-lg tracking-wide truncate',
                            "hover:text-secondary-main hover:underline decoration-1"
                        )}>
                            {course?.title}
                        </Link>
                    </div>
                    <div className='flex flex-col justify-end gap-5'>
                        <div className='flex justify-between items-center'>
                            <div className='flex justify-start gap-2'>
                                <div className='relative w-12 h-12 rounded-full'>
                                    <Link to={`${pagesInfo?.TUTOR?.pagePath}/${course?.tutor?.user?.id}`}>
                                        <img src={course?.tutor?.user?.profilePicUrl} alt={"profile-pic"} className={"w-full h-full rounded-full object-cover"} />
                                    </Link>
                                    <div className={"relative has-tooltip"}>
                                        <div className='absolute right-0.5 bottom-0.5 w-3 h-3 rounded-full overflow-hidden border-white'>
                                            <img
                                                src={`https://flagcdn.com/16x12/${course?.tutor?.user?.fromCountry?.countryDomain}.png`.toLowerCase()}
                                                alt={"country-flag"} className={'w-full h-full object-cover'}
                                            />
                                        </div>
                                        <span
                                            className={cn(
                                                "w-16 px-1 py-0.5 font-bodyPri font-normal text-xs rounded-md shadow-lg bg-text-900 text-text-50 tooltip -top-0.5 -right-5",
                                                "flex flex-col items-center justify-start overflow-hidden"
                                            )}
                                        >
                                            {course?.tutor?.user?.fromCountry?.country}
                                        </span>
                                    </div>
                                </div>
                                <div className='flex flex-col justify-evenly items-start'>
                                    <div className='flex justify-center items-center gap-2'>
                                        <Link to={`${pagesInfo?.TUTOR?.pagePath}/${course?.tutor?.user?.id}`}>
                                            <span className='font-medium font-bodyPri text-md tracking-wide capitalize'>
                                                {course?.tutor?.user?.firstName + " " + course?.tutor?.user?.lastName?.charAt(0) + "."}
                                            </span>
                                        </Link>
                                        {(course?.tutor?.user?.blueTick === blueTickEnum?.YES?.value) && <div className='relative has-tooltip cursor-pointer'>
                                            <MdVerified className={"text-lg text-primary-dark"} />
                                            <span className='tooltip w-fit px-2 py-1 bg-black text-white rounded-lg font-bodyPri font-normal text-xs tracking-wide sm:whitespace-nowrap -translate-x-[50%] -translate-y-[150%] md:-translate-y-[190%]'>
                                                {"Verified Account"}
                                            </span>
                                        </div>
                                        }
                                        {(course?.tutor?.user?.onlineStatus === "online") &&
                                            <span className={"w-2 h-2 rounded-full bg-green-600"}></span>
                                        }
                                    </div>
                                    <div className='flex justify-start items-center gap-0.5'>
                                        {course?.tutor?.rating?.totalStars?.count > RATING_COUNT
                                            && <>
                                                <RatingStars rating={course?.tutor?.rating?.avgRating?.toFixed(1)} fullRating={5} size={0.8} />
                                                <span className='font-medium text-sm font-bodyPri tracking-wide'>{course?.tutor?.rating?.avgRating?.toFixed(1)}</span>
                                                <span className='font-normal text-sm font-bodyPri'>({String(course?.tutor?.rating?.totalStars?.count).padStart(2, "0")})</span>
                                            </>
                                            // : <span className='font-normal text-sm font-bodyPri tracking-wide text-orange-400'>{"New"}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <div className={cn(
                                'flex flex-col justify-evenly items-center gap-1 font-normal text-sm font-bodyPri tracking-wide',
                            )}>
                                <span onClick={handleMessage} className={cn(
                                    isMyOffering
                                        ? "text-text-300"
                                        : "text-text-700 cursor-pointer",
                                )}>
                                    {"Message >"}
                                </span>
                                <Link to={`/class/${course?.id}`}>
                                    <span className='text-text-700 cursor-pointer'>
                                        {"View Detail >"}
                                    </span>
                                </Link>
                            </div> */}
                        </div>
                        <hr />
                        <div className='flex justify-between items-center gap-2'>
                            {course?.coursePrice?.type === coursePriceTypeEnum?.REGULAR?.value &&

                                <div className={"flex flex-col items-start justify-start gap-1"}>
                                    <div className='flex items-center gap-2 whitespace-nowrap'>
                                        {(course?.basicDetail?.discountPct < 100)
                                            ? <span className='font-medium text-md font-bodyPri tracking-wide'>
                                                {currencyRateConversion(locals?.currencyCode, course?.basicDetail?.discountedPrice)}
                                            </span>
                                            : <span className='font-medium text-md font-bodyPri tracking-wide'>
                                                {"Free"}
                                            </span>
                                        }

                                        <span className='font-normal text-sm font-bodyPri tracking-wide'>
                                            {coursePricingModelTypeEnum[course?.basicDetail?.priceModel?.toUpperCase()]?.label}
                                        </span>
                                    </div>
                                    {(course?.basicDetail?.discountPct > 0 && course?.basicDetail?.discountPct < 100) &&
                                        <div className={"flex items-center justify-start gap-2"}>
                                            <del className={"font-bodyPri font-normal text-sm text-text-700 tracking-wide"}>
                                                {currencyRateConversion(locals?.currencyCode, course?.basicDetail?.price)}
                                            </del>
                                            <span className={"font-bodyPri font-normal text-text-700 text-sm"}>
                                                {" " + course?.basicDetail?.discountPct + "% off"}
                                            </span>
                                        </div>
                                    }

                                </div>
                            }

                            {course?.coursePrice?.type === coursePriceTypeEnum?.PLAN?.value &&
                                <div className='flex items-center gap-2 whitespace-nowrap'>
                                    <span className='font-medium text-md font-bodyPri tracking-wide'>
                                        {currencyRateConversion(locals?.currencyCode, course?.coursePrice?.itemPrices[0]?.price)}
                                    </span>
                                    <span className='font-normal text-xs font-bodyPri tracking-wide'>
                                        {coursePricingModelTypeEnum[course?.coursePrice?.itemPrices[0]?.pricing_model?.toUpperCase()]?.label}
                                    </span>
                                </div>
                            }
                            <div className={"flex items-center justify-center gap-3"}>
                                <span onClick={handleMessage} className={cn(
                                    'relative has-tooltip text-2xl',
                                    isMyOffering
                                        ? "text-text-300"
                                        : "text-text-600 hover:text-primary-dark cursor-pointer"
                                )}>
                                    <FiMessageSquare />
                                    <span
                                        className={cn(
                                            "w-fit px-2 py-1 font-bodyPri font-normal text-xs rounded-md shadow-lg bg-text-900 text-text-50 tooltip -translate-x-[40%] -translate-y-14",
                                            "flex flex-col items-center justify-start overflow-hidden whitespace-nowrap"
                                        )}
                                    >
                                        {"Send Message"}
                                    </span>
                                </span>
                                <div onClick={handleBookNow} className={cn(
                                    'px-2.5 py-1 flex justify-center items-center gap-1 rounded-full font-normal font-buttons border whitespace-nowrap',
                                    isMyOffering
                                        ? "border-text-300 text-text-300"
                                        : isMyCourse
                                            ? "border-text-500 text-text-700"
                                            : "border-secondary-main text-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer"
                                )}>
                                    {isMyCourse && <BsInfoCircle className={"text-primary-main text-sm"} />}
                                    <span>
                                        {isMyCourse ? "Purchased" : "Book Now"}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CourseCard;