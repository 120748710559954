import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { toast } from "react-toastify";
import { Draggable } from 'react-drag-reorder';

import { FaTimes } from 'react-icons/fa';

import ComponentLoader from 'components/loader/ComponentLoader';
import { OptionSelector } from 'components/common-components/Select';

import { classDetailNavigationOptions, classDetailTooltips } from '../../data';
import AddBorderButton from '../../_components/buttons/AddBorderButton';
import PageHeading from '../../_components/header/pageHeading';
import PreviousNextButtons from '../../_components/buttons/PreviousNextButtons';

import { getMasterProficiencyList } from 'redux/master/master.request';
import { getCourseProfs, createCourseProf, updateCourseProfOrderList, deleteCourseProf } from 'redux/course/course.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setClearProfList } from 'redux/master/master.slice';
import { resetCourseProfs } from 'redux/course/course.slice';

import { cn } from "utils/cn.utils";

const ProficienciesPage = () => {
    const { profList } = useAppState((state) => state.master)
    const { courseProfs, addCourseProf, destroyCourseProf } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()
    const { courseId } = useParams()

    const [isShowProficiency, setIsShowProficiency] = useState(courseProfs?.result?.length > 0)

    useEffect(() => {
        if (courseId) {
            dispatcher(getMasterProficiencyList())
            dispatcher(getCourseProfs(Number(courseId)))
        }

        return () => {
            dispatcher(setClearProfList())
            dispatcher(resetCourseProfs())
        }
    }, [courseId])

    useEffect(() => {
        if (courseProfs?.result?.length > 0) {
            setIsShowProficiency(true)
        }
    }, [courseProfs?.result])

    const onHandleAddProficiency = useCallback(() => {
        setIsShowProficiency(true)
    }, [isShowProficiency])

    const getChangedPos = useCallback((currentPos, newPos) => {
        var updatedList = [...courseProfs?.result];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(currentPos, 1);
        // Add dropped item
        updatedList.splice(newPos, 0, reorderedItem);
        // Update State

        const body = {
            order: updatedList?.map((prof) => ({
                id: prof?.id
            }))
        }
        dispatcher(updateCourseProfOrderList(Number(courseId), body, { course_id: Number(courseId) }))
    }, [courseId, courseProfs?.result]);

    const onRemoveProficiency = useCallback((selectedProfId) => {

        dispatcher(deleteCourseProf(selectedProfId))
    }, [])

    const onHandleSelectProficiency = (option) => {
        const filteredProficiencyList = courseProfs?.result?.filter((courseProficiency) => courseProficiency?.master_proficiency?.proficiency === option)

        if (filteredProficiencyList?.length > 0) {
            toast.warn("Duplicate Proficiency are not allowed!")
            return;
        }
        dispatcher(createCourseProf(Number(courseId), { proficiency: option }))
    }

    return (
        <div className={"w-full space-y-5"}>
            <div className={"w-full flex flex-col gap-5"}>

                <PageHeading headerText={"Level of difficulty"} tooltipText={classDetailTooltips?.levelOfDifficulty} />

                {!isShowProficiency &&
                    <AddBorderButton
                        btnTitle={"Add Proficiency(s)"}
                        onHandleAdd={onHandleAddProficiency}
                    />
                }
                {isShowProficiency &&
                    <div className={cn(
                        "w-full px-3 py-3 flex flex-col items-start justify-start border border-dashed border-text-500 rounded-lg",
                        "font-bodyPri font-normal text-text-900"
                    )}>
                        {(profList?.isLoading || courseProfs?.isLoading || addCourseProf?.isLoading || destroyCourseProf?.isLoading) &&
                            <ComponentLoader isLoading={profList?.isLoading || courseProfs?.isLoading || addCourseProf?.isLoading || destroyCourseProf?.isLoading} />
                        }
                        {(!profList?.isLoading && !addCourseProf?.isLoading && !destroyCourseProf?.isLoading) &&
                            <div className={"flex flex-wrap items-start justify-start gap-3"}>
                                <Draggable onPosChange={getChangedPos}>
                                    {courseProfs?.result?.map((courseProf, idx) => (
                                        <div
                                            key={idx}
                                            className={'w-max px-3 py-1 border-2 border-text-300 flex items-center justify-start gap-1.5 rounded-xl'}
                                        >
                                            <span className={"font-bodyPri font-medium text-text-900 text-base"}>
                                                {courseProf?.master_proficiency?.proficiency}
                                            </span>
                                            <button className="pl-3 cursor-pointer">
                                                <FaTimes
                                                    className="font-light text-secondary-dark"
                                                    onClick={() => onRemoveProficiency(courseProf?.id)}
                                                />
                                            </button>
                                        </div>
                                    ))}
                                </Draggable>
                                {profList?.profList &&
                                    <div className={cn(
                                        "w-52 h-32 md:h-48 z-[500] overflow-y-scroll rounded-lg bg-white shadow-all",
                                        "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                                        "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                                    )}>
                                        <OptionSelector
                                            options={profList?.profList?.map((proficiency) => ({
                                                label: proficiency?.proficiency,
                                                value: proficiency?.proficiency
                                            }))}
                                            className={""}
                                            value={""}
                                            onChange={(option) => onHandleSelectProficiency(option?.value)}
                                        />
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }
            </div>
            <PreviousNextButtons
                previousBtn={classDetailNavigationOptions?.languages?.to}
                nextBtn={classDetailNavigationOptions?.thumbnails?.to}
            />
        </div>
    )
}

export default ProficienciesPage;