import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Reorder } from 'framer-motion';

import ComponentLoader from 'components/loader/ComponentLoader';

import { classDetailNavigationOptions, classDetailTabConst, classDetailTooltips } from '../../data';
import AddBorderButton from '../../_components/buttons/AddBorderButton';
import PreviousNextButtons from '../../_components/buttons/PreviousNextButtons';
import ShowMoreButton from '../../_components/buttons/ShowMoreButton';
import AddButtonWithIcon from '../../_components/buttons/AddButtonWithIcon';
import PageHeading from '../../_components/header/pageHeading';

import FAQAccordionItem from './accordionItem/index';
import FaqForm from './faqForm/index';

import { getCourseFaqs, updateCourseFaqOrderList } from "redux/course/course.request";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetCourseFaqs, setCourseFaqs } from 'redux/course/course.slice';

import { isOrderUpdated } from 'utils/generators.utils';

const FaqsPage = () => {
  const { courseFaqs, addCourseFaq } = useAppState((state) => state.course)

  const dispatcher = useAppDispatcher()
  const { courseId } = useParams()

  const [isAddFaqEnabled, setIsAddFaqEnabled] = useState(false)
  const [isShowAddButton, setIsShowAddButton] = useState(courseFaqs?.result?.length > 0)
  const [isFaqOrderUpdated, setIsFaqOrderUpdated] = useState(false)
  const [minFaqCount, setMinFaqCount] = useState(5)

  useEffect(() => {
    if (courseId) {
      dispatcher(getCourseFaqs(Number(courseId)))
    }

    return () => {
      dispatcher(resetCourseFaqs())
    }
  }, [courseId])

  useEffect(() => {
    if (!!courseFaqs?.result?.length) {
      setIsShowAddButton(true)
    }
  }, [courseFaqs?.result])

  const onHandleAddFaq = () => {
    setIsShowAddButton(false)
    setIsAddFaqEnabled(true)
  }

  const onHandleReorderGroup = (newOrder) => {
    if (newOrder) {
      const isOrderModified = isOrderUpdated(courseFaqs?.result, newOrder)
      if (!isOrderModified) {
        setIsFaqOrderUpdated(false)
        return;
      }
      setIsFaqOrderUpdated(true)
      dispatcher(setCourseFaqs({
        ...courseFaqs,
        result: newOrder
      }))
    }
  }

  const onHandleUpdateFaqOrder = () => {
    if (isFaqOrderUpdated && (courseFaqs?.result?.length > 1)) {
      const body = {
        order: courseFaqs?.result?.map((courseFaq) => ({
          id: courseFaq?.id
        }))
      }
      dispatcher(updateCourseFaqOrderList(Number(courseId), body, { course_id: Number(courseId) }))
      setIsFaqOrderUpdated(false)
    }
  }

  return (
    <div className={"w-full space-y-5"}>
      <div className={"w-full flex flex-col gap-5"}>
        <PageHeading headerText={"Frequently Asked Questions"} tooltipText={classDetailTooltips?.faq} />
        {(!courseFaqs?.isLoading && (((!courseFaqs?.result || (courseFaqs?.result?.length === 0)) && !isAddFaqEnabled))) &&
          <AddBorderButton
            btnTitle={"Add FAQ(s)"}
            onHandleAdd={onHandleAddFaq}
          />
        }
        {(courseFaqs?.result?.length > 0) &&
          <div className={""}>
            <Reorder.Group
              className={'w-full flex flex-col gap-3'}
              values={courseFaqs?.result}
              onReorder={onHandleReorderGroup}
            >
              {courseFaqs?.result?.slice(0, minFaqCount)?.map((itemFAQ, index) => (
                <Reorder.Item
                  key={itemFAQ?.id}
                  value={itemFAQ}
                  onDragEnd={onHandleUpdateFaqOrder}
                >
                  <FAQAccordionItem
                    key={itemFAQ?.id}
                    index={index}
                    itemFAQ={itemFAQ}
                  />
                </Reorder.Item>
              ))}
            </Reorder.Group>
            <ShowMoreButton
              isShowLessBtn={(courseFaqs?.result?.length > 5) && (courseFaqs?.result?.length === minFaqCount)}
              isShowMoreBtn={courseFaqs?.result?.length > minFaqCount}
              showLessText={"Show less"}
              showMoreText={"Show all FAQs"}
              onHandleToggleBtn={() => setMinFaqCount(prevCount => (prevCount === 5) ? courseFaqs?.result?.length : 5)}
            />
          </div>
        }
        {(courseFaqs?.isLoading || addCourseFaq?.isLoading) &&
          <ComponentLoader isLoading={courseFaqs?.isLoading || addCourseFaq?.isLoading} />
        }
        {(isShowAddButton && (courseFaqs?.result?.length > 0)) &&
          <div className={"w-full flex items-center justify-center"}>
            <AddButtonWithIcon btnTitle={"Add FAQ(s)"} onHandleAddBtn={onHandleAddFaq} />
          </div>
        }
        {isAddFaqEnabled &&
          <FaqForm setIsShowAddButton={setIsShowAddButton} setIsAddFaqEnabled={setIsAddFaqEnabled} />
        }
      </div>
      <PreviousNextButtons
        previousBtn={classDetailNavigationOptions?.proficiencies?.to}
        // nextBtn={classDetailTabConst?.cohorts?.pagePath}
        nextBtn={"cohorts"}
      />
    </div>
  )
}

export default FaqsPage;