import { useEffect, useReducer, useState } from 'react';
import { toast } from 'react-toastify';
import { cn } from "utils/cn.utils";

import { BsCheckAll } from "react-icons/bs";
import { FaSpinner } from 'react-icons/fa';
import { RiDeleteBinLine } from "react-icons/ri";
import { IoIosArrowForward } from "react-icons/io";
import { MdCancel } from "react-icons/md";
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';

import ButtonLoader from 'components/loader/ButtonLoader';

import {
    Container,
    BoxSection,
    NameSection,
    InputBox,
    ReferralBox,
    ReferralText,
    TextMessage,
    SignUpButton,
    ReferralOptionalText,
} from './SignUpFormStyle';

import { createVerifyReferral } from "redux/auth/auth.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setSignUpPayload, setClearVerifyReferral, setClearSignUpPayload } from "redux/auth/auth.slice";
import { setClearSendOtp, setClearVerifyOtp } from 'redux/notification/notification.slice';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';

import { pagesInfo } from 'utils/pagesInfo';

import { validateEmail } from 'utils/validation-functions';

const formReducer = (state, event) => {
    return {
        ...state,
        [event.name]: event.value,
    }
}

const SignUpForm = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const pageName = currentPageInfo.pageName;
    const { modal } = useAppState(s => s.local)
    const { signUp, verifyReferral } = useAppState((s) => s.auth)

    const dispatcher = useAppDispatcher()

    const [formData, setFromData] = useReducer(formReducer, {});
    const [emailErrorText, setEmailErrorText] = useState('')
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
    const [referralCode, setReferralCode] = useState('')
    const [openReferralMsgBox, setOpenReferralMsgBox] = useState(false)

    useEffect(() => {
        return () => {
            // dispatcher(setClearSignUpPayload())
            dispatcher(setClearVerifyReferral())
        }
    }, [])

    useEffect(() => {
        const { email } = formData;

        if (email && !validateEmail(email)) setEmailErrorText('Enter valid Email!');
        else setEmailErrorText('');

        const isDisabled = (emailErrorText.length === 0) && !(formData.firstName && formData.lastName && (formData.email || formData.mobileNo))
        setIsSubmitDisabled(isDisabled)

    }, [formData])

    useEffect(() => {
        if (signUp?.payload?.referralCode) {
            dispatcher(createVerifyReferral(signUp?.payload?.referralCode))
        }
    }, [signUp?.payload?.referralCode])

    useEffect(() => {
        if (verifyReferral?.verifyReferral) {
            setOpenReferralMsgBox(true)
            setReferralCode(verifyReferral?.verifyReferral?.referralCode)
        }
    }, [verifyReferral?.verifyReferral])


    const submitForm = (event) => {
        event.preventDefault();

        if (emailErrorText) {
            toast.warn("Invalid Email!")
            return;
        }

        // if (mobileErrorText) {
        //     toast.warn("Invalid Mobile Number!")
        //     return;
        // }

        dispatcher(setClearSendOtp())
        dispatcher(setClearVerifyOtp())
        // dispatcher(setSignUpPayload({
        //     ...signUp?.payload,
        //     country: myCountry,
        // }))
        dispatcher(setModal({
            ...modal,
            [modalConst.CODE_VERIFICATION_MODAL.stateKey]: true
        }))
    }

    const handleOnChange = (event) => {
        setFromData({
            name: event.target.name,
            value: event.target.value,
        })
        dispatcher(setSignUpPayload({
            ...signUp?.payload,
            [event?.target.name]: event?.target?.value
        }))
    }

    const handleReferralModal = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.APPLY_REFERRAL_MODAL.stateKey]: true
        }))
        dispatcher(setSignUpPayload({
            ...signUp?.payload,
            referralCode: referralCode
        }))
    }

    const handleDeleteReferralCode = () => {
        setReferralCode("")
        dispatcher(setSignUpPayload({
            ...signUp?.payload,
            referralCode: ""
        }))
        setOpenReferralMsgBox(false)
    }

    return (
        <Container onSubmit={submitForm}>
            <NameSection>
                <BoxSection>
                    <InputBox type='text' name='firstName' value={signUp?.payload?.firstName} placeholder='First Name *' onChange={handleOnChange} autoFocus={true} required />
                </BoxSection>
                <BoxSection>
                    <InputBox type='text' name='lastName' value={signUp?.payload?.lastName} placeholder='Last Name *' onChange={handleOnChange} required />
                </BoxSection>
            </NameSection>
            <BoxSection>
                <InputBox type='email' name='email' value={signUp?.payload?.email} placeholder='Enter your email *' onChange={handleOnChange} required />
                {formData.email && emailErrorText.length === 0 && <BsCheckAll className='text-green-500' />}
                {formData.email && emailErrorText.length > 0 && <MdCancel className=' text-base font-bodyPri text-red-800' />}
            </BoxSection>
            {!openReferralMsgBox && pagesInfo.PROMO_SIGN_UP.pageName !== pageName &&
                <BoxSection onClick={handleReferralModal}>
                    <ReferralBox>
                        <LocalOfferOutlinedIcon className='text-primary-main hover:text-secondary-dark' />
                        <ReferralText>{"Apply Referral"}</ReferralText>
                        <ReferralOptionalText>{"(optional)"}</ReferralOptionalText>
                    </ReferralBox>
                    <IoIosArrowForward className='text-2xl cursor-pointer text-text-500' />
                </BoxSection>
            }
            {(openReferralMsgBox && pagesInfo.PROMO_SIGN_UP.pageName !== pageName) &&
                <BoxSection>
                    {verifyReferral?.isLoading && <FaSpinner className='animate-spin text-lg text-primary-main' />}
                    {(!verifyReferral?.isLoading && signUp?.payload?.referralCode) &&
                        <ReferralBox onClick={handleReferralModal}>
                            <LocalOfferOutlinedIcon className={cn(
                                verifyReferral?.verifyReferral?.referralCode === signUp?.payload?.referralCode && 'text-green-500',
                                verifyReferral?.verifyReferral?.referralCode !== signUp?.payload?.referralCode && 'text-red-500'
                            )} />
                            <TextMessage className={cn(
                                "text-sm font-bodyPri font-normal tracking-wide cursor-pointer",
                                verifyReferral?.verifyReferral?.referralCode === signUp?.payload?.referralCode && "text-green-500",
                                verifyReferral?.verifyReferral?.referralCode !== signUp?.payload?.referralCode && "text-red-500"
                            )}>
                                {signUp?.payload?.referralCode + " , "}
                                {verifyReferral?.verifyReferral?.referralCode === signUp?.payload?.referralCode &&
                                    <TextMessage>
                                        {"Code Applied"}
                                        <BsCheckAll className='text-green-500' />
                                    </TextMessage>
                                }
                                {verifyReferral?.verifyReferral?.referralCode !== signUp?.payload?.referralCode &&
                                    <TextMessage>
                                        {"Invalid Referral Code!"}
                                        <MdCancel className='text-red-800' />
                                    </TextMessage>
                                }
                            </TextMessage>
                        </ReferralBox>
                    }
                    <RiDeleteBinLine
                        className='text-2xl text-red-500 cursor-pointer'
                        onClick={handleDeleteReferralCode}
                    />
                </BoxSection>
            }
            <SignUpButton isDisabled={isSubmitDisabled} type={"submit"} aria-disabled="true">
                {signUp?.isLoading &&
                    <ButtonLoader isLoading={signUp?.isLoading} />
                }
                {!signUp?.isLoading && 'Sign up'}
            </SignUpButton>
        </Container>
    )
};

export default SignUpForm;
