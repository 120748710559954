import { useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { BsArrowRight, BsArrowsCollapse, BsArrowsExpand } from 'react-icons/bs';
import { MdFullscreen, MdFullscreenExit, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

import { motion, AnimatePresence } from 'framer-motion';

import PageLoader from 'components/loader/PageLoader';
import ToolTipView from 'components/tooltipView';

import { useGetCohortDetail } from '../_hooks/useCohort.hooks';
import HeaderView from '../components/header';
import SectionView from '../components/section';

import { useAppState } from 'hooks/useStore';

import { cn } from 'utils/cn.utils';

const StudentCohort = () => {
    const { user } = useAppState((state) => state.user)
    const { participantCohortCourse } = useAppState((state) => state.course)

    const params = useParams()
    const navigate = useNavigate()
    const { cohortSectionList, activeSection, activeSession } = useGetCohortDetail()

    const [isSidebarClosed, setIsSidebarClosed] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);

    const toggleFullScreen = () => {
        const toggleContainer = document.getElementById('fullScreen');
        const isFullScreen = document.fullscreenElement;

        if (isFullScreen) {
            document.exitFullscreen();
            setIsFullScreen(false);
        } else {
            toggleContainer.requestFullscreen();
            setIsFullScreen(true);
        }
    };

    const getSession = (sectionSession) => {
        let id = sectionSession?.id
        let title = sectionSession?.name

        return { id, title }
    }

    if (user?.isLoading || participantCohortCourse?.isLoading) {
        return <PageLoader />
    }

    if (!!participantCohortCourse?.error) {
        return (
            <div className={"w-full h-screen flex items-center justify-center"}>
                <div className={"flex flex-col items-center justify-center gap-8"}>
                    <span className={"font-bodyPri font-semibold text-2xl"}>{"Message"}</span>
                    <span className={"font-bodyPri font-normal text-lg"}>{participantCohortCourse?.error}</span>
                </div>
            </div>
        )
    }

    return (
        <main className='min-h-screen w-full bg-white relative'>
            <HeaderView
                isFullScreen={isFullScreen}
                toggleFullScreen={toggleFullScreen}
            />
            <section className='w-full flex relative'>
                <AnimatePresence initial={false}>
                    <motion.div
                        className="hidden w-[450px] lg:block"
                        animate={{ width: isSidebarClosed ? 0 : "450px", opacity: [0, 1] }}
                        exit={{ width: 0, opacity: 0 }}
                    >
                        <div className='sticky top-[4rem]'>
                            <SectionView
                                activeSection={activeSection}
                                getSession={getSession}
                                cohortSections={cohortSectionList?.result || []}
                                onSideBarClose={() => setIsSidebarClosed(true)}
                            />
                        </div>
                    </motion.div>
                </AnimatePresence>
                <div className='w-full'>
                    <button
                        className={cn(
                            "flex item-center justify-center bg-back-ground-darkLight text-text-900 absolute top-10 left-0 px-3 z-20 py-2 gap-2 ease-in-out duration-500 rounded-sm",
                            isSidebarClosed ? "-translate-x-[7.7rem] hover:translate-x-0" : "hidden"
                        )}
                        onClick={() => setIsSidebarClosed(false)}
                    >
                        {"Cohort Content"}
                        <BsArrowRight size={22} />
                    </button>
                    <div id='fullScreen' className={"w-full h-[90vh] relative flex flex-col bg-white"}>
                        <ToolTipView
                            content={`Back | ${getSession(activeSession?.previous)?.title}`}
                            disabled={!activeSession?.previous}
                            placement={"right"}
                        >
                            <button
                                className={cn(
                                    "absolute left-0 top-[50%] transform -translate-y-[50%] z-30 rounded-sm opacity-30 hover:opacity-100 bg-back-ground-black text-white px-0.5 py-3",
                                    !activeSession?.previous && "hover:opacity-30 cursor-not-allowed"
                                )}
                                onClick={() => navigate(`session/${activeSession?.previous?.id}`)}
                                disabled={!activeSession?.previous}
                            >
                                <MdKeyboardArrowLeft size={25} />
                            </button>
                        </ToolTipView>
                        {(!!participantCohortCourse?.result && params?.sessionId) && (
                            <Outlet />
                        )}
                        {!params?.sessionId && (
                            <div className={"grow h-full flex items-center justify-center"}>
                                <span className={"font-bodyPri font-bold text-xl text-secondary-main"}>
                                    {"Oops, Please select any session..."}</span>
                            </div>
                        )}
                        <ToolTipView
                            content={`Next | ${getSession(activeSession?.next)?.title}`}
                            disabled={!activeSession?.next}
                            placement={"left"}
                        >
                            <button
                                className={cn(
                                    "absolute right-0 top-[50%] transform -translate-y-[50%] z-30 rounded-sm opacity-30 hover:opacity-100 bg-back-ground-black text-white px-0.5 py-3",
                                    !activeSession?.next && "hover:opacity-30 cursor-not-allowed"
                                )}
                                onClick={() => navigate(`session/${activeSession?.next?.id}`)}
                                disabled={!activeSession?.next}
                            >
                                <MdKeyboardArrowRight size={25} />
                            </button>
                        </ToolTipView>
                        <div className="w-full border-b px-5">
                            <div className='flex justify-end items-center gap-5 h-10'>
                                {!isFullScreen ? (isSidebarClosed ? (
                                    <ToolTipView content={"Exit expanded view"}>
                                        <span>
                                            <BsArrowsCollapse
                                                className='cursor-pointer rotate-90'
                                                size={20}
                                                onClick={() => setIsSidebarClosed(!isSidebarClosed)}
                                            />
                                        </span>
                                    </ToolTipView>
                                ) : (
                                    <ToolTipView content={"Expanded view"}>
                                        <span>
                                            <BsArrowsExpand
                                                className='cursor-pointer rotate-90'
                                                size={20}
                                                onClick={() => setIsSidebarClosed(!isSidebarClosed)}
                                            />
                                        </span>
                                    </ToolTipView>
                                )) : null}
                                {isFullScreen ? (
                                    <ToolTipView content={"Exit full screen"}>
                                        <span>
                                            <MdFullscreenExit
                                                className='cursor-pointer'
                                                size={28}
                                                onClick={toggleFullScreen}
                                            />
                                        </span>
                                    </ToolTipView>
                                ) : (
                                    <ToolTipView content={"Full screen"}>
                                        <span>
                                            <MdFullscreen
                                                className='cursor-pointer'
                                                size={28}
                                                onClick={toggleFullScreen}
                                            />
                                        </span>
                                    </ToolTipView>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default StudentCohort;