import { useCallback, useEffect, useMemo } from "react";
import { Navigate, useLocation, Outlet, useNavigate } from "react-router-dom";

import { crmClientsNavigationOptions, crmContactsNavigationOptions, crmLeadsNavigationOptions, crmNavigationOptions, searchParamsInfo } from "./data";

import { setPageInfo } from "redux/pageInfo/pageInfo.request";

import { useTitle } from "hooks/useTitle";
import { useAppDispatcher, useAppState } from "hooks/useStore";
import { resetAddCrmContactDetail, resetDestroyCrmBulkContactList, resetModifyCrmContactDetail } from "redux/crm/crm.slice";
import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";
import { crmContactCategoryEnum } from "redux/crm/crm.const";

import { pagesInfo } from "utils/pagesInfo";

export default function CrmLayoutPage({ allowedRoles }) {
    const { currentPageInfo } = useAppState((state) => state.pageInfo);
    const { locals, modal } = useAppState((s) => s.local);
    const { session } = useAppState((s) => s.auth);
    const { user } = useAppState((s) => s.user);
    const { addCrmContactDetail, modifyCrmContactDetail, modifyCrmBulkContactList } = useAppState((state) => state.crm)

    const dispatcher = useAppDispatcher();
    const navigate = useNavigate()
    const location = useLocation();
    const [title, setTitle] = useTitle();

    const searchQueryParams = useMemo(() => new URLSearchParams(location.search), [location.search])

    const onHandleCategoryNavigation = useCallback((contactCategory) => {
        const isTileView = location.pathname.includes("tile-view")
        if (contactCategory === crmContactCategoryEnum.LEAD.value) {
            navigate(`${pagesInfo.TUTOR_CRM.pagePath}${crmNavigationOptions.LEADS.pagePath}${isTileView ? crmLeadsNavigationOptions.TILE_VIEW.pagePath : crmLeadsNavigationOptions.LIST_VIEW.pagePath}`)
        } else if (contactCategory === crmContactCategoryEnum.CONTACT.value) {
            navigate(`${pagesInfo.TUTOR_CRM.pagePath}${crmNavigationOptions.CONTACTS.pagePath}${isTileView ? crmContactsNavigationOptions.TILE_VIEW.pagePath : crmContactsNavigationOptions.LIST_VIEW.pagePath}`)
        } else if (contactCategory === crmContactCategoryEnum.CLIENT.value) {
            navigate(`${pagesInfo.TUTOR_CRM.pagePath}${crmNavigationOptions.CLIENTS.pagePath}${isTileView ? crmClientsNavigationOptions.TILE_VIEW.pagePath : crmClientsNavigationOptions.LIST_VIEW.pagePath}`)
        }
        dispatcher(resetAddCrmContactDetail())
        dispatcher(resetModifyCrmContactDetail())
        dispatcher(resetDestroyCrmBulkContactList())
    }, [location])

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.TUTOR_CRM));
    }, [dispatcher, currentPageInfo]);

    useEffect(() => {
        setTitle({
            ...title,
            title: `${user?.user?.firstName}, Welcome to Your Edulyte Crm`
        })
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        if (searchQueryParams?.get(searchParamsInfo.action.key)) {
            dispatcher(setModal({
                ...modal,
                [modalConst.createCrmContactDetailModal.key]: {
                    ...modalConst.createCrmContactDetailModal,
                    isVisible: true
                }
            }))
        }
    }, [searchQueryParams?.get(searchParamsInfo.action.key)]);

    useEffect(() => {
        if (searchQueryParams?.get(searchParamsInfo.contact.key)) {
            dispatcher(setModal({
                ...modal,
                [modalConst.viewContactDetailModal.key]: {
                    ...modalConst.viewContactDetailModal,
                    isVisible: true
                }
            }))
        }
    }, [searchQueryParams?.get(searchParamsInfo.contact.key)]);

    useEffect(() => {
        if (addCrmContactDetail?.data) {
            onHandleCategoryNavigation(addCrmContactDetail?.data?.category)
        }
    }, [addCrmContactDetail?.data])

    useEffect(() => {
        if (modifyCrmContactDetail?.data) {
            onHandleCategoryNavigation(modifyCrmContactDetail?.data?.category)
        }
    }, [modifyCrmContactDetail?.data])

    useEffect(() => {
        if (modifyCrmBulkContactList?.data) {
            onHandleCategoryNavigation(modifyCrmBulkContactList?.data[0]?.category)
        }
    }, [modifyCrmBulkContactList?.data])

    return (
        <div className="h-full w-full mx-auto min-h-screen overflow-hidden p-3">
            {(session?.isLoggedIn && user?.user?.policy_permissions?.find((permission) => allowedRoles?.includes(permission?.name) && (permission?.name === locals.userRole)))
                ? <Outlet />
                : <Navigate to={pagesInfo.UNAUTHORIZED.pagePath} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />
            }
        </div>
    );
}