import { useEffect } from "react";
import { useParams } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { useTitle } from 'hooks/useTitle';

import { PageContentStyle } from 'pages/auth/checkoutBooking/CheckoutBookingStyle';
import HeaderSection from './components/HeaderSection';
import PaymentMethodSection from './components/PaymentMethodSection';
import PaymentSummerySection from './components/PaymentSummerySection';
import BookingItemSection from './components/BookingItemSection';

import PageLoader from 'components/loader/PageLoader';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getMyProfile } from 'redux/user/user.request';
import { getUserBookingDetail } from 'redux/booking/booking.request';
import { getMasterCountryList } from "redux/master/master.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { masterCurrencyCodeEnum } from "redux/master/master.const";
import {
    resetUserBookingDetail,
    setModifyUserBookingDetailPayload,
    resetModifyUserBookingDetail,
    resetBookingDetailConfirmation
} from 'redux/booking/booking.slice';
import { setAddPaymentIntentDetailPayload, resetAddPaymentIntentDetail } from 'redux/payment/payment.slice';
import { paymentTypeEnum } from 'redux/payment/payment.const';

import { pagesInfo } from 'utils/pagesInfo';
import { timeZone } from 'utils/dateTime.utils';


const CheckoutBooking = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { user } = useAppState((state) => state.user)
    const { userBookingDetail, modifyUserBookingDetail } = useAppState((state) => state.booking)
    const { addPaymentIntentDetail } = useAppState((state) => state.payment)

    const dispatcher = useAppDispatcher()
    const { bookingId } = useParams()
    const [title, setTitle] = useTitle()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.CHECKOUT_BOOKING))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        return () => {
            dispatcher(resetUserBookingDetail())
            dispatcher(resetModifyUserBookingDetail())
            dispatcher(resetAddPaymentIntentDetail())
            dispatcher(resetBookingDetailConfirmation())
        }
    }, [])

    useEffect(() => {
        dispatcher(getMyProfile())
    }, [])

    useEffect(() => {
        dispatcher(getUserBookingDetail(bookingId, user?.user?.userId, { timeZone: timeZone }))
        dispatcher(getMasterCountryList())
    }, [])

    useEffect(() => {
        if (userBookingDetail?.data?.result) {
            setTitle({
                ...title
            })
        }
        handleWalletToggle(modifyUserBookingDetail?.payload?.isWalletAdded)
    }, [userBookingDetail?.data?.result])

    const handleWalletToggle = (isToggle) => {
        let walletAmountToPay = 0
        let gatewayAmountToPay = 0

        let walletCredit = [masterCurrencyCodeEnum.USD.value]?.includes(userBookingDetail?.data?.result?.booking_price?.master_currency?.code)
            ? user?.user?.netWalletCredit
            : [masterCurrencyCodeEnum.AUD.value]?.includes(userBookingDetail?.data?.result?.booking_price?.master_currency?.code)
                ? user?.user?.netAudWalletCredit
                : [masterCurrencyCodeEnum.INR.value]?.includes(userBookingDetail?.data?.result?.booking_price?.master_currency?.code)
                    ? user?.user?.netInrWalletCredit
                    : 0

        if (isToggle) {
            let gatewayAmount = Math.ceil(userBookingDetail?.data?.result?.booking_price?.total_amount - (walletCredit + userBookingDetail?.data?.result?.booking_price?.promo_credit_used))
            if (gatewayAmount > 0) {
                walletAmountToPay = walletCredit
                gatewayAmountToPay = gatewayAmount
            } else {
                walletAmountToPay = userBookingDetail?.data?.result?.booking_price?.total_amount - userBookingDetail?.data?.result?.booking_price?.promo_credit_used
                gatewayAmountToPay = 0
            }
        } else {
            walletAmountToPay = 0
            gatewayAmountToPay = Math.ceil(userBookingDetail?.data?.result?.booking_price?.total_amount - userBookingDetail?.data?.result?.booking_price?.promo_credit_used)
        }
        // gatewayAmountToPay = Math.ceil(gatewayAmountToPay / 100) * 100

        dispatcher(setModifyUserBookingDetailPayload({
            ...modifyUserBookingDetail?.payload,
            offeringType: userBookingDetail?.data?.result?.offering_type,
            course: userBookingDetail?.data?.result?.cohort || null,
            appointment: userBookingDetail?.data?.result?.appointment || null,
            isWalletAdded: isToggle,
            note: userBookingDetail?.data?.result?.note,
            walletAmountToPay: walletAmountToPay,
            isGatewayIncluded: gatewayAmountToPay > 0,
            gatewayAmountToPay: gatewayAmountToPay
        }))

        if (gatewayAmountToPay > 0) {
            let amountToPay = [masterCurrencyCodeEnum.USD.value, masterCurrencyCodeEnum.AUD.value]?.includes(userBookingDetail?.data?.result?.booking_price?.master_currency?.code)
                ? (gatewayAmountToPay >= 100) ? gatewayAmountToPay : 100
                : [masterCurrencyCodeEnum.INR.value]?.includes(userBookingDetail?.data?.result?.booking_price?.master_currency?.code)
                    ? ((gatewayAmountToPay >= 10000) ? gatewayAmountToPay : 10000) : gatewayAmountToPay

            let processingFeeAmount = Math.ceil(amountToPay * user?.user?.processingFeePct / 100)

            let totalAmountToPay = Math.ceil(amountToPay + processingFeeAmount)

            dispatcher(setAddPaymentIntentDetailPayload({
                ...addPaymentIntentDetail?.payload,
                paymentType: paymentTypeEnum.BOOKING.value,
                email: user?.user?.email,
                name: `${user?.user?.firstName} ${user?.user?.lastName}`,
                description: paymentTypeEnum.BOOKING.value,
                currency: userBookingDetail?.data?.result?.booking_price?.master_currency?.code?.toLowerCase(),
                amountToPay: amountToPay,
                processingFeePct: user?.user?.processingFeePct,
                processingFee: processingFeeAmount,
                totalAmountToPay: totalAmountToPay,
            }))
        } else {
            dispatcher(resetAddPaymentIntentDetail())
        }
    }

    if (userBookingDetail?.isLoading) {
        return (
            <PageLoader />
        )
    }

    if (userBookingDetail?.message) {
        return (
            <PageContentStyle>
                <HeaderSection />
                <div className={"flex justify-center items-center font-bodyPri font-semibold text-text-800 text-md"}>
                    {userBookingDetail?.message ? "Sorry, Booking not found." : ""}
                </div>
            </PageContentStyle>
        )
    }

    const setStripeAccount = () => {
        let account_id = "";

        if (!!user?.user?.gateways?.primary && user?.user?.gateways?.primary?.provider_type === 'stripe') {
            account_id = user?.user?.gateways?.primary?.account_id
        } else if (!!user?.user?.gateways?.secondary && user?.user?.gateways?.secondary?.provider_type === 'stripe') {
            account_id = user?.user?.gateways?.secondary?.account_id
        }
        return account_id
    }

    return userBookingDetail?.data?.result ? (
        <PageContentStyle>

            <HeaderSection />

            <div className={"container mx-auto px-8 py-5 grid grid-cols-12 gap-5"}>

                <div className={"col-start-1 col-span-full lg:col-span-8 space-y-5"}>

                    <BookingItemSection />

                    <PaymentMethodSection handleWalletToggle={handleWalletToggle} />

                </div>

                <div className={"relative col-start-1 col-span-full lg:col-start-9 lg:col-span-4"}>
                    <div className={"lg:sticky lg:top-[1rem] lg:mt-10"}>

                        <Elements stripe={loadStripe(user?.user?.stripePk, {
                            stripeAccount: setStripeAccount()
                        })}>
                            <PaymentSummerySection />
                        </Elements>
                    </div>
                </div>

            </div>
        </PageContentStyle>
    ) : null
}

export default CheckoutBooking;