import { useCallback, useState } from 'react';

import { toast } from 'react-toastify';
import { cn } from "utils/cn.utils";

import { MdIosShare, MdOutlineContentCopy } from 'react-icons/md';
import { FaSpinner } from 'react-icons/fa';
import { FiCheckSquare, FiEdit } from 'react-icons/fi';
import { RiDeleteBin6Line } from 'react-icons/ri';

import ToolTipView from 'components/tooltipView';

import SaveAndCancelBtn from 'pages/auth/edulyteLms/commonComponents/lmsButtons/SaveAndCancelBtn';

import { lmsCourseTooltips } from '../../../../editLMSCourse.data';

import { createLmsCourseSlugDetail, deleteLmsCourseSlugDetail, updateLmsCourseDetail } from "redux/edulyteLms/lmsCourse/lmsCourse.request";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setClearShareSocial, setModal, setShareSocial } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';

import { EDULYTE_WEB_DOMAIN_URL } from 'const/default.const';

import { validateSlug } from 'utils/validation-functions';
import { getCustomHostPath } from 'utils/generators.utils';
import useCustomNavigation from 'hooks/useCustomNavigation';

const MAX_LENGTH = 110;

const CourseSlug = () => {
    const publicOrgDetail = useAppState((state) => state.org.publicOrgDetail)
    const { modal, shareSocial } = useAppState((state) => state.local)
    const { lmsCourseDetail, addLmsCourseSlugDetail, destroyLmsCourseSlugDetail } = useAppState((state) => state.lms.lmsCourse)

    const dispatcher = useAppDispatcher()
    const customNavigation = useCustomNavigation()

    const [isAddLinkFormEnabled, setIsAddLinkFormEnabled] = useState(false)
    const [linkTitle, setLinkTitle] = useState("")
    const [copiedUrl, setCopiedURL] = useState(false)

    const onHandleMakeDefault = useCallback((courseSlugId) => {
        if (lmsCourseDetail?.data?.course_setting?.default_slug?.id === courseSlugId) return;

        if (!window.confirm("Are you sure? This will invalidate currently active link")) return;

        dispatcher(updateLmsCourseDetail(lmsCourseDetail?.data?.id, { default_slug_id: courseSlugId }))
    }, [lmsCourseDetail?.data])

    const onHandleSaveLink = useCallback(() => {
        if (lmsCourseDetail?.isLoading) return;

        if (!linkTitle) {
            toast.warn("Please add link!")
            return;
        }

        if (!validateSlug(linkTitle?.toLowerCase()?.trim())) {
            toast.warn("Please provide valid link!")
            return;
        }

        const body = {
            lms_course_id: lmsCourseDetail?.data?.id,
            slug: linkTitle?.toLowerCase()?.trim()
        }
        dispatcher(createLmsCourseSlugDetail(body))
        setLinkTitle('')
        setIsAddLinkFormEnabled(false)
    }, [linkTitle, lmsCourseDetail?.data])

    const onHandleResetLink = useCallback(() => {
        setLinkTitle('')
        setIsAddLinkFormEnabled(false)
    }, [])

    const onHandleDeleteCourseSlug = useCallback((courseSlugId) => {
        if (addLmsCourseSlugDetail?.isLoading || destroyLmsCourseSlugDetail?.isLoading) return;

        dispatcher(deleteLmsCourseSlugDetail(courseSlugId))
    }, [lmsCourseDetail?.data, addLmsCourseSlugDetail?.isLoading, destroyLmsCourseSlugDetail?.isLoading])

    const onHandleCopyUrl = useCallback(() => {
        // navigator.clipboard.writeText(`${EDULYTE_WEB_DOMAIN_URL}/course/${lmsCourseDetail?.data?.active_slug}`);
        // navigator.clipboard.writeText(`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/course/${lmsCourseDetail?.data?.active_slug}` })}`);
        navigator.clipboard.writeText(`${customNavigation.getCustomBaseUrl({ path: `/course/${lmsCourseDetail?.data?.active_slug}`, isWeb: true })}`);
        setCopiedURL(true);
        toast.success("Copied!")
        setTimeout(() => {
            setCopiedURL(false);
        }, 2000);
    }, [lmsCourseDetail?.data])

    const onHandleShareCourse = useCallback(() => {
        // const LMS_COURSE_URL = `${EDULYTE_WEB_DOMAIN_URL}/course/${lmsCourseDetail?.data?.active_slug}`
        // const LMS_COURSE_URL = `${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/course/${lmsCourseDetail?.data?.active_slug}` })}`
        const LMS_COURSE_URL = `${customNavigation.getCustomBaseUrl({ path: `/course/${lmsCourseDetail?.data?.active_slug}`, isWeb: true })}`
        dispatcher(setClearShareSocial())
        dispatcher(setShareSocial({
            ...shareSocial,
            socialAction: "",
            url: LMS_COURSE_URL
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.SHARE_SOCIAL_MODAL.stateKey]: true
        }))
    }, [lmsCourseDetail?.data])

    return (
        <div className={"w-full flex flex-col gap-5"}>
            <div className={"w-full px-3 py-2 bg-divider-medium rounded-lg"}>
                <span className={"font-bodyPri font-bold text-text-900 text-lg tracking-wide"}>
                    {"Shareable Course Link"}
                </span>
            </div>

            <div className={"w-full px-5 font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                {lmsCourseTooltips?.shareableCourseLink}
            </div>

            <div className={"w-full px-5 space-y-5"}>
                {lmsCourseDetail?.data?.active_slug &&
                    <div className={""}>
                        {isAddLinkFormEnabled &&
                            <div className={"w-full grid grid-cols-12 gap-3 items-center"}>
                                <div className={"col-span-8 order-1 lg:col-span-11 space-x-1 mt-0 md:mt-1"}>
                                    <div className={"w-full flex items-center justify-start gap-0.5"}>
                                        <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide"}>
                                            {/* {`${EDULYTE_WEB_DOMAIN_URL}/course/`} */}
                                            {/* {`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/course/` })}`} */}
                                            {`${customNavigation.getCustomBaseUrl({ path: `/course/`, isWeb: true })}`}
                                        </span>
                                        <input
                                            type={"text"}
                                            name={"slug"}
                                            placeholder={"title-of-the-course"}
                                            className={cn(
                                                "h-fit flex-1 px-3 border-b-2 border-divider-lightDark",
                                                "font-bodyPri font-normal text-text-900 text-base tracking-wide",
                                                "focus:outline-none focus:border-primary-main"
                                            )}
                                            maxLength={MAX_LENGTH}
                                            value={linkTitle}
                                            onChange={(event) => setLinkTitle(event?.target?.value?.toLowerCase())}
                                        />
                                    </div>
                                    <span className={"w-full flex justify-end font-bodyPri font-normal text-sm text-text-800"}>
                                        {`${linkTitle?.length}/${MAX_LENGTH}`}
                                    </span>
                                </div>
                                <div className={"col-span-full order-3 lg:order-2 lg:col-start-12 lg:col-span-1 justify-self-stretch flex items-center justify-start gap-1"}>
                                    {addLmsCourseSlugDetail?.isLoading &&
                                        <div className={"flex items-center justify-start gap-1.5"}>
                                            <FaSpinner className={"animate-spin text-primary-dark text-base"} />
                                            <span className={"font-bodyPri font-normal text-primary-dark text-sm"}>
                                                {"Please wait"}
                                            </span>
                                        </div>
                                    }
                                    {!addLmsCourseSlugDetail?.isLoading &&
                                        <SaveAndCancelBtn
                                            onHandleSave={onHandleSaveLink}
                                            onHandleReset={onHandleResetLink}
                                        />
                                    }
                                </div>
                            </div>
                        }
                        {!isAddLinkFormEnabled &&
                            <div className={"w-full grid grid-cols-12 gap-2 items-start"}>
                                <div className={"col-span-8 order-1 lg:col-span-11 space-x-1 mt-0 md:mt-1"}>
                                    <a
                                        // href={`${EDULYTE_WEB_DOMAIN_URL}/course/${lmsCourseDetail?.data?.active_slug}`}
                                        // href={`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/course/${lmsCourseDetail?.data?.active_slug}` })}`}
                                        href={`${customNavigation.getCustomBaseUrl({ path: `/course/${lmsCourseDetail?.data?.active_slug}`, isWeb: true })}`}
                                        className={"w-fit font-bodyPri font-normal text-primary-dark text-base tracking-wide group hover:underline hover:text-primary-dark"}
                                        target={"_blank"}
                                    >
                                        <span>
                                            {/* {`${EDULYTE_WEB_DOMAIN_URL}/course/`} */}
                                            {/* {`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/course/` })}`} */}
                                            {`${customNavigation.getCustomBaseUrl({ path: `/course/`, isWeb: true })}`}
                                        </span>
                                        <span className={"text-primary-dark group-hover:text-primary-dark"}>
                                            {lmsCourseDetail?.data?.active_slug}
                                        </span>
                                    </a>
                                    <span className={"font-bodyPri font-medium text-primary-dark text-base whitespace-nowrap"}>
                                        {"(Currently Active)"}
                                    </span>
                                </div>
                                <div className={"col-span-full order-3 lg:order-2 lg:col-start-12 lg:col-span-1 justify-self-stretch flex items-center justify-start gap-1"}>
                                    <ToolTipView content={"Edit"}>
                                        <button
                                            className={"p-2 rounded-full hover:bg-divider-darkLight"}
                                            onClick={() => setIsAddLinkFormEnabled(true)}
                                        >
                                            <FiEdit className={"text-lg text-text-700 cursor-pointer"} />
                                        </button>
                                    </ToolTipView>
                                    <ToolTipView content={copiedUrl ? "copied" : "Copy"}>
                                        <button
                                            className={"p-2 rounded-full hover:bg-divider-darkLight"}
                                            onClick={onHandleCopyUrl}
                                        >
                                            {copiedUrl &&
                                                <FiCheckSquare className={"text-xl text-green-500"} />
                                            }
                                            {!copiedUrl &&
                                                <MdOutlineContentCopy className={"text-xl text-text-700 cursor-pointer"} />
                                            }
                                        </button>
                                    </ToolTipView>
                                    <ToolTipView content={"Share"}>
                                        <button
                                            className={"p-2 rounded-full hover:bg-divider-darkLight"}
                                            onClick={onHandleShareCourse}
                                        >
                                            <MdIosShare className={"text-text-700 text-xl cursor-pointer"} />
                                        </button>
                                    </ToolTipView>
                                </div>
                            </div>
                        }
                    </div>
                }

                {((lmsCourseDetail?.data?.course_slugs?.filter((slugItem) => slugItem?.id !== lmsCourseDetail?.data?.course_setting?.default_slug?.id))?.length > 0) &&
                    <hr className={"w-full h-0.5 bg-divider-medium"} />
                }
                {((lmsCourseDetail?.data?.course_slugs?.filter((slugItem) => slugItem?.id !== lmsCourseDetail?.data?.course_setting?.default_slug?.id))?.length > 0) &&
                    <div className={"flex flex-col gap-5"}>
                        <span className={"font-bodyPri font-medium text-text-800 text-md tracking-wide"}>
                            {"Inactive links:"}
                        </span>
                        {lmsCourseDetail?.data?.course_slugs?.filter((slugItem) => slugItem?.id !== lmsCourseDetail?.data?.course_setting?.default_slug?.id)?.map((courseSlug, index) => (
                            <div key={index} className={"w-full grid grid-cols-12 gap-2 items-start"}>
                                <div
                                    className={"col-span-full order-3 lg:order-2 lg:col-span-11 justify-self-stretch mt-0 md:mt-1 inline-flex w-fit font-bodyPri font-normal text-text-700 text-base line-clamp-1 tracking-wide"}>
                                    <span>
                                        {/* {`${EDULYTE_WEB_DOMAIN_URL}/course/`} */}
                                        {/* {`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/course/` })}`} */}
                                        {`${customNavigation.getCustomBaseUrl({ path: `/course/`, isWeb: true })}`}
                                    </span>
                                    <span className={""}>
                                        {courseSlug?.slug}
                                    </span>
                                </div>
                                <div className={"col-span-full order-3 lg:order-2 lg:col-start-12 lg:col-span-1 justify-self-stretch flex items-center gap-4"}>
                                    <span
                                        className={"font-bodyPri font-medium text-text-700 text-sm hover:text-text-800 cursor-pointer"}
                                        onClick={() => onHandleMakeDefault(courseSlug?.id)}
                                    >
                                        {"Activate"}
                                    </span>
                                    <ToolTipView content={"Delete Link"}>
                                        <button className={"p-2 rounded-full hover:bg-divider-darkLight"} onClick={() => onHandleDeleteCourseSlug(courseSlug?.id)}>
                                            <RiDeleteBin6Line className={"text-xl text-text-700 hover:text-red-500 cursor-pointer"} />
                                        </button>
                                    </ToolTipView>
                                </div>
                            </div>
                        ))}
                    </div>
                }
            </div>
        </div>
    )
}

export default CourseSlug;