import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { toast } from "react-toastify";
import { Draggable } from 'react-drag-reorder';

import { AiOutlineCheckCircle } from 'react-icons/ai';
import { FaTimes } from 'react-icons/fa';

import ComponentLoader from 'components/loader/ComponentLoader';

import { classDetailNavigationOptions, classDetailTooltips } from '../../data';
import PageHeading from '../../_components/header/pageHeading';
import AddBorderButton from '../../_components/buttons/AddBorderButton';
import PreviousNextButtons from '../../_components/buttons/PreviousNextButtons';

import { createCourseTag, deleteCourseTag, getCourseTags, updateCourseTagOrderList } from 'redux/course/course.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { resetCourseTags } from 'redux/course/course.slice';

import { cn } from "utils/cn.utils";

const SkillsPage = () => {
    const { courseTags, addCourseTag, destroyCourseTag } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()
    const { courseId } = useParams()

    const [isShowSkills, setIsShowSkills] = useState(courseTags?.result?.length > 0)
    const [inputSkill, setInputSkill] = useState("")

    useEffect(() => {
        if (courseId) {
            dispatcher(getCourseTags(Number(courseId)))
        }

        return () => {
            dispatcher(resetCourseTags())
        }
    }, [courseId])

    useEffect(() => {
        if (courseTags?.result?.length > 0) {
            setIsShowSkills(true)
        }
    }, [courseTags?.result])

    const onHandleAddTags = useCallback(() => {
        setIsShowSkills(true)
    }, [isShowSkills])

    const onRemoveSkillTag = useCallback((selectedSkillId) => {

        dispatcher(deleteCourseTag(selectedSkillId))
    }, [])

    const onHandleAddSkill = useCallback(() => {
        if (!inputSkill || (inputSkill?.split(" ")?.length > 3)) {
            toast.error("Skills can have upto 3 words")
            return;
        }
        const skillTag = inputSkill.trim()
        const filteredSkills = courseTags?.result?.filter((skill) => skill?.master_tag?.tag === skillTag)

        if (filteredSkills?.length > 0) {
            toast.warn("Duplicate skills are not allowed!")
            return;
        }
        const body = {
            tag: skillTag
        }
        dispatcher(createCourseTag(Number(courseId), body))
        setTimeout(() => {
            setInputSkill("");
        }, 1000);
    }, [, inputSkill])

    const onKeyboardSave = useCallback((e) => {
        if (((e.keyCode === 9) || (e.keyCode === 13)) && inputSkill.trim()) {
            if (!inputSkill || (inputSkill?.split(" ")?.length > 3)) {
                toast.error("Skills can have upto 3 words")
                return;
            }
            const skillTag = inputSkill.trim()
            const filteredSkills = courseTags?.result?.filter((skill) => skill?.master_tag?.tag === skillTag)

            if (filteredSkills?.length > 0) {
                toast.warn("Duplicate skills are not allowed!")
                return;
            }
            dispatcher(createCourseTag(Number(courseId), {
                tag: skillTag
            }))
            setTimeout(() => {
                setInputSkill("");
            }, 1000);
        }
    }, [inputSkill])

    const onHandleUpdateTagOrder = useCallback((currentPos, newPos) => {
        var updatedList = [...courseTags?.result];
        const [reorderedItem] = updatedList.splice(currentPos, 1);
        updatedList.splice(newPos, 0, reorderedItem);

        const body = {
            order: updatedList?.map((skill) => ({
                id: skill?.id
            }))
        }
        dispatcher(updateCourseTagOrderList(Number(courseId), body, { course_id: Number(courseId) }))
    }, [courseId, courseTags?.result])

    return (
        <div className={"w-full space-y-5"}>
            <div className={"w-full flex flex-col gap-5"}>
                <PageHeading headerText={"Skills you'll learn (keywords)"} tooltipText={classDetailTooltips?.skills} />
                {!isShowSkills &&
                    <AddBorderButton
                        btnTitle={"Add Skill(s)"}
                        onHandleAdd={onHandleAddTags}
                    />
                }
                {isShowSkills &&
                    <div className={cn(
                        "w-full px-3 py-3 flex flex-col items-start justify-start border border-dashed border-text-500 rounded-lg",
                        "font-bodyPri font-normal text-text-900"
                    )}>
                        {(courseTags?.isLoading || addCourseTag?.isLoading || destroyCourseTag?.isLoading) &&
                            <ComponentLoader isLoading={courseTags?.isLoading || addCourseTag?.isLoading || destroyCourseTag?.isLoading} />
                        }
                        {(!addCourseTag?.isLoading && !destroyCourseTag?.isLoading) &&
                            <div className={"flex flex-wrap gap-2"}>
                                <Draggable onPosChange={onHandleUpdateTagOrder}>
                                    {(courseTags?.result?.length > 0)
                                        && courseTags?.result?.map((skill, index) => (
                                            <div key={index} className="w-fit flex items-center px-3 py-1 bg-primary-light rounded-lg font-bodyPri font-normal text-text-900 text-sm">
                                                <span className="sm:whitespace-nowrap">
                                                    {skill?.master_tag?.tag}
                                                </span>
                                                <button className="pl-3 cursor-pointer">
                                                    <FaTimes className="font-light text-secondary-dark" onClick={() => onRemoveSkillTag(skill?.id)} />
                                                </button>
                                            </div>
                                        ))}
                                </Draggable>
                                <div className="flex items-center gap-3">
                                    <input
                                        className={cn(
                                            "w-40 px-3 py-1 border-2 border-text-100 rounded-lg focus:outline-none focus:border-2 focus:border-text-300",
                                            "font-bodyPri font-normal text-text-700 text-sm placeholder:text-text-400 placeholder:text-sm"
                                        )}
                                        type={"text"}
                                        placeholder={"Skill name"}
                                        value={inputSkill}
                                        onChange={(event) => setInputSkill(event.target.value)}
                                        onKeyDown={onKeyboardSave}
                                        autoFocus={true}
                                    />
                                    <AiOutlineCheckCircle className={"text-2xl text-text-800 cursor-pointer"} onClick={onHandleAddSkill} />
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
            <PreviousNextButtons
                previousBtn={classDetailNavigationOptions?.description?.to}
                nextBtn={classDetailNavigationOptions?.languages?.to}
            />
        </div>
    )
}

export default SkillsPage;