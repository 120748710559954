import { Fragment, memo, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { toast } from "react-toastify";
import { Dialog, Transition } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";

import { AiOutlineClose } from "react-icons/ai";
import { MdEdit } from "react-icons/md";

import ButtonLoader from "components/loader/ButtonLoader";
import AiTextButton from "components/createWithAi/aiTextButton";

import useAiText from "hooks/useAiText";
import { useAppDispatcher, useAppState } from "hooks/useStore";
import courseServices from "redux/course/course.services";

import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";
import { setInstructorCourse } from "redux/course/course.slice";

import { cn } from "utils/cn.utils";
import { pagesInfo } from "utils/pagesInfo";

const createCourseSchema = z.object({
    title: z.string().min(10, { message: "Title length should be greater than 10 character" }).max(128, { message: "title length should be less than 128 character" }),
    subtitle: z.string().min(10, { message: "Sub Title length should be greater than 10 character" }).max(256, { message: "title length should be less than 256 character" }).optional()
})

const AddCohortModal = ({
    openModal = false,
    maxWidth = "max-w-2xl",
    bgColor = "bg-white",
}) => {
    const { modal } = useAppState((state) => state.local)
    const { instructorCourse } = useAppState((state) => state.course)

    const navigate = useNavigate()

    const [isShowSubHeading, setIsShowSubHeading] = useState(false)
    const [isShowInput, setIsShowInput] = useState(false)
    const [topic, setTopic] = useState('')

    const { courseId } = useParams()
    const dispatcher = useAppDispatcher()
    const { aiText, generateAiText } = useAiText()

    const form = useForm({
        mode: "onChange",
        resolver: zodResolver(createCourseSchema),
    })

    const isLoading = form.formState.isSubmitting
    const values = form.watch(form.control)

    const handleCreateCohortDetail = async (data) => {
        try {
            const requestData = {
                params: { id: Number(courseId) },
                body: data
            }
            const response = await courseServices.createCohortCourse(requestData)
            if (response.status === 201) {
                dispatcher(setInstructorCourse({
                    result: {
                        ...instructorCourse?.result,
                        cohorts: [response?.data?.data?.result, ...instructorCourse?.result?.cohorts]
                    }
                }))
                toast.success(response.data.data.message)
                closeModal()
                navigate(`${pagesInfo.CREATE_COURSE.pagePath}/${courseId}/edit/cohorts/${response?.data?.data?.result?.id}`)
            } else {
                throw new Error(response.data.error || "Oops, Something went wrong!")
            }
        } catch (error) {
            form.setError({ "root": error?.response?.data?.error || error?.message || "Oops, Something went wrong!" })
        }
    }

    const requestData = {
        purpose: "title", // description or title 
        total_chars: 85,
        topic: topic,
        response_type: "text" // text or html 
    };

    const getAiText = () => {
        if (topic === "") {
            toast.error("Please write ai prompt");
            return
        }
        setIsShowInput(false)
        generateAiText(requestData);
    };

    const handlePrompt = (e) => {
        const value = e.target.value
        setTopic(value)
    }

    useEffect(() => {
        form.reset()
    }, [])

    useEffect(() => {
        if (aiText?.data?.ai_response) {
            form.setValue("title", aiText.data.ai_response)
            setTopic("")
        }
    }, [aiText?.data]);

    const closeModal = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.addCohortModal.key]: {
                ...modal.addCohortModal,
                isVisible: false
            }
        }))
        setTopic("")
        form.reset()
    }

    return (
        <Transition appear show={openModal} as={Fragment}>
            <Dialog
                as="div"
                className="fixed max-h-screen inset-0 z-50 overflow-y-auto"
                open={openModal}
                onClose={closeModal}
            >
                <div className="p-5 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span aria-hidden="true" className="inline-block h-screen align-middle">&#8203;</span>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className={cn(
                            "inline-block w-full shadow-xl rounded-lg space-y-5 overflow-hidden",
                            `text-left align-middle transition-all transform ${maxWidth} ${bgColor}`
                        )}>
                            <Dialog.Description as="div">
                                <div className={cn(
                                    "w-full h-full overflow-hidden overflow-y-scroll",
                                    "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                                    "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                                )}>
                                    <div className={"flex w-full justify-between items-center bg-back-ground-light h-16 px-5 drop-shadow-sm"}>
                                        <p className="font-bodyPri font-medium text-text-900 text-lg">
                                            {"Create a Cohort"}
                                        </p>
                                        <AiOutlineClose
                                            onClick={closeModal}
                                            className={'cursor-pointer'}
                                            size={20}
                                        />
                                    </div>
                                    <form
                                        onSubmit={form.handleSubmit(handleCreateCohortDetail)}
                                        className={"w-full flex flex-col gap-5 bg-white p-10"}
                                    >
                                        <div className={"w-full flex flex-col gap-3"}>
                                            <div className="relative flex justify-between items-center">
                                                <AiTextButton
                                                    isShowInput={isShowInput}
                                                    setIsShowInput={setIsShowInput}
                                                    handlePrompt={handlePrompt}
                                                    getAiText={getAiText}
                                                    aiText={aiText}
                                                />
                                                <div className={"w-full flex justify-end"}>
                                                    {(values?.title?.length > 0) &&
                                                        <span className={"font-bodyPri font-normal text-sm text-text-700"}>
                                                            {`${values?.title?.length}/{10 - 128}`}
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                            <input
                                                type={"text"}
                                                {...form.register("title")}
                                                className={cn(
                                                    "px-3 h-12 flex w-full focus:outline-none rounded-lg border border-back-ground-lightDark focus:border focus:border-primary-main",
                                                    "font-bodyPri font-normal text-text-800 text-base placeholder:text-text-500"
                                                )}
                                                placeholder={'Add Cohort Title'}
                                            />
                                        </div>
                                        <AnimatePresence initial={isShowSubHeading}>
                                            {!isShowSubHeading &&
                                                <div
                                                    className={"w-full flex items-center justify-between gap-3 cursor-pointer pt-1 group"}
                                                    onClick={() => setIsShowSubHeading(true)}
                                                >
                                                    <span className={"font-bodyPri font-normal text-primary-dark text-sm tracking-wide line-clamp-2"}>
                                                        {values?.subtitle
                                                            ? values?.subtitle
                                                            : "Sub Heading (optional)"
                                                        }
                                                    </span>
                                                    <MdEdit className={"text-xl text-text-900 hidden group-hover:flex"} />
                                                </div>
                                            }
                                            {isShowSubHeading &&
                                                <motion.div
                                                    initial={{ opacity: 0, height: 0 }}
                                                    animate={{ opacity: 1, height: 'auto' }}
                                                    exit={{ opacity: 0, height: 0 }}
                                                    transition={{ duration: 0.3 }}
                                                    className={"w-full"}
                                                >
                                                    <textarea
                                                        type={'text'}
                                                        {...form.register("subtitle")}
                                                        className={cn(
                                                            'w-full border outline-none rounded-lg p-2 focus:border-primary-main opacity-100 transition-opacity duration-300',
                                                            'font-bodyPri font-normal text-text-800 text-sm tracking-wide placeholder:text-text-500'
                                                        )}
                                                        placeholder={"Sub Header (optional)"}
                                                        rows={3}
                                                        autoFocus={true}
                                                        onBlur={() => setIsShowSubHeading(false)}
                                                    />
                                                </motion.div>
                                            }
                                        </AnimatePresence>
                                        <div className={"w-full flex items-center justify-start"}>
                                            {(form.formState?.errors?.root?.message) &&
                                                <span className={"w-full font-bodyPri font-normal text-red-500 text-base tracking-wide text-center"}>
                                                    {form.formState?.errors?.root?.message}
                                                </span>
                                            }
                                        </div>
                                        <motion.button
                                            disabled={isLoading}
                                            whileHover={{ scale: 1.05 }}
                                            whileTap={{ scale: 0.95 }}
                                            className={cn(
                                                'self-end font-bodyPri font-normal py-2 w-36 bg-primary-dark text-white rounded-full hover:bg-secondary-dark ease-in-out duration-100'
                                            )}
                                        >
                                            {isLoading ? <ButtonLoader isLoading={isLoading} /> : "Create"}
                                        </motion.button>
                                    </form>
                                </div>
                            </Dialog.Description>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
}

export default memo(AddCohortModal);