import { useEffect, useState } from 'react'

import { Controller, useFormContext } from 'react-hook-form';

import { IoIosCheckmark } from 'react-icons/io';

import DropdownMenu from '../dropdowns/DropdownMenu';
import { inPersonMeetingInputConst, locationInputLengths } from '../../data';

import { useAppState } from 'hooks/useStore';
import { cn } from 'utils/cn.utils';

const InPersonMeetingLocation = ({ values }) => {
    const { countryList, timeZoneCountryDetail } = useAppState((state) => state.master)

    const { control, setValue, register, errors } = useFormContext()

    const [isMounted, setIsMounted] = useState(false)

    useEffect(() => {
        setIsMounted(true)
    }, [])

    useEffect(() => {
        if (!values?.session_locations[0]?.in_person_meeting?.country && countryList?.countryList && timeZoneCountryDetail?.timeZoneCountryDetail) {
            let myLocalCountryList = countryList?.countryList?.filter((country) => (
                country?.masterCountryId === timeZoneCountryDetail?.timeZoneCountryDetail?.country?.masterCountryId
            ))

            if (myLocalCountryList.length === 0) {
                myLocalCountryList = countryList?.countryList?.filter((country) => (
                    country.countryDomain === "US"
                ))
            }
            setValue("session_locations[0].in_person_meeting.country", myLocalCountryList[0]?.country ?? "")
        }
    }, [values?.session_locations[0]?.in_person_meeting?.country, countryList?.countryList, timeZoneCountryDetail?.timeZoneCountryDetail])

    const sortCountryList = (country1, country2) => {
        if (country1?.country === values?.session_locations[0]?.in_person_meeting?.country) return -1;
        if (country2?.country === values?.session_locations[0]?.in_person_meeting?.country) return 1;
        return 0;
    }

    if (!isMounted) return null

    return (
        <div className={"flex flex-col gap-5 w-full"}>
            <Controller
                name={"session_locations[0].in_person_meeting.country"}
                control={control}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <div className={"w-full space-y-1"}>
                        <div className={"w-full"}>
                            <DropdownMenu
                                isLoading={countryList?.isLoading}
                                isSearchEnabled={true}
                                options={countryList?.countryList?.slice(0)?.sort(sortCountryList)?.map((country) => ({
                                    label: `${country?.country} (+${country?.isdCode})`,
                                    value: country?.country,
                                    country: country
                                })) || []}
                                onHandleSelect={(option) => {
                                    onChange(option?.country?.country);
                                }}
                                selectedOption={
                                    <>
                                        {!!value
                                            ? <div className={"flex items-center gap-2 font-bodyPri font-normal text-text-800"}>
                                                <img
                                                    src={`https://flagcdn.com/16x12/${countryList?.countryList?.find(countryItem => countryItem?.country === value)?.countryDomain}.png`.toLowerCase()}
                                                    className={"w-8 h-5 object-cover"}
                                                    alt={"country-flag"}
                                                />
                                                <span className={"text-sm"}>{value}</span>
                                            </div>
                                            : "Select Any"
                                        }
                                    </>
                                }
                                selectedMenuItem={countryList?.countryList?.find(countryItem => countryItem?.country === value)?.country}
                                OptionChild={({ option }) => (
                                    <div className={"w-full flex items-center justify-between gap-3"}>
                                        <div className={"flex items-center gap-3"}>
                                            <img
                                                src={`https://flagcdn.com/16x12/${option?.country?.countryDomain}.png`.toLowerCase()}
                                                className={"w-10 h-6 object-cover"}
                                            />
                                            {option?.label}
                                        </div>
                                        {(option?.value === value)
                                            && <IoIosCheckmark size={20} color="green" />
                                        }
                                    </div>
                                )}
                                menuListProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }
                                }}
                            />
                        </div>
                        {!!error && (
                            <div className={"flex items-center justify-start"}>
                                <span className={"font-bodyPri font-normal text-sm text-secondary-main"}>
                                    {error?.message}
                                </span>
                            </div>
                        )}
                    </div>
                )}
            />
            {Object.values(inPersonMeetingInputConst).map((addressInput, index) => (
                <div
                    key={index}
                    className={cn(
                        "relative group w-full h-full p-2 flex-1 flex gap-3 items-center border rounded focus-within:shadow",
                        !!errors?.session_locations?.[0]?.in_person_meeting?.[addressInput?.value]?.message
                            ? 'border-secondary-dark focus-within:border-secondary-main shadow shadow-secondary-main/20 focus-within:shadow-secondary-main/20'
                            : values?.session_locations?.[0]?.in_person_meeting?.[addressInput?.value]
                                ? 'border-primary-main focus-within:border-primary-main shadow shadow-primary-main/20 focus-within:shadow-primary-main/20'
                                : 'border-text-400 focus-within:border-primary-main focus-within:shadow-primary-main/20'
                    )}
                >
                    <input
                        type={"text"}
                        className={('w-full h-full bg-transparent outline-none text-base')}
                        placeholder={addressInput?.label}
                        maxLength={locationInputLengths?.[addressInput?.value]?.maxLength}
                        {...register(`session_locations[0].in_person_meeting.${addressInput?.value}`)}
                    />
                    {!!values?.session_locations[0]?.in_person_meeting?.[addressInput?.value]?.length &&
                        <div className={"flex justify-end"}>
                            <span className={"font-bodyPri font-normal text-text-800 text-sm"}>
                                {`${values?.session_locations[0]?.in_person_meeting?.[addressInput?.value]
                                    ? values?.session_locations[0]?.in_person_meeting?.[addressInput?.value]?.length
                                    : 0
                                    }/${locationInputLengths?.[addressInput?.value]?.maxLength}`}
                            </span>
                        </div>
                    }
                </div>
            ))}
        </div>
    )
}

export default InPersonMeetingLocation;