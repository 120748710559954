import { isSessionId, saveConsent } from 'utils/cookie-manager';
import { baseCredApiInstance } from 'apiInstances/axios.apiInstance';

const CONSENT_STATUS = {
    ACCEPTED : 'accepted',
    REJECTED: 'rejected'
}

export const CookieConsentBanner = ({ onClose, onOpenSettings }) => {
    const handleAcceptAll = async() => {
        const consent = {
            analytics: "accepted",
            marketing: "accepted",
        };
        const requestData = {
            consent_status: CONSENT_STATUS.ACCEPTED
        }
        const session_id = isSessionId();

        if (session_id) {
            requestData.session_id = session_id;
        }

        const response = await baseCredApiInstance().post('cookie-consent/status/', requestData);
        if (response.status === 200) {
            saveConsent(consent, response?.data?.data?.result?.session_id);
        }
        onClose();
    };

    const handleRejectAll = async() => {
        const consent = {
            analytics: "rejected",
            marketing: "rejected",
        };

        const session_id = isSessionId();

        const requestData = {
            consent_status: CONSENT_STATUS.REJECTED
        }
        if (session_id) {
            requestData.session_id = session_id;
        }

        const response = await baseCredApiInstance().post('cookie-consent/status/', requestData)
        if (response.status === 200) {
            saveConsent(consent, response?.data?.data?.result?.session_id);
        }
        onClose();
    };

    return (
        <div className="fixed bottom-0 left-0 right-0 bg-white shadow-lg border-t border-gray-200 p-6 z-50">
            <div className="max-w-7xl mx-auto">
                <div className="flex items-start justify-between">
                    <div className="flex-1 pr-8">
                        <h2 className="text-xl font-semibold text-gray-900 mb-2">We value your privacy</h2>
                        <p className="text-gray-600 mb-4">
                        We use cookies to enhance your browsing experience, serve personalized content, and analyze our traffic. 
                        By clicking "Accept All", you consent to our use of cookies.
                        </p>
                    </div>
                    </div>
                    
                    <div className="flex items-center space-x-4 mt-4">
                    <button
                        onClick={handleAcceptAll}
                        className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 transition-colors"
                    >
                        Accept All
                    </button>
                    <button
                        onClick={handleRejectAll}
                        className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 transition-colors"
                    >
                        Necessary Only
                    </button>
                    <button
                        onClick={onOpenSettings}
                        className="bg-gray-200 flex items-center px-6 py-2 rounded-lg text-gray-600  hover:text-gray-800 transition-colors"
                    >
                        Customize
                    </button>
                </div>
            </div>
        </div>
    );
};