import { toast } from "react-toastify";

import bookingService from "redux/booking/booking.service";
import {
    setAddBookingDetailLoading,
    setBooking_DetailData,
    setAddBookingDetailMessage,

    setInitializeBookingDetailLoading,
    setInitializeBookingDetailData,
    setInitializeBookingDetailMessage,

    setBookingDetailConfirmationLoading,
    setBookingDetailConfirmationData,
    setBookingDetailConfirmationMessage,

    setUserBookingListLoading,
    setUserBookingListData,
    setUserBookingListMessage,

    setUserBookingDetailLoading,
    setUserBookingDetailData,
    setUserBookingDetailMessage,

    setModifyUserBookingDetailLoading,
    setModifyUserBookingDetailMessage,

    setUserPreBookingDetailLoading,
    setUserPreBookingDetailData,
    setUserPreBookingDetailMessage,

    setTutorBookingListLoading,
    setTutorBookingListData,
    setTutorBookingListMessage,

    setTutorBookingDetailLoading,
    setTutorBookingDetailData,
    setTutorBookingDetailMessage,

    setAddBookingSessionDetailLoading,
    setAddBookingSessionDetailData,
    setAddBookingSessionDetailMessage,

    setCancelBookingSessionDetailLoading,
    setCancelBookingSessionDetailData,
    setCancelBookingSessionDetailMessage,

    // OLD
    setAddBookingLoading,
    setAddBooking,
    setAddBookingErrorMsg,
} from "redux/booking/booking.slice";

import { getMyProfile } from "redux/user/user.request";

import { timeZone } from "utils/dateTime.utils";

// Create Booking Detail Request
export const createBookingDetail = (body) => async (dispatch) => {
    dispatch(setAddBookingDetailLoading(true))

    try {
        const requestData = { body: body }
        const response = await bookingService.createBookingDetail(requestData)
        if (response.status === 201) {
            dispatch(setBooking_DetailData(response.data.data))
            toast.success(response.data.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setAddBookingDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
    } finally {
        dispatch(setAddBookingDetailLoading(false))
    }
}

// Initialize Booking Detail Request
export const initializeBooking = (bookingId, query) => async (dispatch) => {
    dispatch(setInitializeBookingDetailLoading(true))

    try {
        const requestData = {
            params: { bookingId: bookingId },
            query: query
        }
        const response = await bookingService.initializeBookingDetail(requestData)
        if (response.status === 200) {
            dispatch(setInitializeBookingDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setInitializeBookingDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
    } finally {
        dispatch(setInitializeBookingDetailLoading(false))
    }
}

// Confirm Booking Detail Request
export const confirmBooking = (bookingId) => async (dispatch) => {
    dispatch(setBookingDetailConfirmationLoading(true))

    try {
        const requestData = {
            params: { bookingId: bookingId }
        }
        const response = await bookingService.confirmBookingDetail(requestData)
        if (response.status === 200) {
            dispatch(setBookingDetailConfirmationData(response.data.data))
            dispatch(getMyProfile())
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setBookingDetailConfirmationMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
    } finally {
        dispatch(setBookingDetailConfirmationLoading(false))
    }
}

// Get Booking Detail Confirmation Request
export const getBookingDetailConfirmation = (bookingId) => async (dispatch) => {
    dispatch(setBookingDetailConfirmationLoading(true))

    try {
        const requestData = {
            params: { bookingId: bookingId }
        }
        const response = await bookingService.getBookingDetailConfirmation(requestData)
        if (response.status === 200) {
            dispatch(setBookingDetailConfirmationData(response.data.data))
            dispatch(getMyProfile())
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setBookingDetailConfirmationMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
    } finally {
        dispatch(setBookingDetailConfirmationLoading(false))
    }
}

// Get User Booking List Request
export const getUserBookingList = (userId, query) => async (dispatch) => {
    dispatch(setUserBookingListLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            query: query
        }
        const response = await bookingService.getUserBookingList(requestData)
        if (response.status === 200) {
            dispatch(setUserBookingListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setUserBookingListMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
    } finally {
        dispatch(setUserBookingListLoading(false))
    }
}

// Get User Booking Detail Request
export const getUserBookingDetail = (bookingId, userId, query) => async (dispatch) => {
    dispatch(setUserBookingDetailLoading(true))

    try {
        const requestData = {
            params: { bookingId: bookingId, userId: userId },
            query: query
        }
        const response = await bookingService.getUserBookingDetail(requestData)
        if (response.status === 200) {
            dispatch(setUserBookingDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setUserBookingDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
    } finally {
        dispatch(setUserBookingDetailLoading(false))
    }
}

// Update User Booking Detail Request
export const updateUserBookingDetail = (bookingId, body) => async (dispatch) => {
    dispatch(setModifyUserBookingDetailLoading(true))

    try {
        const requestData = {
            params: { bookingId: bookingId },
            body: body
        }
        const response = await bookingService.updateUserBookingDetail(requestData)
        if (response.status === 200) {
            dispatch(setUserBookingDetailData(response.data.data))
            toast.success(response.data.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || error)
        dispatch(setModifyUserBookingDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
    } finally {
        dispatch(setModifyUserBookingDetailLoading(false))
    }
}

// get user pre booking detail
export const getUserPreBookingDetail = (bookingId, userId, query) => async (dispatch) => {
    dispatch(setUserPreBookingDetailLoading(true))

    try {
        const requestData = {
            params: { bookingId: bookingId, userId: userId },
            query: query
        }
        const response = await bookingService.getUserPreBookingDetail(requestData)
        if (response.status === 200) {
            dispatch(setUserPreBookingDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setUserPreBookingDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
    } finally {
        dispatch(setUserPreBookingDetailLoading(false))
    }
}

// get tutor booking list request
export const getTutorBookingList = (userId, query) => async (dispatch) => {
    dispatch(setTutorBookingListLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            query: query
        }
        const response = await bookingService.getTutorBookingList(requestData)
        if (response.status === 200) {
            dispatch(setTutorBookingListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setTutorBookingListMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
    } finally {
        dispatch(setTutorBookingListLoading(false))
    }
}

// get tutor booking detail request
export const getTutorBookingDetail = (bookingId, userId, query) => async (dispatch) => {
    dispatch(setTutorBookingDetailLoading(true))

    try {
        const requestData = {
            params: { bookingId: bookingId, userId: userId },
            query: query
        }
        const response = await bookingService.getTutorBookingDetail(requestData)
        if (response.status === 200) {
            dispatch(setTutorBookingDetailData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setTutorBookingDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
    } finally {
        dispatch(setTutorBookingDetailLoading(false))
    }
}

// create booking session
export const createBookingSessionDetail = (bookingId, body) => async (dispatch, getState) => {
    dispatch(setAddBookingSessionDetailLoading(true))

    try {
        const { user } = getState().user
        const requestData = {
            params: { bookingId: bookingId },
            body: body
        }
        const response = await bookingService.createBookingSessionDetail(requestData)
        if (response.status === 200) {
            dispatch(setAddBookingSessionDetailData(response.data.data))
            dispatch(getUserBookingDetail(bookingId, user?.user?.userId, { timeZone: timeZone }))
            toast.success(response.data.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setAddBookingSessionDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
    } finally {
        dispatch(setAddBookingSessionDetailLoading(false))
    }
}

// cancel booking session
export const cancelSessionBookingDetail = (bookingId, body) => async (dispatch, getState) => {
    dispatch(setCancelBookingSessionDetailLoading(true))

    try {
        const { user } = getState().user
        const requestData = {
            params: { bookingId: bookingId },
            body: body
        }
        const response = await bookingService.cancelSessionBookingDetail(requestData)
        if (response.status === 200) {
            dispatch(setCancelBookingSessionDetailData(response.data.data))
            dispatch(getUserBookingDetail(bookingId, user?.user?.userId, { timeZone: timeZone }))
            toast.success(response.data.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setCancelBookingSessionDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
    } finally {
        dispatch(setCancelBookingSessionDetailLoading(false))
    }
}

// Old Requests
export const oldMakeAddBooking = () => async (dispatch, getState) => {
    dispatch(setAddBookingLoading(true))
    const { addBookingPayload } = getState()?.booking?.addBooking

    try {
        const requestData = {
            body: {
                courseId: addBookingPayload?.course?.courseId,
                coursePrice: addBookingPayload?.coursePrice,
                purchasedPrice: addBookingPayload?.purchasedPrice,
                promoCreditUsed: addBookingPayload?.promoCreditUsed,
                coursePctDiscount: addBookingPayload?.coursePctDiscount,
                volumePctDiscount: addBookingPayload?.volumePctDiscount,
                tutorPctDiscount: addBookingPayload?.tutorPctDiscount,
                offerPctDiscount: addBookingPayload?.offerPctDiscount,
                sessionDuration: addBookingPayload?.sessionDuration,
                noOfSessions: addBookingPayload?.noOfSessions,
                confirm: addBookingPayload?.confirm,
                startDate: addBookingPayload?.startDate,
                endDate: addBookingPayload?.endDate,
                weeklyTimeSlot: addBookingPayload?.weeklyTimeSlot
            }
        }
        const response = await bookingService.old_addBooking(requestData)
        if (response.status === 200) {
            dispatch(setAddBooking(response.data))
            dispatch(getMyProfile())
        } else {
            dispatch(setAddBookingErrorMsg(response.data.message))
        }
    } catch (error) {
        console.error(error.message)
        dispatch(setAddBookingErrorMsg(error.response.data.message))
    } finally {
        dispatch(setAddBookingLoading(false))
    }
}