import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";

import PageHeader from "components/pageHeader/PageHeader";
import Pagination from "components/pagination/Pagination";

import FilterSection from "./filters/FilterSection";
import LiveClassCardUpdated, { LiveClassCardUpdatedLoader } from "./components/liveClassCardUpdated";

import { pagesInfo } from 'utils/pagesInfo';

import { useTitle } from "hooks/useTitle";
import { useAppDispatcher, useAppState } from "hooks/useStore";

import courseServices from "redux/course/course.services";
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { getCourseListPayload, pageHeading, searchParamsInfo } from './data';

const RECORDS = 5

const coursesState = {
  isLoading: false,
  result: [],
  pagination: null,
  error: null
}
const updateCourseState = {
  isLoading: false
}
const deleteCourseState = {
  isLoading: false
}

const OfferingsPage = () => {
  const { currentPageInfo } = useAppState((s) => s.pageInfo)
  const { user } = useAppState((state) => state.user)

  const dispatcher = useAppDispatcher()
  const navigate = useNavigate()
  const location = useLocation()
  const [title, setTitle] = useTitle()

  const [courses, setCourses] = useState(coursesState)
  const [updateCourse, setUpdateCourse] = useState(updateCourseState)
  const [deleteCourse, setDeleteCourse] = useState(deleteCourseState)

  const searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.OFFERINGS))
  }, [dispatcher, currentPageInfo])

  useEffect(() => {
    setTitle({
      ...title,
      title: "All My Classes | Tutor at Edulyte"
    })
  }, [dispatcher, currentPageInfo])

  const getInstructorCourseList = useCallback(async () => {
    setCourses({ ...coursesState, isLoading: true })
    try {
      const queryParamPayload = await getCourseListPayload(searchQueryParams)
      const requestData = {
        params: { user_id: user?.user?.userId },
        query: queryParamPayload
      }
      const response = await courseServices.getInstructorCourses(requestData)
      if (response.status === 200) {
        setCourses(course => ({ ...course, result: response.data.data.result, pagination: response.data.data?.pagination }))
      } else {
        throw new Error(response.data.error || "Oops, Something went wrong!")
      }
    } catch (error) {
      setCourses(course => ({ ...course, error: error?.response?.data?.error || error.message || "Oops, Something went wrong!" }))
      console.error("error: ", error?.response?.data?.error || error.message || error)
    } finally {
      setCourses(course => ({ ...course, isLoading: false }))
    }
  }, [user?.user?.userId, searchQueryParams])

  const updateCourseDetail = useCallback(async (id, body) => {
    setUpdateCourse({ ...updateCourseState, isLoading: true })
    try {
      const requestData = {
        params: { id },
        body
      }
      const response = await courseServices.updateCourse(requestData)
      if (response.status === 200) {
        setCourses(course => ({ ...course, result: course.result.map(item => item.id === id ? response.data.data.result : item) }))
        toast.success(response.data.data.message)
      } else {
        throw new Error(response.data.error || "Oops, Something went wrong!")
      }
    } catch (error) {
      toast.error(error?.response?.data?.error || error.message || "Oops, Something went wrong!")
      console.error("error: ", error?.response?.data?.error || error.message || error)
    } finally {
      setUpdateCourse(updateCourse => ({ ...updateCourse, isLoading: false }))
    }
  }, [])

  const deleteCourseDetail = useCallback(async (id) => {
    setDeleteCourse({ ...deleteCourseState, isLoading: true })
    try {
      const requestData = {
        params: { id },
      }
      const response = await courseServices.deleteCourse(requestData)
      if (response.status === 204) {
        toast.success("Deleted Successfully. 😊")
        setCourses(course => ({ ...course, result: course.result.filter(item => item.id === id ? false : true) }))
        navigate(`${location.pathname}`, { replace: true })
      } else {
        throw new Error(response.data.error || "Oops, Something went wrong!")
      }
    } catch (error) {
      toast.error(error?.response?.data?.error || error.message || "Oops, Something went wrong!")
      console.error("error: ", error?.response?.data?.error || error.message || error)
    } finally {
      setDeleteCourse(deleteCourse => ({ ...deleteCourse, isLoading: false }))
    }
  }, [])

  useEffect(() => {
    getInstructorCourseList()
  }, [getInstructorCourseList, location.search])

  const onHandlePageChange = async (page) => {
    searchQueryParams.set(searchParamsInfo.page.key, page)
    navigate(`${location.pathname}?${searchQueryParams?.toString()}`);
  }

  return (
    <div className={"space-y-3"}>
      <PageHeader
        pageHeading={pageHeading}
        toolTipText={"Host engaging group sessions for your customers."}
      />
      <FilterSection getInstructorCourseList={getInstructorCourseList} />
      {courses?.isLoading && (
        new Array(RECORDS).fill("").map((_, index) => <LiveClassCardUpdatedLoader key={index} />
        ))}
      {(!courses?.isLoading && !!courses?.result) &&
        courses?.result?.map(course => (
          <LiveClassCardUpdated
            key={course?.id}
            course={course}
            updateCourseDetail={updateCourseDetail}
            deleteCourseDetail={deleteCourseDetail}
          />
        ))}
      {(courses?.pagination && (courses?.pagination?.totalPages > 1)) &&
        <div className={"flex justify-center items-center"}>
          <Pagination
            page={courses?.pagination?.page}
            totalPages={courses?.pagination?.totalPages}
            onChangePage={onHandlePageChange}
          />
        </div>
      }
      {(courses?.pagination?.records === 0) &&
        <div className={"w-full h-full flex items-center justify-center font-bodyPri font-semibold text-text-800 text-md"}>
          {"No records found!"}
        </div>
      }
    </div>
  );
};

export default OfferingsPage;
