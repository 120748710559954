import { useMemo, memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { MdVerified } from 'react-icons/md';
import { FiMessageSquare } from "react-icons/fi";
import { MdAccessTime } from "react-icons/md";

import ToolTipView from 'components/tooltipView';
import FadedCollapsible from 'components/fadedCollapse';

import { getFBChat, getFBChatId } from 'redux/firebase/firebase.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { setMessageTemplate } from 'redux/firebase/firebase.slice';
import { appointmentPriceModelEnum } from "redux/appointment/appointment.const";
import { blueTickEnum } from 'redux/user/user.const';
import { modalConst } from 'redux/local/local.const';

import { pagesInfo } from 'utils/pagesInfo';
import { getCustomHostPath } from 'utils/generators.utils';
import { EDULYTE_WEB_DOMAIN_URL } from 'const/default.const';
import useCustomNavigation from 'hooks/useCustomNavigation';

const AppointmentDetail = memo(({ appointment }) => {
    const { publicOrgDetail } = useAppState((state) => state.org)
    const { user } = useAppState((s) => s.user)
    const { session } = useAppState(s => s.auth)
    const { modal } = useAppState(s => s.local)
    const { messageTemplate } = useAppState((s) => s.firebase)

    const navigate = useNavigate()
    const dispatcher = useAppDispatcher()
    const customNavigation = useCustomNavigation()

    const descriptionText = useMemo(() => new DOMParser()?.parseFromString(`<div>${appointment?.description || ""}</div>`, "text/html")?.querySelector("div")?.textContent, [appointment?.description])

    const isMyOffering = useMemo(() => user?.user?.userId === appointment?.user?.id, [appointment, user?.user])

    const onHandleNavigateToTutorDetail = useCallback(() => {
        // window.open(`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `${pagesInfo.TUTOR.pagePath}/${appointment?.user?.username}` })}`, "_blank")
        window.open(`${customNavigation.getCustomBaseUrl({ path: `${pagesInfo.TUTOR.pagePath}/${appointment?.user?.username}`, isWeb: true })}`, "_blank")
    }, [appointment])

    const handleMessage = async () => {
        if (!session?.isLoggedIn) {
            // window.open(`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, pathname: `/log-in?redirect_url=${window.location.href}` })}`, "_self")
            customNavigation.navigation({ path: `/log-in?redirect_url=${window.location.href}` })
            return;
        }

        if (isMyOffering) {
            alert("Can't send self message!")
            return;
        };

        const userIdOne = user?.user?.userId
        const userIdTwo = appointment?.user?.id

        const chatId = await getFBChatId(userIdOne, userIdTwo)
        let fbChat = await getFBChat(chatId)

        if (!fbChat || fbChat === undefined) {
            dispatcher(setMessageTemplate({
                ...messageTemplate,
                isLoading: false,
                userIdOne: userIdOne,
                userIdTwo: userIdTwo,
                tutorUserId: appointment?.user?.id,
                message: '',
                errorMsg: null
            }))
            dispatcher(setModal({
                ...modal,
                [modalConst.MESSAGE_TEMPLATE_MODAL.stateKey]: true
            }))
            return;
        }

        navigate(`${pagesInfo.MESSAGES.pagePath}/${fbChat.chatId}`)
    }

    return (
        <div className={"space-y-8"}>

            <div className={"space-y-1"}>
                <h1 className={"font-bold text-3xl text-text-900"}>
                    {appointment?.title}
                </h1>
                {!!appointment?.subtitle && (
                    <p className={"font-medium text-md text-text-600"}>
                        {appointment?.subtitle}
                    </p>
                )}
            </div>

            <div className="w-full bg-back-ground-darkLight rounded-lg px-3 py-2 flex items-center justify-between">
                <div className={"flex items-center justify-start gap-3 group"}>
                    <div className='w-12 h-12 rounded-full'>
                        <div className={"w-full h-full overflow-hidden cursor-pointer"} onClick={onHandleNavigateToTutorDetail}>
                            <img src={appointment?.user?.profile_pic_url} alt={"tutor-public-profile"} className="w-full h-full object-cover rounded-full" />
                        </div>
                        <div className={'relative has-tooltip'}>
                            <div className={"absolute bottom-0.5 right-0.5 w-4 h-4 rounded-full border-2 border-white overflow-hidden"}>
                                <ToolTipView content={appointment?.user?.from_country?.country}>
                                    <img
                                        src={`https://flagcdn.com/16x12/${appointment?.user?.from_country?.country_domain}.png`.toLowerCase()}
                                        alt={"country-flag"}
                                        className='w-full h-full object-cover overflow-hidden'
                                    />
                                </ToolTipView>
                            </div>
                        </div>
                    </div>
                    <div className='flex items-center gap-2'>
                        <div className={"cursor-pointer"} onClick={onHandleNavigateToTutorDetail}>
                            <span className="font-bodyPri font-medium text-lg text-text-900 group-hover:text-secondary-main group-hover:underline capitalize">
                                {appointment?.user?.first_name + " " + appointment?.user?.last_name?.charAt(0) + "."}
                            </span>
                        </div>
                        {(appointment?.user?.blue_tick === blueTickEnum?.YES?.value) && (
                            <ToolTipView content={"Verified Account"}>
                                <span><MdVerified className={"text-lg text-primary-dark cursor-pointer"} /></span>
                            </ToolTipView>
                        )}
                        {(appointment?.user?.online_status === "online") && <span className={"w-2 h-2 rounded-full bg-green-600"}></span>}
                    </div>
                </div>
                <div>
                    <ToolTipView content={"Message"}>
                        <span><FiMessageSquare className={"text-3xl text-text-700 hover:text-primary-dark cursor-pointer"} onClick={handleMessage} /></span>
                    </ToolTipView>
                </div>
            </div>

            <div className={"space-y-1"}>
                {(appointment?.price_model === appointmentPriceModelEnum.FLAT_FEE.value) && (
                    <div className='space-y-1'>
                        <span className='font-bodyPri font-semibold text-2xl text-text-900'>
                            {!isNaN(appointment?.price) ? appointment?.master_currency?.code + " " + parseFloat((appointment?.price - ((appointment?.price * appointment?.discount_pct) / 100)) / 100).toFixed(2) : `${appointment?.master_currency?.code} 0.00`}
                        </span>
                        {!isNaN(appointment?.price) && ((appointment?.discount_pct > 0) && (appointment?.discount_pct < 100)) && (
                            <span className='flex flex-row items-start justify-start gap-2'>
                                <del className='font-bodyPri font-medium text-base text-text-700'>
                                    {appointment?.master_currency?.code + " " + parseFloat(appointment?.price / 100).toFixed(2)}
                                </del>
                                <span className={"font-bodyPri font-medium text-base text-green-500"}>
                                    {appointment?.discount_pct + "% off"}
                                </span>
                            </span>
                        )}
                    </div>
                )}
                {(appointment?.price_model === appointmentPriceModelEnum.FREE.value) && (
                    <div className='space-y-1'>
                        <span className='font-bodyPri font-semibold text-2xl text-text-900'>
                            {"FREE"}
                        </span>
                    </div>
                )}
            </div>

            <div className={"space-y-2"}>
                <div className={"flex items-center justify-start gap-2"}>
                    <MdAccessTime className={"text-xl text-text-700"} />
                    <span className={"font-bodyPri font-medium text-md text-text-800"}>
                        {(appointment?.duration >= 60)
                            ? `${parseFloat(appointment?.duration / 60).toFixed(2)} hr`
                            : `${appointment?.duration} min`
                        }
                    </span>
                </div>
            </div>

            {!!appointment?.description && (
                <div className="space-y-2">
                    <h2 className="font-bodyPri font-medium text-lg text-text-800">
                        {"Description"}
                    </h2>
                    <FadedCollapsible
                        maxHeight={"3.5rem"}
                        isShowMore={descriptionText?.length > 400}
                    >
                        <div dangerouslySetInnerHTML={{ __html: appointment?.description }}></div>
                    </FadedCollapsible>
                </div>
            )}
        </div>
    )
})

export default memo(AppointmentDetail);