import { Link, useLocation } from "react-router-dom";

const TopTabNavigation = ({ tabs }) => {
    const location = useLocation()

    const activeTabButtonStyle = {
        color: '#0741ad',
        borderColor: '#0741ad',
        backgroundColor: '#e3f2fd'
    };

    return (
        <>
            {tabs.map((tab, index) => (
                <Link
                    key={index}
                    to={`${tab?.key}`}
                    style={location?.pathname?.includes(tab?.key) ? activeTabButtonStyle : {}}
                    className='border-b px-5 py-2 w-full whitespace-nowrap font-bodyComp border-divider-darkLight bg-back-ground-light duration-200 ease-in-out text-back-ground-black hover:bg-back-ground-lightBlue'
                >
                    {tab.label}
                </Link>
            ))}
        </>
    )
}

export default TopTabNavigation;