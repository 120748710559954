import { memo, useMemo } from "react";

import { sessionNoteConst } from 'components/modals/sessionActionModal/SessionActionModal.data';

import { OptionSelector } from 'components/common-components/Select';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setAddSessionUserDetailPayload } from 'redux/session/session.slice';
import { addSessionUserDetailPayload as addSessionUserDetailPayloadConst } from 'redux/session/session.const';
import { sessionTypeEnum, sessionStatusEnum, appointmentSessionStatusActions, courseSessionStatusActions, interviewSessionStatusActions } from 'redux/session/session.const';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';

import { cn } from "utils/cn.utils";

const minCharacterLength = 20
const maxCharacterLength = 100

const SessionActionInput = memo(({ sessionUserInfo, selectedAction, setSelectedAction }) => {
    const { modal } = useAppState((state) => state.local)
    const {
        sessionUserDetail,
        startSessionUserDetail,
        acceptSessionUserDetail,
        declineSessionUserDetail,
        rescheduleSessionUserDetail,
        addSessionUserDetailPayload
    } = useAppState((state) => state.session)

    const dispatcher = useAppDispatcher()

    const errorMessageElement = useMemo(() => (
        startSessionUserDetail?.message || acceptSessionUserDetail?.message || declineSessionUserDetail?.message || rescheduleSessionUserDetail?.message
    ), [startSessionUserDetail?.message, acceptSessionUserDetail?.message, declineSessionUserDetail?.message, rescheduleSessionUserDetail?.message])

    const sessionStatusActionsObject = useMemo(() => {
        let statusActionsObject = {}
        if (sessionUserDetail?.data?.result) {
            if (sessionUserDetail?.data?.result?.type === sessionTypeEnum.COHORT.value) {
                statusActionsObject = courseSessionStatusActions[sessionUserInfo?.user_type?.toUpperCase()][sessionUserInfo?.status?.toUpperCase()]
            }
            if (sessionUserDetail?.data?.result?.type === sessionTypeEnum.APPOINTMENT.value) {
                statusActionsObject = appointmentSessionStatusActions[sessionUserInfo?.user_type?.toUpperCase()][sessionUserInfo?.status?.toUpperCase()]
            }
            if (sessionUserDetail?.data?.result?.type === sessionTypeEnum.INTERVIEW.value) {
                statusActionsObject = interviewSessionStatusActions[sessionUserInfo?.user_type?.toUpperCase()][sessionUserInfo?.status?.toUpperCase()]
            }
        }
        return statusActionsObject;
    }, [sessionUserDetail?.data?.result])

    const onHandleSelectStatus = (option) => {

        if (option?.value === sessionStatusEnum?.RESCHEDULED?.value) {
            if (sessionUserDetail?.data?.result?.type === sessionTypeEnum.INTERVIEW.value) {
                alert("Coming soon!")
                return;
            }

            let payload = {
                ...addSessionUserDetailPayloadConst,
                sessionId: sessionUserDetail?.data?.result?.id,
                duration: sessionUserDetail?.data?.result?.duration,
                start_datetime: sessionUserDetail?.data?.result?.start_datetime,
                end_datetime: sessionUserDetail?.data?.result?.end_datetime,
                note: ""
            }
            if (sessionUserDetail?.data?.result?.type === sessionTypeEnum.COHORT.value) {
                // payload = {
                //     ...payload,
                //     title: sessionUserDetail?.data?.result?.session_course?.course?.title,
                //     availabilityId: sessionUserDetail?.data?.result?.session_course?.course?.courseAvailability?.availabilityId,
                // }
                return;
            }
            if (sessionUserDetail?.data?.result?.type === sessionTypeEnum.APPOINTMENT.value) {
                payload = {
                    ...payload,
                    title: sessionUserDetail?.data?.result?.appointment?.title,
                    availabilityId: sessionUserDetail?.data?.result?.appointment?.appointment_availability?.availability?.id,
                }
            }
            dispatcher(setAddSessionUserDetailPayload(payload))
            dispatcher(setModal({
                ...modal,
                [modalConst.SESSION_ACTION_MODAL.stateKey]: false,
                [modalConst.RESCHEDULE_TIME_SLOT_MODAL.stateKey]: true
            }))
        } else {
            setSelectedAction(option)
        }
    }

    const handleNote = (event) => {
        event.preventDefault()

        dispatcher(setAddSessionUserDetailPayload({
            ...addSessionUserDetailPayload?.payload,
            note: event.target.value
        }))
    }

    return sessionUserDetail?.data?.result && selectedAction ? (
        <div className={"space-y-3"}>
            {/* Option Selector */}
            <div className={"flex items-center gap-2"}>
                <span className={"font-bodyPri font-medium text-base text-text-800 whitespace-nowrap"}>
                    {"Change status:"}
                </span>
                <div className={"w-32 md:w-full"}>
                    <OptionSelector
                        onChange={(option) => onHandleSelectStatus(option)}
                        options={Object.values(sessionStatusActionsObject)?.map((item) => (item?.value === sessionStatusEnum.ON_GOING.value) ? { ...item, label: "Start" } : item)}
                        value={selectedAction?.value}
                    />
                </div>
            </div>

            {/* Note */}
            <div className={"flex flex-col items-start gap-2"}>
                <div className={"w-full flex items-center justify-between gap-2 whitespace-nowrap"}>
                    <div className={"flex items-center justify-start gap-2"}>
                        <span className={"font-bodyPri font-medium text-base text-text-800"}>
                            {"Write a note:"}
                        </span>
                        <span className={"font-bodyPri font-normal text-xs text-text-800"}>
                            {sessionNoteConst[selectedAction?.value?.toUpperCase()]?.isNoteRequired ? "(Required)" : "(Optional)"}
                        </span>
                    </div>
                </div>
                <div className={"w-full flex flex-col items-start gap-3 px-3"}>
                    <textarea
                        className={cn(
                            "w-full px-5 py-3 bg-back-ground-light rounded-lg resize-none",
                            "outline-none border-2 border-primary-main focus:shadow-all-md focus:shadow-primary-light focus:border-primary-main",
                            "font-bodyPri font-normal text-base text-text-800 tracking-wide"
                        )}
                        minLength={minCharacterLength}
                        maxLength={maxCharacterLength}
                        rows={4}
                        name={"todo"}
                        placeholder="Write your note ...."
                        value={addSessionUserDetailPayload?.payload?.note}
                        onChange={handleNote}
                    />
                    <div className={"w-full flex justify-between"}>
                        <span className={cn(
                            "font-bodyPri font-normal text-xs text-text-800",
                            sessionNoteConst[selectedAction?.value?.toUpperCase()]?.isNoteRequired
                            && (addSessionUserDetailPayload?.payload?.note?.length < minCharacterLength
                                || addSessionUserDetailPayload?.payload?.note?.length > maxCharacterLength)
                            && "text-red-500"
                        )}>
                            {`Please enter between ${minCharacterLength} and ${maxCharacterLength} characters`}
                        </span>
                        <span className="flex justify-end font-bodyPri font-normal text-text-800 text-xs">
                            {addSessionUserDetailPayload?.payload?.note?.length + "/" + maxCharacterLength}
                        </span>
                    </div>

                    {errorMessageElement &&
                        <span className={"font-bodyPri font-normal text-base text-red-500"}>
                            {errorMessageElement}
                        </span>
                    }
                </div>
            </div>
        </div>
    ) : null
})

export default SessionActionInput