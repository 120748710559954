import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";

import { FaCheckCircle } from "react-icons/fa";
import { MdIosShare } from "react-icons/md";

import ToolTipView from "components/tooltipView";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setClearShareSocial, setModal, setShareSocial } from "redux/local/local.slice";
import { modalConst, socialAction } from "redux/local/local.const";

import { cn } from 'utils/cn.utils'
import { pagesInfo } from "utils/pagesInfo";
import { getCustomHostPath } from "utils/generators.utils";

import { EDULYTE_WEB_DOMAIN_URL } from "const/default.const";
import useCustomNavigation from "hooks/useCustomNavigation";

const StudentQuizCard = ({ studentQuiz, className }) => {
  const publicOrgDetail = useAppState((state) => state.org.publicOrgDetail)
  const { modal, shareSocial } = useAppState((state) => state.local)

  const { quiz, quiz_student } = studentQuiz

  const dispatcher = useAppDispatcher()
  const navigate = useNavigate()
  const customNavigation = useCustomNavigation()

  const getTutor = () => {
    let displayName = quiz?.quiz_setting?.default_owner?.tutor?.user?.first_name

    return { displayName }
  }

  const getTags = () => {
    return quiz?.tags?.map(tag => tag?.master_tag?.tag)
  }

  const tutor = getTutor()
  const tags = getTags()

  const onHandleShareQuiz = useCallback(() => {
    // const LMS_QUIZ_URL = `${EDULYTE_WEB_DOMAIN_URL}/quiz/${quiz?.active_slug}`
    // const LMS_QUIZ_URL = `${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/quiz/${quiz?.active_slug}` })}`
    const LMS_QUIZ_URL = `${customNavigation.getCustomBaseUrl({ path: `/quiz/${quiz?.active_slug}`, isWeb: true })}`
    dispatcher(setClearShareSocial())
    dispatcher(setShareSocial({
      ...shareSocial,
      socialAction: socialAction?.SHARE_LMS_QUIZ?.value,
      url: LMS_QUIZ_URL
    }))
    dispatcher(setModal({
      ...modal,
      [modalConst.SHARE_SOCIAL_MODAL.stateKey]: true
    }))
  }, [modal, quiz])

  const onHandleFilterQuizzes = useCallback((tag) => {
    // window.open(`${EDULYTE_WEB_DOMAIN_URL}/quizzes?tag=${tag?.replaceAll(" ", "-")}`, "_self")
    // window.open(`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/quizzes?tag=${tag?.replaceAll(" ", "-")}` })}`, "_self")
    customNavigation.navigation({ path: `/quizzes?tag=${tag?.replaceAll(" ", "-")}`, isWeb: true })
  }, [quiz])

  const handleGotoQuiz = () => {
    navigate(`${pagesInfo.STUDENT_LMS_QUIZ.pagePath}/${quiz?.active_slug}/learn`)
  }

  return (
    <div className={cn(
      "group w-full h-full flex flex-col rounded-md overflow-hidden border border-divider-darkLight p-3 gap-3",
      quiz_student?.is_completed && "border-green-500 shadow shadow-green-500",
      className
    )}>

      <div className={"relative"}>
        <Link
          to={`${pagesInfo.STUDENT_LMS_QUIZ.pagePath}/${quiz?.active_slug}/learn`}
          className={"h-36 w-full overflow-hidden rounded-md"}
        >
          <img
            src={quiz?.thumbnail_pic_url}
            alt={quiz?.title || "thumbnail"}
            className={cn(
              "h-36 w-full aspect-video object-cover rounded-md transition-all transform scale-105 group-hover:scale-100 ease-in-out duration-200"
            )}
          />
        </Link>
        <div className={'absolute right-1 top-1.5 px-2 py-1 bg-white rounded-full hover:bg-divider-darkLight shadow-md'}>
          <ToolTipView content={"Share"}>
            <button
              className={"text-text-700 cursor-pointer"}
              onClick={onHandleShareQuiz}
            >
              <MdIosShare className={"text-lg"} />
            </button>
          </ToolTipView>
        </div>
      </div>

      <div className="w-full grow flex flex-col justify-between gap-3">

        <div className={"space-y-1.5"}>
          <Link
            to={`${pagesInfo.STUDENT_LMS_QUIZ.pagePath}/${quiz?.active_slug}/learn`}
            className={"font-bodyPri font-medium text-base text-text-900 hover:text-secondary-dark hover:underline line-clamp-1"}
          >
            {quiz?.title}
          </Link>
          <p className={"font-bodyPri font-normal text-sm text-text-900 whitespace-nowrap"}>
            {`By ${tutor?.displayName}`}
          </p>
          <p className={"font-bodyPri font-normal text-sm text-text-900 line-clamp-1"}>
            {tags?.map((tag, index) => (
              <>
                {!!index && <span>{", "}</span>}
                <span
                  key={index}
                  className={"text-primary-dark hover:text-secondary-dark hover:underline cursor-pointer"}
                  onClick={() => onHandleFilterQuizzes(tag)}
                >
                  {tag}
                </span>
              </>
            ))}
          </p>
        </div>

        <div className={"w-full space-y-2"}>
          {quiz_student?.is_completed && (
            <div className={"flex items-center justify-start gap-2"}>
              <ToolTipView content="Completed">
                <span>
                  <FaCheckCircle className={"text-green-500 text-xl cursor-pointer"} />
                </span>
              </ToolTipView>
              <span className={cn("font-bodyPri font-medium text-md text-text-900")}>
                {!!quiz_student?.total_marks
                  ? `Your Score ${parseFloat((100 * (quiz_student?.obtain_marks / quiz_student?.total_marks))?.toFixed(2))}% (${quiz_student?.obtain_marks}/${quiz_student?.total_marks})`
                  : "Completed"
                }
              </span>
            </div>
          )}
          <button className={cn("w-full px-2 py-1 rounded bg-primary-dark hover:opacity-90 font-buttons font-normal text-base text-text-50 text-center")}
            onClick={handleGotoQuiz}
          >
            {quiz_student?.is_completed ? "Go to Quiz" : quiz_student?.is_started ? "Resume Quiz" : "Start Quiz"}
          </button>
        </div>

      </div>

    </div>
  )
}

export default StudentQuizCard


export const Loader = ({ className }) => (
  <div className={cn("w-full flex flex-col rounded-md border border-divider-darkLight p-2 gap-3 bg-slate-300", className)}>

    <div className={"h-36 w-full rounded-md animate-pulse bg-slate-200"} />
    <div className="w-full flex flex-col gap-2">
      <div className={"h-3 rounded-md w-4/5 animate-pulse bg-slate-200"} />
      <div className="flex justify-between flex-col sm:flex-row gap-5">
        <div className={"space-y-1"}>
          <p className={"h-3 rounded-md w-1/2 animate-pulse bg-slate-200"} />
          <p className={"h-3 rounded-md w-3/4 animate-pulse bg-slate-200"} />
        </div>
      </div>
      <div className={"flex items-center justify-between gap-1"}>
        <p className={"h-3 rounded-md w-1/3 animate-pulse bg-slate-200"} />
        <div className={"h-3 rounded-md w-1/3 animate-pulse bg-slate-200"} />
      </div>
    </div>

  </div>
)