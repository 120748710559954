import { useMemo, useState, useCallback } from 'react';

import { BiLinkExternal } from 'react-icons/bi';

import ToolTipView from 'components/tooltipView';

import AppointmentDetail from "./components/appointmentDetail";
import AppointmentCalendar from "./components/appointmentCalendar";

import { useAppState } from "hooks/useStore";
import { appointmentStatusEnum } from 'redux/appointment/appointment.const';

import { cn } from 'utils/cn.utils';
import { getCustomHostPath } from 'utils/generators.utils';
import { pagesInfo } from 'utils/pagesInfo';

import { EDULYTE_WEB_DOMAIN_URL } from 'const/default.const';
import useCustomNavigation from 'hooks/useCustomNavigation';

const AppointmentCalendarInfo = () => {
    const { publicOrgDetail } = useAppState((state) => state.org)
    const { modifyAppointmentDetail } = useAppState((state) => state.appointment)

    const customNavigation = useCustomNavigation()

    const appointment = useMemo(() => modifyAppointmentDetail?.payload, [modifyAppointmentDetail?.payload])
    const isAppointmentPublished = useMemo(() => [appointmentStatusEnum.PUBLISHED.value]?.includes(modifyAppointmentDetail?.payload?.status), [modifyAppointmentDetail?.payload?.status])

    const [selectedDate, setSelectedDate] = useState(null);

    const onHandleNavigateToPublicPage = useCallback(() => {
        if (!isAppointmentPublished) return;

        // window.open(`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `${pagesInfo.APPOINTMENT.pagePath}/${appointment?.slug}` })}`, "_blank")
        window.open(`${customNavigation.getCustomBaseUrl({ path: `${pagesInfo.APPOINTMENT.pagePath}/${appointment?.slug}`, isWeb: true })}`, "_blank")
    }, [appointment?.slug, isAppointmentPublished])

    return (
        <div className={cn(
            "w-full max-w-full md:max-w-[350px] lg:max-w-[640px] mx-auto bg-white rounded-lg shadow-[0_0_5px_1px_rgba(0,0,0,0.2)] h-full md:h-[80vh] xl:h-[80vh] overflow-hidden",
            "transition-all ease-in-out duration-300",
            selectedDate ? "xl:max-w-[1060px] xl:min-w-[800px]" : "xl:max-w-[800px]"
        )}>
            <div className={cn(
                "w-full py-1 px-2 flex flex-col gap-1.5 sm:flex-row sm:items-center sm:justify-between sm:gap-3",
                `font-medium text-text-50 bg-${appointmentStatusEnum[appointment?.status?.toUpperCase()]?.darkColor}`
            )}>
                <span className={"w-full sm:w-fit text-xs sm:text-sm md:text-base line-clamp-1"}>
                    {"This is a preview. To book an appointment, share the link with your invitees."}
                </span>
                <ToolTipView
                    content={!isAppointmentPublished && "Publish for live link"}
                    disabled={isAppointmentPublished}
                >
                    <button
                        className={cn(
                            "w-full sm:w-fit flex items-center justify-end gap-2 group",
                            !isAppointmentPublished && "!text-text-400 cursor-not-allowed"
                        )}
                        onClick={onHandleNavigateToPublicPage}
                    >
                        <BiLinkExternal className={"text-base sm:text-md md:text-lg"} />
                        <span className={cn("text-xs sm:text-sm md:text-base", isAppointmentPublished && "group-hover:underline")}>
                            {"View live page"}
                        </span>
                    </button>
                </ToolTipView>
            </div>
            <div className={"w-full flex flex-col xl:flex-row divide-x h-full"}>
                <div className={cn("p-8 overflow-y-auto scrollbar-thin w-full xl:w-1/2 transition-all ease-in-out duration-300", selectedDate && "xl:w-2/5")}>
                    <AppointmentDetail appointment={appointment} />
                </div>
                <div className={cn("p-8 pt-0 xl:pt-8 xl:pr-0 w-full xl:w-1/2 transition-all ease-in-out duration-300", selectedDate && "xl:w-3/5")}>
                    <AppointmentCalendar appointment={appointment} selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
                </div>
            </div>
        </div>
    )
}

export default AppointmentCalendarInfo;