import { useState } from 'react';

import { toast } from 'react-toastify';
import { motion, AnimatePresence } from 'framer-motion';

import { FaSpinner } from 'react-icons/fa';
import { FiChevronsDown } from 'react-icons/fi';
import { RiDeleteBin6Line } from 'react-icons/ri';

import ToolTipView from 'components/tooltipView';

import { sectionInitialState } from 'pages/auth/editClass/data';
import Drag from "pages/auth/editClass/_assets/drag.svg";
import SectionInput from 'pages/auth/editClass/_components/sectionInput';

import courseServices from 'redux/course/course.services';

import { cn } from 'utils/cn.utils';
import SectionSessions from './sessions';

const sectionItemInitialState = {
    modifySection: {
        isLoading: false,
        payload: {
            status: ""
        },
        error: null
    },
    destroySection: {
        isLoading: false,
        error: null
    }
}

const CohortSection = ({ cohortSection, setCohortSectionList, sectionPayload, setSectionPayload }) => {

    const [sectionItemPayload, setSectionItemPayload] = useState(sectionItemInitialState)

    const onHandleSaveSectionTitle = async (editableSectionTitle) => {
        if (editableSectionTitle === cohortSection?.title) return;

        setSectionItemPayload(() => ({ ...sectionItemInitialState, modifySection: { ...sectionItemInitialState?.modifySection, payload: { ...sectionItemInitialState?.modifySection?.payload, isLoading: true, status: "Saving..." } } }))

        try {
            const requestData = {
                params: { cohortId: cohortSection?.cohort_id, sectionId: cohortSection?.id },
                body: { title: editableSectionTitle }
            }
            const response = await courseServices.updateCohortSection(requestData)
            if (response.status === 200) {
                setCohortSectionList((prevState) => ({
                    ...prevState, result: prevState?.result?.map((sectionItem) => (
                        (sectionItem?.id === response?.data?.data?.result?.id)
                            ? response?.data?.data?.result
                            : sectionItem
                    ))
                }))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
            toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        } finally {
            setSectionItemPayload(() => ({ ...sectionItemInitialState, modifySection: { ...sectionItemInitialState?.modifySection, payload: { ...sectionItemInitialState?.modifySection?.payload, isLoading: false, status: "" } } }))
        }
    }

    const onHandleDeleteSection = async () => {
        setSectionItemPayload(() => ({ ...sectionItemInitialState, destroySection: { ...sectionItemInitialState?.destroySection, isLoading: true } }))

        try {
            const requestData = {
                params: { cohortId: cohortSection?.cohort_id, sectionId: cohortSection?.id }
            }
            const response = await courseServices.deleteCohortSection(requestData)
            if (response.status === 200) {
                setSectionItemPayload(sectionItemInitialState)
                setCohortSectionList((prevState => ({ ...prevState, result: prevState?.result?.filter((sectionItem) => sectionItem?.id !== cohortSection?.id) })))
                toast.success(response?.data?.data?.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
            toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
        } finally {
            setSectionItemPayload(() => ({ ...sectionItemInitialState, destroySection: { ...sectionItemInitialState?.destroySection, isLoading: false } }))
        }
    }

    const onHandleToggleSection = () => {
        if (sectionPayload?.payload?.id === cohortSection?.id) {
            setSectionPayload(() => ({ ...sectionInitialState, payload: { ...sectionInitialState?.payload, id: 0 } }))
        } else {
            setSectionPayload(() => ({ ...sectionInitialState, payload: { ...sectionInitialState?.payload, id: cohortSection?.id } }))
        }
    }

    return (
        <div className={cn(
            'w-full border rounded-lg',
            (sectionPayload?.payload?.id === cohortSection?.id)
                ? 'border-2 border-primary-main'
                : 'border-back-ground-darkLight'
        )}>
            <div
                className={cn(
                    'w-full flex flex-col px-4 py-2 md:flex-row md:items-center md:justify-between rounded-t-lg',
                    'transition-transform ease-in-out delay-200 duration-700 drop-shadow-sm',
                )}
            >
                <div className={cn('flex flex-1 gap-1 justify-start items-center cursor-grab')}>
                    <img
                        src={Drag}
                        className={"w-2.5 cursor-grab"}
                    />
                    <SectionInput
                        isLoading={sectionItemPayload?.modifySection?.isLoading}
                        isShowInputLabel={false}
                        inputClassName={"font-bodyPri font-medium text-black text-lg hover:!bg-text-100 focus:!border-b focus:!border-primary-dark"}
                        title={cohortSection?.title}
                        titleInputStatus={sectionItemPayload?.modifySection?.payload?.title}
                        // setTitleInputStatus={() => setSectionItemPayload((prevState) => ({ ...prevState, modifySection: { ...prevState?.modifySection, payload: { ...prevState?.modifySection?.payload, status: prevState?.modifySection?.payload?.status } } }))}
                        setTitleInputStatus={() => setSectionItemPayload(sectionItemPayload?.modifySection?.payload?.status)}
                        onHandleSaveTitle={onHandleSaveSectionTitle}
                    />
                </div>
                <div className={"w-full md:w-fit flex justify-end md:justify-start items-center gap-2"}>
                    {/* <LmsIconButtons
                        iconButtonList={Object.values(courseSectionIconList)}
                        onHandleIconButton={onHandleSelectOption}
                    /> */}
                    <ToolTipView content={"Delete Section"} disabled={sectionItemPayload?.destroySection?.isLoading}>
                        <button
                            className={"p-2 rounded-full hover:bg-divider-darkLight shadow-sm"}
                            onClick={onHandleDeleteSection}
                        >
                            {sectionItemPayload?.destroySection?.isLoading && <FaSpinner className={"animate-spin text-lg text-primary"} />}
                            {!sectionItemPayload?.destroySection?.isLoading && <RiDeleteBin6Line className={"text-xl text-text-700 hover:text-red-500 cursor-pointer"} />}
                        </button>
                    </ToolTipView>

                    <button
                        className={cn(
                            'text-black cursor-pointer ease-in-out duration-300 delay-200',
                            "p-2 rounded-full shadow-sm hover:bg-divider-darkLight",
                            (sectionPayload?.payload?.id === cohortSection?.id) ? 'rotate-180 ease-in-out duration-300' : ''
                        )}
                        onClick={() => onHandleToggleSection(cohortSection?.id)}
                    >
                        <FiChevronsDown size={22} />
                    </button>
                </div>
            </div>

            <AnimatePresence initial={sectionPayload?.payload?.id}>
                {(sectionPayload?.payload?.id === cohortSection?.id) &&
                    <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className="overflow-hidden"
                    >
                        <div className={"pl-0 md:pl-4"}>
                            <SectionSessions cohortSection={cohortSection} setCohortSectionList={setCohortSectionList} />
                        </div>
                    </motion.div>
                }
            </AnimatePresence>
        </div>
    )
}

export default CohortSection;