import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { FaSpinner } from "react-icons/fa";

import PageLoader from "components/loader/PageLoader";

import { setPageInfo } from "redux/pageInfo/pageInfo.request";
import { getAcceptAndDeclineEventlyInvitationDetail, getValidateEventlyInvitationDetail } from "redux/evently/evently.request";

import { useTitle } from "hooks/useTitle";
import { useAppDispatcher, useAppState } from "hooks/useStore";
import { eventlyInviteStatusEnum } from "redux/evently/evently.const";

import { cn } from "utils/cn.utils";
import { pagesInfo } from "utils/pagesInfo";
import { getCustomHostPath } from "utils/generators.utils";

import { EDULYTE_WEB_DOMAIN_URL } from "const/default.const";
import useCustomNavigation from "hooks/useCustomNavigation";

const EventlyInvitationPage = () => {
    const publicOrgDetail = useAppState((state) => state.org.publicOrgDetail)
    const { currentPageInfo } = useAppState(state => state.pageInfo)
    const { validateEventlyInvitationDetail, acceptAndDeclineEventlyInvitationDetail } = useAppState((state) => state.evently)

    const dispatcher = useAppDispatcher();
    const navigate = useNavigate();
    const location = useLocation();
    const [title, setTitle] = useTitle();
    const customNavigation = useCustomNavigation()

    const searchQueryParams = useMemo(() => new URLSearchParams(location.search), [location.search])
    const isEnableAccept = useMemo(() => !acceptAndDeclineEventlyInvitationDetail?.isLoading, [acceptAndDeclineEventlyInvitationDetail?.isLoading])
    const isAcceptOrDeclined = useMemo(() => [eventlyInviteStatusEnum.ACCEPTED?.value, eventlyInviteStatusEnum.DECLINED?.value].includes(validateEventlyInvitationDetail?.data?.status), [validateEventlyInvitationDetail?.data?.status])

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.EVENTLY_INVITATION))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        setTitle({
            ...title,
            title: "Edulyte Evently Invitation | Edulyte"
        })
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        dispatcher(getValidateEventlyInvitationDetail({ token: searchQueryParams.get('token') }));
    }, [searchQueryParams.get('token')])

    const handleAccept = async () => {
        const requestBody = {
            status: eventlyInviteStatusEnum?.ACCEPTED?.value
        }
        dispatcher(getAcceptAndDeclineEventlyInvitationDetail(requestBody, { token: searchQueryParams.get('token') }));
    }

    const handleDecline = async () => {
        const requestBody = {
            status: eventlyInviteStatusEnum?.DECLINED?.value
        }
        dispatcher(getAcceptAndDeclineEventlyInvitationDetail(requestBody, { token: searchQueryParams.get('token') }));
    }

    const handleGoToEvently = () => {
        if (eventlyInviteStatusEnum.ACCEPTED?.value === validateEventlyInvitationDetail?.data?.status) {
            navigate(`${pagesInfo?.SCHEDULE?.pagePath}?eventlyId=${validateEventlyInvitationDetail?.data?.evently_id}`)
        } else if (eventlyInviteStatusEnum.DECLINED?.value === validateEventlyInvitationDetail?.data?.status) {
            // window.open(`${EDULYTE_WEB_DOMAIN_URL}/evently/${validateEventlyInvitationDetail?.data?.evently_id}`, "_self")
            // window.open(`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/evently/${validateEventlyInvitationDetail?.data?.evently_id}` })}`, "_self")
            customNavigation.navigation({ path: `/evently/${validateEventlyInvitationDetail?.data?.evently_id}`, isWeb: true })
        }
    }

    if (validateEventlyInvitationDetail?.isLoading) {
        return (
            <PageLoader />
        )
    }

    if (!!validateEventlyInvitationDetail?.message) {
        return (
            <div className="min-h-screen flex justify-center items-center">
                <div className="max-w-full sm:max-w-md mx-auto w-full bg-white rounded-lg shadow-all p-8 flex flex-col items-center gap-5">
                    <div className="font-bodyPri font-bold text-red-500 text-2xl text-center">
                        {validateEventlyInvitationDetail?.message}
                    </div>

                    <button
                        onClick={() => navigate(pagesInfo?.DASHBOARD?.pagePath)}
                        className="bg-primary-dark hover:opacity-90 text-white py-2 px-6 rounded-lg focus:outline-none font-buttons font-medium text-md"
                    >
                        {"Go to Dashboard"}
                    </button>
                    <div className={""}>
                        <span className="font-bodyPri font-normal text-base">
                            {"or Contact to "}
                            <a href={"mailto:support@edulyte.com"}
                                className="font-medium text-primary-dark underline"
                            >
                                {"Support"}
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={"min-h-screen"}>

            <div className={cn(
                "max-w-full sm:max-w-xl mx-auto w-full mt-16 bg-white rounded-lg shadow-all p-3 space-y-3",
                eventlyInviteStatusEnum.ACCEPTED?.value === validateEventlyInvitationDetail?.data?.status && "!shadow-green-500",
                eventlyInviteStatusEnum.DECLINED?.value === validateEventlyInvitationDetail?.data?.status && "!shadow-red-500",
            )}>
                <div className="flex flex-col sm:flex-row items-start justify-start gap-3">

                    <div className="w-full sm:w-28 h-36 sm:h-full aspect-video sm:aspect-square rounded-lg overflow-hidden">
                        <img
                            src={validateEventlyInvitationDetail?.data?.thumbnail_pic_url}
                            className="w-full h-full object-cover"
                            alt={validateEventlyInvitationDetail?.data?.title || "thumbnail"}
                        />
                    </div>

                    <div className={"grow-0 sm:grow w-full flex flex-col items-start justify-between"}>
                        <div className={"w-full space-y-1"}>
                            <h2 className={"font-bodyPri font-medium text-base text-text-900 line-clamp-2"}>
                                {validateEventlyInvitationDetail?.data?.title}
                            </h2>
                            <p className={"font-bodyPri font-normal text-base text-text-800"}>
                                {"Send by"}
                                <span className={"font-medium text-text-900 ml-2"}>
                                    {`${validateEventlyInvitationDetail?.data?.sender_user?.first_name} ${validateEventlyInvitationDetail?.data?.sender_user?.last_name?.charAt(0)}.`}
                                </span>
                            </p>
                            <p className={"font-bodyPri font-normal text-base text-text-800"}>
                                {"Status"}
                                <span className={"font-medium text-text-900 ml-2"}>
                                    {eventlyInviteStatusEnum[validateEventlyInvitationDetail?.data?.status?.toUpperCase()]?.label}
                                </span>
                            </p>
                        </div>

                        <div className={"w-full flex flex-row items-center justify-between sm:justify-end gap-5"}>
                            {!isAcceptOrDeclined && (
                                <button
                                    className={cn(
                                        "w-full sm:w-fit font-buttons font-medium text-base text-text-700",
                                        "hover:text-text-900 cursor-pointer"
                                    )}
                                    onClick={handleDecline}
                                    disabled={!isEnableAccept}
                                >
                                    {"Decline"}
                                </button>
                            )}
                            {!isAcceptOrDeclined && (
                                <button
                                    className={cn(
                                        "w-full sm:w-fit font-buttons font-medium text-base text-text-50",
                                        "px-3 py-1 rounded bg-primary-dark hover:opacity-95 cursor-pointer"
                                    )}
                                    onClick={handleAccept}
                                    disabled={!isEnableAccept}
                                >
                                    {"Accept"}
                                </button>
                            )}
                            {isAcceptOrDeclined && (
                                <button
                                    className={cn(
                                        "w-full sm:w-fit font-buttons font-medium text-base text-text-50",
                                        "px-3 py-1 rounded bg-green-500 hover:bg-green-700 cursor-pointer"
                                    )}
                                    onClick={handleGoToEvently}
                                >
                                    {"Go To Evently"}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                {!!acceptAndDeclineEventlyInvitationDetail?.isLoading && (
                    <div className={"w-full font-bodyPri font-normal text-base text-primary-dark text-center"}>
                        <span>{"Loading..."}<FaSpinner className={"inline text-lg animate-spin"} /></span>
                    </div>
                )}
                {!!acceptAndDeclineEventlyInvitationDetail?.message && (
                    <div className={"w-full font-bodyPri font-normal text-sm text-secondary-dark text-center"}>
                        <span>{acceptAndDeclineEventlyInvitationDetail?.message}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default EventlyInvitationPage;