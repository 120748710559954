import CustomLocation from './custom';
import InPersonMeetingLocation from './inPersonMeeting';
import PhoneCallLocation from './phoneCall';
import InviteeLocation from './inviteeLocation';

import { sessionLocationTypeEnum } from 'redux/session/session.const';

const SessionLocation = ({ values }) => {

    return (
        <>
            {(values?.session_locations[0]?.location_type === sessionLocationTypeEnum.custom.key) && (
                <CustomLocation values={values} />
            )}
            {(values?.session_locations[0]?.location_type === sessionLocationTypeEnum.in_person_meeting.key) && (
                <InPersonMeetingLocation values={values} />
            )}
            {values?.session_locations[0]?.location_type === sessionLocationTypeEnum.phone_call.key && (
                <PhoneCallLocation values={values} />
            )}
            {(values?.session_locations[0]?.location_type === sessionLocationTypeEnum.invitee.key) && (
                <InviteeLocation values={values} />
            )}
        </>
    )
}

export default SessionLocation;