import { cn } from "utils/cn.utils"


export const OfferingCard = ({ cohort }) => {

    return (
        <div
            className={cn(
                "flex flex-col content-between justify-between bg-white rounded-lg shadow-[0_0_10px_1px_rgba(0,0,0,0.3)]",
                "w-52"
            )}
        >
            <div className='space-y-3'>
                <div className='rounded-t-lg overflow-hidden'>
                    <img
                        src={cohort?.cohort?.course?.thumbnail_pic_url}
                        alt=""
                        className="object-contain w-full h-32 rounded-t-lg"
                    />
                </div>
                <div className='px-3 pb-3 flex flex-col justify-start gap-3'>
                    <div className='flex justify-between gap-2'>
                        <span
                            className='px-2 py-0.5 flex items-center justify-center rounded-full bg-text-300 font-normal font-bodyPri text-sm tracking-wide whitespace-nowrap'
                        >
                            {cohort?.cohort?.course?.category?.category}
                        </span>
                    
                    </div>
                    <span className='text-text-900 font-medium text-base font-bodyPri tracking-wide truncate'>
                        {cohort?.cohort?.course?.title}
                    </span>
                </div>
            </div>
        </div>
    )
}


export const OfferingTutorCard = ({ course }) => {

    return (
        <div
            className={cn(
                "flex flex-col content-between justify-between bg-white rounded-lg shadow-[0_0_10px_1px_rgba(0,0,0,0.3)]",
                "w-52"
            )}
        >
            <div className='space-y-3'>
                <div className='rounded-t-lg overflow-hidden'>
                    <img
                        src={course?.thumbnail_pic_url || "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/home-page/live-one-on-one-lesson-learning-never-stop.jpg"}
                        alt=""
                        className="object-contain w-full h-32 rounded-t-lg"
                    />
                </div>
                <div className='px-3 pb-3 flex flex-col justify-start gap-3'>
                    <span className='text-text-900 font-medium text-base font-bodyPri tracking-wide truncate'>
                        {course?.title?.length > 50 ? course?.title?.substring(0, 50) + '...' : course?.title}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default OfferingCard