import { useAppState } from 'hooks/useStore';
import { sessionTypeEnum } from 'redux/session/session.const';

const SessionDetailRoleView = () => {
    const { sessionUserDetail } = useAppState(s => s.session)

    return sessionUserDetail?.data?.result?.type ? (
        <div className={"w-full flex items-center justify-center gap-1"}>
            <span className={"font-bodyPri font-medium text-md text-primary-dark"}>
                ({sessionTypeEnum[sessionUserDetail?.data?.result?.type?.toUpperCase()]?.label + " Meeting"})
            </span>
        </div>
    ) : null
}

export default SessionDetailRoleView;