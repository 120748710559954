import { useCallback, useEffect, useMemo, useState, memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';

import PageHeader from 'components/pageHeader/PageHeader';
import StatusButton from 'components/common-components/StatusButton';
import Pagination from 'components/pagination/Pagination';
import ThreeDotMenu from 'components/threeDotMenu/ThreeDotMenu';

import { downloadsTableHeaderConst, getDownloadsListPayload, menuItemsConst, pageHeading, searchParamsInfo } from './data';
import FilterSection from './components/filters/FilterSection';
import DownloadsTable from './components/DownloadsTable';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getOwnerUserDownloadsList } from "redux/downloads/downloads.request";

import { useTitle } from 'hooks/useTitle';
import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal, setShareSocial } from 'redux/local/local.slice';
import { resetOwnerUserDownloadsList } from "redux/downloads/downloads.slice";
import { modalConst, socialAction } from 'redux/local/local.const';
import { downloadStatusEnum } from 'redux/downloads/downloads.const';

import { dayjs, timeZone } from "utils/dateTime.utils";
import { pagesInfo } from 'utils/pagesInfo';
import { cn } from 'utils/cn.utils';
import { getCustomHostPath } from 'utils/generators.utils';

import { EDULYTE_WEB_DOMAIN_URL } from 'const/default.const';
import useCustomNavigation from "hooks/useCustomNavigation";

const DownloadsPage = () => {
    const publicOrgDetail = useAppState((state) => state.org.publicOrgDetail)
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { modal, shareSocial } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { ownerUserDownloadsList } = useAppState((state) => state.downloads)

    const dispatcher = useAppDispatcher()
    const [title, setTitle] = useTitle()
    const navigate = useNavigate()
    const location = useLocation()
    const customNavigation = useCustomNavigation()

    const [copiedURL, setCopiedURL] = useState(false)

    const searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.DOWNLOADS))
    }, [dispatcher])

    useEffect(() => {
        if (user?.user) {
            setTitle({
                ...title,
                title: `My Downloads | Edulyte`
            })
        }
    }, [user?.user])

    useEffect(() => {
        return () => {
            dispatcher(resetOwnerUserDownloadsList())
        }
    }, [])

    useEffect(() => {
        if (user?.user?.userId) {
            getDownloadsList()
        }
    }, [user?.user?.userId])

    const getDownloadsList = async () => {
        const requestDataPayload = await getDownloadsListPayload(searchQueryParams)

        dispatcher(getOwnerUserDownloadsList(user?.user?.userId, { ...requestDataPayload }))
    }

    const onHandlePageChange = async (page) => {
        searchQueryParams.set(searchParamsInfo.page.key, page)
        navigate(`${location.pathname}?${searchQueryParams?.toString()}`);
    }

    const onHandleSelectMenuItem = useCallback((menuItem, downloadItem) => {
        if (menuItem?.value === menuItemsConst?.INVITE?.value) {
            navigate(`${pagesInfo.EDIT_DOWNLOAD.pagePath}/${downloadItem?.id}/edit/participants/invited?action=invite-participants`)
        }
        if (downloadItem?.status === downloadStatusEnum?.PUBLISHED?.value) {
            if (menuItem.value === menuItemsConst.SHARE.value) {
                dispatcher(setShareSocial({
                    ...shareSocial,
                    socialAction: socialAction.SHARE_DOWNLOAD,
                    // url: `${EDULYTE_WEB_DOMAIN_URL}/download/${downloadItem?.slug}`
                    // url: `${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/download/${downloadItem?.slug}` })}`
                    url: `${customNavigation.getCustomBaseUrl({ path: `/download/${downloadItem?.slug}`, isWeb: true })}`
                }))
                dispatcher(setModal({
                    ...modal,
                    [modalConst.SHARE_SOCIAL_MODAL.stateKey]: true
                }))
            }
            if (menuItem.value === menuItemsConst.COPY.value) {
                // navigator.clipboard.writeText(`${EDULYTE_WEB_DOMAIN_URL}/download/${downloadItem?.slug}`);
                // navigator.clipboard.writeText(`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/download/${downloadItem?.slug}` })}`);
                navigator.clipboard.writeText(`${customNavigation.getCustomBaseUrl({ path: `/download/${downloadItem?.slug}`, isWeb: true })}`);
                setCopiedURL(true);
                toast.success("Copied")
                setTimeout(() => {
                    setCopiedURL(false);
                }, 2000);
            }
        }
        if (menuItem?.value === menuItemsConst?.PARTICIPANTS?.value) {
            navigate(`${pagesInfo.EDIT_DOWNLOAD.pagePath}/${downloadItem?.id}/edit/participants`)
        }
        if (menuItem.value === menuItemsConst.EDIT.value) {
            navigate(`${pagesInfo.EDIT_DOWNLOAD.pagePath}/${downloadItem?.id}/edit`)
        }
    }, [copiedURL, modal, shareSocial])

    const onHandleNavigateToDownload = (downloadItem) => {
        if (downloadItem?.status === downloadStatusEnum?.PUBLISHED?.value) {
            // window.open(`${EDULYTE_WEB_DOMAIN_URL}/download/${downloadItem?.slug}`, "_blank")
            // window.open(`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/download/${downloadItem?.slug}` })}`, "_blank")
            window.open(`${customNavigation.getCustomBaseUrl({ path: `/download/${downloadItem?.slug}`, isWeb: true })}`, "_blank")
        }
    }

    const DownloadTitleContainer = memo(({ downloadItem }) => {
        return (
            <div className='flex flex-col items-start justify-start gap-1'>
                <span
                    className={cn(
                        'font-bodyPri font-normal text-text-900 text-base line-clamp-1 tracking-wide',
                        (downloadItem?.status === downloadStatusEnum?.PUBLISHED?.value) && "cursor-pointer hover:text-primary-dark hover:underline"
                    )}
                    title={downloadItem?.title && downloadItem?.title}
                    onClick={() => onHandleNavigateToDownload(downloadItem)}
                >
                    {downloadItem?.title
                        ? (downloadItem?.title?.length > 30)
                            ? downloadItem?.title?.slice(0, 30) + " ..."
                            : downloadItem?.title
                        : "-"
                    }
                </span>
            </div>
        )
    })

    const tableRowsConst = useMemo(() => {
        if (!!ownerUserDownloadsList?.data?.result?.length) {
            return ownerUserDownloadsList?.data?.result?.map((downloadItem) => ([
                <DownloadTitleContainer downloadItem={downloadItem} />,
                <StatusButton
                    status={downloadStatusEnum[downloadItem?.status?.toUpperCase()?.toUpperCase()]?.label}
                    className={`bg-${downloadStatusEnum[downloadItem?.status?.toUpperCase()?.toUpperCase()]?.lightColor} text-${downloadStatusEnum[downloadItem?.status?.toUpperCase()?.toUpperCase()]?.darkColor}`}
                />,
                dayjs(downloadItem?.createdAt)?.tz(timeZone)?.format('DD, MMM, YYYY') || "-",
                <div className={"flex items-center justify-start"}>
                    <ThreeDotMenu
                        iconClassName={"rotate-[90deg]"}
                        menuItems={(downloadItem?.status === downloadStatusEnum?.PUBLISHED?.value)
                            ? Object.values(menuItemsConst)
                            : Object.values(menuItemsConst)?.filter((menuItem) => ((menuItem?.value !== menuItemsConst?.COPY?.value) && (menuItem?.value !== menuItemsConst?.SHARE?.value) && (menuItem?.value !== menuItemsConst?.INVITE?.value)))
                        }
                        onHandleSelect={(option) => onHandleSelectMenuItem(option, downloadItem)}
                    />
                </div>
            ]))
        } else return [];
    }, [ownerUserDownloadsList?.data?.result])

    const onHandleAddNewDownload = useCallback(() => {

        dispatcher(setModal({
            ...modal,
            [modalConst.addDownloadModal.key]: {
                ...modal[modalConst.addDownloadModal.key],
                isVisible: true,
                title: "Add Download"
            }
        }))
    }, [modal, ownerUserDownloadsList?.data?.result])

    return (
        <div className={"h-full w-full p-3 mx-auto min-h-screen space-y-3"}>
            <PageHeader
                pageHeading={pageHeading}
                toolTipText={"Profit from your digital products. Upload, share and sell pdf eBooks, templates, study notes, guides etc."}
            />
            <FilterSection />
            <DownloadsTable
                isLoading={ownerUserDownloadsList?.isLoading}
                headers={downloadsTableHeaderConst}
                rows={tableRowsConst}
                alternateRowColor={"bg-sky-100"}
                rowHeaderColor={"bg-sky-200"}
                onHandleAddNew={onHandleAddNewDownload}
            />
            {(!ownerUserDownloadsList?.isLoading && (ownerUserDownloadsList?.data && (ownerUserDownloadsList?.data?.pagination?.totalPages > 1))) &&
                <div className={"flex justify-center items-center"}>
                    <Pagination
                        page={ownerUserDownloadsList?.data?.pagination?.page}
                        totalPages={ownerUserDownloadsList?.data?.pagination?.totalPages}
                        onChangePage={onHandlePageChange}
                    />
                </div>
            }
            {(!ownerUserDownloadsList?.isLoading && ownerUserDownloadsList?.message) &&
                <div className={'w-full flex items-center justify-center'}>
                    <span className={'font-bodyPri font-normal text-red-500 text-base text-center'}>
                        {ownerUserDownloadsList.message}
                    </span>
                </div>
            }
        </div>
    )
}

export default DownloadsPage;