import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, Link } from "react-router-dom";

import { IoMdArrowRoundBack } from "react-icons/io";
import { MdPlayLesson } from 'react-icons/md';

import ToolTipView from 'components/tooltipView';

import { classDetailTabConst } from '../../data';
import TopTabNavigation from '../navigations/TopTabNavigation';
import RefreshButton from '../buttons/RefreshButton';
import PublishBtn from '../buttons/PublishBtn';

import { getInstructorCourse, updateCourse } from 'redux/course/course.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetModifyCourse } from 'redux/course/course.slice';
import { instructorCourseStatusEnum } from 'redux/course/course.const';

import { pagesInfo } from 'utils/pagesInfo';

const ClassDetailHeader = () => {
    const { user } = useAppState((state) => state.user)
    const { instructorCourse, modifyCourse } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const onHandleBack = useCallback(() => {
        navigate(-1)
    }, [])

    const onHandleRefreshCourse = () => {
        if (instructorCourse?.isLoading) return;

        dispatcher(resetModifyCourse())
        dispatcher(getInstructorCourse(instructorCourse?.result?.id, user?.user?.userId))
    }

    const onHandleUpdateStatus = (courseStatusType) => {
        if (instructorCourse?.isLoading || modifyCourse?.isLoading) return;

        if (courseStatusType?.key === instructorCourseStatusEnum.draft.key) {
            if (!window.confirm("Are you sure?. You want to save it as draft course.")) return;
        }
        if (courseStatusType?.key === instructorCourseStatusEnum.published.key) {
            if (!instructorCourse?.result?.title) {
                toast.warn("Please add title to publish this course.")
                return;
            }
            if (!window.confirm("Are you sure?. You want to publish this course.")) return;
        }
        if (courseStatusType?.key === instructorCourseStatusEnum.inactive.key) {
            if (!window.confirm("Are you sure?. You want to deactivate this course.")) return;
        }
        dispatcher(updateCourse(instructorCourse?.result?.id, { status: courseStatusType?.key }))
    };

    return (
        <>
            <div className={"sticky top-0 z-50 h-16 bg-white flex items-center justify-between drop-shadow-sm px-5"}>
                <div className={"h-10 flex justify-center items-center gap-2"}>
                    <ToolTipView content={"Back"}>
                        <button className={"p-2 rounded-full hover:bg-divider-darkLight cursor-pointer"} onClick={onHandleBack}>
                            <IoMdArrowRoundBack
                                className={'text-back-ground-dark'}
                                size={25}
                            />
                        </button>
                    </ToolTipView>
                    <Link to={pagesInfo.TUTOR_PRODUCTS.pagePath + "/live-classes"} className={"flex items-center justify-center gap-2 group"}>
                        <MdPlayLesson
                            size={30}
                            className={"text-primary-dark group-hover:text-secondary-dark"}
                        />
                        <p className={'font-bodyPri font-normal text-lg md:text-2xl text-text-900 group-hover:text-secondary-dark group-hover:underline'}>
                            {"Classes"}
                        </p>
                    </Link>
                </div>
                <div className={"hidden md:flex items-center"}>
                    <TopTabNavigation tabs={Object.values(classDetailTabConst)} />
                </div>
                <div className={'flex items-center justify-start gap-5'}>
                    <RefreshButton onHandleRefreshButton={onHandleRefreshCourse} />
                    <PublishBtn
                        isLoading={modifyCourse?.isLoading}
                        loaderBtnClassName={`bg-${instructorCourseStatusEnum[instructorCourse?.result?.status]?.darkColor}`}
                        selectedBtnClassName={`text-white bg-${instructorCourseStatusEnum[instructorCourse?.result?.status]?.darkColor}`}
                        dropdownBtnClassName={`text-white bg-${instructorCourseStatusEnum[instructorCourse?.result?.status]?.darkColor}`}
                        optionsList={Object.values(instructorCourseStatusEnum)?.filter((statusItem) => (statusItem?.key != instructorCourse?.result?.status))}
                        selectedOption={instructorCourseStatusEnum[instructorCourse?.result?.status]?.label}
                        onHandleUpdateStatus={onHandleUpdateStatus}
                    />
                </div>
            </div>
            <div className={"flex md:hidden justify-center items-center w-full rounded-lg"}>
                <TopTabNavigation tabs={Object.values(classDetailTabConst)} />
            </div>
        </>
    )
}

export default ClassDetailHeader;