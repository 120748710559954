import * as React from "react";
import { useNavigate } from "react-router-dom";
import { cn } from "utils/cn.utils";

import { doSomethingActionList, lmsModalsQueryString } from "pages/auth/dashboard/data";

import { setSideOverPanel } from "../store/DashboardSlice";
import SideOverPanel from "components/common-components/SideOverPanel";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal, setShareSocial } from "redux/local/local.slice";
import { modalConst, socialAction } from "redux/local/local.const";

import { pagesInfo } from "utils/pagesInfo";
import { EDULYTE_WEB_DOMAIN_URL } from "const/default.const";
import { getCustomHostPath } from "utils/generators.utils";
import useCustomNavigation from 'hooks/useCustomNavigation';

const DashboardSideOverPanel: React.FC = () => {
  const state = useAppState((s) => s.dashboard._meta.dashboardSideOverVisible);

  const dispatcher = useAppDispatcher();

  const onClose = () => {
    dispatcher(setSideOverPanel({ status: false }));
  };

  return (
    <div>
      <SideOverPanel
        child={<DashboardSideOverContent onClose={onClose} />}
        isOpen={state || false}
        onClose={onClose}
      />
    </div>
  );
};


const DashboardSideOverContent = ({ onClose }: { onClose: any }) => {
  const { user } = useAppState((state) => state.user)
  const { modal, locals, shareSocial } = useAppState((s) => s.local)
  const { publicOrgDetail } = useAppState((state) => state.org)

  const dispatcher = useAppDispatcher()
  const navigate = useNavigate()
  const customNavigation = useCustomNavigation()

  // const TUTOR_PROFILE_URL = `${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/tutor/${user?.user?.username}` })}`
  const TUTOR_PROFILE_URL = `${customNavigation.getCustomBaseUrl({  path: `/tutor/${user?.user?.username}`, isWeb: true })}`
  // const REVIEW_URL = `${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/tutor/${user?.user?.username}?action=leave-a-review` })}`
  const REVIEW_URL = `${customNavigation.getCustomBaseUrl({  path: `/tutor/${user?.user?.username}?action=leave-a-review`, isWeb: true })}`

  const handleOnClick = (action: any) => {
    if (["Create a Quiz", "Create a Course", "Add Your Questions"]?.includes(action.name)) {
      if (["Create a Quiz"]?.includes(action.name)) {
        navigate(`${pagesInfo.DASHBOARD.pagePath}?action=${lmsModalsQueryString.createQuiz.key}`)
      } else if (["Create a Course"]?.includes(action.name)) {
        navigate(`${pagesInfo.DASHBOARD.pagePath}?action=${lmsModalsQueryString.createCourse.key}`)
      } else if (["Add Your Questions"]?.includes(action.name)) {
        navigate(`${pagesInfo.DASHBOARD.pagePath}?action=${lmsModalsQueryString.createQuestion.key}`)
      }
      onClose()
      return;
    }
    if (["Share your profile"].includes(action.name)) {
      dispatcher(setShareSocial({
        ...shareSocial,
        socialAction: socialAction.SHARE_TUTOR_PROFILE,
        url: TUTOR_PROFILE_URL
      }))
      dispatcher(setModal({
        ...modal,
        [modalConst.SHARE_SOCIAL_MODAL.stateKey]: true
      }))
      onClose()
      return;
    }
    if (["Get Reviews"].includes(action.name)) {
      dispatcher(setShareSocial({
        ...shareSocial,
        socialAction: socialAction.GET_MORE_REVIEWS,
        url: REVIEW_URL
      }))
      dispatcher(setModal({
        ...modal,
        [modalConst.GET_MORE_REVIEWS.stateKey]: true
      }))
      onClose()
      return;
    } else {
      onClose()
      navigate(action.href)
    }
  }

  return (
    <>
      <div className="max-w-lg p-5 h-screen">
        <div>
          <p className="text-lg font-semibold text-[#356FC6]">
            {"Do Something"}
          </p>
        </div>
        <div className="grid gap-4 pt-12 md:grid-cols-2">
          {doSomethingActionList.map((action, index) => action.roles.includes(locals.userRole) && (
            <div key={index} onClick={() => handleOnClick(action)} className={cn(
              "flex flex-col items-center justify-center cursor-pointer",
              "p-3 space-y-3 border-2 group rounded-lg",
              "border-primary-light hover:border-secondary-main"
            )}>
              <div className={"w-20 h-20 overflow-hidden"}>
                <img src={action.image} alt={action.name} className={"w-full h-full object-cover"} />
              </div>
              <p className="font-semibold text-center text-base text-primary-main group-hover:text-secondary-main whitespace-nowrap">
                {action.name}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default DashboardSideOverPanel;
