import { useCallback, useState, useEffect } from 'react';

import { cn } from "utils/cn.utils";

import SearchSelector from 'components/searchSelector/SearchSelector';

import LmsCourseTooltip from 'pages/auth/edulyteLms/commonComponents/LmsCourseTooltip';

import { courseDetailBtnConst, classDetailNavigationOptions, classDetailTooltips, TITLE_MAX_LENGTH, SUB_TITLE_MAX_LENGTH, TITLE_MIN_LENGTH } from '../../data';
import PageHeading from '../../_components/header/pageHeading';
import SaveAndCancelBtn from "../../_components/buttons/SaveAndCancelBtn";
import PreviousNextButtons from '../../_components/buttons/PreviousNextButtons';

import { getAllCategoryList } from 'redux/category/category.request';
import { updateCourse } from 'redux/course/course.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setClearCategoryList } from 'redux/category/category.slice';
import { setModifyCoursePayload } from "redux/course/course.slice";
import { instructorCourseStatusEnum } from 'redux/course/course.const';

import { getCustomHostPath } from 'utils/generators.utils';

import { EDULYTE_WEB_DOMAIN_URL } from 'const/default.const';
import useCustomNavigation from 'hooks/useCustomNavigation';

const GeneralDetailPage = () => {
    const publicOrgDetail = useAppState((state) => state.org.publicOrgDetail)
    const { categoryList } = useAppState((state) => state.category)
    const { instructorCourse, modifyCourse } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()
    const customNavigation = useCustomNavigation()

    const [mouseHovered, setMouseHovered] = useState(null)
    const [editBtn, setEditBtn] = useState(null)

    useEffect(() => {
        dispatcher(getAllCategoryList())

        return () => {
            dispatcher(setClearCategoryList())
        }
    }, [])

    const onHandleNavigateToCourse = useCallback(() => {
        if (instructorCourse?.result?.status === instructorCourseStatusEnum?.published?.key) {
            // window.open(`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/class/${instructorCourse?.result?.slug}` })}`)
            customNavigation.navigation({ path: `/class/${instructorCourse?.result?.slug}`, isWeb: true })
        }
    }, [instructorCourse?.result])

    const onHandleEditDetail = (editBtn) => {
        if (editBtn === courseDetailBtnConst?.CATEGORY?.value) {
            dispatcher(setModifyCoursePayload({
                ...modifyCourse?.payload,
                [editBtn]: instructorCourse?.result?.category?.category
            }))
            setMouseHovered(null)
            setEditBtn(editBtn)
            return;
        }
        dispatcher(setModifyCoursePayload({
            ...modifyCourse?.payload,
            [editBtn]: instructorCourse?.result[editBtn]
        }))
        setMouseHovered(null)
        setEditBtn(editBtn)
    }

    const onHandleChangeBasicDetail = useCallback((event) => {
        dispatcher(setModifyCoursePayload({
            ...modifyCourse?.payload,
            [event?.target?.name]: event?.target?.value
        }))
    }, [modifyCourse?.payload])

    const onHandleSaveBasicDetail = useCallback((btnKey) => {
        if (btnKey === courseDetailBtnConst?.CATEGORY?.value) {
            if (instructorCourse?.result?.category === modifyCourse?.payload?.category) {
                setEditBtn(null)
                return;
            }
            dispatcher(updateCourse(
                instructorCourse?.result?.id,
                {
                    [btnKey]: modifyCourse?.payload?.category
                }
            ))
            setEditBtn(null)
        } else {
            if (instructorCourse?.result[btnKey] === modifyCourse?.payload[btnKey]) {
                setEditBtn(null)
                return;
            }
            dispatcher(updateCourse(
                instructorCourse?.result?.id,
                {
                    [btnKey]: modifyCourse?.payload[btnKey]
                }
            ))
            setEditBtn(null)
        }

    }, [instructorCourse?.result, modifyCourse?.payload, editBtn])

    const onHandleResetBasicDetail = useCallback(() => {
        setEditBtn(null)
    }, [editBtn])

    return (
        <div className={"w-full space-y-5"}>
            <div className={"w-full flex flex-col gap-5"}>

                <PageHeading headerText={"General Detail"} />

                <div className={"w-full px-5 flex flex-col gap-5"}>

                    {/* active slug / read */}
                    <div className={"grid grid-cols-12 gap-2 items-start"}>
                        <span className={"col-span-8 order-1 lg:col-span-2 w-fill flex items-center justify-start lg:justify-between font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                            <span>{"Slug"}</span>
                            <span>{":"}</span>
                        </span>
                        <span className={cn(
                            "col-span-full px-3 w-fit order-3 lg:order-2 lg:col-span-9 justify-self-stretch font-bodyPri font-normal text-text-900 text-md tracking-wide",
                            (instructorCourse?.result?.status === instructorCourseStatusEnum?.published?.key) && "!text-primary-dark hover:underline cursor-pointer"
                        )}
                            onClick={onHandleNavigateToCourse}
                        >
                            {instructorCourse?.result?.slug}
                        </span>
                    </div>

                    {/* title / read-write */}
                    <div className={cn("w-full grid grid-cols-12 gap-2 items-start")}>
                        <span className={"col-span-8 order-1 lg:col-span-2 w-full py-3 flex items-center justify-start lg:justify-between font-bodyPri font-medium text-text-700 text-base tracking-wide"}>
                            <span>{"Title"}</span>
                            <span>{":"}</span>
                        </span>
                        {(editBtn !== courseDetailBtnConst.TITLE.value) && (
                            <div className={cn(
                                "col-span-full order-3 lg:order-2 lg:col-span-9 justify-self-stretch",
                                "p-3 h-full hover:bg-text-100 cursor-text",
                                "font-bodyPri font-normal text-text-900 text-md tracking-wide",
                            )}
                                onClick={() => onHandleEditDetail(courseDetailBtnConst.TITLE.value)}
                            >
                                {instructorCourse?.result?.title || "-"}
                            </div>
                        )}
                        {(editBtn === courseDetailBtnConst.TITLE.value) && (
                            <div className={"col-span-full order-3 lg:order-2 lg:col-span-9 justify-self-stretch flex flex-col gap-0.5"}>
                                <input
                                    type={"text"}
                                    name={courseDetailBtnConst.TITLE.value}
                                    placeholder={"Enter course title"}
                                    maxLength={TITLE_MAX_LENGTH}
                                    value={modifyCourse?.payload?.title}
                                    onChange={onHandleChangeBasicDetail}
                                    className={cn(
                                        "flex flex-1 p-3 border-b focus:outline-none focus:!bg-primary-light bg-back-ground-light hover:bg-primary-light",
                                        "transition ease-in-out duration-300 overflow-hidden",
                                        (modifyCourse?.payload?.title?.trim()?.length < TITLE_MIN_LENGTH) ? "focus:border-red-500" : "focus:border-primary-dark",
                                        "font-bodyPri font-normal text-text-900 text-md tracking-wide placeholder:text-text-700"
                                    )}
                                    autoFocus={true}
                                />
                                <span className={"flex justify-end font-bodyPri font-normal text-text-700 text-sm"}>
                                    {`${modifyCourse?.payload?.title?.length}/${TITLE_MAX_LENGTH}`}
                                </span>
                            </div>
                        )}
                        {(editBtn === courseDetailBtnConst.TITLE.value) &&
                            <SaveAndCancelBtn
                                className={"col-span-4 lg:col-span-1 order-2 lg:order-3 justify-self-end flex flex-row gap-3"}
                                onHandleSave={() => onHandleSaveBasicDetail(courseDetailBtnConst.TITLE.value)}
                                onHandleReset={() => onHandleResetBasicDetail(courseDetailBtnConst.TITLE.value)}
                            />
                        }
                    </div>

                    {/* sub title / read-write */}
                    <div className={cn("w-full grid grid-cols-12 gap-2 items-start")}>
                        <span className={"col-span-8 order-1 lg:col-span-2 w-full py-3 flex items-center justify-start lg:justify-between font-bodyPri font-medium text-text-700 text-base tracking-wide"}>
                            <span className={"flex items-center gap-1"}>
                                <span>{"Sub Header"}</span>
                                <LmsCourseTooltip tooltip={classDetailTooltips?.courseSubTitle} />
                            </span>
                            <span>{":"}</span>
                        </span>
                        {(editBtn !== courseDetailBtnConst.SUB_TITLE.value) && (
                            <div className={cn(
                                "col-span-full order-3 lg:order-2 lg:col-span-9 justify-self-stretch",
                                "p-3 h-full hover:bg-text-100 cursor-text",
                                "font-bodyPri font-normal text-text-900 text-md tracking-wide",
                            )}
                                onClick={() => onHandleEditDetail(courseDetailBtnConst.SUB_TITLE.value)}
                            >
                                {instructorCourse?.result?.subtitle || "-"}
                            </div>
                        )}
                        {(editBtn === courseDetailBtnConst.SUB_TITLE.value) && (
                            <div className={"col-span-full order-3 lg:order-2 lg:col-span-9 justify-self-stretch flex flex-col gap-0.5"}>
                                <textarea
                                    type={"text"}
                                    name={courseDetailBtnConst.SUB_TITLE.value}
                                    placeholder={"Enter sub title"}
                                    rows={2}
                                    value={modifyCourse?.payload?.subtitle}
                                    onChange={onHandleChangeBasicDetail}
                                    className={cn(
                                        "w-full flex p-3 border-b border-primary-dark focus:outline-none focus:!bg-primary-light bg-back-ground-light hover:bg-primary-light",
                                        "transition ease-in-out duration-300 overflow-hidden",
                                        "font-bodyPri font-normal text-text-900 text-md tracking-wide placeholder:text-text-700"
                                    )}
                                    autoFocus={true}
                                />
                                <span className={"flex justify-end font-bodyPri font-normal text-text-700 text-sm"}>
                                    {`${modifyCourse?.payload?.sub_title?.length}/${SUB_TITLE_MAX_LENGTH}`}
                                </span>
                            </div>
                        )}
                        {(editBtn === courseDetailBtnConst.SUB_TITLE.value) &&
                            <SaveAndCancelBtn
                                className={"col-span-4 lg:col-span-1 order-2 lg:order-3 justify-self-end flex flex-row gap-3"}
                                onHandleSave={() => onHandleSaveBasicDetail(courseDetailBtnConst.SUB_TITLE.value)}
                                onHandleReset={() => onHandleResetBasicDetail(courseDetailBtnConst.SUB_TITLE.value)}
                            />
                        }
                    </div>

                    {/* category / read-write */}
                    <div className={cn("w-full grid grid-cols-12 gap-2 items-start")}>
                        <span className={"col-span-8 order-1 lg:col-span-2 w-full py-3 flex items-center justify-start lg:justify-between font-bodyPri font-medium text-text-700 text-base tracking-wide"}>
                            <span className={"inline-flex items-center gap-1"}>
                                <span className={"inline-flex whitespace-normal lg:whitespace-nowrap"}>
                                    {instructorCourse?.result?.category?.category
                                        ? instructorCourse?.result?.category?.segment?.segment
                                            ? "Segment/Category"
                                            : "Category"
                                        : "Category"
                                    }
                                </span>
                                <LmsCourseTooltip tooltip={classDetailTooltips?.category} />
                            </span>
                            <span>{":"}</span>
                        </span>

                        {(editBtn !== courseDetailBtnConst.CATEGORY.value) && (
                            <div className={cn(
                                "col-span-full order-3 lg:order-2 lg:col-span-9 justify-self-stretch",
                                "p-3 h-full hover:bg-text-100 cursor-text",
                                "font-bodyPri font-normal text-text-900 text-md tracking-wide",
                            )}
                                onClick={() => onHandleEditDetail(courseDetailBtnConst.CATEGORY.value)}
                            >
                                {instructorCourse?.result?.category?.category
                                    ? instructorCourse?.result?.category?.segment?.segment
                                        ? `${instructorCourse?.result?.category?.segment?.segment} / ${instructorCourse?.result?.category?.category}`
                                        : instructorCourse?.result?.category?.category
                                    : "Select Category"
                                }
                            </div>
                        )}
                        {(editBtn === courseDetailBtnConst.CATEGORY.value) && (
                            <div className={cn(
                                "col-span-full order-3 lg:order-2 lg:col-span-9 justify-self-stretch",
                                "w-full sm:h-fit z-10 border border-divider-lightDark rounded-lg",
                                "font-bodyPri font-normal text-text-900 text-base tracking-wide",
                                "focus:outline-none"
                            )}>
                                <SearchSelector
                                    isLoading={categoryList?.isLoading}
                                    isShowDropdownLabel={false}
                                    isOpenDropdown={true}
                                    placeholder={"Select Category"}
                                    dropdownLabel={"Select Category"}
                                    options={categoryList?.categoryList?.map((category) => ({
                                        label: category.category,
                                        value: category.category,
                                    })) || []}
                                    size={"small"}
                                    autoFocus={true}
                                    selectedValue={{
                                        label: modifyCourse?.payload?.category || "",
                                        value: modifyCourse?.payload?.category || ""
                                    }}
                                    onHandleSelect={(option) => dispatcher(setModifyCoursePayload({
                                        ...modifyCourse?.payload,
                                        category: option?.value
                                    }))}
                                />
                            </div>
                        )}
                        {(editBtn === courseDetailBtnConst.CATEGORY.value) &&
                            <SaveAndCancelBtn
                                className={"col-span-4 lg:col-span-1 order-2 lg:order-3 justify-self-end flex flex-row gap-3"}
                                onHandleSave={() => onHandleSaveBasicDetail(courseDetailBtnConst.CATEGORY.value)}
                                onHandleReset={() => onHandleResetBasicDetail(courseDetailBtnConst.CATEGORY.value)}
                            />
                        }
                    </div>
                </div>
            </div>
            <PreviousNextButtons
                previousBtn={null}
                nextBtn={classDetailNavigationOptions?.description?.to}
            />
        </div>
    )
}

export default GeneralDetailPage;