import { useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { MdVerified } from 'react-icons/md'
import { FiMessageSquare } from "react-icons/fi";
import { MdAccessTime } from "react-icons/md";
import { IoMdArrowRoundBack } from 'react-icons/io';
import { FaRegCalendarCheck } from 'react-icons/fa';

import ToolTipView from 'components/tooltipView'
import FadedCollapsible from 'components/fadedCollapse'

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { setMessageTemplate } from 'redux/firebase/firebase.slice';
import { getFBChat, getFBChatId } from 'redux/firebase/firebase.request';
import { appointmentPriceModelEnum } from "redux/appointment/appointment.const"
import { blueTickEnum } from 'redux/user/user.const'
import { modalConst } from 'redux/local/local.const';

import { cn } from 'utils/cn.utils';
import { pagesInfo } from 'utils/pagesInfo';
import { dayjs, timeZone, getTimeZoneOffset } from 'utils/dateTime.utils';
import { getCustomHostPath } from 'utils/generators.utils';
import useCustomNavigation from 'hooks/useCustomNavigation';

const AppointmentDetail = ({ appointment, onHandleBack, values }) => {
    const { publicOrgDetail } = useAppState((state) => state.org)
    const { user } = useAppState((s) => s.user)
    const { session } = useAppState(s => s.auth)
    const { modal } = useAppState(s => s.local)
    const { messageTemplate } = useAppState((s) => s.firebase)

    const navigate = useNavigate()
    const dispatcher = useAppDispatcher()
    const customNavigation = useCustomNavigation()

    const descriptionText = useMemo(() => new DOMParser()?.parseFromString(`<div>${appointment?.description || ""}</div>`, "text/html")?.querySelector("div")?.textContent, [appointment?.description])

    const isMyOffering = useMemo(() => user?.user?.userId === appointment?.user?.id, [appointment, user?.user])
    const timeZoneOffset = useMemo(() => getTimeZoneOffset(timeZone), [])

    const handleMessage = async () => {
        if (!session?.isLoggedIn) {
            // window.open(`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, pathname: `/log-in?redirect_url=${window.location.href}` })}`, "__self")
            customNavigation.navigation({ path: `/log-in?redirect_url=${window.location.href}` })
            return;
        }

        if (isMyOffering) {
            alert("Can't send self message!")
            return;
        };

        const userIdOne = user?.user?.userId
        const userIdTwo = appointment?.user?.id

        const chatId = await getFBChatId(userIdOne, userIdTwo)
        let fbChat = await getFBChat(chatId)

        if (!fbChat || fbChat === undefined) {
            dispatcher(setMessageTemplate({
                ...messageTemplate,
                isLoading: false,
                userIdOne: userIdOne,
                userIdTwo: userIdTwo,
                tutorUserId: appointment?.user?.id,
                message: '',
                errorMsg: null
            }))
            dispatcher(setModal({
                ...modal,
                [modalConst.MESSAGE_TEMPLATE_MODAL.stateKey]: true
            }))
            return;
        }

        navigate(`${pagesInfo.MESSAGES.pagePath}/${fbChat.chatId}`)
    }

    return (
        <div className={"space-y-8"}>

            {values?.appointment && (
                <ToolTipView content='Back'>
                    <button type="button" onClick={onHandleBack} className={
                        "p-2 w-fit group bg-back-ground-medium hover:bg-back-ground-lightDark rounded-full cursor-pointer transition-all ease-in-out duration-300"
                    }>
                        <IoMdArrowRoundBack className={"text-2xl text-text-700 group-hover:text-text-300"} />
                    </button>
                </ToolTipView>
            )}

            <div className={"space-y-1"}>
                <h1 className={cn("font-bold text-3xl text-text-900", values?.appointment && "line-clamp-1")}>
                    {appointment?.title}
                </h1>
                {!!appointment?.subtitle && (
                    <p className={cn("font-medium text-md text-text-600", values?.appointment && "line-clamp-1")}>
                        {appointment?.subtitle}
                    </p>
                )}
            </div>

            <div className="w-full bg-back-ground-darkLight rounded-lg px-3 py-2 flex items-center justify-between">
                <div className={"flex items-center justify-start gap-3 group"}>
                    <div className='w-12 h-12 rounded-full'>
                        <Link to={`${pagesInfo?.TUTOR?.pagePath}/${appointment?.user?.id}`}>
                            <img src={appointment?.user?.profilePicUrl} alt={"tutor-public-profile"} className="w-full h-full object-cover rounded-full" />
                        </Link>
                        <div className={'relative has-tooltip'}>
                            <div className={"absolute bottom-0.5 right-0.5 w-4 h-4 rounded-full border-2 border-white overflow-hidden"}>
                                <ToolTipView content={appointment?.user?.fromCountry?.country}>
                                    <img
                                        src={`https://flagcdn.com/16x12/${appointment?.user?.fromCountry?.countryDomain}.png`.toLowerCase()}
                                        alt={"country-flag"}
                                        className='w-full h-full object-cover overflow-hidden'
                                    />
                                </ToolTipView>
                            </div>
                        </div>
                    </div>
                    <div className='flex items-center gap-2'>
                        <Link to={`${pagesInfo?.TUTOR?.pagePath}/${appointment?.user?.id}`}>
                            <span className="font-bodyPri font-medium text-lg text-text-900 group-hover:text-secondary-main group-hover:underline capitalize">
                                {appointment?.user?.firstName + " " + appointment?.user?.lastName.charAt(0) + "."}
                            </span>
                        </Link>
                        {(appointment?.user?.blueTick === blueTickEnum?.YES?.value) && (
                            <ToolTipView content={"Verified Account"}>
                                <span><MdVerified className={"text-lg text-primary-dark cursor-pointer"} /></span>
                            </ToolTipView>
                        )}
                        {(appointment?.user?.onlineStatus === "online") && <span className={"w-2 h-2 rounded-full bg-green-600"}></span>}
                    </div>
                </div>
                <div>
                    <ToolTipView content={"Message"}>
                        <span><FiMessageSquare className={"text-3xl text-text-700 hover:text-primary-dark cursor-pointer"} onClick={handleMessage} /></span>
                    </ToolTipView>
                </div>
            </div>

            <div className={"space-y-1"}>
                {(appointment?.appointment_price?.price_model === appointmentPriceModelEnum.FLAT_FEE.value) && (
                    <div className='space-y-1'>
                        <span className='font-bodyPri font-semibold text-2xl text-text-900'>
                            {appointment?.appointment_price?.master_currency?.code + " " + parseFloat((appointment?.appointment_price?.price - ((appointment?.appointment_price?.price * appointment?.appointment_price?.discount_pct) / 100)) / 100).toFixed(2)}
                        </span>
                        {(appointment?.appointment_price?.discount_pct > 0 && appointment?.appointment_price?.discount_pct < 100) && (
                            <span className='flex flex-row items-start justify-start gap-2'>
                                <del className='font-bodyPri font-medium text-base text-text-700'>
                                    {appointment?.appointment_price?.master_currency?.code + " " + parseFloat(appointment?.appointment_price?.price / 100).toFixed(2)}
                                </del>
                                <span className={"font-bodyPri font-medium text-base text-green-500"}>
                                    {appointment?.appointment_price?.discount_pct + "% off"}
                                </span>
                            </span>
                        )}
                    </div>
                )}
                {(appointment?.appointment_price?.price_model === appointmentPriceModelEnum.FREE.value) && (
                    <div className='space-y-1'>
                        <span className='font-bodyPri font-semibold text-2xl text-text-900'>
                            {"FREE"}
                        </span>
                    </div>
                )}
            </div>

            <div className={"space-y-2"}>
                <div className={"flex items-center justify-start gap-2"}>
                    <MdAccessTime className={"text-xl text-text-700"} />
                    <span className={"font-bodyPri font-medium text-md text-text-800"}>
                        {(appointment?.appointment_availability?.duration >= 60)
                            ? `${parseFloat(appointment?.appointment_availability?.duration / 60).toFixed(2)} hr`
                            : `${appointment?.appointment_availability?.duration} min`
                        }
                    </span>
                </div>
            </div>

            {(!!appointment?.description && !values?.appointment?.schedule?.length) && (
                <div className="space-y-2">
                    <h2 className="font-bodyPri font-medium text-lg text-text-800">
                        {"Description"}
                    </h2>
                    <FadedCollapsible
                        maxHeight={"3.5rem"}
                        isShowMore={descriptionText?.length > 400}
                    >
                        <div dangerouslySetInnerHTML={{ __html: appointment?.description }}></div>
                    </FadedCollapsible>
                </div>
            )}

            {!!values?.appointment?.schedule?.length && (
                <div className={"space-y-2"}>
                    <div className={"flex items-start justify-start gap-2"}>
                        <FaRegCalendarCheck className={"text-xl text-text-700"} />
                        <div className={"space-y-1"}>
                            {values?.appointment?.schedule?.map(dateTimeSlot => dateTimeSlot?.timeSlots?.map((timeSlot, index) => (
                                <div key={index} className={"font-bodyPri font-medium text-md text-text-800"}>
                                    <span>{dayjs(dateTimeSlot?.date + " " + timeSlot?.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("hh:mm A")}</span>
                                    <span>{dayjs(dateTimeSlot?.date + " " + timeSlot?.endTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("[ - ]hh:mm A")}</span>
                                    <span>{dayjs(dateTimeSlot?.date + timeZoneOffset, "YYYY-MM-DDZ").tz(timeZone).format("[, ]dddd, DD MMM YYYY")}</span>
                                </div>
                            )))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default AppointmentDetail