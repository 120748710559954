import format from 'string-format';

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";

import { url } from "redux/session/session.const";

class SessionService {
    createSession = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CREATE_SESSION_DETAIL),
            body
        )

        return response;
    }

    getCalendarSessionUserList = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_CALENDAR_SESSION_USER_LIST, params),
            { params: query }
        )

        return response;
    }

    getSessionUserDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_SESSION_USER_DETAIL, params)
        )

        return response;
    }

    startSessionUser = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.START_SESSION_USER_DETAIL, params),
            body
        )

        return response;
    }

    acceptSessionUser = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.ACCEPT_SESSION_USER_DETAIL, params),
            body
        )

        return response;
    }

    declineSessionUser = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.DECLINE_SESSION_USER_DETAIL, params),
            body
        )

        return response;
    }

    rescheduleSessionUser = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.RESCHEDULE_SESSION_USER_DETAIL, params),
            body
        )

        return response;
    }

    completeSessionUser = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.COMPLETE_SESSION_USER_DETAIL, params),
            body
        )

        return response;
    }

    getSessionCourseList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_SESSION_COURSE_LIST),
            { params: query }
        )

        return response;
    }

    getSessionAppointmentList = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_SESSION_APPOINTMENT_LIST, params),
            { params: query }
        )

        return response;
    }

    getSessionLocationList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_SESSION_LOCATION_LIST),
            { params: query }
        )

        return response;
    }

    getSessionLocationDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_SESSION_LOCATION_DETAIL, params)
        )

        return response;
    }

    createSessionLocationDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().get(
            format(url.CREATE_SESSION_LOCATION_DETAIL, params),
            body
        )

        return response;
    }

    updateSessionLocationDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().get(
            format(url.UPDATE_SESSION_LOCATION_DETAIL, params),
            body
        )

        return response;
    }

    deleteSessionLocationDetail = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.DELETE_SESSION_LOCATION_DETAIL, params)
        )

        return response;
    }
}

export default new SessionService()