import { z } from "zod";

import GeneralDetailPage from "./classDetail/general";
import DescriptionPage from "./classDetail/description";
import SkillsPage from "./classDetail/skills";
import languagesPage from "./classDetail/languages";
import ProficienciesPage from "./classDetail/proficiencies";
import ThumbnailPage from "./classDetail/thumbnail";
import VideoPage from "./classDetail/video";
import FaqsPage from "./classDetail/faqs";

import DetailPage from "./cohorts/(cohort)/detail";
import SchedulePage from "./cohorts/(cohort)/schedule";
import ParticipantsPage from "./cohorts/(cohort)/participants";

import { dayjs } from "utils/dateTime.utils";

export const classDetailTabConst = {
    class_details: {
        label: "Details",
        key: "details",
        pagePath: "/details",
        routePath: "/details/*",
    },
    cohorts: {
        label: "Cohorts",
        key: "cohorts",
        pagePath: "/cohorts",
        routePath: "/cohorts/:cohortId/*",
    },
}

export const classDetailNavigationOptions = {
    general: {
        label: "General details",
        key: "general",
        pagePath: "/general",
        routePath: "general",
        to: "details/general",
        component: GeneralDetailPage
    },
    description: {
        label: "Description",
        key: "description",
        pagePath: "/description",
        routePath: "description",
        to: "details/description",
        component: DescriptionPage
    },
    skills: {
        label: "Skills you'll learn",
        key: "skills",
        pagePath: "/skills-you-will-learn",
        routePath: "skills-you-will-learn",
        to: "details/skills-you-will-learn",
        component: SkillsPage
    },
    languages: {
        label: "Language of instruction",
        key: "languages",
        pagePath: "/language-of-instruction",
        routePath: "language-of-instruction",
        to: "details/language-of-instruction",
        component: languagesPage
    },
    proficiencies: {
        label: "Level of difficulty",
        key: "proficiencies",
        pagePath: "/level-of-difficulty",
        routePath: "level-of-difficulty",
        to: "details/level-of-difficulty",
        component: ProficienciesPage
    },
    thumbnails: {
        label: "Thumbnail",
        key: "thumbnail",
        pagePath: "/thumbnail",
        routePath: "thumbnail",
        to: "details/thumbnail",
        component: ThumbnailPage
    },
    video: {
        label: "Promo video",
        value: "video",
        pagePath: "/promo-video",
        routePath: "promo-video",
        to: "details/promo-video",
        component: VideoPage
    },
    faqs: {
        label: "FAQs",
        key: "faqs",
        pagePath: "/faqs",
        routePath: "faqs",
        to: "details/faqs",
        component: FaqsPage
    },
}

export const cohortsNavigationOptions = {
    detail: {
        label: "Detail",
        key: "detail",
        pagePath: "/detail",
        routePath: "detail",
        component: DetailPage
    },
    schedule: {
        label: "Schedule",
        key: "schedule",
        pagePath: "/schedule",
        routePath: "schedule",
        component: SchedulePage
    },
    participants: {
        label: "Participants",
        key: "participants",
        pagePath: "/participants",
        routePath: "participants",
        component: ParticipantsPage
    },
}

export const TITLE_MIN_LENGTH = 5
export const TITLE_MAX_LENGTH = 128
export const SUB_TITLE_MAX_LENGTH = 512

export const courseDetailBtnConst = {
    TITLE: {
        value: "title"
    },
    SUB_TITLE: {
        value: "subtitle"
    },
    CATEGORY: {
        value: "category"
    },
    DESCRIPTION: {
        value: "description"
    }
}

export const Editor = {
    modules: {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'list': 'bullet' }],
            ['bold', 'italic', 'underline'],
            ['link'],
            [{ 'color': [] }, { 'background': [] }],
            ['clean']
        ],
        clipboard: {
            matchVisual: false,
        }
    },
    theme: 'snow',
    formats: [
        'header', 'font', 'size',
        'bold', 'italic', 'underline',
        'list', 'bullet', 'indent',
        'link',
        'color', 'background'
    ]
}

export const cohortDetailSchema = z.object({
    title: z.string()
        .min(10, { message: "Text length must be at least 10 characters" })
        .max(500, { message: "Text length must not exceed 500 characters" })
        .default(""),
    description: z.string().optional(),
    enrollment_start_datetime: z.string().refine((value) => dayjs(value, "YYYY-MM-DD HH:mm:ss", true).isValid(),
        { message: "Invalid enrollment start datetime" }
    ),
    enrollment_close: z.string({ message: "Enrollment close is required" }),
    participant_limit: z.number().min(1, { message: "Participant limit must be at least 1" }).optional(),
    enrollment_close_datetime: z.string().optional().refine((value) => {
        if (!value) return true;
        else return dayjs(value, "YYYY-MM-DD HH:mm:ss", true).isValid();
    }, { message: "Invalid enrollment start datetime" }),
    status: z.string(),
    is_paid: z.boolean().optional(),
    cohort_price: z.object({
        paid_session_count: z.number().min(1, { message: "Must be at least 1 session" }).max(100, { message: "Max session count can be 100" }),
        currency_code: z.string({message: "Currency code is required!"}),
        price: z.number({ message: "Price is required!" }),
        discount_pct: z.number({ message: "Discount oct code is required!" }).max(100, { message: "Percentage cannot be greater than 100" }),
    }).optional()
}).superRefine((data, ctx) => {
    if (data.is_paid && !data.cohort_price) {
        ctx.addIssue({
            path: ["cohort_price"],
            message: "Cohort price is required when is paid is true",
        });
    }
});

export const sectionInitialState = {
    isLoading: false,
    result: null,
    payload: { isInputVisible: false, id: 0, title: "" },
    error: null
}

export const participantsHeaderConst = {
    image: {
        label: "Image",
        key: "image"
    },
    user_id: {
        label: "User ID",
        key: "user_id"
    },
    name: {
        label: "Name",
        key: "name"
    },
    joining_flow: {
        label: "Joining Flow",
        key: "joining_flow"
    },
    status: {
        label: "Status",
        key: "status"
    },
    is_active: {
        label: "Is Active",
        key: "is_active"
    },
    joined_on: {
        label: "Joined on",
        key: "joined_on"
    },
    activity: {
        label: "Activity",
        key: "activity"
    }
}

export const cohortParticipantIsActiveConst = {
    active: {
        label: "Active",
        key: "active"
    },
    inactive: {
        label: "In Active",
        key: "inactive"
    }
}

export const classDetailTooltips = {
    courseSubTitle: "Mini subheader to give further insight or support to the course name",
    category: "Select the primary category that best fits your course. This helps students find your content based on their interests.",
    description: "Provide a compelling overview of your course. Highlight what makes it unique and what students can expect to achieve upon completion.",
    skills: "List key skills or concepts students will acquire. Use keywords (upto 3 words for each keyword) to make your course easily discoverable.",
    languageOfInstructions: "Specify the language you'll use for teaching this course. This ensures students are comfortable with the language before enrolling.",
    levelOfDifficulty: "Choose the difficulty level that best describes your course content, helping students to pick courses that match their skill level.",
    thumbnail: "Upload / Find an eye-catching image that represents your course. First impressions matter!",
    promoVideo: "Add a short promotional video to give potential students a taste of your teaching style and course content.",
    faq: "Include answers to frequently asked questions about your course. This can cover course prerequisites, materials provided, and any other common queries.",
    shareableCourseLink: "Share a direct link to your course. Perfect for promoting your course on social media, emails, or any other marketing channels.",
    publishedInviteBtnTooltip: "Send cohort invitations through email.",
    disabledInviteBtnTooltip: "Publish this cohort to invite participants.",
}