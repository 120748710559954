import { Controller, useFormContext } from 'react-hook-form';

import { OptionSelector } from 'components/common-components/Select';

import DateTimePicker from 'pages/auth/editClass/_components/datetimePicker';

import { cohortEnrollmentCloseEnum, cohortStatusEnum } from 'redux/course/course.const';

import { cn } from 'utils/cn.utils';
import { dayjs, timeZone } from 'utils/dateTime.utils';

const EnrollmentInfo = ({ enrollmentDetail }) => {

    const { register, setValue, getValues, control, unregister, trigger } = useFormContext()

    return (
        <div className={"w-full grid grid-cols-2 gap-5 font-bodyPri font-normal"}>
            <div className={"col-span-full md:col-span-1 space-y-2"}>
                <span className={"font-bodyPri font-bold text-text-700 text-base tracking-wide"}>
                    {"Enrollment Start Date"}<span className={"text-red-500"}>{"*"}</span>
                </span>
                <Controller
                    name={"enrollment_start_datetime"}
                    control={control}
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                        <div className={"w-full space-y-2"}>
                            <DateTimePicker
                                defaultValue={dayjs(value).tz(timeZone)}
                                value={value ? dayjs(value).tz(timeZone) : null}
                                onChange={(datetime) => {
                                    onChange(dayjs(datetime).format("YYYY-MM-DD HH:mm:ss"))
                                    trigger("enrollment_start_datetime")
                                }}
                                className={"!py-0 flex w-full focus:outline-none rounded-lg border border-back-ground-lightDark focus:border focus:border-primary-main"}
                                minDate={dayjs().tz(timeZone)}
                            />
                            {!!error && (
                                <div className={"flex items-center justify-start"}>
                                    <span className={"font-bodyPri font-normal text-sm text-secondary-main"}>
                                        {error?.message}
                                    </span>
                                </div>
                            )}
                        </div>
                    )}
                />
            </div>
            <div className={"col-span-full md:col-span-1 space-y-2"}>
                <span className={"font-bodyPri font-bold text-text-700 text-base tracking-wide"}>
                    {"Enrollment Close"}<span className={"text-red-500"}>{"*"}</span>
                </span>
                <Controller
                    name={"enrollment_close"}
                    control={control}
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                        <div className={"w-full space-y-2"}>
                            <OptionSelector
                                options={Object.values(cohortEnrollmentCloseEnum).map((cohortEnroll) => ({
                                    label: cohortEnroll?.label,
                                    value: cohortEnroll?.key
                                }))}
                                value={!!value ? value : "Select any"}
                                onChange={(option) => {
                                    if ([cohortEnrollmentCloseEnum.participant_limit.key]?.includes(option)) {
                                        register("participant_limit", enrollmentDetail?.participant_limit ?? 0)
                                        unregister("enrollment_close_datetime")
                                    } else if ([cohortEnrollmentCloseEnum.datetime.key]?.includes(option)) {
                                        register("enrollment_close_datetime", enrollmentDetail?.enrollment_close_datetime ?? dayjs().tz(timeZone))
                                        unregister("participant_limit")
                                    }
                                    onChange(option?.value)
                                    trigger("enrollment_close")
                                }}
                            />
                            {!!error && (
                                <div className={"flex items-center justify-start"}>
                                    <span className={"font-bodyPri font-normal text-sm text-secondary-main"}>
                                        {error?.message}
                                    </span>
                                </div>
                            )}
                        </div>
                    )}
                />
            </div>
            {!!getValues("enrollment_close") &&
                <>
                    {(getValues("enrollment_close") === cohortEnrollmentCloseEnum.participant_limit.key) &&
                        <div className={"col-span-full md:col-span-1 space-y-2"}>
                            <span className={"font-bodyPri font-bold text-text-700 text-base tracking-wide"}>
                                {"Participant Limit"}<span className={"text-red-500"}>{"*"}</span>
                            </span>
                            <input
                                type={"number"}
                                placeholder={"Enter limit here ..."}
                                className={cn(
                                    "px-3 h-9 flex w-full input-number-spin-none focus:outline-none rounded-lg border border-back-ground-lightDark focus:border focus:border-primary-main",
                                    "font-bodyPri font-normal text-text-800 text-base placeholder:text-text-500"
                                )}
                                value={enrollmentDetail?.participant_limit.toString()}
                                onChange={(event) => {
                                    setValue("participant_limit", Number(event.target.value))
                                    trigger("participant_limit")
                                }}
                            />
                        </div>
                    }
                    {(getValues("enrollment_close") === cohortEnrollmentCloseEnum.datetime.key) &&
                        <div className={"col-span-full md:col-span-1 space-y-2"}>
                            <span className={"font-bodyPri font-bold text-text-700 text-base tracking-wide"}>
                                {"Enrollment Close Date"}<span className={"text-red-500"}>{"*"}</span>
                            </span>
                            <Controller
                                name={"enrollment_close_datetime"}
                                control={control}
                                render={({ field: { value, onChange }, fieldState: { error } }) => (
                                    <div className={"w-full space-y-2"}>
                                        <DateTimePicker
                                            defaultValue={dayjs(value).tz(timeZone)}
                                            value={value ? dayjs(value).tz(timeZone) : null}
                                            onChange={(datetime) => {
                                                onChange(dayjs(datetime).format("YYYY-MM-DD HH:mm:ss"))
                                                trigger("enrollment_close_datetime")
                                            }}
                                            className={"!py-0 flex w-full focus:outline-none rounded-lg border border-back-ground-lightDark focus:border focus:border-primary-main"}
                                            minDate={dayjs(enrollmentDetail?.enrollment_start_datetime).tz(timeZone)}
                                        />
                                        {!!error && (
                                            <div className={"flex items-center justify-start"}>
                                                <span className={"font-bodyPri font-normal text-sm text-secondary-main"}>
                                                    {error?.message}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                )}
                            />
                        </div>
                    }
                </>
            }
            <div className={"col-span-full md:col-span-1 space-y-2"}>
                <span className={"font-bodyPri font-bold text-text-700 text-base tracking-wide"}>
                    {"Status"}<span className={"text-red-500"}>{"*"}</span>
                </span>
                <Controller
                    name={"status"}
                    control={control}
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                        <div className={"w-full space-y-2"}>
                            <OptionSelector
                                options={Object.values(cohortStatusEnum).map((cohortEnroll) => ({
                                    label: cohortEnroll?.label,
                                    value: cohortEnroll?.key
                                }))}
                                value={!!value ? value : "Select any"}
                                onChange={(option) => {
                                    onChange(option?.value)
                                    trigger("status")
                                }}
                            />
                            {!!error && (
                                <div className={"flex items-center justify-start"}>
                                    <span className={"font-bodyPri font-normal text-sm text-secondary-main"}>
                                        {error?.message}
                                    </span>
                                </div>
                            )}
                        </div>
                    )}
                />
            </div>
        </div>
    )
}

export default EnrollmentInfo;