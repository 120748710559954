import { useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { toast } from "react-toastify";
import { Draggable } from 'react-drag-reorder';

import { FaTimes } from 'react-icons/fa';

import SelectSearch, { fuzzySearch } from 'react-select-search';

import ComponentLoader from 'components/loader/ComponentLoader';

import PageHeading from '../../_components/header/pageHeading';
import AddBorderButton from '../../_components/buttons/AddBorderButton';
import PreviousNextButtons from '../../_components/buttons/PreviousNextButtons';

import { classDetailNavigationOptions, classDetailTooltips } from '../../data';

import { getMasterLanguageList } from 'redux/master/master.request';
import { getCourseLanguages, createCourseLanguage, updateCourseLanguageOrderList, deleteCourseLanguage } from 'redux/course/course.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setClearLanguageList } from 'redux/master/master.slice';
import { resetCourseLanguages } from 'redux/course/course.slice';

import { cn } from "utils/cn.utils";

const LanguagesPage = () => {
    const { languageList } = useAppState((state) => state.master)
    const { courseLanguages, addCourseLanguage, destroyCourseLanguage } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()
    const { courseId } = useParams()

    const [isShowLanguage, setIsShowLanguage] = useState(courseLanguages?.result?.length > 0)

    useEffect(() => {
        if (courseId)
            dispatcher(getMasterLanguageList())
            dispatcher(getCourseLanguages(Number(courseId)))

        return () => {
            dispatcher(setClearLanguageList())
            dispatcher(resetCourseLanguages())
        }
    }, [courseId])

    useEffect(() => {
        if (courseLanguages?.result?.length > 0) {
            setIsShowLanguage(true)
        }
    }, [courseLanguages?.result])

    const onHandleAddLanguage = useCallback(() => {
        dispatcher(createCourseLanguage(Number(courseId), {
            language: 'English'
        }))
        setIsShowLanguage(true)
    }, [isShowLanguage, courseId])

    const getChangedPos = useCallback((currentPos, newPos) => {
        var updatedList = [...courseLanguages?.result];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(currentPos, 1);
        // Add dropped item
        updatedList.splice(newPos, 0, reorderedItem);
        // Update State

        const body = {
            order: updatedList?.map((language) => ({
                id: language?.id
            }))
        }

        dispatcher(updateCourseLanguageOrderList(Number(courseId), body, { course_id: courseId }))
    }, [courseId, courseLanguages?.result]);

    const onHandleRemoveLanguage = useCallback((selectedLanguageId) => {

        dispatcher(deleteCourseLanguage(selectedLanguageId))
    }, [])

    const onHandleSelectLanguage = (option) => {
        const filteredLanguageList = courseLanguages?.result?.filter((language) => language?.master_language?.language === option)

        if (filteredLanguageList?.length > 0) {
            toast.warn("Duplicate languages are not allowed!")
            return;
        }
        dispatcher(createCourseLanguage(Number(courseId), { language: option }))
    }

    return (
        <div className={"w-full space-y-5"}>
            <div className={"w-full flex flex-col gap-5"}>

                <PageHeading headerText={"Language of instruction"} tooltipText={classDetailTooltips?.languageOfInstructions} />

                {!isShowLanguage &&
                    <AddBorderButton
                        btnTitle={"Add Language(s)"}
                        onHandleAdd={onHandleAddLanguage}
                    />
                }
                {isShowLanguage &&
                    <div
                        className={cn(
                            "w-full px-3 py-3 flex flex-col items-start justify-start border border-dashed border-text-500 rounded-lg",
                            "font-bodyPri font-normal text-text-900",
                        )}
                    >
                        {(languageList?.isLoading || addCourseLanguage?.isLoading || destroyCourseLanguage?.isLoading || courseLanguages?.isLoading) &&
                            <ComponentLoader isLoading={languageList?.isLoading || addCourseLanguage?.isLoading || destroyCourseLanguage?.isLoading || courseLanguages?.isLoading} />
                        }
                        {(!languageList?.isLoading && !addCourseLanguage?.isLoading && !destroyCourseLanguage?.isLoading) &&
                            <div className={"flex flex-wrap items-start justify-start gap-3"}>
                                <Draggable onPosChange={getChangedPos}>
                                    {courseLanguages?.result?.map((courseLanguage, idx) => (
                                        <div
                                            key={idx}
                                            className={'w-max px-3 py-1 border-2 border-text-300 flex items-center justify-start gap-1.5 rounded-xl'}
                                        >
                                            <span className={"font-bodyPri font-medium text-text-900 text-base"}>
                                                {courseLanguage?.master_language?.language}
                                            </span>
                                            <button className="pl-3 cursor-pointer">
                                                <FaTimes
                                                    className="font-light text-secondary-dark"
                                                    onClick={() => onHandleRemoveLanguage(courseLanguage?.id)}
                                                />
                                            </button>
                                        </div>
                                    ))}
                                </Draggable>
                                <div className={"w-52 h-32 md:h-48 z-[500] overflow-hidden border-2 border-divider-medium rounded-lg"}>
                                    <SelectSearch
                                        options={languageList?.languageList?.map((language) => ({
                                            name: language?.language,
                                            value: language?.language
                                        })) || []}
                                        placeholder={"Select Language"}
                                        search
                                        filterOptions={fuzzySearch}
                                        value={""}
                                        onChange={(option) => onHandleSelectLanguage(option)}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
            <PreviousNextButtons
                previousBtn={classDetailNavigationOptions?.skills?.to}
                nextBtn={classDetailNavigationOptions?.proficiencies?.to}
            />
        </div>
    )
}

export default LanguagesPage;