import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { AiOutlineLink } from 'react-icons/ai';

import { useAppState } from 'hooks/useStore';
import { userRoles } from 'redux/auth/auth.const';
import { cohortStatusEnum } from 'redux/course/course.const';
import { bookingStatusEnum } from 'redux/booking/booking.const';

import { pagesInfo } from 'utils/pagesInfo';
import { cn } from 'utils/cn.utils';
import { getCustomHostPath } from 'utils/generators.utils';
import useCustomNavigation from 'hooks/useCustomNavigation';

const BookingCourse = memo(({ newBookingDetail }) => {
    const { locals } = useAppState((state) => state.local)
    const { publicOrgDetail } = useAppState((state) => state.org)

    const navigate = useNavigate()
    const customNavigation = useCustomNavigation()

    const onHandleNavigateToCourse = useCallback(() => {
        if (newBookingDetail?.data?.result?.cohort?.course?.slug && [cohortStatusEnum.published.key]?.includes(newBookingDetail?.data?.result?.cohort?.status)) {
            // window.open(`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, pathname: `${pagesInfo.COURSE.pagePath}/${newBookingDetail?.data?.result?.cohort?.course?.slug}` })}`, "_blank")
            window.open(`${customNavigation.getCustomBaseUrl({ path: `${pagesInfo.COURSE.pagePath}/${newBookingDetail?.data?.result?.cohort?.course?.slug}` })}`, "_blank")
        }
    }, [newBookingDetail?.data?.result])

    const onHandleCheckout = useCallback(() => {
        navigate(`${pagesInfo?.CHECKOUT_BOOKING?.pagePath}/${newBookingDetail?.data?.result?.id}`)
    }, [newBookingDetail?.data?.result?.id])

    return (
        <>
            <div className={"w-full px-2 py-3 flex flex-col gap-5 border border-divider-medium rounded-md"}>
                <div className={"w-full flex flex-col gap-1.5"}>
                    <div className={cn(
                        "w-fit flex items-center justify-start gap-1.5 text-lg text-text-900",
                        [cohortStatusEnum.published.key]?.includes(newBookingDetail?.data?.result?.cohort?.status) && "!text-primary-dark hover:!text-primary-dark hover:underline cursor-pointer"
                    )}
                        onClick={onHandleNavigateToCourse}
                    >
                        <AiOutlineLink className={"text-lg"} />
                        <span className={"font-semibold font-bodyPri tracking-wide text-left truncate"}>
                            {newBookingDetail?.data?.result?.cohort?.course?.title}
                        </span>
                    </div>
                    {newBookingDetail?.data?.result?.cohort?.course?.subTitle &&
                        <span className={"font-bodyPri font-normal text-text-800 text-sm truncate"}>
                            {newBookingDetail?.data?.result?.cohort?.course?.subTitle}
                        </span>
                    }
                </div>
                <div className={"p-2 border border-divider-medium rounded-lg"}>
                    <div className={"grid gap-3 text-sm md:grid-cols-2 opacity-70 place-items-start"}>
                        <div className={"col-span-full md:col-span-2"}>
                            <span className={"font-semibold font-bodyPri tracking-wide text-left truncate"}>
                                {newBookingDetail?.data?.result?.cohort?.title
                                    ? (newBookingDetail?.data?.result?.cohort?.title?.length > 40)
                                        ? newBookingDetail?.data?.result?.cohort?.title?.slice(0, 40) + "..."
                                        : newBookingDetail?.data?.result?.cohort?.title
                                    : ""
                                }
                            </span>
                        </div>
                        <div className={"inline-flex space-x-2 whitespace-nowrap font-bodyPri tracking-wide text-text-900"}>
                            <p>{"CATEGORY:"}</p>
                            <p className="font-bold truncate">
                                {newBookingDetail?.data?.result?.cohort?.course?.category?.category
                                    ? (newBookingDetail?.data?.result?.cohort?.course?.category?.category?.length > 17)
                                        ? newBookingDetail?.data?.result?.cohort?.course?.category?.category?.slice(0, 17) + "..."
                                        : newBookingDetail?.data?.result?.cohort?.course?.category?.category
                                    : "-"
                                }
                            </p>
                        </div>
                        <div className="inline-flex space-x-1 whitespace-nowrap font-bodyPri tracking-wide text-text-900">
                            <p>{"SESSION(S):"}</p>
                            <p className="font-bold">
                                {newBookingDetail?.data?.result?.cohort?.session_count}
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            {((locals?.userRole === userRoles?.STUDENT?.value) && (newBookingDetail?.data?.result?.status === bookingStatusEnum?.PENDING?.value)) &&
                <div
                    className={cn(
                        "w-full px-4 py-2 mt-3 rounded-md bg-secondary-dark hover:opacity-90 cursor-pointer",
                        "text-center font-buttons font-medium text-base text-text-50 whitespace-nowrap",
                    )}
                    onClick={onHandleCheckout}
                >
                    <span>
                        {"Checkout to confirm"}
                    </span>
                </div>
            }
        </>
    )
})

export default BookingCourse;