import { GA_ID, GMT_ID } from "const/default.const";

const CONSENT_COOKIE_NAME = 'cookie_consent';
const COOKIE_SESSION_ID = 'session_id';
const COOKIE_SESSION_USER_ID = 'session_user_id';

export const getStoredConsent = () => {
    const stored = localStorage.getItem(CONSENT_COOKIE_NAME);
    return stored ? JSON.parse(stored) : null;
};

export const saveConsent = (consent, session_id=null) => {
    localStorage.setItem(CONSENT_COOKIE_NAME, JSON.stringify(consent));
    if (session_id) {
        localStorage.setItem(COOKIE_SESSION_ID, session_id);
    }
    window.dispatchEvent(new CustomEvent('cookieConsentUpdate', { detail: consent }));
    if (consent.analytics === 'accepted') {
        loadGTM(); 
    }
};

export const isSessionId = () => {
    const session_id = localStorage.getItem(COOKIE_SESSION_ID);
    return session_id
}

export const setCookieSessionUserId = (userId) => {
    localStorage.setItem(COOKIE_SESSION_USER_ID, userId);
    return
}
export const hasConsentFor = () => {
    const consent = getStoredConsent();
    return consent ? consent["category"] : 'rejected';
};

export const getDefaultConsent = () => ({
    necessary: true,
    analytics: false,
    marketing: false,
});


export const loadGTM = () => {

    // const script = document.createElement('script');
    // script.innerHTML = `
    //     (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    //     new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    //     j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    //     'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    //     })(window,document,'script','dataLayer','${GMT_ID}');
    //     `;
    // document.head.appendChild(script);


    // Load Google Tag Manager script

    const gtmScript = document.createElement('script');
    gtmScript.async = true;
    gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${GMT_ID}`;
    document.head.appendChild(gtmScript);

    const noscriptTag = document.createElement('noscript');
    noscriptTag.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${GMT_ID}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.appendChild(noscriptTag);

  // Load Google Analytics (gtag.js) script

    const gtagScript = document.createElement('script');
    gtagScript.defer = true;
    gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${GA_ID}`;
    document.head.appendChild(gtagScript);

    gtagScript.onload = () => {
      // Initialize dataLayer for Google Analytics
        window.dataLayer = window.dataLayer || [];
        function gtag() { window.dataLayer.push(arguments); }

        // Configure GA4 or Google Ads
        gtag('js', new Date());
        gtag('config', GA_ID);
    }
}