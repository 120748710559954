import { createSlice } from "@reduxjs/toolkit";

import { SESSION_INITIAL_STATE } from "./session.initialState";

const session = createSlice({
    name: "session",
    initialState: SESSION_INITIAL_STATE,
    reducers: {
        setAddSession: (state, { payload }) => {
            state.addSession = { ...state.addSession, ...payload }
        },
        resetAddSession: (state) => {
            state.addSession = SESSION_INITIAL_STATE.addSession
        },

        // reducers for dashboard event list
        setDashboardUpcomingEventListLoading: (state, { payload }) => {
            state.dashboardUpcomingEventList.isLoading = payload
        },
        setDashboardUpcomingEventListData: (state, { payload }) => {
            state.dashboardUpcomingEventList.data = payload
            state.dashboardUpcomingEventList.message = SESSION_INITIAL_STATE.dashboardUpcomingEventList.message
        },
        setDashboardUpcomingEventListMessage: (state, { payload }) => {
            state.dashboardUpcomingEventList.message = payload
            state.dashboardUpcomingEventList.data = SESSION_INITIAL_STATE.dashboardUpcomingEventList.data
        },
        resetDashboardUpcomingEventList: (state) => {
            state.dashboardUpcomingEventList = SESSION_INITIAL_STATE.dashboardUpcomingEventList
        },

        // reducers for dashboard event list
        setDashboardCalendarEventListLoading: (state, { payload }) => {
            state.dashboardCalendarEventList.isLoading = payload
        },
        setDashboardCalendarEventListData: (state, { payload }) => {
            state.dashboardCalendarEventList.data = payload
            state.dashboardCalendarEventList.message = SESSION_INITIAL_STATE.dashboardCalendarEventList.message
        },
        setDashboardCalendarEventListMessage: (state, { payload }) => {
            state.dashboardCalendarEventList.message = payload
            state.dashboardCalendarEventList.data = SESSION_INITIAL_STATE.dashboardCalendarEventList.data
        },
        resetDashboardCalendarEventList: (state) => {
            state.dashboardCalendarEventList = SESSION_INITIAL_STATE.dashboardCalendarEventList
        },

        // reducers for calendar session user list
        setCalendarSessionUserListLoading: (state, { payload }) => {
            state.calendarSessionUserList.isLoading = payload
        },
        setCalendarSessionUserListData: (state, { payload }) => {
            state.calendarSessionUserList.data = payload
            state.calendarSessionUserList.message = SESSION_INITIAL_STATE.calendarSessionUserList.message
        },
        setCalendarSessionUserListMessage: (state, { payload }) => {
            state.calendarSessionUserList.message = payload
            state.calendarSessionUserList.data = SESSION_INITIAL_STATE.calendarSessionUserList.data
        },
        resetCalendarSessionUserList: (state) => {
            state.calendarSessionUserList = SESSION_INITIAL_STATE.calendarSessionUserList
        },

        // reducers for session user detail
        setSessionUserDetailLoading: (state, { payload }) => {
            state.sessionUserDetail.isLoading = payload
        },
        setSessionUserDetailData: (state, { payload }) => {
            state.sessionUserDetail.data = payload
            state.sessionUserDetail.message = SESSION_INITIAL_STATE.sessionUserDetail.message
        },
        setSessionUserDetailMessage: (state, { payload }) => {
            state.sessionUserDetail.message = payload
            state.sessionUserDetail.data = SESSION_INITIAL_STATE.sessionUserDetail.data
        },
        resetSessionUserDetail: (state) => {
            state.sessionUserDetail = SESSION_INITIAL_STATE.sessionUserDetail
        },

        // add session user detail payload
        setAddSessionUserDetailPayload: (state, { payload }) => {
            state.addSessionUserDetailPayload.payload = payload
        },
        resetAddSessionUserDetailPayload: (state) => {
            state.addSessionUserDetailPayload = SESSION_INITIAL_STATE.addSessionUserDetailPayload
        },

        // reducers for start session
        setStartSessionUserDetailLoading: (state, { payload }) => {
            state.startSessionUserDetail.isLoading = payload
        },
        setStartSessionUserDetailData: (state, { payload }) => {
            state.startSessionUserDetail.data = payload
            state.startSessionUserDetail.message = SESSION_INITIAL_STATE.startSessionUserDetail.message
        },
        setStartSessionUserDetailMessage: (state, { payload }) => {
            state.startSessionUserDetail.message = payload
            state.startSessionUserDetail.data = SESSION_INITIAL_STATE.startSessionUserDetail.data
        },
        resetStartSessionUserDetail: (state) => {
            state.startSessionUserDetail = SESSION_INITIAL_STATE.startSessionUserDetail
        },

        // reducers for accept session
        setAcceptSessionUserDetailLoading: (state, { payload }) => {
            state.acceptSessionUserDetail.isLoading = payload
        },
        setAcceptSessionUserDetailData: (state, { payload }) => {
            state.acceptSessionUserDetail.data = payload
            state.acceptSessionUserDetail.message = SESSION_INITIAL_STATE.acceptSessionUserDetail.message
        },
        setAcceptSessionUserDetailMessage: (state, { payload }) => {
            state.acceptSessionUserDetail.message = payload
            state.acceptSessionUserDetail.data = SESSION_INITIAL_STATE.acceptSessionUserDetail.data
        },
        resetAcceptSessionUserDetail: (state) => {
            state.acceptSessionUserDetail = SESSION_INITIAL_STATE.acceptSessionUserDetail
        },

        // reducers for decline session
        setDeclineSessionUserDetailLoading: (state, { payload }) => {
            state.declineSessionUserDetail.isLoading = payload
        },
        setDeclineSessionUserDetailData: (state, { payload }) => {
            state.declineSessionUserDetail.data = payload
            state.declineSessionUserDetail.message = SESSION_INITIAL_STATE.declineSessionUserDetail.message
        },
        setDeclineSessionUserDetailMessage: (state, { payload }) => {
            state.declineSessionUserDetail.message = payload
            state.declineSessionUserDetail.data = SESSION_INITIAL_STATE.declineSessionUserDetail.data
        },
        resetDeclineSessionUserDetail: (state) => {
            state.declineSessionUserDetail = SESSION_INITIAL_STATE.declineSessionUserDetail
        },

        // reducers for reschedule session
        setRescheduleSessionUserDetailLoading: (state, { payload }) => {
            state.rescheduleSessionUserDetail.isLoading = payload
        },
        setRescheduleSessionUserDetailData: (state, { payload }) => {
            state.rescheduleSessionUserDetail.data = payload
            state.rescheduleSessionUserDetail.message = SESSION_INITIAL_STATE.rescheduleSessionUserDetail.message
        },
        setRescheduleSessionUserDetailMessage: (state, { payload }) => {
            state.rescheduleSessionUserDetail.message = payload
            state.rescheduleSessionUserDetail.data = SESSION_INITIAL_STATE.rescheduleSessionUserDetail.data
        },
        resetRescheduleSessionUserDetail: (state) => {
            state.rescheduleSessionUserDetail = SESSION_INITIAL_STATE.rescheduleSessionUserDetail
        },

        // reducers for complete session
        setCompleteSessionUserDetailLoading: (state, { payload }) => {
            state.completeSessionUserDetail.isLoading = payload
        },
        setCompleteSessionUserDetailData: (state, { payload }) => {
            state.completeSessionUserDetail.data = payload
            state.completeSessionUserDetail.message = SESSION_INITIAL_STATE.completeSessionUserDetail.message
        },
        setCompleteSessionUserDetailMessage: (state, { payload }) => {
            state.completeSessionUserDetail.message = payload
            state.completeSessionUserDetail.data = SESSION_INITIAL_STATE.completeSessionUserDetail.data
        },
        resetCompleteSessionUserDetail: (state) => {
            state.completeSessionUserDetail = SESSION_INITIAL_STATE.completeSessionUserDetail
        },

        // reducers for session course list
        setSessionCourseListLoading: (state, { payload }) => {
            state.sessionCourseList.isLoading = payload
        },
        setSessionCourseListData: (state, { payload }) => {
            state.sessionCourseList.data = payload
            state.sessionCourseList.message = SESSION_INITIAL_STATE.sessionCourseList.message
        },
        setSessionCourseListMessage: (state, { payload }) => {
            state.sessionCourseList.message = payload
            state.sessionCourseList.data = SESSION_INITIAL_STATE.sessionCourseList.data
        },
        resetSessionCourseList: (state) => {
            state.sessionCourseList = SESSION_INITIAL_STATE.sessionCourseList
        },

        // reducers for session appointment list
        setSessionAppointmentListLoading: (state, { payload }) => {
            state.sessionAppointmentList.isLoading = payload
        },
        setSessionAppointmentListData: (state, { payload }) => {
            state.sessionAppointmentList.data = payload
            state.sessionAppointmentList.message = SESSION_INITIAL_STATE.sessionAppointmentList.message
        },
        setSessionAppointmentListMessage: (state, { payload }) => {
            state.sessionAppointmentList.message = payload
            state.sessionAppointmentList.data = SESSION_INITIAL_STATE.sessionAppointmentList.data
        },
        resetSessionAppointmentList: (state) => {
            state.sessionAppointmentList = SESSION_INITIAL_STATE.sessionAppointmentList
        },

        // reducers for session location
        // list
        setSessionLocationListLoading: (state, { payload }) => {
            state.sessionLocationList.isLoading = payload
        },
        setSessionLocationListData: (state, { payload }) => {
            state.sessionLocationList.data = payload
            state.sessionLocationList.message = SESSION_INITIAL_STATE.sessionLocationList.message
        },
        setSessionLocationListMessage: (state, { payload }) => {
            state.sessionLocationList.message = payload
            state.sessionLocationList.data = SESSION_INITIAL_STATE.sessionLocationList.data
        },
        resetSessionLocationList: (state) => {
            state.sessionLocationList = SESSION_INITIAL_STATE.sessionLocationList
        },

        // get session location detail
        setSessionLocationDetailLoading: (state, { payload }) => {
            state.sessionLocationDetail.isLoading = payload
        },
        setSessionLocationDetailData: (state, { payload }) => {
            state.sessionLocationDetail.data = payload
            state.sessionLocationDetail.message = SESSION_INITIAL_STATE.sessionLocationDetail.message
        },
        setSessionLocationDetailMessage: (state, { payload }) => {
            state.sessionLocationDetail.message = payload
            state.sessionLocationDetail.data = SESSION_INITIAL_STATE.sessionLocationDetail.data
        },
        resetSessionLocationDetail: (state) => {
            state.sessionLocationDetail = SESSION_INITIAL_STATE.sessionLocationDetail
        },

        // add session location detail
        setAddSessionLocationDetailLoading: (state, { payload }) => {
            state.addSessionLocationDetail.isLoading = payload
        },
        setAddSessionLocationDetailData: (state, { payload }) => {
            state.addSessionLocationDetail.data = payload
            state.addSessionLocationDetail.message = SESSION_INITIAL_STATE.addSessionLocationDetail.message
        },
        setAddSessionLocationDetailMessage: (state, { payload }) => {
            state.addSessionLocationDetail.message = payload
            state.addSessionLocationDetail.data = SESSION_INITIAL_STATE.addSessionLocationDetail.data
        },
        resetAddSessionLocationDetail: (state) => {
            state.addSessionLocationDetail = SESSION_INITIAL_STATE.addSessionLocationDetail
        },

        // update session location detail
        setModifySessionLocationDetailLoading: (state, { payload }) => {
            state.modifySessionLocationDetail.isLoading = payload
        },
        setModifySessionLocationDetailData: (state, { payload }) => {
            state.modifySessionLocationDetail.data = payload
            state.modifySessionLocationDetail.message = SESSION_INITIAL_STATE.modifySessionLocationDetail.message
        },
        setModifySessionLocationDetailMessage: (state, { payload }) => {
            state.modifySessionLocationDetail.message = payload
            state.modifySessionLocationDetail.data = SESSION_INITIAL_STATE.modifySessionLocationDetail.data
        },
        resetModifySessionLocationDetail: (state) => {
            state.modifySessionLocationDetail = SESSION_INITIAL_STATE.modifySessionLocationDetail
        },

        // delete session location detail
        setDestroySessionLocationDetailLoading: (state, { payload }) => {
            state.destroySessionLocationDetail.isLoading = payload
        },
        setDestroySessionLocationDetailMessage: (state, { payload }) => {
            state.destroySessionLocationDetail.message = payload
        },
        resetDestroySessionLocationDetail: (state) => {
            state.destroySessionLocationDetail = SESSION_INITIAL_STATE.destroySessionLocationDetail
        },

        // reducers for session filters
        setFilterProps: (state, { payload }) => {
            state.filterProps = payload.filterProps
        },
        resetFilterProps: (state) => {
            state.filterProps = SESSION_INITIAL_STATE.filterProps
        }
    }
})

export const {
    setAddSession,
    resetAddSession,

    setDashboardUpcomingEventListLoading,
    setDashboardUpcomingEventListData,
    setDashboardUpcomingEventListMessage,
    resetDashboardUpcomingEventList,

    setDashboardCalendarEventListLoading,
    setDashboardCalendarEventListData,
    setDashboardCalendarEventListMessage,
    resetDashboardCalendarEventList,

    setCalendarSessionUserListLoading,
    setCalendarSessionUserListData,
    setCalendarSessionUserListMessage,
    resetCalendarSessionUserList,

    setSessionUserDetailLoading,
    setSessionUserDetailData,
    setSessionUserDetailMessage,
    resetSessionUserDetail,

    setAddSessionUserDetailPayload,
    resetAddSessionUserDetailPayload,

    setStartSessionUserDetailLoading,
    setStartSessionUserDetailData,
    setStartSessionUserDetailMessage,
    resetStartSessionUserDetail,

    setAcceptSessionUserDetailLoading,
    setAcceptSessionUserDetailData,
    setAcceptSessionUserDetailMessage,
    resetAcceptSessionUserDetail,

    setDeclineSessionUserDetailLoading,
    setDeclineSessionUserDetailData,
    setDeclineSessionUserDetailMessage,
    resetDeclineSessionUserDetail,

    setRescheduleSessionUserDetailLoading,
    setRescheduleSessionUserDetailData,
    setRescheduleSessionUserDetailMessage,
    resetRescheduleSessionUserDetail,

    setCompleteSessionUserDetailLoading,
    setCompleteSessionUserDetailData,
    setCompleteSessionUserDetailMessage,
    resetCompleteSessionUserDetail,

    setSessionCourseListLoading,
    setSessionCourseListData,
    setSessionCourseListMessage,
    resetSessionCourseList,

    setSessionAppointmentListLoading,
    setSessionAppointmentListData,
    setSessionAppointmentListMessage,
    resetSessionAppointmentList,

    setSessionLocationListLoading,
    setSessionLocationListData,
    setSessionLocationListMessage,
    resetSessionLocationList,

    setSessionLocationDetailLoading,
    setSessionLocationDetailData,
    setSessionLocationDetailMessage,
    resetSessionLocationDetail,

    setAddSessionLocationDetailLoading,
    setAddSessionLocationDetailData,
    setAddSessionLocationDetailMessage,
    resetAddSessionLocationDetail,

    setModifySessionLocationDetailLoading,
    setModifySessionLocationDetailData,
    setModifySessionLocationDetailMessage,
    resetModifySessionLocationDetail,

    setDestroySessionLocationDetailLoading,
    setDestroySessionLocationDetailMessage,
    resetDestroySessionLocationDetail,

    setFilterProps,
    resetFilterProps
} = session.actions
export default session.reducer;