import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ToolTipView from 'components/tooltipView';

import { cn } from "utils/cn.utils";

import {
    HamburgerMenuContainer,
    HamburgerHeader,
    HamburgerHeaderContent,
    CloseIcon,
    HamburgerContent,
    HamburgerAuthSection,
    SignUpIcon,
    LogInIcon,
    HamburgerFeatured,
    HamburgerFeaturedLink,
    CategoryIcon,
    FindTeacherIcon,
    FindCoursesIcon,
    TeachWithUsIcon,

    HamburgerMenuContent,
    Item,
    ProfileImageContainer,
    ItemIcon,
    ItemName,
    RoleSwitchIcon,
} from './NavBarStyle';

import { IoIosArrowForward } from 'react-icons/io';

import { HamburgerMenuItems } from "components/navbar/HamburgerMenu.data"
import HamburgerSubMenu from './HamburgerSubMenu';

import { pagesInfo } from 'utils/pagesInfo';

import { createLogout } from "redux/auth/auth.request"

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setLocals } from 'redux/local/local.slice';
import { userRoles } from 'redux/auth/auth.const';
import { localsConst } from 'redux/local/local.const';

const HamburgerMenu = ({ setIsMediumHamburger, visible = true }) => {
    const { locals } = useAppState((state) => state.local)
    const { session } = useAppState(s => s.auth)
    const { user } = useAppState((s) => s.user)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const [activeMenuItem, setActiveMenuItem] = useState(null);

    const DASHBOARD = ((locals?.userRole === userRoles.STUDENT.value) ? "Business" : (locals?.userRole === userRoles.TUTOR.value) ? "Default" : "") + " Dashboard"
    const DASHBOARDTooltip = (locals?.userRole === userRoles.STUDENT.value ? `Go to ${userRoles.TUTOR.label}` : (locals?.userRole === userRoles.TUTOR.value) ? `Go to ${userRoles.STUDENT.label}` : "") + " Dashboard"

    const handleOnClickItem = (dataItem) => {
        if (dataItem.name === "Log Out") {
            dispatcher(createLogout())
            setIsMediumHamburger(false)
            return;
        }
        if (!dataItem?.subMenu || dataItem?.subMenu?.length === 0) {
            navigate(dataItem.path)
            setIsMediumHamburger(false)
        }
        if (dataItem?.subMenu?.length > 0) {
            if (!activeMenuItem || (activeMenuItem !== dataItem?.value)) {
                setActiveMenuItem(dataItem?.value)
            } else {
                setActiveMenuItem(null)
            }
        }
    }

    const handleRole = () => {
        let role = userRoles.STUDENT.value === locals.userRole
            ? userRoles.TUTOR.value
            : userRoles.STUDENT.value

        dispatcher(setLocals({
            ...locals,
            [localsConst.USER_ROLE.value]: role
        }))
        navigate(pagesInfo.DASHBOARD.pagePath, { replaceAll: true })
        setIsMediumHamburger(false)
        return;
    }

    const excludeItem = (dataItem) => {
        if (dataItem?.name === "Apply to Teach" && !!user?.user?.policy_permissions?.find(permission => permission?.name === userRoles.TUTOR.value)) {
            return false
        }
        return true
    }

    return (
        <HamburgerMenuContainer>
            <HamburgerHeader>
                {!session?.isLoggedIn &&
                    <HamburgerHeaderContent onClick={() => setIsMediumHamburger(false)}>
                        <HamburgerAuthSection to={pagesInfo.SIGN_UP.pagePath}>
                            <SignUpIcon />
                            {'Sign up'}
                        </HamburgerAuthSection>
                        <HamburgerAuthSection to={pagesInfo.LOG_IN.pagePath}>
                            <LogInIcon />
                            {'Log in'}
                        </HamburgerAuthSection>
                    </HamburgerHeaderContent>
                }
                {session?.isLoggedIn &&
                    <Item
                        className={"py-2 px-3 flex items-center gap-3"}
                        onClick={() => {
                            navigate(`${pagesInfo?.SETTINGS?.pagePath}${pagesInfo?.PROFILE?.pagePath}`)
                            setIsMediumHamburger(false)
                        }}
                    >
                        <ProfileImageContainer>
                            <img src={user?.user?.profilePicUrl} alt={"profile"} />
                        </ProfileImageContainer>
                        <ItemName>
                            <div className={"w-full h-full flex flex-col justify-start items-start"}>
                                <span>{user?.user?.firstName + " " + user?.user?.lastName.charAt(0)}</span>
                                <span className={"text-xs text-primary-main"}>{"My Profile"}</span>
                            </div>
                        </ItemName>
                    </Item>
                }
            </HamburgerHeader>

            <hr />

            <HamburgerContent>

                <HamburgerFeatured onClick={() => setIsMediumHamburger(false)}>
                    <HamburgerFeaturedLink to={pagesInfo.SEGMENTS.pagePath}>
                        <CategoryIcon />
                        {'Categories'}
                    </HamburgerFeaturedLink>
                    {!session?.isLoggedIn && (
                        <HamburgerFeaturedLink to={pagesInfo.TEACH_WITH_US.pagePath}>
                            <TeachWithUsIcon />
                            {'Teach With Us'}
                        </HamburgerFeaturedLink>
                    )}
                    <HamburgerFeaturedLink to={pagesInfo.FIND_COURSES.pagePath}>
                        <FindCoursesIcon />
                        {'Find Classes'}
                    </HamburgerFeaturedLink>
                    <HamburgerFeaturedLink to={pagesInfo.FIND_TUTORS.pagePath}>
                        <FindTeacherIcon />
                        {'Find Tutors'}
                    </HamburgerFeaturedLink>
                </HamburgerFeatured>

                <hr />

                {session?.isLoggedIn &&
                    <HamburgerMenuContent>

                        {!!locals && !!user?.user?.policy_permissions?.find(permission => permission?.name === userRoles.TUTOR.value) && (
                            <ToolTipView content={DASHBOARDTooltip} disabled={false}>
                                <Item onClick={handleRole}>
                                    <ItemIcon>
                                        <RoleSwitchIcon />
                                    </ItemIcon>
                                    <ItemName>
                                        {DASHBOARD}
                                    </ItemName>
                                </Item>
                            </ToolTipView>
                        )}

                        {HamburgerMenuItems?.map((dataItem, index) => dataItem.roles.includes(locals.userRole) && excludeItem(dataItem) && (
                            <>
                                <ToolTipView content={dataItem?.name}>
                                    <Item key={index} onClick={() => handleOnClickItem(dataItem)}>
                                        <ItemIcon>
                                            <dataItem.icon />
                                        </ItemIcon>
                                        <ItemName>
                                            {dataItem.name}
                                        </ItemName>
                                        {(dataItem?.subMenu?.length > 0) &&
                                            <div className={"pl-5 flex justify-end"}>
                                                <IoIosArrowForward className={cn(
                                                    "font-bold text-2xl text-text-600 transition ease-in-out delay-50 duration-100",
                                                    (activeMenuItem && (activeMenuItem === dataItem?.value)) && "rotate-90",
                                                    (!activeMenuItem || (activeMenuItem && (activeMenuItem !== dataItem?.value))) && "rotate-0",
                                                )}
                                                />
                                            </div>
                                        }
                                    </Item>
                                </ToolTipView>
                                {((dataItem?.subMenu?.length > 0) && (activeMenuItem === dataItem?.value)) &&
                                    <>
                                        {dataItem?.subMenu?.map((menuItem, index) => menuItem?.roles?.includes(locals.userRole) && (
                                            <HamburgerSubMenu
                                                key={index}
                                                menuItem={menuItem}
                                                setIsMediumHamburger={setIsMediumHamburger}
                                            />
                                        ))}
                                    </>
                                }
                            </>
                        ))}

                    </HamburgerMenuContent>
                }
            </HamburgerContent>
        </HamburgerMenuContainer>
    )
}

export default HamburgerMenu;