import { appointmentPriceModelEnum, locationLabelsEnum, locationPhoneCallTypeEnum, locationTypeEnum, modifyAppointmentDetailPayload } from "redux/appointment/appointment.const"

import { validateMobileNo } from "utils/validation-functions";

export const searchParamsInfo = {
    action: {
        key: "action"
    },
    appointment: {
        key: "appointment"
    },
    price: {
        key: "price"
    },
    sessions: {
        key: "sessions"
    },
    page: {
        key: "page"
    },
    sessionId: {
        key: "sessionId"
    }
}

export const sessionDurationConst = {
    THIRTY: {
        label: "30 min",
        value: 30
    },
    FORTY_FIVE: {
        label: "45 min",
        value: 45
    },
    SIXTY: {
        label: "60 min",
        value: 60
    },
    CUSTOM: {
        label: "Custom",
        value: "custom"
    }
}

export const appointmentTabConst = {
    EDIT: {
        label: "Edit",
        value: "edit"
    },
    PREVIEW: {
        label: "Preview",
        value: "preview"
    }
}

export const setAppointmentDetailPayload = (appointmentDetail) => {
    let payload = { ...modifyAppointmentDetailPayload }
    if (appointmentDetail?.id) {
        payload["id"] = appointmentDetail?.id
    }
    if (appointmentDetail?.slug) {
        payload["slug"] = appointmentDetail?.slug
    }
    if (appointmentDetail?.title) {
        payload["title"] = appointmentDetail?.title
    }
    if (appointmentDetail?.subtitle) {
        payload["subtitle"] = appointmentDetail?.subtitle
    }
    if (appointmentDetail?.appointment_availability?.duration) {
        payload["duration"] = appointmentDetail?.appointment_availability?.duration
    }
    if (appointmentDetail?.appointment_price?.price) {
        payload["price"] = appointmentDetail?.appointment_price?.price
    }
    if (appointmentDetail?.appointment_price?.price_model) {
        payload["price_model"] = appointmentDetail?.appointment_price?.price_model
    }
    if (appointmentDetail?.appointment_price?.discount_pct) {
        payload["discount_pct"] = appointmentDetail?.appointment_price?.discount_pct
    }
    if (appointmentDetail?.status) {
        payload["status"] = appointmentDetail?.status
    }
    if (appointmentDetail?.description) {
        payload["description"] = appointmentDetail?.description
    }
    if (appointmentDetail?.appointment_availability?.availability) {
        payload["availability"] = appointmentDetail?.appointment_availability?.availability
    }
    if (appointmentDetail?.user) {
        payload["user"] = appointmentDetail?.user
    }
    if (appointmentDetail?.appointment_price?.master_currency) {
        payload["master_currency"] = appointmentDetail?.appointment_price?.master_currency
    }
    if (appointmentDetail?.visibility) {
        payload["visibility"] = appointmentDetail?.visibility
    }
    if (appointmentDetail?.instant_session_schedule) {
        payload["instant_session_schedule"] = appointmentDetail?.instant_session_schedule
    }

    return payload;
}

export const validateAppointmentDetailPayload = (responseData, localPayload) => {
    return (!localPayload?.title || (localPayload?.title?.length < 10) || (localPayload?.subtitle && localPayload?.subtitle?.length < 10) || !localPayload?.duration || (localPayload?.duration < 30)) || !localPayload?.availability?.id
        || (responseData && (JSON.stringify({
            title: responseData?.title,
            subtitle: responseData?.subtitle,
            duration: responseData?.appointment_availability?.duration,
            availabilityId: responseData?.appointment_availability?.availability?.id,
            description: responseData?.description,
            visibility: responseData?.visibility,
            instant_session_schedule: responseData?.instant_session_schedule
        }) === JSON.stringify({
            title: localPayload?.title,
            subtitle: localPayload?.subtitle,
            duration: localPayload?.duration,
            availabilityId: localPayload?.availability?.id,
            description: localPayload?.description,
            visibility: localPayload?.visibility,
            instant_session_schedule: localPayload?.instant_session_schedule
        })))
}

export const validateAppointmentPricePayload = (responseData, localPayload) => {
    return !localPayload?.price_model || !localPayload?.master_currency?.code || ([appointmentPriceModelEnum.FLAT_FEE.value]?.includes(localPayload?.price_model) && (!localPayload?.price || (localPayload?.discount_pct === 100)))
        || (responseData && (JSON.stringify({
            price_model: responseData?.appointment_price?.price_model,
            master_currency: responseData?.appointment_price?.master_currency,
            price: responseData?.appointment_price?.price || 0,
            discount_pct: responseData?.appointment_price?.discount_pct || 0
        }) === JSON.stringify({
            price_model: localPayload?.price_model,
            master_currency: localPayload?.master_currency,
            price: localPayload?.price || 0,
            discount_pct: localPayload?.discount_pct || 0
        })))
}

export const locationsConst = {
    [locationTypeEnum.custom.key]: {
        label: locationTypeEnum.custom.label,
        value: locationTypeEnum.custom.key,
        icon: locationTypeEnum.custom.icon,
        default: true,
        isWebConferencingEnabled: false
    },
    [locationTypeEnum.in_person_meeting.key]: {
        label: locationTypeEnum.in_person_meeting.label,
        value: locationTypeEnum.in_person_meeting.key,
        icon: locationTypeEnum.in_person_meeting.icon,
        default: true,
        isWebConferencingEnabled: false
    },
    [locationTypeEnum.phone_call.key]: {
        label: locationTypeEnum.phone_call.label,
        value: locationTypeEnum.phone_call.key,
        icon: locationTypeEnum.phone_call.icon,
        default: true,
        isWebConferencingEnabled: false
    },
    [locationTypeEnum.invitee.key]: {
        label: locationTypeEnum.invitee.label,
        value: locationTypeEnum.invitee.key,
        icon: locationTypeEnum.invitee.icon,
        default: false,
        isWebConferencingEnabled: false
    },
    [locationTypeEnum.google_meet.key]: {
        label: locationTypeEnum.google_meet.label,
        value: locationTypeEnum.google_meet.key,
        icon: locationTypeEnum.google_meet.icon,
        default: false,
        isWebConferencingEnabled: true
    },
    [locationTypeEnum.edulyte_meet.key]: {
        label: locationTypeEnum.edulyte_meet.label,
        value: locationTypeEnum.edulyte_meet.key,
        icon: locationTypeEnum.edulyte_meet.icon,
        default: false,
        isWebConferencingEnabled: true
    }
}

export const inPersonMeetingInputConst = {
    STREET_LINE_1: {
        label: "Street address line 1 *",
        value: "street_line_1"
    },
    STREET_LINE_2: {
        label: "Street address line 2",
        value: "street_line_2"
    },
    STATE: {
        label: "State",
        value: "state"
    },
    CITY: {
        label: "City",
        value: "city"
    },
    POST_OFFICE: {
        label: "Po box",
        value: "post_office"
    },
    ZIP: {
        label: "Zip",
        value: "zip"
    },
}

export const locationInputLengths = {
    street_line_1: {
        minLength: 10,
        maxLength: 120
    },
    street_line_2: {
        minLength: 10,
        maxLength: 120
    },
    state: {
        minLength: 2,
        maxLength: 32
    },
    city: {
        minLength: 2,
        maxLength: 32
    },
    post_office: {
        minLength: 2,
        maxLength: 32
    },
    zip: {
        minLength: 4,
        maxLength: 8
    },
    label: {
        minLength: 2,
        maxLength: 16
    },
    custom: {
        minLength: 10,
        maxLength: 500
    }
}
export const initialLocationPayload = {
    id: null,
    appointment_id: null,
    location_type: null,
    phone_call: {
        location_phone_call_type: locationPhoneCallTypeEnum.i_will_call.key,
        country: {
            masterCountryId: 0,
            country: "",
            countryDomain: "",
            isdCode: ""
        },
        phone_number: ""
    },
    in_person_meeting: {
        street_line_1: "",
        street_line_2: "",
        city: "",
        post_office: "",
        state: "",
        country: {
            masterCountryId: 0,
            country: "",
            countryDomain: "",
            isdCode: ""
        },
        zip: "",
        label: locationLabelsEnum.home.key,
    },
    custom: {
        custom_text: ""
    },
    in_person_label_text: "",
    isAdditionInfoEnabled: false,
    info: ""
}

export const setLocationDetailPayload = (locationDetail, countryList = []) => {
    let requestPayload = { ...initialLocationPayload }

    if (locationDetail?.id) {
        requestPayload["id"] = locationDetail?.id
    }
    if (locationDetail?.appointment_id) {
        requestPayload["appointment_id"] = locationDetail?.appointment_id
    }
    if (locationDetail?.location_type) {
        requestPayload["location_type"] = locationsConst[locationDetail?.location_type]
    }
    if (locationDetail?.custom) {
        requestPayload["custom"] = locationDetail?.custom
    }
    if (![locationLabelsEnum.home.key, locationLabelsEnum.work.key]?.includes(locationDetail?.in_person_meeting?.label)) {
        requestPayload["in_person_label_text"] = locationDetail?.in_person_meeting?.label
    }
    if (locationDetail?.in_person_meeting) {
        requestPayload["in_person_meeting"] = {
            ...locationDetail?.in_person_meeting,
            label: [locationLabelsEnum.home.key, locationLabelsEnum.work.key]?.includes(locationDetail?.in_person_meeting?.label) ? locationDetail?.in_person_meeting?.label : locationLabelsEnum.custom.key,
            country: countryList?.find((country) => country?.country === locationDetail?.in_person_meeting?.country)
        }
    }
    if (locationDetail?.phone_call) {
        if ([locationPhoneCallTypeEnum?.call_me?.key]?.includes(locationDetail?.phone_call?.location_phone_call_type)) {
            requestPayload["phone_call"] = {
                location_phone_call_type: locationDetail?.phone_call?.location_phone_call_type,
                country: countryList?.find((country) => country?.isdCode === locationDetail?.phone_call?.isd_code),
                phone_number: locationDetail?.phone_call?.phone_number
            }
        } else {
            requestPayload["phone_call"] = {
                ...initialLocationPayload.phone_call,
                location_phone_call_type: locationDetail?.phone_call?.location_phone_call_type,
            }
        }
    }
    if (locationDetail?.google_meet) {
    }
    if (locationDetail?.info) {
        requestPayload["isAdditionInfoEnabled"] = true
        requestPayload["info"] = locationDetail?.info
    }

    return requestPayload;
}

function deepEqual(obj1, obj2) {
    if (obj1 === obj2) return true;

    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
        return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (let key of keys1) {
        const val1 = obj1[key];
        const val2 = obj2[key];
        if (!deepEqual(val1, val2)) return false;
    }

    return true;
}

export const isSimilarOrInValidLocationDetail = (responseData, localPayload) => {
    let isDataSimilarOrInvalid = false
    if (!localPayload?.location_type?.value) {
        isDataSimilarOrInvalid = true
        return isDataSimilarOrInvalid;
    }
    if ([locationTypeEnum.custom.key]?.includes(localPayload?.location_type?.value)) {
        if (!localPayload?.custom?.custom_text || (localPayload?.custom?.custom_text?.length < locationInputLengths.custom.minLength)) {
            isDataSimilarOrInvalid = true
            return isDataSimilarOrInvalid;
        }
    }
    if ([locationTypeEnum.in_person_meeting.key]?.includes(localPayload?.location_type?.value)) {
        if (!localPayload?.in_person_meeting?.street_line_1 || (localPayload?.in_person_meeting?.street_line_1?.length < locationInputLengths.street_line_1.minLength)) {
            isDataSimilarOrInvalid = true
            return isDataSimilarOrInvalid;
        }
        if (!!localPayload?.in_person_meeting?.street_line_2 && (localPayload?.in_person_meeting?.street_line_2?.length < locationInputLengths.street_line_2.minLength)) {
            isDataSimilarOrInvalid = true
            return isDataSimilarOrInvalid;
        }
        if (!!localPayload?.in_person_meeting?.state && (localPayload?.in_person_meeting?.state?.length < locationInputLengths.state.minLength)) {
            isDataSimilarOrInvalid = true
            return isDataSimilarOrInvalid;
        }
        if (!!localPayload?.in_person_meeting?.city && (localPayload?.in_person_meeting?.city?.length < locationInputLengths.city.minLength)) {
            isDataSimilarOrInvalid = true
            return isDataSimilarOrInvalid;
        }
        if (!!localPayload?.in_person_meeting?.zip && (localPayload?.in_person_meeting?.zip?.length < locationInputLengths.zip.minLength)) {
            isDataSimilarOrInvalid = true
            return isDataSimilarOrInvalid;
        }
        if (!!localPayload?.in_person_meeting?.post_office && (localPayload?.in_person_meeting?.post_office?.length < locationInputLengths.post_office.minLength)) {
            isDataSimilarOrInvalid = true
            return isDataSimilarOrInvalid;
        }
        if (!!localPayload?.in_person_label_text && (localPayload?.in_person_meeting?.label?.length < locationInputLengths.label.minLength)) {
            isDataSimilarOrInvalid = true
            return isDataSimilarOrInvalid;
        }
    }
    if ([locationTypeEnum.phone_call.key]?.includes(localPayload?.location_type?.value)) {
        if ([locationPhoneCallTypeEnum.call_me.key]?.includes(localPayload?.phone_call?.location_phone_call_type)) {
            if (!localPayload?.phone_call?.country?.country) {
                isDataSimilarOrInvalid = true
                return isDataSimilarOrInvalid;
            }
            if (!localPayload?.phone_call?.phone_number || !validateMobileNo(localPayload?.phone_call?.phone_number)) {
                isDataSimilarOrInvalid = true
                return isDataSimilarOrInvalid;
            }
        }
    }

    if (responseData?.isEditEnabled && deepEqual(responseData, localPayload)) {
        isDataSimilarOrInvalid = true
        return isDataSimilarOrInvalid;
    }

    return isDataSimilarOrInvalid;
}

export const validateAppointmentLocationPayload = async (locationPayload) => {
    let requestPayload = {};
    let errorMsg = "";
    if (!locationPayload?.location_type?.value) {
        errorMsg = "Please select location type"
        return { requestPayload, errorMsg }
    }
    if ([locationTypeEnum.custom.key]?.includes(locationPayload?.location_type?.value) && !locationPayload?.custom?.custom_text) {
        errorMsg = "Please enter description"
        return { requestPayload, errorMsg }
    }
    if (locationPayload?.location_type?.value) {
        requestPayload["location_type"] = locationPayload?.location_type?.value
    }
    if ([locationTypeEnum.custom.key]?.includes(locationPayload?.location_type?.value)) {
        requestPayload["custom"] = locationPayload?.custom
    }
    if ([locationTypeEnum.in_person_meeting.key]?.includes(locationPayload?.location_type?.value)) {
        requestPayload["in_person_meeting"] = {
            ...locationPayload?.in_person_meeting,
            label: [locationLabelsEnum.custom.key]?.includes(locationPayload?.in_person_meeting?.label)
                ? locationPayload?.in_person_label_text
                : locationPayload?.in_person_meeting?.label,
            country: locationPayload?.in_person_meeting?.country?.country,
        }
    }
    if ([locationTypeEnum.phone_call.key]?.includes(locationPayload?.location_type?.value)) {
        if ([locationPhoneCallTypeEnum.call_me.key]?.includes(locationPayload?.phone_call?.location_phone_call_type)) {
            requestPayload["phone_call"] = {
                location_phone_call_type: locationPayload?.phone_call?.location_phone_call_type,
                phone_number: `${locationPayload?.phone_call?.country?.isdCode}-${locationPayload?.phone_call?.phone_number}`
            }
        } else {
            requestPayload["phone_call"] = {
                location_phone_call_type: locationPayload?.phone_call?.location_phone_call_type,
            }
        }
    }
    if (locationPayload?.info) {
        requestPayload["info"] = locationPayload?.info
    }

    return { requestPayload, errorMsg }
}

export const appointmentDetailTooltips = {
    title: "Enter the name of your appointment or event",
    subtitle: "Add a brief description or tagline for your appointment.",
    duration: "Specify the length of each appointment or session",
    location: 'Use the "Location" field to specify how and where both parties will connect at the scheduled time.',
    schedule: "Select an existing schedule or create a new one.",
    description: "Provide additional details or special instructions for attendees",
    visibility: "Set visibility: Public appointments will show on your profile. Private appointments can be shared via link",
    autoConfirmSessions: "This option confirms all requests automatically. Otherwise, you need to confirm each request manually.",
    price: "Set the cost for each appointment or session, if applicable.",
    bookedSessions: "View and manage your appointments or sessions"
}