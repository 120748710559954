import React from 'react'

const PageHeading = ({ headerText = "", tooltipText = null }) => {

    return (
        <>
            <div className={"w-full px-3 py-2 bg-divider-medium rounded-lg"}>
                <span className={"font-bodyPri font-bold text-text-900 text-lg tracking-wide"}>
                    {headerText}
                </span>
            </div>
            {!!tooltipText &&
                <div className={"w-full text-center font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                    {tooltipText}
                </div>
            }
        </>
    )
}

export default PageHeading;