import { toast } from "react-toastify";

import courseService from 'redux/course/course.services';
import {

    // cohort course imports
    setInstructorCourse,
    setModifyCourse,

    setCourseTags,
    setAddCourseTag,
    setModifyCourseTagOrderList,
    setDestroyCourseTag,

    setCourseLanguages,
    setAddCourseLanguage,
    setModifyCourseLanguageOrderList,
    setDestroyCourseLanguage,

    setCourseProfs,
    setAddCourseProf,
    setModifyCourseProfOrderList,
    setDestroyCourseProf,

    setCourseFaqs,
    setAddCourseFaq,
    setModifyCourseFaq,
    setModifyCourseFaqOrderList,
    setDestroyCourseFaq,

    //cohort course imports end

    // new course
    setUserCourseListLoading,
    setUserCourseListData,
    setUserCourseListMessage,

    setUserFavCourseListLoading,
    setUserFavCourseListData,
    setUserFavCourseListMessage,

    setAddCourseFavDetailLoading,
    setAddCourseFavDetailMessage,

    setDeleteCourseFavDetailLoading,
    setDeleteCourseFavDetailMessage,

    setPublicCourseListLoading,
    setPublicCourseListData,
    setPublicCourseListMessage,

    setPublicCourseDetailLoading,
    setPublicCourseDetailData,
    setPublicCourseDetailMessage,

    setSelectedPublicCourseDetailLoading,
    setSelectedPublicCourseDetail,
    setSelectedPublicCourseDetailMessage,

    setPublicTutorCourseListLoading,
    setPublicTutorCourseListData,
    setPublicTutorCourseListMessage,

    setCourseTemplateListLoading,
    setCourseTemplateList,
    setCourseTemplateListErrorMsg,

    setCourseTemplateDetailLoading,
    setCourseTemplateDetail,
    setCourseTemplateDetailErrorMsg,

    setCourseDetailLoading,
    setCourseDetail,
    setCourseDetailErrorMsg,

    setAddCourseDetailLoading,
    setAddCourseDetail,
    setAddCourseDetailErrorMsg,

    setCourseTagListLoading,
    setCourseTagList,
    setCourseTagListErrorMsg,

    setAddCourseTagDetailLoading,
    setAddCourseTagDetail,
    setAddCourseTagDetailErrorMsg,

    setCourseTutorListLoading,
    setCourseTutorList,
    setCourseTutorListErrorMsg,

    setCourseTutorDetailLoading,
    setCourseTutorDetail,
    setCourseTutorDetailErrorMsg,

    setCourseLanguageListLoading,
    setCourseLanguageList,
    setCourseLanguageListErrorMsg,

    setAddCourseLanguageDetailLoading,
    setAddCourseLanguageDetail,
    setAddCourseLanguageDetailErrorMsg,

    setCourseProfListLoading,
    setCourseProfList,
    setCourseProfListErrorMsg,

    setAddCourseProfDetailLoading,
    setAddCourseProfDetail,
    setAddCourseProfDetailErrorMsg,

    setCourseAvailDetailLoading,
    setCourseAvailDetail,
    setCourseAvailErrorMsg,

    setAddCourseAvailDetailLoading,
    setAddCourseAvailDetail,
    setAddCourseAvailDetailErrorMsg,

    setCoursePriceDetailLoading,
    setCoursePriceDetail,
    setCoursePriceDetailErrorMsg,

    setAddCoursePriceDetailLoading,
    setAddCoursePriceDetail,
    setAddCoursePriceDetailErrorMsg,

    setCoursePreviewDetailLoading,
    setCoursePreviewDetailData,
    setCoursePreviewDetailMessage,

    setTutorCourseListLoading,
    setTutorCourseListData,
    setTutorCourseListMessage,

    setTutorCourseDetailLoading,
    setTutorCourseDetailData,
    setTutorCourseDetailMessage,

    setCloneCourseDetailLoading,
    setCloneCourseDetailData,
    setCloneCourseDetailMessage,

    setNextCourseSessionDetailLoading,
    setNextCourseSessionDetailData,
    setNextCourseSessionDetailMessage,

    setCourseCommentListLoading,
    setCourseCommentListData,
    setCourseCommentListMessage,

    setAddCourseCommentDetailLoading,
    setAddCourseCommentDetailMessage,
    setParticipantCohortCourseDetail,
} from 'redux/course/course.slice';

import { getSessionCourseList } from "redux/session/session.request";

import { setUser } from "redux/user/user.slice";

import { timeZone } from "utils/dateTime.utils";

// cohort courses

// get instructor course
export const getInstructorCourse = (id, user_id) => async (dispatch) => {
    dispatch(setInstructorCourse({ isLoading: true }))

    try {
        const requestData = {
            params: { id: id, user_id: user_id }
        }
        const response = await courseService.getInstructorCourse(requestData)
        if (response.status === 200) {
            dispatch(setInstructorCourse({ result: response?.data?.data?.result }))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setInstructorCourse({ error: error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!" }))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
    } finally {
        dispatch(setInstructorCourse({ isLoading: false }))
    }
}

// update instructor course
export const updateCourse = (id, body) => async (dispatch) => {
    dispatch(setModifyCourse({ isLoading: true }))

    try {
        const requestData = {
            params: { id: id },
            body: body
        }
        const response = await courseService.updateCourse(requestData)
        if (response.status === 200) {
            dispatch(setInstructorCourse({ result: response?.data?.data?.result }))
            dispatch(setModifyCourse({ result: response?.data?.data?.result }))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setModifyCourse({ error: error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!" }))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
    } finally {
        dispatch(setModifyCourse({ isLoading: false }))
    }
}

// tag 
// list
export const getCourseTags = (courseId) => async (dispatch) => {
    dispatch(setCourseTags({ isLoading: true }))

    try {
        const requestData = {
            params: { id: courseId }
        }
        const response = await courseService.getCourseTags(requestData)
        if (response.status === 200) {
            dispatch(setCourseTags({ result: response?.data?.data?.result }))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setCourseTags({ error: error.response.data.message || error.response.data.error || "Something went wrong!" }))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setCourseTags({ isLoading: false }))
    }
}

// create tag detail
export const createCourseTag = (courseId, body) => async (dispatch, getState) => {
    dispatch(setAddCourseTag({ isLoading: true }))

    try {
        const { courseTags } = getState().course
        const requestData = {
            params: { id: courseId },
            body: body
        }
        const response = await courseService.createCourseTag(requestData)
        if (response.status === 201) {
            dispatch(setCourseTags({
                ...courseTags,
                result: [...courseTags?.result, response.data.data?.result]
            }))
            toast.success(response.data.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setAddCourseTag({ error: error.response.data.message || error.response.data.error || "Something went wrong!" }))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddCourseTag({ isLoading: false }))
    }
}

// update tag list order
export const updateCourseTagOrderList = (courseId, body, query) => async (dispatch, getState) => {
    dispatch(setModifyCourseTagOrderList({ isLoading: true }))

    try {
        const { courseTags } = getState().course
        const requestData = {
            params: { id: courseId },
            body: body,
            query: query
        }
        const response = await courseService.updateCourseTagOrderList(requestData)
        if (response.status === 200) {
            dispatch(setCourseTags({ result: response.data.data?.result }))
            toast.success(response.data.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setModifyCourseTagOrderList({ error: error.response.data.message || error.response.data.error || "Something went wrong!" }))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setModifyCourseTagOrderList({ isLoading: false }))
    }
}

// delete tag detail
export const deleteCourseTag = (courseTagId) => async (dispatch, getState) => {
    dispatch(setDestroyCourseTag({ isLoading: true }))

    try {
        const { courseTags } = getState().course
        const requestData = {
            params: { courseTagId: courseTagId }
        }
        const response = await courseService.deleteCourseTag(requestData)
        if (response.status === 204) {
            dispatch(setCourseTags({
                ...courseTags,
                result: courseTags?.result?.filter((tagItem) => tagItem?.id != courseTagId)
            }))
            toast.success(response.data.data.message || "Delete Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setDestroyCourseTag({ error: error.response.data.message || error.response.data.error || "Something went wrong!" }))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setDestroyCourseTag({ isLoading: false }))
    }
}

// course language
// list
export const getCourseLanguages = (courseId) => async (dispatch) => {
    dispatch(setCourseLanguages({ isLoading: true }))

    try {
        const requestData = {
            params: { id: courseId }
        }
        const response = await courseService.getCourseLanguages(requestData)
        if (response.status === 200) {
            dispatch(setCourseLanguages({ result: response?.data?.data?.result }))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setCourseLanguages({ error: error.response.data.message || error.response.data.error || "Something went wrong!" }))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setCourseLanguages({ isLoading: false }))
    }
}

// create
export const createCourseLanguage = (courseId, body) => async (dispatch, getState) => {
    dispatch(setAddCourseLanguage({ isLoading: true }))

    try {
        const { courseLanguages } = getState().course
        const requestData = {
            params: { id: courseId },
            body: body
        }
        const response = await courseService.createCourseLanguage(requestData)
        if (response.status === 201) {
            dispatch(setCourseLanguages({
                ...courseLanguages,
                result: [...courseLanguages?.result, response.data.data?.result]
            }))
            toast.success(response.data.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setAddCourseLanguage({ error: error.response.data.message || error.response.data.error || "Something went wrong!" }))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddCourseLanguage({ isLoading: false }))
    }
}

// update language list order
export const updateCourseLanguageOrderList = (courseId, body, query) => async (dispatch) => {
    dispatch(setModifyCourseLanguageOrderList({ isLoading: true }))

    try {
        const requestData = {
            params: { id: courseId },
            body: body,
            query: query
        }
        const response = await courseService.updateCourseLanguageOrderList(requestData)
        if (response.status === 200) {
            dispatch(setCourseLanguages({ result: response.data.data?.result }))
            toast.success(response.data.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setModifyCourseLanguageOrderList({ error: error.response.data.message || error.response.data.error || "Something went wrong!" }))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setModifyCourseLanguageOrderList({ isLoading: false }))
    }
}

// delete language detail
export const deleteCourseLanguage = (courseLanguageId) => async (dispatch, getState) => {
    dispatch(setDestroyCourseLanguage({ isLoading: true }))

    try {
        const { courseLanguages } = getState().course
        const requestData = {
            params: { courseLanguageId: courseLanguageId }
        }
        const response = await courseService.deleteCourseLanguage(requestData)
        if (response.status === 204) {
            dispatch(setCourseLanguages({
                ...courseLanguages,
                result: courseLanguages?.result?.filter((LanguageItem) => LanguageItem?.id != courseLanguageId)
            }))
            toast.success(response.data.data.message || "Delete Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setDestroyCourseLanguage({ error: error.response.data.message || error.response.data.error || "Something went wrong!" }))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setDestroyCourseLanguage({ isLoading: false }))
    }
}

// course prof
// list
export const getCourseProfs = (courseId) => async (dispatch) => {
    dispatch(setCourseProfs({ isLoading: true }))

    try {
        const requestData = {
            params: { id: courseId }
        }
        const response = await courseService.getCourseProfs(requestData)
        if (response.status === 200) {
            dispatch(setCourseProfs({ result: response?.data?.data?.result }))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setCourseProfs({ error: error.response.data.message || error.response.data.error || "Something went wrong!" }))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setCourseProfs({ isLoading: false }))
    }
}

// create
export const createCourseProf = (courseId, body) => async (dispatch, getState) => {
    dispatch(setAddCourseProf({ isLoading: true }))

    try {
        const { courseProfs } = getState().course
        const requestData = {
            params: { id: courseId },
            body: body
        }
        const response = await courseService.createCourseProf(requestData)
        if (response.status === 201) {
            dispatch(setCourseProfs({
                ...courseProfs,
                result: [...courseProfs?.result, response.data.data?.result]
            }))
            toast.success(response.data.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setAddCourseProf({ error: error.response.data.message || error.response.data.error || "Something went wrong!" }))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddCourseProf({ isLoading: false }))
    }
}

// update
export const updateCourseProfOrderList = (courseId, body, query) => async (dispatch, getState) => {
    dispatch(setModifyCourseProfOrderList({ isLoading: true }))

    try {
        const requestData = {
            params: { id: courseId },
            body: body,
            query: query
        }
        const response = await courseService.updateCourseProfOrderList(requestData)
        if (response.status === 200) {
            dispatch(setCourseProfs({ result: response.data.data?.result }))
            toast.success(response.data.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setModifyCourseProfOrderList({ error: error.response.data.message || error.response.data.error || "Something went wrong!" }))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setModifyCourseProfOrderList({ isLoading: false }))
    }
}

// delete
export const deleteCourseProf = (courseProfId) => async (dispatch, getState) => {
    dispatch(setDestroyCourseProf({ isLoading: true }))

    try {
        const { courseProfs } = getState().course
        const requestData = {
            params: { courseProfId: courseProfId }
        }
        const response = await courseService.deleteCourseProf(requestData)
        if (response.status === 204) {
            dispatch(setCourseProfs({
                ...courseProfs,
                result: courseProfs?.result?.filter((ProfItem) => ProfItem?.id != courseProfId)
            }))
            toast.success(response.data.data.message || "Delete Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setDestroyCourseProf({ error: error.response.data.message || error.response.data.error || "Something went wrong!" }))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setDestroyCourseProf({ isLoading: false }))
    }
}

// course faq
// list
export const getCourseFaqs = (courseId) => async (dispatch) => {
    dispatch(setCourseFaqs({ isLoading: true }))

    try {
        const requestData = {
            params: { id: courseId }
        }
        const response = await courseService.getCourseFaqs(requestData)
        if (response.status === 200) {
            dispatch(setCourseFaqs({ result: response?.data?.data?.result }))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setCourseFaqs({ error: error.response.data.message || error.response.data.error || "Something went wrong!" }))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setCourseFaqs({ isLoading: false }))
    }
}

// create
export const createCourseFaq = (courseId, body) => async (dispatch, getState) => {
    dispatch(setAddCourseFaq({ isLoading: true }))

    try {
        const { courseFaqs } = getState().course
        const requestData = {
            params: { id: courseId },
            body: body
        }
        const response = await courseService.createCourseFaq(requestData)
        if (response.status === 201) {
            dispatch(setCourseFaqs({
                ...courseFaqs,
                result: [...courseFaqs?.result, response.data.data?.result]
            }))
            toast.success(response.data.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setAddCourseFaq({ error: error.response.data.message || error.response.data.error || "Something went wrong!" }))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setAddCourseFaq({ isLoading: false }))
    }
}

// update faq detail
export const updateCourseFaq = (courseId, courseFaqId, body) => async (dispatch, getState) => {
    dispatch(setModifyCourseFaq({ isLoading: true }))

    try {
        const { courseFaqs } = getState().course
        const requestData = {
            params: { id: courseId, courseFaqId: courseFaqId },
            body: body
        }
        const response = await courseService.updateCourseFaq(requestData)
        if (response.status === 200) {
            dispatch(setCourseFaqs({
                ...courseFaqs,
                result: courseFaqs?.result?.map((faqItem) => (
                    (faqItem?.id === response?.data.data?.result?.id)
                        ? response?.data.data?.result
                        : faqItem
                ))
            }))
            toast.success(response.data.data.message || "Delete Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setModifyCourseFaq({ error: error.response.data.message || error.response.data.error || "Something went wrong!" }))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setModifyCourseFaq({ isLoading: false }))
    }
}

// update order list
export const updateCourseFaqOrderList = (courseId, body, query) => async (dispatch, getState) => {
    dispatch(setModifyCourseFaqOrderList({ isLoading: true }))

    try {
        const requestData = {
            params: { id: courseId },
            body: body,
            query: query
        }
        const response = await courseService.updateCourseFaqOrderList(requestData)
        if (response.status === 200) {
            dispatch(setCourseFaqs({ result: response.data.data?.result }))
            toast.success(response.data.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setModifyCourseFaqOrderList({ error: error.response.data.message || error.response.data.error || "Something went wrong!" }))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setModifyCourseFaqOrderList({ isLoading: false }))
    }
}

// delete
export const deleteCourseFaq = (courseFaqId) => async (dispatch, getState) => {
    dispatch(setDestroyCourseFaq({ isLoading: true }))

    try {
        const { courseFaqs } = getState().course
        const requestData = {
            params: { courseFaqId: courseFaqId }
        }
        const response = await courseService.deleteCourseFaq(requestData)
        if (response.status === 200) {
            dispatch(setCourseFaqs({
                ...courseFaqs,
                result: courseFaqs?.result?.filter((faqItem) => faqItem?.id != courseFaqId)
            }))
            toast.success(response.data.data.message || "Delete Successfully")
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setDestroyCourseFaq({ error: error.response.data.message || error.response.data.error || "Something went wrong!" }))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setDestroyCourseFaq({ isLoading: false }))
    }
}

// participant course cohort
export const getParticipantCourseCohortDetail = (cohortId, participantId) => async (dispatch) => {
    dispatch(setParticipantCohortCourseDetail({ isLoading: true }))

    try {
        const requestData = {
            params: { cohortId: cohortId, participantId: participantId }
        }
        const response = await courseService.getParticipantCohortCourseDetail(requestData)
        if (response.status === 200) {
            dispatch(setParticipantCohortCourseDetail({ result: response?.data?.data?.result }))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setParticipantCohortCourseDetail({ error: error.response.data.message || error.response.data.error || "Something went wrong!" }))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong!")
    } finally {
        dispatch(setParticipantCohortCourseDetail({ isLoading: false }))
    }
}

// new course request
export const getUserCourseList = (userId, query) => async (dispatch) => {
    dispatch(setUserCourseListLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            query: query
        }
        const response = await courseService.getUserCourseList(requestData)
        if (response.status === 200) {
            dispatch(setUserCourseListData(response?.data?.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setUserCourseListMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
    } finally {
        dispatch(setUserCourseListLoading(false))
    }
}

export const getUserFavoriteCourseList = (userId, query) => async (dispatch) => {
    dispatch(setUserFavCourseListLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            query: query
        }
        const response = await courseService.getUserFavoriteCourseList(requestData)
        if (response.status === 200) {
            dispatch(setUserFavCourseListData(response?.data?.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setUserFavCourseListMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
    } finally {
        dispatch(setUserFavCourseListLoading(false))
    }
}

// fav course
export const createCourseFavoriteDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddCourseFavDetailLoading(true))

    try {
        const { user } = getState().user
        const requestData = {
            body: body
        }
        const response = await courseService.createCourseFavoriteDetail(requestData)
        if (response.status === 200) {
            dispatch(setUser({
                ...user?.user,
                favoriteCourses: [...user?.user?.favoriteCourses, { courseId: response?.data?.data.id }]
            }))
            toast.success(response?.data?.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setAddCourseFavDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
    } finally {
        dispatch(setAddCourseFavDetailLoading(false))
    }
}

// delete fav course
export const deleteCourseFavoriteDetail = (body) => async (dispatch, getState) => {
    dispatch(setDeleteCourseFavDetailLoading(true))

    try {
        const { user } = getState().user
        const requestData = {
            body: body
        }
        const response = await courseService.deleteCourseFavoriteDetail(requestData)
        if (response.status === 200) {
            dispatch(setUser({
                ...user?.user,
                favoriteCourses: user?.user?.favoriteCourses?.filter((course) => course?.courseId !== body.courseId)
            }))
            toast.success(response?.data?.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setDeleteCourseFavDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
    } finally {
        dispatch(setDeleteCourseFavDetailLoading(false))
    }
}

// public course list
export const getPublicCourseList = (query) => async (dispatch) => {
    dispatch(setPublicCourseListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await courseService.getPublicCourseList(requestData)
        if (response.status === 200) {
            dispatch(setPublicCourseListData(response?.data?.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setPublicCourseListMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
    } finally {
        dispatch(setPublicCourseListLoading(false))
    }
}

// get public course detail
export const getPublicCourseDetail = (slug) => async (dispatch) => {
    dispatch(setPublicCourseDetailLoading(true))

    try {
        const requestData = {
            params: { slug: slug }
        }
        const response = await courseService.getPublicCourseDetail(requestData)
        if (response.status === 200) {
            dispatch(setPublicCourseDetailData(response?.data?.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setPublicCourseDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
    } finally {
        dispatch(setPublicCourseDetailLoading(false))
    }
}

// Get Selected Public Course Detail Request
export const getSelectedPublicCourseDetail = (slug) => async (dispatch) => {
    dispatch(setSelectedPublicCourseDetailLoading(true))

    try {
        const requestData = {
            params: { slug: slug }
        }
        const response = await courseService.getPublicCourseDetail(requestData)
        if (response.status === 200) {
            dispatch(setSelectedPublicCourseDetail(response?.data?.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setSelectedPublicCourseDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
    } finally {
        dispatch(setSelectedPublicCourseDetailLoading(false))
    }
}

// get public tutor course list
export const getPublicTutorCourseList = (tutorId, query) => async (dispatch) => {
    dispatch(setPublicTutorCourseListLoading(true))

    try {
        const requestData = {
            params: { tutorId: tutorId },
            query: query
        }
        const response = await courseService.getPublicTutorCourseList(requestData)
        if (response.status === 200) {
            dispatch(setPublicTutorCourseListData(response?.data?.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setPublicTutorCourseListMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
    }
    finally {
        dispatch(setPublicTutorCourseListLoading(false))
    }
}

// get course template list
export const getCourseTemplateList = (query) => async (dispatch) => {
    dispatch(setCourseTemplateListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await courseService.getCourseTemplateList(requestData)
        if (response.status === 200) {
            dispatch(setCourseTemplateList(response?.data?.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setCourseTemplateListErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
    } finally {
        dispatch(setCourseTemplateListLoading(false))
    }
}

// course template detail
export const getCourseTemplateDetail = (courseTemplateId) => async (dispatch) => {
    dispatch(setCourseTemplateDetailLoading(true))

    try {
        const requestData = {
            params: { courseTemplateId: courseTemplateId }
        }
        const response = await courseService.getCourseTemplateDetail(requestData)
        if (response.status === 200) {
            dispatch(setCourseTemplateDetail(response?.data?.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setCourseTemplateDetailErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
    } finally {
        dispatch(setCourseTemplateDetailLoading(false))
    }
}

// get course detail
export const getCourseDetail = (courseId) => async (dispatch) => {
    dispatch(setCourseDetailLoading(true))

    try {
        const requestData = {
            params: { courseId: courseId }
        }
        const response = await courseService.getCourseDetail(requestData)
        if (response.status === 200) {
            dispatch(setCourseDetail(response?.data?.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setCourseDetailErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
    } finally {
        dispatch(setCourseDetailLoading(false))
    }
}

// create course detail
export const createCourseDetail = (body) => async (dispatch) => {
    dispatch(setAddCourseDetailLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await courseService.createCourseDetail(requestData)
        if (response.status === 200) {
            dispatch(setAddCourseDetail(response?.data?.data))
            toast.success(response?.data?.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setAddCourseDetailErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
    } finally {
        dispatch(setAddCourseDetailLoading(false))
    }
}

// update course detail
export const updateCourseDetail = (courseId, body) => async (dispatch) => {
    dispatch(setAddCourseDetailLoading(true))

    try {
        const requestData = {
            params: { courseId: courseId },
            body: body
        }
        const response = await courseService.updateCourseDetail(requestData)
        if (response.status === 200) {
            dispatch(setAddCourseDetail(response?.data?.data))
            dispatch(setCourseDetail(response?.data?.data))
            dispatch(setTutorCourseDetailData(response?.data?.data))
            toast.success(response?.data?.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setAddCourseDetailErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
    } finally {
        dispatch(setAddCourseDetailLoading(false))
    }
}

// delete course detail
export const deleteCourseDetail = (courseId) => async (dispatch) => {
    dispatch(setAddCourseDetailLoading(true))

    try {
        const requestData = {
            params: { courseId: courseId }
        }
        const response = await courseService.deleteCourseDetail(requestData)
        if (response.status === 200) {
            dispatch(setAddCourseDetail(response?.data?.message))
            toast.success(response?.data?.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setAddCourseDetailErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
    } finally {
        dispatch(setAddCourseDetailLoading(false))
    }
}

// get course tag list
export const getCourseTagList = (query) => async (dispatch) => {
    dispatch(setCourseTagListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await courseService.getCourseTagList(requestData)
        if (response.status === 200) {
            dispatch(setCourseTagList(response?.data?.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setCourseTagListErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
    } finally {
        dispatch(setCourseTagListLoading(false))
    }
}

// create course tags
export const createCourseTagDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddCourseTagDetailLoading(true))

    try {
        const { tutorCourseDetail } = getState().course
        const requestData = {
            body: body
        }
        const response = await courseService.createCourseTagDetail(requestData)
        if (response.status === 200) {
            dispatch(setAddCourseTagDetail(response?.data?.data))
            dispatch(getCourseTagList({ course: tutorCourseDetail?.data?.id }))
            toast.success(response?.data?.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setAddCourseTagDetailErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
    } finally {
        dispatch(setAddCourseTagDetailLoading(false))
    }
}

// update course tag list by order
export const updateCourseTagListOrder = (body, query) => async (dispatch, getState) => {
    dispatch(setAddCourseTagDetailLoading(true))

    try {
        const { tutorCourseDetail } = getState().course
        const requestData = {
            body: body,
            query: query
        }
        const response = await courseService.updateCourseTagListOrder(requestData)
        if (response.status === 200) {
            // dispatch(setCourseTagList(response?.data?.data))
            dispatch(getCourseTagList({ course: tutorCourseDetail?.data?.id }))
            toast.success(response?.data?.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setAddCourseTagDetailErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
    } finally {
        dispatch(setAddCourseTagDetailLoading(false))
    }
}

// delete course tag detail
export const deleteCourseTagDetail = (courseTagId) => async (dispatch, getState) => {
    dispatch(setAddCourseTagDetailLoading(true))

    try {
        const { tutorCourseDetail } = getState().course
        const requestData = {
            params: { courseTagId: courseTagId }
        }
        const response = await courseService.deleteCourseTagDetail(requestData)
        if (response.status === 200) {
            dispatch(setAddCourseTagDetail(response?.data?.data))
            dispatch(getCourseTagList({ course: tutorCourseDetail?.data?.id }))
            toast.success(response?.data?.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setAddCourseTagDetailErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
    } finally {
        dispatch(setAddCourseTagDetailLoading(false))
    }
}

// course language list
export const getCourseLanguageList = (query) => async (dispatch) => {
    dispatch(setCourseLanguageListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await courseService.getCourseLanguageList(requestData)
        if (response.status === 200) {
            dispatch(setCourseLanguageList(response?.data?.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setCourseLanguageListErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
    } finally {
        dispatch(setCourseLanguageListLoading(false))
    }
}

// create course language detail
export const createCourseLanguageDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddCourseLanguageDetailLoading(true))

    try {
        const { tutorCourseDetail } = getState().course
        const requestData = {
            body: body
        }
        const response = await courseService.createCourseLanguageDetail(requestData)
        if (response.status === 200) {
            dispatch(setCourseLanguageList(response?.data?.data))
            // dispatch(setAddCourseLanguageDetail(response?.data?.data))
            dispatch(getCourseLanguageList({ course: tutorCourseDetail?.data?.id }))
            toast.success(response?.data?.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setAddCourseLanguageDetailErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
    } finally {
        dispatch(setAddCourseLanguageDetailLoading(false))
    }
}

// update course language list order
export const updateCourseLanguageListOrder = (body, query) => async (dispatch, getState) => {
    dispatch(setAddCourseLanguageDetailLoading(true))

    try {
        const { tutorCourseDetail } = getState().course
        const requestData = {
            body: body,
            query: query
        }
        const response = await courseService.updateCourseLanguageListOrder(requestData)
        if (response.status === 200) {
            dispatch(setCourseLanguageList(response?.data?.data))
            dispatch(getCourseLanguageList({ course: tutorCourseDetail?.data?.id }))
            toast.success(response?.data?.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setAddCourseLanguageDetailErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
    } finally {
        dispatch(setAddCourseLanguageDetailLoading(false))
    }
}

// delete course language 
export const deleteCourseLanguageDetail = (courseLanguageId) => async (dispatch, getState) => {
    dispatch(setAddCourseLanguageDetailLoading(true))

    try {
        const { tutorCourseDetail } = getState().course
        const requestData = {
            params: { courseLanguageId: courseLanguageId }
        }
        const response = await courseService.deleteCourseLanguageDetail(requestData)
        if (response.status === 200) {
            dispatch(setCourseLanguageList(response?.data?.data))
            dispatch(setAddCourseLanguageDetail(response?.data?.data))
            dispatch(getCourseLanguageList({ course: tutorCourseDetail?.data?.id }))
            toast.success(response?.data?.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setAddCourseLanguageDetailErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
    } finally {
        dispatch(setAddCourseLanguageDetailLoading(false))
    }
}

// get course proficiency
export const getCourseProficiencyList = (query) => async (dispatch) => {
    dispatch(setCourseProfListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await courseService.getCourseProficiencyList(requestData)
        if (response.status === 200) {
            dispatch(setCourseProfList(response?.data?.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setCourseProfListErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
    } finally {
        dispatch(setCourseProfListLoading(false))
    }
}

// create course proficiency
export const createCourseProficiencyDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddCourseProfDetailLoading(true))

    try {
        const { tutorCourseDetail } = getState().course
        const requestData = {
            body: body
        }
        const response = await courseService.createCourseProficiencyDetail(requestData)
        if (response.status === 200) {
            dispatch(setAddCourseProfDetail(response?.data?.data))
            dispatch(getCourseProficiencyList({ course: tutorCourseDetail?.data?.id }))
            toast.success(response?.data?.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setAddCourseProfDetailErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
    } finally {
        dispatch(setAddCourseProfDetailLoading(false))
    }
}

// update course proficiency order
export const updateCourseProficiencyListOrder = (body, query) => async (dispatch, getState) => {
    dispatch(setAddCourseProfDetailLoading(true))

    try {
        const { tutorCourseDetail } = getState().course
        const requestData = {
            body: body,
            query: query
        }
        const response = await courseService.updateCourseProficiencyListOrder(requestData)
        if (response.status === 200) {
            dispatch(setAddCourseProfDetail(response?.data?.data))
            dispatch(getCourseProficiencyList({ course: tutorCourseDetail?.data?.id }))
            toast.success(response?.data?.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setAddCourseProfDetailErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
    } finally {
        dispatch(setAddCourseProfDetailLoading(false))
    }
}

// delete course proficiency
export const deleteCourseProficiencyDetail = (courseProficiencyId) => async (dispatch, getState) => {
    dispatch(setAddCourseProfDetailLoading(true))

    try {
        const { tutorCourseDetail } = getState().course
        const requestData = {
            params: { courseProficiencyId: courseProficiencyId }
        }
        const response = await courseService.deleteCourseProficiencyDetail(requestData)
        if (response.status === 200) {
            dispatch(setAddCourseProfDetail(response?.data?.data))
            dispatch(getCourseProficiencyList({ course: tutorCourseDetail?.data?.id }))
            toast.success(response?.data?.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setAddCourseProfDetailErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
    } finally {
        dispatch(setAddCourseProfDetailLoading(false))
    }
}

// course availability detail
export const getCourseAvailabilityDetail = (courseAvailabilityId, query) => async (dispatch) => {
    dispatch(setCourseAvailDetailLoading(true))

    try {
        const requestData = {
            params: { courseAvailabilityId: courseAvailabilityId },
            query: query
        }
        const response = await courseService.getCourseAvailabilityDetail(requestData)
        if (response.status === 200) {
            dispatch(setCourseAvailDetail(response?.data?.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setCourseAvailErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
    } finally {
        dispatch(setCourseAvailDetailLoading(false))
    }
}

// create course availability detail
export const createCourseAvailabilityDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddCourseAvailDetailLoading(true))

    try {
        const { user } = getState().user
        const { tutorCourseDetail } = getState().course
        const requestData = {
            body: body
        }
        const response = await courseService.createCourseAvailabilityDetail(requestData)
        if (response.status === 200) {
            dispatch(setAddCourseAvailDetail(response?.data?.data))
            dispatch(setCourseAvailDetail(response?.data?.data))
            dispatch(getTutorCourseDetail(tutorCourseDetail?.data?.id, user?.user?.tutor?.tutorId))
            toast.success(response?.data?.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setAddCourseAvailDetailErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
    } finally {
        dispatch(setAddCourseAvailDetailLoading(false))
    }
}

// update course availability detail
export const updateCourseAvailabilityDetail = (courseAvailabilityId, body) => async (dispatch) => {
    dispatch(setAddCourseAvailDetailLoading(true))

    try {
        const requestData = {
            params: { courseAvailabilityId: courseAvailabilityId },
            body: body
        }
        const response = await courseService.updateCourseAvailabilityDetail(requestData)
        if (response.status === 200) {
            dispatch(setAddCourseAvailDetail(response?.data?.data))
            dispatch(setCourseAvailDetail(response?.data?.data))
            dispatch(getCourseAvailabilityDetail(courseAvailabilityId, { timeZone: timeZone }))
            toast.success(response?.data?.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setAddCourseAvailDetailErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
    } finally {
        dispatch(setAddCourseAvailDetailLoading(false))
    }
}

// delete course availability detail
export const deleteCourseAvailabilityDetail = (courseAvailabilityId) => async (dispatch, getState) => {
    dispatch(setAddCourseAvailDetailLoading(true))

    try {
        const { user } = getState().user
        const { tutorCourseDetail } = getState().course
        const requestData = {
            params: { courseAvailabilityId: courseAvailabilityId }
        }
        const response = await courseService.deleteCourseAvailabilityDetail(requestData)
        if (response.status === 200) {
            toast.success(response?.data?.message)
            dispatch(getTutorCourseDetail(tutorCourseDetail?.data?.id, user?.user?.tutor?.tutorId))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setAddCourseAvailDetailErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
    } finally {
        dispatch(setAddCourseAvailDetailLoading(false))
    }
}

// get course price detail
export const getCoursePriceDetail = (coursePriceId) => async (dispatch) => {
    dispatch(setCoursePriceDetailLoading(true))

    try {
        const requestData = {
            params: { coursePriceId: coursePriceId }
        }
        const response = await courseService.getCoursePriceDetail(requestData)
        if (response.status === 200) {
            dispatch(setCoursePriceDetail(response?.data?.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setCoursePriceDetailErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
    } finally {
        dispatch(setCoursePriceDetailLoading(false))
    }
}

// create course price detail
export const createCoursePriceDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddCoursePriceDetailLoading(true))

    try {
        const { user } = getState().user
        const { tutorCourseDetail } = getState().course
        const requestData = {
            body: body
        }
        const response = await courseService.createCoursePriceDetail(requestData)
        if (response.status === 200) {
            dispatch(getTutorCourseDetail(tutorCourseDetail?.data?.id, user?.user?.tutor?.tutorId))
            dispatch(setCoursePriceDetail(response?.data?.data))
            toast.success(response?.data?.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setAddCoursePriceDetailErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
    } finally {
        dispatch(setAddCoursePriceDetailLoading(false))
    }
}

// update course price detail
export const updateCoursePriceDetail = (coursePriceId, body) => async (dispatch) => {
    dispatch(setAddCoursePriceDetailLoading(true))

    try {
        const requestData = {
            params: { coursePriceId: coursePriceId },
            body: body
        }
        const response = await courseService.updateCoursePriceDetail(requestData)
        if (response.status === 200) {
            dispatch(setAddCoursePriceDetail(response?.data?.data))
            dispatch(setCoursePriceDetail(response?.data?.data))
            toast.success(response?.data?.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setAddCoursePriceDetailErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
    } finally {
        dispatch(setAddCoursePriceDetailLoading(false))
    }
}

// delete course price detail
export const deleteCoursePriceDetail = (coursePriceId) => async (dispatch, getState) => {
    dispatch(setAddCoursePriceDetailLoading(true))

    try {
        const { user } = getState().user
        const { tutorCourseDetail } = getState().course
        const requestData = {
            params: { coursePriceId: coursePriceId }
        }
        const response = await courseService.deleteCoursePriceDetail(requestData)
        if (response.status === 200) {
            dispatch(getTutorCourseDetail(tutorCourseDetail?.data?.id, user?.user?.tutor?.tutorId))
            dispatch(setCoursePriceDetail(response?.data?.data))
            toast.success(response?.data?.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setAddCoursePriceDetailErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
    } finally {
        dispatch(setAddCoursePriceDetailLoading(false))
    }
}

// get tutor course list
export const getTutorCourseList = (tutorId, query) => async (dispatch) => {
    dispatch(setTutorCourseListLoading(true))

    try {
        const requestData = {
            params: { tutorId: tutorId },
            query: query
        }
        const response = await courseService.getTutorCourseList(requestData)
        if (response.status === 200) {
            dispatch(setTutorCourseListData(response?.data?.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setTutorCourseListMessage(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!"))
    } finally {
        dispatch(setTutorCourseListLoading(false))
    }
}

// get tutor course detail
export const getTutorCourseDetail = (courseId, tutorId) => async (dispatch) => {
    dispatch(setTutorCourseDetailLoading(true))

    try {
        const requestData = {
            params: { courseId: courseId, tutorId: tutorId }
        }
        const response = await courseService.getTutorCourseDetail(requestData)
        if (response.status === 200) {
            dispatch(setTutorCourseDetailData(response?.data?.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "SOmething Went Wrong!")
        dispatch(setTutorCourseDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "SOmething Went Wrong!"))
    } finally {
        dispatch(setTutorCourseDetailLoading(false))
    }
}

// get course preview detail
export const getCoursePreviewDetail = (slug, query) => async (dispatch) => {
    dispatch(setCoursePreviewDetailLoading(true))

    try {
        const requestData = {
            query: query,
            params: { slug: slug }
        }
        const response = await courseService.getCoursePreviewDetail(requestData)
        if (response.status === 200) {
            dispatch(setCoursePreviewDetailData(response?.data?.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setCoursePreviewDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
    } finally {
        dispatch(setCoursePreviewDetailLoading(false))
    }
}

// clone course detail
export const createCloneCourseDetail = (courseId, body) => async (dispatch) => {
    dispatch(setCloneCourseDetailLoading(true))

    try {
        const requestData = {
            params: { courseId: courseId },
            body: body
        }
        const response = await courseService.createCloneCourseDetail(requestData)
        if (response.status === 200) {
            dispatch(setCloneCourseDetailData(response?.data?.data))
            toast.success(response?.data?.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setCloneCourseDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
    } finally {
        dispatch(setCloneCourseDetailLoading(false))
    }
}

// get course tutor list
export const getCourseTutorList = (query) => async (dispatch) => {
    dispatch(setCourseTutorListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await courseService.getCourseTutorList(requestData)
        if (response.status === 200) {
            dispatch(setCourseTutorList(response?.data?.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setCourseTutorListErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
    } finally {
        dispatch(setCourseTutorListLoading(false))
    }
}

//get course tutor detail
export const getCourseTutorDetail = (courseTutorId) => async (dispatch) => {
    dispatch(setCourseTutorDetailLoading(true))

    try {
        const requestData = {
            params: { courseTutorId: courseTutorId }
        }
        const response = await courseService.getCourseTutorDetail(requestData)
        if (response.status === 200) {
            dispatch(setCourseTutorDetail(response?.data?.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setCourseTutorDetailErrorMsg(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
    } finally {
        dispatch(setCourseTutorDetailLoading(false))
    }
}

// create next course session detail
export const createNextCourseSessionDetail = (courseId, body) => async (dispatch) => {
    dispatch(setNextCourseSessionDetailLoading(true))

    try {
        const requestData = {
            params: { courseId: courseId },
            body: body
        }
        const response = await courseService.createNextCourseSessionDetail(requestData)
        if (response.status === 200) {
            dispatch(setNextCourseSessionDetailData(response?.data?.message))
            dispatch(getSessionCourseList({ courseId: courseId }))
            toast.success(response?.data?.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setNextCourseSessionDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
    } finally {
        dispatch(setNextCourseSessionDetailLoading(false))
    }
}

// get course comment list
export const getCourseCommentList = (query) => async (dispatch) => {
    dispatch(setCourseCommentListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await courseService.getCourseCommentList(requestData)
        if (response.status === 200) {
            dispatch(setCourseCommentListData(response?.data?.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setCourseCommentListMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
    } finally {
        dispatch(setCourseCommentListLoading(false))
    }
}

// create course comment detail
export const createCourseCommentDetail = (body) => async (dispatch, getState) => {
    dispatch(setAddCourseCommentDetailLoading(true))

    try {
        const { tutorCourseDetail } = getState().course
        const requestData = {
            body: body
        }
        const response = await courseService.createCourseCommentDetail(requestData)
        if (response.status === 200) {
            dispatch(getCourseCommentList({ courseId: tutorCourseDetail?.data?.id }))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!")
        dispatch(setAddCourseCommentDetailMessage(error?.response?.data?.message || error?.response?.data?.error || "Something Went Wrong!"))
    } finally {
        dispatch(setAddCourseCommentDetailLoading(false))
    }
}