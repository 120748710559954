import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { IoIosCheckmark } from 'react-icons/io';

import DropdownMenu from '../dropdowns/DropdownMenu';

import {  useAppState } from 'hooks/useStore';
import { sessionLocationPhoneCallTypeEnum } from 'redux/session/session.const';

import { cn } from 'utils/cn.utils';

const PhoneCallLocation = ({ values }) => {
    const { countryList, timeZoneCountryDetail } = useAppState((state) => state.master)

    const { control, setValue } = useFormContext()

    const [isMounted, setIsMounted] = useState(false)

    useEffect(() => {
        setIsMounted(true)
    }, [])

    useEffect(() => {
        if (!values?.session_locations[0]?.phone_call?.phone_number && countryList?.countryList && timeZoneCountryDetail?.timeZoneCountryDetail) {
            let myLocalCountryList = countryList?.countryList?.filter((country) => (
                country?.masterCountryId === timeZoneCountryDetail?.timeZoneCountryDetail?.country?.masterCountryId
            ))

            if (myLocalCountryList.length === 0) {
                myLocalCountryList = countryList?.countryList?.filter((country) => (
                    country.countryDomain === "US"
                ))
            }
            setValue("session_locations[0].phone_call.location_phone_call_type", sessionLocationPhoneCallTypeEnum.call_me.key ?? "")
            setValue("session_locations[0].phone_call.phone_number", `${myLocalCountryList[0]?.isdCode}-${values?.session_locations[0]?.phone_call?.phone_number ?? ""}`)
        }
    }, [values?.session_locations[0]?.phone_call?.phone_number, countryList?.countryList, timeZoneCountryDetail?.timeZoneCountryDetail])

    if (!isMounted) return null

    return (
        <div className={"w-full space-y-0.5"}>
            <Controller
                name={"session_locations[0].phone_call.phone_number"}
                control={control}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <div className={"w-full space-y-1"}>
                        <div className={"w-full flex items-center justify-start gap-2"}>
                            <div className={"w-full md:w-[12rem]"}>
                                <DropdownMenu
                                    isLoading={countryList?.isLoading}
                                    isSearchEnabled={true}
                                    options={countryList?.countryList?.map((country) => ({
                                        label: `${country?.country} (+${country?.isdCode})`,
                                        value: country?.isdCode,
                                        country: country
                                    })) || []}
                                    onHandleSelect={(option) => {
                                        const splitValue = value?.split?.("-") || [""];
                                        onChange(`${option?.value}-${splitValue[1] || ""}`);
                                    }}
                                    selectedOption={
                                        <>
                                            {!!value
                                                ? <div className={"flex items-center gap-2 font-bodyPri font-normal text-text-800"}>
                                                    <img
                                                        src={`https://flagcdn.com/16x12/${countryList?.countryList?.find(countryItem => countryItem?.isdCode === value?.split?.("-")[0])?.countryDomain}.png`.toLowerCase()}
                                                        className={"w-8 h-5 object-cover"}
                                                        alt={"country-flag"}
                                                    />
                                                    <span className={"text-sm"}>{` (+${value?.split?.("-")[0]})`}</span>
                                                </div>
                                                : "Select Any"
                                            }
                                        </>
                                    }
                                    selectedMenuItem={countryList?.countryList?.find(countryItem => countryItem?.isdCode === value?.split?.("-")[0])?.isdCode}
                                    OptionChild={({ option }) => (
                                        <div className={"w-full flex items-center justify-between gap-3"}>
                                            <div className={"flex items-center gap-3"}>
                                                <img
                                                    src={`https://flagcdn.com/16x12/${option?.country?.countryDomain}.png`.toLowerCase()}
                                                    className={"w-10 h-6 object-cover"}
                                                />
                                                {option?.label}
                                            </div>
                                            {(option?.value === value?.split("-")[0])
                                                && <IoIosCheckmark size={20} color="green" />
                                            }
                                        </div>
                                    )}
                                    menuListProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        },
                                        transformOrigin: {
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }
                                    }}
                                />
                            </div>
                            <div
                                className={cn(
                                    "relative group min-w-[3rem] w-fit h-full p-2 flex-1 flex gap-3 items-center border rounded focus-within:shadow",
                                    !!error
                                        ? 'border-secondary-dark focus-within:border-secondary-main shadow shadow-secondary-main/20 focus-within:shadow-secondary-main/20'
                                        : !!value
                                            ? 'border-primary-main focus-within:border-primary-main shadow shadow-primary-main/20 focus-within:shadow-primary-main/20'
                                            : 'border-text-400 focus-within:border-primary-main focus-within:shadow-primary-main/20'
                                )}
                            >
                                <input
                                    type={"tel"}
                                    className={('w-full h-full bg-transparent outline-none text-base')}
                                    placeholder={"Your Phone number..."}
                                    value={value?.split?.("-")[1]}
                                    onChange={(e) => {
                                        onChange(
                                            `${value?.split?.("-")[0]}-${e.target.value}`
                                        )
                                    }}
                                />
                            </div>
                        </div>
                        {!!error && (
                            <div className={"flex items-center justify-start"}>
                                <span className={"font-bodyPri font-normal text-sm text-secondary-main"}>
                                    {error?.message}
                                </span>
                            </div>
                        )}
                    </div>
                )}
            />
        </div>
    )
}

export default PhoneCallLocation;