import { useCallback } from 'react';
import { Link } from 'react-router-dom';

// import { cn } from "utils/cn.utils";

// import { MdAdd } from 'react-icons/md';

import ThreeDotMenu from 'components/threeDotMenu/ThreeDotMenu';

import LMSTableComponent from 'pages/auth/edulyteLms/commonComponents/tableComponent/LMSTableComponent';
import { lectureInstructorHeaderConst, lectureInstructorMenuItemConst } from '../../data';

import { deleteLmsLectureInstructorDetail, updateLmsLectureDetail, updateLmsLectureInstructorDetail } from 'redux/edulyteLms/lmsLecture/lmsLecture.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import {
    resetAddLmsLectureInstructorDetail,
    resetAddLmsLectureInstructorDetailPayload,
    resetModifyLmsLectureInstructorDetail
} from 'redux/edulyteLms/lmsLecture/lmsLecture.slice';
import { modalConst } from 'redux/local/local.const';
import { lmsLectureInstructorPermissionEnum } from 'redux/edulyteLms/lmsLecture/lmsLecture.const';

import { pagesInfo } from 'utils/pagesInfo';
import { getCustomHostPath } from 'utils/generators.utils';
import { EDULYTE_WEB_DOMAIN_URL } from 'const/default.const';
import useCustomNavigation from 'hooks/useCustomNavigation';

const LectureInstructor = () => {
    const { modal } = useAppState((state) => state.local)
    const { tutorLmsLectureDetail } = useAppState((state) => state.lms.lmsLecture)

    const dispatcher = useAppDispatcher()
    const customNavigation = useCustomNavigation()

    const onHandleAddInstructor = useCallback(() => {
        dispatcher(resetAddLmsLectureInstructorDetail())
        dispatcher(resetModifyLmsLectureInstructorDetail())
        dispatcher(resetAddLmsLectureInstructorDetailPayload())
        dispatcher(setModal({
            ...modal,
            [modalConst.addLmsLectureInstructorModal.key]: {
                ...modal[modalConst.addLmsLectureInstructorModal.key],
                isVisible: true,
                title: "Add Instructor"
            }
        }))
    }, [modal])

    const sortLectureInstructor = (instructor1, instructor2) => {
        if (instructor1?.id === tutorLmsLectureDetail?.data?.lecture_setting?.default_owner?.id) {
            return -1;
        } else if (instructor2?.id === tutorLmsLectureDetail?.data?.lecture_setting?.default_owner?.id) {
            return 1;
        } else {
            return instructor1 - instructor2;
        }
    }

    const onHandleSelectMenuItem = (menuItem, lectureInstructorItem) => {
        switch (menuItem.value) {
            case lectureInstructorMenuItemConst?.MAKE_DEFAULT?.value:
                dispatcher(updateLmsLectureDetail(tutorLmsLectureDetail?.data?.lecture_id, { default_owner_id: lectureInstructorItem?.lecture_user_id }))
                return;
            case lectureInstructorMenuItemConst?.VIEWER?.value:
                if (lectureInstructorMenuItemConst?.VIEWER?.value === lectureInstructorItem?.permission) return;
                dispatcher(updateLmsLectureInstructorDetail(lectureInstructorItem?.lecture_user_id, { user_id: lectureInstructorItem?.user?.userId, permission: lectureInstructorMenuItemConst?.VIEWER?.value }))
                break;
            case lectureInstructorMenuItemConst?.EDITOR?.value:
                if (lectureInstructorMenuItemConst?.EDITOR?.value === lectureInstructorItem?.permission) return;
                dispatcher(updateLmsLectureInstructorDetail(lectureInstructorItem?.lecture_user_id, { user_id: lectureInstructorItem?.user?.userId, permission: lectureInstructorMenuItemConst?.EDITOR?.value }))
                break;
            case lectureInstructorMenuItemConst?.OWNER?.value:
                if (lectureInstructorMenuItemConst?.OWNER?.value === lectureInstructorItem?.permission) return;
                dispatcher(updateLmsLectureInstructorDetail(lectureInstructorItem?.lecture_user_id, { user_id: lectureInstructorItem?.user?.userId, permission: lectureInstructorMenuItemConst?.OWNER?.value }))
                break;
            case lectureInstructorMenuItemConst?.DELETE?.value:
                dispatcher(deleteLmsLectureInstructorDetail(lectureInstructorItem?.lecture_user_id))
                break;

            default:
                break;
        }
    }

    const LectureTutorProfileImageContainer = ({ lectureInstructor }) => {
        return (
            <div className={"flex items-center justify-center"}>
                <div className={'relative w-12 h-12 rounded-full'}>
                    <Link to={`${pagesInfo.TUTOR.pagePath}/${lectureInstructor?.user?.userId}`}>
                        <img
                            src={lectureInstructor?.user?.profile_pic_url}
                            alt={"profile-pic"}
                            className={"w-full h-full rounded-full object-cover"}
                        />
                    </Link>
                </div>
            </div>
        )
    }

    const LectureTutorNameContainer = ({ lectureInstructor }) => {
        const { publicOrgDetail } = useAppState((state) => state.org)

        return (
            <div className={'flex items-center justify-center gap-1'}>
                <a
                    // href={`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `${pagesInfo.TUTOR.pagePath}/${lectureInstructor?.tutor?.user?.username}` })}`}
                    href={`${customNavigation.getCustomBaseUrl({ path: `${pagesInfo.TUTOR.pagePath}/${lectureInstructor?.tutor?.user?.username}`, isWeb: true })}`}
                    target={"_blank"}
                    className={"w-fit"}
                >
                    <span className={'font-bodyPri font-normal text-base tracking-wide truncate capitalize'}>
                        {lectureInstructor?.user?.first_name} {lectureInstructor?.user?.last_name.charAt(0) + "."}
                    </span>
                </a>
                {(tutorLmsLectureDetail?.data?.lecture_setting?.default_owner?.id === lectureInstructor?.lecture_user_id) &&
                    <span className={"font-bodyPri font-medium text-green-500 text-sm"}>
                        {"(Primary)"}
                    </span>
                }
            </div>
        )
    }

    const lectureInstructorRows = tutorLmsLectureDetail?.data?.lecture_users && tutorLmsLectureDetail?.data?.lecture_users?.slice(0)?.sort(sortLectureInstructor)?.map((lectureInstructor) => [
        <LectureTutorProfileImageContainer lectureInstructor={lectureInstructor} />,
        <LectureTutorNameContainer lectureInstructor={lectureInstructor} />,
        lmsLectureInstructorPermissionEnum[lectureInstructor?.permission?.toUpperCase()]?.label,
        (tutorLmsLectureDetail?.data?.lecture_setting?.default_owner?.id != lectureInstructor?.lecture_user_id)
            ? <div className={"w-full flex items-center justify-center"}>
                <ThreeDotMenu
                    menuItems={Object.values(lectureInstructorMenuItemConst)?.filter((menuItem) => menuItem?.value != lectureInstructor?.permission)}
                    onHandleSelect={(menuItem) => onHandleSelectMenuItem(menuItem, lectureInstructor)}
                />
            </div>
            : "-"
    ])

    return (
        <div className={"w-full flex flex-col gap-5"}>
            <div className={"w-full flex items-center justify-between gap-3"}>
                <span className={"font-bodyPri font-bold text-text-900 text-lg tracking-wide"}>
                    {"Instructor(s)"}
                </span>
                {/* <div
                    className={cn(
                        "flex items-center justify-center gap-1 py-1 px-3",
                        "border border-primary-dark text-primary-dark rounded-lg cursor-pointer hover:bg-primary-dark group"
                    )}
                    onClick={onHandleAddInstructor}
                >
                    <MdAdd className={"text-lg group-hover:text-text-50"} />
                    <span className={"font-bodyPri font-normal group-hover:text-text-50"}>
                        {"Add Instructor"}
                    </span>
                </div> */}
            </div>

            <div className={"w-full px-5"}>
                <LMSTableComponent
                    headers={Object.values(lectureInstructorHeaderConst)}
                    subRowHeader={[]}
                    rows={(tutorLmsLectureDetail?.data?.lecture_users?.length > 0) ? lectureInstructorRows : []}
                    rowHeaderColor={"bg-back-ground-medium"}
                    alternateRowColor={"bg-primary-light"}
                />
            </div>
        </div>
    )
}

export default LectureInstructor;