import { useEffect } from 'react';

import PageHeader from 'components/pageHeader/PageHeader';

import { pageHeading } from 'pages/auth/myCourses/data';

// import MyCoursesSearch from 'pages/auth/myCourses/components/MyCoursesSearch';
// import AllCourses from 'pages/auth/myCourses/components/AllCourses';
// import FavCourses from 'pages/auth/myCourses/components/FavCourses';
import CohortList from './components/cohorts';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';

import { pagesInfo } from 'utils/pagesInfo';

import { useTitle } from 'hooks/useTitle';

const MyCoursesPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    
    const dispatcher = useAppDispatcher()
    const [title, setTitle] = useTitle()

    // const [myActiveTab, setMyActiveTab] = useState(courseTabs.ALL_COURSES.value)

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.MY_COURSES))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        setTitle({
            ...title,
            title: "All My Cohorts | Edulyte"
        })
    }, [dispatcher, currentPageInfo])


    return (
        <div className='h-full w-full p-3 mx-auto min-h-screen space-y-3'>
            <PageHeader pageHeading={pageHeading} />
            <CohortList />
            {/* <MyCoursesSearch myActiveTab={myActiveTab} /> */}
            {/* <Tabs
                tabs={Object.values(courseTabs)}
                handleTabs={(activeTab) => {
                    setMyActiveTab(activeTab.value)
                    }}
                    /> */}
            {/* {myActiveTab === courseTabs.ALL_COURSES.value &&
                    <AllCourses />
            }
            {myActiveTab === courseTabs.FAV_COURSES.value &&
            <FavCourses />
            } */}
        </div>
    );
};

export default MyCoursesPage;
