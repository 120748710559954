import { useState } from "react";

import Menu from "@mui/material/Menu";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import { BsThreeDotsVertical } from "react-icons/bs";

import { bookingMenuOptions } from "../data";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal, setShareSocial } from "redux/local/local.slice";
import { modalConst, socialAction } from "redux/local/local.const";
import { getCustomHostPath } from "utils/generators.utils";
import useCustomNavigation from "hooks/useCustomNavigation";

const useStyles = makeStyles({
    root: {},
});


const BookingMenu = ({ booking }) => {
    const { modal, shareSocial } = useAppState((state) => state.local)
    const { publicOrgDetail } = useAppState((state) => state.org)

    const dispatcher = useAppDispatcher()
    const customNavigation = useCustomNavigation()

    // const REVIEW_URL = `${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, pathname: `/tutor/${booking?.tutor?.userId}?action=leave-a-review` })}`
    const REVIEW_URL = `${customNavigation.getCustomBaseUrl({ path: `/tutor/${booking?.tutor?.userId}?action=leave-a-review` })}`

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (ele) => {
        setAnchorEl(null);
        switch (ele.value) {
            case bookingMenuOptions.GET_A_REVIEW.value:
                dispatcher(setShareSocial({
                    ...shareSocial,
                    socialAction: socialAction.GET_MORE_REVIEWS,
                    url: REVIEW_URL
                }))
                dispatcher(setModal({
                    ...modal,
                    [modalConst.GET_MORE_REVIEWS.stateKey]: true
                }))
                break;
            // case bookingMenuOptions.ADD_BOOKING.value:
            //     navigate(pagesInfo?.ADD_BOOKING?.pagePath)
            //     break;
            // case bookingMenuOptions.ADD_BADGE.value:
            //     break;
            default:
        }
    };

    return (
        <div>
            <div onClick={handleClick}>
                <BsThreeDotsVertical size={24} color="gray" />
            </div>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
                classes={{
                    root: classes.root,
                }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                {Object.values(bookingMenuOptions).map((ele, idx) =>
                    <MenuItem key={idx} onClick={() => handleClose(ele)} value={ele.label}>
                        {ele.label}
                    </MenuItem>
                )
                }
            </Menu>
        </div>
    );
};

export default BookingMenu;