import { useNavigate } from 'react-router-dom'
import { cn } from "utils/cn.utils";

import { MdGroups } from 'react-icons/md';
import { FiMessageSquare } from 'react-icons/fi';
import { BsInfoCircle } from "react-icons/bs";
import { IoMdPeople } from "react-icons/io";

import MuxVideoPlayer from 'components/muxVideoPlayer/MuxVideoPlayer';

import { getFBChat, getFBChatId } from 'redux/firebase/firebase.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { setMessageTemplate } from 'redux/firebase/firebase.slice';
import { getSelectedPublicCourseDetail } from 'redux/course/course.request';
import { setAddBookingDetailPayload } from 'redux/booking/booking.slice';
import { modalConst } from 'redux/local/local.const';
import { courseType, coursePriceTypeEnum, coursePricingModelTypeEnum } from 'redux/course/course.const';
import { addBookingDetailPayload, bookingOfferingTypeEnum } from 'redux/booking/booking.const';

import { dayjs } from "utils/dateTime.utils";
import { pagesInfo } from "utils/pagesInfo";
import { currencyRateConversion } from 'utils/generators.utils';
import { MUX_DEMO_PLAYBACK_ID } from 'const/default.const';


const CourseHeaderCard = () => {
    const { publicCourseDetail, publicCourseList } = useAppState((s) => s.course)
    const { modal, locals } = useAppState((s) => s.local)
    const { session } = useAppState(s => s.auth)
    const { user } = useAppState((s) => s.user)
    const { messageTemplate } = useAppState((s) => s.firebase)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()


    const isMyOffering = user?.user?.userId === publicCourseDetail?.data?.tutor?.user?.id

    const userCourse = user?.user?.bookedCourses?.find((courseItem) => courseItem?.courseId === publicCourseDetail?.data?.id)
    const isMyCourse = publicCourseList?.data?.results?.filter((publicCourse) => publicCourse?.type === courseType?.GROUP?.value)
        .filter((myCourse) => myCourse?.id === userCourse?.courseId)?.length > 0

    const handleMessage = async () => {
        if (!session?.isLoggedIn) {
            dispatcher(setModal({
                ...modal,
                [modalConst.AUTHORIZATION_MODAL.stateKey]: true
            }))
            return;
        }

        if (isMyOffering) return;

        const userIdOne = user?.user?.userId
        const userIdTwo = publicCourseDetail?.data?.tutor?.user?.id

        const chatId = await getFBChatId(userIdOne, userIdTwo)
        let fbChat = await getFBChat(chatId)

        if (!fbChat || fbChat === undefined) {
            dispatcher(setMessageTemplate({
                ...messageTemplate,
                isLoading: false,
                userIdOne: userIdOne,
                userIdTwo: userIdTwo,
                tutorUserId: publicCourseDetail?.data?.tutor?.user?.id,
                message: '',
                errorMsg: null
            }))
            dispatcher(setModal({
                ...modal,
                [modalConst.MESSAGE_TEMPLATE_MODAL.stateKey]: true
            }))
            return;
        }

        navigate(`${pagesInfo.MESSAGES.pagePath}/${fbChat.chatId}`)
    }

    const openModal = () => {
        dispatcher(setAddBookingDetailPayload({
            ...addBookingDetailPayload,
            offeringType: bookingOfferingTypeEnum.COHORT.value,
            courseId: publicCourseDetail?.data?.id,
            slug: publicCourseDetail?.data?.slug
        }))
        dispatcher(getSelectedPublicCourseDetail(publicCourseDetail?.data?.slug))

        if (publicCourseDetail?.data?.type === courseType.GROUP.value) {
            dispatcher(setModal({ ...modal, [modalConst.COURSE_SCHEDULE_MODAL.stateKey]: true }))
        } else if (publicCourseDetail?.data?.type === courseType.ONE_ON_ONE.value && publicCourseDetail?.data?.coursePrice?.type === coursePriceTypeEnum.REGULAR.value) {
            dispatcher(setModal({ ...modal, [modalConst.NO_SESSION_MODAL.stateKey]: true }))
        }
    }

    const handleBookNow = () => {
        if (isMyCourse || isMyOffering) return;

        dispatcher(setAddBookingDetailPayload({
            ...addBookingDetailPayload,
            offeringType: bookingOfferingTypeEnum.COHORT.value,
            courseId: publicCourseDetail?.data?.id,
            slug: publicCourseDetail?.data?.slug
        }))
        dispatcher(getSelectedPublicCourseDetail(publicCourseDetail?.data?.slug))

        if (publicCourseDetail?.data?.type === courseType.GROUP.value) {
            dispatcher(setModal({ ...modal, [modalConst.COURSE_CHECKOUT_MODAL.stateKey]: true }))
        } else if (publicCourseDetail?.data?.type === courseType.ONE_ON_ONE.value && publicCourseDetail?.data?.coursePrice?.type === coursePriceTypeEnum.REGULAR.value) {
            dispatcher(setModal({ ...modal, [modalConst.NO_SESSION_MODAL.stateKey]: true }))
        }
    }

    // const absoNav = ` ${props.position} object-bottom bg-white rounded-b-xl shadow-md p-3 px-4 flex flex-col gap-3`;
    // const fixedNav = "fixed w-80 z-60 top-0 mt-24 object-bottom bg-white rounded-b-xl shadow-md p-3 px-4 flex flex-col gap-3"
    // window.addEventListener('scroll', changeNav);

    return (
        <>
            <div className='rounded-t-lg w-full h-48 md:w-[28rem] lg:w-full bg-white overflow-hidden'>
                <MuxVideoPlayer
                    playbackId={publicCourseDetail?.data?.introVideoUrl ? publicCourseDetail?.data?.introVideoUrl : MUX_DEMO_PLAYBACK_ID}
                    isShowButtons={false}
                    isButtonDisabled={true}
                    muted={true}
                />
                {/* <iframe width="100%" height="100%" src={`https://www.youtube.com/embed/${publicCourseDetail?.data?.videoUrl}`}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
                </iframe> */}
            </div>

            <div className={"sticky top-[5rem] bg-white rounded-b-xl shadow-none lg:shadow-md p-3 px-4 flex flex-col gap-3"}>
                {(publicCourseDetail?.data?.coursePrice?.type === coursePriceTypeEnum?.REGULAR?.value) &&
                    <div className="flex items-center gap-2 w-full">
                        <div className='flex justify-center items-center gap-2'>
                            <span className='text-2xl text-text-900'>
                                {currencyRateConversion(locals?.currencyCode, publicCourseDetail?.data?.basicDetail?.discountedPrice)}
                            </span>
                        </div>
                        {publicCourseDetail?.data?.coursePrice?.discountPct > 0 &&
                            <div className='flex flex-col'>
                                <span className='text-text-900 font-semibold'>
                                    <del className='text-text-600'>
                                        {currencyRateConversion(locals?.currencyCode, publicCourseDetail?.data?.basicDetail?.price)}
                                    </del>
                                    {" " + publicCourseDetail?.data?.coursePrice?.discountPct + "% off"}
                                </span>
                                <span className="text-text-900 font-medium font-bodyPri">
                                    {coursePricingModelTypeEnum[publicCourseDetail?.data?.coursePrice?.priceModel?.toUpperCase()]?.label}
                                </span>
                            </div>
                        }
                        {(!publicCourseDetail?.data?.coursePrice?.discountPct || publicCourseDetail?.data?.coursePrice?.discountPct === 0) &&
                            <span className="text-text-900 font-medium font-bodyPri">
                                {coursePricingModelTypeEnum[publicCourseDetail?.data?.coursePrice?.priceModel?.toUpperCase()]?.label}
                            </span>
                        }
                    </div>
                }
                {(publicCourseDetail?.data?.coursePrice?.type === coursePriceTypeEnum?.PLAN?.value) &&
                    <div className="flex items-center gap-2 w-full">
                        <div className='flex justify-center items-center gap-2'>
                            <span className='text-2xl text-text-900'>
                                {currencyRateConversion(locals?.currencyCode, publicCourseDetail?.data?.coursePrice?.itemPrices[0]?.price)}
                            </span>
                            <span className="text-text-900 font-medium font-bodyPri">
                                {coursePricingModelTypeEnum[publicCourseDetail?.data?.coursePrice?.itemPrices[0]?.pricing_model?.toUpperCase()]?.label}
                            </span>
                        </div>
                    </div>
                }
                <div className='flex items-center w-full justify-evenly'>
                    <span onClick={handleBookNow} className={cn(
                        'w-2/3 py-1 flex justify-center items-center gap-1 rounded-full font-normal font-buttons border',
                        isMyOffering
                            ? "border-text-300 text-text-300"
                            : isMyCourse
                                ? "border-text-500 text-text-700"
                                : "border-secondary-main text-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer"
                    )}>
                        {isMyCourse && <BsInfoCircle className={"text-primary-main text-base"} />}
                        <span className={""}>
                            {isMyCourse
                                ? "Course Purchased"
                                : (publicCourseDetail?.data?.coursePrice?.type === coursePriceTypeEnum?.REGULAR?.value)
                                    ? "Book Now"
                                    : "Subscribe"
                            }
                        </span>
                    </span>
                    <span onClick={handleMessage} className={cn(
                        'text-3xl',
                        isMyOffering
                            ? "text-text-300"
                            : "text-text-700 hover:text-primary-main cursor-pointer"
                    )}>
                        <FiMessageSquare />
                    </span>
                </div>

                <div className="flex flex-col gap-2 pt-2">

                    {publicCourseDetail?.data?.type === courseType.ONE_ON_ONE.value &&
                        <div className='flex justify-start gap-3'>
                            <IoMdPeople className="text-gray-700 text-xl" />
                            <span className="text-text-700 font-normal font-bodyPri">
                                {"Class Type:"}
                            </span>
                            <span className='text-text-900 font-medium font-bodyPri'>
                                {courseType.ONE_ON_ONE.label}
                            </span>
                        </div>
                    }
                    {publicCourseDetail?.data?.type === courseType.GROUP.value &&
                        <div className='flex justify-start gap-3'>
                            <MdGroups className="text-gray-700 text-xl" />
                            <span className="text-text-700 font-normal font-bodyPri">
                                {"Class Type:"}
                            </span>
                            <span className='text-text-900 font-medium font-bodyPri'>
                                {courseType.GROUP.label}
                            </span>
                        </div>
                    }

                    <div className='flex flex-col gap-2 pt-2'>
                        <span onClick={openModal} className={cn(
                            'w-full py-0.5 flex justify-center items-center font-buttons rounded-full font-normal',
                            "border border-text-700 text-gray-700 hover:border hover:border-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer"
                        )}>
                            {(publicCourseDetail?.data?.type === courseType?.GROUP?.value)
                                ? "See schedule"
                                : "See calendar"
                            }
                        </span>
                        {(publicCourseDetail?.data?.type === courseType?.GROUP?.value) &&
                            <div
                                className={'flex flex-col justify-center items-center p-2 bg-yellow-300 font-bold cursor-pointer text-text-900 hover:text-primary-dark'}
                                onClick={handleBookNow}
                            >
                                <span className={""}>
                                    {"Enroll Now"}
                                </span>
                                <span className={""}>
                                    {"Starts on " + dayjs(publicCourseDetail?.data?.startDateTime).format("DD MMMM, YYYY")}
                                </span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default CourseHeaderCard