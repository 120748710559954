import { Link } from 'react-router-dom';

import { MdAccessTime, MdOutlinePeopleAlt } from 'react-icons/md';

import { appointmentPriceModelEnum } from 'redux/appointment/appointment.const';

import { cn } from 'utils/cn.utils';
import { pagesInfo } from 'utils/pagesInfo';

const AppointmentCard = ({ appointment }) => {

    return (
        <div className={cn("w-full p-3 bg-white shadow-all rounded-lg space-y-2")}>
            <div className={"space-y-1"}>
                <div className={"inline-flex items-center justify-start gap-2 bg-primary-light rounded-full px-3 py-0.5"}>
                    <MdAccessTime className={"text-md text-primary-dark"} />
                    <span className={"font-bodyPri font-medium text-sm text-primary-dark"}>
                        {(appointment?.appointment_availability?.duration >= 60)
                            ? `${parseFloat(appointment?.appointment_availability?.duration / 60).toFixed(2)} hr`
                            : `${appointment?.appointment_availability?.duration} min`
                        }
                    </span>
                </div>
                <div className={"space-y-1"}>
                    <Link to={`${pagesInfo.APPOINTMENT.pagePath}/${appointment?.slug}`}>
                        <h3 className={"w-fit font-bodyPri font-semibold text-lg text-text-900 line-clamp-1 hover:text-secondary-dark hover:underline"}>
                            {appointment?.title}
                        </h3>
                    </Link>
                    {!!appointment?.subtitle && (
                        <p className={"font-bodyPri font-normal text-sm text-text-700 line-clamp-1"}>
                            {appointment?.subtitle}
                        </p>
                    )}
                </div>
                <div className={"flex items-center justify-start gap-2"}>
                    <MdOutlinePeopleAlt className={"text-lg text-text-700"} />
                    <span className={"font-bodyPri font-medium text-sm text-text-800 text-left"}>
                        {`By ${appointment?.user?.firstName} ${appointment?.user?.lastName.charAt(0)}.`}
                    </span>
                </div>
            </div>
            <div className={"flex items-end justify-between gap-2"}>
                <div className={"flex-1"}>
                    {(appointment?.appointment_price?.price_model === appointmentPriceModelEnum.FLAT_FEE.value) && (
                        <div className='w-full'>
                            <span className='font-bodyPri font-medium text-lg text-text-900'>
                                {`${appointment?.appointment_price?.master_currency?.code} ` + parseFloat((appointment?.appointment_price?.price - ((appointment?.appointment_price?.price * appointment?.appointment_price?.discount_pct) / 100)) / 100).toFixed(2)}
                            </span>
                            {((appointment?.appointment_price?.discount_pct > 0) && (appointment?.appointment_price?.discount_pct < 100)) && (
                                <span className='flex flex-row items-center justify-start gap-2'>
                                    <del className='font-bodyPri font-medium text-sm text-text-700'>
                                        {`${appointment?.appointment_price?.master_currency?.code}` + parseFloat(appointment?.appointment_price?.price / 100).toFixed(2)}
                                    </del>
                                    <span className={"font-bodyPri font-medium text-sm text-green-500"}>
                                        {appointment?.appointment_price?.discount_pct + "% off"}
                                    </span>
                                </span>
                            )}
                        </div>
                    )}
                    {(appointment?.appointment_price?.price_model === appointmentPriceModelEnum.FREE.value) && (
                        <div className='w-full'>
                            <span className='font-bodyPri font-medium text-lg text-text-900'>
                                {"FREE"}
                            </span>
                        </div>
                    )}
                </div>
                <Link to={`${pagesInfo.APPOINTMENT.pagePath}/${appointment?.slug}`}>
                    <div className={"px-3 py-0.5 rounded-full border border-secondary-dark font-buttons font-medium text-base text-secondary-dark hover:bg-secondary-dark hover:text-text-50 cursor-pointer"}>
                        {"View Detail >"}
                    </div>
                </Link>
            </div>
        </div>
    )
}

export const AppointmentCardLoader = (className) => {
    return (
        <div className={cn("w-full shadow-all rounded-lg bg-slate-200", className)}>
            <div className={"p-3 space-y-2"}>
                <div className={"inline-flex items-center justify-start gap-2 rounded-full"}>
                    <span className={"w-10 h-5 bg-slate-300 animate-pulse"}>
                    </span>
                </div>
                <div className={"space-y-2"}>
                    <h3 className={"w-9/10 h-7 bg-slate-300 animate-pulse"}>
                    </h3>
                    <p className={"w-9/10 h-5 bg-slate-300 animate-pulse"}>
                    </p>
                </div>
                <div className={"flex items-center justify-start gap-2"}>
                    <span className={"w-1/2 h-5 bg-slate-300 animate-pulse"}>
                    </span>
                </div>
            </div>
            <div className={"px-5 h-12 flex items-center justify-between gap-2"}>
                <div className='w-2/5 h-8 bg-slate-300 animate-pulse'>
                </div>
                <div className={"px-3 py-0.5 rounded-full w-1/2 h-7 bg-slate-300 animate-pulse"}>
                </div>
            </div>
        </div>
    )
}

export default AppointmentCard