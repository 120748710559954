import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { cn } from "utils/cn.utils";

import Pagination from 'components/pagination/Pagination';
import StudentQuizCard, { Loader as StudentQuizCardLoader } from 'components/quiz-card/student-quiz-card';

import { getStudentLmsQuizList } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { resetStudentLmsQuizList } from 'redux/edulyteLms/lmsQuiz/lmsQuiz.slice';

import { pagesInfo } from 'utils/pagesInfo';
import { generateQueryParams, getCustomHostPath } from 'utils/generators.utils';

import { EDULYTE_WEB_DOMAIN_URL } from 'const/default.const';
import useCustomNavigation from 'hooks/useCustomNavigation';

const RECORDS = 15

const MyQuizzes = () => {
    const publicOrgDetail = useAppState((state) => state.org.publicOrgDetail)
    const { user } = useAppState((s) => s.user)
    const { studentLmsQuizList } = useAppState((s) => s.lms.lmsQuiz)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()
    const customNavigation = useCustomNavigation()

    useEffect(() => {
        if (user?.user?.student?.studentId) {
            const query = generateQueryParams(location.search)

            dispatcher(getStudentLmsQuizList(user?.user?.student?.studentId, { page: query.pn || 1, records: RECORDS }))
        }

        return () => {
            dispatcher(resetStudentLmsQuizList())
        }
    }, [location.search])

    const handleBuyQuiz = () => {
        // window.open(`${EDULYTE_WEB_DOMAIN_URL}/quizzes`, "_self")
        // window.open(`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/quizzes` })}`, "_self")
        customNavigation.navigation({ path: `/quizzes`, isWeb: true })
    }

    return (
        <div className={'flex justify-center items-center'}>
            <div className={'w-full space-y-5'}>

                {(!studentLmsQuizList?.isLoading && !!studentLmsQuizList?.data?.records) &&
                    <div className={'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-5 gap-3'}>
                        {studentLmsQuizList?.data?.results?.map((studentQuizItem, index) => (
                            <div key={index} className={'col-span-1 flex justify-center'}>
                                <StudentQuizCard className={"max-w-xs w-full"} studentQuiz={studentQuizItem} />
                            </div>
                        ))}
                    </div>
                }
                {studentLmsQuizList?.isLoading &&
                    <div className={'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-5 gap-3'}>
                        {new Array(RECORDS).fill("").map((_, index) => (
                            <div key={index} className={"col-span-1 flex justify-center"}>
                                <StudentQuizCardLoader className={"max-w-xs w-full"} />
                            </div>
                        ))}
                    </div>
                }
                {(studentLmsQuizList?.data && (studentLmsQuizList?.data?.totalPages > 1)) && (
                    <div className="w-full flex justify-center items-center">
                        <Pagination
                            page={studentLmsQuizList?.data?.page}
                            totalPages={studentLmsQuizList?.data?.totalPages}
                            onChangePage={(page) => navigate(`${pagesInfo.STUDENT_LMS_QUIZZES.pagePath}/quizzes?pn=${page}`)}
                        />
                    </div>
                )}
                {(!studentLmsQuizList?.isLoading && (!!studentLmsQuizList?.message || !studentLmsQuizList?.data?.records)) &&
                    <div className={'w-full h-full flex flex-col items-center justify-center gap-1 text-center text-base font-bodyPri tracking-wide'}>
                        <div className={"w-[16rem] h-[16rem] overflow-hidden"}>
                            <img
                                src={"https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/my-lms-courses/lms-course-not-found.png"}
                                alt={"no-course-found"}
                                className={"w-full h-full object-cover"}
                            />
                        </div>
                        <div
                            className={cn(
                                "w-fit px-5 py-1 flex items-center justify-center rounded-lg",
                                "border border-primary-dark bg-primary-dark text-text-50 hover:opacity-90",
                                "transition-transform duration-300 delay-150 ease-in-out"
                            )}
                            onClick={handleBuyQuiz}
                        >
                            <span className={"font-bodyPri font-normal text-base tracking-wide text-center cursor-pointer"}>
                                {"Buy a Quiz"}
                            </span>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default MyQuizzes;