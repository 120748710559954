import { useCallback, useState } from 'react';

import { toast } from 'react-toastify';
import { cn } from "utils/cn.utils";

import { FaSpinner } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';

import ToolTipView from 'components/tooltipView';
import ComponentLoader from 'components/loader/ComponentLoader';

import SaveAndCancelBtn from 'pages/auth/edulyteLms/commonComponents/lmsButtons/SaveAndCancelBtn';

import QuizSlugCard from './QuizSlugCard';

import { createLmsQuizSlugDetail, deleteLmsQuizSlugDetail, updateLmsQuizDetail } from "redux/edulyteLms/lmsQuiz/lmsQuiz.request";

import { useAppDispatcher, useAppState } from 'hooks/useStore';

import { EDULYTE_WEB_DOMAIN_URL } from 'const/default.const';

import { validateSlug } from 'utils/validation-functions';
import { getCustomHostPath } from 'utils/generators.utils';
import useCustomNavigation from 'hooks/useCustomNavigation';

const MAX_LENGTH = 110;

const QuizSlug = () => {
    const publicOrgDetail = useAppState((state) => state.org.publicOrgDetail)
    const { lmsQuizDetail, updateLmsQuiz, addLmsQuizSlugDetail, destroyLmsQuizSlugDetail } = useAppState((state) => state.lms.lmsQuiz)

    const dispatcher = useAppDispatcher()
    const customNavigation = useCustomNavigation()

    const [isAddLinkFormEnabled, setIsAddLinkFormEnabled] = useState(false)
    const [linkTitle, setLinkTitle] = useState("")

    const onHandleMakeDefault = useCallback((quizSlugId) => {
        if (!window.confirm("Are you sure? This will invalidate currently active link")) return;

        dispatcher(updateLmsQuizDetail(lmsQuizDetail?.data?.id, { default_slug_id: quizSlugId }))
    }, [lmsQuizDetail?.data])

    const onHandleSaveLink = useCallback(() => {
        if (lmsQuizDetail?.isLoading) return;

        if (!linkTitle) {
            toast.warn("Please add link!")
            return;
        }

        if (!validateSlug(linkTitle?.toLowerCase()?.trim())) {
            toast.warn("Please provide valid link!")
            return;
        }

        const body = {
            quiz_id: lmsQuizDetail?.data?.id,
            slug: linkTitle?.toLowerCase()?.trim()
        }
        dispatcher(createLmsQuizSlugDetail(body))
        setLinkTitle('')
        setIsAddLinkFormEnabled(false)
    }, [linkTitle, lmsQuizDetail?.data])

    const onHandleResetLink = useCallback(() => {
        setLinkTitle('')
        setIsAddLinkFormEnabled(false)
    }, [])

    const onHandleDeleteQuizSlug = useCallback((quizSlugId) => {
        if (addLmsQuizSlugDetail?.isLoading || destroyLmsQuizSlugDetail?.isLoading) return;

        dispatcher(deleteLmsQuizSlugDetail(quizSlugId))
    }, [lmsQuizDetail?.data, addLmsQuizSlugDetail?.isLoading, destroyLmsQuizSlugDetail?.isLoading])

    return (
        <div className={"w-full flex flex-col gap-5"}>
            <div className={"w-full px-3 py-2 bg-divider-medium rounded-lg"}>
                <span className={"font-bodyPri font-bold text-text-900 text-lg tracking-wide"}>
                    {"Shareable Quiz Link(s)"}
                </span>
            </div>

            <div className={"w-full px-5 space-y-5"}>
                {lmsQuizDetail?.data?.active_slug &&
                    <div className={"space-y-5"}>
                        <QuizSlugCard
                            isAddLinkFormEnabled={isAddLinkFormEnabled}
                            setIsAddLinkFormEnabled={setIsAddLinkFormEnabled}
                        />
                        {(addLmsQuizSlugDetail?.isLoading || updateLmsQuiz?.isLoading) &&
                            <ComponentLoader isLoading={addLmsQuizSlugDetail?.isLoading || updateLmsQuiz?.isLoading} />
                        }
                        {isAddLinkFormEnabled &&
                            <hr className={"w-full h-0.5 bg-divider-medium"} />
                        }
                        {(!addLmsQuizSlugDetail?.isLoading && !updateLmsQuiz?.isLoading) && isAddLinkFormEnabled &&
                            <div className={"w-full grid grid-cols-12 gap-3 items-start"}>
                                <div className={"col-span-8 order-1 lg:col-span-11 space-x-1 mt-0 md:mt-1"}>
                                    <div className={"w-full flex items-center justify-start gap-0.5"}>
                                        <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide"}>
                                            {/* {`${EDULYTE_WEB_DOMAIN_URL}/quiz/`} */}
                                            {/* {`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/quiz/` })}`} */}
                                            {`${customNavigation.getCustomBaseUrl({ path: `/quiz/`, isWeb: true })}`}
                                        </span>
                                        <input
                                            type={"text"}
                                            name={"slug"}
                                            placeholder={"title-of-the-quiz"}
                                            className={cn(
                                                "h-fit flex-1 px-3 border-b-2 border-divider-lightDark",
                                                "font-bodyPri font-normal text-text-900 text-base tracking-wide",
                                                "focus:outline-none focus:border-primary-main"
                                            )}
                                            maxLength={MAX_LENGTH}
                                            value={linkTitle}
                                            onChange={(event) => setLinkTitle(event?.target?.value?.toLowerCase())}
                                        />
                                    </div>
                                    <span className={"w-full flex justify-end font-bodyPri font-normal text-sm text-text-800"}>
                                        {`${linkTitle?.length}/${MAX_LENGTH}`}
                                    </span>
                                </div>
                                <div className={"col-span-full order-3 lg:order-2 lg:col-start-12 lg:col-span-1 justify-self-stretch flex items-center justify-start gap-1"}>
                                    {addLmsQuizSlugDetail?.isLoading &&
                                        <div className={"flex items-center justify-start gap-1.5"}>
                                            <FaSpinner className={"animate-spin text-primary-dark text-base"} />
                                            <span className={"font-bodyPri font-normal text-primary-dark text-sm"}>
                                                {"Please wait"}
                                            </span>
                                        </div>
                                    }
                                    {!addLmsQuizSlugDetail?.isLoading &&
                                        <SaveAndCancelBtn
                                            onHandleSave={onHandleSaveLink}
                                            onHandleReset={onHandleResetLink}
                                        />
                                    }
                                </div>
                            </div>
                        }
                    </div>
                }

                {((lmsQuizDetail?.data?.quiz_slugs?.filter((slugItem) => (slugItem?.slug !== lmsQuizDetail?.data?.active_slug)))?.length > 0) &&
                    <hr className={"w-full h-0.5 bg-divider-medium"} />
                }
                {((lmsQuizDetail?.data?.quiz_slugs?.filter((slugItem) => (slugItem?.slug !== lmsQuizDetail?.data?.active_slug)))?.length > 0) &&
                    <div className={"flex flex-col gap-5"}>
                        <span className={"font-bodyPri font-medium text-text-800 text-md tracking-wide"}>
                            {"Inactive links:"}
                        </span>
                        {lmsQuizDetail?.data?.quiz_slugs?.filter((slugItem) => (slugItem?.slug !== lmsQuizDetail?.data?.active_slug))?.map((quizSlug, index) => (
                            <div key={index} className={"w-full grid grid-cols-12 gap-2 items-start"}>
                                <div
                                    className={"col-span-full order-3 lg:order-2 lg:col-span-11 justify-self-stretch mt-0 md:mt-1 inline-flex w-fit font-bodyPri font-normal text-text-700 text-base line-clamp-1 tracking-wide"}>
                                    <span>
                                        {/* {`${EDULYTE_WEB_DOMAIN_URL}/quiz/`} */}
                                        {/* {`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/quiz/` })}`} */}
                                        {`${customNavigation.getCustomBaseUrl({ path: `/quiz/`, isWeb: true })}`}
                                    </span>
                                    <span className={""}>
                                        {quizSlug?.slug}
                                    </span>
                                </div>
                                <div className={"col-span-full order-3 lg:order-2 lg:col-start-12 lg:col-span-1 justify-self-stretch flex items-center justify-start gap-1"}>
                                    <span
                                        className={"font-bodyPri font-medium text-text-700 text-sm hover:text-text-800 cursor-pointer"}
                                        onClick={() => onHandleMakeDefault(quizSlug?.id)}
                                    >
                                        {"Activate"}
                                    </span>
                                    <ToolTipView content={"Delete Link"}>
                                        <button className={"p-2 rounded-full hover:bg-divider-darkLight"} onClick={() => onHandleDeleteQuizSlug(quizSlug?.id)}>
                                            <RiDeleteBin6Line
                                                className={"text-xl text-text-700 hover:text-red-500 cursor-pointer"} />
                                        </button>
                                    </ToolTipView>
                                </div>
                            </div>
                        ))}
                    </div>
                }
            </div>
        </div>
    )
}

export default QuizSlug;