import { sessionStatusEnum, sessionUserTypeEnum } from 'redux/session/session.const';

export const sessionLogConst = {
    NOT_MAPPED: {
        title: "Not Mapped",
    },
    PENDING: {
        title: "Pending",
    },
    SCHEDULED: {
        title: "Schedule",
    },
    ON_GOING: {
        title: "On Going",
    },
    COMPLETED: {
        title: "Completed",
    },
    DECLINED: {
        title: "Declined",
    },
    RESCHEDULED: {
        title: "Rescheduled",
    },
    CANCELLED: {
        title: "Cancelled",
    },
}


export const createSessionLog = (sessionLogs) => {
    return sessionLogs?.map(sessionLog => {
        return {
            ...sessionStatusEnum[sessionLog?.status?.toUpperCase()],
            ...sessionLog,
            title: sessionLogConst[sessionLog?.status?.toUpperCase()]?.title,
            createdAt: sessionLog?.created_at
        }
    })
}

export const statusToolTip = {
    PENDING: "confirmation pending from moderator / organisor.",
    SCHEDULED: "confirmed appointment",
    ON_GOING: "Session is occuring now",
    CANCELLED: "Session has been cancelled",
    COMPLETED: "marked as completed",
    DECLINED: "meeting request declined"
}

export const sessionUserTypeMessage = {
    APPOINTMENT: {
        PARTICIPANT: {
            ON_GOING: {
                message: {
                    sessionOngoing: "Your session is currently in progress.",
                    sessionEnded: "Your session has been completed."
                },
            },
            RESCHEDULED: {
                message: "Please contact support for more details"
            },
            COMPLETED: {
                message: "Your session is completed. Thank you for attending."
            },
            CANCELLED: {
                message: "Your session has been cancelled. We apologize for the inconvenience."
            }
        },
        MODERATOR: {
            COMPLETED: {
                message: "The session has been completed successfully."
            },
            CANCELLED: {
                message: "The session has been cancelled."
            }
        }
    },
    COURSE: {
        PARTICIPANT: {
            PENDING: {
                message: "Please contact support for more details."
            },
            DECLINED: {
                message: "Please contact support for more details."
            },
            ON_GOING: {
                message: {
                    sessionOngoing: "Your session is currently in progress.",
                    sessionEnded: "Your session has been completed. We hope you enjoyed it!"
                },
            },
            RESCHEDULED: {
                message: "Please contact support for more details."
            },
            COMPLETED: {
                message: "The session has been completed. Congratulations on finishing!"
            },
            CANCELLED: {
                message: "The session has been cancelled. Please check your email for further information."
            }
        },
        MODERATOR: {
            COMPLETED: {
                message: "The session has been successfully completed."
            },
            CANCELLED: {
                message: "The session has been cancelled. Please notify the participants."
            }
        }
    },
    COHORT: {
        PARTICIPANT: {
            PENDING: {
                message: "Please contact support for more details."
            },
            DECLINED: {
                message: "Please contact support for more details."
            },
            ON_GOING: {
                message: {
                    sessionOngoing: "Your session is currently in progress.",
                    sessionEnded: "Your session has been completed. We hope you enjoyed it!"
                },
            },
            RESCHEDULED: {
                message: "Please contact support for more details."
            },
            COMPLETED: {
                message: "The session has been completed. Congratulations on finishing!"
            },
            CANCELLED: {
                message: "The session has been cancelled. Please check your email for further information."
            }
        },
        MODERATOR: {
            COMPLETED: {
                message: "The session has been successfully completed."
            },
            CANCELLED: {
                message: "The session has been cancelled. Please notify the participants."
            }
        }
    },
    INTERVIEW: {
        PARTICIPANT: {
            ON_GOING: {
                message: {
                    sessionOngoing: "Your session is currently in progress.",
                    sessionEnded: "Your session has been completed. Thank you for your time."
                },
            },
            RESCHEDULED: {
                message: "Please contact support for more details."
            },
            COMPLETED: {
                message: "The session has been completed. We appreciate your participation."
            },
            CANCELLED: {
                message: "The session has been cancelled. We apologize for any inconvenience."
            }
        },
        MODERATOR: {
            COMPLETED: {
                message: "The session has been successfully completed."
            },
            CANCELLED: {
                message: "The session has been cancelled. Please inform the participants."
            }
        }
    }
}

export const getSessionStatusMessage = (sessionType, sessionUserType, userSessionStatus, isSessionEnded) => {
    if (sessionType && sessionUserType && userSessionStatus) {
        const messageObject = sessionUserTypeMessage[sessionType?.toUpperCase()]?.[sessionUserType?.toUpperCase()]?.[userSessionStatus?.toUpperCase()]
        if (userSessionStatus === sessionStatusEnum.ON_GOING.value) {
            if (messageObject) {
                return isSessionEnded ? messageObject?.message?.sessionEnded : messageObject?.message?.sessionOngoing;
            }
        } else {
            return messageObject ? messageObject?.message : "";
        }
    }

    return ""
}

export const sortSessionUserList = (user1, user2) => {
    const priority = {
        [sessionUserTypeEnum.MODERATOR.value]: 1,
        [sessionUserTypeEnum.PARTICIPANT.value]: 2
    };

    const userType1 = user1?.userType || sessionUserTypeEnum.PARTICIPANT.value;
    const userType2 = user2?.userType || sessionUserTypeEnum.PARTICIPANT.value;

    if (priority[userType1] < priority[userType2]) {
        return -1;
    } else if (priority[userType1] > priority[userType2]) {
        return 1;
    }
    return 0;
};