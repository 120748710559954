import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Menu from "@mui/material/Menu";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";

import { menu, menuItems } from '../data';

import { deleteCourseDetail, updateCourseDetail } from "redux/course/course.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal, setShareSocial } from "redux/local/local.slice";
import {
  setClearCloneCourseDetail,
} from "redux/course/course.slice";
import { modalConst, socialAction } from "redux/local/local.const";
import { courseStatus, visibility } from "redux/course/course.const";

import { pagesInfo } from "utils/pagesInfo";
import { MdMoreVert } from "react-icons/md";
import { getCustomHostPath } from "utils/generators.utils";
import { EDULYTE_WEB_DOMAIN_URL } from "const/default.const";
import useCustomNavigation from "hooks/useCustomNavigation";

const useStyles = makeStyles({
  root: {},
});


const MyMenu = ({ course, updateCourseDetail, deleteCourseDetail }) => {
  const { modal, shareSocial } = useAppState((state) => state.local)
  const { publicOrgDetail } = useAppState((state) => state.org)
  const { cloneCourseDetail } = useAppState((state) => state.course)

  const dispatcher = useAppDispatcher()
  const customNavigation = useCustomNavigation()

  // const TUTOR_COURSE_URL = `${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/class/${course?.slug}` })}`
  const TUTOR_COURSE_URL = `${customNavigation.getCustomBaseUrl({ path: `/class/${course?.slug}`, isWeb: true })}`

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  const location = useLocation()
  const from = location.state?.from?.url || `${pagesInfo?.CREATE_COURSE?.pagePath}/${cloneCourseDetail?.data?.id}/edit?step=${cloneCourseDetail?.data?.step}`

  useEffect(() => {
    if (cloneCourseDetail?.data) {
      dispatcher(setClearCloneCourseDetail())
      navigate(from, { replace: true })
    }
  }, [cloneCourseDetail?.data])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (ele) => {
    setAnchorEl(null);
    switch (ele?.value) {
      case menuItems?.SHARE_LINK?.value:
        dispatcher(setShareSocial({
          ...shareSocial,
          socialAction: socialAction.SHARE_TUTOR_COURSE,
          url: TUTOR_COURSE_URL
        }))
        dispatcher(setModal({
          ...modal,
          [modalConst.SHARE_SOCIAL_MODAL.stateKey]: true
        }))
        break;
      case menuItems?.MAKE_PRIVATE?.value:
        updateCourseDetail(course?.id, { visibility: visibility?.PRIVATE?.value })
        break;
      case menuItems?.MAKE_PUBLIC?.value:
        updateCourseDetail(course?.id, { visibility: visibility?.PUBLIC?.value })
        break;
      case menuItems?.MODIFY_DETAILS?.value:
        const from = location.state?.from?.url || `${pagesInfo?.CREATE_COURSE?.pagePath}/${course?.id}/edit`
        navigate(from)
        break;
      case menuItems?.VIEW?.value:
        if (course?.status === courseStatus?.PUBLISHED?.value) {
          navigate(`${pagesInfo?.COURSE?.pagePath}/${course?.slug}`)
        }
        break;
      case menuItems?.DELETE?.value:
        alert("This will delete your draft and can’t be undone. Are you sure?")
        deleteCourseDetail(course?.id)
        break;
      default:
        break;
    }
  };

  return (
    <>
      <button className='hover:text-secondary-dark' onClick={handleClick}>
        <MdMoreVert />
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        classes={{
          root: classes.root,
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {
          course && menu[course?.status?.toUpperCase()] && menu[course?.status?.toUpperCase()][course?.visibility?.toLowerCase()]?.map((ele, idx) =>
            <MenuItem
              key={idx}
              onClick={() => handleClose(ele)}
              value={ele?.label}
            >
              {ele?.label}
            </MenuItem>
          )
        }
      </Menu>
    </>
  );
};

export default MyMenu;
