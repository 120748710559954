import { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import CardForm from 'components/modals/paymentCardModal/CardForm';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setJoinUserPaymentMethodDetailPayload } from 'redux/payment/payment.slice';

const PaymentCardModal = () => {
    const { user } = useAppState((state) => state.user)
    const { joinUserPaymentMethodDetail } = useAppState((state) => state.payment)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        dispatcher(setJoinUserPaymentMethodDetailPayload({
            ...joinUserPaymentMethodDetail?.payload,
            userId: user?.user?.userId,
            email: user?.user?.email,
            name: `${user?.user?.firstName} ${user?.user?.lastName}`
        }))
    }, [])

    const setStripeAccount = () => {

        if (!!user?.user?.gateways?.primary && user?.user?.gateways?.primary?.provider_type === 'stripe') {
            return user?.user?.gateways?.primary?.account_id
        } else if (!!user?.user?.gateways?.secondary && user?.user?.gateways?.secondary?.provider_type === 'stripe') {
            return user?.user?.gateways?.secondary?.account_id
        }
        return ""
    }

    return (
        <div className={"mt-5 space-y-5 overflow-x-hidden"}>
            <span className={"w-full text-center font-bodyPri font-medium text-base text-text-800"}>
                {"Fill Your Card Detail"}
            </span>
            {user?.user &&
                <Elements stripe={loadStripe(user?.user?.stripePk, {
                    stripeAccount: setStripeAccount()
                })}>
                    <CardForm />
                </Elements>
            }
        </div>
    )
}

export default PaymentCardModal