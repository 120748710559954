import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { FaEye, FaLayerGroup } from "react-icons/fa";

import { pageHeading, myBookingToolTip, tHeaders, sHeaders, getBookingListPayload, searchParamsInfo } from "pages/auth/bookings/data";

import { TableFilerActions } from "pages/auth/bookings/components/filters/TableFilterActions";
import BookingSideOverContent from 'pages/auth/bookings/detail';

import ToolTipView from 'components/tooltipView';
import PageHeader from 'components/pageHeader/PageHeader';
import SideOverPanel from "components/common-components/SideOverPanel";
import TableComponent from "components/common-components/Table";
import Pagination from "components/pagination/Pagination";

import BookingMenu from '../components/BookingMenu';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getTutorBookingList, getUserBookingList } from 'redux/booking/booking.request';

import { useTitle } from 'hooks/useTitle';
import { useAppDispatcher, useAppState } from "hooks/useStore";
import {
  setBookingDetailPayload,
  setClearFilterProps,
  resetUserBookingList,
  resetTutorBookingList,
  resetCancelBookingSessionDetail
} from "redux/booking/booking.slice";
import { userRoles } from 'redux/auth/auth.const';
import { bookingOfferingTypeEnum, bookingStatus } from 'redux/booking/booking.const';

import { pagesInfo } from 'utils/pagesInfo';
import { dayjs, timeZone } from "utils/dateTime.utils";

const BookingsPage = () => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)
  const { locals } = useAppState((s) => s.local)
  const { user } = useAppState((state) => state.user)
  const { userBookingList, tutorBookingList, cancelBookingSessionDetail } = useAppState((s) => s.booking)

  const dispatcher = useAppDispatcher()
  const navigate = useNavigate()
  const location = useLocation()
  const [title, setTitle] = useTitle()

  const [sideOverPanel, setSideOverPanel] = useState(false)

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.BOOKINGS))
  }, [dispatcher])

  useEffect(() => {
    setTitle({
      ...title,
      title: "Browse all Your Bookings and Take Action | Edulyte"
    })
  }, [dispatcher])

  useEffect(() => {
    return () => {
      dispatcher(resetUserBookingList())
      dispatcher(resetTutorBookingList())
      dispatcher(resetCancelBookingSessionDetail())
      dispatcher(setClearFilterProps())
    }
  }, [])

  const bookingList = useMemo(() => locals.userRole === userRoles?.STUDENT?.value ? userBookingList : tutorBookingList, [locals.userRole, userBookingList, tutorBookingList])
  let searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

  const getBookingList = async () => {
    const requestDataPayload = await getBookingListPayload(searchQueryParams)
    if (locals?.userRole === userRoles?.STUDENT?.value) {
      dispatcher(getUserBookingList(user?.user?.userId, { ...requestDataPayload }))
    }
    if (locals?.userRole === userRoles?.TUTOR?.value) {
      dispatcher(getTutorBookingList(user?.user?.userId, { ...requestDataPayload }))
    }
  }

  useEffect(() => {
    getBookingList()
  }, [cancelBookingSessionDetail?.data?.result])

  useEffect(() => {
    if (searchQueryParams?.get(searchParamsInfo.actionBookingId.key)) {
      const bookingId = searchQueryParams?.get(searchParamsInfo.actionBookingId.key)
      dispatcher(setBookingDetailPayload({ bookingId: bookingId }));
      setSideOverPanel(true)
    }
  }, [searchQueryParams.get(searchParamsInfo.actionBookingId.key)])

  const onHandleViewBookingDetail = useCallback((bookingId) => {
    dispatcher(setBookingDetailPayload({ bookingId: bookingId }));
    setSideOverPanel(true)
    searchQueryParams.set(searchParamsInfo.actionBookingId.key, bookingId)
    navigate(`${location.pathname}?${searchQueryParams.toString()}`)
  }, [location])

  const onHandleCloseBookingDetail = useCallback((isShow) => {
    setSideOverPanel(isShow)
    dispatcher(setBookingDetailPayload({ bookingId: null }));
    searchQueryParams.delete(searchParamsInfo.actionBookingId.key)
    navigate(`${location.pathname}?${searchQueryParams.toString()}`)
  }, [sideOverPanel, location])

  const onHandlePageChange = async (page) => {
    searchQueryParams.set(searchParamsInfo.page.key, page)
    navigate(`${location.pathname}?${searchQueryParams?.toString()}`);
  }

  const BookingSessionCount = memo(({ sessionCount, bookingId }) => {
    return (
      <>
        {sessionCount
          ? (sessionCount > 1)
            ? <span className={"flex items-center justify-start gap-2"}>
              <span className={"font-bodyPri font-normal"}>
                {sessionCount}
              </span>
              <ToolTipView content={"Multi-Session Package: Extended learning in one booking."}>
                <button className={""} onClick={() => onHandleViewBookingDetail(bookingId)}>
                  <FaLayerGroup className={"text-lg text-text-800 hover:text-secondary-dark"} />
                </button>
              </ToolTipView>
            </span>
            : sessionCount
          : "-"
        }
      </>
    )
  })

  const sRows = useMemo(() => {
    return bookingList?.data?.result?.map((booking) => [
      booking?.id,

      <BookingSessionCount sessionCount={booking?.booking_price?.session_count} bookingId={booking?.id} />,

      (booking?.offering_type === bookingOfferingTypeEnum?.APPOINTMENT?.value)
        ? `${booking?.appointment?.user?.first_name} ${booking?.appointment?.user?.last_name?.charAt(0)}.`
        : `${booking?.cohort?.course?.instructor?.user?.first_name} ${booking?.cohort?.course?.instructor?.user?.last_name?.charAt(0)}.`,

      (booking?.offering_type === bookingOfferingTypeEnum?.APPOINTMENT?.value)
        ? `${bookingOfferingTypeEnum[booking?.offering_type?.toUpperCase()]?.label}`
        : `${bookingOfferingTypeEnum[booking?.offering_type?.toUpperCase()]?.label}`,

      `${booking?.booking_price?.master_currency?.code} ${parseFloat(booking?.booking_price?.total_amount / 100).toFixed(2)}`,

      bookingStatus[booking?.status.toUpperCase()].label,

      dayjs(booking?.created_at).tz(timeZone).format('dddd, MMM DD, YYYY h:mm A'),

      <div className="flex space-x-1">
        <button
          className="p-1 text-sky-400"
          onClick={() => {
            dispatcher(setBookingDetailPayload({ bookingId: booking?.id }));
            setSideOverPanel(true)
            searchQueryParams.set(searchParamsInfo.actionBookingId.key, booking?.id)
            navigate(`${location.pathname}?${searchQueryParams.toString()}`)
          }}
        >
          <FaEye size={20} className="hover:scale-110" />
        </button>
        <button>
          {(locals.userRole === userRoles?.TUTOR.value) &&
            <BookingMenu booking={booking} />
          }
        </button>
      </div>,
    ]);
  }, [bookingList?.data])

  const tRows = useMemo(() => {
    return bookingList?.data?.result?.map((booking) => [
      booking?.id,

      <BookingSessionCount sessionCount={booking?.booking_price?.session_count} bookingId={booking?.id} />,

      `${booking?.user?.first_name} ${booking?.user?.last_name?.charAt(0)}.`,

      (booking?.offering_type === bookingOfferingTypeEnum?.APPOINTMENT?.value)
        ? `${bookingOfferingTypeEnum[booking?.offering_type?.toUpperCase()]?.label}`
        : `${bookingOfferingTypeEnum[booking?.offering_type?.toUpperCase()]?.label}`,

      `${booking?.booking_price?.master_currency?.code} ${parseFloat(booking?.booking_price?.total_amount / 100).toFixed(2)}` || "-",

      bookingStatus[booking?.status.toUpperCase()]?.label || "-",

      dayjs(booking?.created_at).tz(timeZone).format('dddd, MMM DD, YYYY h:mm A'),

      <div className="flex space-x-1">
        <button
          className="p-1 text-sky-400"
          onClick={() => onHandleViewBookingDetail(booking?.id)}
        >
          <FaEye size={20} className="hover:scale-110" />
        </button>
        <button>
          {(locals.userRole === userRoles?.TUTOR.value) &&
            <BookingMenu booking={booking} />
          }
        </button>
      </div>,
    ]);
  }, [bookingList?.data])

  return (
    <div className="h-full w-full p-3 mx-auto min-h-screen">
      <SideOverPanel
        child={<BookingSideOverContent />}
        isOpen={sideOverPanel}
        onClose={onHandleCloseBookingDetail}
      />
      <div className={"space-y-3"}>
        <PageHeader
          pageHeading={pageHeading}
          toolTipText={myBookingToolTip}
        />
        {/* <MyBookingSearch /> */}
        <TableFilerActions />
        <TableComponent
          isLoading={bookingList?.isLoading}
          headers={locals.userRole === userRoles?.STUDENT.value ? sHeaders : tHeaders}
          rows={locals.userRole === userRoles?.STUDENT.value ? sRows : tRows}
          message={(bookingList?.message || (bookingList?.data?.pagination?.records === 0)) && (bookingList?.message || "No bookings found")}
          alternateRowColor={"bg-sky-100"}
          rowHeaderColor={"bg-sky-200"}
        />
        {(!!bookingList?.data?.result?.length && (bookingList?.data?.pagination?.totalPages > 1)) &&
          <div className="w-full flex justify-center items-center">
            <Pagination
              page={bookingList?.data?.pagination?.page}
              totalPages={bookingList?.data?.pagination?.totalPages}
              onChangePage={onHandlePageChange}
            />
          </div>
        }
      </div>
    </div>
  );
}

export default BookingsPage;
