import { useEffect, useState } from 'react';
import { cn } from "utils/cn.utils";

import { MdAccessTimeFilled } from 'react-icons/md';
import { HiVideoCamera } from 'react-icons/hi';
import { AiTwotoneCalendar } from 'react-icons/ai';

import ComponentLoader from 'components/loader/ComponentLoader';
import TimeSlot from 'components/modals/checkoutSlotModal/TimeSlot';

import { getAvailableSlotDetail } from 'redux/availability/availability.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { resetAvailableSlotDetail } from 'redux/availability/availability.slice';
import { bookingOfferingTypeEnum } from 'redux/booking/booking.const';

import { dayjs, timeZone, getTimeZoneOffset } from 'utils/dateTime.utils';

const ChooseTimeSlotModal = () => {
    const { userBookingDetail } = useAppState((s) => s.booking)
    const { availableSlotDetail } = useAppState((s) => s.availability)

    const dispatcher = useAppDispatcher()

    const [nowDayjs, setNowDayjs] = useState(dayjs())

    const timeZoneOffset = getTimeZoneOffset(timeZone)

    useEffect(() => {
        const interval = setInterval(() => {
            setNowDayjs(dayjs())
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    })

    useEffect(() => {
        return () => {
            dispatcher(resetAvailableSlotDetail())
        }
    }, [])

    useEffect(() => {
        if (userBookingDetail?.data?.result?.offering_type === bookingOfferingTypeEnum.APPOINTMENT.value) {
            dispatcher(getAvailableSlotDetail(userBookingDetail?.data?.result?.appointment?.appointment_availability?.availability?.id, { timeZone: timeZone, duration: userBookingDetail?.data?.result?.appointment?.appointment_availability?.duration || 60 }))
        }
    }, [])

    if (userBookingDetail?.isLoading) {
        return (
            <ComponentLoader
                isLoading={userBookingDetail?.isLoading}
                className={"min-h-[24rem] flex justify-center items-center"}
            />
        )
    }

    if (userBookingDetail?.message) {
        return (
            <div className={"min-h-[24rem] flex justify-center items-center"}>
                <span className={"font-bodyPri font-medium text-md text-text-700"}>
                    {userBookingDetail?.message}
                </span>
            </div>
        )
    }

    if (!userBookingDetail?.data?.result) {
        return (
            <div className={"min-h-[24rem] flex justify-center items-center"}>
                <span className={"font-bodyPri font-medium text-md text-text-700"}>
                    {"Something went wrong"}
                </span>
            </div>
        )
    }

    const getUser = () => {
        if (userBookingDetail?.data?.result?.offering_type === bookingOfferingTypeEnum.COHORT.value) {
            return userBookingDetail?.data?.result?.course?.tutor?.user
        } else if (userBookingDetail?.data?.result?.offering_type === bookingOfferingTypeEnum.APPOINTMENT.value) {
            return userBookingDetail?.data?.result?.appointment?.user
        }
    }

    return (
        <div className={"space-y-5"}>
            <div className={"min-h-[24rem] block"}>

                <div className={'grid grid-cols-12 gap-5'}>

                    <div className={"col-span-12 space-y-3"}>
                        {/* {(userBookingDetail?.data?.result?.offering_type === bookingOfferingTypeEnum.COHORT.value) &&
                            <span className={"truncate block text-center font-bodyPri font-medium text-base text-text-900"}>
                                {userBookingDetail?.data?.result?.course?.title?.substring(0, 50) + ((userBookingDetail?.data?.result?.course?.title?.length > 50) ? "..." : "")}
                            </span>
                        } */}
                        {userBookingDetail?.data?.result?.offering_type === bookingOfferingTypeEnum.APPOINTMENT.value &&
                            <span className={"truncate block text-center font-bodyPri font-medium text-base text-text-900"}>
                                {userBookingDetail?.data?.result?.appointment?.title?.substring(0, 50) + ((userBookingDetail?.data?.result?.appointment?.title?.length > 50) ? "..." : "")}
                            </span>
                        }
                        <div className={"h-0.5 bg-divider-medium w-full rounded-full"}></div>
                    </div>

                    <div className={"col-span-12 md:col-span-4 space-y-3"}>
                        {/* {(userBookingDetail?.data?.result?.offering_type === bookingOfferingTypeEnum.COHORT.value) &&
                            <div className={"w-52 h-32 border border-border-medium rounded-lg overflow-hidden"}>
                                <img
                                    src={userBookingDetail?.data?.result?.course?.thumbnailPicUrl}
                                    alt={"course-thumbnail"}
                                    className={"w-full h-full object-cover"}
                                />
                            </div>
                        } */}

                        <div className={"w-full md:w-52 flex flex-col items-start gap-2 overflow-hidden"}>

                            <div className={"flex items-center justify-start gap-2"}>
                                <div className='relative w-12 h-12 rounded-full'>
                                    <img
                                        src={getUser()?.profile_pic_url}
                                        alt={"profile-pic"}
                                        className="w-full h-full rounded-full object-cover"
                                    />
                                    <div className='absolute right-0.5 bottom-0.5 w-3 h-3 rounded-full overflow-hidden border-white'>
                                        <img
                                            src={`https://flagcdn.com/16x12/${getUser()?.from_country?.country_domain}.png`.toLowerCase()}
                                            alt={"country-flag"}
                                            className='w-full h-full object-cover'
                                        />
                                    </div>
                                </div>
                                <span className='font-normal font-bodyPri text-sm tracking-wide'>
                                    {getUser()?.first_name + " " + getUser()?.last_name.charAt(0) + "."}
                                </span>
                            </div>

                            <span className={"font-bodyPri font-normal text-sm text-text-700 flex justify-start items-center gap-1"}>
                                <MdAccessTimeFilled className={"text-sm"} />
                                {userBookingDetail?.data?.result?.appointment?.appointment_availability?.duration + " min | " + userBookingDetail?.data?.result?.booking_price?.session_count + " sessions"}
                            </span>
                            <span className={"inline space-x-2 font-bodyPri font-normal text-sm text-text-700"}>
                                <HiVideoCamera className={"text-md inline"} />
                                {/* {(userBookingDetail?.data?.result?.offering_type === bookingOfferingTypeEnum.COHORT.value) ? userBookingDetail?.data?.result?.course?.subTitle : ""} */}
                                {userBookingDetail?.data?.result?.offering_type === bookingOfferingTypeEnum.APPOINTMENT.value ? userBookingDetail?.data?.result?.appointment?.subtitle : ""}
                            </span>
                        </div>
                    </div>

                    <div className={"col-span-12 md:col-span-8 space-y-2"}>

                        <div className={"flex flex-wrap items-center justify-between"}>
                            <span className={"font-bodyPri font-normal text-sm text-text-700 text-center"}>
                                {"Available Slots"}
                            </span>
                            <span className={"font-bodyPri font-normal text-sm text-text-700 text-center"}>
                                {nowDayjs.tz(timeZone).format(`ddd, DD/MMM/YY hh:mm:ss A (z)`)}
                            </span>
                        </div>

                        <div className={cn(
                            "w-full flex flex-col items-start justify-start gap-5 h-full md:h-96 overflow-hidden overflow-y-scroll",
                            "scrollbar-thin scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                            "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                        )}>
                            <ComponentLoader isLoading={availableSlotDetail?.isLoading} />
                            {(availableSlotDetail?.message || availableSlotDetail?.data?.result?.availableSlots?.length <= 0) &&
                                <div className={"w-full h-full flex items-center justify-center"}>
                                    <span className={"font-bodyPri font-semibold text-md text-text-700"}>
                                        {"No Available Slots"}
                                    </span>
                                </div>
                            }
                            {!!availableSlotDetail?.data?.result?.availableSlots?.length
                                && availableSlotDetail?.data?.result?.availableSlots?.map((dailyAvail, index) => (
                                    <div key={index} className={"flex flex-col items-start justify-start gap-3"}>
                                        <span className={"font-bodyPri font-medium text-base text-text-900 flex items-center justify-start gap-2"}>
                                            <AiTwotoneCalendar />
                                            {dayjs(dailyAvail.date + timeZoneOffset, "YYYY-MM-DDZ").tz(timeZone).format('dddd, DD MMM YYYY')}
                                        </span>
                                        <div className={"px-3 flex flex-wrap items-center gap-2"}>
                                            {dailyAvail.timeSlots.map((timeSlot, index) => (
                                                <TimeSlot key={index} timeSlot={timeSlot} dailyAvail={dailyAvail} />
                                            ))}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChooseTimeSlotModal