import { useEffect } from "react";

import ComponentLoader from "components/loader/ComponentLoader";

import BookingInterviewerCard from "./BookingInterviewerCard";
import BookingTutorCard from "./BookingTutorCard";

import { getTutorUserPublicProfileByUserId } from "redux/tutor/tutor.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { bookingOfferingTypeEnum } from "redux/booking/booking.const";

const BookingCard = () => {
    const { userPreBookingDetail } = useAppState((state) => state.booking)
    const { tutorPublicProfile } = useAppState((s) => s.tutor)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        if (userPreBookingDetail?.data?.result) {
            if (userPreBookingDetail?.data?.result?.offering_type === bookingOfferingTypeEnum.COHORT.value) {
                dispatcher(getTutorUserPublicProfileByUserId(userPreBookingDetail?.data?.result?.course?.tutor?.user?.id))
            }
            if (userPreBookingDetail?.data?.result?.offering_type === bookingOfferingTypeEnum.APPOINTMENT.value) {
                dispatcher(getTutorUserPublicProfileByUserId(userPreBookingDetail?.data?.result?.appointment?.user?.id))
            }

        }
    }, [userPreBookingDetail?.data?.result])

    return (
        <div className={"w-full md:w-[28rem] h-[44rem] bg-white rounded-lg shadow-all space-y-5 p-5"}>
            {tutorPublicProfile?.isLoading &&
                <ComponentLoader isLoading={tutorPublicProfile?.isLoading} />
            }
            {!tutorPublicProfile?.isLoading && (userPreBookingDetail?.data?.result?.offering_type === bookingOfferingTypeEnum?.APPOINTMENT?.value) &&
                <BookingInterviewerCard />
            }
            {!tutorPublicProfile?.isLoading && (userPreBookingDetail?.data?.result?.offering_type === bookingOfferingTypeEnum?.COHORT?.value) &&
                <BookingTutorCard />
            }
            {tutorPublicProfile?.errorMsg &&
                <span className={"flex items-center justify-center font-bodyPri font-normal text-red-500 text-base tracking-wide"}>
                    {tutorPublicProfile?.errorMsg}
                </span>
            }
        </div>
    )
}

export default BookingCard;