import { useEffect, useState } from 'react'

import { z } from "zod";
import { Controller, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod";

import { IoIosCheckmark, IoIosRadioButtonOff, IoIosRadioButtonOn } from 'react-icons/io'

import FloatingLabelSelect from 'components/floating/floatingLabelSelect'
import ButtonLoader from 'components/loader/button-loader'

import { useAppDispatcher, useAppState } from 'hooks/useStore'

import { setModal } from 'redux/local/local.slice'

import { updateUserBookingDetail } from 'redux/booking/booking.request'

import { locationPhoneCallTypeEnum, locationTypeEnum } from 'redux/appointment/appointment.const'
import { bookingOfferingTypeEnum } from 'redux/booking/booking.const'
import { modalConst } from 'redux/local/local.const'

import { cn } from 'utils/cn.utils'
import { timeZone } from 'utils/dateTime.utils'


const updateBookingSchema = z.object({
    appointment: z.object({
        appointment_id: z.number({ message: "invalid Appointment" }),
        timeZone: z.literal(timeZone),
        appointment_location: z.object({
            appointment_location_id: z.number({ message: "Location is Required" }),
            phone_call: z.object({
                location_phone_call_type: z.literal(locationPhoneCallTypeEnum.i_will_call.key),
                phone_number: z.string({ message: "Phone number is Required" }).regex(/^[0-9][0-9]{0,2}-[0-9]{6,15}[0-9]$/, { message: "Invalid Phone number" })
            }).optional(),
            invitee: z.object({
                custom_text: z.string().trim()
                    .min(10, { message: "Text length not be less then 10 character" })
                    .max(500, { message: "Text length mot be more then 500 character" })
            }).optional()
        }, { message: "Location is Required" })
    })
})


const CheckoutLocationModal = () => {
    const { modal } = useAppState((s) => s.local)
    const { userBookingDetail, modifyUserBookingDetail } = useAppState((s) => s.booking)
    const { countryList, timeZoneCountryDetail } = useAppState((state) => state.master)

    const dispatcher = useAppDispatcher()

    const {
        register,
        unregister,
        control,
        formState: { errors, isValid, isLoading },
        setValue,
        trigger,
        watch,
        setFocus
    } = useForm({
        mode: "onChange",
        resolver: zodResolver(updateBookingSchema)
    })

    const values = watch({ control })

    useEffect(() => {
        setValue("appointment.appointment_id", userBookingDetail?.data?.result?.appointment?.id)
        setValue("appointment.timeZone", timeZone)
        setValue("appointment.appointment_location.appointment_location_id", parseInt(userBookingDetail?.data?.result?.booking_location?.reference_id || "0"))
        if (userBookingDetail?.data?.result?.booking_location?.location_type === locationTypeEnum.phone_call.key && userBookingDetail?.data?.result?.booking_location?.phone_call?.location_phone_call_type === locationPhoneCallTypeEnum.i_will_call.key) {
            setValue("appointment.appointment_location.phone_call.location_phone_call_type", locationPhoneCallTypeEnum.i_will_call.key)
            setValue("appointment.appointment_location.phone_call.phone_number",
                `${userBookingDetail?.data?.result?.booking_location?.phone_call?.isd_code}-${userBookingDetail?.data?.result?.booking_location?.phone_call?.phone_number}`
            )
            setFocus("appointment.appointment_location.phone_call.phone_number")
        } else if (userBookingDetail?.data?.result?.booking_location?.location_type === locationTypeEnum.invitee.key) {
            setValue("appointment.appointment_location.invitee.custom_text", userBookingDetail?.data?.result?.booking_location?.invitee?.custom_text)
            setFocus("appointment.appointment_location.invitee.custom_text")
        }
    }, [setValue, userBookingDetail?.data?.result])

    const onHandleSetLocation = (selectedLocation) => {
        setValue("appointment.appointment_location.appointment_location_id", selectedLocation?.id)
        if (selectedLocation?.location_type === locationTypeEnum.phone_call.key && selectedLocation?.phone_call?.location_phone_call_type === locationPhoneCallTypeEnum.i_will_call.key) {
            let myCountry = countryList?.countryList?.find(countryItem => countryItem?.masterCountryId === timeZoneCountryDetail?.timeZoneCountryDetail?.country?.masterCountryId)
            setValue("appointment.appointment_location.phone_call.location_phone_call_type", locationPhoneCallTypeEnum.i_will_call.key)
            setValue("appointment.appointment_location.phone_call.phone_number", `${myCountry?.isdCode}-`)
            setFocus("appointment.appointment_location.phone_call.phone_number")
            unregister("appointment.appointment_location.invitee")
        } else if (selectedLocation?.location_type === locationTypeEnum.invitee.key) {
            setValue("appointment.appointment_location.invitee.custom_text", "")
            setFocus("appointment.appointment_location.invitee.custom_text")
            unregister("appointment.appointment_location.phone_call")
        } else {
            unregister("appointment.appointment_location.phone_call")
            unregister("appointment.appointment_location.invitee")
        }
    }

    const handleSubmitLocation = async () => {

        if (modifyUserBookingDetail?.payload?.offeringType === bookingOfferingTypeEnum.COHORT.value) {
            return
        }

        const is_valid = await trigger()
        if (!is_valid) return

        dispatcher(updateUserBookingDetail(userBookingDetail?.data?.result?.id, values))

        setTimeout(() => {
            dispatcher(setModal({
                ...modal,
                [modalConst?.checkoutLocationModal.key]: modalConst.checkoutLocationModal
            }))
        }, 2000)
    }

    return (
        <div className={"space-y-3"}>
            <div className={"flex items-center justify-between"}>
                <div className={"flex items-center justify-start gap-0.5"}>
                    <span className={"font-bodyPri font-medium text-md text-text-800"}>
                        {"Select Location:"}
                    </span>
                </div>
            </div>

            <div className={"space-y-4"}>
                {userBookingDetail?.data?.result?.appointment?.appointment_locations?.map((location, index) => (
                    <div key={index} className={"flex items-start justify-start gap-2"}>
                        <div onClick={() => onHandleSetLocation(location)} className={"cursor-pointer"}>
                            {location?.id === values?.appointment?.appointment_location?.appointment_location_id ? (
                                <IoIosRadioButtonOn className="text-2xl text-primary-dark" />
                            ) : (
                                <IoIosRadioButtonOff className="text-2xl text-text-700" />
                            )}
                        </div>
                        {location?.location_type === locationTypeEnum.custom.key && (
                            <div className={"w-full flex items-start justify-start gap-2"}>
                                <locationTypeEnum.custom.icon className={"text-2xl text-text-700"} />
                                <p className={"font-bodyPri font-medium text-md text-text-800"}>
                                    {location?.custom?.custom_text}
                                </p>
                            </div>
                        )}
                        {location?.location_type === locationTypeEnum.in_person_meeting.key && (
                            <div className={"w-full flex items-start justify-start gap-2"}>
                                <locationTypeEnum.in_person_meeting.icon className={"text-2xl text-text-700"} />
                                <p className={"flex flex-wrap gap-1 font-bodyPri font-medium text-md text-text-800 "}>
                                    <span>{location?.in_person_meeting?.street_line_1}</span>
                                    {location?.in_person_meeting?.street_line_2 && (<>|<span>{location?.in_person_meeting?.street_line_2}</span></>)}
                                    {location?.in_person_meeting?.city && (<>|<span>{location?.in_person_meeting?.city}</span></>)}
                                    {location?.in_person_meeting?.post_office && (<>|<span>{location?.in_person_meeting?.post_office}</span></>)}
                                    {location?.in_person_meeting?.state && (<>|<span>{location?.in_person_meeting?.state}</span></>)}
                                    {location?.in_person_meeting?.country && (<>|<span>{location?.in_person_meeting?.country}</span></>)}
                                    {location?.in_person_meeting?.zip && (<>|<span>{location?.in_person_meeting?.zip}</span></>)}
                                    {location?.in_person_meeting?.label && (<>|<span>{location?.in_person_meeting?.label}</span></>)}
                                </p>
                            </div>
                        )}
                        {location?.location_type === locationTypeEnum.phone_call.key && (
                            <div className={"w-full space-y-0.5"}>
                                <div className={"w-full flex items-start justify-start gap-2"}>
                                    <locationTypeEnum.phone_call.icon className={"text-2xl text-text-700"} />
                                    <p className={"font-bodyPri font-medium text-md text-text-800"}>
                                        {locationTypeEnum.phone_call.label}
                                    </p>
                                </div>
                                {location?.id === values?.appointment?.appointment_location?.appointment_location_id && location?.phone_call?.location_phone_call_type === locationPhoneCallTypeEnum.call_me.key && (
                                    <p className={"font-bodyPri font-normal text-xs text-text-800"}>
                                        {"You will call the Host. Phone number will be provided upon booking completion."}
                                    </p>
                                )}
                                {location?.id === values?.appointment?.appointment_location?.appointment_location_id && location?.phone_call?.location_phone_call_type === locationPhoneCallTypeEnum.i_will_call.key && (
                                    <Controller
                                        name={"appointment.appointment_location.phone_call.phone_number"}
                                        control={control}
                                        render={({ field: { value, onChange, ref }, fieldState: { error } }) => (
                                            <div className={"space-y-1"}>
                                                <div className={"flex items-center justify-start gap-2 h-10"}>
                                                    <FloatingLabelSelect
                                                        labelItem={`phone_country`}
                                                        searchable={true}
                                                        options={countryList?.countryList?.map((country) => ({
                                                            label: `${country?.country} (+${country?.isdCode})`,
                                                            value: country?.isdCode,
                                                            country: country
                                                        })) || []}
                                                        onHandleSelect={(selectedOption) => {
                                                            onChange(
                                                                `${selectedOption?.value}-${value?.split("-")[1]}`
                                                            )
                                                        }}
                                                        value={
                                                            <div className={"flex items-center gap-1"}>
                                                                <img
                                                                    src={`https://flagcdn.com/16x12/${countryList?.countryList?.find(countryItem => countryItem?.isdCode === value?.split("-")[0])?.countryDomain}.png`.toLowerCase()}
                                                                    className={"w-8 h-5 object-cover"}
                                                                    alt={"country-flag"}
                                                                />
                                                                <span className={"text-sm"}>{` (+${value?.split("-")[0]})`}</span>
                                                            </div>
                                                        }
                                                        OptionChild={({ option }) => (
                                                            <div className="bg-white hover:bg-gray-100 px-3 py-2 flex items-center justify-between">
                                                                <div className="flex items-center gap-3">
                                                                    <img
                                                                        src={`https://flagcdn.com/16x12/${option?.country?.countryDomain}.png`.toLowerCase()}
                                                                        className={"w-6 h-4 object-cover"}
                                                                        alt={"country-flag"}
                                                                    />
                                                                    {option?.label}
                                                                </div>
                                                                {(option?.value === value?.split("-")[0])
                                                                    && <IoIosCheckmark size={20} color="green" />
                                                                }
                                                            </div>
                                                        )}
                                                        label={"Country"}
                                                        dropdownWrapperClassName={"w-full sm:w-[25.4rem]"}
                                                        dropDownContainerClassName={"w-full max-h-60 overflow-y-auto scrollbar-thin"}
                                                    />
                                                    <div
                                                        className={cn(
                                                            "relative group min-w-[3rem] w-fit h-full p-2 flex gap-3 items-center border rounded focus-within:shadow",
                                                            !!error
                                                                ? 'border-secondary-dark focus-within:border-secondary-main shadow shadow-secondary-main/20 focus-within:shadow-secondary-main/20'
                                                                : !!value
                                                                    ? 'border-primary-main focus-within:border-primary-main shadow shadow-primary-main/20 focus-within:shadow-primary-main/20'
                                                                    : 'border-text-400 focus-within:border-primary-main focus-within:shadow-primary-main/20'
                                                        )}
                                                    >
                                                        <input
                                                            ref={ref}
                                                            type={"tel"}
                                                            className={('w-full h-full bg-transparent outline-none text-base')}
                                                            placeholder={"Your Phone number..."}
                                                            value={value?.split("-")[1]}
                                                            onChange={(e) => {
                                                                onChange(
                                                                    `${value?.split("-")[0]}-${e.target.value}`
                                                                )
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                {!!error && (
                                                    <div className={"flex items-center justify-start"}>
                                                        <span className={"font-bodyPri font-normal text-sm text-secondary-main"}>
                                                            {error?.message}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    />
                                )}
                            </div>
                        )}
                        {location?.location_type === locationTypeEnum.invitee.key && (
                            <div className={"w-full space-y-0.5"}>
                                <div className={"w-full flex items-start justify-start gap-2"}>
                                    <locationTypeEnum.invitee.icon className={"text-2xl text-text-700"} />
                                    <p className={"font-bodyPri font-medium text-md text-text-800"}>
                                        {"Somewhere else"}
                                    </p>
                                </div>
                                {location?.id === values?.appointment?.appointment_location?.appointment_location_id && (
                                    <div className={"space-y-0.5"}>
                                        <div className={"flex items-center justify-end"}>
                                            <span className={"font-bodyPri font-normal text-xs text-text-600 text-right"}>
                                                {`${values?.appointment?.appointment_location?.invitee?.custom_text?.length} / {10, 500} characters`}
                                            </span>
                                        </div>
                                        <div
                                            className={cn(
                                                "relative group w-full h-full min-h-20 p-2 flex gap-3 items-center border rounded focus-within:shadow",
                                                !!errors?.appointment?.appointment_location?.invitee?.custom_text
                                                    ? 'border-secondary-dark focus-within:border-secondary-main shadow shadow-secondary-main/20 focus-within:shadow-secondary-main/20'
                                                    : !!values?.appointment?.appointment_location?.invitee?.custom_text
                                                        ? 'border-primary-main focus-within:border-primary-main shadow shadow-primary-main/20 focus-within:shadow-primary-main/20'
                                                        : 'border-text-400 focus-within:border-primary-main focus-within:shadow-primary-main/20'
                                            )}
                                        >
                                            <textarea
                                                className={('w-full h-full bg-transparent outline-none text-base')}
                                                placeholder={"Write your place..."}
                                                {...register("appointment.appointment_location.invitee.custom_text")}
                                            />
                                        </div>
                                        {!!errors?.appointment?.appointment_location?.invitee?.custom_text && (
                                            <div className={"flex items-center justify-start"}>
                                                <span className={"font-bodyPri font-normal text-xs text-secondary-main"}>
                                                    {errors?.appointment?.appointment_location?.invitee?.custom_text?.message}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                        {location?.location_type === locationTypeEnum.edulyte_meet.key && (
                            <div className={"w-full space-y-0.5"}>
                                <div className={"w-full flex items-start justify-start gap-2"}>
                                    <locationTypeEnum.edulyte_meet.icon className={"text-2xl text-text-700"} />
                                    <p className={"font-bodyPri font-medium text-md text-text-800"}>
                                        {locationTypeEnum.edulyte_meet?.label}
                                    </p>
                                </div>
                                {location?.id === values?.appointment?.appointment_location?.appointment_location_id && (
                                    <p className={"font-bodyPri font-normal text-xs text-text-800"}>
                                        {"Web conferencing details provided upon confirmation."}
                                    </p>
                                )}
                            </div>
                        )}
                        {location?.location_type === locationTypeEnum.google_meet.key && (
                            <div className={"w-full space-y-0.5"}>
                                <div className={"w-full flex items-start justify-start gap-2"}>
                                    <locationTypeEnum.google_meet.icon className={"text-2xl text-text-700"} />
                                    <p className={"font-bodyPri font-medium text-md text-text-800"}>
                                        {locationTypeEnum.google_meet?.label}
                                    </p>
                                </div>
                                {location?.id === values?.appointment?.appointment_location?.appointment_location_id && (
                                    <p className={"font-bodyPri font-normal text-xs text-text-800"}>
                                        {"Web conferencing details provided upon confirmation."}
                                    </p>
                                )}
                            </div>
                        )}
                    </div>
                ))}
            </div>
            {(!!errors?.appointment?.appointment_location || !!errors?.appointment?.appointment_location?.appointment_location_id) && (
                <div className={"flex justify-start items-center"}>
                    <span className={"font-bodyPri font-normal text-sm text-secondary-main"}>
                        {errors?.appointment?.appointment_location?.message || errors?.appointment?.appointment_location?.appointment_location_id?.message}
                    </span>
                </div>
            )}
            <div className={"flex justify-end"}>
                <button
                    type="button"
                    onClick={handleSubmitLocation}
                    disabled={modifyUserBookingDetail?.isLoading || isLoading || !isValid}
                    className={cn(
                        "px-4 py-2 flex justify-center items-center rounded-full cursor-pointer",
                        "font-buttons font-normal text-base text-text-50 bg-secondary-main hover:bg-secondary-dark",
                        !isValid ? "!text-secondary-light !border-secondary-light bg-white hover:bg-white hover:text-secondary-light cursor-not-allowed" : ""
                    )}
                >
                    {modifyUserBookingDetail?.isLoading || isLoading
                        ? <ButtonLoader />
                        : "Update Location"
                    }
                </button>
            </div>
        </div>
    )
}

export default CheckoutLocationModal