import { cn } from "utils/cn.utils";

import { useAppState } from 'hooks/useStore';

import { dayjs, timeZone, getTimeZoneOffset } from 'utils/dateTime.utils';


const SessionDetailDateTimeSlot = () => {
    const { sessionUserDetail } = useAppState(s => s.session)

    return sessionUserDetail?.data?.result ? (
        <div className={cn(
            "flex flex-col items-center justify-center gap-2 p-3 mx-5",
            "bg-primary-light rounded-lg border-2 border-divider-medium",
            "font-bodyPri font-medium text-base text-primary-dark",
        )}>
            <span className={""}>
                {dayjs(sessionUserDetail?.data?.result?.start_datetime).tz(timeZone).format('dddd, DD MMM YYYY')}
            </span>
            <span className={""}>
                {dayjs(sessionUserDetail?.data?.result?.start_datetime).tz(timeZone).format('hh:mm A') + " - " + dayjs(sessionUserDetail?.data?.result?.end_datetime).tz(timeZone).format('hh:mm A')}
            </span>
            <span className={"text-xs"}>
                {`${timeZone} ${getTimeZoneOffset(timeZone)}`}
            </span>
        </div>
    ) : null
}

export default SessionDetailDateTimeSlot