import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { toast } from "react-toastify";

import courseServices from "redux/course/course.services";
import { cohortEnrollmentCloseEnum } from 'redux/course/course.const';
import { dayjs, timeZone } from "utils/dateTime.utils";

const cohortDetailInitialState = { isLoading: false, result: null, error: null }

export const useGetCohortDetail = ({ setValue }) => {

    const [cohortDetail, setCohortDetail] = useState(cohortDetailInitialState)

    const { cohortId } = useParams()

    useEffect(() => {
        if (!!cohortId) {
            getCohortDetail()
        }
    }, [cohortId])

    useEffect(() => {
        if (!!cohortDetail?.result) {
            setValue("title", cohortDetail?.result?.title)
            if (!!cohortDetail?.result?.description) {
                setValue("description", cohortDetail?.result?.description)
            }
            setValue("enrollment_start_datetime", dayjs(cohortDetail?.result?.enrollment_start_datetime).tz(timeZone).format("YYYY-MM-DD HH:mm:ss"))
            setValue("enrollment_close", cohortDetail?.result?.enrollment_close)
            if ([cohortEnrollmentCloseEnum.participant_limit.key]?.includes(cohortDetail?.result?.enrollment_close)) {
                setValue("participant_limit", Number(cohortDetail?.result?.participant_limit) ?? 0)
            } else if ([cohortEnrollmentCloseEnum.datetime.key]?.includes(cohortDetail?.result?.enrollment_close)) {
                setValue("enrollment_close_datetime", dayjs(cohortDetail?.result?.enrollment_close_datetime).tz(timeZone).format("YYYY-MM-DD HH:mm:ss") ?? null)
            }
            setValue("status", cohortDetail?.result?.status)
            setValue("is_paid", cohortDetail?.result?.is_paid)
            if (!!cohortDetail?.result?.is_paid) {
                setValue("cohort_price.paid_session_count", Number(cohortDetail?.result?.cohort_price?.paid_session_count ?? 0))
                setValue("cohort_price.currency_code", cohortDetail?.result?.cohort_price?.master_currency?.code ?? "")
                setValue("cohort_price.price", Number(cohortDetail?.result?.cohort_price?.price ?? 0))
                setValue("cohort_price.discount_pct", Number(cohortDetail?.result?.cohort_price?.discount_pct ?? 0))
            }
        }
    }, [cohortDetail?.result])

    const getCohortDetail = async () => {
        setCohortDetail((prevState) => ({ ...prevState, isLoading: true }))

        try {
            const requestData = {
                params: { cohortId: Number(cohortId) }
            }
            const response = await courseServices.getCohortDetail(requestData)
            if (response.status === 200) {
                setCohortDetail((prevState) => ({ ...prevState, result: response?.data?.data?.result }))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
            setCohortDetail((prevState) => ({ ...prevState, error: error?.response?.data?.message || error?.response?.data?.error || "Something went wrong" }))
            toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        } finally {
            setCohortDetail((prevState) => ({ ...prevState, isLoading: false }))
        }
    }

    return { cohortDetail, setCohortDetail, getCohortDetail };
}