import { useEffect, useMemo } from 'react';
import { cn } from "utils/cn.utils";

import { BsCreditCard2Back } from 'react-icons/bs';
import { MdOutlineRadioButtonChecked } from 'react-icons/md';
import { MdOutlineRadioButtonUnchecked } from 'react-icons/md';
import { ImCheckboxChecked } from 'react-icons/im';
import { ImCheckboxUnchecked } from 'react-icons/im';
import { BiCreditCard } from 'react-icons/bi';

import { Toggle } from "components/common-components/Toggle";
import ComponentLoader from "components/loader/ComponentLoader";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { getUserPaymentMethodList } from 'redux/payment/payment.request';
import { updateUserBookingDetail } from 'redux/booking/booking.request';
import { setModal } from 'redux/local/local.slice';
import { setModifyUserBookingDetailPayload } from 'redux/booking/booking.slice';
import { setAddPaymentIntentDetailPayload } from 'redux/payment/payment.slice';
import { modalConst } from 'redux/local/local.const';
import { masterCurrencyCodeEnum } from 'redux/master/master.const';
import { paymentFundingEnum, gatewayProviderEnum } from 'redux/payment/payment.const';
import { bookingOfferingTypeEnum } from 'redux/booking/booking.const';

import { timeZone } from 'utils/dateTime.utils';

const PaymentMethodSection = ({ handleWalletToggle }) => {
    const { modal } = useAppState(s => s.local)
    const { user } = useAppState(s => s.user)
    const { userBookingDetail, modifyUserBookingDetail } = useAppState((state) => state.booking)
    const { userPaymentMethodList, addPaymentIntentDetail } = useAppState((state) => state.payment)

    const dispatcher = useAppDispatcher()

    const currentMasterCurrency = useMemo(() => userBookingDetail?.data?.result?.booking_price?.master_currency?.code, [userBookingDetail?.data?.result?.booking_price?.master_currency])
    let isGatewayIncluded = modifyUserBookingDetail?.payload?.isGatewayIncluded

    const GATEWAYS = useMemo(() => {
        const gateways = [];
        const { primary, secondary } = user?.user?.gateways || {};
        
        if (primary) {
            gateways.push({ isPrimary: true, provider: gatewayProviderEnum[primary.provider_type] });
        }

        if (secondary) {
            gateways.push({ isPrimary: false, provider: gatewayProviderEnum[secondary.provider_type] });
        }

        return gateways;
    }, [user?.user?.gateways]);

    useEffect(() => {
        if (isGatewayIncluded && GATEWAYS) {
            if (currentMasterCurrency === masterCurrencyCodeEnum.INR.value) { 
                selectGateway(gatewayProviderEnum.razorpay)
            } else {
                selectGateway(gatewayProviderEnum.stripe)
            }
        }
    }, [currentMasterCurrency, isGatewayIncluded])

    useEffect(() => {
        if (addPaymentIntentDetail?.payload?.gatewayName === gatewayProviderEnum.stripe.key) {
            dispatcher(getUserPaymentMethodList(user?.user?.userId))
        }
    }, [addPaymentIntentDetail?.payload?.gatewayName])

    useEffect(() => {
        if (userPaymentMethodList?.data && userPaymentMethodList?.data?.length > 0) {
            selectPaymentMethod(userPaymentMethodList?.data[0])
        }
    }, [userPaymentMethodList?.data])

    const addPaymentMethod = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.PAYMENT_CARD_MODAL.stateKey]: true
        }))
    }

    const selectGateway = (gateway) => {
        dispatcher(setModifyUserBookingDetailPayload({
            ...modifyUserBookingDetail?.payload,
            paymentMethod: null
        }))

        dispatcher(setAddPaymentIntentDetailPayload({
            ...addPaymentIntentDetail?.payload,
            paymentMethodId: null,
            gatewayName: gateway.key
        }))
    }

    const selectPaymentMethod = (selectedPaymentMethod) => {
        if (!modifyUserBookingDetail?.payload?.isGatewayIncluded) return;

        dispatcher(setModifyUserBookingDetailPayload({
            ...modifyUserBookingDetail?.payload,
            paymentMethod: modifyUserBookingDetail?.payload?.paymentMethod
                ? null
                : selectedPaymentMethod
        }))

        dispatcher(setAddPaymentIntentDetailPayload({
            ...addPaymentIntentDetail?.payload,
            paymentMethodId: modifyUserBookingDetail?.payload?.paymentMethod?.id
                ? null
                : selectedPaymentMethod?.id
        }))
    }

    const getWalletCredit = () => {
        let walletCredit = [masterCurrencyCodeEnum.USD.value]?.includes(currentMasterCurrency)
            ? user?.user?.netWalletCredit
            : [masterCurrencyCodeEnum.AUD.value]?.includes(currentMasterCurrency)
                ? user?.user?.netAudWalletCredit
                : [masterCurrencyCodeEnum.INR.value]?.includes(currentMasterCurrency)
                    ? user?.user?.netInrWalletCredit
                    : 0
        return `${currentMasterCurrency} ${parseFloat(walletCredit / 100).toFixed(2)}`
    }

    const onHandlePromoBalance = (event) => {
        let requestBody = {}
        if (userBookingDetail?.data?.result?.offering_type === bookingOfferingTypeEnum.APPOINTMENT.value) {
            requestBody = {
                appointment: {
                    appointment_id: userBookingDetail?.data?.result?.appointment?.id,
                    timeZone: timeZone,
                },
                is_promo_used: event?.target?.checked
            }
        } else {
            requestBody = {
                is_promo_used: event?.target?.checked
            }
        }
        dispatcher(updateUserBookingDetail(userBookingDetail?.data?.result?.id, requestBody))
    }

    const GatewayList = () => {

        const ShowOrgGateway = ({ gateway }) => {
            return (
                <div
                    className={cn("p-2 flex gap-3")}
                >
                    <div className="mt-5" onClick={() => { if (isGatewayIncluded) selectGateway(gateway.provider) }}>
                        {(gateway.provider.key === addPaymentIntentDetail?.payload?.gatewayName)
                            ? <MdOutlineRadioButtonChecked className={cn("text-primary-dark text-2xl cursor-pointer", !isGatewayIncluded && "text-text-100 cursor-default")} />
                            : <MdOutlineRadioButtonUnchecked className={cn("text-text-500 text-2xl cursor-pointer", !isGatewayIncluded && "text-text-100 cursor-default")} />
                        }
                    </div>
                    <div className={"flex flex-row gap-2"}>
                        <img src={gateway.provider.image} alt={"gateway"} className={cn("w-28 aspect-video object-contain")} />
                        {(gateway.provider.key === addPaymentIntentDetail?.payload?.gatewayName) && (
                            <p className={cn("pl-2 font-medium text-muted-foreground/70")}>
                                {gateway.provider.info}
                            </p>
                        )}
                    </div>
                </div>
            )
        }

        return (
            <div className="w-full space-y-2 border divide-y">
                {GATEWAYS.map((gateway, index) => (
                    <ShowOrgGateway key={index} gateway={gateway}/>
                ))}
            </div>
        );
    };

    return (
        <div className={"space-y-3"}>
            <div className={"font-bodyPri font-medium text-lg text-text-800 space-x-3"}>
                <span>{"2"}</span>
                <span>{"Payment Method"}</span>
            </div>
            <div className={"W-full rounded-lg bg-white shadow-all p-5 space-y-3"}>

                <div className={"w-full flex items-center justify-between gap-3"}>
                    <span className={"font-bodyPri font-normal text-base text-text-800 truncate whitespace-nowrap"}>
                        {"My available balance"}
                    </span>
                    <div className={"flex items-center justify-end gap-2"}>
                        <div className={"flex items-center justify-start gap-1 font-bodyPri font-medium text-base text-text-900"}>
                            {/* <span>{"USD"}</span>
                            <span>{parseFloat(user?.user?.netWalletCredit / 100).toFixed(2)}</span> */}
                            <span>{getWalletCredit()}</span>
                        </div>
                        <Toggle
                            value={modifyUserBookingDetail?.payload?.isWalletAdded}
                            onChange={handleWalletToggle}
                        />
                    </div>
                </div>

                {[masterCurrencyCodeEnum.USD.value]?.includes(currentMasterCurrency) && (
                    <div className={"w-full flex items-center justify-between gap-3"}>
                        <div classList={"flex items-center justify-start gap-2"}>
                            <input
                                type={"checkbox"}
                                checked={userBookingDetail?.data?.result?.booking_price?.is_promo_used}
                                onClick={onHandlePromoBalance}
                                className={"cursor-pointer text-primary-dark mr-2"}
                            />
                            <span className={cn(
                                "font-bodyPri font-normal text-base text-text-800 truncate whitespace-nowrap",
                                userBookingDetail?.data?.result?.booking_price?.is_promo_used && "text-green-500"
                            )}>
                                {"Promo Balance"}
                            </span>
                        </div>
                        <div className={"flex items-center justify-end gap-2"}>
                            <div className={cn(
                                "flex items-center justify-start gap-1 font-bodyPri font-medium text-base text-text-900",
                                userBookingDetail?.data?.result?.booking_price?.is_promo_used && "text-green-500"
                            )}>
                                <span>{"USD"}</span>
                                <span>{parseFloat(userBookingDetail?.data?.result?.booking_price?.promo_credit_used / 100).toFixed(2)}</span>
                            </div>
                        </div>
                    </div>
                )}

                <hr className={"h-0.5 bg-divider-medium w-full rounded-full"} />

                { GATEWAYS.length > 0 ? <GatewayList/>: <div>{"No payment gateways available"}</div>}

                {(isGatewayIncluded && addPaymentIntentDetail?.payload?.gatewayName === gatewayProviderEnum.stripe.key) && (
                    <div className={"w-full flex flex-col items-start gap-5"}>
                        <div className={"w-full flex items-center justify-between gap-2"}>
                            <div className={"flex items-center gap-5"}>
                                <BsCreditCard2Back className={"text-text-700 text-xl"} />
                                <span className={"font-bodyPri font-medium text-md text-text-800"}>{"Credit/Debit Card Detail"}</span>
                            </div>
                            <div onClick={addPaymentMethod}
                                className={cn(
                                    "px-3 py-1 font-bodyPri font-normal text-sm text-primary-dark bg-primary-light hover:bg-primary-dark hover:text-text-50 hover:opacity-90 cursor-pointer rounded-md",
                                    !isGatewayIncluded && "!bg-back-ground-darkLight !text-text-50 cursor-not-allowed"
                                )}>
                                {"Add Payment Method"}
                            </div>
                        </div>

                        <div className={"w-full flex flex-col items-start gap-3 px-10"}>
                            <ComponentLoader isLoading={userPaymentMethodList?.isLoading} className={"h-40"} />
                            {userPaymentMethodList?.data?.map((paymentMethod, index) => {
                                let isSelected = paymentMethod?.id === modifyUserBookingDetail?.payload?.paymentMethod?.id
                                return (
                                    <div className={"w-full md:w-[40%]  flex gap-3 items-start"}>
                                        <div onClick={() => selectPaymentMethod(paymentMethod)}>
                                            {isSelected
                                                ? <ImCheckboxChecked className={cn("mt-3 text-primary-dark cursor-pointer")} />
                                                : <ImCheckboxUnchecked className={cn("mt-3 text-text-500 cursor-pointer")} />}
                                        </div>
                                        <div key={index} className={cn(
                                            "w-full flex flex-col items-start gap-1 p-3 border-2 border-text-300 rounded-lg",
                                            isSelected && "border-primary-dark"
                                        )}>
                                            <span className={cn(
                                                "w-full flex items-center justify-between gap-2"
                                            )}>
                                                <span className={"flex items-center justify-start gap-2 font-bodyPri font-medium text-base text-text-900"}>
                                                    <BiCreditCard />
                                                    {`${paymentMethod?.card?.brand?.toUpperCase().replace("_", " ")} ${paymentFundingEnum[paymentMethod?.card?.funding?.toUpperCase()].label} Card`}
                                                </span>
                                            </span>
                                            <span className={cn(
                                                "font-bodyPri font-medium text-md text-text-900"
                                            )}>
                                                {`xxxx xxxx xxxx ${paymentMethod?.card?.last4}`}
                                            </span>
                                            <span className={cn(
                                                "font-bodyPri font-medium text-md text-text-900"
                                            )}>
                                                {`${paymentMethod?.card?.exp_month}/${paymentMethod?.card?.exp_year}  `}
                                                <span className={cn(
                                                    "font-bodyPri font-normal text-base text-text-800"
                                                )}>
                                                    {`(${paymentMethod?.card?.country}) ${paymentMethod?.billing_details?.name ? paymentMethod?.billing_details?.name : ""}`}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}

            </div>
        </div>
    )
}

export default PaymentMethodSection