import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { pagesInfo } from 'utils/pagesInfo';

import { useTitle } from 'hooks/useTitle';
import Accordion from './components/accordion';
import linkTreeService from 'redux/linkTree/linkTree.service';
import PageLoader from 'components/loader/PageLoader';
import { getMasterSocialList } from 'redux/master/master.request';
import { modalConst } from 'redux/local/local.const';
import { setModal } from 'redux/local/local.slice';
import RefreshButton from 'components/common-components/RefreshButton';

const initialState = {
    isLoading: false,
    data: null,
    pagination: null,
    error: null,
}

const MyLinks = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { user } = useAppState((state) => state.user)
    const { modal } = useAppState((s) => s.local)
    const { createLinkTree } = useAppState((state) => state.linkTree);
    const [linkList, setLinkList] = useState(initialState)

    const dispatcher = useAppDispatcher()
    const [title, setTitle] = useTitle()


    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.MY_LINKS))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        setTitle({
            ...title,
            title: "My Links | Edulyte"
        })
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        dispatcher(getMasterSocialList())
    }, [])

    useEffect(() => {
        if (user?.user) {
            getLinkTreeList()
        }
    }, [user?.user])

    const getLinkTreeList = async () => {
        setLinkList((s) => ({ ...s, isLoading: true }));
        try {
            const requestData = {
                params: { userId: user?.user?.userId },
                query: {
                    records: 20,
                    page: 1
                }
            };
            const response = await linkTreeService.getLinkTreeList(requestData);
            if (response.status === 200) {
                setLinkList((s) => ({
                    ...s,
                    data: response.data.data,
                    isLoading: false
                }));
            } else {
                throw new Error(response);
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || error);
            setLinkList((s) => ({
                ...s,
                error: error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!",
                isLoading: false,
            }));
        }
    };

    const handleCreateLinks = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.CREATE_LINK_HUG_MODAL.key]: {
                ...modal[modalConst.CREATE_LINK_HUG_MODAL.key],
                isVisible: true,
                title: "Create Unique Link",
            }
        }))
    }

    if (linkList?.error) {
        return <div className="flex items-center justify-center h-screen bg-white text-center text-lg text-secondary-dark">{linkList?.error}</div>;
    }

    if (linkList?.isLoading) {
        return <PageLoader />
    }

    return (
        <div className={"h-full w-full p-3 mx-auto min-h-screen"}>
            <div className='flex flex-col gap-5 bg-white p-5 rounded-lg'>
                <div className='flex items-center justify-between gap-2'>
                    <h1 className='font-heading text-primary-dark text-xl'>Manage Your Links</h1>
                    <div className='flex flex-row items-center justify-start gap-3'>
                        <RefreshButton onHandleRefreshButton={getLinkTreeList}/>
                        <motion.button
                            whileHover={{ scale: 1.095 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={handleCreateLinks}
                            className='flex items-center gap-2 px-4 py-2 text-sm border rounded hover:border-secondary-dark text-text-600 hover:text-secondary-dark ease-in-out duration-200'
                        >
                            <span>Add New Link</span>
                        </motion.button>

                    </div>
                </div>
                <Accordion data={linkList?.data?.result} getLinkTreeList={getLinkTreeList} />
            </div>
        </div>
    );
};

export default MyLinks;
