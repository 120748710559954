import { memo } from 'react';

import ButtonLoader from "components/loader/ButtonLoader";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from 'redux/local/local.slice';
import { setCancelBookingSessionDetailPayload, resetCancelBookingSessionDetailPayload, resetCancelBookingSessionDetail } from "redux/booking/booking.slice";
import { modalConst } from 'redux/local/local.const';
import { cancelBookingSessionDetailPayload } from 'redux/booking/booking.const';

import { cn } from 'utils/cn.utils';

const CancelBookingDetail = ({ newBookingDetail }) => {
    const { modal } = useAppState((state) => state.local)
    const { userBookingDetail, tutorBookingDetail, addBookingSessionDetail, cancelBookingSessionDetail } = useAppState((s) => s.booking)

    const dispatcher = useAppDispatcher()

    const onHandleCancelBookingSession = () => {
        if (userBookingDetail?.isLoading || tutorBookingDetail?.isLoading || addBookingSessionDetail?.isLoading || cancelBookingSessionDetail?.isLoading) return;

        dispatcher(resetCancelBookingSessionDetail())
        dispatcher(resetCancelBookingSessionDetailPayload())
        dispatcher(setCancelBookingSessionDetailPayload({
            ...cancelBookingSessionDetailPayload,
            bookingId: newBookingDetail?.data?.result?.id
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.CANCEL_BOOKING_SESSION_MODAL.stateKey]: true
        }))
    }

    return (
        <div className={"w-full flex items-center justify-start my-5"}>
            <div className={cn(
                "w-full px-2 py-1.5 flex items-center justify-center gap-1 rounded-lg border border-red-500",
                "font-bodyPri font-normal text-red-500 text-base whitespace-nowrap cursor-pointer",
                "hover:bg-red-500 hover:text-text-50",
                (userBookingDetail?.isLoading || cancelBookingSessionDetail?.isLoading) && "!bg-red-500"
            )}
                onClick={onHandleCancelBookingSession}
            >
                {(userBookingDetail?.isLoading || cancelBookingSessionDetail?.isLoading) &&
                    <ButtonLoader isLoading={userBookingDetail?.isLoading || cancelBookingSessionDetail?.isLoading} />
                }
                {(!userBookingDetail?.isLoading && !cancelBookingSessionDetail?.isLoading) &&
                    <span className={""}>
                        {"Cancel Booking"}
                    </span>
                }
            </div>
        </div>
    )
}

export default memo(CancelBookingDetail);