import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';

import PageHeader from 'components/pageHeader/PageHeader';
import StatusButton from 'components/common-components/StatusButton';
import Pagination from 'components/pagination/Pagination';
import ThreeDotMenu from 'components/threeDotMenu/ThreeDotMenu';

import { appointmentTableHeaderConst, getAppointmentListPayload, menuItemsConst, pageHeading, searchParamsInfo } from 'pages/auth/appointments/data';
import FilterSection from 'pages/auth/appointments/components/filters';
import AppointmentTable from 'pages/auth/appointments/components/AppointmentTable';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { deleteAppointmentDetail, getUserAppointmentList } from "redux/appointment/appointment.request";

import { useTitle } from 'hooks/useTitle';
import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal, setShareSocial } from 'redux/local/local.slice';
import { resetDestroyAppointmentDetail, resetUserAppointmentList, setUserAppointmentListData } from "redux/appointment/appointment.slice";
import { socialAction, modalConst } from 'redux/local/local.const';
import { appointmentStatusEnum, appointmentVisibilityEnum } from 'redux/appointment/appointment.const';

import { cn } from 'utils/cn.utils';
import { pagesInfo } from 'utils/pagesInfo';
import { dayjs, timeZone } from "utils/dateTime.utils";
import { getCustomHostPath } from 'utils/generators.utils';

import { EDULYTE_WEB_DOMAIN_URL } from 'const/default.const';
import useCustomNavigation from 'hooks/useCustomNavigation';

const AppointmentListPage = () => {
    const publicOrgDetail = useAppState((state) => state.org.publicOrgDetail)
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { shareSocial, modal } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { userAppointmentList, destroyAppointmentDetail } = useAppState((state) => state.appointment)

    const dispatcher = useAppDispatcher()
    const [title, setTitle] = useTitle()
    const navigate = useNavigate()
    const location = useLocation()
    const customNavigation = useCustomNavigation()

    const [copiedURL, setCopiedURL] = useState(false)

    const searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.APPOINTMENT_LIST))
    }, [dispatcher])

    useEffect(() => {
        if (user?.user) {
            setTitle({
                ...title,
                title: `My Appointment Booking Pages | Edulyte`
            })
        }
    }, [user?.user])

    useEffect(() => {
        return () => {
            dispatcher(resetUserAppointmentList())
        }
    }, [])

    useEffect(() => {
        if (destroyAppointmentDetail?.data) {
            dispatcher(setUserAppointmentListData({
                ...userAppointmentList?.data,
                results: userAppointmentList?.data?.result?.filter((item) => item?.id !== destroyAppointmentDetail?.data?.id),
                pagination: {
                    ...userAppointmentList?.data?.pagination,
                    records: userAppointmentList?.data?.pagination?.records - 1,
                    totalRecords: userAppointmentList?.data?.pagination?.totalRecords - 1
                },
            }))
            dispatcher(resetDestroyAppointmentDetail())
        }
    }, [destroyAppointmentDetail?.data])

    useEffect(() => {
        if (user?.user?.userId) {
            getAppointmentList()
        }
    }, [user?.user?.userId, location.search])

    const getAppointmentList = async () => {
        const requestDataPayload = await getAppointmentListPayload(searchQueryParams)

        dispatcher(getUserAppointmentList(user?.user?.userId, { ...requestDataPayload }))
    }

    const onHandlePageChange = async (page) => {
        searchQueryParams.set(searchParamsInfo.page.key, page)
        navigate(`${location.pathname}?${searchQueryParams?.toString()}`);
    }

    const onHandleSelectMenuItem = useCallback((menuItem, appointmentItem) => {
        if (menuItem.value === menuItemsConst.SHARE.value) {
            dispatcher(setShareSocial({
                ...shareSocial,
                socialAction: socialAction.SHARE_APPOINTMENT,
                // url: `${EDULYTE_WEB_DOMAIN_URL}${pagesInfo.APPOINTMENT.pagePath}/${appointmentItem?.slug}`
                // url: `${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `${pagesInfo.APPOINTMENT.pagePath}/${appointmentItem?.slug}` })}`
                url: `${customNavigation.getCustomBaseUrl({ path: `${pagesInfo.APPOINTMENT.pagePath}/${appointmentItem?.slug}`, isWeb: true })}`
            }))
            dispatcher(setModal({
                ...modal,
                [modalConst.SHARE_SOCIAL_MODAL.stateKey]: true
            }))
        }
        if (menuItem.value === menuItemsConst.COPY.value) {
            // navigator.clipboard.writeText(`${EDULYTE_WEB_DOMAIN_URL}${pagesInfo.APPOINTMENT.pagePath}/${appointmentItem?.slug}`);
            // navigator.clipboard.writeText(`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `${pagesInfo.APPOINTMENT.pagePath}/${appointmentItem?.slug}` })}`);
            navigator.clipboard.writeText(`${customNavigation.getCustomBaseUrl({ path: `${pagesInfo.APPOINTMENT.pagePath}/${appointmentItem?.slug}`, isWeb: true })}`);
            setCopiedURL(true);
            toast.success("Copied")
            setTimeout(() => {
                setCopiedURL(false);
            }, 2000);
        }
        if (menuItem.value === menuItemsConst.EDIT.value) {
            navigate(`${pagesInfo.EDIT_APPOINTMENT.pagePath}/${appointmentItem?.id}/edit`)
        }
        if (menuItem.value === menuItemsConst.DELETE.value) {
            dispatcher(deleteAppointmentDetail(appointmentItem?.id))
        }
    }, [copiedURL, shareSocial, modal])

    const onHandleNavigateToAppointment = (appointmentItem) => {
        if (appointmentItem?.status === appointmentStatusEnum.PUBLISHED.value) {
            // window.open(`${EDULYTE_WEB_DOMAIN_URL}${pagesInfo.APPOINTMENT.pagePath}/${appointmentItem?.slug}`, "_blank")
            // window.open(`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `${pagesInfo.APPOINTMENT.pagePath}/${appointmentItem?.slug}` })}`, "_blank")
            window.open(`${customNavigation.getCustomBaseUrl({ path: `${pagesInfo.APPOINTMENT.pagePath}/${appointmentItem?.slug}`, isWeb: true })}`, "_blank")
        }
    }

    const AppointmentTitleContainer = ({ appointmentItem }) => {
        return appointmentItem?.title ? (
            <span
                className={cn(
                    'w-fit font-bodyPri font-normal text-text-900 text-base line-clamp-1 tracking-wide',
                    (appointmentItem?.status === appointmentStatusEnum?.PUBLISHED?.value) && "cursor-pointer hover:text-primary-dark hover:underline"
                )}
                title={appointmentItem?.title && appointmentItem?.title}
                onClick={() => onHandleNavigateToAppointment(appointmentItem)}
            >
                {appointmentItem?.title
                    ? (appointmentItem?.title?.length > 30)
                        ? appointmentItem?.title?.slice(0, 30) + " ..."
                        : appointmentItem?.title
                    : "-"
                }
            </span>
        ) : "-"
    }

    const tableRowsConst = useMemo(() => {
        if (!!userAppointmentList?.data?.result?.length) {
            return userAppointmentList?.data?.result?.map((appointmentItem) => ([
                <AppointmentTitleContainer appointmentItem={appointmentItem} />,
                <StatusButton
                    status={appointmentStatusEnum[appointmentItem?.status?.toUpperCase()]?.label}
                    className={`bg-${appointmentStatusEnum[appointmentItem?.status?.toUpperCase()]?.lightColor} text-${appointmentStatusEnum[appointmentItem?.status?.toUpperCase()]?.darkColor}`}
                />,
                appointmentVisibilityEnum[appointmentItem?.visibility?.toUpperCase()]?.label || "-",
                dayjs(appointmentItem?.createdAt)?.tz(timeZone)?.format('DD, MMM, YYYY') || "-",
                <div className={"flex items-center justify-start"}>
                    <ThreeDotMenu
                        iconClassName={"rotate-[90deg]"}
                        menuItems={(![appointmentStatusEnum?.PUBLISHED.value]?.includes(appointmentItem?.status)) ? Object.values(menuItemsConst)?.filter((item) => (item.value === menuItemsConst.EDIT.value) || (item?.value === menuItemsConst.DELETE.value)) : Object.values(menuItemsConst).filter((menuItem) => menuItem.value !== menuItemsConst.DELETE.value)}
                        onHandleSelect={(option) => onHandleSelectMenuItem(option, appointmentItem)}
                    />
                </div>
            ]))
        }
    }, [userAppointmentList?.data?.result])

    return (
        <div className={"space-y-3"}>
            <PageHeader
                pageHeading={pageHeading}
                toolTipText={"Easily create and share your personal booking links, whether free or paid"}
            />
            <FilterSection />
            <AppointmentTable
                isLoading={userAppointmentList?.isLoading}
                headers={appointmentTableHeaderConst}
                rows={tableRowsConst}
                alternateRowColor={"bg-sky-100"}
                rowHeaderColor={"bg-sky-200"}
            />
            {(!userAppointmentList?.isLoading && (userAppointmentList?.data && (userAppointmentList?.data?.pagination?.totalPages > 1))) &&
                <div className={"flex justify-center items-center"}>
                    <Pagination
                        page={userAppointmentList?.data?.pagination?.page}
                        totalPages={userAppointmentList?.data?.pagination?.totalPages}
                        onChangePage={onHandlePageChange}
                    />
                </div>
            }
            {(!userAppointmentList?.isLoading && userAppointmentList?.message) &&
                <div className={'w-full flex items-center justify-center'}>
                    <span className={'font-bodyPri font-normal text-red-500 text-base text-center'}>
                        {userAppointmentList.message}
                    </span>
                </div>
            }
        </div>
    )
}

export default AppointmentListPage;