import { useState, useEffect } from "react";
import { cn } from "utils/cn.utils";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import ComponentLoader from "components/loader/ComponentLoader";
import Pagination from "components/pagination/Pagination";
import ReviewCard from "components/ReviewCard";
import RatingStars from "components/ratingStars/RatingStars";

import { getPublicUserReviewListByUserId } from 'redux/review/review.request';

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { setClearUserReviewList, setAddUserReviewDetailPayload, setClearAddUserReviewDetail } from 'redux/review/review.slice';
import { modalConst } from "redux/local/local.const";
import { userRoles } from "redux/auth/auth.const";

import { pagesInfo } from "utils/pagesInfo";
import { generateQueryParams } from "utils/generators.utils";

const Reviews = ({ ...props }) => {
  const { modal } = useAppState((state) => state.local)
  const { session } = useAppState((state) => state.auth)
  const { user } = useAppState((state) => state.user)
  const { userReviewList, addUserReviewDetail } = useAppState((s) => s.review)

  const dispatcher = useAppDispatcher()
  const navigate = useNavigate()
  const location = useLocation()
  const { userId } = useParams()

  const [page, setPage] = useState(1)


  useEffect(() => {
    dispatcher(getPublicUserReviewListByUserId(Number(userId), { page: page, records: 9, receiverRole: userRoles?.TUTOR?.key }))

    return () => {
      dispatcher(setClearUserReviewList())
    }
  }, [page, userId])

  useEffect(() => {
    const query = generateQueryParams(location.search)
    if (query && query?.action === "leave-a-review" && !user?.isLoading) {
      if (user?.user) {
        if (user?.user?.userId != Number(userId)) {
          dispatcher(setAddUserReviewDetailPayload({
            ...addUserReviewDetail?.addUserReviewDetailPayload,
            userId: Number(userId),
            receiverUserId: Number(userId),
            receiverRole: userRoles?.TUTOR?.key,
            isNavigateToTutorPage: true
          }))
          dispatcher(setModal({
            ...modal,
            [modalConst.ADD_REVIEW_MODAL.stateKey]: true
          }))
        } else {
          dispatcher(setModal({
            ...modal,
            [modalConst.ADD_REVIEW_MODAL.stateKey]: false
          }))
          navigate(`${pagesInfo?.TUTOR?.pagePath}/${Number(userId)}`)
        }
      } else {
        navigate(pagesInfo?.LOG_IN?.pagePath)
      }
    }
  }, [location.search, user?.user])

  const onHandleReview = () => {
    if (user?.user?.userId === Number(userId)) return;

    if (session?.isLoggedIn) {
      navigate(`${pagesInfo?.TUTOR?.pagePath}/${Number(userId)}?action=leave-a-review`)
      dispatcher(setClearAddUserReviewDetail())
      dispatcher(setAddUserReviewDetailPayload({
        ...addUserReviewDetail?.addUserReviewDetailPayload,
        userId: Number(userId),
        receiverUserId: Number(userId),
        receiverRole: userRoles?.TUTOR?.key,
        isNavigateToTutorPage: true
      }))
      dispatcher(setModal({
        ...modal,
        [modalConst.ADD_REVIEW_MODAL.stateKey]: true
      }))
    } else {
      navigate(pagesInfo?.LOG_IN?.pagePath)
    }
  }

  return (
    <div className={"p-2.5 md:p-6"} id={"REVIEWS"}>
      <div className={"flex items-center justify-between gap-3"}>
        <div className="flex items-center gap-3">
          <span className="text-xl font-medium font-bodyPri tracking-wide text-text-900">
            {"Reviews"}
          </span>
          <RatingStars rating={userReviewList?.filteredUserReviewList?.rating?.avgRating || 0} fullRating={5} />
          {userReviewList?.filteredUserReviewList &&
            <span className="font-medium text-md">
              {userReviewList?.filteredUserReviewList?.avgRating} ({userReviewList?.filteredUserReviewList?.rating?.totalStars?.count})
            </span>
          }
        </div>
        {(user?.user?.userId !== Number(userId)) &&
          <div className={cn(
            "w-fit px-2 py-0.5 flex items-center justify-center rounded-lg cursor-pointer border border-divider-medium",
            "font-bodyPri font-normal text-text-700 text-sm whitespace-nowrap tracking-wide",
            "hover:bg-secondary-dark hover:text-text-50"
          )}
            onClick={onHandleReview}
          >
            {"Leave a review"}
          </div>
        }
      </div>

      {(userReviewList?.isLoading || addUserReviewDetail?.isLoading) &&
        <ComponentLoader isLoading={(userReviewList?.isLoading || addUserReviewDetail?.isLoading)} />
      }
      {userReviewList?.filteredUserReviewList?.results &&
        <div className={cn("mt-6 grid grid-cols-1 sm:grid-cols-2 gap-3 grid-flow-row grid-rows-auto auto-cols-max")}>
          {userReviewList?.filteredUserReviewList?.results?.map((ele, idx) => (
            <ReviewCard
              key={idx}
              rating={ele?.rating}
              content={ele?.review}
              profileImg={ele?.providerUser?.profilePicUrl}
              fName={ele?.providerUser?.firstName}
              lName={ele?.providerUser?.lastName}
              createdAt={ele?.createdAt}
            />
          ))}
        </div>
      }
      {(userReviewList?.errorMsg || userReviewList?.filteredUserReviewList?.records === 0 || !userReviewList?.filteredUserReviewList) &&
        <span className={"font-bodyPri font-normal text-text-800 text-lg text-center sm:text-left py-0.5 md:py-1 px-3"}>
          {userReviewList?.errorMsg || "Work in Progress"}
        </span>
      }
      {userReviewList?.filteredUserReviewList && userReviewList?.filteredUserReviewList?.records > 0 &&
        <div className="w-full flex justify-center items-center pt-3">
          <Pagination
            page={userReviewList?.filteredUserReviewList?.page}
            totalPages={userReviewList?.filteredUserReviewList?.totalPages}
            onChangePage={(page) => setPage(page)}
          />
        </div>
      }
    </div>
  );
};

export default Reviews;
