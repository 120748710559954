import { courseType } from "redux/course/course.const"

export const url = {
    CREATE_BOOKING_DETAIL: "bookings/",
    INITIALIZE_BOOKING_DETAIL: "bookings/{bookingId}/initialize",
    CONFIRM_BOOKING_DETAIL: "bookings/{bookingId}/confirm",
    GET_BOOKING_DETAIL_CONFIRMATION: "bookings/{bookingId}/confirmation",

    GET_USER_BOOKING_LIST: "bookings/bookings/users/{userId}",
    GET_USER_BOOKING_DETAIL: "bookings/bookings/{bookingId}/users/{userId}",
    UPDATE_USER_BOOKING_DETAIL: "bookings/{bookingId}",
    
    GET_TUTOR_BOOKING_LIST: "bookings/bookings/owner-users/{userId}",
    GET_TUTOR_BOOKING_DETAIL: "bookings/bookings/{bookingId}/owner-users/{userId}",
    
    ADD_BOOKING_SESSION_DETAIL: "bookings/{bookingId}/sessions",
    CANCEL_BOOKING_SESSION_DETAIL: "bookings/{bookingId}/cancel",

    // no use for now will work when added to console
    GET_USER_PRE_BOOKING_DETAIL: "bookings/bookings/{bookingId}/pre/users/{userId}",
}

export const bookingWsUrl = {
    BOOKING_CONFIRMATION: "/bookings/bookings/{booking_id}/confirmation"
}

export const addBookingDetailPayload = {
    offering: null,
    slug: null,
    userId: null,
    offeringType: null,
    bookingId: null,
    courseId: null,
    appointmentId: null,
    availabilityId: null,
    timeZone: null,
    duration: 60,
    sessionCount: 5,
    schedule: [],
    itemPriceId: null
}

export const modifyUserBookingDetailPayload = {
    offeringType: null,
    course: null,
    appointment: null,
    isWalletAdded: true,
    walletAmountToPay: 0,
    isGatewayIncluded: false,
    gatewayAmountToPay: 0,
    paymentMethod: null,
    note: null
}

export const cancelBookingSessionDetailPayload = {
    bookingId: null,
    comment: ""
}

export const bookingOfferingTypeEnum = {
    ALL: {
        value: "all",
        label: "All"
    },
    COURSE: {
        value: 'course',
        label: 'Class'
    },
    APPOINTMENT: {
        value: 'appointment',
        label: 'Appointment'
    },
    COHORT: {
        value: "cohort",
        label: "Cohort"
    }
}

export const bookingTypeEnum = {
    REGULAR: {
        value: 'regular',
        label: 'Regular'
    },
    PLAN: {
        value: 'plan',
        label: 'PLAN',
    }
}

export const bookingStatusEnum = {
    ALL: {
        label: "All",
        value: "all",
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    },
    PENDING: {
        value: "pending",
        label: "Pending",
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    PROCESSING: {
        value: "processing",
        label: "Processing",
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    CONFIRMED: {
        value: "confirmed",
        label: "Confirmed",
        lightColor: 'blue-100',
        darkColor: 'blue-500',
    },
    COMPLETED: {
        value: "completed",
        label: "Completed",
        lightColor: 'green-100',
        darkColor: 'green-500',
    },
    REFUNDED: {
        value: "refunded",
        label: "Refunded",
        lightColor: 'red-100',
        darkColor: 'red-500',
    },
    FAILED: {
        value: "failed",
        label: "Failed",
        lightColor: 'red-100',
        darkColor: 'red-500',
    },
    CANCELLED: {
        value: "cancelled",
        label: "Cancelled",
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    },
}

export const bookingScheduleTypeEnum = {
    ONE_ON_ONE: {
        value: 'one_on_one',
        label: 'One on One'
    },
    GROUP: {
        value: 'group',
        label: 'Group'
    },
}

export const bookingScheduleIsRecurringEnum = {
    RECURRING: {
        value: 'recurring',
        label: 'Recurring'
    },
    NON_RECURRING: {
        value: 'non_recurring',
        label: 'Non Recurring'
    },
}

export const bookingScheduleEndTypeEnum = {
    FOREVER: {
        value: 'forever',
        label: 'Forever'
    },
    ON_DATE: {
        value: 'on_date',
        label: 'On Date'
    },
    WEEKLY_CYCLE: {
        value: 'weekly_cycle',
        label: 'Weekly Cycle'
    }
}

export const bookingRegularPriceModelEnum = {
    FREE: {
        value: 'free',
        label: 'Free'
    },
    FLAT_FEE: {
        value: 'flat_fee',
        label: 'Flat Fee'
    },
    PER_HOUR: {
        value: 'per_hour',
        label: 'Per Hour'
    },
    PER_SESSION: {
        value: 'per_session',
        label: 'Per Session'
    }
}

export const bookingPlanPeriodUnitEnum = {
    DAY: {
        value: 'day',
        label: 'Day'
    },
    WEEK: {
        value: 'week',
        label: 'Week'
    },
    MONTH: {
        value: 'month',
        label: 'Month'
    },
    YEAR: {
        value: 'year',
        label: 'Year'
    }
}

export const bookingPlanPriceModelEnum = {
    FLAT_FEE: {
        value: 'flat_fee',
        label: 'Flat Fee'
    },
    PER_UNIT: {
        value: 'per_unit',
        label: 'Per Unit'
    },
    TIERED: {
        value: 'tired',
        label: 'Tiered'
    },
    VOLUME: {
        value: 'volume',
        label: 'Volume'
    },
    STAIRSTEP: {
        value: 'stairstep',
        label: 'Stairstep'
    }
}

export const bookingIsPaidEnum = {
    PAID: {
        value: 'paid',
        label: 'Paid'
    },
    UNPAID: {
        value: 'unpaid',
        label: 'Unpaid'
    }
}

export const bookingSessionEnum = {
    MAPPED: {
        value: 'mapped',
        label: 'Mapped'
    },
    IS_MAPPED: {
        value: 'is_mapped',
        label: 'Is Mapped'
    }
}

// OLD
export const oldUrl = {
    ADD_BOOKING: '/bookings/add'
}


export const filterName = {
    BOOKING: {
        value: "booking"
    }
}

export const bookingStatus = {
    ALL: {
        label: "All",
        value: "all",
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    },
    PENDING: {
        label: "Pending",
        value: "pending",
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    PROCESSING: {
        label: "Processing",
        value: "processing",
        lightColor: "yellow-100",
        darkColor: "yellow-500"
    },
    CONFIRMED: {
        label: "Confirmed",
        value: "confirmed",
        lightColor: 'blue-100',
        darkColor: 'blue-500',
    },
    COMPLETED: {
        label: "Completed",
        value: "completed",
        lightColor: 'green-100',
        darkColor: 'green-500',
    },
    CANCELLED: {
        label: "Cancelled",
        value: "cancelled",
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    },
    REFUNDED: {
        label: "Refunded",
        value: "refunded",
        lightColor: 'red-100',
        darkColor: 'red-500',
    },
    FAILED: {
        label: "Failed",
        value: "failed",
        lightColor: "red-100",
        darkColor: "red-500"
    }
}

export const weeklyTimeSlot = {
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: []
}

export const bookingType = {
    ALL: {
        value: 'all',
        label: 'All'
    },
    REGULAR: {
        value: 'regular',
        label: 'Regular'
    },
    TRIAL: {
        value: 'trial',
        lable: 'Trial',
    },
    ON_CHAT: {
        value: 'on_chat',
        lable: 'On Chat'
    }
}

export const chooseTimeSlotAction = {
    BOOKING: {
        value: 'booking',
        label: 'Booking',
    },
    RESCHEDULED: {
        value: 'rescheduled',
        label: 'Rescheduled',
    },
    CHOOSE_BOOKED_SLOT: {
        value: 'choose_booked_slot',
        label: 'Choose Booked Slot',
    },
}

export const DEFAULT_BOOKING_PAGE = 1;
export const DEFAULT_BOOKING_RECORDS = 10;

export const bookingFilterProps = {
    topic: "",
    priceRange: {
        startPrice: "",
        endPrice: ""
    },
    availability: {
        timeRange: {
            startTime: "",
            endTime: ""
        },
        day: ""
    },
    tutorFrom: "",
    language: "",
    proficiency: "",
    ageGroup: {
        startAge: "",
        endAge: ""
    },
    searchText: "",
    sortBy: "",
    courseType: courseType.ALL.value,
    bookingStatus: bookingStatus.ALL.value,
    bookingOfferingType: bookingOfferingTypeEnum.ALL.value
}