import { useCallback, useEffect, memo, useMemo } from 'react';

import { toast } from 'react-toastify';

import { IoIosArrowBack, IoIosCheckmark } from 'react-icons/io';
import { BsInfoCircle } from 'react-icons/bs';

import ButtonLoader from 'components/loader/button-loader';
import ToolTipView from 'components/tooltipView';
import { Toggle } from 'components/common-components/Toggle';
import FloatingLabelSelect from 'components/floating/floatingLabelSelect';

import { appointmentDetailTooltips, validateAppointmentPricePayload, setAppointmentDetailPayload } from 'pages/auth/editAppointment/data';
import SaveAndCancelBtn from 'pages/auth/editAppointment/commonComponents/SaveAndCancelBtn';

import { updateAppointmentDetail } from 'redux/appointment/appointment.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModifyAppointmentDetailPayload } from 'redux/appointment/appointment.slice';
import { masterCurrencyCodeEnum } from 'redux/master/master.const';
import { appointmentPriceModelEnum } from 'redux/appointment/appointment.const';

import { cn } from 'utils/cn.utils';

const EditPriceDetail = ({ onHandleCloseSection }) => {
    const { locals } = useAppState((state) => state.local)
    const { userAppointmentDetail, modifyAppointmentDetail } = useAppState((state) => state.appointment)

    const dispatcher = useAppDispatcher()

    const isSaveBtnDisabled = useMemo(() => {
        const isDetailSimilarOrValid = validateAppointmentPricePayload(userAppointmentDetail?.data?.result, modifyAppointmentDetail?.payload)

        return isDetailSimilarOrValid;
    }, [modifyAppointmentDetail?.payload, userAppointmentDetail?.data])

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (!isSaveBtnDisabled) {
                const message = "Are you sure you want to leave?";
                event.preventDefault();
                event.returnValue = message;
                return message;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isSaveBtnDisabled]);

    const onHandleGoBack = useCallback(() => {
        if (!isSaveBtnDisabled) {
            onHandleSaveAndCloseBtn()
        } else {
            onHandleCancelBtn()
        }
    }, [isSaveBtnDisabled, userAppointmentDetail?.data])

    const getDefaultCurrencyCode = useCallback(() => {
        let currencyCode = [masterCurrencyCodeEnum.AUD.value, masterCurrencyCodeEnum.INR.value]?.includes(locals?.currencyCode)
            ? locals?.currencyCode
            : masterCurrencyCodeEnum.USD.value
        return { code: currencyCode }
    }, [locals?.currencyCode])

    const onHandleTogglePriceModel = useCallback((key, value) => {
        let payload = {
            [key]: value ? appointmentPriceModelEnum.FREE.value : appointmentPriceModelEnum.FLAT_FEE.value
        }
        if (!value) {
            payload["master_currency"] = userAppointmentDetail?.data?.result?.appointment_price?.master_currency || getDefaultCurrencyCode()
            payload["price"] = userAppointmentDetail?.data?.result?.appointment_price?.price || 1000
            payload["discount_pct"] = userAppointmentDetail?.data?.result?.appointment_price?.discount_pct || 0
        }
        if (value) {
            payload["master_currency"] = userAppointmentDetail?.data?.result?.appointment_price?.master_currency || null
            payload["price"] = userAppointmentDetail?.data?.result?.appointment_price?.price || 0
            payload["discount_pct"] = userAppointmentDetail?.data?.result?.appointment_price?.discount_pct || 0
        }
        dispatcher(setModifyAppointmentDetailPayload({
            ...modifyAppointmentDetail?.payload,
            ...payload,
        }))
    }, [modifyAppointmentDetail?.payload, userAppointmentDetail?.data?.result])

    const onHandleSelectCurrencyCode = useCallback((option) => {
        dispatcher(setModifyAppointmentDetailPayload({
            ...modifyAppointmentDetail?.payload,
            master_currency: {
                ...modifyAppointmentDetail?.payload?.master_currency,
                code: option?.value
            }
        }))
    }, [modifyAppointmentDetail?.payload])

    const onHandleChangeText = useCallback((key, value) => {
        dispatcher(setModifyAppointmentDetailPayload({
            ...modifyAppointmentDetail?.payload,
            [key]: value
        }))
    }, [modifyAppointmentDetail?.payload])

    const onHandleSaveAndCloseBtn = useCallback(() => {
        if (modifyAppointmentDetail?.isLoading || isSaveBtnDisabled) return;

        const body = {
            price_model: modifyAppointmentDetail?.payload?.price_model
        }
        if ([appointmentPriceModelEnum.FLAT_FEE.value]?.includes(modifyAppointmentDetail?.payload?.price_model)) {
            if (!modifyAppointmentDetail?.payload?.master_currency?.code) {
                toast.warn("Please select currency code!")
                return;
            }
            if (!modifyAppointmentDetail?.payload?.price) {
                toast.warn("Please enter valid price!")
                return;
            }
            body["currency_code"] = modifyAppointmentDetail?.payload?.master_currency?.code
            body["price"] = modifyAppointmentDetail?.payload?.price
            body["discount_pct"] = modifyAppointmentDetail?.payload?.discount_pct || 0
        }
        dispatcher(updateAppointmentDetail(modifyAppointmentDetail?.payload?.id, body))
    }, [modifyAppointmentDetail])

    const onHandleCancelBtn = useCallback(() => {
        const payload = setAppointmentDetailPayload(userAppointmentDetail?.data?.result)
        dispatcher(setModifyAppointmentDetailPayload(payload))
        onHandleCloseSection()
    }, [userAppointmentDetail?.data])

    return (
        <div className={"w-full h-full flex flex-col"}>
            <div className={"h-28 p-5 flex flex-col gap-3 md:gap-5 border-b border-text-300 font-bodyPri"}>
                {modifyAppointmentDetail?.isLoading &&
                    <div className={"w-fit"}>
                        <ButtonLoader isLoading={modifyAppointmentDetail?.isLoading} dotStyles={{ backgroundColor: "#9e9e9e" }} />
                    </div>
                }
                {!modifyAppointmentDetail?.isLoading &&
                    <div className={"w-fit flex items-center justify-start gap-2 cursor-pointer group"} onClick={onHandleGoBack}>
                        <IoIosArrowBack className={"text-xl text-text-800 group-hover:text-secondary-dark"} />
                        <span className={"font-bodyPri font-normal text-text-900 text-base underline tracking-wide line-clamp-1 group-hover:text-secondary-dark cursor-pointer"}>
                            {"Price Summary"}
                        </span>
                    </div>
                }
                <span className={"font-bold text-lg text-text-900 tracking-wide"}>
                    {"Price details"}
                </span>
            </div>

            <div className={cn(
                "p-5 w-full space-y-5 md:space-y-8 flex-grow overflow-y-auto",
                "scrollbar-thin scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-light",
            )}
            >

                {/* price model */}
                <div className={"w-full flex items-center justify-between gap-3"}>
                    <p className={cn(
                        "font-bodyPri font-bold text-text-700 text-base tracking-wide",
                        [appointmentPriceModelEnum.FREE.value]?.includes(modifyAppointmentDetail?.payload?.price_model) && "text-green-500"
                    )}>
                        {"This is Free"}
                    </p>
                    <div className={"-m-2 flex"}>
                        <Toggle
                            value={[appointmentPriceModelEnum.FREE.value]?.includes(modifyAppointmentDetail?.payload?.price_model)}
                            onChange={(value) => onHandleTogglePriceModel("price_model", value)}
                        />
                    </div>
                </div>

                {[appointmentPriceModelEnum.FLAT_FEE.value]?.includes(modifyAppointmentDetail?.payload?.price_model) &&
                    <>

                        {/* currency code */}
                        <div className={'flex flex-col justify-start gap-2'}>
                            <span className='font-bodyPri font-normal text-text-900 text-base'>
                                {"Currency Code "}
                                <span className="text-red-500 text-lg">{"*"}
                                </span>
                            </span>
                            <FloatingLabelSelect
                                labelItem={`appointment_currency_code`}
                                showLabel={false}
                                options={Object.values(masterCurrencyCodeEnum)}
                                onHandleSelect={onHandleSelectCurrencyCode}
                                value={masterCurrencyCodeEnum[modifyAppointmentDetail?.payload?.master_currency?.code?.toUpperCase()]?.label}
                                OptionChild={({ option }) => (
                                    <div className="bg-white hover:bg-gray-100 px-3 py-2 flex items-center justify-between">
                                        <span className={"font-bodyPri font-normal text-text-900 text-base tracking-wide"}>
                                            {option?.label}
                                        </span>
                                        {(option?.value === modifyAppointmentDetail?.payload?.master_currency?.code)
                                            && <IoIosCheckmark size={20} color="green" />
                                        }
                                    </div>
                                )}
                                dropdownWrapperClassName={"w-full"}
                                dropDownContainerClassName={"w-full max-h-44 overflow-y-auto scrollbar-thin"}
                            />
                        </div>

                        {/* price */}
                        <div className={"w-full flex flex-col gap-2"}>
                            <div className={"flex items-center justify-start gap-2"}>
                                <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                                    {"Price *"}
                                </span>
                                <ToolTipView content={appointmentDetailTooltips.price}>
                                    <button className={""}>
                                        <BsInfoCircle className={"inline text-md text-text-900 hover:text-text-900"} />
                                    </button>
                                </ToolTipView>
                            </div>
                            <input
                                type={"number"}
                                placeholder={"$ 10"}
                                name={"price"}
                                className={cn(
                                    "w-full h-10 px-3 input-number-spin-none text-text-900 bg-white rounded bg-transparent outline-none placeholder:text-text-500",
                                    "border border-divider-lightDark hover:border-primary-main font-bodyPri cursor-text",
                                    "focus:ring-2 focus:ring-primary-main focus:border-none transition duration-200",
                                    (modifyAppointmentDetail?.payload?.price === 0) && "focus:ring-red-500 focus:border-none border-red-500 hover:border-red-500"
                                )}
                                onChange={(event) => {
                                    let safe_number = Number(event.target.value)
                                    if (isNaN(safe_number)) {
                                        safe_number = 0
                                    }
                                    onHandleChangeText(event?.target?.name, (safe_number * 100))
                                }}
                                value={(modifyAppointmentDetail?.payload?.price / 100)?.toString()}
                            />

                            {(modifyAppointmentDetail?.payload?.price === 0) &&
                                <span className={"font-bodyPri font-normal text-text-700 text-sm"}>
                                    {"Choose the"}
                                    <span className={"font-medium text-text-900 text-base"}>
                                        {' "Free" '}
                                    </span>
                                    {"pricing model to set the price to 0!"}
                                </span>
                            }
                        </div>

                        {/* discount percentage */}
                        <div className={"w-full flex flex-col gap-2"}>
                            <span className={"font-bodyPri font-normal text-text-900 text-base"}>
                                {"Discount Percent(%) "}
                            </span>
                            <input
                                type={"number"}
                                placeholder={"e.g. 10%"}
                                name={"discount_pct"}
                                className={cn(
                                    "w-full h-10 px-3 input-number-spin-none text-text-900 bg-white rounded bg-transparent outline-none placeholder:text-text-500",
                                    "border border-divider-lightDark hover:border-primary-main font-bodyPri cursor-text",
                                    "focus:ring-2 focus:ring-primary-main focus:border-none transition duration-200",
                                    (modifyAppointmentDetail?.payload?.discount_pct === 100) && "focus:ring-red-500 focus:border-none border-red-500 hover:border-red-500"
                                )}
                                onChange={(event) => {
                                    let safe_number = Number(event?.target?.value)
                                    if (safe_number <= 100) onHandleChangeText(event?.target?.name, safe_number)
                                }}
                                value={modifyAppointmentDetail?.payload?.discount_pct?.toString() || 0}
                            />
                            {(modifyAppointmentDetail?.payload?.discount_pct === 100) &&
                                <span className={"font-bodyPri font-normal text-text-700 text-sm"}>
                                    {"Choose the"}
                                    <span className={"font-medium text-text-900 text-base"}>
                                        {' "Free" '}
                                    </span>
                                    {"pricing model to apply a 100% discount"}
                                </span>
                            }
                        </div>
                    </>
                }
            </div>

            <div className={"flex flex-col items-end justify-end gap-5"}>
                <div className={"w-full border-b border-text-300"} />
                <div className={"px-5 pb-3"}>
                    <SaveAndCancelBtn
                        isSaveBtnLoading={modifyAppointmentDetail?.isLoading}
                        isSaveBtnDisabled={isSaveBtnDisabled} 
                        saveBtnTooltip={"No changes detected. Nothing to save"}
                        onHandleCancelBtn={onHandleCancelBtn}
                        onHandleSaveAndCloseBtn={onHandleSaveAndCloseBtn}
                    />
                </div>
            </div>
        </div>
    )
};

export default memo(EditPriceDetail);