import { memo, useCallback, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { AnimatePresence, motion } from 'framer-motion';

import { FaInfoCircle } from 'react-icons/fa';
import { IoIosArrowForward } from 'react-icons/io';

import ToolTipView from 'components/tooltipView';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { setAddBookingDetailPayload } from 'redux/booking/booking.slice';
import { modalConst } from 'redux/local/local.const';
import { addBookingDetailPayload, bookingOfferingTypeEnum, bookingStatusEnum } from 'redux/booking/booking.const';

import { pagesInfo } from 'utils/pagesInfo';
import { dayjs, getTimeZoneOffset, timeZone } from 'utils/dateTime.utils';
import { cn } from 'utils/cn.utils';

const MINIMUM_EVENT_LENGTH = 5

const BookingSchedule = ({ newBookingDetail }) => {
    const { modal } = useAppState((state) => state.local)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const timeZoneOffset = useMemo(() => getTimeZoneOffset(timeZone), [timeZone])

    const [visible, setVisible] = useState(true);
    const [lastIndex, setLastIndex] = useState(MINIMUM_EVENT_LENGTH)

    const onHandleOpenSchedule = () => {
        setVisible(!visible)
    }

    const sortEvent = useCallback((event1, event2) => {
        const event1StartTime = dayjs(event1.start_datetime).utc()
        const event2StartTime = dayjs(event2.start_datetime).utc()
        return event2StartTime.isSameOrBefore(event1StartTime) ? 1 : -1
    }, [])

    const onEventSlotSelect = useCallback((sessionId) => {
        navigate(`${pagesInfo.SCHEDULE.pagePath}?sessionId=${sessionId}`)
    }, []);

    const onHandleAddSessions = () => {
        if (newBookingDetail?.data?.result?.offering_type === bookingOfferingTypeEnum?.APPOINTMENT?.value) {
            dispatcher(setAddBookingDetailPayload({
                ...addBookingDetailPayload,
                offeringType: bookingOfferingTypeEnum.APPOINTMENT.value,
                bookingId: newBookingDetail?.data?.result?.id,
                appointmentId: newBookingDetail?.data?.result?.appointment?.id,
                offering: newBookingDetail?.data?.result?.appointment,
                slug: newBookingDetail?.data?.result?.appointment?.slug,
                sessionCount: 1,
                availabilityId: newBookingDetail?.data?.result?.appointment?.appointment_availability?.availability?.id
            }))
        }
        // if (newBookingDetail?.data?.result?.course?.type === courseType?.ONE_ON_ONE?.value) {
        //     dispatcher(setAddBookingDetailPayload({
        //         ...addBookingDetailPayload,
        //         offeringType: bookingOfferingTypeEnum.COHORT.value,
        //         bookingId: newBookingDetail?.data?.result?.id,
        //         courseId: newBookingDetail?.data?.result?.course?.id,
        //         slug: newBookingDetail?.data?.result?.course?.slug,
        //         availabilityId: newBookingDetail?.data?.result?.course?.courseAvailability?.availabilityId
        //     }))
        // }
        dispatcher(setModal({
            ...modal,
            [modalConst.ADD_BOOKING_SESSION_MODAL.stateKey]: true
        }))
    }

    return newBookingDetail?.data?.result && (
        <div className={"flex flex-col gap-1"}>
            <div className={"w-full flex items-center justify-between cursor-pointer"}
                onClick={onHandleOpenSchedule}
            >
                <span className={"text-lg font-bold font-bodyPri"}>
                    {"Session(s)"}
                </span>
                <div className={"flex justify-start items-center gap-3"}>
                    <span className="text-xs text-text-700 font-bodyPri font-normal tracking-wide">
                        {`Your local timezone:  ${timeZone} ${timeZoneOffset}`}
                    </span>
                    <IoIosArrowForward className={cn(
                        "font-bold text-2xl text-text-800 transition ease-in-out delay-50 duration-100",
                        visible
                            ? "rotate-90"
                            : "rotate-0"
                    )} />
                </div>
            </div>
            {(((newBookingDetail?.data?.result?.offering_type === bookingOfferingTypeEnum.APPOINTMENT.value)
                // || (newBookingDetail?.data?.result?.offering_type === bookingOfferingTypeEnum.COHORT.value
                // && (newBookingDetail?.data?.result?.course?.type === courseType?.ONE_ON_ONE?.value))
            )
                && (newBookingDetail?.data?.result?.status === bookingStatusEnum?.CONFIRMED?.value)
                && (newBookingDetail?.data?.result?.user_sessions?.length < newBookingDetail?.data?.result?.booking_price?.session_count)) &&
                <div className={"flex items-center justify-start gap-1 mt-5"}>
                    <div className={cn(
                        "w-fit px-2 py-1.5 flex items-center justify-center gap-1 rounded-lg border border-primary-dark bg-primary-light",
                        "font-bodyPri font-normal text-primary-dark text-base whitespace-nowrap cursor-pointer",
                        "hover:bg-primary-dark hover:text-text-50"
                    )}
                        onClick={onHandleAddSessions}
                    >
                        <span className={""}>
                            {"Add a Session"}
                        </span>
                        <span className={"font-bodyPri font-semibold"}>
                            {`(${newBookingDetail?.data?.result?.booking_price?.session_count - newBookingDetail?.data?.result?.user_sessions?.length} left)`}
                        </span>
                    </div>
                    <ToolTipView content={"Multi session pack: Book your remaining session(s) with your tutor. Add a session and choose a suitable slot. You may message your tutor to open more slots."}>
                        <button className={""}>
                            <FaInfoCircle className={"inline text-md text-text-500 hover:text-text-700"} />
                        </button>
                    </ToolTipView>
                </div>
            }
            <AnimatePresence initial={visible}>
                {visible && (
                    <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: "auto", opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        className={"w-full pt-4 space-y-3 divide-y sm:divide-y-0 transition ease-in-out delay-200 duration-300"}
                    >
                        {(!newBookingDetail?.data?.result?.user_sessions || (newBookingDetail?.data?.result?.user_sessions?.length === 0)) &&
                            <div className={"w-full flex items-center justify-center"}>
                                <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                                    {"No Schedule Added"}
                                </span>
                            </div>
                        }
                        {!!newBookingDetail?.data?.result?.user_sessions?.length &&
                            newBookingDetail?.data?.result?.user_sessions?.slice(0, lastIndex)?.sort(sortEvent)?.map((event, index) => (
                                <div key={index} className={"w-full flex items-center justify-center md:justify-between group font-bodyPri"}>
                                    <span
                                        className={"text-xs md:text-base sm:flex-row sm:w-9/12 cursor-pointer group-hover:text-primary-dark group-hover:underline"}
                                        onClick={() => onEventSlotSelect(event?.session?.id)}
                                    >
                                        {dayjs(event?.session?.start_datetime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").format('ddd, MMM DD, YYYY')}
                                    </span>
                                    <div
                                        className={"w-full space-x-1 cursor-pointer text-slate-600 group-hover:text-primary-dark group-hover:underline"}
                                        onClick={() => onEventSlotSelect(event?.session?.id)}
                                    >
                                        <span className="">{dayjs(event?.session?.start_datetime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").format('hh:mm A')}</span>
                                        <span>-</span>
                                        <span className="">
                                            {dayjs(event?.session?.end_datetime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").format('hh:mm A')}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        {(newBookingDetail?.data?.result?.user_sessions?.length > lastIndex) &&
                            <div className={"flex items-center justify-center font-bodyPri font-normal text-primary-main text-base cursor-pointer hover:underline"}
                                onClick={() => setLastIndex(newBookingDetail?.data?.result?.user_sessions?.length)}
                            >
                                {"See More"}
                            </div>
                        }
                        {((newBookingDetail?.data?.result?.user_sessions?.length > 5) && (newBookingDetail?.data?.result?.user_sessions?.length <= lastIndex)) &&
                            <div className={"flex items-center justify-center font-bodyPri font-normal text-primary-main text-base cursor-pointer hover:underline"}
                                onClick={() => setLastIndex(MINIMUM_EVENT_LENGTH)}
                            >
                                {"See Less"}
                            </div>
                        }
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default memo(BookingSchedule);