import { useMemo } from 'react';

import ComponentLoader from 'components/loader/ComponentLoader';

import { useGetSessionDetail } from '../_hooks/useCohort.hooks';
import SessionDetailTypeView from './_components/SessionDetailTypeView';
import SessionDetailDateTimeSlot from './_components/SessionDetailDateTimeSlot';
import SessionDetailCountDown from './_components/SessionDetailCountDown';
import SessionDefaultLocation from './_components/SessionDefaultLocation';
import SessionDetailRoleView from './_components/SessionDetailRoleView';
import SessionDetailCourseType from './_components/SessionDetailCourseType';
import SessionDetailStatus from './_components/SessionDetailStatus';
import SessionDetailLocation from './_components/SessionDetailLocation';
import SessionDetailParticipants from './_components/SessionDetailParticipants';
import SessionDetailSessionLog from './_components/SessionDetailSessionLog';

import { useAppState } from 'hooks/useStore';

import { cn } from 'utils/cn.utils';

const SessionView = () => {
    const { user } = useAppState((state) => state.user)
    const { sessionUserDetail } = useAppState((state) => state.session)

    const _ = useGetSessionDetail()

    const sessionUserInfo = useMemo(() => sessionUserDetail?.data?.result?.session_users?.find((sessionUser) => sessionUser?.user?.id === user?.user?.userId), [sessionUserDetail?.data?.result?.session_users, user?.user?.userId])

    if (sessionUserDetail?.isLoading) {
        return (
            <div className={"h-full w-full flex items-center justify-center py-5"}>
                <ComponentLoader isLoading={sessionUserDetail?.isLoading} />
            </div>
        )
    }

    if (sessionUserDetail?.message) {
        return (
            <div className={"h-full w-full flex items-center justify-center py-5"}>
                <span className={"font-bodyPri font-normal text-red-500 text-base"}>
                    {sessionUserDetail?.message}
                </span>
            </div>
        )
    }

    return (
        <div className={cn(
            "grow",
            "overflow-auto scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
            "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-light"
        )}>
            <div className={"max-w-full sm:max-w-xl md:max-w-3xl w-full mx-auto px-3 md:px-8 py-5 space-y-5 select-none"}>
                <div className={"p-5 rounded-lg bg-white shadow-all space-y-5"}>
                    <div className={""}>
                        <h2 className={"font-bodyPri font-bold text-xl md:text-3xl text-900 text-center line-clamp-1"}>
                            {sessionUserDetail?.data?.result?.name}
                        </h2>
                    </div>

                    <SessionDetailTypeView />

                    <SessionDetailDateTimeSlot />

                    <SessionDetailCountDown sessionUserInfo={sessionUserInfo} />

                    {!!sessionUserDetail?.data?.result?.session_locations?.length &&
                        <SessionDefaultLocation sessionLocationInfo={sessionUserDetail?.data?.result?.session_locations[0]} />
                    }

                    <SessionDetailRoleView sessionUserInfo={sessionUserInfo} />

                    <SessionDetailCourseType />

                    <SessionDetailStatus sessionUserInfo={sessionUserInfo} />

                    {sessionUserDetail?.data?.result && <div className={"h-0.5 bg-back-ground-medium w-full rounded-full"}></div>}

                    <SessionDetailLocation />

                    {sessionUserDetail?.data?.result && <div className={"h-0.5 bg-back-ground-medium w-full rounded-full"}></div>}

                    <SessionDetailParticipants sessionUserInfo={sessionUserInfo} />

                    {sessionUserDetail?.data?.result && <div className={"h-0.5 bg-back-ground-medium w-full rounded-full"}></div>}

                    <SessionDetailSessionLog />
                </div>
            </div>
        </div>
    )
}

export default SessionView;