import { userRoles } from 'redux/auth/auth.const';

const StudentIconImageUrl = 'https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/role-image/student-role-image.png';
const TutorIconImageUrl = 'https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/role-image/tutor-role-image.png';

export const switchRoleOption = {
    USER: {
        ...userRoles.STUDENT,
        roleImageUrl: StudentIconImageUrl
    },
    TUTOR: {
        ...userRoles.TUTOR,
        roleImageUrl: TutorIconImageUrl
    }
}