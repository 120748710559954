import { useEffect } from 'react';
import { cn } from "utils/cn.utils";

import { FiSearch } from 'react-icons/fi';

import { classDetailNavigationOptions, classDetailTooltips } from '../../data';
import PageHeading from '../../_components/header/pageHeading';
import PreviousNextButtons from '../../_components/buttons/PreviousNextButtons';

import { getCourseTags, updateCourse } from 'redux/course/course.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { resetCourseTags } from 'redux/course/course.slice';
import { resetUploadFile, setUploadFilePayload } from 'redux/storage/storage.slice';
import { setClearThumbnailDetailPayload, setThumbnailDetailPayload, setThumbnailDetailPayloadLoading } from 'redux/other/other.slice';
import { modalConst } from 'redux/local/local.const';
import { fileCategories } from 'redux/storage/storage.const';
import { orientationTypeConst } from 'redux/other/other.const';

const ThumbnailPage = () => {
    const { modal } = useAppState(s => s.local)
    const { uploadFile } = useAppState((s) => s.storage)
    const { thumbnailDetail } = useAppState((state) => state.other)
    const { instructorCourse, courseTags } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        if (!!instructorCourse?.result?.id) {
            dispatcher(getCourseTags(instructorCourse?.result?.id))
        }

        return () => {
            dispatcher(resetCourseTags())
        }
    }, [instructorCourse?.result?.id])

    useEffect(() => {
        if (uploadFile?.data && (uploadFile?.payload?.fileCategory === fileCategories.courseThumbnail.value)) {
            dispatcher(updateCourse(
                instructorCourse?.result?.id,
                {
                    thumbnail_pic_url: uploadFile?.data[0]?.fileUrl
                }
            ))
            dispatcher(resetUploadFile())
        }
    }, [uploadFile?.data])

    useEffect(() => {
        if (thumbnailDetail?.thumbnailDetailPayload?.url) {
            dispatcher(setThumbnailDetailPayloadLoading(true))
            dispatcher(updateCourse(
                instructorCourse?.result?.id,
                {
                    thumbnail_pic_url: thumbnailDetail?.thumbnailDetailPayload?.url
                }
            ))
            setTimeout(() => {
                dispatcher(setClearThumbnailDetailPayload())
                dispatcher(setModal({
                    ...modal,
                    [modalConst.PHOTO_SEARCH_MODAL.stateKey]: false
                }))
            }, 2000)
        }
    }, [thumbnailDetail?.thumbnailDetailPayload?.url])

    const handleOnSelectImage = () => {
        dispatcher(resetUploadFile())
        dispatcher(setUploadFilePayload({
            ...uploadFile?.payload,
            fileCategory: fileCategories.courseThumbnail.value
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.IMAGE_PICKER_MODAL.stateKey]: true
        }))
    }

    const onHandleSearchThumbnail = () => {
        dispatcher(setThumbnailDetailPayload({
            ...thumbnailDetail?.thumbnailDetailPayload,
            keywords: courseTags?.result?.map((courseTag) => ({
                skill: courseTag?.master_tag?.tag
            })),
            query: instructorCourse?.result?.category?.category ? instructorCourse?.result?.category?.category : "Learn English",
            orientation: orientationTypeConst.LANDSCAPE.value,
            dimensionUnit: 11
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.PHOTO_SEARCH_MODAL.stateKey]: true
        }))
    }

    return (
        <div className={"w-full space-y-5"}>
            <div className={"w-full flex flex-col gap-5"}>
                <PageHeading headerText={"Thumbnail"} />

                <div className={"flex flex-col items-start justify-start gap-5"}>

                    <div className={"w-full text-center font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                        {classDetailTooltips?.thumbnail}
                    </div>
                    <div className={"w-full mx-auto flex flex-wrap items-center justify-center gap-3 border-b pb-1.5"}>
                        <div className={cn(
                            "min-w-80 max-w-full pl-5 pr-1 py-1 flex items-center justify-between cursor-default space-x-5",
                            "font-bodyPri font-normal text-text-500 text-base",
                            "border-2 border-divider-medium rounded-full hover:outline-none hover:border-2 hover:border-text-400"
                        )}
                            onClick={onHandleSearchThumbnail}
                        >
                            <span className={"overflow-hidden truncate"}>
                                {`e.g: ${instructorCourse?.result?.category?.category ? instructorCourse?.result?.category?.category : "Learn English"}`}
                            </span>
                            <span className={"flex items-center justify-center gap-1 rounded-full px-2 py-0.5 bg-primary-dark text-text-50 font-bodyPri font-light text-base"}>
                                <FiSearch className={"text-text-50 text-base"} />
                                <span className={"text-sm"}>
                                    {"Search"}
                                </span>
                            </span>
                        </div>
                        <div className={"font-bodyPri font-normal text-text-900 text-base"}>
                            <span className={""}>
                                {"Or"}
                            </span>
                        </div>
                        <div
                            className={cn(
                                "px-2 py-0.5 cursor-pointer border-2 border-primary-dark rounded-lg text-center",
                                "font-bodyPri font-normal text-primary-dark text-base",
                                "hover:bg-primary-dark hover:text-text-50"
                            )}
                            onClick={handleOnSelectImage}
                        >
                            <span className={""}>
                                {"Upload"}
                            </span>
                        </div>
                    </div>
                    {instructorCourse?.result?.thumbnail_pic_url &&
                        <div className={"w-full space-y-3"}>
                            <div className={"w-full font-bodyPri font-normal text-text-900 text-base text-center"}>
                                {"Current Image"}
                            </div>
                            <div className={"mx-auto w-full md:w-[50%] aspect-video rounded-lg overflow-hidden shadow-all-md"}>
                                <img
                                    src={instructorCourse?.result?.thumbnail_pic_url}
                                    className={"w-full h-full object-cover"}
                                    alt={"Thumbnail-photo"}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
            <PreviousNextButtons
                previousBtn={classDetailNavigationOptions?.proficiencies?.to}
                nextBtn={classDetailNavigationOptions?.video?.to}
            />
        </div>
    )
}

export default ThumbnailPage;