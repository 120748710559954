import { useEffect, useCallback, useMemo, memo } from 'react'
import { useNavigate, useLocation } from "react-router-dom";

import { IoIosArrowBack } from 'react-icons/io';
import { BsInfoCircle } from 'react-icons/bs';

import ToolTipView from 'components/tooltipView';
import Pagination from 'components/pagination/Pagination';

import { appointmentDetailTooltips, searchParamsInfo } from 'pages/auth/editAppointment/data';
import SessionAppointmentCard, { SessionAppointmentCardLoader } from 'pages/auth/editAppointment/detail/edit/sessions/SessionAppointmentCard';

import { getSessionAppointmentList } from "redux/session/session.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { resetSessionAppointmentList } from "redux/session/session.slice";

import { cn } from 'utils/cn.utils';

const RECORDS = 5;

const AppointmentSessionList = ({ onHandleCloseSection }) => {
    const { modifyAppointmentDetail } = useAppState((state) => state.appointment)
    const { sessionAppointmentList } = useAppState((state) => state.session)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()

    const searchQueryParams = useMemo(() => new URLSearchParams(location.search.toString()), [location.search])

    useEffect(() => {
        if (modifyAppointmentDetail?.payload?.id) {
            const requestPayload = {
                page: 1,
                records: RECORDS
            }
            dispatcher(getSessionAppointmentList(modifyAppointmentDetail?.payload?.id, requestPayload))
        }

        return () => {
            dispatcher(resetSessionAppointmentList)
        }
    }, [modifyAppointmentDetail?.payload?.id])

    useEffect(() => {
        if (sessionAppointmentList?.data && !!searchQueryParams.get(searchParamsInfo.page.key)) {
            const requestPayload = {
                page: searchQueryParams.get(searchParamsInfo.page.key),
                records: RECORDS
            }
            dispatcher(getSessionAppointmentList(modifyAppointmentDetail?.payload?.id, requestPayload))
        }
    }, [searchQueryParams.get(searchParamsInfo.page.key)])

    const onHandlePageChange = useCallback((page) => {
        searchQueryParams.set(searchParamsInfo.page.key, page)
        navigate(`${location.pathname}?${searchQueryParams.toString()}`)
    }, [location])

    return (
        <div className={"w-full h-full flex flex-col"}>
            <div className={"h-28 p-5 flex flex-col gap-3 md:gap-5 border-b border-text-300 font-bodyPri"}>
                <div className={"w-fit flex items-center justify-start gap-2 cursor-pointer group"} onClick={onHandleCloseSection}>
                    <IoIosArrowBack className={"text-xl text-text-800 group-hover:text-secondary-dark"} />
                    <span className={"font-bodyPri font-normal text-text-900 text-base underline tracking-wide line-clamp-1 group-hover:text-secondary-dark cursor-pointer"}>
                        {"Booking Summary"}
                    </span>
                </div>
                <div className={"flex items-center justify-start gap-2"}>
                    <span className={"font-bold text-lg text-text-900 tracking-wide"}>
                        {"Booked Sessions"}
                    </span>
                    <ToolTipView content={appointmentDetailTooltips.bookedSessions}>
                        <button className={""}>
                            <BsInfoCircle className={"inline text-md text-text-900 hover:text-text-900"} />
                        </button>
                    </ToolTipView>
                </div>
            </div>
            <div className={cn(
                "p-5 w-full flex-grow overflow-y-auto space-y-5",
                "scrollbar-thin scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-light",
            )}
            >
                {new Array(RECORDS).fill("").map((_, index) => <SessionAppointmentCardLoader key={index} isLoading={sessionAppointmentList?.isLoading} />)}
                {(!sessionAppointmentList?.isLoading && !!sessionAppointmentList?.data?.result?.length) &&
                    sessionAppointmentList?.data?.result?.map((sessionAppointment, index) => (
                        <SessionAppointmentCard
                            key={index}
                            sessionAppointment={sessionAppointment}
                        />
                    ))
                }
                {(!sessionAppointmentList?.isLoading && (sessionAppointmentList?.data?.pagination?.records === 0)) &&
                    <div className={"w-full h-full flex items-center justify-center"}>
                        <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide text-center"}>
                            {"No session found, please check your availability or share your booking link"}
                        </span>
                    </div>
                }
                {(!sessionAppointmentList?.isLoading && sessionAppointmentList?.message) &&
                    <div className={"w-full h-full flex items-center justify-center"}>
                        <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide text-center"}>
                            {sessionAppointmentList?.message || "No session(s) Booked!"}
                        </span>
                    </div>
                }
            </div>
            {(!sessionAppointmentList?.isLoading && (!!sessionAppointmentList?.data?.result?.length && (sessionAppointmentList?.data?.pagination?.totalPages > 1))) && (
                <div className={"flex flex-col gap-5"}>
                    <div className={"w-full border-b border-text-300"} />
                    <div className={"w-full flex justify-center items-center px-5 pb-3"}>
                        <Pagination
                            page={sessionAppointmentList?.data?.pagination?.page}
                            totalPages={sessionAppointmentList?.data?.pagination?.totalPages}
                            onChangePage={onHandlePageChange}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default memo(AppointmentSessionList);