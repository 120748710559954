import { toast } from 'react-toastify';

import userService from 'redux/user/user.service';
import {
    setUserLoading,
    setUser,
    setUserErrorMsg,

    setUserDetailLoading,
    setUserDetail,
    setUserDetailErrorMsg,

    setAddUserDetailLoading,
    setAddUserDetailErrorMsg,

    setUserCultureDetailLoading,
    setUserCultureDetail,
    setUserCultureDetailErrorMsg,

    setAddUserCultureDetailLoading,
    setAddUserCultureDetailErrorMsg,

    setUserEducationListLoading,
    setUserEducationList,
    setUserEducationListErrorMsg,

    setAddUserEducationDetailLoading,
    seAddUserEducationDetailErrorMsg,

    setUserOtherPrefLoading,
    setUserOtherPref,
    setUserOtherPrefErrorMsg,

    setAddUserOtherPrefLoading,
    setAddUserOtherPrefErrorMsg,

    setUserSocialMediaLinksLoading,
    setUserSocialMediaLinks,
    setUserSocialMediaLinksErrorMsg,

    setAddUserSocialMediaLinksLoading,
    setAddUserSocialMediaLinksErrorMsg,

    setUserAddressListLoading,
    setUserAddressList,
    setUserAddressListErrorMsg,

    setAddUserAddressDetailLoading,
    setAddUserAddressDetailErrorMsg,

    setUserListLoading,
    setUserListData,
    setUserListMessage,

    setModifyUserConfigLoading,
    setModifyUserConfigMessage,
    setModifyUserConfigData,
} from 'redux/user/user.slice';

import { setSessionLoggedIn } from 'redux/auth/auth.slice';

import { setUserLocals } from "redux/user/user.utils";

export const getMyProfile = () => async (dispatch) => {
    dispatch(setUserLoading(true));

    try {
        const response = await userService.getMyProfile()
        if (response.status === 200) {
            dispatch(setUser(response.data.data))
            dispatch(getUserCultureDetailByUserId(response?.data?.data?.userId))
            dispatch(setSessionLoggedIn())
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setUserErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setUserLoading(false));
    }
}

export const getUserDetailByUserId = (userId) => async (dispatch) => {
    dispatch(setUserDetailLoading(true))

    try {
        const requestData = {
            params: { userId: userId }
        }
        const response = await userService.getUserDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUserDetail(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setUserDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setUserDetailLoading(false))
    }
}

export const updateUserDetailByUserId = (userId, body) => async (dispatch) => {
    dispatch(setAddUserDetailLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await userService.updateUserDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(getMyProfile())
            dispatch(setUserDetail(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddUserDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setAddUserDetailLoading(false))
    }
}

export const getUserCultureDetailByUserId = (userId) => async (dispatch, getState) => {
    dispatch(setUserCultureDetailLoading(true))

    try {
        const { user } = getState().user
        const requestData = {
            params: { userId: userId }
        }
        const response = await userService.getUserCultureDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUserCultureDetail(response.data.data))
            await setUserLocals({
                roles: user?.user?.policy_permissions,
                displayLanguage: response?.data?.data?.displayLanguage?.displayLanguage,
                currencyCode: response?.data?.data?.currency?.code,
                timeZone: response?.data?.data?.timeZone?.timeZone,
                timeFormat: response?.data?.data?.timeFormat,
            })
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setUserCultureDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setUserCultureDetailLoading(false))
    }
}

export const updateUserCultureDetailByUserId = (userId, body) => async (dispatch) => {
    dispatch(setAddUserCultureDetailLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await userService.updateUserCultureDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUserCultureDetail(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddUserCultureDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setAddUserCultureDetailLoading(false))
    }
}

export const getUserEducationListByUserId = (userId) => async (dispatch) => {
    dispatch(setUserEducationListLoading(true))

    try {
        const requestData = {
            params: { userId: userId }
        }
        const response = await userService.getUserEducationListByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUserEducationList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setUserEducationListErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setUserEducationListLoading(false))
    }
}

export const createUserEducationDetailByUserId = (userId, body) => async (dispatch, getState) => {
    dispatch(setAddUserEducationDetailLoading(true))

    try {
        const { userEducationList } = getState().user
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await userService.createUserEducationDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUserEducationList([
                ...userEducationList?.userEducationList,
                response.data.data
            ]))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(seAddUserEducationDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setAddUserEducationDetailLoading(false))
    }
}

export const updateEducationDetailByUserEducationId = (userEducationId, body) => async (dispatch, getState) => {
    dispatch(setAddUserEducationDetailLoading(true))

    try {
        const { userEducationList } = getState().user
        const requestData = {
            params: { userEducationId: userEducationId },
            body: body
        }
        const response = await userService.updateEducationDetailByUserEducationId(requestData)
        if (response.status === 200) {
            dispatch(setUserEducationList(
                userEducationList?.userEducationList?.map((userEducation) => (
                    userEducation.userEducationId === response.data.data.userEducationId ? response.data.data : userEducation
                ))
            ))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(seAddUserEducationDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setAddUserEducationDetailLoading(false))
    }
}

export const deleteEducationDetailByUserEducationId = (userEducationId, body) => async (dispatch, getState) => {
    dispatch(setAddUserEducationDetailLoading(true))

    try {
        const { userEducationList } = getState().user
        const requestData = {
            params: { userEducationId: userEducationId },
            body: body
        }
        const response = await userService.deleteEducationDetailByUserEducationId(requestData)
        if (response.status === 200) {
            dispatch(setUserEducationList(
                userEducationList?.userEducationList?.filter((userEducation) => (
                    userEducation.userEducationId !== userEducationId
                ))
            ))
            toast.success(response.data.message)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(seAddUserEducationDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setAddUserEducationDetailLoading(false))
    }
}

export const getUserOtherPrefDetailByUserId = (userId) => async (dispatch, getState) => {
    dispatch(setUserOtherPrefLoading(true))

    try {
        const requestData = {
            params: { userId: userId }
        }
        const response = await userService.getUserOtherPrefDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUserOtherPref(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setUserOtherPrefErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setUserOtherPrefLoading(false))
    }
}

export const updateUserOtherPrefDetailByUserId = (userId, body) => async (dispatch, getState) => {
    dispatch(setAddUserOtherPrefLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await userService.updateUserOtherPrefDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUserOtherPref(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddUserOtherPrefErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
        toast.error(error.response.data.message || error.response.data.error || "Something went wrong")
    } finally {
        dispatch(setAddUserOtherPrefLoading(false))
    }
}

// request for user social media presence
export const getUserSocialListByUserId = (userId) => async (dispatch) => {
    dispatch(setUserSocialMediaLinksLoading(true))

    try {
        const requestData = {
            params: { userId: userId }
        }
        const response = await userService.getUserSocialListByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUserSocialMediaLinks(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setUserSocialMediaLinksErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setUserSocialMediaLinksLoading(false))
    }
}

export const createUserSocialDetailByUserId = (userId, body) => async (dispatch, getState) => {
    dispatch(setAddUserSocialMediaLinksLoading(true))

    try {
        const { userSocialMediaLinks } = getState().user
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await userService.createUserSocialDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUserSocialMediaLinks([...userSocialMediaLinks?.userSocialMediaLinks, response.data.data]))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddUserSocialMediaLinksErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setAddUserCultureDetailLoading(false))
    }
}

export const updateSocialDetailByUserSocialId = (userSocialId, body) => async (dispatch, getState) => {
    dispatch(setAddUserSocialMediaLinksLoading(true))

    try {
        const { userSocialMediaLinks } = getState().user
        const requestData = {
            params: { userSocialId: userSocialId },
            body: body
        }
        const response = await userService.updateSocialDetailByUserSocialId(requestData)
        if (response.status === 200) {
            const filteredList = userSocialMediaLinks?.userSocialMediaLinks?.filter((userSocial) => userSocial?.userSocialId !== userSocialId)
            dispatch(setUserSocialMediaLinks([...filteredList, response.data.data]))
            toast.success(response.data.message)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddUserSocialMediaLinksErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setAddUserSocialMediaLinksLoading(false))
    }
}

// request for user addresses
export const getUserAddressListByUserId = (userId) => async (dispatch) => {
    dispatch(setUserAddressListLoading(true))

    try {
        const requestData = {
            params: { userId: userId }
        }
        const response = await userService.getUserAddressListByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUserAddressList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setUserAddressListErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setUserAddressListLoading(false))
    }
}

export const createUserAddressDetailByUserId = (userId, body) => async (dispatch, getState) => {
    dispatch(setAddUserAddressDetailLoading(true))

    try {
        const { userAddressList } = getState().user
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await userService.createUserAddressDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUserAddressList([...userAddressList?.userAddressList, response.data.data]))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddUserAddressDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setAddUserAddressDetailLoading(false))
    }
}

export const updateAddressDetailByUserAddressId = (userAddressId, body) => async (dispatch, getState) => {
    dispatch(setAddUserAddressDetailLoading(true))

    try {
        const { userAddressList } = getState().user
        const requestData = {
            params: { userAddressId: userAddressId },
            body: body
        }
        const response = await userService.updateAddressDetailByUserAddressId(requestData)
        if (response.status === 200) {
            const filteredAddressList = userAddressList?.userAddressList?.filter((userAddress) => userAddress?.userAddressId !== userAddressId)
            dispatch(setUserAddressList([...filteredAddressList, response.data.data]))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddUserAddressDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setAddUserAddressDetailLoading(false))
    }
}

export const deleteAddressDetailByUserAddressId = (userAddressId, body) => async (dispatch, getState) => {
    dispatch(setAddUserAddressDetailLoading(true))

    try {
        const { userAddressList } = getState().user
        const requestData = {
            params: { userAddressId: userAddressId },
            body: body
        }
        const response = await userService.deleteAddressDetailByUserAddressId(requestData)
        if (response.status === 200) {
            const filteredUserAddressList = userAddressList?.userAddressList?.filter((userAddress) => userAddress?.userAddressId !== userAddressId)
            dispatch(setUserAddressList(filteredUserAddressList))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || error)
        dispatch(setAddUserAddressDetailErrorMsg(error.response.data.message || error.response.data.error || "Something went wrong"))
    } finally {
        dispatch(setAddUserAddressDetailLoading(false))
    }
}

export const getUserList = (query) => async (dispatch) => {
    dispatch(setUserListLoading(true))

    try {
        const requestData = {
            query: query
        }
        const response = await userService.getUserList(requestData)
        if (response.status === 200) {
            dispatch(setUserListData(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setUserListMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setUserListLoading(false))
    }
}

export const updateUserConfig = (userId, body) => async (dispatch, getState) => {
    dispatch(setModifyUserConfigLoading(true))

    try {
        const { user } = getState().user

        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await userService.updateUserConfig(requestData)
        if (response.status === 200) {
            dispatch(setModifyUserConfigData(response?.data?.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error || "Something went wrong!")
        dispatch(setModifyUserConfigMessage(error.response.data.message || error.response.data.error || "Something went wrong!"))
    } finally {
        dispatch(setModifyUserConfigLoading(false))
    }
}