import { useState, memo, useRef } from 'react';

import { AnimatePresence, motion } from "framer-motion";

import { MdArrowDropDown } from 'react-icons/md';

import ButtonLoader from 'components/loader/ButtonLoader';

import { cn } from "utils/cn.utils";

const StatusButton = ({
    isLoading = false,
    optionsList = [],
    selectedOption = null,
    onHandleUpdateStatus,
    loaderBtnClassName = "bg-primary-dark",
    selectedBtnClassName = "bg-primary-dark hover:bg-blue-900 text-white"
}) => {

    const [showDropdown, setShowDropdown] = useState(false);
    const statusContainerRef = useRef(null);

    const handleClickOutside = (event) => {
        if (statusContainerRef.current && !statusContainerRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
    };

    const onHandleOpenDropdown = () => {
        setShowDropdown(!showDropdown);
        if (!showDropdown) {
            document.addEventListener("click", handleClickOutside);
        } else {
            document.removeEventListener("click", handleClickOutside);
        }
    };

    return (
        <div ref={statusContainerRef} className={""}>
            {isLoading &&
                <div className={cn("w-[6rem] py-2", loaderBtnClassName)}>
                    <ButtonLoader isLoading={isLoading} className='w-full h-full' />
                </div>
            }
            {!isLoading &&
                <div className={"flex items-center justify-center h-10 relative font-bodyPri"}>
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        transition={{ duration: 0.3 }}
                        className={cn("px-3 md:px-5 h-10 flex items-center justify-center gap-1.5 relative rounded font-bodyPri", selectedBtnClassName)}
                        onClick={onHandleOpenDropdown}
                    >
                        <span className={""}>
                            {selectedOption}
                        </span>
                        <span className={""}>
                            <MdArrowDropDown size={20} />
                        </span>
                    </motion.button>
                    <AnimatePresence initial={showDropdown}>
                        {showDropdown && (
                            <motion.ul
                                initial={{ opacity: 0, y: -10, height: 0 }}
                                animate={{ opacity: 1, y: 0, height: "auto" }}
                                exit={{ opacity: 0, y: -10, height: 0 }}
                                transition={{ type: "spring", duration: 0.5 }}
                                className={"w-full absolute z-30 top-11 left-0 py-1 rounded-md overflow-hidden bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"}
                            >
                                {Object.values(optionsList)?.map((option, index) => (
                                    <motion.li
                                        key={index}
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        className={cn(
                                            "cursor-pointer px-3 py-1 ease-in-out duration-300",
                                            "hover:text-secondary-dark hover:bg-back-ground-lightBlue"
                                        )}
                                        onClick={() => {
                                            onHandleUpdateStatus(option)
                                            setShowDropdown(false)
                                        }}
                                    >
                                        {option?.label}
                                    </motion.li>
                                ))}
                            </motion.ul>
                        )}
                    </AnimatePresence>
                </div>
            }
        </div>
    );
};

export default memo(StatusButton);