import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { toast } from 'react-toastify';

import ButtonLoader from 'components/loader/button-loader';

import { createCourseFaq } from 'redux/course/course.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';

import { cn } from 'utils/cn.utils';

const FaqForm = ({ setIsShowAddButton, setIsAddFaqEnabled }) => {
    const { courseFaqs, addCourseFaq } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()
    const { courseId } = useParams()

    const [faqPayload, setFaqPayload] = useState({
        question: "",
        answer: ""
    })

    const onHandleChangeText = (event) => {
        setFaqPayload({
            ...faqPayload,
            [event?.target?.name]: event?.target?.value
        })
    }

    const onHandleSaveFaq = () => {
        if (courseFaqs?.isLoading || addCourseFaq?.isLoading) return;

        if (!faqPayload?.question) {
            toast.warn("Please add Question!")
            return;
        }
        if (!faqPayload?.answer) {
            toast.warn("Please add Answer!")
            return;
        }
        dispatcher(createCourseFaq(Number(courseId), {
            question: faqPayload?.question,
            answer: faqPayload?.answer
        }))
        setTimeout(() => {
            setIsShowAddButton(true)
            setIsAddFaqEnabled(false)
            setFaqPayload({
                question: "",
                answer: ""
            })
        }, 1000);
    }

    const onHandleResetFaq = useCallback(() => {
        setFaqPayload({
            question: "",
            answer: ""
        })
        if (courseFaqs?.result?.length > 0) {
            setIsShowAddButton(true)
        }
        setIsAddFaqEnabled(false)
    }, [])

    return (
        <div className={"w-full flex flex-col gap-5 font-bodyPri"}>
            <div className={"w-full flex flex-col gap-2"}>
                <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                    {"Question:"}
                </span>
                <div className={"w-full space-y-2"}>
                    <input
                        type={"text"}
                        name={"question"}
                        placeholder={"Write question here ..."}
                        className={cn(
                            "w-full h-fit px-3 py-2 border border-divider-lightDark rounded-lg",
                            "font-bodyPri font-normal text-text-900 text-base tracking-wide first-letter:capitalize",
                            "focus:outline-none focus:border-primary-main"
                        )}
                        autoFocus={true}
                        value={faqPayload?.question}
                        onChange={onHandleChangeText}
                    />
                    {faqPayload?.question && (faqPayload?.question?.length < 10) &&
                        <div className={"text-sm text-red-500"}>{"Question can have minimum 10 characters"}</div>
                    }
                </div>
            </div>
            <div className={"w-full flex flex-col gap-2"}>
                <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                    {"Answer:"}
                </span>
                <div className={"w-full space-y-2"}>
                    <textarea
                        type={"text"}
                        name={"answer"}
                        className={cn(
                            "w-full h-fit px-3 py-2 resize-none border border-divider-lightDark rounded-lg",
                            "font-bodyPri font-normal text-text-900 text-base tracking-wide",
                            "focus:outline-none focus:border-primary-main first-letter:capitalize"
                        )}
                        rows={5}
                        placeholder={"Write answer here ..."}
                        value={faqPayload?.answer}
                        onChange={onHandleChangeText}
                    />
                    {faqPayload?.answer && (faqPayload?.answer?.length < 10) &&
                        <div className={"text-sm text-red-500"}>{"Answer can have minimum 10 characters"}</div>
                    }
                </div>
            </div>
            <div className={"flex items-center justify-start gap-3"}>
                <button
                    className={cn(
                        "flex item-center justify-center w-20 py-1 rounded-md cursor-pointer",
                        "font-buttons font-normal text-base text-text-50 bg-primary-main",
                        "hover:bg-primary-dark",
                        (!faqPayload?.question || !faqPayload?.answer || (faqPayload?.question?.length < 10) || (faqPayload?.answer?.length < 10)) && "bg-text-300 text-text-200 hover:bg-text-300"
                    )}
                    onClick={onHandleSaveFaq}
                    disabled={!faqPayload?.question || !faqPayload?.answer || (faqPayload?.question?.length < 10) || (faqPayload?.answer?.length < 10)}
                >
                    {addCourseFaq?.isLoading &&
                        <ButtonLoader className={"py-2"} isLoading={addCourseFaq?.isLoading} />
                    }
                    {!addCourseFaq?.isLoading &&
                        <span className={""}>
                            {"Save"}
                        </span>
                    }
                </button>
                <div
                    onClick={onHandleResetFaq}
                    className={cn(
                        "flex item-center justify-center w-20 py-1 rounded-md cursor-pointer",
                        "font-buttons font-normal text-base text-text-800 bg-back-ground-darkLight",
                        "hover:bg-back-ground-medium hover:text-text-900"
                    )}>
                    <span className={""}>
                        {"Reset"}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default FaqForm;