import format from 'string-format';

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url, oldUrl } from "redux/booking/booking.const"


class BookingService {

    // Booking Service
    createBookingDetail = async ({ body }) => {
        const response = baseAuthApiInstance().post(
            format(url.CREATE_BOOKING_DETAIL),
            body
        )

        return response
    }
    initializeBookingDetail = async ({ params, query }) => {
        const response = baseAuthApiInstance().post(
            format(url.INITIALIZE_BOOKING_DETAIL, params),
            { params: query }
        )

        return response
    }
    confirmBookingDetail = async ({ params }) => {
        const response = baseAuthApiInstance().post(
            format(url.CONFIRM_BOOKING_DETAIL, params)
        )

        return response
    }
    getBookingDetailConfirmation = async ({ params }) => {
        const response = baseAuthApiInstance().get(
            format(url.GET_BOOKING_DETAIL_CONFIRMATION, params)
        )

        return response
    }

    // User Booking Service
    getUserBookingList = async ({ params, query }) => {
        const response = baseAuthApiInstance().get(
            format(url.GET_USER_BOOKING_LIST, params),
            { params: query }
        )

        return response
    }
    getUserBookingDetail = async ({ params, query }) => {
        const response = baseAuthApiInstance().get(
            format(url.GET_USER_BOOKING_DETAIL, params),
            { params: query }
        )

        return response
    }
    updateUserBookingDetail = async ({ params, body }) => {
        const response = baseAuthApiInstance().put(
            format(url.UPDATE_USER_BOOKING_DETAIL, params),
            body
        )

        return response
    }

    getUserPreBookingDetail = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_USER_PRE_BOOKING_DETAIL, params),
            { params: query }
        )

        return response;
    }

    // tutor booking service
    getTutorBookingList = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_TUTOR_BOOKING_LIST, params),
            { params: query }
        )

        return response;
    }
    getTutorBookingDetail = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_TUTOR_BOOKING_DETAIL, params),
            { params: query }
        )

        return response;
    }

    // booking session service
    createBookingSessionDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.ADD_BOOKING_SESSION_DETAIL, params),
            body
        )

        return response;
    }

    cancelSessionBookingDetail = async ({ params, body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.CANCEL_BOOKING_SESSION_DETAIL, params),
            body
        )

        return response;
    }

    // OLD
    old_addBooking = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            oldUrl.ADD_BOOKING,
            body
        )

        return response
    }
}
export default new BookingService();