import { useState, useEffect } from 'react';
import { Outlet, useLocation } from "react-router-dom";

import { GoogleOAuthProvider } from '@react-oauth/google';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import RecaptchaProvider from 'provider/recaptcha.provider';
import GoogleOneTapProvider from 'provider/google-one-tap.provider'

import PageLoader from 'components/loader/PageLoader';
import ModalContainer from 'components/modalContainer/ModalContainer';
import NavBar from 'components/navbar/NavBar';
import SideNavBar from 'components/sideNavBar/SideNavBar';
import AdminLoginAsNavBar from 'components/adminLoginAs/AdminLoginAsNavBar';

import { PageStyle, PageContainerStyle, BarContainer } from './Router.style';

import { edulyteAuthWebSocket } from 'websocket/edulyte.websocket';

import { useUser } from 'pages/auth/message/contexts/userContext';

import { getMasterTimezoneDetailByTimeZone, getMasterCurrencyRateList } from "redux/master/master.request";
import { getToken } from 'redux/auth/auth.request';
import { setFBUserLogin } from 'redux/firebase/firebase.request';

import { useTitle } from "hooks/useTitle";
import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { LOCAL_INITIAL_STATE } from "redux/local/local.initialState";
import { setUser } from 'redux/user/user.slice';
import { setLocals } from "redux/local/local.slice";
import { setClearLogout } from 'redux/auth/auth.slice';

import { wsUrl } from 'redux/auth/auth.const';
import { notificationWsUrl } from 'redux/notification/notification.const'


import { timeZone } from "utils/dateTime.utils";

import { EDULYTE_APP_DOMAIN_URL, GOOGLE_CLIENT_ID } from 'const/default.const';
import { CookieConsentBanner } from 'components/cookieManagement/CookieConsentBanner';
import { CookieSettingsModal } from 'components/cookieManagement/CookieSettings';
import { getStoredConsent, loadGTM } from 'utils/cookie-manager';

const LayoutRouter = () => {
    const { timeZoneCountryDetail } = useAppState((state) => state.master)
    const { publicOrgDetail } = useAppState((state) => state.org)
    const { session, logout } = useAppState(s => s.auth)
    const { user } = useAppState((state) => state.user)
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const [showBanner, setShowBanner] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const pageName = currentPageInfo.pageName;

    const userContext = useUser()
    const dispatcher = useAppDispatcher()
    const location = useLocation()
    const [title, setTitle] = useTitle()

    const [visibility, setVisibility] = useState({ isVisibleSideBar: true })

    let currentUserWebSocket = null
    let userPushNotificationWebSocket = null

    const sendNotification = () => {
        /* 
            If we want to show notification when user switch tab or minimize the browser.
            then invoke 
            document.onvisibilitychange = () => {}
        */

        const storedNotifications = localStorage.getItem('notifications') || '[]'
        const userNotifications = JSON.parse(storedNotifications)
        while (userNotifications.length > 0) {
            const userNotification = userNotifications.shift()
            const notification = new Notification("New Notification from Edulyte", {
                icon: "https://www.edulyte.com/wp-content/uploads/2021/08/Edulyte-Favicon-100x100.png",
                body: userNotification?.message
            })
            notification.onclick = () => {
                window.location.href = userNotification?.action_url
            }
        }
        localStorage.setItem('notifications', JSON.stringify(userNotifications))
    }

    const storeNotifications = (notification) => {
        if (!!notification) {
            const storedNotifications = localStorage.getItem('notifications')
            let notificationList = []
            if (!!storedNotifications) {
                notificationList = JSON.parse(storedNotifications)
            }
            notificationList.push(notification)
            localStorage.setItem('notifications', JSON.stringify(notificationList))
        }
    }

    const checkPageStatus = () => {
        if (!("Notification" in window)) {
            console.error("This browser does not support system notifications!")
        }
        if (Notification.permission === "granted") {
            sendNotification()
        }
        else if (Notification.permission !== "denied") {
            Notification.requestPermission((permission) => {
                if (permission === "granted") {
                    sendNotification()
                }
            })
        }
    }

    const getCurrentUserWs = async () => {
        const wsConfig = { url: wsUrl.CURRENT_USER }
        currentUserWebSocket = await edulyteAuthWebSocket(wsConfig)
        currentUserWebSocket.onmessage = async (event) => {
            const userMeObj = JSON.parse(event?.data)
            if (userMeObj?.error) return;
            dispatcher(setUser(userMeObj))
        }
    }

    const getUserPushNotificationWs = async () => {
        const wsConfig = { url: notificationWsUrl.NOTIFICATION_URL }
        userPushNotificationWebSocket = await edulyteAuthWebSocket(wsConfig)
        userPushNotificationWebSocket.onmessage = async (event) => {
            const pushNotification = JSON.parse(event?.data)
            if (pushNotification?.error) return;
            storeNotifications(pushNotification)
            checkPageStatus()
        }
    }

    let timeout;
    const loginWindowMessageEvent = (event) => {
        if (event.origin === EDULYTE_APP_DOMAIN_URL && event.data?.login_popup && event.data?.success) {
            if (!session?.isLoggedIn) {
                if (timeout) clearTimeout(timeout)
                timeout = setTimeout(() => {
                    dispatcher(getToken())
                }, 500)
            }
        }
    }
    useEffect(() => {
        const hasConsent = getStoredConsent();
        if (hasConsent?.analytics === 'accepted') {
            loadGTM()
        }
        setShowBanner(!hasConsent);
    }, []);
    
    useEffect(() => {
        if (!session?.isLoggedIn) {
            window.addEventListener("message", loginWindowMessageEvent);
        }
        return () => {
            window.removeEventListener("message", loginWindowMessageEvent)
        }
    }, [session?.isLoggedIn])

    function getCookie() {
        const cookies = document.cookie.split('; ');
        const adminTokenCookie = cookies.find(cookie => cookie.startsWith('isAdmin='));
        const adminToken = !!adminTokenCookie ? adminTokenCookie.split('=')[1] : null;
        return { adminToken }
    }

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: '0px' });
        setTitle({ ...title })
    }, [location.pathname]);

    useEffect(() => {
        dispatcher(getMasterCurrencyRateList())
        dispatcher(getMasterTimezoneDetailByTimeZone(timeZone))
    }, [dispatcher])

    useEffect(() => {
        if (!session?.isLoggedIn) {
            dispatcher(getToken())
        }
    }, [dispatcher, session?.isLoggedIn, publicOrgDetail?.data?.result])

    useEffect(() => {
        if (user?.user) {
            setFBUserLogin(user?.user?.userId, userContext)
        }
    }, [user?.user])

    useEffect(() => {
        if (session?.accessToken) {
            checkPageStatus()
            getCurrentUserWs()
            getUserPushNotificationWs()
        }

        return () => {
            if (!!currentUserWebSocket && (currentUserWebSocket.readyState === WebSocket.OPEN)) {
                currentUserWebSocket?.close()
            }
            if (!!userPushNotificationWebSocket && (userPushNotificationWebSocket.readyState === WebSocket.OPEN)) {
                userPushNotificationWebSocket?.close()
            }
        }
    }, [session?.accessToken])

    useEffect(() => {
        if (logout?.logout) {
            if (!!currentUserWebSocket &&
                (currentUserWebSocket.readyState === WebSocket.OPEN)) {
                currentUserWebSocket?.close()
            }
            if (!!userPushNotificationWebSocket &&
                (userPushNotificationWebSocket.readyState === WebSocket.OPEN)) {
                userPushNotificationWebSocket?.close()
            }
            dispatcher(setLocals({
                ...LOCAL_INITIAL_STATE.locals,
                currencyCode: timeZoneCountryDetail.timeZoneCountryDetail?.country?.currency?.code,
                timeZone: timeZoneCountryDetail.timeZoneCountryDetail?.timeZone
            }))
            dispatcher(setClearLogout())
        }
    }, [logout?.logout])

    if (session?.isLoading || logout?.isLoading) {
        return (
            <PageLoader />
        )
    }

    return (
        <PageStyle>
            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                <RecaptchaProvider>
                    <GoogleOneTapProvider>
                        <ToastContainer
                            position={"bottom-right"}
                            hideProgressBar={false}
                            autoClose={2000}
                            closeOnClick={true}
                            pauseOnFocusLoss={true}
                            pauseOnHover={true}
                            draggable={true}
                        />
                        <ModalContainer />
                        {!!user?.user?.org_banner && <AdminLoginAsNavBar />}
                        {/* <CookieConsentManagement showBanner={showBanner} showSettings={showSettings} /> */}
                    
                        {showBanner && (
                            <CookieConsentBanner
                            onClose={() => setShowBanner(false)}
                            onOpenSettings={() => {
                                setShowBanner(false);
                                setShowSettings(true);
                            }}
                            />
                        )}

                        <CookieSettingsModal
                            isOpen={showSettings}
                            onClose={() => setShowSettings(false)}
                        />
                        
                        <BarContainer pageName={pageName}>
                            <NavBar visibility={visibility} setVisibility={setVisibility} />
                        </BarContainer>
                        <PageContainerStyle pageName={pageName}>
                            <SideNavBar visible={visibility.isVisibleSideBar} />
                            <Outlet />
                        </PageContainerStyle>
                    </GoogleOneTapProvider>
                </RecaptchaProvider>
            </GoogleOAuthProvider>
        </PageStyle>
    )
}

export default LayoutRouter;