import { BsArrowRight } from 'react-icons/bs';
import { AiOutlineTag } from 'react-icons/ai';

import { cohortParticipantStatusEnum } from 'redux/course/course.const';

import useCustomNavigation from 'hooks/useCustomNavigation';

import { cn } from 'utils/cn.utils';
import { dayjs, timeZone } from 'utils/dateTime.utils';
import { Link } from 'react-router-dom';

const CohortCard = ({ cohort }) => {

    const customNavigation = useCustomNavigation()

    const onHandleViewClass = () => {
        customNavigation.navigation({ path: `/class/${cohort?.cohort?.course?.slug}`, isWeb: true })
    }

    return (
        <div className={'min-w-[16rem] max-w-[24rem] h-full flex flex-col bg-white rounded-lg shadow-all'}>
            <div className={'w-full h-full overflow-hidden relative'}>
                <div className={'rounded-t-lg overflow-hidden h-full w-full aspect-video transition-transform ease-in-out delay-150 duration-200 hover:scale-105'}>
                    <a
                        // href={getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, pathname: `/class/${cohort?.cohort?.course?.slug}` })}
                        href={customNavigation.getCustomBaseUrl({ path: `/cohort/${cohort?.cohort?.id}/learn` })}
                        target={"_self"}
                        className={""}
                    >
                        <img
                            src={cohort?.cohort?.course?.thumbnail_pic_url}
                            alt={"class-thumbnail"}
                            className='w-full h-full object-cover'
                        />
                    </a>
                </div>
            </div>
            <div className={'w-full h-full flex flex-col justify-between gap-3'}>
                <div className='px-3 py-2 flex flex-col justify-start gap-3'>
                    <div>
                        {cohort?.cohort?.course?.category?.category &&
                            <div className={cn(
                                "w-fit px-3 py-0.5 flex items-center justify-center gap-1.5 rounded-full bg-text-300",
                                'text-text-900 font-normal font-bodyPri text-sm tracking-wide whitespace-nowrap truncate'
                            )}>
                                <AiOutlineTag className={"text-base"} />
                                <span>
                                    {cohort?.cohort?.course?.category?.category}
                                </span>
                            </div>
                        }
                    </div>
                    <a
                        // href={getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, pathname: `/class/${cohort?.cohort?.course?.slug}` })}
                        href={customNavigation.getCustomBaseUrl({ path: `/class/${cohort?.cohort?.course?.slug}`, isWeb: true })}
                        target={"_self"}
                        className={cn(
                            'text-text-900 font-bodyPri font-medium text-lg tracking-wide truncate',
                            "hover:text-secondary-main hover:underline decoration-1"
                        )}
                    >
                        {cohort?.cohort?.course?.title}
                    </a>
                </div>
                <div className={"w-full px-3"}>
                    <div className={"w-full flex flex-col gap-2 p-2 border border-divider-medium rounded-lg truncate"}>
                        <Link to={`/cohort/${cohort?.cohort?.id}/learn`}
                            className={"font-bodyPri font-medium text-text-800 text-base truncate hover:text-secondary-main hover:underline decoration-1"}>
                            {cohort?.cohort?.title}
                        </Link>
                        <span className={`inline-flex items-center gap-1.5 font-bodyPri font-normal text-sm text-text-800`}>
                            {`${cohortParticipantStatusEnum[cohort?.participant?.status]?.label}`}
                            <span className={"text-text-700"}>
                                {`(${dayjs(cohort?.participant?.joined_at).tz(timeZone).format("dddd, MMM DD, YYYY")})`}
                            </span>
                        </span>
                    </div>
                </div>
                <div className={'w-full'}>
                    <hr className={"w-full border border-divider-darkLight"} />
                    <button
                        onClick={onHandleViewClass}
                        className={cn(
                            'w-full px-3 h-10 flex justify-center items-center gap-2',
                            "font-normal font-bodyPri whitespace-nowrap text-center"
                        )}>
                        <span>
                            {"View Class"}
                        </span>
                        <BsArrowRight className={"text-lg"} />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CohortCard;

export const CohortCardLoader = ({ isLoading = false }) => {

    return isLoading ? (
        <div className="w-full rounded-md overflow-hidden shadow-all flex flex-col cursor-pointer bg-white">
            <div className="w-full min-w-[16rem] max-w-[24rem] aspect-video bg-slate-100 animate-pulse object-cover"></div>
            <div className="flex flex-col gap-3 p-3">
                <div className="w-full h-8 rounded animate-pulse bg-slate-200"></div>
                <div className="w-40 h-6 rounded animate-pulse bg-slate-200"></div>
                <div className="w-full h-6 rounded-full animate-pulse bg-slate-200"></div>
            </div>
        </div>
    ) : null;
}