import { useFormContext } from 'react-hook-form';

import { locationInputLengths } from '../../data';

import { cn } from 'utils/cn.utils';

const CustomLocation = ({ values }) => {

    const { register } = useFormContext()

    return (
        <div className={"space-y-1"}>
            <textarea
                className={cn(
                    "w-full px-5 py-3 bg-back-ground-light rounded-lg resize-none",
                    "outline-none border-2 border-primary-main focus:shadow-all-md focus:shadow-primary-light focus:border-primary-main",
                    "font-bodyPri font-normal text-base text-text-800 tracking-wide"
                )}
                minLength={locationInputLengths.custom.minLength}
                maxLength={locationInputLengths.custom.maxLength}
                rows={4}
                autoFocus={true}
                placeholder={"Custom location *"}
                {...register("session_locations[0].custom.custom_text")}
            />
            <div className={"flex justify-end"}>
                <span className={"font-bodyPri font-normal text-text-800 text-sm"}>
                    {`${values?.session_locations[0]?.custom?.custom_text ? values?.session_locations[0]?.custom?.custom_text?.length : 0}/${locationInputLengths.custom.maxLength}`}
                </span>
            </div>
        </div>
    )
}

export default CustomLocation;