import { memo, useRef, useEffect } from 'react';

import { AnimatePresence, motion } from 'framer-motion';

import { locationsConst } from '../data';

import { locationTypeEnum } from 'redux/appointment/appointment.const';

import { cn } from 'utils/cn.utils';

const LocationOptionsMenu = ({
    isOpenDropdown = false,
    onHandleAddLocation = () => { }
}) => {
    const menuRef = useRef(null);

    useEffect(() => {
        if (isOpenDropdown && menuRef.current) {
            menuRef.current.scrollIntoView({ block: 'nearest', inline: 'nearest', behavior: 'smooth' });
            menuRef.current.scrollTop += 50;
        }
    }, [isOpenDropdown]);

    return (
        <AnimatePresence initial={isOpenDropdown}>
            {isOpenDropdown && (
                <ul
                    ref={menuRef}
                    className={cn(
                        "w-full md:w-64 absolute z-20 top-[10rem] md:top-[7.5rem] py-1 right-0 rounded-md space-y-1",
                        "drop-shadow-md bg-white border border-gray-300",
                        "transition-transform ease-in-out duration-200 delay-150"
                    )}>
                    {Object.values(locationsConst)?.filter((item) => (!item.default && !item.isWebConferencingEnabled))?.map((actionBtn, index) => (
                        <motion.li
                            key={index}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className={"w-full flex items-center justify-start gap-1.5 p-2 hover:bg-back-ground-lightBlue cursor-pointer"}
                            onClick={() => onHandleAddLocation(actionBtn)}
                        >
                            <actionBtn.icon className={"text-xl"} />
                            <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                                {actionBtn?.label}
                            </span>
                        </motion.li>
                    ))}
                    <li className={"px-2 py-3"}>
                        <span className={"font-bodyPri font-medium text-text-900 text-base tracking-wide"}>
                            {"WEB CONFERENCING"}
                        </span>
                    </li>
                    {Object.values(locationsConst)?.filter((item) => item.isWebConferencingEnabled)?.filter((actionItem) => (actionItem?.value !== locationTypeEnum.edulyte_meet.key))?.map((actionBtn, index) => (
                        <motion.li
                            key={index}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className={"w-full flex items-center justify-start gap-1.5 p-2 hover:bg-back-ground-lightBlue cursor-pointer"}
                            onClick={() => onHandleAddLocation(actionBtn)}
                        >
                            <actionBtn.icon className={"text-xl"} />
                            <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                                {actionBtn?.label}
                            </span>
                        </motion.li>
                    ))}
                </ul>
            )}
        </AnimatePresence>
    )
}

export default memo(LocationOptionsMenu);