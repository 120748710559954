import { useState, useEffect, useMemo } from "react";
import { NavLink as RouterLink } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import tw, { styled } from 'twin.macro';

import { RiMenuFoldFill } from "react-icons/ri";
import { BiChevronDown } from "react-icons/bi";

import ToolTipView from "components/tooltipView/index";

import { cn } from "utils/cn.utils";

const RouteLinkItems = styled.div`
    div > a.active > div > div {
        ${tw`!bg-primary-light text-primary-dark hover:text-primary-dark`}
    }
`

const SectionItem = ({ sectionNo, title, sectionSessions, getSession, isExpanded, toggleExpand }) => {

    let sessionNo = useMemo(() => {
        return sectionSessions
            ?.slice(0, sectionNo - 1)
            ?.reduce((initial, cohortSection) => {
                return [...initial, ...(cohortSection?.sessions || [])]
            }, [])?.length || 1
    }, [sectionSessions, sectionNo])

    return (
        <>
            <div
                className="group flex justify-between items-center p-4 gap-5 cursor-pointer hover:bg-primary-light"
                onClick={toggleExpand}
            >
                <h2 className="font-bodyPri font-medium text-base text-text-900 line-clamp-2">{`Section ${sectionNo?.toString().padStart(2, "0")}: ${title}`}</h2>
                <span
                    className={`ease-in-out duration-300 ${isExpanded ? "rotate-180" : ""}`}
                >
                    <BiChevronDown size={20} />
                </span>
            </div>
            <AnimatePresence>
                {isExpanded && (
                    <RouteLinkItems className="flex flex-col divide-y divide-divider-darkLight">
                        {sectionSessions?.map((sectionSession, index) => {
                            return (
                                <div key={index} className="relative group parent">
                                    <RouterLink to={`session/${sectionSession?.id}`}>
                                        <motion.div
                                            initial={{ height: 0, opacity: 0 }}
                                            animate={{ height: "auto", opacity: 1 }}
                                            transition={{ duration: 0.1 }}
                                            exit={{ height: 0, opacity: 0 }}
                                        >
                                            <div className="px-5 py-3 group-hover:bg-primary-light cursor-pointer flex flex-col gap-1 text-text-800 group-hover:text-text-900">
                                                <h3 className="h3 font-bodyPri font-normal text-base line-clamp-2">
                                                    {`Session ${(sessionNo + index + 1)?.toString().padStart(2, "0")}: ${getSession(sectionSession)?.title}`}
                                                </h3>
                                            </div>
                                        </motion.div>
                                    </RouterLink>
                                </div>
                            )
                        })}
                    </RouteLinkItems>
                )}
            </AnimatePresence>
        </>
    )
};

const SectionView = ({ activeSection, cohortSections, getSession, onSideBarClose }) => {

    const [expandedSections, setExpandedSections] = useState(new Array(cohortSections?.length).fill(false));

    useEffect(() => {
        setExpandedSections(cohortSections?.map(cohortSection => cohortSection?.id === activeSection?.current?.id))
    }, [activeSection, cohortSections])

    const toggleExpand = (index) => {
        const updatedSections = [...expandedSections];
        updatedSections[index] = !updatedSections[index];
        setExpandedSections(updatedSections);
    };

    return (
        <div className={cn(
            "space-y-1 overflow-auto h-screen",
            "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
            "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-light",
        )}>
            <div className="flex justify-between items-center px-5 py-3 bg-back-ground-darkLight text-text-800 sticky top-0 z-10 drop-shadow-sm">
                <p className="font-medium text-lg">{"Cohort Content"}</p>
                <ToolTipView content="Expanded view" placement='right'>
                    <span><RiMenuFoldFill size={20} onClick={onSideBarClose} className="cursor-pointer" /></span>
                </ToolTipView>
            </div>
            {(!cohortSections || (cohortSections?.length === 0)) &&
                <span>{"No Section(s) added!"}</span>
            }
            {!!cohortSections?.length && cohortSections?.map((section, index) => (
                <SectionItem
                    key={index}
                    sectionNo={index + 1}
                    title={section?.title}
                    sectionSessions={section?.sessions || []}
                    getSession={getSession}
                    isExpanded={expandedSections[index]}
                    toggleExpand={() => toggleExpand(index)}
                />
            ))}
        </div>
    );
};

export default SectionView;