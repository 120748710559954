import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import AddBorderButton from 'pages/auth/editClass/_components/buttons/AddBorderButton';
import SectionDescription from 'pages/auth/editClass/_components/sectionDescription';

import CohortSession from './session';

import courseServices from 'redux/course/course.services';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';
import { resetAddSession, setAddSession } from 'redux/session/session.slice';

const sessionsInitialState = {
    modifySession: {
        isLoading: false,
        payload: {
            status: ""
        },
        error: null
    },
    destroySession: {
        isLoading: false,
        error: null
    }
}

const SectionSessions = ({ cohortSection, setCohortSectionList }) => {
    const { modal } = useAppState((state) => state.local)
    const { addSession } = useAppState((state) => state.session)

    const dispatcher = useAppDispatcher()

    const [sessionsPayload, setSessionsPayload] = useState(sessionsInitialState)

    useEffect(() => {
        if (!!addSession?.result && (addSession?.payload?.cohort_section_id === cohortSection?.id)) {
            setCohortSectionList((prevState) => ({
                ...prevState,
                result: prevState?.result?.map((sectionItem) => (
                    (sectionItem?.id === cohortSection?.id)
                        ? {
                            ...sectionItem,
                            sessions: [addSession?.result, ...sectionItem?.sessions]
                        }
                        : sectionItem
                ))
            }))
            dispatcher(resetAddSession())
        }
    }, [addSession?.result])

    const onHandleSaveDescription = async (editedDescription) => {
        // if (editedDescription === cohortSection?.description) return;

        setSessionsPayload(() => ({ ...sessionsInitialState, modifySession: { ...sessionsInitialState?.modifySession, payload: { ...sessionsInitialState?.modifySession?.payload, isLoading: true, status: "Saving..." } } }))

        try {
            const requestData = {
                params: { cohortId: cohortSection?.cohort_id, sectionId: cohortSection?.id },
                body: { description: editedDescription }
            }
            const response = await courseServices.updateCohortSection(requestData)
            if (response.status === 200) {
                setCohortSectionList((prevState) => ({
                    ...prevState, result: prevState?.result?.map((sectionItem) => (
                        (sectionItem?.id === response?.data?.data?.result?.id)
                            ? response?.data?.data?.result
                            : sectionItem
                    ))
                }))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
            toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong")
        } finally {
            setSessionsPayload(() => ({ ...sessionsInitialState, modifySession: { ...sessionsInitialState?.modifySession, payload: { ...sessionsInitialState?.modifySession?.payload, isLoading: false, status: "" } } }))
        }
    }

    const onHandleAddSession = () => {
        dispatcher(setAddSession({
            payload: {
                cohort_section_id: cohortSection?.id
            }
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.addCohortSessionModal.key]: {
                ...modal[modalConst.addCohortSessionModal.key],
                isVisible: true,
                title: "Add Session"
            }
        }))
    }

    return (
        <div className={"flex flex-col gap-5 p-4 rounded-lg transition-transform ease-in-out duration-300"}>
            <SectionDescription
                isLoading={sessionsPayload?.modifySession?.isLoading}
                isShowDescriptionLabel={false}
                description={cohortSection?.description}
                descriptionPlaceholder={"Add Description (optional)"}
                rows={2}
                autoFocus={true}
                // setTitleInputStatus={() => setSessionsPayload((prevState) => ({ ...prevState, modifySession: { ...prevState?.modifySession, payload: { ...prevState?.modifySession?.payload, status: prevState?.modifySession?.payload?.status } } }))}
                descriptionInputStatus={sessionsPayload?.modifySession?.payload?.description}
                setDescriptionInputStatus={() => setSessionsPayload(sessionsPayload?.modifySession?.payload?.status)}
                onHandleSaveDescription={onHandleSaveDescription}
            />
            {(cohortSection?.sessions?.length > 0) &&
                <div className={'w-full flex flex-col gap-3'}>
                    {cohortSection?.sessions?.map((cohortSession) => (
                        <CohortSession key={cohortSession?.id} cohortSession={cohortSession} />
                    ))}
                </div>
            }
            <AddBorderButton btnTitle={"Add Session(s)"} onHandleAdd={onHandleAddSession} />
        </div>
    )
}

export default SectionSessions;