import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from "@mui/material/TextField";

import { dayjs, timeZone } from 'utils/dateTime.utils';

const DateTimePicker = ({
    defaultValue = "",
    value = "",
    onChange,
    minDate = dayjs().tz(timeZone)
}) => {

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDateTimePicker
                defaultValue={defaultValue}
                value={value ? value : null}
                onChange={(datetime) => onChange(datetime)}
                className={"!py-0 flex w-full focus:outline-none rounded-lg border border-back-ground-lightDark focus:border focus:border-primary-main"}
                format={"DD-MM-YYYY hh:mm A"}
                color={"primary"}
                disablePast={true}
                helperText={"SelectDate"}
                margin={"dense"}
                minDate={minDate}
                slots={{
                    textField: TextField,
                }}
                slotProps={{
                    textField: {
                        size:"small",
                        sx: {
                            "& .MuiInputBase-root": {
                                padding: "4px",
                                maxHeight: "36px",
                            },
                        },
                    },
                }}
            />
        </LocalizationProvider>
    )
}

export default DateTimePicker;