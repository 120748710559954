import { z } from "zod";

import { sessionLocationPhoneCallTypeEnum, sessionLocationTypeEnum, sessionTypeEnum } from "redux/session/session.const";

import { dayjs, timeZone } from "utils/dateTime.utils";

export const addSessionFormSchema = z.object({
    owner_user_id: z.number(),
    slot: z.object({
        start_datetime: z.string().refine((value) => dayjs(value, "YYYY-MM-DD HH:mm:ss", true).isValid(),
            { message: "Start date time is required" }
        ),
        end_datetime: z.string().refine((value) => dayjs(value, "YYYY-MM-DD HH:mm:ss", true).isValid(),
            { message: "End date time is required" }
        )
    }),
    timeZone: z.literal(timeZone),
    name: z.string()
        .min(10, { message: "Text length not be less then 10 character" })
        .max(500, { message: "Text length mot be more then 500 character" }),
    type: z.literal(sessionTypeEnum.COHORT.value),
    cohort_section: z.object({
        cohort_section_id: z.number()
    }),
    session_locations: z.array(
        z.object({
            location_type: z.string({ message: "Location is Required" }),
            info: z.string().optional(),
            custom: z.object({
                custom_text: z.string().trim()
                    .min(10, { message: "Text length not be less then 10 character" })
                    .max(500, { message: "Text length mot be more then 500 character" })
            }).optional(),
            in_person_meeting: z.object({
                street_line_1: z.string().trim()
                    .min(10, { message: "Text length not be less then 10 character" })
                    .max(500, { message: "Text length mot be more then 500 character" }),
                street_line_2: z.string().trim()
                    .max(500, { message: "Text length mot be more then 500 character" }),
                country: z.string(),
                state: z.string()
                    .min(2, { message: "Text length not be less then 5 character" })
                    .max(32, { message: "Text length mot be more then 30 character" }),
                city: z.string()
                    .min(2, { message: "Text length not be less then 5 character" })
                    .max(32, { message: "Text length mot be more then 30 character" }),
                post_office: z.string()
                    .min(2, { message: "Text length not be less then 3 character" })
                    .max(32, { message: "Text length mot be more then 20 character" }),
                zip: z.string()
                    .min(4, { message: "Text length not be less then 3 character" })
                    .max(8, { message: "Text length mot be more then 20 character" }),
                label: z.string()
                    .min(2, { message: "Text length not be less then 3 character" })
                    .max(16, { message: "Text length mot be more then 30 character" }),
            }).optional(),
            phone_call: z.object({
                location_phone_call_type: z.literal(sessionLocationPhoneCallTypeEnum.call_me.key),
                phone_number: z.string({ message: "Phone number is Required" }).regex(/^[0-9][0-9]{0,2}-[0-9]{6,15}[0-9]$/, { message: "Invalid Phone number" })
            }).optional(),
            invitee: z.object({
                custom_text: z.string().trim()
                    .min(10, { message: "Text length not be less then 10 character" })
                    .max(500, { message: "Text length mot be more then 500 character" })
            }).optional(),
            google_meet: z.string().optional(),
            edulyte_meet: z.string().optional()
        }, { message: "Location is Required" })
    )
}).superRefine((data, ctx) => {
    const { location_type } = data;

    if (location_type === sessionLocationTypeEnum.custom.key && !data.custom) {
        ctx.addIssue({
            path: [sessionLocationTypeEnum.custom.key],
            message: "Custom details are required when location type is Custom",
        });
    } else if (location_type === sessionLocationTypeEnum.in_person_meeting.key && !data.in_person_meeting) {
        ctx.addIssue({
            path: [sessionLocationTypeEnum.in_person_meeting.key],
            message: "In-person meeting details are required when location type is In-person Meeting",
        });
    }  else if (location_type === sessionLocationTypeEnum.invitee.key && !data.invitee) {
        ctx.addIssue({
            path: [sessionLocationTypeEnum.invitee.key],
            message: "Invitee details are required when location type is Invitee",
        });
    } else if (location_type === sessionLocationTypeEnum.phone_call.key && !data.phone_call) {
        ctx.addIssue({
            path: [sessionLocationTypeEnum.phone_call.key],
            message: "Phone call details are required when location type is Phone Call",
        });
    }
});

export const locationTypeFieldMap = {
    [sessionLocationTypeEnum.custom.key]: [sessionLocationTypeEnum.in_person_meeting.key, sessionLocationTypeEnum.in_person_meeting.key, sessionLocationTypeEnum.in_person_meeting.key, sessionLocationTypeEnum.in_person_meeting.key, sessionLocationTypeEnum.in_person_meeting.key],
    [sessionLocationTypeEnum.in_person_meeting.key]: [sessionLocationTypeEnum.custom.key, sessionLocationTypeEnum.in_person_meeting.key, sessionLocationTypeEnum.in_person_meeting.key, sessionLocationTypeEnum.in_person_meeting.key, sessionLocationTypeEnum.in_person_meeting.key],
    [sessionLocationTypeEnum.invitee.key]: [sessionLocationTypeEnum.custom.key, sessionLocationTypeEnum.in_person_meeting.key, sessionLocationTypeEnum.in_person_meeting.key, sessionLocationTypeEnum.in_person_meeting.key, sessionLocationTypeEnum.in_person_meeting.key],
    [sessionLocationTypeEnum.phone_call.key]: [sessionLocationTypeEnum.custom.key, sessionLocationTypeEnum.in_person_meeting.key, sessionLocationTypeEnum.in_person_meeting.key, sessionLocationTypeEnum.in_person_meeting.key, sessionLocationTypeEnum.in_person_meeting.key],
    [sessionLocationTypeEnum.google_meet.key]: [sessionLocationTypeEnum.custom.key, sessionLocationTypeEnum.in_person_meeting.key, sessionLocationTypeEnum.in_person_meeting.key, sessionLocationTypeEnum.in_person_meeting.key, sessionLocationTypeEnum.in_person_meeting.key],
    [sessionLocationTypeEnum.edulyte_meet.key]: [sessionLocationTypeEnum.custom.key, sessionLocationTypeEnum.in_person_meeting.key, sessionLocationTypeEnum.in_person_meeting.key, sessionLocationTypeEnum.in_person_meeting.key, sessionLocationTypeEnum.in_person_meeting.key],
};

export const locationInputLengths = {
    street_line_1: {
        minLength: 10,
        maxLength: 120
    },
    street_line_2: {
        minLength: 10,
        maxLength: 120
    },
    state: {
        minLength: 2,
        maxLength: 32
    },
    city: {
        minLength: 2,
        maxLength: 32
    },
    post_office: {
        minLength: 2,
        maxLength: 32
    },
    zip: {
        minLength: 4,
        maxLength: 8
    },
    label: {
        minLength: 2,
        maxLength: 16
    },
    custom: {
        minLength: 10,
        maxLength: 500
    }
}

export const inPersonMeetingInputConst = {
    STREET_LINE_1: {
        label: "Street address line 1 *",
        value: "street_line_1"
    },
    STREET_LINE_2: {
        label: "Street address line 2",
        value: "street_line_2"
    },
    STATE: {
        label: "State",
        value: "state"
    },
    CITY: {
        label: "City",
        value: "city"
    },
    POST_OFFICE: {
        label: "Po box",
        value: "post_office"
    },
    ZIP: {
        label: "Zip",
        value: "zip"
    },
    LABEL: {
        label: "Label",
        value: "label"
    }
}