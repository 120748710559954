import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';

import Pagination from 'components/pagination/Pagination';

import CohortCard, { CohortCardLoader } from './card';

import courseServices from 'redux/course/course.services';

import { useAppState } from 'hooks/useStore';
import useCustomNavigation from 'hooks/useCustomNavigation';

import { generateQueryParams } from 'utils/generators.utils';
import { pagesInfo } from 'utils/pagesInfo';
import { cn } from 'utils/cn.utils';

const RECORDS = 6
const cohortListInitialState = { isLoading: false, result: null, pagination: null, message: null }

const CohortList = () => {
    const { user } = useAppState((s) => s.user)

    const [cohortList, setCohortList] = useState(cohortListInitialState)

    const navigate = useNavigate()
    const location = useLocation()
    const customNavigation = useCustomNavigation()

    useEffect(() => {
        if (user?.user?.userId) {
            const query = generateQueryParams(location.search)
            getParticipantCohortCourseList(user?.user?.userId, query)
        }
    }, [location.search, user?.user])

    const getParticipantCohortCourseList = async (participantId, query) => {
        setCohortList((prevState) => ({ ...prevState, isLoading: true }))

        try {
            const requestData = {
                params: { participantId: participantId },
                query: query
            }
            const response = await courseServices.getParticipantCohortCourseList(requestData)
            if (response.status === 200) {
                setCohortList((prevState) => ({
                    ...prevState,
                    result: response?.data?.data?.result,
                    pagination: response?.data?.data?.pagination
                }))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
            toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
        } finally {
            setCohortList((prevState) => ({ ...prevState, isLoading: false }))
        }
    }

    const onHandleBuyCohort = () => {
        customNavigation.navigation({ path: "/classes", isWeb: true })
    }

    return (
        <div className={'flex justify-center items-center'}>
            <div className={'max-w-sm md:max-w-3xl lg:max-w-full w-full space-y-5'}>
                <div className={'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-8 lg:gap-5'}>
                    {!!cohortList?.result?.length && cohortList?.result?.map((cohort, index) => (
                        <div key={index} className={'col-span-1 flex justify-center'}>
                            <CohortCard cohort={cohort} />
                        </div>
                    ))}
                    {new Array(RECORDS).fill("").map((_, index) => (<CohortCardLoader key={index} isLoading={cohortList?.isLoading} />))}
                </div>
                {(cohortList?.pagination && (cohortList?.pagination?.totalPages > 1)) && (
                    <div className="w-full flex justify-center items-center">
                        <Pagination
                            page={cohortList?.pagination?.page}
                            totalPages={cohortList?.pagination?.totalPages}
                            onChangePage={(page) => navigate(pagesInfo.MY_COURSES.pagePath + "?pn=" + page)}
                        />
                    </div>
                )}
                {((cohortList?.pagination?.records === 0) || cohortList?.error) &&
                    <div className={'w-full h-full flex flex-col items-center justify-center gap-1 text-center text-base font-bodyPri tracking-wide'}>
                        <div className={"w-[16rem] h-[16rem] overflow-hidden"}>
                            <img
                                src={"https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/my-lms-courses/lms-course-not-found.png"}
                                alt={"no-cohort-found"}
                                className={"w-full h-full object-cover"}
                            />
                        </div>
                        <div
                            className={cn(
                                "w-fit px-5 py-1 flex items-center justify-center rounded-lg",
                                "border border-primary-dark bg-primary-dark text-text-50 hover:opacity-90",
                                "transition-transform duration-300 delay-150 ease-in-out"
                            )}
                            onClick={onHandleBuyCohort}
                        >
                            <span className={"font-bodyPri font-normal text-base tracking-wide text-center cursor-pointer"}>
                                {"Buy a Cohort"}
                            </span>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default CohortList;