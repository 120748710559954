import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import PrivateRouter from 'routers/PrivateRouter';
import LayoutRouter from "routers/LayoutRouter";

import { pagesInfo } from 'utils/pagesInfo';

import ChatView from "pages/auth/message/components/ChatView/ChatView";

import { profileTabConst } from "pages/auth/profile/data";

import MyDriveFiles from "pages/auth/drive/components/myFiles/MyFiles";
import SessionRecording from "pages/auth/drive/components/recordedSessions/RecordedSessions";
import SharedWithMe from "pages/auth/drive/components/sharedWithMe/SharedWithMe";

import General from "pages/auth/settings/components/general/General";
import BillingPage from 'pages/auth/settings/components/BillingPage';
import { PaymentPage } from "pages/auth/settings/components/PaymentPage";
import PayoutPage from "pages/auth/settings/components/payoutMethods/PayoutPage";
import { CalenderPage } from "pages/auth/settings/components/CalenderPage";
import { NotificationSettings } from "pages/auth/settings/components/NotificationSettings";

import { articleSettingsNavigationOptions, lmsArticleTabConst } from "pages/auth/edulyteLms/editLmsPages/editLmsArticle/data";
import { lectureSettingsNavigationOptions, lmsLectureTabConst } from "pages/auth/edulyteLms/editLmsPages/editLmsLecture/data";
import { courseDetailNavigationOptions, courseParticipantsNavigationOptions, courseSettingsNavigationOptions, lmsCourseTabConst } from "pages/auth/edulyteLms/editLmsPages/editLmsCourse/editLMSCourse.data";
import { lmsQuizTabConst, quizParticipantsNavigationOptions, quizSettingsNavigationOptions } from "pages/auth/edulyteLms/editLmsPages/editLmsQuiz/editLMSQuiz.data";
import { lmsQuestionTabConst, questionSettingsNavigationOptions } from "pages/auth/edulyteLms/editLmsPages/editLmsQuestion/editLMSQuestion.data";

import LessonView from "pages/auth/studentLms/course/components/lesson";
import SessionView from "pages/auth/studentCohort/session";

import MyCourses from "pages/auth/studentLms/courses/components/myCourses/MyCourses";
import FavouriteCourses from "pages/auth/studentLms/courses/components/favouriteCourses/FavouriteCourses";
import MyQuizzes from "pages/auth/studentLms/quizzes/components/my-quizzes";

import EditClassRouter from "pages/auth/editClass/router";

import { crmClientsNavigationOptions, crmContactsNavigationOptions, crmLeadsNavigationOptions, crmNavigationOptions } from "pages/auth/crm/data";
import { preferencesTabConst } from "pages/auth/preferences/data";
import { downloadTabConst, downloadParticipantsNavigationOptions, downloadSettingsNavigationOptions } from "pages/auth/editDownload/data";

import { ENVIRONMENT } from "const/default.const";
import { environmentEnum } from "redux/local/local.const";

import StoreProvider from "provider/store.provider";
import OrgProvider from "provider/org.provider";

const InitRouter = () => {
  const location = useLocation()

  return (
    <Routes>
      <Route
        element={
          <StoreProvider>
            <OrgProvider>
              <LayoutRouter />
            </OrgProvider>
          </StoreProvider>
        }
      >

        {/* public routes */}
        <Route path={pagesInfo.APPOINTMENT.routePath} exact element={<pagesInfo.APPOINTMENT.component />} />

        {(ENVIRONMENT !== environmentEnum.prod.key) &&
          <Route path={pagesInfo.APPOINTMENTS.routePath} exact element={<pagesInfo.APPOINTMENTS.component />} />
        }

        <Route path={pagesInfo.BUY_GIFT_CARD.routePath} exact element={<pagesInfo.BUY_GIFT_CARD.component />} />

        <Route path={pagesInfo.COMPANY.routePath} exact element={<pagesInfo.COMPANY.component />} />

        <Route path={pagesInfo.COURSE.routePath} exact element={<pagesInfo.COURSE.component />} />

        <Route path={pagesInfo.FEATURE_GET_YOUR_ACCOUNT.routePath} exact element={<pagesInfo.FEATURE_GET_YOUR_ACCOUNT.component />} />

        <Route path={pagesInfo.FIND_COURSES.routePath} exact element={<pagesInfo.FIND_COURSES.component />} />
        <Route path={"/find-classes/*"} element={<Navigate to={"/classes"} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />} />

        <Route path={pagesInfo.FIND_TUTORS.routePath} exact element={<pagesInfo.FIND_TUTORS.component />} />
        <Route path={"/find-tutors/*"} element={<Navigate to={"/tutors"} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />} />

        <Route path={pagesInfo.HEALTH_CHECK.routePath} exact element={<pagesInfo.HEALTH_CHECK.component />} />
        <Route path={pagesInfo.HOME.routePath} exact element={<pagesInfo.HOME.component />} />

        <Route path={pagesInfo.LOG_IN.routePath} exact element={<pagesInfo.LOG_IN.component />} />

        <Route path={pagesInfo.PROMO_SIGN_UP.routePath} exact element={<pagesInfo.PROMO_SIGN_UP.component />} />

        <Route path={pagesInfo.REFERRAL_SIGN_UP.routePath} exact element={<pagesInfo.REFERRAL_SIGN_UP.component />} />

        <Route path={pagesInfo.RESET_PASSWORD.routePath} exact element={<pagesInfo.RESET_PASSWORD.component />} />

        <Route path={pagesInfo.SEGMENTS.routePath} exact element={<pagesInfo.SEGMENTS.component />} />

        <Route path={pagesInfo.SIGN_UP.routePath} exact element={<pagesInfo.SIGN_UP.component />} />

        <Route path={pagesInfo.TEACH_WITH_US.routePath} exact element={<pagesInfo.TEACH_WITH_US.component />} />

        <Route path={pagesInfo.THANK_YOU.routePath} exact element={<pagesInfo.THANK_YOU.component />} />

        <Route path={pagesInfo.TUTOR.routePath} exact element={<pagesInfo.TUTOR.component />} />

        <Route path={pagesInfo.TUTOR_COURSE.routePath} exact element={<pagesInfo.TUTOR_COURSE.component />} />

        <Route path={pagesInfo.VERIFY_SIGNUP.routePath} exact element={<pagesInfo.VERIFY_SIGNUP.component />} />

        {/* we want to protect these routes */}
        <Route element={<PrivateRouter allowedRoles={pagesInfo.BOOKING_CONFIRMATION.roles} />}>
          <Route path={pagesInfo.BOOKING_CONFIRMATION.routePath} exact element={<pagesInfo.BOOKING_CONFIRMATION.component />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.BOOKING_SUMMARY.roles} />}>
          <Route path={pagesInfo.BOOKING_SUMMARY.routePath} exact element={<pagesInfo.BOOKING_SUMMARY.component />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.CHECKOUT_BOOKING.roles} />}>
          <Route path={pagesInfo.CHECKOUT_BOOKING.routePath} exact element={<pagesInfo.CHECKOUT_BOOKING.component />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.CHECKOUT_TOKEN.roles} />}>
          <Route path={pagesInfo.CHECKOUT_TOKEN.routePath} exact element={<pagesInfo.CHECKOUT_TOKEN.component />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.CREATE_COURSE.roles} />}>
          <Route path={pagesInfo.CREATE_COURSE.routePath} exact element={<EditClassRouter />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.CREATE_COURSE_PREVIEW.roles} />}>
          <Route path={pagesInfo.CREATE_COURSE_PREVIEW.routePath} exact element={<pagesInfo.CREATE_COURSE_PREVIEW.component />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.DASHBOARD.roles} />}>
          <Route path={pagesInfo.DASHBOARD.routePath} exact element={<pagesInfo.DASHBOARD.component />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.DOWNLOADS.roles} />}>
          <Route path={pagesInfo.DOWNLOADS.routePath} exact element={<pagesInfo.DOWNLOADS.component />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.EDIT_APPOINTMENT.roles} />}>
          <Route path={pagesInfo.EDIT_APPOINTMENT.routePath} exact element={<pagesInfo.EDIT_APPOINTMENT.component />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.DOWNLOAD_INVITATION.roles} />}>
          <Route path={pagesInfo.DOWNLOAD_INVITATION.routePath} exact element={<pagesInfo.DOWNLOAD_INVITATION.component />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.EDIT_COMPANY.roles} />}>
          <Route path={pagesInfo.EDIT_COMPANY.routePath} exact element={<pagesInfo.EDIT_COMPANY.component />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.EDIT_COURSE.roles} />}>
          <Route path={pagesInfo.EDIT_COURSE.routePath} exact element={<pagesInfo.EDIT_COURSE.component />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.EDIT_CERTIFICATE.roles} />}>
          <Route path={pagesInfo.EDIT_CERTIFICATE.routePath} element={<pagesInfo.EDIT_CERTIFICATE.component />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.EDIT_DOWNLOAD.roles} />}>
          <Route path={pagesInfo.EDIT_DOWNLOAD.routePath} exact element={<pagesInfo.EDIT_DOWNLOAD.component />}>

            <Route path={downloadTabConst.BUILDER.routePath} exact element={<downloadTabConst.BUILDER.component />} />

            <Route path={downloadTabConst.SETTINGS.routePath} exact element={<downloadTabConst.SETTINGS.component />}>
              <Route path={downloadSettingsNavigationOptions.GENERAL.routePath} element={<downloadSettingsNavigationOptions.GENERAL.component />} />
              <Route path={downloadSettingsNavigationOptions.PRODUCT.routePath} element={<downloadSettingsNavigationOptions.PRODUCT.component />} />
              <Route path="" element={<Navigate to={downloadSettingsNavigationOptions.GENERAL.routePath} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />} />
            </Route>

            <Route path={downloadTabConst.PARTICIPANTS.routePath} exact element={<downloadTabConst.PARTICIPANTS.component />}>
              <Route path={downloadParticipantsNavigationOptions.JOINED.routePath} element={<downloadParticipantsNavigationOptions.JOINED.component />} />
              <Route path={downloadParticipantsNavigationOptions.INVITED.routePath} element={<downloadParticipantsNavigationOptions.INVITED.component />} />
              <Route path="" element={<Navigate to={downloadParticipantsNavigationOptions.JOINED.routePath} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />} />
            </Route>

            <Route path="" element={<Navigate to={downloadTabConst.BUILDER.routePath} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />} />
          </Route>
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.EDIT_EVENTLY.roles} />}>
          <Route path={pagesInfo.EDIT_EVENTLY.routePath} exact element={<pagesInfo.EDIT_EVENTLY.component />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.EDULYTE_LMS.roles} />}>
          <Route path={pagesInfo.EDULYTE_LMS.routePath} exact element={<pagesInfo.EDULYTE_LMS.component />}>
            <Route path="/lms/*" element={<Navigate to={"/products/courses"} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />} />
          </Route>
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.EDIT_LMS_COURSE.roles} />}>
          <Route path={pagesInfo.EDIT_LMS_COURSE.routePath} exact element={<pagesInfo.EDIT_LMS_COURSE.component />}>
            <Route path={lmsCourseTabConst.COURSE_DETAILS.routePath} exact element={<lmsCourseTabConst.COURSE_DETAILS.component />}>
              <Route path={courseDetailNavigationOptions.GENERAL.routePath} element={<courseDetailNavigationOptions.GENERAL.component />} />
              <Route path={courseDetailNavigationOptions.DESCRIPTION.routePath} element={<courseDetailNavigationOptions.DESCRIPTION.component />} />
              <Route path={courseDetailNavigationOptions.SKILLS_YOU_WILL_LEARN.routePath} element={<courseDetailNavigationOptions.SKILLS_YOU_WILL_LEARN.component />} />
              <Route path={courseDetailNavigationOptions.LANGUAGES.routePath} element={<courseDetailNavigationOptions.LANGUAGES.component />} />
              <Route path={courseDetailNavigationOptions.PROFICIENCY.routePath} element={<courseDetailNavigationOptions.PROFICIENCY.component />} />
              <Route path={courseDetailNavigationOptions.THUMBNAIL.routePath} element={<courseDetailNavigationOptions.THUMBNAIL.component />} />
              <Route path={courseDetailNavigationOptions.VIDEO.routePath} element={<courseDetailNavigationOptions.VIDEO.component />} />
              <Route path={courseDetailNavigationOptions.WHAT_YOU_WILL_LEARN.routePath} element={<courseDetailNavigationOptions.WHAT_YOU_WILL_LEARN.component />} />
              <Route path={courseDetailNavigationOptions.FAQS.routePath} element={<courseDetailNavigationOptions.FAQS.component />} />
              <Route path={courseDetailNavigationOptions.PRODUCT.routePath} element={<courseDetailNavigationOptions.PRODUCT.component />} />
              <Route path="" element={<Navigate to={courseDetailNavigationOptions.GENERAL.routePath} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />} />
            </Route>
            <Route path={lmsCourseTabConst.COURSE_BUILDER.routePath} exact element={<lmsCourseTabConst.COURSE_BUILDER.component />} />
            <Route path={lmsCourseTabConst.COURSE_SETTINGS.routePath} exact element={<lmsCourseTabConst.COURSE_SETTINGS.component />}>
              <Route path={courseSettingsNavigationOptions.GENERAL.routePath} element={<courseSettingsNavigationOptions.GENERAL.component />} />
              <Route path={courseSettingsNavigationOptions.SLUG.routePath} element={<courseSettingsNavigationOptions.SLUG.component />} />
              <Route path={courseSettingsNavigationOptions.USER.routePath} element={<courseSettingsNavigationOptions.USER.component />} />
              <Route path={courseSettingsNavigationOptions.CERTIFICATE.routePath} element={<courseSettingsNavigationOptions.CERTIFICATE.component />} />
              <Route path="" element={<Navigate to={courseSettingsNavigationOptions.GENERAL.routePath} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />} />
            </Route>
            <Route path={lmsCourseTabConst.COURSE_PARTICIPANTS.routePath} exact element={<lmsCourseTabConst.COURSE_PARTICIPANTS.component />}>
              <Route path={courseParticipantsNavigationOptions.JOINED.routePath} element={<courseParticipantsNavigationOptions.JOINED.component />} />
              <Route path={courseParticipantsNavigationOptions.INVITED.routePath} element={<courseParticipantsNavigationOptions.INVITED.component />} />
              <Route path="" element={<Navigate to={courseParticipantsNavigationOptions.JOINED.routePath} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />} />
            </Route>
            <Route path="" element={<Navigate to={lmsCourseTabConst.COURSE_DETAILS.routePath} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />} />
          </Route>
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.EDIT_LMS_LECTURE.roles} />}>
          <Route path={pagesInfo.EDIT_LMS_LECTURE.routePath} exact element={<pagesInfo.EDIT_LMS_LECTURE.component />}>
            <Route path={lmsLectureTabConst.BUILDER.routePath} element={<lmsLectureTabConst.BUILDER.component />} />
            <Route path={lmsLectureTabConst.SETTINGS.routePath} element={<lmsLectureTabConst.SETTINGS.component />}>
              <Route path={lectureSettingsNavigationOptions.GENERAL.routePath} element={<lectureSettingsNavigationOptions.GENERAL.component />} />
              <Route path={lectureSettingsNavigationOptions.USER.routePath} element={<lectureSettingsNavigationOptions.USER.component />} />
              <Route path="" element={<Navigate to={lectureSettingsNavigationOptions.GENERAL.routePath} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />} />
            </Route>
            <Route path="" element={<Navigate to={lmsLectureTabConst.BUILDER.routePath} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />} />
          </Route>
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.EDIT_LMS_ARTICLE.roles} />}>
          <Route path={pagesInfo.EDIT_LMS_ARTICLE.routePath} exact element={<pagesInfo.EDIT_LMS_ARTICLE.component />}>
            <Route path={lmsArticleTabConst.BUILDER.routePath} element={<lmsArticleTabConst.BUILDER.component />} />
            <Route path={lmsArticleTabConst.SETTINGS.routePath} element={<lmsArticleTabConst.SETTINGS.component />}>
              <Route path={articleSettingsNavigationOptions.GENERAL.routePath} element={<articleSettingsNavigationOptions.GENERAL.component />} />
              <Route path={articleSettingsNavigationOptions.USER.routePath} element={<articleSettingsNavigationOptions.USER.component />} />
              <Route path="" element={<Navigate to={articleSettingsNavigationOptions.GENERAL.routePath} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />} />
            </Route>
            <Route path="" element={<Navigate to={lmsArticleTabConst.BUILDER.routePath} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />} />
          </Route>
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.EDIT_LMS_QUIZ.roles} />}>
          <Route path={pagesInfo.EDIT_LMS_QUIZ.routePath} exact element={<pagesInfo.EDIT_LMS_QUIZ.component />}>
            <Route path={lmsQuizTabConst.BUILDER.routePath} exact element={<lmsQuizTabConst.BUILDER.component />} />
            <Route path={lmsQuizTabConst.SETTINGS.routePath} exact element={<lmsQuizTabConst.SETTINGS.component />}>
              <Route path={quizSettingsNavigationOptions.GENERAL.routePath} element={<quizSettingsNavigationOptions.GENERAL.component />} />
              <Route path={quizSettingsNavigationOptions.PRODUCT.routePath} element={<quizSettingsNavigationOptions.PRODUCT.component />} />
              <Route path={quizSettingsNavigationOptions.SLUG.routePath} element={<quizSettingsNavigationOptions.SLUG.component />} />
              <Route path={quizSettingsNavigationOptions.INSTRUCTOR.routePath} element={<quizSettingsNavigationOptions.INSTRUCTOR.component />} />
              <Route path={quizSettingsNavigationOptions.CERTIFICATE.routePath} element={<quizSettingsNavigationOptions.CERTIFICATE.component />} />
              <Route path="" element={<Navigate to={quizSettingsNavigationOptions.GENERAL.routePath} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />} />
            </Route>
            <Route path={lmsQuizTabConst.PARTICIPANTS.routePath} exact element={<lmsQuizTabConst.PARTICIPANTS.component />}>
              <Route path={quizParticipantsNavigationOptions.JOINED.routePath} element={<quizParticipantsNavigationOptions.JOINED.component />} />
              <Route path={quizParticipantsNavigationOptions.INVITED.routePath} element={<quizParticipantsNavigationOptions.INVITED.component />} />
              <Route path="" element={<Navigate to={quizParticipantsNavigationOptions.JOINED.routePath} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />} />
            </Route>
            <Route path="" element={<Navigate to={lmsQuizTabConst.BUILDER.routePath} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />} />
          </Route>
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.EDIT_LMS_QUESTION.roles} />}>
          <Route path={pagesInfo.EDIT_LMS_QUESTION.routePath} exact element={<pagesInfo.EDIT_LMS_QUESTION.component />}>
            <Route path={lmsQuestionTabConst.BUILDER.routePath} element={<lmsQuestionTabConst.BUILDER.component />} />
            <Route path={lmsQuestionTabConst.SETTINGS.routePath} element={<lmsQuestionTabConst.SETTINGS.component />}>
              <Route path={questionSettingsNavigationOptions.GENERAL.routePath} element={<questionSettingsNavigationOptions.GENERAL.component />} />
              <Route path={questionSettingsNavigationOptions.INSTRUCTOR.routePath} element={<questionSettingsNavigationOptions.INSTRUCTOR.component />} />
              <Route path="" element={<Navigate to={questionSettingsNavigationOptions.GENERAL.routePath} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />} />
            </Route>
            <Route path="" element={<Navigate to={lmsQuestionTabConst.BUILDER.routePath} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />} />
          </Route>
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.CREATE_AI_QUIZ.roles} />}>
          <Route path={pagesInfo.CREATE_AI_QUIZ.routePath} exact element={<pagesInfo.CREATE_AI_QUIZ.component allowedRoles={['Tutor']} />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.EVENTLY_INVITATION.roles} />}>
          <Route path={pagesInfo.EVENTLY_INVITATION.routePath} exact element={<pagesInfo.EVENTLY_INVITATION.component />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.LMS_COURSE_INVITATION.roles} />}>
          <Route path={pagesInfo.LMS_COURSE_INVITATION.routePath} exact element={<pagesInfo.LMS_COURSE_INVITATION.component />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.LMS_QUIZ_INVITATION.roles} />}>
          <Route path={pagesInfo.LMS_QUIZ_INVITATION.routePath} exact element={<pagesInfo.LMS_QUIZ_INVITATION.component />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.MEETING.roles} />}>
          <Route path={pagesInfo.MEETING.routePath} exact element={<pagesInfo.MEETING.component />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.MESSAGES.roles} />}>
          <Route path={pagesInfo.MESSAGES.routePath} exact element={<pagesInfo.MESSAGES.component />} >
            <Route path={":chatId"} element={<ChatView />} />
          </Route>
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.MY_TUTORS.roles} />}>
          <Route path={pagesInfo.MY_TUTORS.routePath} exact element={<pagesInfo.MY_TUTORS.component />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.MY_COURSES.roles} />}>
          <Route path={pagesInfo.MY_COURSES.routePath} exact element={<pagesInfo.MY_COURSES.component />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.MY_DOWNLOADS.roles} />}>
          <Route path={pagesInfo.MY_DOWNLOADS.routePath} exact element={<pagesInfo.MY_DOWNLOADS.component />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.ORDER_CONFIRMATION.roles} />}>
          <Route path={pagesInfo.ORDER_CONFIRMATION.routePath} exact element={<pagesInfo.ORDER_CONFIRMATION.component />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.PAYMENT_CONFIRMATION.roles} />}>
          <Route path={pagesInfo.PAYMENT_CONFIRMATION.routePath} exact element={<pagesInfo.PAYMENT_CONFIRMATION.component />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.PRE_ONBOARDING.roles} />}>
          <Route path={pagesInfo.PRE_ONBOARDING.routePath} exact element={<pagesInfo.PRE_ONBOARDING.component />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.PRODUCTS.roles} />}>
          <Route path={pagesInfo.PRODUCTS.routePath} exact element={<pagesInfo.PRODUCTS.component />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.SCHEDULE.roles} />}>
          <Route path={`${pagesInfo.SCHEDULE.pagePath}/month`} element={<pagesInfo.SCHEDULE.component />} />
          <Route path={`${pagesInfo.SCHEDULE.pagePath}/week`} element={<pagesInfo.SCHEDULE.component />} />
          <Route path={`${pagesInfo.SCHEDULE.pagePath}/day`} element={<pagesInfo.SCHEDULE.component />} />
          <Route path={`${pagesInfo.SCHEDULE.pagePath}/agenda`} element={<pagesInfo.SCHEDULE.component />} />
          <Route path={pagesInfo.SCHEDULE.pagePath} element={<Navigate to={`month${location.search}${location.hash}`} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.GOOGLE_CALENDAR_CONSENT.roles} />}>
          <Route path={pagesInfo.GOOGLE_CALENDAR_CONSENT.routePath} exact element={<pagesInfo.GOOGLE_CALENDAR_CONSENT.component />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.SETTINGS.roles} />}>
          <Route path={pagesInfo.SETTINGS.routePath} exact element={<pagesInfo.SETTINGS.component allowedRoles={['User', 'Tutor']} />}>
            <Route path={pagesInfo.PROFILE.routePath} exact element={<pagesInfo.PROFILE.component allowedRoles={['User', 'Tutor']} />}>
              <Route path={`${profileTabConst.PERSONAL_DETAILS.routePath}`} element={<profileTabConst.PERSONAL_DETAILS.component />} />
              <Route path={`${profileTabConst.ABOUT.routePath}`} element={<profileTabConst.ABOUT.component />} />
              <Route path={`${profileTabConst.INTRO_VIDEO.routePath}`} element={<profileTabConst.INTRO_VIDEO.component />} />
              <Route path={`${profileTabConst.SKILLS.routePath}`} element={<profileTabConst.SKILLS.component />} />
              <Route path={`${profileTabConst.LINK_IN_BIO.routePath}`} element={<profileTabConst.LINK_IN_BIO.component />} />
              <Route path={`${profileTabConst.APPOINTMENT.routePath}`} element={<profileTabConst.APPOINTMENT.component />} />
              <Route path={""} element={<Navigate to={profileTabConst.PERSONAL_DETAILS.routePath} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />} />
            </Route>
          </Route>

          <Route path={pagesInfo.INTEGRATIONS.routePath} exact element={<pagesInfo.INTEGRATIONS.component allowedRoles={['User', 'Tutor']} />}>
            <Route path="intergrations" element={<pagesInfo.INTEGRATIONS.component />} />
          </Route>

          <Route path={pagesInfo.PREFERENCES.routePath} exact element={<pagesInfo.PREFERENCES.component allowedRoles={['User', 'Tutor']} />}>
            <Route path={preferencesTabConst.AVAILABILITY.routePath} element={<preferencesTabConst.AVAILABILITY.component />} />
            <Route path={preferencesTabConst.CREATE_AVAILABILITY_DETAIL.routePath} exact element={<preferencesTabConst.CREATE_AVAILABILITY_DETAIL.component />} />
            <Route path={preferencesTabConst.AVAILABILITY_DETAIL.routePath} exact element={<preferencesTabConst.AVAILABILITY_DETAIL.component />} />
            <Route path={preferencesTabConst.OFF_DAY.routePath} element={<preferencesTabConst.OFF_DAY.component />} />
            <Route path={preferencesTabConst.OTHER.routePath} exact element={<preferencesTabConst.OTHER.component />} />
            <Route path="" element={<Navigate to={preferencesTabConst.AVAILABILITY.routePath} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />} />
          </Route>
          {/* <Route path={pagesInfo.PREFERENCES.routePath} exact element={<pagesInfo.PREFERENCES.component allowedRoles={['Tutor']} />}>
            <Route path={preferencesTabConst.PRICE.routePath} element={<preferencesTabConst.PRICE.component />} />
          </Route> */}

          <Route element={<PrivateRouter allowedRoles={pagesInfo.GENERAL_SETTINGS.roles} />}>
            <Route path={pagesInfo.GENERAL_SETTINGS.routePath} exact element={<pagesInfo.GENERAL_SETTINGS.component allowedRoles={['User', 'Tutor']} />}>
              <Route path="general" element={<General />} />
            </Route>
            <Route path={pagesInfo.GENERAL_SETTINGS.routePath} exact element={<pagesInfo.GENERAL_SETTINGS.component allowedRoles={['User', 'Tutor']} />}>
              <Route path="billing" element={<BillingPage />} />
            </Route>
            <Route path={pagesInfo.GENERAL_SETTINGS.routePath} exact element={<pagesInfo.GENERAL_SETTINGS.component allowedRoles={['User', 'Tutor']} />}>
              <Route path="payment" element={<PaymentPage />} />
            </Route>
            <Route path={pagesInfo.GENERAL_SETTINGS.routePath} exact element={<pagesInfo.GENERAL_SETTINGS.component allowedRoles={['User', 'Tutor']} />}>
              <Route path="payout" element={<PayoutPage />} />
            </Route>
            <Route path={pagesInfo.GENERAL_SETTINGS.routePath} exact element={<pagesInfo.GENERAL_SETTINGS.component allowedRoles={['User', 'Tutor']} />}>
              <Route path="calendar" element={<CalenderPage />} />
            </Route>
            <Route path={pagesInfo.GENERAL_SETTINGS.routePath} exact element={<pagesInfo.GENERAL_SETTINGS.component allowedRoles={['User', 'Tutor']} />}>
              <Route path="notifications" element={<NotificationSettings />} />
            </Route>
            <Route path={pagesInfo.GENERAL_SETTINGS.routePath} exact element={<pagesInfo.GENERAL_SETTINGS.component allowedRoles={['User', 'Tutor']} />}>
              <Route path="" element={<Navigate to={"general"} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />} />
            </Route>
          </Route>
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.STUDENT_COHORT.roles} />}>
          <Route path={pagesInfo.STUDENT_COHORT.routePath} exact element={<pagesInfo.STUDENT_COHORT.component />} >
            <Route path="session/:sessionId" element={<SessionView />} />
          </Route>
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.STUDENT_LMS_COURSE.roles} />}>
          <Route path={pagesInfo.STUDENT_LMS_COURSE.routePath} exact element={<pagesInfo.STUDENT_LMS_COURSE.component />} >
            <Route path="lesson/:lesson_slug" element={<LessonView />} />
          </Route>
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.STUDENT_LMS_COURSES.roles} />}>
          <Route path={pagesInfo.STUDENT_LMS_COURSES.routePath} exact element={<pagesInfo.STUDENT_LMS_COURSES.component allowedRoles={['User']} />}>
            <Route path="courses" element={<MyCourses />} />
          </Route>
          <Route path={pagesInfo.STUDENT_LMS_COURSES.routePath} exact element={<pagesInfo.STUDENT_LMS_COURSES.component allowedRoles={['User']} />}>
            <Route path="favourite" element={<FavouriteCourses />} />
          </Route>
          <Route path={pagesInfo.STUDENT_LMS_COURSES.routePath} exact element={<pagesInfo.STUDENT_LMS_COURSES.component allowedRoles={['User']} />}>
            <Route path="" element={<Navigate to={"courses"} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />} />
          </Route>
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.STUDENT_CERTIFICATES.roles} />}>
          <Route path={pagesInfo.STUDENT_CERTIFICATES.routePath} exact element={<pagesInfo.STUDENT_CERTIFICATES.component />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.STUDENT_LMS_QUIZ.roles} />}>
          <Route path={pagesInfo.STUDENT_LMS_QUIZ.routePath} exact element={<pagesInfo.STUDENT_LMS_QUIZ.component />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.STUDENT_LMS_QUIZZES.roles} />}>
          <Route path={pagesInfo.STUDENT_LMS_QUIZZES.routePath} exact element={<pagesInfo.STUDENT_LMS_QUIZZES.component allowedRoles={['User']} />}>
            <Route path="quizzes" element={<MyQuizzes />} />
          </Route>
          <Route path={pagesInfo.STUDENT_LMS_QUIZZES.routePath} exact element={<pagesInfo.STUDENT_LMS_QUIZZES.component allowedRoles={['User']} />}>
            <Route path="" element={<Navigate to={"quizzes"} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />} />
          </Route>
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.SUPPORT.roles} />}>
          <Route path={pagesInfo.SUPPORT.routePath} element={<pagesInfo.SUPPORT.component />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.VIDEO_GUIDE.roles} />}>
          <Route path={pagesInfo.VIDEO_GUIDE.routePath} element={<pagesInfo.VIDEO_GUIDE.component />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.TEMPLATE.roles} />}>
          <Route path={pagesInfo.TEMPLATE.routePath} exact element={<pagesInfo.TEMPLATE.component />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.TOKEN_PAYMENT_CONFIRMATION.roles} />}>
          <Route path={pagesInfo.TOKEN_PAYMENT_CONFIRMATION.routePath} exact element={<pagesInfo.TOKEN_PAYMENT_CONFIRMATION.component />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.TOKEN_WALLET_CONFIRMATION.roles} />}>
          <Route path={pagesInfo.TOKEN_WALLET_CONFIRMATION.routePath} exact element={<pagesInfo.TOKEN_WALLET_CONFIRMATION.component />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.TUTOR_CRM.roles} />}>
          <Route path={pagesInfo.TUTOR_CRM.routePath} exact element={<pagesInfo.TUTOR_CRM.component allowedRoles={['Tutor']} />}>
            <Route path={crmNavigationOptions.LEADS.routePath} exact element={<crmNavigationOptions.LEADS.component />}>
              <Route path={crmLeadsNavigationOptions.TILE_VIEW.routePath} element={<crmLeadsNavigationOptions.TILE_VIEW.component />} />
              <Route path={crmLeadsNavigationOptions.LIST_VIEW.routePath} element={<crmLeadsNavigationOptions.LIST_VIEW.component />} />
              <Route path="" element={<Navigate to={crmLeadsNavigationOptions.TILE_VIEW.routePath} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />} />
            </Route>
            <Route path={crmNavigationOptions.CONTACTS.routePath} exact element={<crmNavigationOptions.CONTACTS.component />}>
              <Route path={crmContactsNavigationOptions.TILE_VIEW.routePath} element={<crmContactsNavigationOptions.TILE_VIEW.component />} />
              <Route path={crmContactsNavigationOptions.LIST_VIEW.routePath} element={<crmContactsNavigationOptions.LIST_VIEW.component />} />
              <Route path="" element={<Navigate to={crmContactsNavigationOptions.TILE_VIEW.routePath} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />} />
            </Route>
            <Route path={crmNavigationOptions.CLIENTS.routePath} exact element={<crmNavigationOptions.CLIENTS.component />}>
              <Route path={crmClientsNavigationOptions.TILE_VIEW.routePath} element={<crmClientsNavigationOptions.TILE_VIEW.component />} />
              <Route path={crmClientsNavigationOptions.LIST_VIEW.routePath} element={<crmClientsNavigationOptions.LIST_VIEW.component />} />
              <Route path="" element={<Navigate to={crmClientsNavigationOptions.TILE_VIEW.routePath} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />} />
            </Route>
            <Route path="" element={<Navigate to={crmNavigationOptions.LEADS.routePath} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />} />
          </Route>
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.TUTOR_ONBOARDING_S1.roles} />}>
          <Route path={pagesInfo.TUTOR_ONBOARDING_S1.routePath} exact element={<pagesInfo.TUTOR_ONBOARDING_S1.component />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.TUTOR_PRODUCTS.roles} />}>
          <Route path={pagesInfo.TUTOR_PRODUCTS.routePath} exact element={<pagesInfo.TUTOR_PRODUCTS.component allowedRoles={['Tutor', 'User']} />}>
            <Route path={pagesInfo.LMS_COURSE_PAGE.routePath} exact element={<pagesInfo.LMS_COURSE_PAGE.component />} />
            <Route path={pagesInfo.OFFERINGS.routePath} element={<pagesInfo.OFFERINGS.component />} />
            <Route path={pagesInfo.EVENTS_LIST.routePath} element={<pagesInfo.EVENTS_LIST.component />} />
            <Route path={pagesInfo.APPOINTMENT_LIST.routePath} exact element={<pagesInfo.APPOINTMENT_LIST.component />} />
            <Route path={pagesInfo.LMS_QUIZ_PAGE.routePath} exact element={<pagesInfo.LMS_QUIZ_PAGE.component />} />
          </Route>
          <Route path="" element={<Navigate to={"courses"} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.TUTOR_ONBOARDING_S2.roles} />}>
          <Route path={pagesInfo.TUTOR_ONBOARDING_S2.routePath} exact element={<pagesInfo.TUTOR_ONBOARDING_S2.component />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.USER_FINANCE.roles} />}>
          <Route path={pagesInfo.USER_FINANCE.routePath} exact element={<pagesInfo.USER_FINANCE.component allowedRoles={['User', 'Tutor']} />}>
            <Route path="subscriptions" element={<pagesInfo.SUBSCRIPTIONS.component />} />
            <Route path="wallet" element={<pagesInfo.WALLET.component />} />
            <Route path="bookings" element={<pagesInfo.BOOKINGS.component />} />
            <Route path={"orders"} exact element={<pagesInfo.ORDERS.component />} />
            <Route path="" element={<Navigate to={"wallet"} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />} />
          </Route>
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.USER_MARKETING.roles} />}>
          <Route path={pagesInfo.USER_MARKETING.routePath} exact element={<pagesInfo.USER_MARKETING.component allowedRoles={['User', 'Tutor']} />}>
            <Route path="my-links" element={<pagesInfo.MY_LINKS.component />} />
          </Route>
          <Route path={pagesInfo.USER_MARKETING.routePath} exact element={<pagesInfo.USER_MARKETING.component allowedRoles={['User', 'Tutor']} />}>
            <Route path="referral" element={<pagesInfo.REFERRAL.component />} />
          </Route>
          <Route path={pagesInfo.USER_MARKETING.routePath} exact element={<pagesInfo.USER_MARKETING.component allowedRoles={['User', 'Tutor']} />}>
            <Route path="" element={<Navigate to={"referral"} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />} />
          </Route>
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.USER_RESOURCES.roles} />}>
          <Route path={pagesInfo.USER_RESOURCES.routePath} exact element={<pagesInfo.USER_RESOURCES.component allowedRoles={['User', 'Tutor']} />}>
            <Route path={pagesInfo.LMS_QUESTIONS_PAGE.routePath} element={<pagesInfo.LMS_QUESTIONS_PAGE.component allowedRoles={['Tutor']} />} />
            <Route path={pagesInfo.CREATE_CERTIFICATE.routePath} element={<pagesInfo.CREATE_CERTIFICATE.component allowedRoles={['Tutor']} />} />
            <Route path="tools" element={<pagesInfo.TOOLS.component />} />
            <Route path="partner-offers" element={<pagesInfo.OFFERS.component />} />
            <Route path="" element={<Navigate to={"questions"} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />} />
          </Route>

          {/* update nesting inside drive to activate btn before removing private route from here */}
          <Route path={pagesInfo.DRIVE.routePath} exact element={<pagesInfo.DRIVE.component allowedRoles={['User', 'Tutor']} />}>
            <Route path="my-files" element={<MyDriveFiles />} />
            <Route path="recording" element={<SessionRecording />} />
            <Route path="share-with-me" element={<SharedWithMe />} />
            <Route path="" element={<Navigate to={"my-files"} state={{ from: { ...location, url: `${location.pathname}${location.search}${location.hash}` } }} replace />} />
          </Route>
          {/* update nesting inside drive to activate btn before removing private route from here */}
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.UNAUTHORIZED.roles} />}>
          <Route path={pagesInfo.UNAUTHORIZED.routePath} exact element={<pagesInfo.UNAUTHORIZED.component />} />
        </Route>

        <Route element={<PrivateRouter allowedRoles={pagesInfo.WALLET_CONFIRMATION.roles} />}>
          <Route path={pagesInfo.WALLET_CONFIRMATION.routePath} exact element={<pagesInfo.WALLET_CONFIRMATION.component />} />
        </Route>

        <Route path={pagesInfo.PAGE_NOT_FOUND.routePath} element={<pagesInfo.PAGE_NOT_FOUND.component />} />

      </Route>

      <Route element={<PrivateRouter allowedRoles={pagesInfo.MEETING_ROOM.roles} />} >
        <Route path={pagesInfo.MEETING_ROOM.routePath} exact element={<pagesInfo.MEETING_ROOM.component />} />
      </Route>

    </Routes >
  )
}

export default InitRouter;