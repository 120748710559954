import { useLocation, useNavigate, useParams } from "react-router-dom";

import { RootState, useAppState } from "./useStore";

const DEFAULT_PATH = "/dashboard";
const DEFAULT_DOMAIN = "https://edulyte.com";

const getBaseUrl = (publicOrgDetail: RootState, isWeb: boolean) => {
    const customDomain = publicOrgDetail?.data?.result?.domains?.custom_domain;
    const subDomain = publicOrgDetail?.data?.result?.domains?.sub_domain;

    if (!!customDomain) {
        return isWeb ? customDomain?.web?.origin : customDomain?.app?.origin;
    }

    if (!!subDomain) {
        return isWeb ? subDomain?.web?.origin : subDomain?.app?.origin;
    }

    return null;
};

const useCustomNavigation = () => {
    const { publicOrgDetail } = useAppState((state) => state.org);

    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()

    const appBaseUrl = (typeof window !== "undefined") ? window.location.origin : "";

    const navigation = ({ path = DEFAULT_PATH, isWeb = false, target = "_self", replaceCurrent = false }) => {
        const baseUrl = getBaseUrl(publicOrgDetail, isWeb);

        if (!!baseUrl) {
            // const url = new URL(path, baseUrl).toString()
            // window.open(url, target)
            const targetUrl = new URL(path, baseUrl).toString()

            if (targetUrl.startsWith(appBaseUrl)) {
                const relativePath = targetUrl.replace(appBaseUrl, "") || "/";

                if (replaceCurrent) {
                    navigate(relativePath, { replace: true });
                } else {
                    navigate(relativePath)
                }
            } else {
                if (replaceCurrent) {
                    window.location.replace(targetUrl);
                } else {
                    window.open(targetUrl, target);
                }
            }
        } else {
            window.open(DEFAULT_DOMAIN, '_self')
        }
    }

    const goBack = () => navigate(-1)

    const goForward = () => navigate(1)

    const onReload = () => {
        navigate(location.pathname, { replace: true })
    }

    const getCurrentPath = () => location.pathname

    const isActive = (path: string) => location.pathname === path;

    const getCustomBaseUrl = ({ path = DEFAULT_PATH, isWeb = false }) => {
        const baseUrl = getBaseUrl(publicOrgDetail, isWeb);

        if (!baseUrl) {
            return DEFAULT_DOMAIN;
        }

        let targetUrl = new URL(path, baseUrl).toString()

        if (targetUrl.startsWith(appBaseUrl)) {
            targetUrl = targetUrl.replace(appBaseUrl, "") || "/";
        }
        return targetUrl;
    }

    return {
        navigation,
        goBack,
        goForward,
        onReload,
        getCurrentPath,
        isActive,
        getCustomBaseUrl,
        params,
        location,
    };
};

export default useCustomNavigation;