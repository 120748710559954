import { useState } from 'react';
import { X } from 'lucide-react';
import { getStoredConsent, isSessionId, saveConsent } from 'utils/cookie-manager';
import { baseCredApiInstance } from 'apiInstances/axios.apiInstance';
import { toast } from 'react-toastify';


const CONSENT_STATUS = {
    ACCEPTED : 'accepted',
    REJECTED: 'rejected'
}

const cookieSettings = [
    {
        category: 'necessary',
        title: 'Necessary Cookies',
        description: 'These cookies are required for basic site functionality and cannot be disabled.',
        required: true,
    },
    {
        category: 'analytics',
        title: 'Analytics Cookies',
        description: 'These cookies allow us to analyze site usage to improve our website.',
    },
    {
        category: 'marketing',
        title: 'Marketing Cookies',
        description: 'These cookies are used to deliver personalized advertisements.',
    },
];

export const CookieSettingsModal= ({ isOpen, onClose }) => {
    const [preferences, setPreferences] = useState(() => 
        getStoredConsent() || {
            analytics: CONSENT_STATUS.REJECTED,
            marketing: CONSENT_STATUS.REJECTED,
        }
    );
    
    if (!isOpen) return null;

    const handleToggle = (category) => {
        if (category === 'necessary') return; 
        setPreferences(prev => ({
            ...prev,
            [category]: prev[category] === CONSENT_STATUS.ACCEPTED ? CONSENT_STATUS.REJECTED : CONSENT_STATUS.ACCEPTED,
        }));
    };

    const handleSave = async() => {
        try {

            const requestData = {
                cookie_preferences: []
            }
    
            const session_id = isSessionId();
            if (session_id) {
                requestData.session_id = session_id
            }

            Object.entries(preferences).forEach(([key, value]) => {
                requestData.cookie_preferences.push({ ['cookie_category']: key,  ['is_accepted']: value === 'accepted' });
            });
    
            const response = await baseCredApiInstance().post('cookie-consent/preferences/', requestData);
            if (response?.status === 200) {
                saveConsent(preferences, response?.data?.data?.result[0]?.session_id);
    
            } else {
                throw new Error(response);
            }
        } catch (error) {
            console.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!");
            toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!");
        }
        onClose();
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
        <div className="bg-white rounded-lg max-w-2xl w-full mx-4 max-h-[90vh] overflow-y-auto">
            <div className="p-6">
            <div className="flex items-center justify-between mb-6">
                <h2 className="text-2xl font-semibold text-gray-900">Customise Consent Preferences</h2>
                <button
                onClick={onClose}
                className="text-gray-400 hover:text-gray-500"
                aria-label="Close"
                >
                <X size={24} />
                </button>
            </div>

            <div className="space-y-6">
                {cookieSettings.map((setting) => (
                <div key={setting.category} className="border-b border-gray-200 pb-6">
                    <div className="flex items-center justify-between mb-2">
                    <h3 className="text-lg font-medium text-gray-900">{setting.title}</h3>
                    <label className="relative inline-flex items-center cursor-pointer">
                        <input
                        type="checkbox"
                        checked={setting.category === 'necessary' ? true : preferences[setting.category] === CONSENT_STATUS.ACCEPTED}
                        onChange={() => handleToggle(setting.category)}
                        disabled={setting.required}
                        className="sr-only peer"
                        />
                        <div className={`w-11 h-6 bg-gray-200 rounded-full peer 
                        peer-checked:after:translate-x-full peer-checked:after:border-white 
                        after:content-[''] after:absolute after:top-0.5 after:left-[2px] 
                        after:bg-white after:border-gray-300 after:border after:rounded-full 
                        after:h-5 after:w-5 after:transition-all
                        ${preferences[setting.category] ? 'bg-blue-600' : ''}`}></div>
                    </label>
                    </div>
                    <p className="text-gray-600">{setting.description}</p>
                </div>
                ))}
            </div>

            <div className="mt-6 flex justify-end space-x-4">
                <button
                    onClick={onClose}
                    className="px-6 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50"
                >
                    Cancel
                </button>
                <button
                    onClick={handleSave}
                    className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                >
                    Save Preferences
                </button>
            </div>
            </div>
        </div>
        </div>
    );
};
