import { extractSubdomain, getCustomHostPath } from "utils/generators.utils";

import { EDULYTE_WEB_DOMAIN_URL } from "const/default.const";

export const dataFAQ = [
    {
        question: "Where can I spend this credit?",
        answer: "You can book your one-on-one lessons or group classes on our website. Pay for all the paid services/subscriptions."
    },
    {
        question: "Does this credit expire?",
        answer: "If your account remains inactive for 12 months, then your credit will expire."
    },
    {
        question: "Can I convert this credit to cash?",
        answer: "You cannot convert the credit to cash, they must be used on Edulyte Platform."
    },
    {
        question: "I am a tutor, how can I use this credit?",
        answer: <span>You can use this credit to <a className={"text-primary-dark"} href={`${getCustomHostPath({ subDomain: extractSubdomain(window.location.host), domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/classes` })}`} target={"_blank"}>book classes</a> or buy any product available on our website.</span>
    },
    {
        question: "I have some questions, who can I contact?",
        answer: <span>You can send an email to <a className={"text-primary-dark"} href={"mailto:support@edulyte.com"}>support@edulyte.com</a> or message us on <a className={"text-primary-dark"} href={"https://api.whatsapp.com/message/MVMIAUQBIAFHD1?autoload=1&app_absent=0"} target={"_blank"}>whatsapp</a>/<a className={"text-primary-dark"} href={`https://t.me/Edulyte_bot`} target={"_blank"}>telegram</a>.</span>
    },
    {
        question: "What are the terms and conditions?",
        answer: <span>Please see our terms for the upfront credit promotions <a className={"text-primary-dark"} href={"https://support.edulyte.com/portal/en/kb/articles/upfront-credit-promo"} target={"_blank"}>here</a>.</span>
    },
]