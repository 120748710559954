import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import courseServices from "redux/course/course.services";

const cohortSectionListState = { isLoading: false, result: null, error: null }

export const useGetCohortSectionList = () => {
    const { cohortId } = useParams()

    const [cohortSectionList, setCohortSectionList] = useState(cohortSectionListState)

    useEffect(() => {
        if (!!cohortId) {
            getCohortSectionList(cohortId)
        }
    }, [cohortId])

    const getCohortSectionList = async (cohortId) => {
        setCohortSectionList((prevState) => ({ ...prevState, isLoading: true }))

        try {
            const requestData = {
                params: { cohortId: cohortId }
            }
            const response = await courseServices.getCohortSectionList(requestData)
            if (response.status === 200) {
                setCohortSectionList((prevState) => ({ ...prevState, result: response?.data?.data?.result }))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            setCohortSectionList((prevState) => ({ ...prevState, error: error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!" }))
        } finally {
            setCohortSectionList((prevState) => ({ ...prevState, isLoading: false }))
        }
    }

    return { cohortSectionList, setCohortSectionList };
}