import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import { Controller, useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { IoIosCheckmark } from 'react-icons/io';

import ButtonLoader from 'components/loader/button-loader';

import { addSessionFormSchema, locationTypeFieldMap } from './data';
import DropdownMenu from './location/dropdowns/DropdownMenu';
import DateTimePicker from './datetimePicker';
import SessionLocation from './location';

import { getMasterCountryList } from 'redux/master/master.request';
import sessionService from 'redux/session/session.service';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { setClearCountryList } from 'redux/master/master.slice';
import { resetAddSession, setAddSession } from 'redux/session/session.slice';
import { modalConst } from 'redux/local/local.const';
import { sessionLocationTypeEnum, sessionTypeEnum } from 'redux/session/session.const';

import { cn } from "utils/cn.utils";
import { dayjs, timeZone } from 'utils/dateTime.utils';

const AddCohortSessionModal = () => {
    const { modal } = useAppState((state) => state.local)
    const { user } = useAppState((state) => state.user)
    const { addSession } = useAppState((state) => state.session)

    const dispatcher = useAppDispatcher()

    const [isSubmitting, setIsSubmitting] = useState(false)

    const form = useForm({
        mode: "onChange",
        resolver: zodResolver(addSessionFormSchema),
        defaultValues: {
            owner_user_id: user?.user?.userId,
            slot: {
                start_datetime: "",
                end_datetime: "",
            },
            timeZone: timeZone,
            name: "",
            type: sessionTypeEnum.COHORT.value,
            cohort_section: {
                cohort_section_id: addSession?.payload?.cohort_section_id,
            },
            session_locations: []
        }
    })

    const {
        register,
        unregister,
        setValue,
        control,
        formState: { errors, isLoading, isValid },
        setError,
        handleSubmit,
        watch,
        trigger
    } = form

    const values = watch({ control: control })

    useEffect(() => {
        dispatcher(getMasterCountryList())

        return () => {
            dispatcher(setClearCountryList())
        }
    }, [])

    useEffect(() => {
        if (!!values?.session_locations[0]?.location_type) {
            const selectedLocation = values?.session_locations[0]?.location_type
            const fieldsToUnregister = Object.keys(locationTypeFieldMap)
                .filter(key => key !== selectedLocation)
                .flatMap(key => locationTypeFieldMap[key]);

            fieldsToUnregister.forEach(field => {
                setValue(`session_locations[0].${field}`, "")
                unregister(`session_locations[0].${field}`)
            });
        }
    }, [values?.session_locations[0]?.location_type, unregister, setValue]);

    useEffect(() => {
        if (!!addSession?.result) {
            dispatcher(resetAddSession())
            dispatcher(setModal({
                ...modal,
                [modalConst.addCohortSessionModal.key]: {
                    ...modal[modalConst.addCohortSessionModal.key],
                    isVisible: false,
                    title: "Add Session"
                }
            }))
        }
    }, [addSession?.result])

    const onHandleSubmit = async (data) => {
        const requestBody = {
            ...data,
            slot: {
                start_datetime: dayjs(data?.slot?.start_datetime).format("YYYY-MM-DD HH:mm:ss"),
                // start_datetime: dayjs(data?.slot?.start_datetime).utc().format("YYYY-MM-DD HH:mm:ss"),
                end_datetime: dayjs(data?.slot?.end_datetime).format("YYYY-MM-DD HH:mm:ss")
                // end_datetime: dayjs(data?.slot?.end_datetime).utc().format("YYYY-MM-DD HH:mm:ss")
            }
        }
        try {
            setIsSubmitting(true)
            const requestData = {
                body: requestBody
            }
            const response = await sessionService.createSession(requestData)
            if (response.status === 201) {
                dispatcher(setAddSession({ result: response.data.data?.result }))
                toast.success(response.data.data.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            setError("root", { message: error?.response?.data?.message || error?.response?.data?.error || "Oops, Something went wrong!" })
            console.error(error?.response?.data?.message || error?.response?.data?.error || "Oops, Something went wrong!")
            toast.error(error?.response?.data?.message || error?.response?.data?.error || "Oops, Something went wrong!")
        } finally {
            setIsSubmitting(false)
        }
    }

    return (
        <div className={"w-full space-y-5"}>
            <div className={'w-full min-h-[16rem] block space-y-5'}>
                <FormProvider {...form}>
                    <form className={'w-full grid grid-cols-2 gap-5 font-bodyPri font-normal'} onSubmit={handleSubmit(onHandleSubmit)}>

                        {/* session name */}
                        <div className={"w-full col-span-full space-y-2"}>
                            <span>{"Name:"}</span><span className={"text-red-500"}>{"*"}</span>
                            <input
                                type={"text"}
                                className={cn(
                                    "px-3 h-9 flex w-full focus:outline-none rounded-lg border border-back-ground-lightDark focus:border focus:border-primary-main",
                                    "font-bodyPri font-normal text-text-800 text-base placeholder:text-text-500"
                                )}
                                placeholder={'Session name ...'}
                                {...register("name")}
                            />
                            {!!errors?.name && (
                                <span className={"font-bodyPri font-normal text-xs text-secondary-main text-left transition-all ease-in-out duration-200"}>
                                    {errors?.name?.message}
                                </span>
                            )}
                        </div>

                        {/* session date time */}
                        <div className={"w-full col-span-full md:col-span-1 space-y-1"}>
                            <span>{"Start Date:"}</span><span className={"text-red-500"}>{"*"}</span>
                            <Controller
                                name={"slot.start_datetime"}
                                control={control}
                                render={({ field: { value, onChange }, fieldState: { error } }) => (
                                    <div className={"w-full space-y-1"}>
                                        <DateTimePicker
                                            defaultValue={dayjs(value)?.tz(timeZone)}
                                            value={dayjs(value)?.tz(timeZone)}
                                            onChange={(datetime) => {
                                                onChange(dayjs(datetime).format("YYYY-MM-DD HH:mm:ss"))
                                                trigger("slot.start_datetime")
                                            }}
                                        />
                                        {!!error && (
                                            <div className={"flex items-center justify-start"}>
                                                <span className={"font-bodyPri font-normal text-sm text-secondary-main"}>
                                                    {error?.message}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                )}
                            />
                        </div>

                        {/* session end date */}
                        <div className={"w-full col-span-full md:col-span-1 space-y-1"}>
                            <span>{"End Date:"}</span><span className={"text-red-500"}>{"*"}</span>
                            <Controller
                                name={"slot.end_datetime"}
                                control={control}
                                render={({ field: { value, onChange }, fieldState: { error } }) => (
                                    <div className={"w-full space-y-1"}>
                                        <DateTimePicker
                                            defaultValue={dayjs(value)?.tz(timeZone)}
                                            value={dayjs(value)?.tz(timeZone)}
                                            onChange={(datetime) => {
                                                onChange(dayjs(datetime).format("YYYY-MM-DD HH:mm:ss"))
                                                trigger("slot.end_datetime")
                                            }}
                                            minDate={dayjs(values?.slot?.start_datetime).tz(timeZone)}
                                        />
                                        {!!error && (
                                            <div className={"flex items-center justify-start"}>
                                                <span className={"font-bodyPri font-normal text-sm text-secondary-main"}>
                                                    {error?.message}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                )}
                            />
                        </div>

                        {/* session location */}
                        <div className={"w-full col-span-full space-y-3"}>
                            <span>{"Location:"}</span><span className={"text-red-500"}>{"*"}</span>
                            <Controller
                                name={"session_locations[0].location_type"}
                                control={control}
                                render={({ field: { value, onChange }, fieldState: { error } }) => (
                                    <div className={"space-y-1"}>
                                        <DropdownMenu
                                            options={Object.values(sessionLocationTypeEnum)?.filter((item) => item.key !== sessionLocationTypeEnum.google_meet.key)}
                                            onHandleSelect={(selectedOption) => {
                                                onChange(selectedOption?.key)
                                                // trigger("session_locations[0].location_type")
                                            }}
                                            selectedOption={<button className={"flex items-center justify-start gap-3 font-normal text-text-800"}>
                                                {/* <sessionLocationTypeEnum[value].icon className={"text-xl"} /> */}
                                                <span className={""}>
                                                    {sessionLocationTypeEnum[value]?.label ?? "Select any"}
                                                </span>
                                            </button>}
                                            selectedMenuItem={value}
                                            OptionChild={({ option }) => (
                                                <div className={"w-full flex items-center justify-between gap-3"}>
                                                    <div className={"flex items-center justify-start gap-3"}>
                                                        <option.icon className={"text-xl"} />
                                                        {option?.label}
                                                    </div>
                                                    {(option?.key === value)
                                                        && <IoIosCheckmark size={20} color="green" />
                                                    }
                                                </div>
                                            )}
                                        />
                                        {!!error && (
                                            <div className={"flex items-center justify-start"}>
                                                <span className={"font-bodyPri font-normal text-sm text-secondary-main"}>
                                                    {error?.message}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                )}
                            />
                            {!!values?.session_locations[0]?.location_type &&
                                <SessionLocation values={values} />
                            }
                        </div>

                        <div className={"col-span-full flex justify-end"}>
                            <button
                                type={"submit"}
                                className={cn(
                                    "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                                    "font-buttons font-normal text-base text-secondary-main",
                                    (isLoading || isSubmitting) && "bg-secondary-main",
                                    !isValid && "text-secondary-light border-secondary-light hover:bg-white hover:text-secondary-light",
                                    (!isLoading && !isSubmitting && isValid) && "border border-secondary-main hover:bg-secondary-main hover:text-text-50",
                                )}
                                disabled={isLoading || isSubmitting}
                            >
                                {isLoading || isSubmitting &&
                                    <ButtonLoader className={"py-2"} isLoading={isLoading || isSubmitting} />
                                }
                                {(!isLoading && !isSubmitting) && "Submit"}
                            </button>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
    )
}

export default AddCohortSessionModal;