import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { BsEye } from 'react-icons/bs';

import ToolTipView from 'components/tooltipView';

import { pagesInfo } from 'utils/pagesInfo';
import { dayjs, timeZone, getTimeZoneOffset } from 'utils/dateTime.utils';
import { cn } from 'utils/cn.utils';

const isCurrentTime = (events) => {
    const currentDay = dayjs();
    return (
        currentDay.isAfter(dayjs(events?.start_datetime, "hh:mm A")) &&
        currentDay.isBefore(dayjs(events?.end_datetime, "hh:mm A"))
    );
};

const CohortSession = ({ cohortSession }) => {

    const navigate = useNavigate()

    const timeZoneOffset = useMemo(() => getTimeZoneOffset(timeZone))

    const isDateSimilar = useMemo(() => dayjs(cohortSession?.start_datetime).isSame(cohortSession?.end_datetime, "date"), [cohortSession?.start_datetime, cohortSession?.end_datetime])

    const onHandleViewSession = () => {
        navigate(`${pagesInfo.SCHEDULE.pagePath}?sessionId=${cohortSession?.id}`)
    }

    return (
        <div className={cn(
            'flex flex-col md:flex-row items-end md:items-start justify-between gap-2 px-5 py-2 border bg-white rounded-lg',
            'border-divider-darkLight hover:border-divider-lightDark',
            isCurrentTime(cohortSession) && "border-2 border-blue-400 bg-blue-300"
        )}>
            <div className={"w-full flex items-center gap-3"} >
                <div className={"border border-primary rounded-lg px-4 py-1 flex flex-col items-center justify-center gap-1"}>
                    <p className={"font-bodyPri text-base text-text-900"}>
                        {dayjs(cohortSession?.start_datetime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format('MMM')}
                    </p>
                    <p className={"font-bodyPri font-bold text-base text-primary"}>
                        {dayjs(cohortSession?.start_datetime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format('DD')}
                    </p>
                </div>
                <div className={"flex-1 px-2.5 py-0.5 flex flex-col gap-1 rounded-lg overflow-hidden"}>
                    <p className={"text-base font-medium font-bodyPri text-text-900 truncate capitalize"}>
                        {cohortSession?.name}
                    </p>
                    <div className={"inline-flex items-center gap-1"}>
                        <p className={"font-bodyPri text-xs text-text-700"}>
                            {dayjs(cohortSession?.start_datetime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format('ddd, MMM DD, YYYY hh:mm A')}
                        </p>
                        {(cohortSession?.end_datetime && !isDateSimilar) &&
                            <>
                                <p>{"-"}</p>
                                <p className={"font-bodyPri text-xs text-text-700"}>
                                    {dayjs(cohortSession?.end_datetime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format('ddd, MMM DD, YYYY hh:mm A')}
                                </p>
                            </>
                        }
                        <p className={"text-xs text-text-700"}>
                            {`(${timeZoneOffset})`}
                        </p>
                    </div>
                </div>
            </div>
            <div className={'flex justify-end items-center gap-1.5'}>
                <ToolTipView content={'View Session'}>
                    <button className={"p-2 rounded-full hover:bg-divider-darkLight group"} onClick={onHandleViewSession}>
                        <BsEye className={"text-xl text-text-700 group-hover:text-primary-dark cursor-pointer"} />
                    </button>
                </ToolTipView>
            </div>
        </div>
    )
}

export default CohortSession;