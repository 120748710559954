import { Link } from "react-router-dom";
import { cn } from "utils/cn.utils";

import { FiShare } from "react-icons/fi";
import { MdVerified } from "react-icons/md";

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import { RightArrow } from './CourseHeaderStyle';

// import CourseHeaderCard from './CourseHeaderCard';
import RatingStars from 'components/ratingStars/RatingStars';

import { createCourseFavoriteDetail, deleteCourseFavoriteDetail } from "redux/course/course.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal, setShareSocial } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";
import { blueTickEnum } from "redux/user/user.const";

import { pagesInfo } from "utils/pagesInfo";
import { getCustomHostPath } from "utils/generators.utils";
import useCustomNavigation from "hooks/useCustomNavigation";

const CourseHeader = () => {
    const { modal, shareSocial } = useAppState((s) => s.local)
    const { publicOrgDetail } = useAppState((state) => state.org)
    const { session } = useAppState(s => s.auth)
    const { user } = useAppState((s) => s.user)
    const { publicCourseDetail, addCourseFavDetail, deleteCourseFavDetail } = useAppState((s) => s.course)

    const dispatcher = useAppDispatcher()
    const customNavigation = useCustomNavigation()

    // const TUTOR_COURSE_URL = `${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, pathname: `${pagesInfo.COURSE.pagePath}/${publicCourseDetail?.data?.slug}` })}`
    const TUTOR_COURSE_URL = `${customNavigation.getCustomBaseUrl({ path: `${pagesInfo.COURSE.pagePath}/${publicCourseDetail?.data?.slug}` })}`

    const filteredFavoriteCourse = user?.user?.favoriteCourses?.filter((course) => course.courseId === publicCourseDetail?.data?.id)?.length > 0
    const AVG_RATING = 2

    const handleFavCourse = () => {
        if (addCourseFavDetail?.isLoading || deleteCourseFavDetail?.isLoading) return;
        if (filteredFavoriteCourse) {
            dispatcher(deleteCourseFavoriteDetail({ userId: user?.user?.userId, courseId: publicCourseDetail?.data?.id }))
        }
        else {
            dispatcher(createCourseFavoriteDetail({ userId: user?.user?.userId, courseId: publicCourseDetail?.data?.id }))
        }
    }

    const handleShareCourse = () => {
        dispatcher(setShareSocial({
            ...shareSocial,
            socialAction: "",
            url: TUTOR_COURSE_URL
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.SHARE_SOCIAL_MODAL.stateKey]: true
        }))
    }

    return (
        <div className={"w-full space-y-5"}>
            <div className={"grid grid-cols-12 gap-5"}>
                <div className={"col-start-1 col-span-8 flex items-center justify-start gap-0.5 md:gap-2 text-text-50"}>
                    <Link to={pagesInfo?.FIND_COURSES.pagePath} className={'hover:text-secondary-main'}>
                        {"Browse"}
                    </Link>
                    <RightArrow className="text-xl sm:text-md md:text-sm" />
                    <Link to={`${pagesInfo?.FIND_COURSES.pagePath}?segment=${publicCourseDetail?.data?.segment?.replaceAll(" ", "-")?.toLowerCase()}`} className={'truncate hover:text-secondary-main'}>
                        {publicCourseDetail?.data?.segment}
                    </Link>
                    <RightArrow className="text-xl sm:text-md md:text-sm" />
                    <Link to={`${pagesInfo?.FIND_COURSES.pagePath}/${publicCourseDetail?.data?.category?.replaceAll(" ", "-")?.toLowerCase()}`} className={'truncate hover:text-secondary-main'}>
                        {publicCourseDetail?.data?.category}
                    </Link>
                </div>

                <div className={"col-start-9 col-span-4 flex justify-end"}>
                    {session?.isLoggedIn &&
                        <div className={"px-2 cursor-pointer"} onClick={handleFavCourse}>
                            {filteredFavoriteCourse
                                ? <FavoriteIcon className='text-red-500' style={{ fontSize: "1.8rem" }} />
                                : <FavoriteBorderIcon className='text-text-500' style={{ fontSize: "1.8rem" }} />
                            }
                        </div>
                    }
                    <div className={"relative has-tooltip cursor-pointer"} onClick={handleShareCourse}>
                        <FiShare className={"text-text-50 text-2xl cursor-pointer"} />
                        <span
                            className={cn(
                                "w-16 px-2 py-1 font-bodyPri font-normal text-xs rounded-md shadow-lg bg-text-900 text-text-50 tooltip -top-5",
                                "flex flex-col items-center justify-start overflow-hidden"
                            )}
                        >
                            {"Share"}
                        </span>
                    </div>
                </div>
            </div>
            <div className={"grid grid-cols-12 gap-5"}>
                <div className={"col-start-1 col-span-full lg:col-start-1 lg:col-span-8"}>
                    <div className={"flex flex-col items-start justify-start gap-3 text-white"}>
                        <span className={'text-3xl font-semibold font-bodyPri tracking-wide'}>
                            {publicCourseDetail?.data?.title}
                        </span>
                        <span className={"text-lg font-normal font-bodyPri tracking-wide"}>
                            {publicCourseDetail?.data?.subTitle}
                        </span>
                    </div>
                </div>
                <div className={"col-start-1 col-span-8 space-y-5 py-5"}>
                    <div className="w-full flex items-center justify-start gap-3">
                        <div className='w-20 h-20 rounded-full'>
                            <Link to={`${pagesInfo?.TUTOR?.pagePath}/${publicCourseDetail?.data?.tutor?.user?.id}`}>
                                <img src={publicCourseDetail?.data?.tutor?.user?.profilePicUrl} alt={"tutor-public-profile"} className="w-full h-full object-cover rounded-full" />
                            </Link>
                            <div className={'relative has-tooltip'}>
                                <div className={"absolute bottom-0.5 right-0.5 w-5 h-5 rounded-full border-2 border-white overflow-hidden"}>
                                    <img
                                        src={`https://flagcdn.com/16x12/${publicCourseDetail?.data?.tutor?.user?.fromCountry?.countryDomain}.png`.toLowerCase()}
                                        alt={"country-flag"}
                                        className='w-full h-full object-cover overflow-hidden'
                                    />
                                </div>
                                <span
                                    className={cn(
                                        "w-16 px-1 py-0.5 font-bodyPri font-normal text-xs rounded-md shadow-lg bg-text-900 text-text-50 tooltip -top-1 -right-0",
                                        "flex flex-col items-center justify-start overflow-hidden"
                                    )}
                                >
                                    {publicCourseDetail?.data?.tutor?.user?.fromCountry?.country}
                                </span>
                            </div>
                        </div>
                        <div className="text-white flex flex-col items-start justify-center">
                            <div className='flex items-center gap-2'>
                                <span className="text-lg font-bodyPri capitalize">
                                    {publicCourseDetail?.data?.tutor?.user?.firstName + " " + publicCourseDetail?.data?.tutor?.user?.lastName.charAt(0) + "."}
                                </span>
                                {(publicCourseDetail?.data?.tutor?.user?.blueTick === blueTickEnum?.YES?.value) &&
                                    <div className='relative has-tooltip cursor-pointer'>
                                        <MdVerified className={"text-lg text-primary-dark"} />
                                        <span className='tooltip w-fit px-2 py-1 bg-black text-white rounded-lg font-bodyPri font-normal text-xs tracking-wide sm:whitespace-nowrap -translate-x-[50%] -translate-y-[150%] md:-translate-y-[190%]'>
                                            {"Verified Account"}
                                        </span>
                                    </div>
                                }
                                {(publicCourseDetail?.data?.tutor?.user?.onlineStatus === "online")
                                    && <span className={"w-2 h-2 rounded-full bg-green-600"}></span>
                                }
                            </div>
                            <div className='flex items-center'>
                                {publicCourseDetail?.data?.tutor?.rating?.avgRating > AVG_RATING
                                    && <>
                                        <RatingStars rating={publicCourseDetail?.data?.tutor?.rating?.avgRating?.toFixed(1)} fullRating={5} />
                                        <span className="font-bold">{publicCourseDetail?.data?.tutor?.rating?.avgRating}
                                            ({publicCourseDetail?.data?.tutor?.rating?.totalStars?.count})
                                        </span>
                                    </>
                                    // : <div className='text-sm font-bodyPri tracking-wide text-accent-main'>
                                    //     {"New"}
                                    // </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default CourseHeader
