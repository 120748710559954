import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { cn } from "utils/cn.utils";

import { MdEdit } from 'react-icons/md';

import ComponentLoader from 'components/loader/ComponentLoader';
import ViewOnlyQuillEditor from 'components/textEditor/ViewOnlyQuillEditor';
import QuillEditor from 'components/textEditor/QuillEditor';
import AiTextButton from 'components/createWithAi/aiTextButton';

import AddBorderButton from '../../_components/buttons/AddBorderButton';
import SaveAndCancelBtn from "../../_components/buttons/SaveAndCancelBtn";
import PreviousNextButtons from '../../_components/buttons/PreviousNextButtons';
import { courseDetailBtnConst, classDetailNavigationOptions, classDetailTooltips, Editor } from '../../data';

import { updateCourse } from 'redux/course/course.request';

import useAiText from 'hooks/useAiText';
import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModifyCoursePayload } from 'redux/course/course.slice';

const DescriptionPage = () => {
    const { instructorCourse, modifyCourse } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()

    const [isMouseHover, setIsMouseHover] = useState(false)
    const [editBtn, setEditBtn] = useState(null)
    const [isShowAddDescription, setIsShowAddDescription] = useState(!instructorCourse?.result?.description)
    const [shouldUpdateDescription, setShouldUpdateDescription] = useState(false)
    const [isShowInput, setIsShowInput] = useState(false)
    const [topic, setTopic] = useState('')

    const { aiText, generateAiText } = useAiText()

    const requestData = {
        purpose: "description", // description or title 
        total_chars: 250,
        topic: topic,
        response_type: "html" // text or html 
    };

    const getAiText = () => {
        if (topic === "") {
            toast.error("Please write ai prompt");
            return
        }
        setIsShowInput(false)
        generateAiText(requestData);
    };

    const handlePrompt = (e) => {
        const value = e.target.value
        setTopic(value)
    }

    useEffect(() => {
        if (aiText?.data?.ai_response) {
            dispatcher(updateCourse(
                instructorCourse?.result?.id,
                {
                    description: aiText.data.ai_response
                })
            )
        }
    }, [aiText?.data]);

    const has_content = useMemo(() => (!!new DOMParser()?.parseFromString(`<div>${instructorCourse?.result?.description}</div>`, "text/html")?.querySelector("div")?.textContent), [instructorCourse?.result?.description])

    const onHandleAddDescription = useCallback(() => {
        setIsShowAddDescription(false)
        setEditBtn(courseDetailBtnConst.DESCRIPTION.value)
    }, [isShowAddDescription, editBtn])

    const onHandleEditDescription = useCallback(() => {
        setIsMouseHover(false)
        dispatcher(setModifyCoursePayload({
            ...modifyCourse?.payload,
            description: instructorCourse?.result?.description
        }))
        setEditBtn(courseDetailBtnConst.DESCRIPTION.value)
    }, [modifyCourse?.payload, instructorCourse?.result])

    const onHandleChangeDescription = useCallback((content) => {
        setShouldUpdateDescription(true)
        dispatcher(setModifyCoursePayload({
            ...modifyCourse?.payload,
            description: content
        }))
    }, [modifyCourse?.payload, shouldUpdateDescription])

    const onHandleSaveDescription = useCallback(() => {
        if (instructorCourse?.result?.description === modifyCourse?.payload?.description) {
            setEditBtn(null)
            return;
        }
        dispatcher(updateCourse(
            instructorCourse?.result?.id,
            {
                description: modifyCourse?.payload?.description
            })
        )
        setEditBtn(null)
    }, [instructorCourse?.result?.description, modifyCourse?.payload?.description, editBtn])

    const onHandleResetDescription = useCallback(() => {
        setEditBtn(null)
    }, [editBtn])

    return (
        <div className={"w-full space-y-5"}>
            <div className={"w-full flex flex-col gap-5"}>
                <div className={"w-full flex justify-between items-center px-3 py-2 bg-back-ground-lightBlue rounded-lg"}>
                    <span className={"font-bodyPri font-bold text-text-900 text-lg tracking-wide"}>
                        {"Description"}
                    </span>
                    <div className='relative mt-1 flex justify-end'>
                        <AiTextButton
                            containerClassName="top-0 right-0"
                            btnClassName="rounded h-6"
                            btnTitle=''
                            tooltip='Create description with AI'
                            isShowInput={isShowInput}
                            setIsShowInput={setIsShowInput}
                            handlePrompt={handlePrompt}
                            getAiText={getAiText}
                            aiText={aiText}
                        />
                    </div>
                </div>

                <div className={"w-full text-center font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                    {classDetailTooltips?.description}
                </div>
                {(!modifyCourse?.isLoading && isShowAddDescription) &&
                    <AddBorderButton
                        btnTitle={"Add Description"}
                        onHandleAdd={onHandleAddDescription}
                    />
                }

                {modifyCourse?.isLoading &&
                    <ComponentLoader isLoading={modifyCourse?.isLoading} />
                }

                {(!modifyCourse?.isLoading && !isShowAddDescription) &&
                    <div className={"w-full flex flex-col gap-3 px-5"}>
                        {/* description */}
                        <div
                            className={cn("grid grid-cols-12 gap-2 items-start", !editBtn && "cursor-pointer")}
                            onMouseOver={() => setIsMouseHover(true)}
                            onMouseOut={() => setIsMouseHover(false)}
                        >
                            <span className={"h-5 col-span-8 order-1 w-fill flex items-center justify-start font-bodyPri font-medium text-text-700 text-base tracking-wide"}>
                                <span>{""}</span>
                                <span>{""}</span>
                            </span>
                            {(editBtn !== courseDetailBtnConst.DESCRIPTION.value) &&
                                <div
                                    className={"relative w-full col-span-full order-3 justify-self-stretch px-2 py-2 rounded-lg hover:bg-text-100"}
                                    onClick={onHandleEditDescription}
                                >
                                    {has_content &&
                                        <ViewOnlyQuillEditor
                                            editorContent={instructorCourse?.result?.description}
                                            editorClassName={"placeholder:text-text-500 placeholder:text-lg"}
                                        />
                                    }
                                    {!has_content &&
                                        <span className={"font-bodyPri font-normal text-text-700 text-base italic"}>
                                            {"Start Typing"}
                                        </span>
                                    }
                                    {isMouseHover &&
                                        <span className={"absolute top-1 right-2"}>
                                            <MdEdit className={"text-xl md:text-2xl text-text-800 cursor-pointer"}
                                            />
                                        </span>
                                    }
                                </div>
                            }
                            {(editBtn === courseDetailBtnConst.DESCRIPTION.value) &&
                                <div className={"relative col-span-full order-3 justify-self-stretch"}>
                                    <div className={"absolute right-3 flex items-center justify-start gap-0.5"}>
                                        <SaveAndCancelBtn
                                            onHandleSave={onHandleSaveDescription}
                                            onHandleReset={onHandleResetDescription}
                                        />
                                    </div>
                                    <QuillEditor
                                        editorToolModules={Editor?.modules}
                                        editorToolFormats={Editor?.formats}
                                        editorText={modifyCourse?.payload?.description}
                                        onHandleChangeText={onHandleChangeDescription}
                                        placeholder={"Start Typing"}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
            <PreviousNextButtons
                shouldUpdateDescription={shouldUpdateDescription}
                body={{ description: modifyCourse?.payload?.description }}
                previousBtn={classDetailNavigationOptions?.general?.to}
                nextBtn={classDetailNavigationOptions?.skills?.to}
            />
        </div>
    )
}

export default DescriptionPage;