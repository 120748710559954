import { useCallback, useState, useEffect } from 'react';

import { cn } from "utils/cn.utils";

import SearchSelector from 'components/searchSelector/SearchSelector';

import LmsCourseTooltip from 'pages/auth/edulyteLms/commonComponents/LmsCourseTooltip';

import { courseDetailBtnConst, courseDetailNavigationOptions, lmsCourseTooltips } from '../../../../editLMSCourse.data';
import SaveAndCancelBtn from "pages/auth/edulyteLms/commonComponents/lmsButtons/SaveAndCancelBtn";
import PreviousNextButtons from '../../../../../../commonComponents/lmsButtons/PreviousNextButtons';

import { getAllCategoryList } from 'redux/category/category.request';
import { updateLmsCourseDetail } from 'redux/edulyteLms/lmsCourse/lmsCourse.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setClearCategoryList } from 'redux/category/category.slice';
import { setAddLmsCourseDetailPayload } from "redux/edulyteLms/lmsCourse/lmsCourse.slice";
import { lmsCourseStatusEnum } from 'redux/edulyteLms/lmsCourse/lmsCourse.const';

import { getCustomHostPath } from 'utils/generators.utils';

import { EDULYTE_WEB_DOMAIN_URL } from 'const/default.const';
import useCustomNavigation from 'hooks/useCustomNavigation';

const TITLE_MIN_LENGTH = 5
const TITLE_MAX_LENGTH = 128
const SUB_TITLE_MAX_LENGTH = 512

const CourseBasicDetail = () => {
    const publicOrgDetail = useAppState((state) => state.org.publicOrgDetail)
    const { categoryList } = useAppState((state) => state.category)
    const { lmsCourseDetail, addLmsCourseDetail } = useAppState((state) => state.lms.lmsCourse)

    const dispatcher = useAppDispatcher()
    const customNavigation = useCustomNavigation()

    const [mouseHovered, setMouseHovered] = useState(null)
    const [editBtn, setEditBtn] = useState(null)

    useEffect(() => {
        dispatcher(getAllCategoryList())

        return () => {
            dispatcher(setClearCategoryList())
        }
    }, [])

    const onHandleNavigateToCourse = useCallback(() => {
        if (lmsCourseDetail?.data?.course_setting?.status === lmsCourseStatusEnum?.PUBLISHED?.value) {
            // window.open(`${EDULYTE_WEB_DOMAIN_URL}/course/${lmsCourseDetail?.data?.active_slug}`)
            // window.open(`${getCustomHostPath({ subDomain: publicOrgDetail?.data?.result?.sub_domain, domain: EDULYTE_WEB_DOMAIN_URL, pathname: `/course/${lmsCourseDetail?.data?.active_slug}` })}`)
            customNavigation.navigation({ path: `/course/${lmsCourseDetail?.data?.active_slug}`, isWeb: true })
        }
    }, [lmsCourseDetail?.data])

    const onHandleEditDetail = (editBtn) => {
        if (editBtn === courseDetailBtnConst?.CATEGORY?.value) {
            dispatcher(setAddLmsCourseDetailPayload({
                ...addLmsCourseDetail?.payload,
                [editBtn]: lmsCourseDetail?.data?.category?.category
            }))
            setMouseHovered(null)
            setEditBtn(editBtn)
            return;
        }
        dispatcher(setAddLmsCourseDetailPayload({
            ...addLmsCourseDetail?.payload,
            [editBtn]: lmsCourseDetail?.data[editBtn]
        }))
        setMouseHovered(null)
        setEditBtn(editBtn)
    }

    const onHandleChangeBasicDetail = useCallback((event) => {
        dispatcher(setAddLmsCourseDetailPayload({
            ...addLmsCourseDetail?.payload,
            [event?.target?.name]: event?.target?.value
        }))
    }, [addLmsCourseDetail?.payload])

    const onHandleSaveBasicDetail = useCallback((btnKey) => {
        if (btnKey === courseDetailBtnConst?.CATEGORY?.value) {
            if (lmsCourseDetail?.data?.category === addLmsCourseDetail?.payload?.category) {
                setEditBtn(null)
                return;
            }
            dispatcher(updateLmsCourseDetail(
                lmsCourseDetail?.data?.id,
                {
                    [btnKey]: addLmsCourseDetail?.payload?.category
                }
            ))
            setEditBtn(null)
        } else {
            if (lmsCourseDetail?.data[btnKey] === addLmsCourseDetail?.payload[btnKey]) {
                setEditBtn(null)
                return;
            }
            dispatcher(updateLmsCourseDetail(
                lmsCourseDetail?.data?.id,
                {
                    [btnKey]: addLmsCourseDetail?.payload[btnKey]
                }
            ))
            setEditBtn(null)
        }

    }, [lmsCourseDetail?.data, addLmsCourseDetail?.payload, editBtn])

    const onHandleResetBasicDetail = useCallback(() => {
        setEditBtn(null)
    }, [editBtn])

    return (
        <div className={"w-full flex flex-col justify-between gap-5 rounded-lg overflow-hidden p-5 bg-white"}>
            <div className={"w-full flex flex-col gap-5"}>
                <div className={"w-full px-3 py-2 bg-divider-medium rounded-lg"}>
                    <span className={"font-bodyPri font-bold text-text-900 text-lg tracking-wide"}>
                        {"General Detail"}
                    </span>
                </div>
                <div className={"w-full px-5 flex flex-col gap-5"}>

                    {/* active slug / read */}
                    <div className={"grid grid-cols-12 gap-2 items-start"}>
                        <span className={"col-span-8 order-1 lg:col-span-2 w-fill flex items-center justify-start lg:justify-between font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
                            <span>{"Slug"}</span>
                            <span>{":"}</span>
                        </span>
                        <span className={cn(
                            "col-span-full px-3 w-fit order-3 lg:order-2 lg:col-span-9 justify-self-stretch font-bodyPri font-normal text-text-900 text-md tracking-wide",
                            (lmsCourseDetail?.data?.course_setting?.status === lmsCourseStatusEnum?.PUBLISHED?.value) && "!text-primary-dark hover:underline cursor-pointer"
                        )}
                            onClick={onHandleNavigateToCourse}
                        >
                            {lmsCourseDetail?.data?.active_slug}
                        </span>
                    </div>

                    {/* title / read-write */}
                    <div className={cn("w-full grid grid-cols-12 gap-2 items-start")}>
                        <span className={"col-span-8 order-1 lg:col-span-2 w-full py-3 flex items-center justify-start lg:justify-between font-bodyPri font-medium text-text-700 text-base tracking-wide"}>
                            <span>{"Title"}</span>
                            <span>{":"}</span>
                        </span>
                        {(editBtn !== courseDetailBtnConst.TITLE.value) && (
                            <div className={cn(
                                "col-span-full order-3 lg:order-2 lg:col-span-9 justify-self-stretch",
                                "p-3 h-full hover:bg-text-100 cursor-text",
                                "font-bodyPri font-normal text-text-900 text-md tracking-wide",
                            )}
                                onClick={() => onHandleEditDetail(courseDetailBtnConst.TITLE.value)}
                            >
                                {lmsCourseDetail?.data?.title || "-"}
                            </div>
                        )}
                        {(editBtn === courseDetailBtnConst.TITLE.value) && (
                            <div className={"col-span-full order-3 lg:order-2 lg:col-span-9 justify-self-stretch flex flex-col gap-0.5"}>
                                <input
                                    type={"text"}
                                    name={courseDetailBtnConst.TITLE.value}
                                    placeholder={"Enter course title"}
                                    maxLength={TITLE_MAX_LENGTH}
                                    value={addLmsCourseDetail?.payload?.title}
                                    onChange={onHandleChangeBasicDetail}
                                    className={cn(
                                        "flex flex-1 p-3 border-b focus:outline-none focus:!bg-primary-light bg-back-ground-light hover:bg-primary-light",
                                        "transition ease-in-out duration-300 overflow-hidden",
                                        (addLmsCourseDetail?.payload?.title?.trim()?.length < TITLE_MIN_LENGTH) ? "focus:border-red-500" : "focus:border-primary-dark",
                                        "font-bodyPri font-normal text-text-900 text-md tracking-wide placeholder:text-text-700"
                                    )}
                                    autoFocus={true}
                                />
                                <span className={"flex justify-end font-bodyPri font-normal text-text-700 text-sm"}>
                                    {`${addLmsCourseDetail?.payload?.title?.length}/${TITLE_MAX_LENGTH}`}
                                </span>
                            </div>
                        )}
                        {(editBtn === courseDetailBtnConst.TITLE.value) &&
                            <SaveAndCancelBtn
                                className={"col-span-4 lg:col-span-1 order-2 lg:order-3 justify-self-end flex flex-row gap-3"}
                                onHandleSave={() => onHandleSaveBasicDetail(courseDetailBtnConst.TITLE.value)}
                                onHandleReset={() => onHandleResetBasicDetail(courseDetailBtnConst.TITLE.value)}
                            />
                        }
                    </div>

                    {/* sub title / read-write */}
                    <div className={cn("w-full grid grid-cols-12 gap-2 items-start")}>
                        <span className={"col-span-8 order-1 lg:col-span-2 w-full py-3 flex items-center justify-start lg:justify-between font-bodyPri font-medium text-text-700 text-base tracking-wide"}>
                            <span className={"flex items-center gap-1"}>
                                <span>{"Sub Header"}</span>
                                <LmsCourseTooltip tooltip={lmsCourseTooltips?.courseSubTitle} />
                            </span>
                            <span>{":"}</span>
                        </span>
                        {(editBtn !== courseDetailBtnConst.SUB_TITLE.value) && (
                            <div className={cn(
                                "col-span-full order-3 lg:order-2 lg:col-span-9 justify-self-stretch",
                                "p-3 h-full hover:bg-text-100 cursor-text",
                                "font-bodyPri font-normal text-text-900 text-md tracking-wide",
                            )}
                                onClick={() => onHandleEditDetail(courseDetailBtnConst.SUB_TITLE.value)}
                            >
                                {lmsCourseDetail?.data?.sub_title || "-"}
                            </div>
                        )}
                        {(editBtn === courseDetailBtnConst.SUB_TITLE.value) && (
                            <div className={"col-span-full order-3 lg:order-2 lg:col-span-9 justify-self-stretch flex flex-col gap-0.5"}>
                                <textarea
                                    type={"text"}
                                    name={courseDetailBtnConst.SUB_TITLE.value}
                                    placeholder={"Enter sub title"}
                                    rows={2}
                                    value={addLmsCourseDetail?.payload?.sub_title}
                                    onChange={onHandleChangeBasicDetail}
                                    className={cn(
                                        "w-full flex p-3 border-b border-primary-dark focus:outline-none focus:!bg-primary-light bg-back-ground-light hover:bg-primary-light",
                                        "transition ease-in-out duration-300 overflow-hidden",
                                        "font-bodyPri font-normal text-text-900 text-md tracking-wide placeholder:text-text-700"
                                    )}
                                    autoFocus={true}
                                />
                                <span className={"flex justify-end font-bodyPri font-normal text-text-700 text-sm"}>
                                    {`${addLmsCourseDetail?.payload?.sub_title?.length}/${SUB_TITLE_MAX_LENGTH}`}
                                </span>
                            </div>
                        )}
                        {(editBtn === courseDetailBtnConst.SUB_TITLE.value) &&
                            <SaveAndCancelBtn
                                className={"col-span-4 lg:col-span-1 order-2 lg:order-3 justify-self-end flex flex-row gap-3"}
                                onHandleSave={() => onHandleSaveBasicDetail(courseDetailBtnConst.SUB_TITLE.value)}
                                onHandleReset={() => onHandleResetBasicDetail(courseDetailBtnConst.SUB_TITLE.value)}
                            />
                        }
                    </div>

                    {/* category / read-write */}
                    <div className={cn("w-full grid grid-cols-12 gap-2 items-start")}>
                        <span className={"col-span-8 order-1 lg:col-span-2 w-full py-3 flex items-center justify-start lg:justify-between font-bodyPri font-medium text-text-700 text-base tracking-wide"}>
                            <span className={"inline-flex items-center gap-1"}>
                                <span className={"inline-flex whitespace-normal lg:whitespace-nowrap"}>
                                    {lmsCourseDetail?.data?.category?.category
                                        ? lmsCourseDetail?.data?.category?.segment?.segment
                                            ? "Segment/Category"
                                            : "Category"
                                        : "Category"
                                    }
                                </span>
                                <LmsCourseTooltip tooltip={lmsCourseTooltips?.category} />
                            </span>
                            <span>{":"}</span>
                        </span>

                        {(editBtn !== courseDetailBtnConst.CATEGORY.value) && (
                            <div className={cn(
                                "col-span-full order-3 lg:order-2 lg:col-span-9 justify-self-stretch",
                                "p-3 h-full hover:bg-text-100 cursor-text",
                                "font-bodyPri font-normal text-text-900 text-md tracking-wide",
                            )}
                                onClick={() => onHandleEditDetail(courseDetailBtnConst.CATEGORY.value)}
                            >
                                {lmsCourseDetail?.data?.category?.category
                                    ? lmsCourseDetail?.data?.category?.segment?.segment
                                        ? `${lmsCourseDetail?.data?.category?.segment?.segment} / ${lmsCourseDetail?.data?.category?.category}`
                                        : lmsCourseDetail?.data?.category?.category
                                    : "Select Category"
                                }
                            </div>
                        )}
                        {(editBtn === courseDetailBtnConst.CATEGORY.value) && (
                            <div className={cn(
                                "col-span-full order-3 lg:order-2 lg:col-span-9 justify-self-stretch",
                                "w-full sm:h-fit z-10 border border-divider-lightDark rounded-lg",
                                "font-bodyPri font-normal text-text-900 text-base tracking-wide",
                                "focus:outline-none"
                            )}>
                                <SearchSelector
                                    isLoading={categoryList?.isLoading}
                                    isShowDropdownLabel={false}
                                    isOpenDropdown={true}
                                    placeholder={"Select Category"}
                                    dropdownLabel={"Select Category"}
                                    options={categoryList?.categoryList?.map((category) => ({
                                        label: category.category,
                                        value: category.category,
                                    })) || []}
                                    size={"small"}
                                    autoFocus={true}
                                    selectedValue={{
                                        label: addLmsCourseDetail?.payload?.category || "",
                                        value: addLmsCourseDetail?.payload?.category || ""
                                    }}
                                    onHandleSelect={(option) => dispatcher(setAddLmsCourseDetailPayload({
                                        ...addLmsCourseDetail?.payload,
                                        category: option?.value
                                    }))}
                                />
                            </div>
                        )}
                        {(editBtn === courseDetailBtnConst.CATEGORY.value) &&
                            <SaveAndCancelBtn
                                className={"col-span-4 lg:col-span-1 order-2 lg:order-3 justify-self-end flex flex-row gap-3"}
                                onHandleSave={() => onHandleSaveBasicDetail(courseDetailBtnConst.CATEGORY.value)}
                                onHandleReset={() => onHandleResetBasicDetail(courseDetailBtnConst.CATEGORY.value)}
                            />
                        }
                    </div>
                </div>
            </div>
            <PreviousNextButtons
                previousBtn={null}
                nextBtn={courseDetailNavigationOptions?.DESCRIPTION?.to}
            />
        </div>
    )
}

export default CourseBasicDetail;