import store from "redux/store";

import { EDULYTE_APP_DOMAIN_URL } from "const/default.const";

// get the video id from video url
export const getYouTubeVideoIdFromUrl = (url) => {
    // Our regex pattern to look for a youTube ID
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    //Match the url with the regex
    const match = url?.match(regExp);
    //Return the result
    return match && match[2]?.length === 11 ? match[2] : '';
};


// get the id from vimeo url
export const getVimeoIdFromUrl = (url) => {
    // Look for a string with 'vimeo', then whatever, then a
    // forward slash and a group of digits.
    const match = /vimeo.*\/(\d+)/i.exec(url);
    // If the match isn't null (i.e. it matched)
    if (match) {
        // The grouped/matched digits from the regex
        return match[1];
    }
};

// get youtube id from any youtube url
export const getYoutubePlayId = (url) => {
    if (!url) {
        return false
    }

    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url?.match(regExp);
    return (match && match[7]?.length === 11) ? match[7] : false;
}


// generate your own urls for youtube
export const generateYouTubeUrl = (videoId) => {
    return `//www.youtube.com/embed/${videoId}?autoplay=1&autohide=1&showinfo=0&modestbranding=1&controls=0&mute=0&rel=0&enablejsapi=1`;
};


// generate your own urls for vimeo
export const generateVimeoUrl = (videoId) => {
    return `https://player.vimeo.com/video/${videoId}?&autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1&#t=235s`;
};


// Convert queryString to queryParma object
export const generateQueryParams = (search) => {
    const query = {}
    search && search.slice(1).split("&").forEach(searchItem => {
        let keyValue = searchItem.split("=")
        query[keyValue[0]] = keyValue[1]
    })
    return query
}


// Convert a Base64-encoded string to a File object
export function base64StringToFile(base64String, filename) {
    var arr = base64String.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
}


// Download a Base64-encoded file
export function downloadBase64File(base64Data, filename) {
    var element = document.createElement('a')
    element.setAttribute('href', base64Data)
    element.setAttribute('download', filename)
    element.style.display = 'none'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
}


// Extract an Base64 Image's File Extension
export function extractImageFileExtensionFromBase64(base64Data) {
    return base64Data.substring('data:image/'.length, base64Data.indexOf(';base64'))
}


// Currency rate conversion
export function currencyRateConversion(toCurrency = "USD", amount = 0) {
    const { currencyRateList } = store.getState().master

    if (!currencyRateList?.currencyRateList || [null, undefined].includes(toCurrency) || [null, undefined].includes(amount)) {
        return "Loading..."
    }

    let targetCurrencyRate = currencyRateList?.currencyRateList[(toCurrency || "USD")]
    let targetAmount = (amount || 0) * targetCurrencyRate

    return `${toCurrency.toUpperCase()} ${parseFloat("" + (targetAmount / 100)).toFixed(2)}`

}

export function currencyRateConversionToUsd(fromCurrency = "USD", amount = 0) {
    const { currencyRateList } = store.getState().master

    if (!currencyRateList?.currencyRateList || [null, undefined].includes(fromCurrency) || [null, undefined].includes(amount)) {
        return "Loading..."
    }

    let targetCurrencyRate = currencyRateList?.currencyRateList[(fromCurrency || "USD")]
    let targetAmount = (amount || 0) / targetCurrencyRate

    return parseFloat("" + (targetAmount / 100)).toFixed(2)
}

export const formatFileSize = (fileSize) => {
    if (fileSize < 1024) {
        return `${(fileSize).toFixed(2)} B`
    } else if (fileSize < 1024 * 1024) {
        return `${(fileSize / 1024).toFixed(2)} KB`
    } else if (fileSize < 1024 * 1024 * 1024) {
        return `${((fileSize / (1024 * 1024)).toFixed(2))} MB`
    } else {
        return `${fileSize / (1024 * 1024 * 1024).toFixed(2)} GB`
    }
}

export const removeFileExtensionFromFileName = (fileName) => {
    if (!fileName) return;
    const lastDotIndex = fileName?.lastIndexOf(".")

    if (lastDotIndex !== -1) {
        return fileName?.substring(0, lastDotIndex)
    }

    return fileName;
}

export const isOrderUpdated = (array1 = [], array2 = []) => {
    if (!array1 || !array2) return;

    const isUpdated = array1?.some((item1, index) => item1 !== array2[index]);
    if (isUpdated) return true;
    else return false;
}

export const throttle = (callbackFunc, delay) => {
    let inProgress = false
    return (...args) => {
        if (inProgress) return;
        callbackFunc(...args)
        inProgress = true
        setTimeout(() => {
            inProgress = false
        }, delay)
    }
}

export function extractSubdomain(host) {
    if (!host || typeof host !== 'string') {
        return null;
    }

    const hostWithoutPort = host.split(':')[0];

    const hostParts = hostWithoutPort.split('.');

    if ((hostWithoutPort === 'localhost') || /^[0-9.]+$/.test(hostWithoutPort)) {
        return null;
    }

    if (hostParts.length < 2) {
        return null;
    }

    if ((hostParts[0] === 'www') && (hostParts.length === 3)) {
        return null;
    }

    return hostParts[0];
}

export const getCustomHostPath = ({ subDomain, domain = EDULYTE_APP_DOMAIN_URL, pathname = "" }) => {
    return `${domain?.split("://")[0]}://${subDomain}.${domain?.split("://")[1]}${pathname}`
}

class Debounce {
    __timer
    debounce(fn, delay = 2000) {
        return (...args) => {
            clearTimeout(this.__timer)
            this.__timer = setTimeout(() => {
                fn(...args)
            }, delay)
        }
    }
}

export const debounce = new Debounce();