import { cn } from "utils/cn.utils";

import { useAppState, useAppDispatcher } from 'hooks/useStore';

import { rescheduleSessionUser } from 'redux/session/session.request';
import { setAddSessionUserDetailPayload } from 'redux/session/session.slice';

import { dayjs, timeZone, getTimeZoneOffset } from 'utils/dateTime.utils';

const TimeSlot = ({ dailyAvail, timeSlot }) => {
    const { addSessionUserDetailPayload } = useAppState((s) => s.session)

    const dispatcher = useAppDispatcher()

    const timeZoneOffset = getTimeZoneOffset(timeZone)

    let isSelected = false
    addSessionUserDetailPayload?.payload?.schedule?.forEach((schedule) => {
        let currentDayjs = dayjs(dailyAvail.date + " " + timeSlot.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)
        let selectedDayjs = dayjs(schedule?.date + " " + schedule?.timeSlots[0]?.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone)

        isSelected = currentDayjs.format("YYYY-MM-DD HH:mm:ssZ") === selectedDayjs.format("YYYY-MM-DD HH:mm:ssZ")
    })

    const handleSelect = () => {
        if (!timeSlot?.isAvailable) return;

        dispatcher(setAddSessionUserDetailPayload({
            ...addSessionUserDetailPayload?.payload,
            timeZone: timeZone,
            schedule: isSelected ? [] : [{
                date: dailyAvail?.date,
                timeSlots: [{
                    startTime: timeSlot?.startTime,
                    endTime: timeSlot?.endTime
                }]
            }]
        }))
    }

    const handleConfirm = () => {
        if (addSessionUserDetailPayload?.isLoading) return;

        if (!window.confirm("Are you sure?")) return;

        const body = {
            availability_id: addSessionUserDetailPayload?.payload?.availabilityId,
            duration: addSessionUserDetailPayload?.payload?.duration,
            timeZone: timeZone,
            schedule: addSessionUserDetailPayload?.payload?.schedule
        }
        if (addSessionUserDetailPayload?.payload?.note) {
            body["comment"] = addSessionUserDetailPayload?.payload?.note
        }

        dispatcher(rescheduleSessionUser(addSessionUserDetailPayload?.payload?.sessionId, body))
    }

    return (
        <div className={"flex flex-row items-center justify-center"}>
            <span onClick={handleSelect} className={cn(
                "px-2 py-0.5 border-2 cursor-pointer border-primary-main",
                "font-bodyPri font-medium text-sm text-primary-main rounded",
                isSelected && "!text-text-50 bg-primary-main rounded-r-none",
                !timeSlot?.isAvailable && "text-secondary-light border-secondary-light"
            )}>
                {dayjs(dailyAvail.date + " " + timeSlot.startTime + timeZoneOffset, "YYYY-MM-DD HH:mm:ssZ").tz(timeZone).format("hh:mm A")}
            </span>
            {isSelected &&
                <span onClick={handleConfirm} className={cn(
                    "px-2 py-0.5 border-2 cursor-pointer border-secondary-main rounded-r",
                    "font-bodyPri font-medium text-sm",
                    "transition ease-in-out delay-150",
                    isSelected
                        ? "bg-secondary-main text-text-50"
                        : "hidden"
                )}>
                    {"Confirm"}
                </span>
            }
        </div>
    )
}

export default TimeSlot;