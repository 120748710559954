import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';

import { MdAdd } from 'react-icons/md';

import ToolTipView from 'components/tooltipView';
import ComponentLoader from 'components/loader/ComponentLoader';
import Pagination from 'components/pagination/Pagination';
import ThreeDotMenu from 'components/threeDotMenu/ThreeDotMenu';

import PageHeading from 'pages/auth/editClass/_components/header/pageHeading';
import { classDetailTabConst, classDetailTooltips, cohortsNavigationOptions, participantsHeaderConst, cohortParticipantIsActiveConst } from 'pages/auth/editClass/data';
import CourseTable from "pages/auth/editClass/_components/table";

import courseServices from 'redux/course/course.services';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';
import { cohortJoiningFlowEnum, instructorCourseStatusEnum, cohortParticipantStatusEnum } from 'redux/course/course.const';

import { dayjs, timeZone } from 'utils/dateTime.utils';
import { cn } from 'utils/cn.utils';
import { generateQueryParams } from 'utils/generators.utils';
import { pagesInfo } from 'utils/pagesInfo';

const RECORDS = 10;
const INVITE_PARTICIPANT_QUERY = "invite-participants";

const ParticipantsInitialState = { isLoading: false, result: null, pagination: null, error: null }

const ParticipantsPage = () => {
    const { modal } = useAppState((state) => state.local)
    const { instructorCourse } = useAppState((state) => state.course)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()
    const { cohortId } = useParams()

    const [participantList, setParticipantList] = useState(ParticipantsInitialState)

    const query = useMemo(() => generateQueryParams(location.search), [location.search])

    useEffect(() => {

        return () => {
            dispatcher(setModal({
                ...modal,
                [modalConst.inviteCohortParticipantModal.key]: {
                    ...modal[modalConst.inviteCohortParticipantModal.key],
                    isVisible: false,
                    title: "Invite Participant"
                }
            }))
        }
    }, [query?.action])

    useEffect(() => {
        if (instructorCourse?.result) {
            if (query && (query?.action && (query?.action === INVITE_PARTICIPANT_QUERY))) {
                if (instructorCourse?.result?.status === instructorCourseStatusEnum?.published?.key) {
                    dispatcher(setModal({
                        ...modal,
                        [modalConst.inviteCohortParticipantModal.key]: {
                            ...modal[modalConst.inviteCohortParticipantModal.key],
                            isVisible: true,
                            title: "Invite Participant",
                            payload: {
                                cohortId: Number(cohortId)
                            }
                        }
                    }))
                } else {
                    navigate(`${pagesInfo?.CREATE_COURSE?.pagePath}/${instructorCourse?.result?.id}/edit${classDetailTabConst.cohorts.pagePath}/${cohortId}/${cohortsNavigationOptions.participants.routePath}`)
                }
            }
        }
    }, [instructorCourse?.result, query?.action]);

    useEffect(() => {
        if (cohortId) {
            const query = { page: 1, records: RECORDS }
            getCohortParticipantList(query)
        }
    }, [cohortId])

    const getCohortParticipantList = async (query) => {
        setParticipantList((prevState) => ({ ...prevState, isLoading: true }))

        try {
            const requestData = {
                params: { cohortId: Number(cohortId) },
                query: query
            }
            const response = await courseServices.getCohortParticipantList(requestData)
            if (response.status === 200) {
                setParticipantList((prevState) => ({
                    ...prevState,
                    result: response?.data?.data?.result,
                    pagination: response?.data?.data?.pagination
                }))
            } else {
                throw new Error(response)
            }
        } catch (error) {
            setParticipantList((prevState) => ({ ...prevState, error: error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!" }))
            toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
        } finally {
            setParticipantList((prevState) => ({ ...prevState, isLoading: false }))
        }
    }

    const updateCohortParticipantDetail = async (cohortId, participantId, body) => {
        try {
            const requestData = {
                params: { cohortId: Number(cohortId), participantId: Number(participantId) },
                body: { ...body, invite_email_sent: false }
            }
            const response = await courseServices.updateCohortParticipantDetail(requestData)
            if (response.status === 200) {
                setParticipantList((prevState) => ({
                    ...prevState,
                    result: participantList?.result?.map((participant) => (
                        (participant?.id === participantId) ? response?.data?.data?.result : participant
                    )),
                    error: null
                }))
                toast.success(response?.data?.data?.message)
            } else {
                throw new Error(response)
            }
        } catch (error) {
            setParticipantList((prevState) => ({ ...prevState, error: error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!" }))
            toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong!")
        }
    }

    const onHandleSelectMenu = (menuItem, participant) => {
        switch (menuItem?.key) {
            case cohortParticipantIsActiveConst.active.key:
                updateCohortParticipantDetail(Number(cohortId), participant?.id, { active: true })
                break;
            case cohortParticipantIsActiveConst.inactive.key:
                updateCohortParticipantDetail(Number(cohortId), participant?.id, { active: false })
                break;
        }
    }

    const onHandleChangePage = (page) => {
        const query = {
            page: page,
            records: RECORDS
        }
        getCohortParticipantList(query)
    }

    const onHandleInviteParticipants = () => {
        if (instructorCourse?.result?.status !== instructorCourseStatusEnum?.published?.key) return;

        navigate(`?action=${INVITE_PARTICIPANT_QUERY}`)
    }


    const UserImageContainer = ({ profilePicUrl }) => {
        return (
            <div className={"flex items-center justify-center"}>
                <div className={"relative w-10 h-10 overflow-hidden rounded-full"}>
                    <img
                        src={profilePicUrl}
                        className={"w-full h-full object-cover"}
                    />
                </div>
            </div>
        )
    }

    const ActivityContainer = ({ participant }) => {
        const menuItems = participant?.active
            ? Object.values(cohortParticipantIsActiveConst)?.filter((item) => item.key !== cohortParticipantIsActiveConst.active.key)
            : Object.values(cohortParticipantIsActiveConst)?.filter((item) => item.key !== cohortParticipantIsActiveConst.inactive.key)

        return (
            <div className={"flex items-center justify-center"}>
                <ThreeDotMenu
                    menuItems={menuItems}
                    onHandleSelect={(menuItem) => onHandleSelectMenu(menuItem, participant)}
                />
            </div>
        )

    }

    const participantRows = useMemo(() => {
        if (!!participantList?.result?.length) {
            return participantList?.result?.map((participant) => [
                <UserImageContainer profilePicUrl={participant?.user?.profile_pic_url} />,
                participant?.user?.id || "-",
                participant?.user?.first_name + " " + participant?.user?.last_name?.charAt(0) + "." || "-",
                cohortJoiningFlowEnum[participant?.joining_flow]?.label || "-",
                cohortParticipantStatusEnum[participant?.status]?.label || "-",
                !!participant?.active ? "Active" : "Inactive" || "-",
                dayjs(participant?.joined_at)?.tz(timeZone)?.format("ddd, MMM D, YYYY") || "-",
                <ActivityContainer participant={participant} />
            ])
        } else return [];
    }, [participantList?.result])

    return (
        <div className={"w-full space-y-5"}>
            {participantList?.isLoading &&
                <ComponentLoader isLoading={participantList?.isLoading} />
            }

            <PageHeading headerText={"Participant(s)"} />

            {(!participantList?.isLoading && (participantList?.pagination?.records > 0)) &&
                <div className={"w-full flex items-center justify-end gap-3"}>
                    <ToolTipView
                        content={(instructorCourse?.result?.status === instructorCourseStatusEnum?.published?.key)
                            ? classDetailTooltips?.publishedInviteBtnTooltip
                            : classDetailTooltips?.disabledInviteBtnTooltip
                        }>
                        <div
                            className={cn(
                                "flex items-center justify-center gap-1 py-1 px-3 rounded-lg",
                                (instructorCourse?.result?.status === instructorCourseStatusEnum?.published?.key)
                                    ? "border border-primary-dark text-primary-dark cursor-pointer hover:bg-primary-dark group"
                                    : "border border-divider-medium bg-text-300 text-text-400 cursor-not-allowed"
                            )}
                            onClick={onHandleInviteParticipants}
                        >
                            <MdAdd className={"text-lg group-hover:text-text-50"} />
                            <span className={"font-bodyPri font-normal group-hover:text-text-50"}>
                                {"Add Participant"}
                            </span>
                        </div>
                    </ToolTipView>
                </div>
            }

            {(!participantList?.isLoading && (participantList?.pagination?.records > 0)) &&
                <CourseTable
                    headers={Object.values(participantsHeaderConst)}
                    rows={participantRows}
                    rowHeaderColor={"bg-back-ground-medium"}
                    alternateRowColor={"bg-primary-light"}
                />
            }
            {(participantList && (participantList?.pagination?.totalPages > 1)) &&
                <div className={"flex justify-center items-center"}>
                    <Pagination
                        page={participantList?.pagination?.page}
                        totalPages={participantList?.pagination?.totalPages}
                        onChangePage={onHandleChangePage}
                    />
                </div>
            }
            {(participantList && (participantList?.pagination?.records === 0)) &&
                <div className={'w-full h-full flex flex-col items-center justify-center gap-1 text-center text-base font-bodyPri tracking-wide'}>
                    <div className={"w-[16rem] h-[16rem] overflow-hidden"}>
                        <img
                            src={"https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/lms-quiz/quiz-student-not-found.jpg"}
                            alt={"no-participant-found"}
                            className={"w-full h-full object-cover"}
                        />
                    </div>
                    <ToolTipView content={(instructorCourse?.result?.status === instructorCourseStatusEnum?.published?.key)
                        ? classDetailTooltips?.publishedInviteBtnTooltip
                        : classDetailTooltips?.disabledInviteBtnTooltip
                    }>
                        <div
                            className={cn(
                                "flex items-center justify-center gap-1 py-1 px-3 rounded-lg",
                                (instructorCourse?.result?.status === instructorCourseStatusEnum?.published?.key)
                                    ? "border border-divider-dark text-divider-dark cursor-pointer hover:bg-divider-dark group"
                                    : "border border-divider-medium bg-text-300 text-text-400 cursor-not-allowed"
                            )}
                            onClick={onHandleInviteParticipants}
                        >
                            <MdAdd className={"text-lg group-hover:text-text-50"} />
                            <span className={"font-bodyPri font-normal group-hover:text-text-50"}>
                                {"Add Participants"}
                            </span>
                        </div>
                    </ToolTipView>
                </div>
            }
            {(!participantList?.isLoading && participantList?.error) &&
                <div className={"flex items-center justify-start gap-1"}>
                    <span className={"font-bodyPri font-medium text-red-500 text-base tracking-wide"}>
                        {participantList?.error}
                    </span>
                </div>
            }
        </div>
    )
}

export default ParticipantsPage;